/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";
import styles from "./style.module.css";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { Button } from "@mui/material";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
const SheetA = ({ handleNextStep, handlePrevStep, currentStep,handleStepDetails }) => {
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [economicData, setEconomicData] = useState("");
  const [sheetData, setSheetData] = useState({
    sr_no: "1",
    panchayat_name: "",
    gpt_name: "",
    population_as_per_census: "",
    no_of_unreserved_seats: "",
    female_reserve: "",
    female_schedule_caste: "",
    female_scheduled_tribes: "",
    female_other_back: "",
    scheduled_caste_male: "",
    scheduled_tribe_male: "",
    male_other_back: "",
    total_unreserved_seats: "",
    associate_member_space: "",
    total_no_of_seats: "",
    gp_election_reporting: "",
    stolen_elections: "",
    elected_members: "",
    appointed_members: "",
    total_fifteen_or_sixteen: "",
    elected_female_reserve: "",
    elected_female_schedule_cast: "",
    elected_female_scheduled_tribe: "",
    elected_female_other_back: "",
    committee_female_reserve: "",
    committee_female_schedule_cast: "",
    committee_female_schedule_tribe: "",
    committee_female_other_back: "",
    total_female_reserve: "",
    total_female_schedule_cast: "",
    total_female_schedule_tribe: "",
    total_female_other_back: "",
    schedule_elected_members: "",
    schedule_appointed_members: "",
    schedule_total: "",
    schedule_tribe_members: "",
    schedule_tribe_appointed_members: "",
    schedule_tribe_total: "",
    other_back_members: "",
    other_back_appointed_members: "",
    other_back_total: "",
    total_elected_members: "",
    committee_appointed_members: "",
    no_of_co_members: "",
    elected_no_of_co_members: "",
    general: "",
    female_reserve_vacancies: "",
    female_schedule_cast_vacancies: "",
    female_schedule_tribe_vacancies: "",
    female_other_back_vacancies: "",
    male_schedule_cast_vacancies: "",
    male_schedule_tribe_vacancies: "",
    male_other_back_vacancies: "",
    total_vacancies: "",
    shera: "",
  });

  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetA response", response.data.data);
      if (response.data.status === true) {
        const data = response.data.data.form_a_data;
        const otherData = response.data.data.other_data;
        const defaultSheetData = {
          sr_no: "1",
          panchayat_name: otherData.taluka_name,
          gpt_name: otherData.village_name,
          // ... (other fields with default values)
        };
        if (data !== null) {
          setSheetData({
           ...defaultSheetData,
            population_as_per_census: data.population_as_per_census,
            no_of_unreserved_seats: data.no_of_unreserved_seats,
            female_reserve: data.female_reserve,
            female_schedule_caste: data.female_schedule_caste,
            female_scheduled_tribes: data.female_scheduled_tribes,
            female_other_back: data.female_other_back,
            scheduled_caste_male: data.scheduled_caste_male,
            scheduled_tribe_male: data.scheduled_tribe_male,
            male_other_back: data.male_other_back,
            total_unreserved_seats: "",
            associate_member_space: data.associate_member_space,
            total_no_of_seats: "",
            gp_election_reporting: data.gp_election_reporting,
            stolen_elections: data.stolen_elections,
            elected_members: data.elected_members,
            appointed_members: data.appointed_members,
            total_fifteen_or_sixteen: "",
            elected_female_reserve: data.elected_female_reserve,
            elected_female_schedule_cast: data.elected_female_schedule_cast,
            elected_female_scheduled_tribe: data.elected_female_scheduled_tribe,
            elected_female_other_back: data.elected_female_other_back,
            committee_female_reserve: data.committee_female_reserve,
            committee_female_schedule_cast: data.committee_female_schedule_cast,
            committee_female_schedule_tribe:
              data.committee_female_schedule_tribe,
            committee_female_other_back: data.committee_female_other_back,
            total_female_reserve: data.total_female_reserve,
            total_female_schedule_cast: data.total_female_schedule_cast,
            total_female_schedule_tribe: data.total_female_schedule_tribe,
            total_female_other_back: data.total_female_other_back,
            schedule_elected_members: data.schedule_elected_members,
            schedule_appointed_members: data.schedule_appointed_members,
            schedule_total: "",
            schedule_tribe_members: data.schedule_tribe_members,
            schedule_tribe_appointed_members:
              data.schedule_tribe_appointed_members,
            schedule_tribe_total: "",
            other_back_members: data.other_back_members,
            other_back_appointed_members: data.other_back_appointed_members,
            other_back_total: "",
            total_elected_members: "",
            committee_appointed_members: "",
            no_of_co_members: data.no_of_co_members,
            elected_no_of_co_members: "",
            general: data.general,
            female_reserve_vacancies: data.female_reserve_vacancies,
            female_schedule_cast_vacancies: data.female_schedule_cast_vacancies,
            female_schedule_tribe_vacancies:
              data.female_schedule_tribe_vacancies,
            female_other_back_vacancies: data.female_other_back_vacancies,
            male_schedule_cast_vacancies: data.female_schedule_cast_vacancies,
            male_schedule_tribe_vacancies: data.male_schedule_tribe_vacancies,
            male_other_back_vacancies: data.male_other_back_vacancies,
            total_vacancies: data.total_vacancies,
            shera: data.shera,
          });
        } else {
          setSheetData({
            ...defaultSheetData,
            population_as_per_census: "",
            no_of_unreserved_seats: "",
            female_reserve: "",
            female_schedule_caste: "",
            female_scheduled_tribes: "",
            female_other_back: "",
            scheduled_caste_male: "",
            scheduled_tribe_male: "",
            male_other_back: "",
            total_unreserved_seats: "",
            associate_member_space: "",
            total_no_of_seats: "",
            gp_election_reporting: "",
            stolen_elections: "",
            elected_members: "",
            appointed_members: "",
            total_fifteen_or_sixteen: "",
            elected_female_reserve: "",
            elected_female_schedule_cast: "",
            elected_female_scheduled_tribe: "",
            elected_female_other_back: "",
            committee_female_reserve: "",
            committee_female_schedule_cast: "",
            committee_female_schedule_tribe: "",
            committee_female_other_back: "",
            total_female_reserve: "",
            total_female_schedule_cast: "",
            total_female_schedule_tribe: "",
            total_female_other_back: "",
            schedule_elected_members: "",
            schedule_appointed_members: "",
            schedule_total: "",
            schedule_tribe_members: "",
            schedule_tribe_appointed_members: "",
            schedule_tribe_total: "",
            other_back_members: "",
            other_back_appointed_members: "",
            other_back_total: "",
            total_elected_members: "",
            committee_appointed_members: "",
            no_of_co_members: "",
            elected_no_of_co_members: "",
            general: "",
            female_reserve_vacancies: "",
            female_schedule_cast_vacancies: "",
            female_schedule_tribe_vacancies: "",
            female_other_back_vacancies: "",
            male_schedule_cast_vacancies: "",
            male_schedule_tribe_vacancies: "",
            male_other_back_vacancies: "",
            total_vacancies: "",
            shera: "",
          });
        }
      }

      if (
        response.data.data.form_a_data === null ||
        response.data.data.form_a_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_a_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value  }));
  };

  // table validation
  const validateForm = (isDraft = false) => {
    const newErrors = {};

    // Validate tabels all input
    if (
      (!sheetData.population_as_per_census ||
        !/^-?\d*\.?\d+$/.test(sheetData.population_as_per_census)) &&
      (!isDraft || sheetData.population_as_per_census !== "")
    ) {
      newErrors.population_as_per_census = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.no_of_unreserved_seats !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.no_of_unreserved_seats)
    ) {
      newErrors.no_of_unreserved_seats = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_reserve !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_reserve)
    ) {
      newErrors.female_reserve = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_schedule_caste !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_schedule_caste)
    ) {
      newErrors.female_schedule_caste = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_scheduled_tribes !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_scheduled_tribes)
    ) {
      newErrors.female_scheduled_tribes = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_other_back !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_other_back)
    ) {
      newErrors.female_other_back = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.scheduled_caste_male !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.scheduled_caste_male)
    ) {
      newErrors.scheduled_caste_male = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.scheduled_tribe_male !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.scheduled_tribe_male)
    ) {
      newErrors.scheduled_tribe_male = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.male_other_back !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.male_other_back)
    ) {
      newErrors.male_other_back = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.associate_member_space !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.associate_member_space)
    ) {
      newErrors.associate_member_space = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.gp_election_reporting !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.gp_election_reporting)
    ) {
      newErrors.gp_election_reporting = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }
    if (
      sheetData.stolen_elections !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.stolen_elections)
    ) {
      newErrors.stolen_elections = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }
    if (
      sheetData.elected_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.elected_members)
    ) {
      newErrors.elected_members = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.appointed_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.appointed_members)
    ) {
      newErrors.appointed_members = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.elected_female_reserve !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.elected_female_reserve)
    ) {
      newErrors.elected_female_reserve = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.elected_female_schedule_cast !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.elected_female_schedule_cast)
    ) {
      newErrors.elected_female_schedule_cast =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.elected_female_scheduled_tribe !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.elected_female_scheduled_tribe)
    ) {
      newErrors.elected_female_scheduled_tribe =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.elected_female_other_back !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.elected_female_other_back)
    ) {
      newErrors.elected_female_other_back = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.committee_female_reserve !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.committee_female_reserve)
    ) {
      newErrors.committee_female_reserve = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.committee_female_schedule_cast !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.committee_female_schedule_cast)
    ) {
      newErrors.committee_female_schedule_cast =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.committee_female_schedule_tribe !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.committee_female_schedule_tribe)
    ) {
      newErrors.committee_female_schedule_tribe =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.committee_female_other_back !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.committee_female_other_back)
    ) {
      newErrors.committee_female_other_back = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    // if (
    //   sheetData.total_female_reserve !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.total_female_reserve)
    // ) {
    //   newErrors.total_female_reserve = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    // if (
    //   sheetData.total_female_schedule_cast !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.total_female_schedule_cast)
    // ) {
    //   newErrors.total_female_schedule_cast = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    // if (
    //   sheetData.total_female_schedule_tribe !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.total_female_schedule_tribe)
    // ) {
    //   newErrors.total_female_schedule_tribe = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    // if (
    //   sheetData.total_female_other_back !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.total_female_other_back)
    // ) {
    //   newErrors.total_female_other_back = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    if (
      sheetData.schedule_elected_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.schedule_elected_members)
    ) {
      newErrors.schedule_elected_members = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.schedule_appointed_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.schedule_appointed_members)
    ) {
      newErrors.schedule_appointed_members = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.schedule_tribe_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.schedule_tribe_members)
    ) {
      newErrors.schedule_tribe_members = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.schedule_tribe_appointed_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.schedule_tribe_appointed_members)
    ) {
      newErrors.schedule_tribe_appointed_members =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_back_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_back_members)
    ) {
      newErrors.other_back_members = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_back_appointed_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_back_appointed_members)
    ) {
      newErrors.other_back_appointed_members =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.no_of_co_members !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.no_of_co_members)
    ) {
      newErrors.no_of_co_members = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.general !== "" && !/^-?\d*\.?\d+$/.test(sheetData.general)) {
      newErrors.general = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_reserve_vacancies !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_reserve_vacancies)
    ) {
      newErrors.female_reserve_vacancies = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_schedule_cast_vacancies !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_schedule_cast_vacancies)
    ) {
      newErrors.female_schedule_cast_vacancies =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_schedule_tribe_vacancies !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_schedule_tribe_vacancies)
    ) {
      newErrors.female_schedule_tribe_vacancies =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.female_other_back_vacancies !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.female_other_back_vacancies)
    ) {
      newErrors.female_other_back_vacancies = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.male_schedule_cast_vacancies !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.male_schedule_cast_vacancies)
    ) {
      newErrors.male_schedule_cast_vacancies =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.male_schedule_tribe_vacancies !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.male_schedule_tribe_vacancies)
    ) {
      newErrors.male_schedule_tribe_vacancies =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.male_other_back_vacancies !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.male_other_back_vacancies)
    ) {
      newErrors.male_other_back_vacancies = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.shera !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.shera)
    ) {
      newErrors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }

    console.log("Validation errors:", newErrors);
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // const handleFormSubmission = async (e) => {
  //   e.preventDefault();

  //   // Validate the form and get the validation errors
  //   const isValid = validateForm(false);

  //   // Check if there are errors in the current state
  //   if (Object.keys(errors).length > 0) {
  //     await handleSubmit(e); // If there are errors, submit the form
  //   } else if (isValid) {
  //     handleShow(); // If there are no errors and the form is valid, show success or proceed with further actions
  //   }
  // };

  // update api

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    handleClose();

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_a_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          population_as_per_census: sheetData.population_as_per_census,
          no_of_unreserved_seats: sheetData.no_of_unreserved_seats,
          female_reserve: sheetData.female_reserve,
          female_schedule_caste: sheetData.female_schedule_caste,
          female_scheduled_tribes: sheetData.female_scheduled_tribes,
          female_other_back: sheetData.female_other_back,
          scheduled_caste_male: sheetData.scheduled_caste_male,
          scheduled_tribe_male: sheetData.scheduled_tribe_male,
          male_other_back: sheetData.male_other_back,
          total_unreserved_seats: totalUnreservedSeat,
          associate_member_space: sheetData.associate_member_space,
          total_no_of_seats: totalNumberOfArea,
          gp_election_reporting: sheetData.gp_election_reporting,
          stolen_elections: sheetData.stolen_elections,
          elected_members: sheetData.elected_members,
          appointed_members: sheetData.appointed_members,
          total_fifteen_or_sixteen: totalUnreserved,
          elected_female_reserve: sheetData.elected_female_reserve,
          elected_female_schedule_cast: sheetData.elected_female_schedule_cast,
          elected_female_scheduled_tribe:
            sheetData.elected_female_scheduled_tribe,
          elected_female_other_back: sheetData.elected_female_other_back,
          committee_female_reserve: sheetData.committee_female_reserve,
          committee_female_schedule_cast:
            sheetData.committee_female_schedule_cast,
          committee_female_schedule_tribe:
            sheetData.committee_female_schedule_tribe,
          committee_female_other_back: sheetData.female_other_back,
          total_female_reserve: parseFloat(sheetData.elected_female_reserve || 0)+parseFloat(sheetData.committee_female_reserve || 0) || "",
          total_female_schedule_cast: parseFloat(sheetData.elected_female_schedule_cast || 0)+parseFloat(sheetData.committee_female_schedule_cast || 0) || "",
          total_female_schedule_tribe: parseFloat(sheetData.elected_female_scheduled_tribe || 0) + parseFloat(sheetData.committee_female_schedule_tribe || 0) || "",
          total_female_other_back: parseFloat(sheetData.elected_female_other_back || 0) + parseFloat(sheetData.committee_female_other_back || 0) || "",
          schedule_elected_members: sheetData.schedule_elected_members,
          schedule_appointed_members: sheetData.schedule_appointed_members,
          schedule_total: totalSchedule,
          schedule_tribe_members: sheetData.schedule_tribe_members,
          schedule_tribe_appointed_members:
            sheetData.schedule_tribe_appointed_members,
          schedule_tribe_total: totalScheduleTribe,
          other_back_members: sheetData.other_back_members,
          other_back_appointed_members: sheetData.other_back_appointed_members,
          other_back_total: totalOtherBackMember,
          total_elected_members: totalElectedMember,
          committee_appointed_members: totalCommitteeMember,
          no_of_co_members: sheetData.no_of_co_members,
          elected_no_of_co_members: totalElectedCoMember,
          general: sheetData.general,
          female_reserve_vacancies: sheetData.female_reserve_vacancies,
          female_schedule_cast_vacancies:
            sheetData.female_schedule_cast_vacancies,
          female_schedule_tribe_vacancies:
            sheetData.female_schedule_tribe_vacancies,
          female_other_back_vacancies: sheetData.female_other_back_vacancies,
          male_schedule_cast_vacancies:
            sheetData.female_schedule_cast_vacancies,
          male_schedule_tribe_vacancies:
            sheetData.male_schedule_tribe_vacancies,
          male_other_back_vacancies: sheetData.male_other_back_vacancies,
          total_vacancies: totalVacancies,
          shera: sheetData.shera,
        },
      };
      console.log("update finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetA response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // draft api
  const handleDraftSheetA = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm(true)) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_a_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          population_as_per_census: sheetData.population_as_per_census,
          no_of_unreserved_seats: sheetData.no_of_unreserved_seats,
          female_reserve: sheetData.female_reserve,
          female_schedule_caste: sheetData.female_schedule_caste,
          female_scheduled_tribes: sheetData.female_scheduled_tribes,
          female_other_back: sheetData.female_other_back,
          scheduled_caste_male: sheetData.scheduled_caste_male,
          scheduled_tribe_male: sheetData.scheduled_tribe_male,
          male_other_back: sheetData.male_other_back,
          total_unreserved_seats: totalUnreservedSeat,
          associate_member_space: sheetData.associate_member_space,
          total_no_of_seats: totalNumberOfArea,
          gp_election_reporting: sheetData.gp_election_reporting,
          stolen_elections: sheetData.stolen_elections,
          elected_members: sheetData.elected_members,
          appointed_members: sheetData.appointed_members,
          total_fifteen_or_sixteen: totalUnreserved,
          elected_female_reserve: sheetData.elected_female_reserve,
          elected_female_schedule_cast: sheetData.elected_female_schedule_cast,
          elected_female_scheduled_tribe:
            sheetData.elected_female_scheduled_tribe,
          elected_female_other_back: sheetData.elected_female_other_back,
          committee_female_reserve: sheetData.committee_female_reserve,
          committee_female_schedule_cast:
            sheetData.committee_female_schedule_cast,
          committee_female_schedule_tribe:
            sheetData.committee_female_schedule_tribe,
          committee_female_other_back: sheetData.female_other_back,
          total_female_reserve: parseFloat(sheetData.elected_female_reserve || 0)+parseFloat(sheetData.committee_female_reserve || 0) || "",
          total_female_schedule_cast: parseFloat(sheetData.elected_female_schedule_cast || 0)+parseFloat(sheetData.committee_female_schedule_cast || 0) || "",
          total_female_schedule_tribe: parseFloat(sheetData.elected_female_scheduled_tribe || 0) + parseFloat(sheetData.committee_female_schedule_tribe || 0) || "",
          total_female_other_back: parseFloat(sheetData.elected_female_other_back || 0) + parseFloat(sheetData.committee_female_other_back || 0) || "",
          schedule_elected_members: sheetData.schedule_elected_members,
          schedule_appointed_members: sheetData.schedule_appointed_members,
          schedule_total: totalSchedule,
          schedule_tribe_members: sheetData.schedule_tribe_members,
          schedule_tribe_appointed_members:
            sheetData.schedule_tribe_appointed_members,
          schedule_tribe_total: totalScheduleTribe,
          other_back_members: sheetData.other_back_members,
          other_back_appointed_members: sheetData.other_back_appointed_members,
          other_back_total: totalOtherBackMember,
          total_elected_members: totalElectedMember,
          committee_appointed_members: totalCommitteeMember,
          no_of_co_members: sheetData.no_of_co_members,
          elected_no_of_co_members: totalElectedCoMember,
          general: sheetData.general,
          female_reserve_vacancies: sheetData.female_reserve_vacancies,
          female_schedule_cast_vacancies:
            sheetData.female_schedule_cast_vacancies,
          female_schedule_tribe_vacancies:
            sheetData.female_schedule_tribe_vacancies,
          female_other_back_vacancies: sheetData.female_other_back_vacancies,
          male_schedule_cast_vacancies:
            sheetData.female_schedule_cast_vacancies,
          male_schedule_tribe_vacancies:
            sheetData.male_schedule_tribe_vacancies,
          male_other_back_vacancies: sheetData.male_other_back_vacancies,
          total_vacancies: totalVacancies,
          shera: sheetData.shera,
        },
      };
      console.log("draftSheetA finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetA response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // totalUnreservedSeat
  const totalUnreservedSeat =
    parseInt(sheetData.female_reserve || 0) +
      parseInt(sheetData.female_schedule_caste || 0) +
      parseInt(sheetData.female_scheduled_tribes || 0) +
      parseInt(sheetData.female_other_back || 0) +
      parseInt(sheetData.scheduled_caste_male || 0) +
      parseInt(sheetData.scheduled_tribe_male || 0) +
      parseInt(sheetData.male_other_back || 0) || "";

  // totalNumberOfArea
  const totalNumberOfArea =
    parseInt(sheetData.no_of_unreserved_seats || 0) +
      parseInt(sheetData.female_reserve || 0) +
      parseInt(sheetData.female_schedule_caste || 0) +
      parseInt(sheetData.female_scheduled_tribes || 0) +
      parseInt(sheetData.female_other_back || 0) +
      parseInt(sheetData.scheduled_caste_male || 0) +
      parseInt(sheetData.scheduled_tribe_male || 0) +
      parseInt(sheetData.male_other_back || 0) +
      parseInt(sheetData.associate_member_space || 0) || "";

  // totalUnreserved
  const totalUnreserved =
    parseInt(sheetData.elected_members || 0) +
      parseInt(sheetData.appointed_members || 0) || "";

  // totalSchedule
  const totalSchedule =
    parseInt(sheetData.schedule_elected_members || 0) +
      parseInt(sheetData.schedule_appointed_members || 0) || "";

  // totalScheduleTribe
  const totalScheduleTribe =
    parseInt(sheetData.schedule_tribe_members || 0) +
      parseInt(sheetData.schedule_tribe_appointed_members || 0) || "";

  // totalOtherBackMember
  const totalOtherBackMember =
    parseInt(sheetData.other_back_members || 0) +
      parseInt(sheetData.other_back_appointed_members || 0) || "";

  // totalElectedMember
  const totalElectedMember =
    parseInt(sheetData.elected_members || 0) +
      parseInt(sheetData.elected_female_reserve || 0) +
      parseInt(sheetData.elected_female_schedule_cast || 0) +
      parseInt(sheetData.elected_female_scheduled_tribe || 0) +
      parseInt(sheetData.elected_female_other_back || 0) +
      parseInt(sheetData.schedule_elected_members || 0) +
      parseInt(sheetData.schedule_tribe_members || 0) +
      parseInt(sheetData.other_back_members || 0) || "";

  // totalCommitteeMember
  const totalCommitteeMember =
    parseInt(sheetData.appointed_members || 0) +
      parseInt(sheetData.committee_female_reserve || 0) +
      parseInt(sheetData.committee_female_schedule_cast || 0) +
      parseInt(sheetData.committee_female_schedule_tribe || 0) +
      parseInt(sheetData.committee_female_other_back || 0) +
      parseInt(sheetData.schedule_appointed_members || 0) +
      parseInt(sheetData.schedule_tribe_appointed_members || 0) +
      parseInt(sheetData.other_back_appointed_members || 0) || "";

  // totalElectedCoMember

  const totalElectedCoMember =
    parseInt(sheetData.elected_members || 0) +
      parseInt(sheetData.elected_female_reserve || 0) +
      parseInt(sheetData.elected_female_schedule_cast || 0) +
      parseInt(sheetData.elected_female_scheduled_tribe || 0) +
      parseInt(sheetData.elected_female_other_back || 0) +
      parseInt(sheetData.schedule_elected_members || 0) +
      parseInt(sheetData.schedule_tribe_members || 0) +
      parseInt(sheetData.other_back_members || 0) +
      parseInt(sheetData.appointed_members || 0) +
      parseInt(sheetData.committee_female_reserve || 0) +
      parseInt(sheetData.committee_female_schedule_cast || 0) +
      parseInt(sheetData.committee_female_schedule_tribe || 0) +
      parseInt(sheetData.committee_female_other_back || 0) +
      parseInt(sheetData.schedule_appointed_members || 0) +
      parseInt(sheetData.schedule_tribe_appointed_members || 0) +
      parseInt(sheetData.other_back_appointed_members || 0) +
      parseInt(sheetData.no_of_co_members || 0) || "";

  // total Vacancies
  const totalVacancies =
    parseInt(sheetData.general || 0) +
      parseInt(sheetData.female_reserve_vacancies || 0) +
      parseInt(sheetData.female_schedule_cast_vacancies || 0) +
      parseInt(sheetData.female_schedule_tribe_vacancies || 0) +
      parseInt(sheetData.female_other_back_vacancies || 0) +
      parseInt(sheetData.male_schedule_cast_vacancies || 0) +
      parseInt(sheetData.male_schedule_tribe_vacancies || 0) +
      parseInt(sheetData.male_other_back_vacancies || 0) || "";

    // TotalFemale reserve
    const totalFemaleReserve = 
    parseInt(sheetData.elected_female_reserve || 0)+ parseInt(sheetData.committee_female_reserve || 0) || "";

    // TotalFemaleScheduleCast reserve
    const TotalFemaleScheduleCast = 
    parseInt(sheetData.elected_female_schedule_cast || 0)+parseInt(sheetData.committee_female_schedule_cast || 0) || "";

     // TotalFemaleScheduleCast reserve
     const TotalElectedFemaleSchedule = 
     parseInt(sheetData.elected_female_scheduled_tribe || 0) + parseInt(sheetData.committee_female_schedule_tribe || 0) || "";
     // Totalelected_female_other_back reserve
     const TotalElectedFemaleOther = 
     parseInt(sheetData.elected_female_other_back || 0) + parseInt(sheetData.committee_female_other_back || 0) || "";
  return (
    <>
      <Helmet>
        <title>ग्रामपंचायत रचना दर्शविणारे</title>
      </Helmet>
      <div className={`sheet_bg_color_pdf container-fluid`}>
        <div className={`row`}>
          <div className="col-lg-12">
            <div className="table-main-sec">
              <div className="table_top">
                <div
                  className={`table_title_top mt-3 justify-content-center ${
                    !economicData ? "gap-4" : "gap-1"
                  }`}
                >
                  <h4 className="fw-bold">ग्रामपंचायतीची रचना दर्शविणारे</h4>
                  <h6 className={styles.patrak_heading}>पत्रक- अ (ए)</h6>
                  {economicData === "Y" ? (
                    <span className="drafted_text ms-3">
                      <FaEdit /> Drafted
                    </span>
                  ) : economicData === "N" ? (
                    <span className="submit_text ms-3">
                      Submitted{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                  <div className={styles.grmapnchyat_start}>
                    <div>
                    <p>ग्रामपंचयात स्थापना ता. | |</p>
                    <p>ग्रामपंचयात कार्यक्षेत्रफळ चौ.कि.मी.</p>
                    </div>
                  </div>
                </div>
                <form action="" className={styles.first_table}>
                  <table className={`${styles.table_bordered}`}>
                    <thead>
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <th scope="col" rowSpan={3}>
                          अनु क्रमांक
                        </th>
                        <th scope="col" rowSpan={3}>
                          पंचयात समितीचे नाव
                        </th>
                        <th scope="col" rowSpan={3}>
                          ग्रामपंचायतीचे नाव
                        </th>
                        <th scope="col" rowSpan={3}>
                          सन १९९१ च्या जनगणनेनुसार लोकसंख्या
                        </th>
                        <th scope="col" className="text-center" colSpan={8}>
                          ग्रामपंचयातची रचना
                        </th>
                        <th scope="col" rowSpan={3}>
                          राखून ठेवलेल्या जागांची संख्या कॉ.नं.६ अ,ब,क,ड + ७,८,९
                        </th>
                        <th scope="col" rowSpan={3}>
                          सहकारी सदस्याकरिता जागा
                        </th>
                        <th scope="col" rowSpan={3}>
                          एकूण जागांची संख्या कॉ.नं. ५+१०+११
                        </th>
                        <th scope="col" rowSpan={3}>
                          अहवाल सालात ग्रा.पं.ची निवडणूक झाली अगर कसे
                        </th>
                        <th scope="col" rowSpan={3}>
                          पोट निवडणूक झाली अगर कसे
                        </th>
                        <th scope="col" colSpan={3}>
                          निवडणूक आलेल्या किवा नेमलेल्या सदस्यांची संख्या
                        </th>
                        <th scope="col" className="text-center" colSpan={4}>
                          स्त्री राखीव
                        </th>
                      </tr>
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <th rowSpan={2}>राखीव नसलेल्या जागांची संख्या </th>
                        <th colSpan={4} className="text-center">
                          स्त्री राखीव
                        </th>
                        <th rowSpan={2}>अनु. जाती पुरुष</th>
                        <th rowSpan={2}>अनु. जमाती पुरुष</th>
                        <th rowSpan={2}>इतर मागास पुरुष</th>
                        <th colSpan={3} className="text-center">
                          बिगर राखीव जागा
                        </th>
                        <th colSpan={4} className="text-center">
                          निवडून आलेल्या स्त्री सदस्य
                        </th>
                      </tr>
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <th>स्त्री राखीव</th>
                        <th>स्त्री अनु. जाती</th>
                        <th> स्त्री अनु. जमाती</th>
                        <th>स्त्री इतर मागास</th>
                        <th>निवडून आलेले सदस्य</th>
                        <th>स्थायी समितीने नेमलेले सदस्य</th>
                        <th>एकूण कॉलम नंबर १५+१६</th>
                        <th>स्त्री राखीव</th>
                        <th>स्त्री अनु. जाती</th>
                        <th> स्त्री अनु. जमाती</th>
                        <th>स्त्री इतर मागास</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <td>१</td>
                        <td>२</td>
                        <td>३</td>
                        <td>४</td>
                        <td>५</td>
                        <td>६ अ</td>
                        <td>६ ब</td>
                        <td>६ क</td>
                        <td>६ ड</td>
                        <td>७</td>
                        <td>८</td>
                        <td>९</td>
                        <td>१०</td>
                        <td>११</td>
                        <td>१२</td>
                        <td>१३</td>
                        <td>१४</td>
                        <td>१५</td>
                        <td>१६</td>
                        <td>१७</td>
                        <td>१८ अ</td>
                        <td>१८ ब</td>
                        <td>१८ क</td>
                        <td>१८ ड</td>
                      </tr>
                      {/* <tr
                        className={`${styles.text_font_padding} ${styles.common_tr_border}`}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr> */}
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <td style={{fontSize:"1em"}}>
                        {sheetData.sr_no}
                        </td>
                        <td style={{fontSize:"1em"}}>
                          {/* <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={sheetData.panchayat_name}
                            readOnly
                          /> */}
                          {sheetData.panchayat_name}
                        </td>
                        <td style={{fontSize:"1em"}}>
                        {sheetData.gpt_name}
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.population_as_per_census
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.population_as_per_census
                                ? errors.population_as_per_census
                                : ""
                            }
                            name="population_as_per_census"
                            value={sheetData.population_as_per_census}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.population_as_per_census.length <= 6
                                ? "100%"
                                : `${sheetData.population_as_per_census.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.no_of_unreserved_seats ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.no_of_unreserved_seats
                                ? errors.no_of_unreserved_seats
                                : ""
                            }
                            name="no_of_unreserved_seats"
                            value={sheetData.no_of_unreserved_seats}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.no_of_unreserved_seats.length <= 3
                                ? "100%"
                                : `${sheetData.no_of_unreserved_seats.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_reserve ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.female_reserve ? errors.female_reserve : ""
                            }
                            name="female_reserve"
                            value={sheetData.female_reserve}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.female_reserve.length <= 3
                                ? "100%"
                                : `${sheetData.female_reserve.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_schedule_caste ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.female_schedule_caste
                                ? errors.female_schedule_caste
                                : ""
                            }
                            name="female_schedule_caste"
                            value={sheetData.female_schedule_caste}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.female_schedule_caste.length <= 3
                                ? "100%"
                                : `${sheetData.female_schedule_caste.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_scheduled_tribes ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.female_scheduled_tribes
                                ? errors.female_scheduled_tribes
                                : ""
                            }
                            name="female_scheduled_tribes"
                            value={sheetData.female_scheduled_tribes}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.female_scheduled_tribes.length <= 3
                                ? "100%"
                                : `${sheetData.female_scheduled_tribes.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_other_back ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.female_other_back
                                ? errors.female_other_back
                                : ""
                            }
                            name="female_other_back"
                            value={sheetData.female_other_back}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.female_other_back.length <= 3
                                ? "100%"
                                : `${sheetData.female_other_back.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.scheduled_caste_male ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.scheduled_caste_male
                                ? errors.scheduled_caste_male
                                : ""
                            }
                            name="scheduled_caste_male"
                            value={sheetData.scheduled_caste_male}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.scheduled_caste_male.length <= 3
                                ? "100%"
                                : `${sheetData.scheduled_caste_male.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.scheduled_tribe_male ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.scheduled_tribe_male
                                ? errors.scheduled_tribe_male
                                : ""
                            }
                            name="scheduled_tribe_male"
                            value={sheetData.scheduled_tribe_male}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.scheduled_tribe_male.length <= 3
                                ? "100%"
                                : `${sheetData.scheduled_tribe_male.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.male_other_back ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.scheduled_tribe_male
                                ? errors.male_other_back
                                : ""
                            }
                            name="male_other_back"
                            value={sheetData.male_other_back}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.male_other_back.length <= 3
                                ? "100%"
                                : `${sheetData.male_other_back.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalUnreservedSeat}
                            readOnly
                            style={{
                              width: totalUnreservedSeat.toString().length <= 3 ? "100%" : `${totalUnreservedSeat.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.associate_member_space ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.associate_member_space
                                ? errors.associate_member_space
                                : ""
                            }
                            name="associate_member_space"
                            value={sheetData.associate_member_space}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.associate_member_space.length <= 3
                                ? "100%"
                                : `${sheetData.associate_member_space.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalNumberOfArea}
                            readOnly
                            style={{
                              width: totalNumberOfArea.toString().length <= 3 ? "100%" : `${totalNumberOfArea.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.gp_election_reporting ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.gp_election_reporting
                                ? errors.gp_election_reporting
                                : ""
                            }
                            name="gp_election_reporting"
                            value={sheetData.gp_election_reporting}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.gp_election_reporting.length <= 3
                                ? "100%"
                                : `${sheetData.gp_election_reporting.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.stolen_elections ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.stolen_elections
                                ? errors.stolen_elections
                                : ""
                            }
                            name="stolen_elections"
                            value={sheetData.stolen_elections}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.stolen_elections.length <= 3
                                ? "100%"
                                : `${sheetData.stolen_elections.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.elected_members ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.elected_members
                                ? errors.elected_members
                                : ""
                            }
                            name="elected_members"
                            value={sheetData.elected_members}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.elected_members.length <= 3
                                ? "100%"
                                : `${sheetData.elected_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.appointed_members ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.appointed_members
                                ? errors.appointed_members
                                : ""
                            }
                            name="appointed_members"
                            value={sheetData.appointed_members}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width: sheetData.appointed_members.length <= 3
                                ? "100%"
                                : `${sheetData.appointed_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalUnreserved}
                            style={{
                              width: totalUnreserved.toString().length <= 3 ? "100%" : `${totalUnreserved.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.elected_female_reserve ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.elected_female_reserve
                                ? errors.elected_female_reserve
                                : ""
                            }
                            name="elected_female_reserve"
                            value={sheetData.elected_female_reserve}
                            onChange={handleChange}
                            style={{
                              width: sheetData.elected_female_reserve.length <= 3
                                ? "100%"
                                : `${sheetData.elected_female_reserve.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.elected_female_schedule_cast
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.elected_female_schedule_cast
                                ? errors.elected_female_schedule_cast
                                : ""
                            }
                            name="elected_female_schedule_cast"
                            value={sheetData.elected_female_schedule_cast}
                            onChange={handleChange}
                            style={{
                              width: sheetData.elected_female_schedule_cast.length <= 3
                                ? "100%"
                                : `${sheetData.elected_female_schedule_cast.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.elected_female_scheduled_tribe
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.elected_female_scheduled_tribe
                                ? errors.elected_female_scheduled_tribe
                                : ""
                            }
                            name="elected_female_scheduled_tribe"
                            value={sheetData.elected_female_scheduled_tribe}
                            onChange={handleChange}
                            style={{
                              width: sheetData.elected_female_scheduled_tribe.length <= 3
                                ? "100%"
                                : `${sheetData.elected_female_scheduled_tribe.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.elected_female_other_back
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.elected_female_other_back
                                ? errors.elected_female_other_back
                                : ""
                            }
                            name="elected_female_other_back"
                            value={sheetData.elected_female_other_back}
                            onChange={handleChange}
                            style={{
                              width: sheetData.elected_female_other_back.length <= 3
                                ? "100%"
                                : `${sheetData.elected_female_other_back.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      {/* <tr
                        className={`${styles.text_font_padding} ${styles.common_tr_border}`}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr> */}
                    </tbody>
                  </table>
                </form>
              </div>
              {/* second table */}
              <div className={`margin_class mt-5 pb-5 ${styles.patrak1}`}>
                <form action="">
                  <table className={` table-bordered `}>
                    <thead>
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <th scope="col" colSpan={4}>
                          स्थायी समितीने नेमलेल्या स्त्री सदस्य
                        </th>
                        <th scope="col" className="text-center" colSpan={4}>
                          एकूण
                        </th>
                        <th scope="col" className="text-center" colSpan={3}>
                          अनुसूचीत जाती
                        </th>
                        <th scope="col" className="text-center" colSpan={3}>
                          अनुसूचीत जामाती
                        </th>
                        <th scope="col" className="text-center" colSpan={3}>
                          इतर मागास
                        </th>
                        <th scope="col" rowSpan={3}>
                          निवडून आलेल्या सदस्याची संख्या कॉलम नंबर
                          (१५+१८+२१+२४+२७)
                        </th>
                        <th scope="col" rowSpan={3}>
                          स्थायी समितीने नेमलेल्या सदस्याची संख्या कॉ.नं.
                          (१६+१९+२२+२५+२८)
                        </th>
                        <th scope="col" rowSpan={2} style={{ width: "20px" }}>
                          <div className={styles.rotate_column_text1}>
                            सह सदस्याची संख्या
                          </div>
                        </th>
                        <th scope="col" rowSpan={3}>
                          निवडून आलेल्या सह सदस्याची संख्या कॉलम नंबर (३०+३१+३२)
                        </th>
                        <th scope="col" className="text-center" colSpan={9}>
                          रिक्त असलेल्या जागांची संख्या
                        </th>
                        <th scope="col" rowSpan={3}>
                          शेरा
                        </th>
                      </tr>
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <th>स्त्री राखीव</th>
                        <th>स्त्री अनु. जाती</th>
                        <th> स्त्री अनु. जमाती</th>
                        <th>स्त्री इतर मागास</th>

                        <th>स्त्री राखीव</th>
                        <th>स्त्री अनु. जाती</th>
                        <th>स्त्री अनु. जमाती</th>
                        <th>स्त्री इतर मागास</th>

                        <th>निवडून आलेले सदस्य</th>
                        <th>स्थायी समितीने नेमलेले सदस्य</th>
                        <th>एकूण कॉलम नंबर २१+२२ </th>

                        <th>निवडून आलेले सदस्य</th>
                        <th>स्थायी समितीने नेमलेले सदस्य</th>
                        <th>एकूण कॉलम नंबर २४+२५ </th>

                        <th>निवडून आलेले सदस्य</th>
                        <th>स्थायी समितीने नेमलेले सदस्य</th>
                        <th>एकूण कॉलम नंबर २७+२८ </th>

                        <th>
                          <div className={styles.rotate_column_text1}>
                            सर्वसाधारण
                          </div>
                        </th>
                        <th>
                          <div className={styles.rotate_column_text1}>
                            स्त्री राखीव{" "}
                          </div>
                        </th>
                        <th>
                          <div className={styles.rotate_column_text1}>
                            {" "}
                            स्त्री अनु. जाती{" "}
                          </div>
                        </th>
                        <th>
                          <div className={styles.rotate_column_text1}>
                            {" "}
                            स्त्री अनु. जमाती
                          </div>{" "}
                        </th>
                        <th>
                          <div className={styles.rotate_column_text1}>
                            {" "}
                            स्त्री इतर मागास{" "}
                          </div>
                        </th>
                        <th>
                          {" "}
                          <div className={styles.rotate_column_text1}>
                            पुरुष अनु. जाती
                          </div>
                        </th>
                        <th>
                          <div className={styles.rotate_column_text1}>
                            {" "}
                            पुरुष अनु. जमाती{" "}
                          </div>
                        </th>
                        <th>
                          <div className={styles.rotate_column_text1}>
                            पुरुष इतर मागास{" "}
                          </div>
                        </th>
                        <th>
                          <div className={styles.rotate_column_text1}>एकूण</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <td>१९ अ</td>
                        <td>१९ ब</td>
                        <td>१९ क</td>
                        <td>१९ ड</td>
                        <td>२० अ</td>
                        <td>२० ब</td>
                        <td>२० क</td>
                        <td>२० ड</td>
                        <td>२१</td>
                        <td>२२</td>
                        <td>२३</td>
                        <td>२४</td>
                        <td>२५</td>
                        <td>२६</td>
                        <td>२७</td>
                        <td>२८</td>
                        <td>२९</td>
                        <td>३०</td>
                        <td>३१</td>
                        <td>३२</td>
                        <td>३३</td>
                        <td>३४</td>
                        <td>३५</td>
                        <td>३६</td>
                        <td>३७</td>
                        <td>३८</td>
                        <td>३९</td>
                        <td>४०</td>
                        <td>४१</td>
                        <td>४२</td>
                        <td>४३</td>
                      </tr>
                      {/* <tr
                        className={`${styles.text_font_padding} ${styles.common_tr_border}`}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr> */}
                      <tr
                        className={`${styles.text_font} ${styles.common_tr_border}`}
                      >
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.committee_female_reserve
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.committee_female_reserve
                                ? errors.committee_female_reserve
                                : ""
                            }
                            name="committee_female_reserve"
                            value={sheetData.committee_female_reserve}
                            onChange={handleChange}
                            style={{
                              width: sheetData.committee_female_reserve.length <= 3
                                ? "100%"
                                : `${sheetData.committee_female_reserve.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.committee_female_schedule_cast
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.committee_female_schedule_cast
                                ? errors.committee_female_schedule_cast
                                : ""
                            }
                            name="committee_female_schedule_cast"
                            value={sheetData.committee_female_schedule_cast}
                            onChange={handleChange}
                            style={{
                              width: sheetData.committee_female_schedule_cast.length <= 3
                                ? "100%"
                                : `${sheetData.committee_female_schedule_cast.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.committee_female_schedule_tribe
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.committee_female_schedule_tribe
                                ? errors.committee_female_schedule_tribe
                                : ""
                            }
                            name="committee_female_schedule_tribe"
                            value={sheetData.committee_female_schedule_tribe}
                            onChange={handleChange}
                            style={{
                              width: sheetData.committee_female_schedule_tribe.length <= 3
                                ? "100%"
                                : `${sheetData.committee_female_schedule_tribe.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.committee_female_other_back
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.committee_female_other_back
                                ? errors.committee_female_other_back
                                : ""
                            }
                            name="committee_female_other_back"
                            value={sheetData.committee_female_other_back}
                            onChange={handleChange}
                            style={{
                              width: sheetData.committee_female_other_back.length <= 3
                                ? "100%"
                                : `${sheetData.committee_female_other_back.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalFemaleReserve}
                            style={{
                              width: totalFemaleReserve.toString().length <= 3 ? "100%" : `${totalFemaleReserve.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={TotalFemaleScheduleCast}
                            style={{
                              width: TotalFemaleScheduleCast.toString().length <= 3 ? "100%" : `${TotalFemaleScheduleCast.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                                                        
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={TotalElectedFemaleSchedule}
                            style={{
                              width: TotalElectedFemaleSchedule.toString().length <= 3 ? "100%" : `${TotalElectedFemaleSchedule.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={TotalElectedFemaleOther}
                            style={{
                              width: TotalElectedFemaleOther.toString().length <= 3 ? "100%" : `${TotalElectedFemaleOther.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.schedule_elected_members
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.schedule_elected_members
                                ? errors.schedule_elected_members
                                : ""
                            }
                            name="schedule_elected_members"
                            value={sheetData.schedule_elected_members}
                            onChange={handleChange}
                            style={{
                              width: sheetData.schedule_elected_members.length <= 3
                                ? "100%"
                                : `${sheetData.schedule_elected_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.schedule_appointed_members
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.schedule_appointed_members
                                ? errors.schedule_appointed_members
                                : ""
                            }
                            name="schedule_appointed_members"
                            value={sheetData.schedule_appointed_members}
                            onChange={handleChange}
                            style={{
                              width: sheetData.schedule_appointed_members.length <= 3
                                ? "100%"
                                : `${sheetData.schedule_appointed_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalSchedule}
                            style={{
                              width: totalSchedule.toString().length <= 3 ? "100%" : `${totalSchedule.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.schedule_tribe_members ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.schedule_tribe_members
                                ? errors.schedule_tribe_members
                                : ""
                            }
                            name="schedule_tribe_members"
                            value={sheetData.schedule_tribe_members}
                            onChange={handleChange}
                            style={{
                              width: sheetData.schedule_tribe_members.length <= 3
                                ? "100%"
                                : `${sheetData.schedule_tribe_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.schedule_tribe_appointed_members
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.schedule_tribe_appointed_members
                                ? errors.schedule_tribe_appointed_members
                                : ""
                            }
                            name="schedule_tribe_appointed_members"
                            value={sheetData.schedule_tribe_appointed_members}
                            onChange={handleChange}
                            style={{
                              width: sheetData.schedule_tribe_members.length <= 3
                                ? "100%"
                                : `${sheetData.schedule_tribe_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalScheduleTribe}
                            style={{
                              width: totalScheduleTribe.toString().length <= 3 ? "30px" : `${totalScheduleTribe.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_back_members ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_back_members
                                ? errors.other_back_members
                                : ""
                            }
                            name="other_back_members"
                            value={sheetData.other_back_members}
                            onChange={handleChange}
                            style={{
                              width: sheetData.other_back_members.length <= 3
                                ? "100%"
                                : `${sheetData.other_back_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_back_appointed_members
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.other_back_appointed_members
                                ? errors.other_back_appointed_members
                                : ""
                            }
                            name="other_back_appointed_members"
                            value={sheetData.other_back_appointed_members}
                            onChange={handleChange}
                            style={{
                              width: sheetData.other_back_appointed_members.length <= 3
                                ? "100%"
                                : `${sheetData.other_back_appointed_members.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalOtherBackMember}
                            style={{
                              width: totalOtherBackMember.toString().length <= 3 ? "30px" : `${totalOtherBackMember.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalElectedMember}
                            style={{
                              width: totalElectedMember.toString().length <= 5 ? "100%" : `${totalElectedMember.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalCommitteeMember}
                            style={{
                              width: totalCommitteeMember.toString().length <= 5 ? "100%" : `${totalCommitteeMember.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.no_of_co_members ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.no_of_co_members
                                ? errors.no_of_co_members
                                : ""
                            }
                            name="no_of_co_members"
                            value={sheetData.no_of_co_members}
                            onChange={handleChange}
                            style={{
                              width: sheetData.no_of_co_members.length <= 1
                                ? "100%"
                                : `${sheetData.no_of_co_members.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalElectedCoMember}
                            style={{
                              width: totalElectedCoMember.toString().length <= 5 ? "100%" : `${totalElectedCoMember.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.general ? "is-invalid" : ""
                            }`}
                            placeholder={errors.general ? errors.general : ""}
                            name="general"
                            value={sheetData.general}
                            onChange={handleChange}
                            style={{
                              width: sheetData.general.length <= 1
                                ? "100%"
                                : `${sheetData.general.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_reserve_vacancies
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.female_reserve_vacancies
                                ? errors.female_reserve_vacancies
                                : ""
                            }
                            name="female_reserve_vacancies"
                            value={sheetData.female_reserve_vacancies}
                            onChange={handleChange}
                            style={{
                              width: sheetData.female_reserve_vacancies.length <= 1
                                ? "100%"
                                : `${sheetData.female_reserve_vacancies.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_schedule_cast_vacancies
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.female_schedule_cast_vacancies
                                ? errors.female_schedule_cast_vacancies
                                : ""
                            }
                            name="female_schedule_cast_vacancies"
                            value={sheetData.female_schedule_cast_vacancies}
                            onChange={handleChange}
                            style={{
                              width: sheetData.female_schedule_cast_vacancies.length <= 1
                                ? "100%"
                                : `${sheetData.female_schedule_cast_vacancies.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_schedule_tribe_vacancies
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.female_schedule_tribe_vacancies
                                ? errors.female_schedule_tribe_vacancies
                                : ""
                            }
                            name="female_schedule_tribe_vacancies"
                            value={sheetData.female_schedule_tribe_vacancies}
                            onChange={handleChange}
                            style={{
                              width: sheetData.female_schedule_tribe_vacancies.length <= 1
                                ? "100%"
                                : `${sheetData.female_schedule_tribe_vacancies.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.female_other_back_vacancies
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.female_other_back_vacancies
                                ? errors.female_other_back_vacancies
                                : ""
                            }
                            name="female_other_back_vacancies"
                            value={sheetData.female_other_back_vacancies}
                            onChange={handleChange}
                            style={{
                              width: sheetData.female_other_back_vacancies.length <= 1
                                ? "100%"
                                : `${sheetData.female_other_back_vacancies.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.male_schedule_cast_vacancies
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.male_schedule_cast_vacancies
                                ? errors.male_schedule_cast_vacancies
                                : ""
                            }
                            name="male_schedule_cast_vacancies"
                            value={sheetData.male_schedule_cast_vacancies}
                            onChange={handleChange}
                            style={{
                              width: sheetData.male_schedule_cast_vacancies.length <= 1
                                ? "100%"
                                : `${sheetData.male_schedule_cast_vacancies.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.male_schedule_tribe_vacancies
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.male_schedule_tribe_vacancies
                                ? errors.male_schedule_tribe_vacancies
                                : ""
                            }
                            name="male_schedule_tribe_vacancies"
                            value={sheetData.male_schedule_tribe_vacancies}
                            onChange={handleChange}
                            style={{
                              width: sheetData.male_schedule_tribe_vacancies.length <= 1
                                ? "100%"
                                : `${sheetData.male_schedule_tribe_vacancies.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.male_other_back_vacancies
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.male_other_back_vacancies
                                ? errors.male_other_back_vacancies
                                : ""
                            }
                            name="male_other_back_vacancies"
                            value={sheetData.male_other_back_vacancies}
                            onChange={handleChange}
                            style={{
                              width: sheetData.male_other_back_vacancies.length <= 1
                                ? "100%"
                                : `${sheetData.male_other_back_vacancies.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalVacancies}
                            readOnly
                            style={{
                              width: totalVacancies.toString().length <= 1 ? "100%" : `${totalVacancies.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.shera ? "is-invalid" : ""
                            }`}
                            placeholder={errors.shera ? errors.shera : ""}
                            name="shera"
                            value={sheetData.shera}
                            onChange={handleChange}
                            style={{
                              width: sheetData.shera.length <= 1
                                ? "100%"
                                : `${sheetData.shera.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      {/* <tr
                        className={`${styles.text_font_padding} ${styles.common_tr_border}`}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr> */}
                    </tbody>
                  </table>
                </form>
              </div>
              <div className={styles.form_btn_space}>
                <div className="col-lg-12 d-flex justify-content-end align-items=center">
                    <FormBtn
                      handleSubmit={handleSubmit}
                      resetForm={handleSubmit}
                      resetButton={true}
                      handleDraft={handleDraftSheetA}
                    />
                </div>
                <div className="">
                  <div className={styles.btn_position}>
                    {currentStep > 1 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handlePrevStep}
                      >
                        Previous
                      </Button>
                    )}

                    {currentStep < 10 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handleNextStep}
                      >
                        Next
                      </Button>
                    )}

                    {currentStep === 10 && (
                      <button
                        type="submit"
                        className={styles.btn_generate}
                        // onClick={handlePrint}
                      >
                        Generate Pdf
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* {economicData === "N" && (
                <div className="col-lg-12 mt-3">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )} */}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-center"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default SheetA;
