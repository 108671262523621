/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import style from "./styles.module.css";

import { ToastContainer, toast } from "react-toastify";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { Button } from "@mui/material";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import { Modal } from "react-bootstrap";
import { MdOutlineArrowDownward } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const SheetG = ({
  handleNextStep,
  handlePrevStep,
  currentStep,
  handleStepDetails,
}) => {
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [economicData, setEconomicData] = useState("");
  const [sheetData, setSheetData] = useState({
    sr_no: "1",
    panchayat_name: "",
    gpt_name: "",
    year_of_initial_arrears: "",
    year_demand: "",
    recovered_total_amount: "",
    recover_cd_sheet_col_thirtytwo: "",
    down_sink_dropped_amount: "",
    previous_arrears: "",
    current_demand_arrears: "",
    total_arrears: "",
    recovery_rate_quantity: "",
    shera: "",
  });

  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_g",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetG response", response.data.data);
      if (response.data.status === true) {
        const data = response.data.data.form_g_data;
        const otherData = response.data.data.other_data;

        const defaultSheetData = {
          sr_no: "1",
          panchayat_name: otherData.taluka_name,
          gpt_name: otherData.village_name,
          recover_cd_sheet_col_thirtytwo:
            response.data.data.total_deposit_fourteen || "",
        };
        if (data !== null) {
          setSheetData({
            ...defaultSheetData,
            year_of_initial_arrears: data.year_of_initial_arrears,
            year_demand: data.year_demand,
            recovered_total_amount: data.recovered_total_amount,
            down_sink_dropped_amount: data.down_sink_dropped_amount,
            previous_arrears: data.previous_arrears,
            current_demand_arrears: data.current_demand_arrears,
            total_arrears: "",
            recovery_rate_quantity: "",
            shera: data.shera,
          });
        } else {
          setSheetData({
            ...defaultSheetData,
            year_of_initial_arrears: "",
            year_demand: "",
            recovered_total_amount: "",
            recover_cd_sheet_col_thirtytwo: "",
            down_sink_dropped_amount: "",
            previous_arrears: "",
            current_demand_arrears: "",
            total_arrears: "",
            recovery_rate_quantity: "",
            shera: "",
          });
        }
      }

      if (
        response.data.data.form_g_data === null ||
        response.data.data.form_g_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_g_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value }));
  };

  // table validation
  const validateForm = () => {
    const newErrors = {};

    // Validate tabels all input

    if (
      sheetData.year_of_initial_arrears !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.year_of_initial_arrears)
    ) {
      newErrors.year_of_initial_arrears = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.year_demand !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.year_demand)
    ) {
      newErrors.year_demand = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.recovered_total_amount !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.recovered_total_amount)
    ) {
      newErrors.recovered_total_amount = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.down_sink_dropped_amount !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.down_sink_dropped_amount)
    ) {
      newErrors.down_sink_dropped_amount = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.previous_arrears !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.previous_arrears)
    ) {
      newErrors.previous_arrears = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.current_demand_arrears !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.current_demand_arrears)
    ) {
      newErrors.current_demand_arrears = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.shera !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.shera)
    ) {
      newErrors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // update api

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_g_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          year_of_initial_arrears: sheetData.year_of_initial_arrears,
          year_demand: sheetData.year_demand,
          recovered_total_amount: sheetData.recovered_total_amount,
          recover_cd_sheet_col_thirtytwo: "",
          down_sink_dropped_amount: sheetData.down_sink_dropped_amount,
          previous_arrears: sheetData.previous_arrears,
          current_demand_arrears: sheetData.current_demand_arrears,
          total_arrears: totalArrears,
          recovery_rate_quantity: totalRecoveryPercentage,
          shera: sheetData.shera,
        },
      };
      console.log("update finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_g",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetG response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // draft api
  const handleDraftSheetG = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_g_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          year_of_initial_arrears: sheetData.year_of_initial_arrears,
          year_demand: sheetData.year_demand,
          recovered_total_amount: sheetData.recovered_total_amount,
          recover_cd_sheet_col_thirtytwo:
            sheetData.recover_cd_sheet_col_thirtytwo,
          down_sink_dropped_amount: sheetData.down_sink_dropped_amount,
          previous_arrears: sheetData.previous_arrears,
          current_demand_arrears: sheetData.current_demand_arrears,
          total_arrears: totalArrears,
          recovery_rate_quantity: totalRecoveryPercentage,
          shera: sheetData.shera,
        },
      };
      console.log("draftSheetG finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_g",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetG response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // totalArrears
  const totalArrears =
    parseInt(sheetData.previous_arrears || 0) +
      parseInt(sheetData.current_demand_arrears || 0) || "";

  // totalRecoveryPercentage
  const totalRecoveryPercentage =
    (parseInt(sheetData.recover_cd_sheet_col_thirtytwo) /
      parseInt(sheetData.recovered_total_amount)) *
      100 || "";

  return (
    <>
      <Helmet>
        <title>पत्रक - जी</title>
      </Helmet>
      <div
        id="table-container"
        className={`container-fluid sheet_bg_color_pdf mb-5 pt-0`}
      >
        <div className={`row `}>
          <div className="col-lg-12">
            <div className="table-main-sec">
              <div className="table_top">
                <div className={`${style.patrak_title_top}`}>
                  <p className="mb-4">
                    कर व फी यांची मागणी, वसूली व थकबाकी दर्शविणारे पत्रक
                    <span className="patrak_box">पत्रक- 'जी'</span>
                  </p>
                  {economicData === "Y" ? (
                    <span className="drafted_text text-danger mt-3">
                      <FaEdit /> Drafted
                    </span>
                  ) : economicData === "N" ? (
                    <span className="table_title_top gap-0 submit_text1s">
                      Submitted{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <form action="">
                  <table className={`w-100 ${style.table_bordered} `}>
                    <thead className={`${style.custom_thead}`}>
                      <tr className={` ${style.common_tr_border}`}>
                        <th rowSpan={2} className="text-center">
                          अ.क्र
                        </th>
                        <th rowSpan={2} className="text-center">
                          पंचायत समितीचे <br /> नांव
                        </th>
                        <th rowSpan={2} className="text-center">
                          ग्रामपंचायतीचे <br /> नांव
                        </th>
                        <th rowSpan={2} className="text-center">
                          वर्षाच्या <br /> प्रारंभीची <br /> येणे असलेली <br />{" "}
                          थकबाकी
                        </th>
                        <th rowSpan={2} className="text-center">
                          वर्षासाठी केलेली <br /> मागणी
                        </th>
                        <th rowSpan={2} className="text-center">
                          वसूल करावयाच्या <br /> एकूण रकमा
                        </th>
                        <th rowSpan={2} className="text-center">
                          वसूल केलेली रक्कम <br /> (सी आणि डी पत्रकातील <br />{" "}
                          कॉलम नं. ३२)
                        </th>
                        <th rowSpan={2} className="text-center">
                          बुडीत खाली <br /> टाकलेली <br /> रक्कम
                        </th>
                        <th colSpan={3} className="text-center">
                          वर्षाच्या अखेरीस असलेली थकबाकी
                        </th>
                        <th rowSpan={2} className="text-center">
                          वसूली शेकडा <br /> प्रमाण
                        </th>
                        <th rowSpan={2} className="text-center">
                          शेरा
                        </th>
                      </tr>
                      <tr className={` ${style.common_tr_border}`}>
                        <th className="text-center">
                          मागील <br /> थकबाकी
                        </th>
                        <th className="text-center">
                          चालू मागणीची <br /> थकबाकी
                        </th>
                        <th className="text-center">एकूण थकबाकी</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>१</td>
                        <td>२</td>
                        <td>३</td>
                        <td>४</td>
                        <td>५</td>
                        <td>६</td>
                        <td>७</td>
                        <td>८</td>
                        <td>९</td>
                        <td>१०</td>
                        <td>११</td>
                        <td>१२</td>
                        <td>१३</td>
                      </tr>
                      <tr
                        className={`${style.custom_tr_second} ${style.common_tr_border}`}
                      >
                        <td>{sheetData.sr_no}</td>
                        <td>{sheetData.panchayat_name}</td>
                        <td>{sheetData.gpt_name}</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.year_of_initial_arrears ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.year_of_initial_arrears
                                ? errors.year_of_initial_arrears
                                : ""
                            }
                            name="year_of_initial_arrears"
                            value={sheetData.year_of_initial_arrears}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.year_of_initial_arrears.length <= 6
                                  ? "100%"
                                  : `${
                                      sheetData.year_of_initial_arrears.length *
                                      10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.year_demand ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.year_demand ? errors.year_demand : ""
                            }
                            name="year_demand"
                            value={sheetData.year_demand}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.year_demand.length <= 6
                                  ? "100%"
                                  : `${sheetData.year_demand.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.recovered_total_amount ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.recovered_total_amount
                                ? errors.recovered_total_amount
                                : ""
                            }
                            name="recovered_total_amount"
                            value={sheetData.recovered_total_amount}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.recovered_total_amount.length <= 6
                                  ? "100%"
                                  : `${
                                      sheetData.recovered_total_amount.length *
                                      10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={
                              sheetData.recover_cd_sheet_col_thirtytwo || ""
                            }
                            readOnly
                            style={{
                              width:
                                sheetData.recover_cd_sheet_col_thirtytwo
                                  .length <= 6
                                  ? "100%"
                                  : `${
                                      sheetData.recover_cd_sheet_col_thirtytwo
                                        .length * 10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.down_sink_dropped_amount
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.down_sink_dropped_amount
                                ? errors.down_sink_dropped_amount
                                : ""
                            }
                            name="down_sink_dropped_amount"
                            value={sheetData.down_sink_dropped_amount}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.down_sink_dropped_amount.length <= 6
                                  ? "100%"
                                  : `${
                                      sheetData.down_sink_dropped_amount
                                        .length * 10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.previous_arrears ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.previous_arrears
                                ? errors.previous_arrears
                                : ""
                            }
                            name="previous_arrears"
                            value={sheetData.previous_arrears}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.previous_arrears.length <= 6
                                  ? "100%"
                                  : `${
                                      sheetData.previous_arrears.length * 10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.current_demand_arrears ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.current_demand_arrears
                                ? errors.current_demand_arrears
                                : ""
                            }
                            name="current_demand_arrears"
                            value={sheetData.current_demand_arrears}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.current_demand_arrears.length <= 6
                                  ? "100%"
                                  : `${
                                      sheetData.current_demand_arrears.length *
                                      10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalArrears}
                            readOnly
                            style={{
                              width:
                                totalArrears.toString().length <= 2
                                  ? "30px"
                                  : `${totalArrears.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={
                              typeof totalRecoveryPercentage === "number"
                                ? totalRecoveryPercentage.toFixed(2)
                                : ""
                            }
                            readOnly
                            style={{
                              width:
                                totalRecoveryPercentage.toString().length <= 2
                                  ? "30px"
                                  : `${
                                      totalRecoveryPercentage.toString()
                                        .length * 12
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.shera ? "is-invalid" : ""
                            }`}
                            placeholder={errors.shera ? errors.shera : ""}
                            name="shera"
                            value={sheetData.shera}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.shera.length <= 3
                                  ? "100%"
                                  : `${sheetData.shera.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                      </tr>
                      {/* <tr
                        className={`${style.custom_tr_second} ${style.common_tr_border}`}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr> */}
                    </tbody>
                  </table>
                </form>
              </div>
              <div className={style.form_btn_space_f}>
                <div className="col-lg-12 d-flex justify-content-end align-items=center">
                  <FormBtn
                    handleSubmit={handleSubmit}
                    resetForm={handleSubmit}
                    resetButton={true}
                    handleDraft={handleDraftSheetG}
                  />
                </div>
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {" "}
                      सूचना <MdOutlineArrowDownward />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                    Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit
                    करू इच्छिता?
                  </Modal.Body>
                  <Modal.Footer className="gap-3">
                    <Button
                      className="modal-button-cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-button-submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>

                <div className="">
                  <div
                    style={{ marginLeft: "8px" }}
                    className={style.btn_position}
                  >
                    {currentStep > 1 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handlePrevStep}
                      >
                        Previous
                      </Button>
                    )}

                    {currentStep < 8 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handleNextStep}
                      >
                        Next
                      </Button>
                    )}

                    {currentStep === 8 && (
                      <button
                        type="submit"
                        className={style.btn_generate}
                        // onClick={handlePrint}
                      >
                        Generate Pdf
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-center"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default SheetG;
