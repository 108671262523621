/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import pulseIcon from "../../component/assets/images/logo/plus-removebg-preview.png";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import style from "./style.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AiFillDelete } from "react-icons/ai";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import Loader from "../../component/Loader/Loader";

const GramMetting = () => {
  // add new row in table
  const [draftShow, setDraftShow] = useState(true);
  const [resetShow, setResetShow] = useState(true);
  const [rows, setRows] = useState([{}]);
  const [monthMeet, SetMonthMeet] = useState("");
  const [date, SetDate] = useState("");
  const [newMonthMeet, SetNewMonthMeet] = useState("");
  const [newDate, SetNewDate] = useState("");
  const [monthMeetingTableData, setMonthMeetingTableData] = useState([]);
  const [monthMeetingData, setMonthMeetingData] = useState("");
  const [updatedRows, setUpdatedRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [month, setMonth] = useState("");

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const months = [
    "जानेवारी",
    "फेब्रुवारी",
    "मार्च",
    "एप्रिल",
    "मे",
    "जून",
    "जुलै",
    "ऑगस्ट",
    "सप्टेंबर",
    "ऑक्टोंबर",
    "नोव्हेंबर",
    "डिसेंबर",
  ];

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);

    // Set the updated rows to state
    setUpdatedRows(updatedRows);

    const errors = validateRow(updatedRows[index]);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;
    setValidationErrors(updatedValidationErrors);
  };

  const validateRow = (row) => {
    const errors = {};
    if (!row.month) {
      errors.month = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.month)) {
      errors.month = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.date) {
      errors.date = "महत्वाचे आहे";
    } else if (/^(\d{2})-(\d{2})-(\d{4})$/.test(row.date)) {
      errors.date = "अयोग्य दिनांक";
    }
    return errors;
  };

  const validateRow1 = (row) => {
    const errors = {};

    if (row.month !== "" && !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.month)) {
      errors.month = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (row.date !== "" && /^(\d{2})-(\d{2})-(\d{4})$/.test(row.date)) {
      errors.date = "अयोग्य दिनांक";
    }

    return errors;
  };

  // ग्रामसभा  Get Information api

  const handleGramMeetingDetails = async () => {
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/gram_sabha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("GramMeetingDetails response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      setEconomicData(response.data.data.gram_sabha_data[0].is_draft);
      setMonthMeetingData(response.data.data.gram_sabha_data);
      setMonthMeetingTableData(response.data.data.gram_sabha_data);
      setMonth(response.data.data.month);
      // updated date show in inputs

      // SetMonthMeet(response.data.data.monthly_meeting_data[0].month);
      // SetDate(response.data.data.monthly_meeting_data[0].date);
      // const initialRows = response.data.data.gram_sabha_data.map((item) => ({
      //   id: item.id,
      //   month: item.month,
      //   date: item.date,
      // }));
      // setRows(initialRows);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleGramMeetingDetails();
  }, []);

  const handleDataRefresh = async () => {
    await handleGramMeetingDetails();
  };

  // reset the data
  const handleDataRefreshReset = async () => {
    if (monthMeetingData.length > 0) {
      await handleGramMeetingDetails();
      setValidationErrors("");
      setRows([{}]);
    } else {
      setRows([{}]);
      setValidationErrors("");
    }
  };

  // ग्रामसभा   Update Information api

  const handleGramMeetingUpdate = async () => {
    // e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        gram_sabha_data: rows,
      };
      console.log("updateGramMeeting finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_gram_sabha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateGramMeeting response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
          setRows([{}]);
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };

  const handleUpdateButtonClick = () => {
    handleGramMeetingUpdate(rows);
  };

  // मासिक सभा  Draft Information api

  const handleGramMeetingDraft = async (e) => {
    e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow1(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        gram_sabha_data: rows.map((row) => ({
          id: row.id || "",
          month: row.month || "",
          date: row.date || "",
        })),
      };
      console.log("DraftGramMeeting finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_gram_sabha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftGramMeeting response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // add child in table
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const renderRows = () => {
    const usedMonths = monthMeetingTableData.map((ele) => ele.month);
    return rows.length < 0 ? (
      <tr>
        <td colSpan={9} className="text-center fs-5">
          no data found, please insert data in table
        </td>
      </tr>
    ) : (
      rows.map((rowData, index) => {
        const selectedMonth = rowData.month || "";
        const selectedYear = new Date().getFullYear();
        const numberOfDays = daysInMonth(
          selectedYear,
          months.indexOf(selectedMonth)
        );

        const dateOptions = Array.from({ length: numberOfDays }, (_, i) => {
          const day = String(i + 1).padStart(2, "0");
          const formattedMonth = String(
            months.indexOf(selectedMonth) + 1
          ).padStart(2, "0");
          return `${selectedYear}-${formattedMonth}-${day}`;
        });

        const availableMonths = months.filter(
          (month) => !usedMonths.includes(month)
        );

        return (
          <tr key={index}>
            <td>
              <select
                name="month"
                value={selectedMonth}
                onChange={(e) => handleInputChange(e, index)}
                style={{
                  appearance: validationErrors[index]?.month ? "none" : "auto",
                }}
                className={`form-control w-100 table-input-box pb-0 ${
                  validationErrors[index]?.month ? "is-invalid" : ""
                }`}
              >
                <option value="">महिना निवडा</option>
                {availableMonths.map((month, idx) => (
                  <option key={idx} value={month} className="fw-bold">
                    {month}
                  </option>
                ))}
              </select>
            </td>
            <td>
              <select
                name="date"
                value={rowData.date || ""}
                onChange={(e) => handleInputChange(e, index)}
                max={getCurrentDate()}
                className={`form-control table-input-box ${
                  validationErrors[index]?.date ? "is-invalid" : ""
                }`}
              >
                <option value="" className="fw-bold">
                  तारीख निवडा
                </option>
                {dateOptions.map((dateString, idx) => {
                  const parts = dateString.split("-"); // Split the date string into parts
                  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Reformat the date string
                  return (
                    <option key={idx} value={dateString}>
                      {formattedDate}
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
        );
      })
    );
  };

  // delete entry
  const handleDelete = async (id) => {
    let confirm = window.confirm("Are you sure want to delete these !");
    if (!confirm) {
      return;
    }
    try {
      const finalData = {
        id: id,
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/delete_gram_sabha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
        const filteredData = rows.filter((item) => item.id !== id);
        setRows(filteredData);
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>मासिक सभा / ग्रामसभा</title>
          </Helmet>
          <div className="container common-section">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">१.५ ग्रामसभा</p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="arrow-box ">
                    <Link to="/month-meeting" className="me-5 text-dark">
                      १) मासिक सभा दिनांक (सन{" "}
                      {yearInfo && yearInfo !== null
                        ? yearInfo.current_year_name
                        : "N/A"}
                      )
                    </Link>
                  </div>
                  <div className="arrow-box-active d-flex me-5 flex-column justify-content-center">
                    <Link to="/gram-meeting" className="text-decoration-none">
                      २) ग्रामसभा दिनांक (सन{" "}
                      {yearInfo && yearInfo !== null
                        ? yearInfo.current_year_name
                        : "N/A"}
                      )
                    </Link>
                    <span className="text-center me-4 arrow-icon">
                      <ArrowDropDownIcon fontSize="large" color="primary" />
                    </span>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="col-lg-12 mt-4 mb-4">
                <Village_Tal_Dist_Mahe
                  villageDataInfo={villageDataInfo}
                  month={month}
                />
              </div>
              <div className="col-lg-12 mt-4">
                <div className="table-division d-flex">
                  <div className="col-lg-6">
                    <div className="employeesInFigure table_title_top table-box border d-flex align-items-center">
                      <label>
                        ग्रामसभा दिनांक (सन{" "}
                        {yearInfo && yearInfo !== null
                          ? yearInfo.current_year_name
                          : "N/A"}
                        ){" "}
                      </label>
                      {economicData === "Y" ? (
                        <span className="drafted_text">
                          <FaEdit /> Drafted
                        </span>
                      ) : economicData === "N" ? (
                        <span className="submit_text">
                          Submitted{" "}
                          <img
                            src={require("../../component/assets/images/sumbit-success.png")}
                            alt="success"
                            width={30}
                            className="img-fluid"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-5 mb-4">
                <div className="table-main-sec">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">माहे</th>
                          <th scope="col">दिनांक</th>
                        </tr>
                      </thead>
                      <tbody>
                        {monthMeetingTableData.map((ele, index) => {
                          const parts = ele.date.split("-"); // Split the date string into parts
                          const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Format the date to "YYYY-MM-DD" format
                          return (
                            <tr key={index}>
                              <td className="ps-4">{ele.month}</td>
                              <td>{formattedDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tbody>{renderRows()}</tbody>
                      {/* <div className="plus-icon">
                        <img
                          src={pulseIcon}
                          width={50}
                          className="mt-3"
                          alt="plusIcon"
                          onClick={handleAddRow}
                        />
                      </div> */}
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
                <FormBtn
                  handleSubmit={handleUpdateButtonClick}
                  handleDraft={handleGramMeetingDraft}
                  draftButton={draftShow}
                  resetShow={resetShow}
                  resetForm={handleDataRefreshReset}
                />
              </div>
            </div>
            <ToastContainer
              position={"top-right"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default GramMetting;
