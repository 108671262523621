import React from "react";

const FooterDevOfficer = ({ villageDataInfo }) => {
  return (
    <div className="page-one-footer-sec float-end">
      <div className="section-one">
        <p>ग्रामविकास अधिकारी / ग्रामसेवक</p>
      </div>
      <div className="section-two d-flex gap-3">
        <p>
          ता.{" "}
          <span className="fw-bold">
            {" "}
            {villageDataInfo === undefined ? (
              <span></span>
            ) : (
              <span>{villageDataInfo.taluka_name}</span>
            )}
          </span>
        </p>
        <p>
          जि.{" "}
          <span className="fw-bold">
            {" "}
            {villageDataInfo === undefined ? (
              <span></span>
            ) : (
              <span>{villageDataInfo.district_name}</span>
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

export default FooterDevOfficer;
