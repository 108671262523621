/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import style from "./style.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const DistrictExpense = () => {
  const [districtInfo, setDistrictInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  //  १५% मागासवर्गीय खर्चाची तरतूद  input filed calculation

  const [expenseAData, setExpenseAData] = useState([]);
  const [expenseBData, setExpenseBData] = useState([]);
  const [expenseCData, setExpenseCData] = useState([]);

  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  useEffect(() => {
    handleExpenseDetails();
  }, [talId, villageId, month]);

  const handleExpenseDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month,
      };
      const response = await axios.post(
        API_BASE_URL + "api/summary_expense",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("district expense response", response);

      // first table data
      const expenseAData = response.data.data.expense_a;
      if (expenseAData && typeof expenseAData === "object") {
        // Check if properties exist before accessing them
        if (
          expenseAData.economic_e_data &&
          Array.isArray(expenseAData.economic_e_data) &&
          expenseAData.expense_a_data &&
          Array.isArray(expenseAData.expense_a_data) &&
          expenseAData.last_month_total &&
          Array.isArray(expenseAData.last_month_total)
        ) {
          // Concatenate the arrays
          const totalData = [
            ...expenseAData.economic_e_data,
            ...expenseAData.expense_a_data,
            ...expenseAData.last_month_total,
          ];
          // Merge properties into a single object using the spread operator
          const mergedArray = totalData.reduce(
            (result, obj) => ({ ...result, ...obj }),
            []
          );
          setExpenseAData(mergedArray);
        } else {
          console.error("Invalid structure in sub-properties of expenseAData");
        }
      } else {
        console.error("Invalid structure in expenseAData");
      }

      // second table data
      const expenseBData = response.data.data.expense_b;
      if (expenseBData && typeof expenseBData === "object") {
        // Check if properties exist before accessing them
        if (
          expenseBData.economic_e_data &&
          Array.isArray(expenseBData.economic_e_data) &&
          expenseBData.expense_b_data &&
          Array.isArray(expenseBData.expense_b_data) &&
          expenseBData.last_month_total &&
          Array.isArray(expenseBData.last_month_total)
        ) {
          // Concatenate the arrays
          const totalData = [
            ...expenseBData.economic_e_data,
            ...expenseBData.expense_b_data,
            ...expenseBData.last_month_total,
          ];
          // Merge properties into a single object using the spread operator
          const mergedArray = totalData.reduce(
            (result, obj) => ({ ...result, ...obj }),
            []
          );
          setExpenseBData(mergedArray);
        } else {
          console.error("Invalid structure in sub-properties of expenseBData");
        }
      } else {
        console.error("Invalid structure in expenseAData");
      }

      // third table data
      const expenseCData = response.data.data.expense_c;
      if (expenseCData && typeof expenseCData === "object") {
        // Check if properties exist before accessing them
        if (
          expenseCData.economic_e_data &&
          Array.isArray(expenseCData.economic_e_data) &&
          expenseCData.expense_c_data &&
          Array.isArray(expenseCData.expense_c_data) &&
          expenseCData.last_month_total &&
          Array.isArray(expenseCData.last_month_total)
        ) {
          // Concatenate the arrays
          const totalData = [
            ...expenseCData.economic_e_data,
            ...expenseCData.expense_c_data,
            ...expenseCData.last_month_total,
          ];
          // Merge properties into a single object using the spread operator
          const mergedArray = totalData.reduce(
            (result, obj) => ({ ...result, ...obj }),
            []
          );
          setExpenseCData(mergedArray);
        } else {
          console.error("Invalid structure in sub-properties of expenseCData");
        }
      } else {
        console.error("Invalid structure in expenseAData");
      }

      setDistrictInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
    } catch (error) {
      console.log("error occured fieching the data", error);
    }
  };

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };


  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id:userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>मागासवर्गीय खर्चाची तरतूद</title>
      </Helmet>
      <div className="container-fluid common-section common_deputy_container pt-0">
        <div className="row common_deputy_row">
          <div className="col-lg-12 navigate-section-header mt-3">
            <div className="page-title">
              <p className="fw-bold mb-2"># खर्च तरतूद</p>
            </div>
            <div className="navigate-section d-flex">
              <div className="d-flex flex-column justify-content-center">
                <div className={`${style.select_group_top}`}>
                  <label>जिल्हा: </label>
                  <span className="fw-bold">{districtInfo.district_name}</span>
                </div>
              </div>
              <div className="VillagePanchayat ms-5 d-flex gap-2 justify-content-center align-items-center">
                <label htmlFor="">माहे :</label>
                {month === undefined ? (
                  <span></span>
                ) : (
                  <select
                    name="month"
                    value={month}
                    onChange={(e) => setMonth(parseInt(e.target.value))}
                    className="taluka_select_input"
                  >
                    <option value="1">जानेवारी</option>
                    <option value="2">फेब्रुवारी</option>
                    <option value="3">मार्च</option>
                    <option value="4">एप्रिल</option>
                    <option value="5">मे</option>
                    <option value="6">जून</option>
                    <option value="7">जुलै</option>
                    <option value="8">ऑगस्ट</option>
                    <option value="9">सप्टेंबर</option>
                    <option value="10">ऑक्टोंबर</option>
                    <option value="11">नोव्हेंबर</option>
                    <option value="12">डिसेंबर</option>
                  </select>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">तालुका: </label>
                  <select
                    name="taluka"
                    className="taluka_select_input"
                    onChange={(e) => handleOnchangeTaluka(e, talId)}
                    value={talId}
                  >
                    {userDetails.role_id === "7" ? <option>निवडा</option> : ""}
                    {tal.map((ele) => {
                      return (
                        <option key={ele.id} value={ele.id}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">ग्रामपंचयात: </label>
                  <Autocomplete
                    disablePortal
                    className={`${style.select_common_dropdown}`}
                    options={village}
                    sx={{ width: 200 }}
                    onChange={(e, selectedOption) =>
                      handleVillage(selectedOption)
                    }
                    value={
                      villageId
                        ? village.find((v) => v.value === villageId) || null
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="निवडा" />
                    )}
                  />
                </div>
              </div>
            </div>
            {/* <TabEco/> */}
          </div>
          <div className="col-lg-12 mt-5" id="pdf-content">
            <div className="table-main-sec">
              <div className="table_title_top">
                <p>अ) १५% मागासवर्गीय खर्चाची तरतूद </p>
              </div>
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="pdf-table"
                >
                  <thead>
                    <tr>
                      <th scope="col">अ.नं.</th>
                      <th scope="col">मागील अनुशेष</th>
                      <th scope="col">
                        चालू तरतूद <br /> (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        एकूण तरतूद <br />
                        (मागील + चालू) (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">खर्च</th>
                      <th scope="col">बाब</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseAData ? (
                      <tr>
                        <td>1</td>
                        <td>{expenseAData.backlog}</td>
                        <td>{expenseAData.current_provision}</td>
                        <td className="fw-bold">{expenseAData.total_provision}</td>
                        <td>{expenseAData.total_expenses}</td>
                        <td>{expenseAData.matter}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                      </tr>
                    )}

                    {/* {expenseAData || expenseAData.length > 0 ? (
                      expenseAData.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td>{ele.type}</td>
                            <td>{ele.backlog}</td>
                            <td>{ele.current_provision}</td>
                            <td>{ele.total_provision}</td>
                            <td>{ele.expenses}</td>
                            <td>{ele.matter}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                      </tr>
                    )} */}
                  </tbody>
                </table>
              </div>
              <p>ब) १0% महिला व बालकल्याण खर्चाची तरतूद </p>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.नं.</th>
                      <th scope="col">मागील अनुशेष</th>
                      <th scope="col">
                        चालू तरतूद <br /> (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        एकूण तरतूद <br />
                        (मागील + चालू) (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">खर्च</th>
                      <th scope="col">बाब</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseBData ? (
                      <tr>
                        <td>1</td>
                        <td>{expenseBData.backlog}</td>
                        <td>{expenseBData.current_provision}</td>
                        <td className="fw-bold">{expenseBData.total_provision}</td>
                        <td>{expenseBData.total_expenses}</td>
                        <td>{expenseBData.matter}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {/* {expenseBData.length <= 0 ? (
                      <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                      </tr>
                    ) : (
                      expenseBData.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td>{ele.type}</td>
                            <td>{ele.backlog}</td>
                            <td>{ele.current_provision}</td>
                            <td>{ele.total_provision}</td>
                            <td>{ele.expenses}</td>
                            <td>{ele.matter}</td>
                          </tr>
                        );
                      })
                    )} */}
                  </tbody>
                </table>
              </div>
              <p>क) ५% अपंग खर्चाची तरतूद </p>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.नं.</th>
                      <th scope="col">मागील अनुशेष</th>
                      <th scope="col">
                        चालू तरतूद <br /> (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        एकूण तरतूद <br />
                        (मागील + चालू) (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">खर्च</th>
                      <th scope="col">बाब</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseCData ? (
                      <tr>
                        <td>1</td>
                        <td>{expenseCData.backlog}</td>
                        <td>{expenseCData.current_provision}</td>
                        <td className="fw-bold">{expenseCData.total_provision}</td>
                        <td >{expenseCData.total_expenses}</td>
                        <td>{expenseCData.matter}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {/* {expenseCData.length <= 0 ? (
                      <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                      </tr>
                    ) : (
                      expenseCData.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td>{ele.type === null ? "1" : ele.type}</td>
                            <td>
                              {ele.backlog === null ? "N/A" : ele.backlog}
                            </td>
                            <td>
                              {ele.current_provision === null
                                ? "N/A"
                                : ele.current_provision}
                            </td>
                            <td>
                              {ele.total_provision === null
                                ? "N/A"
                                : ele.total_provision}
                            </td>
                            <td>
                              {ele.expenses === null ? "N/A" : ele.expenses}
                            </td>
                            <td>{ele.matter === null ? "N/A" : ele.matter}</td>
                          </tr>
                        );
                      })
                    )} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </React.Fragment>
  );
};

export default DistrictExpense;
