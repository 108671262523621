import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../Gharkul.css";
import "../../GeneralAdministrationDepartment/EconomicsYear.css";
import DataTable from "react-data-table-component";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import "../districtGharkul/districtGharkul.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";

const VistarGharkul = () => {
  const navigate = useNavigate("");
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [uploadFileData, setUploadFileData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [downloadSampleFileUrl, setDownloadSampleFileUrl] = useState("");
  const [base64Data, setBase64Data] = useState("");
  const [type, setType] = useState("");
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [talId, setTalId] = useState("");
  const [villageId, setVillageId] = useState("");
  const [gharkul, setGharkul] = useState([]);
  const [gharkulData, setGharkulData] = useState("");
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  // get data on the server
  // useEffect(() => {
  //   handleGharkulDetails();
  // }, []);

  // const handleGharkulDetails = async () => {
  //   try {
  //     const finalData = {
  //       village_id: villageId,
  //       taluka_id: talId,
  //       district_id: userDetails.district_id,
  //       year_id: userDetails.current_year_id,
  //     };
  //     const response = await axios.post(
  //       API_BASE_URL + "api/summary_gharkul",
  //       finalData,
  //       {
  //         auth: {
  //           username: API_AUTH_USERNAME,
  //           password: API_AUTH_PASSWORD,
  //         },
  //       }
  //     );
  //     setIsLoading(false);
  //     console.log("gharkul summary response", response.data.data);
  //     setVillageDataInfo(response.data.data.other_data);
  //     setGharkul(response.data.data.gharkul_data);
  //     setGharkulData(response.data.data);
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response.data.data);
  //       console.log(error.response.status);
  //     }
  //   }
  // };

  // download sample file

  const handleDownloadSampleFile = async () => {
    try {
      const finalData = {};
      const response = await axios.post(
        API_BASE_URL + "gharkul/gharkul_sample_file",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      console.log("gharkul download sample file  response", response.data.data);
      setDownloadSampleFileUrl(response.data.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleDownloadSampleFile();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Content = e.target.result.split(",")[1];
        setBase64Data(base64Content);
      };

      reader.readAsDataURL(file);
    }
  };

  // Gharkul list

  const handleList = async () => {
    try {
      const finalData = {
        user_id: userDetails.id,
        month: month,
        type: type || "प्रधानमंत्री आवास योजना",
        // year_id: userDetails.current_year_id,
      };
      console.log("gharkul list finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "gharkul/gharkul_list",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      console.log("gharkul list  response", response.data);
      if (response.data.status === true) {
        setList(response.data.data);
        setFilteredData(response.data.data); // Initialize filteredData with the fetched data
      }
    } catch (error) {
      console.log(error.response.data.data);
    }
  };

  useEffect(() => {
    handleList();
  }, [type, month]);

  const handleRefresh = async () => {
    await handleList();
  };

  // upload file api

  const handleUploadFile = async (e) => {
    e.preventDefault();
    try {
      if (base64Data) {
        const data = {
          user_id: userDetails.id,
          gharkul_file: base64Data,
        };
        const response = await axios.post(
          API_BASE_URL + "gharkul/gharkul_data_upload",
          data,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("upload gharkul file response", response);
        setUploadFileData(response.data);
        handleRefresh();
        // if (response.data.status === true) {
        //   toast.success(response.data.message);
        // }
      } else {
        toast.error("Please select an Excel file before uploading.");
      }
    } catch (error) {
      console.log("error occurred in add imp places", error);
      // Handle general error
      toast.error("An error occurred while uploading the Excel file.");
    }
  };

  const columns = [
    {
      name: "अ.क्र",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "लाभाथ्याचे नाव",
      selector: (row) => row.beneficiary_name,
      sortable: true,
    },
    {
      name: "ग्रा.पं.नाव",
      selector: (row) => row.gpt_name,
      sortable: true,
    },
    {
      name: "घरकुल स्थिती",
      selector: (row) =>
        row.house_status === null || row.house_status === ""
          ? "-"
          : row.house_status,
      sortable: true,
    },
    {
      name: "अखेर",
      selector: (row) =>
        row.akher === null || row.akher === "" ? "-" : row.akher,
      sortable: true,
    },
    {
      name: "फोटो",
      selector: (row) =>
        row.uploaded_photo === null || row.uploaded_photo === "" ? (
          "-"
        ) : (
          <Link to={row.uploaded_photo} target="_blank">
            <div className="upload_file_img_sec">
              <img src={row.uploaded_photo} alt="uploaded_doc" />
            </div>
          </Link>
        ),
      sortable: true,
    },
    {
      name: "डॉक्युमेंट",
      selector: (row) =>
        row.uploaded_doc === null || row.uploaded_doc === "" ? (
          "-"
        ) : (
          <Link to={row.uploaded_doc} target="_blank">
            <div className="upload_file_img_sec">
              <img src={row.uploaded_doc} alt="uploaded_doc" />
            </div>
          </Link>
          // <Link
          //   to={row.uploaded_doc}
          //   target="_blank"
          //   className="upload_file_img_sec"
          // >
          //   <img src={row.uploaded_doc} alt="uploaded_doc" />
          // </Link>
        ),
      sortable: true,
    },
    {
      name: "शेरा",
      selector: (row) =>
        row.comment === null || row.comment === "" ? "-" : row.comment,
      sortable: true,
    },
  ];

  // Implement custom search
  useEffect(() => {
    const filteredItems = list.filter((item) =>
      item.beneficiary_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filteredItems);
  }, [list, searchText]);

  // const handleSearch = (searchText) => {
  //   const filteredItems = list.filter(
  //     (item) =>
  //       item.beneficiary_name
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase()) ||
  //       item.gpt_name.toLowerCase().includes(searchText.toLowerCase()) ||
  //       item.house_status.toLowerCase().includes(searchText.toLowerCase()) ||
  //       item.akher.toLowerCase().includes(searchText.toLowerCase()) ||
  //       item.uploaded_photo.toLowerCase().includes(searchText.toLowerCase()) ||
  //       item.uploaded_doc.toLowerCase().includes(searchText.toLowerCase()) ||
  //       item.comment.toLowerCase().includes(searchText.toLowerCase())
  //   );

  //   setFilteredData(filteredItems);
  // };

  const customStyles = {
    table: {
      style: {
        border: "1px solid #d9d9d9",
        // fontFamily: "Poppins",
      },
    },
    headRow: {
      style: {
        border: "none",
        fontFamily: "Noto Serif Devanagari",
        fontWeight: "600",
        fontSize: "17px",
        color: "#fff",
        backgroundColor: "#625c5c",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #d9d9d9",
        // fontFamily: "Noto Serif Devanagari",
        fontWeight: "600",
        fontSize: "14px",
      },
    },
  };

  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>घरकुल विभाग</title>
        </Helmet>
        <div className="container common-section">
          <div className="row">
            <div className="col-lg-12 home_navbar_sec justify-content-start mt-3">
              <div className="page-title">
                <p className="fw-bold mb-2"># घरकुल विभाग </p>
              </div>
              {/* <div className="navigate-section d-flex">
                <div className="d-flex flex-column justify-content-center">
                  <div className={`${style.select_group_top}`}>
                    <label>जिल्हा: </label>
                    <span className="fw-bold">
                      {villageDataInfo.district_name}
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row ps-0 pe-0  upload_excel_row">
              {/* <div className="col-lg-12 ps-0  pe-0">
                <div className="download_btn_sample_sec">
                  <Link
                    to={downloadSampleFileUrl}
                    className="btn download_btn_sample"
                    target="_blank"
                    download
                  >
                    Download Sample File
                  </Link>
                </div>
              </div> */}
              <div className="col-lg-12 ps-0  pe-0">
                <div className="excel_upload_to_api">
                  <form className="home_file_form">
                    <input
                      type="file"
                      accept=".xls, .xlsx"
                      className="form-control upload_home_file_input"
                      onChange={handleFileChange}
                    />
                    <button
                      className="btn upload_home_file_btn"
                      onClick={handleUploadFile}
                      disabled={!base64Data}
                    >
                      Upload
                    </button>
                  </form>
                  <div className="download_btn_sample_sec">
                    <Link
                      to={downloadSampleFileUrl}
                      className="btn download_btn_sample"
                      target="_blank"
                      download
                    >
                      Download Sample File
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="upload_file_info">
                  {uploadFileData.status === false &&
                    (uploadFileData.message2 || uploadFileData.message) && (
                      <div>
                        <h6 className="upload_message_title">अपलोड समस्या</h6>
                        {uploadFileData.message && (
                          <p className="upload_message_error">
                            {uploadFileData.message}
                          </p>
                        )}
                        {uploadFileData.message2 && (
                          <p className="upload_message_error">
                            {uploadFileData.message2}
                          </p>
                        )}
                      </div>
                    )}
                  {uploadFileData.status === true && (
                    <>
                      <h6 className="upload_message_title">अपलोड यशस्वी</h6>
                      {uploadFileData.message && (
                        <p className="upload_message_success">
                          {uploadFileData.message}
                        </p>
                      )}
                    </>
                  )}

                  {uploadFileData.data && (
                    <div className="upload_count_main">
                      <div className="file_upload_count_section">
                        <p className="upload_count_title">
                          यशस्वी अपलोड संख्या :-
                        </p>{" "}
                        <span className="upload_count_info_success">
                          {uploadFileData.data.successful_upload_count}
                        </span>
                      </div>
                      <div className="file_upload_count_section">
                        <p className="upload_count_title">
                          अयशस्वी अपलोड संख्या :-
                        </p>{" "}
                        <span className="upload_count_info_error">
                          {uploadFileData.data.failed_upload_count}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="table_dropdown_with_title">
                  <h6 className="table_list_title">लाभार्थी डेटा</h6>
                  <div className="d-flex gap-3">
                    <label className="" htmlFor="">
                      प्रकार :
                    </label>
                    {type === undefined ? (
                      <span></span>
                    ) : (
                      <select
                        name="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        className="fw-bold "
                      >
                        <option value="प्रधानमंत्री आवास योजना">
                          प्रधानमंत्री आवास योजना
                        </option>
                        <option value="रमाई आवास योजना">रमाई आवास योजना</option>
                      </select>
                    )}
                  </div>
                  <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                    <label htmlFor="">माहे :</label>
                    {month === undefined ? (
                      <span></span>
                    ) : (
                      <select
                        name="month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                        className="fw-bold"
                      >
                        {/* <option value="" className="fw-bold">
                          महिना निवडा
                        </option> */}
                        <option value="1">जानेवारी</option>
                        <option value="2">फेब्रुवारी</option>
                        <option value="3">मार्च</option>
                        <option value="4">एप्रिल</option>
                        <option value="5">मे</option>
                        <option value="6">जून</option>
                        <option value="7">जुलै</option>
                        <option value="8">ऑगस्ट</option>
                        <option value="9">सप्टेंबर</option>
                        <option value="10">ऑक्टोंबर</option>
                        <option value="11">नोव्हेंबर</option>
                        <option value="12">डिसेंबर</option>
                      </select>
                    )}
                  </div>
                </div>
                <div className="search_input_main_gharkul">
                  <div className="search_input_home">
                    <input
                      type="text"
                      value={searchText}
                      placeholder="येथे शोधा"
                      className="table_list_input"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <span className="search_icon_home">
                      {!searchText && <IoIosSearch />}
                    </span>
                  </div>
                </div>

                <DataTable
                  // title="Beneficiary Data"
                  columns={columns}
                  data={filteredData}
                  pagination
                  highlightOnHover
                  // selectableRows
                  responsive
                  // searchable={true}
                  customStyles={customStyles}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 30]}
                  paginationTotalRows={filteredData.length}
                  // onSearch={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </React.Fragment>
    </div>
  );
};

export default VistarGharkul;
