// Loader.jsx
import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      {/* <img
        src={require("../../component/assets/images/loader.png")}
        alt="Loading"
        className="loader-image"
      /> */}
      <div className="spinner">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
};

export default Loader;
