import React, { useEffect, useState } from "react";
import style from "../style.module.css";
import { Button } from "@mui/material";
import FormBtn from "../../../component/buttons/Form/FormBtn";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import { FaEdit } from "react-icons/fa";

const WaterSupplySampleNoThree = ({
  handleNextStep,
  handlePrevStep,
  currentStep,
  handleStepDetails
}) => {
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [economicData, setEconomicData] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [sheetData, setSheetData] = useState({
    gpt_name: "",
    taluka_name: "",
    district_name: "",
    opening_balance: "",
    money_opening_balance: "",
    mumbai_district_civil: "",
    money_mumbai_district_civil: "",
    wahad_municipality: "",
    money_wahad_municipality: "",
    section_eight: "",
    money_section_eight: "",
    govt_distribute_amount: "",
    money_govt_distribute_amount: "",
    fee_under_section: "",
    money_fee_under_section: "",
    building_tax: "",
    money_building_tax: "",
    shungi: "",
    money_shungi: "",
    tax_on_pilgrims: "",
    money_tax_on_pilgrims: "",
    fairs_tax: "",
    money_fair_tax: "",
    lamp_tax: "",
    money_lamp_tax: "",
    shop_hotel_tax: "",
    money_shop_hotel_tax: "",
    mill_tax: "",
    money_mill_tax: "",
    broker_tax: "",
    money_broker_tax: "",
    general_health_tax: "",
    money_general_health_tax: "",
    special_health_tax: "",
    money_special_health_tax: "",
    common_watershed: "",
    money_common_watershed: "",
    special_watershed: "",
    money_special_watershed: "",
    market_tax: "",
    money_market_tax: "",
    carriage_fees: "",
    money_carriage_fees: "",
    other_water_charges: "",
    money_other_water_charges: "",
    road_space_rent: "",
    money_road_space_rent: "",
    garbage_clean_fee: "",
    money_garbage_clean_fee: "",
    locate_in_panchyat: "",
    money_locate_in_panchyat: "",
    cattle_grazing_fee: "",
    money_cattle_grazing_fee: "",
    other_tax_specified: "",
    money_other_tax_specified: "",
    maharashtra_section: "",
    money_maharashtra_section: "",
    business_transaction: "",
    money_business_transaction: "",
    income_on_job: "",
    money_income_on_job: "",
    sale_of_goods: "",
    money_sale_of_goods: "",
    dust_garbage: "",
    money_dust_garbage: "",
    carcases_of_animal: "",
    money_carcases_of_animal: "",
    irrelevant_property: "",
    money_irrelevant_property: "",
    // gst: "",
    // money_gst: "",
    // income_tax: "",
    // money_income_tax: "",
    // insurance: "",
    // money_insurance: "",
    // dmf_fund: "",
    // money_dmf_fund: "",
    state_govt: "",
    money_state_govt: "",
    land_revenue: "",
    money_land_revenue: "",
    equation: "",
    money_equation: "",
    stamp_duty: "",
    money_stamp_duty: "",
    cess: "",
    money_cess: "",
    light_bill: "",
    money_light_bill: "",
    financial_assistance: "",
    money_financial_assistance: "",
    jp_pt_form: "",
    money_jp_pt_form: "",
    employee_pay: "",
    money_employee_pay: "",
    other_contributions: "",
    money_other_contributions: "",
    fee_section_ninety: "",
    money_fee_section_ninety: "",
    fix_income_panchyat: "",
    money_fix_income_panchyat: "",
    building_rent: "",
    money_building_rent: "",
    space_rent: "",
    money_space_rent: "",
    kondwara: "",
    money_kondwara: "",
    donations: "",
    money_donations: "",
    fines_from_criminal: "",
    money_fines_from_criminal: "",
    section_one_twentyseven: "",
    money_section_one_twentyseven: "",
    other_amount_or_windmill: "",
    money_other_amount_or_windmill: "",
    loan: "",
    money_loan: "",
    receive_loan_govt: "",
    money_receive_loan_govt: "",
    jp_receive_loan: "",
    money_jp_receive_loan: "",
    jp_gram_loan_fund: "",
    money_jp_gram_loan_fund: "",
    works_devolved: "",
    money_works_devolved: "",
    section_fourtyeight_duties: "",
    money_section_fourtyeight_duties: "",
    jp_pt_receive_fund: "",
    money_jp_pt_receive_fund: "",
    // member_allowance: "",
    // money_member_allowance: "",
    loan_amount: "",
    money_loan_amount: "",
    total_amount_receive_year: "",
    money_total_amount_receive_year: "",
    total: "",
    money_total: "",
  });

  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_j",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetJ response", response.data);
      if (response.data.status === true) {
        const data = response.data.data.form_j_data;
        const otherData = response.data.data.other_data;
        setYearInfo(response.data.data.year_info);
        const defaultSheetData = {
          sr_no: "1",
          gpt_name: otherData.village_name,
          taluka_name: otherData.taluka_name,
          district_name: otherData.district_name,
          // ... (other fields with default values)
        };
        if (data !== null) {
          var parts = data.opening_balance.toString().split(".");
          var parts1 = data.mumbai_district_civil.toString().split(".");
          var parts2 = data.wahad_municipality.toString().split(".");
          var parts3 = data.section_eight.toString().split(".");
          var parts4 = data.govt_distribute_amount.toString().split(".");
          var parts5 = data.fee_under_section.toString().split(".");
          var parts6 = data.building_tax.toString().split(".");
          var parts7 = data.shungi.toString().split(".");
          var parts8 = data.tax_on_pilgrims.toString().split(".");
          var parts9 = data.fairs_tax.toString().split(".");
          var parts10 = data.lamp_tax.toString().split(".");
          var parts11 = data.shop_hotel_tax.toString().split(".");
          var parts12 = data.mill_tax.toString().split(".");
          var parts13 = data.broker_tax.toString().split(".");
          var parts14 = data.general_health_tax.toString().split(".");
          var parts15 = data.special_health_tax.toString().split(".");
          // console.log("parts15", parts15);
          var parts16 = data.common_watershed.toString().split(".");
          // console.log("parts16", parts16);

          var parts17 = data.special_watershed.toString().split(".");
          // console.log("parts17", parts17);

          var parts18 = data.market_tax.toString().split(".");
          // console.log("parts18", parts18);

          var parts19 = data.carriage_fees.toString().split(".");
          // console.log("parts19", parts19);

          var parts20 = data.other_water_charges.toString().split(".");
          // console.log("parts20", parts20);
          var parts21 = data.road_space_rent.toString().split(".");
          var parts22 = data.garbage_clean_fee.toString().split(".");
          var parts23 = data.locate_in_panchyat.toString().split(".");
          var parts24 = data.cattle_grazing_fee.toString().split(".");
          var parts25 = data.other_tax_specified.toString().split(".");
          // console.log("parts25", parts25);
          var parts26 = data.maharashtra_section.toString().split(".");
          // console.log("parts26", parts26);

          var parts27 = data.business_transaction.toString().split(".");
          // console.log("parts27", parts27);

          var parts28 = data.income_on_job.toString().split(".");
          // console.log("parts28", parts28);

          var parts29 = data.sale_of_goods.toString().split(".");
          // console.log("parts29", parts29);

          var parts30 = data.dust_garbage.toString().split(".");
          // console.log("parts30", parts30);

          var parts31 = data.carcases_of_animal.toString().split(".");
          // console.log("parts31", parts31);

          var parts32 = data.irrelevant_property.toString().split(".");
          // console.log("parts32", parts32);

          // var parts33 = data.gst.toString().split(".");
          // console.log("parts33", parts33);

          // var parts34 = data.income_tax.toString().split(".");
          // console.log("parts34", parts34);

          // var parts35 = data.insurance.toString().split(".");
          // console.log("parts35", parts35);
          // var parts36 = data.dmf_fund.toString().split(".");
          // console.log("parts36", parts36);

          var parts37 = data.state_govt.toString().split(".");
          // console.log("parts37", parts37);

          var parts38 = data.land_revenue.toString().split(".");
          // console.log("parts38", parts38);

          var parts39 = data.equation.toString().split(".");
          // console.log("parts39", parts39);

          var parts40 = data.stamp_duty.toString().split(".");
          // console.log("parts40", parts40);

          var parts41 = data.cess.toString().split(".");
          // console.log("parts41", parts41);
          var parts42 = data.light_bill.toString().split(".");
          // console.log("parts42", parts42);
          var parts43 = data.financial_assistance.toString().split(".");
          // console.log("parts43", parts43);
          var parts44 = data.jp_pt_form.toString().split(".");
          // console.log("parts44", parts44);
          var parts45 = data.other_contributions.toString().split(".");
          // console.log("parts45", parts45);
          var parts46 = data.fee_section_ninety.toString().split(".");
          // console.log("parts46", parts46);
          var parts47 = data.fix_income_panchyat.toString().split(".");
          // console.log("parts47", parts47);
          var parts48 = data.building_rent.toString().split(".");
          // console.log("parts48", parts48);
          var parts49 = data.space_rent.toString().split(".");
          // console.log("parts49", parts49);
          var parts50 = data.kondwara.toString().split(".");
          // console.log("parts50", parts50);
          var parts51 = data.donations.toString().split(".");
          // console.log("parts51", parts51);

          var parts52 = data.fines_from_criminal.toString().split(".");
          // console.log("parts52", parts52);

          var parts53 = data.section_one_twentyseven.toString().split(".");
          // console.log("parts53", parts53);

          var parts54 = data.other_amount_or_windmill.toString().split(".");
          // console.log("parts54", parts54);

          var parts55 = data.loan.toString().split(".");
          // console.log("parts55", parts55);

          var parts56 = data.receive_loan_govt.toString().split(".");
          // console.log("parts56", parts56);
          var parts57 = data.jp_receive_loan.toString().split(".");
          // console.log("parts57", parts57);
          var parts58 = data.jp_gram_loan_fund.toString().split(".");
          // console.log("parts58", parts58);
          var parts59 = data.works_devolved.toString().split(".");
          // console.log("parts59", parts59);
          var parts60 = data.section_fourtyeight_duties.toString().split(".");
          // console.log("parts60", parts60);
          var parts61 = data.jp_pt_receive_fund.toString().split(".");
          // console.log("parts61", parts61);
          // var parts62 = data.member_allowance.toString().split(".");
          // console.log("parts62", parts62);
          var parts63 = data.loan_amount.toString().split(".");
          // console.log("parts63", parts63);
          var parts64 = data.employee_pay.toString().split(".");
          // console.log("parts64", parts64);

          setSheetData({
            ...defaultSheetData,
            opening_balance: parseInt(parts[0]) || "",
            money_opening_balance: parseInt(parts[1]) || "",
            mumbai_district_civil: parseInt(parts1[0]) || "",
            money_mumbai_district_civil: parseInt(parts1[1]) || "",
            wahad_municipality: parseInt(parts2[0]) || "",
            money_wahad_municipality: parseInt(parts2[1]) || "",
            section_eight: parseInt(parts3[0]) || "",
            money_section_eight: parseInt(parts3[1]) || "",
            govt_distribute_amount: parseInt(parts4[0]) || "",
            money_govt_distribute_amount: parseInt(parts4[1]) || "",
            fee_under_section: parseInt(parts5[0]) || "",
            money_fee_under_section: parseInt(parts5[1]) || "",
            building_tax: parseInt(parts6[0]) || "",
            money_building_tax: parseInt(parts6[1]) || "",
            shungi: parseInt(parts7[0]) || "",
            money_shungi: parseInt(parts7[1]) || "",
            tax_on_pilgrims: parseInt(parts8[0]) || "",
            money_tax_on_pilgrims: parseInt(parts8[1]) || "",
            fairs_tax: parseInt(parts9[0]) || "",
            money_fair_tax: parseInt(parts9[1]) || "",
            lamp_tax: parseInt(parts10[0]) || "",
            money_lamp_tax: parseInt(parts10[1]) || "",
            shop_hotel_tax: parseInt(parts11[0]) || "",
            money_shop_hotel_tax: parseInt(parts11[1]) || "",
            mill_tax: parseInt(parts12[0]) || "",
            money_mill_tax: parseInt(parts12[1]) || "",
            broker_tax: parseInt(parts13[0]) || "",
            money_broker_tax: parseInt(parts13[1]) || "",
            general_health_tax: parseInt(parts14[0]) || "",
            money_general_health_tax: parseInt(parts14[1]) || "",
            special_health_tax: parseInt(parts15[0]) || "",
            money_special_health_tax: parseInt(parts15[1]) || "",
            common_watershed: parseInt(parts16[0]) || "",
            money_common_watershed: parseInt(parts16[1]) || "",
            special_watershed: parseInt(parts17[0]) || "",
            money_special_watershed: parseInt(parts17[1]) || "",
            market_tax: parseInt(parts18[0]) || "",
            money_market_tax: parseInt(parts18[1]) || "",
            carriage_fees: parseInt(parts19[0]) || "",
            money_carriage_fees: parseInt(parts19[1]) || "",
            other_water_charges: parseInt(parts20[0]) || "",
            money_other_water_charges: parseInt(parts20[1]) || "",
            road_space_rent: parseInt(parts21[0]) || "",
            money_road_space_rent: parseInt(parts21[1]) || "",
            garbage_clean_fee: parseInt(parts22[0]) || "",
            money_garbage_clean_fee: parseInt(parts22[1]) || "",
            locate_in_panchyat: parseInt(parts23[0]) || "",
            money_locate_in_panchyat: parseInt(parts23[1]) || "",
            cattle_grazing_fee: parseInt(parts24[0]) || "",
            money_cattle_grazing_fee: parseInt(parts24[1]) || "",
            other_tax_specified: parseInt(parts25[0]) || "",
            money_other_tax_specified: parseInt(parts25[1]) || "",
            maharashtra_section: parseInt(parts26[0]) || "",
            money_maharashtra_section: parseInt(parts26[1]) || "",
            business_transaction: parseInt(parts27[0]) || "",
            money_business_transaction: parseInt(parts27[1]) || "",
            income_on_job: parseInt(parts28[0]) || "",
            money_income_on_job: parseInt(parts28[1]) || "",
            sale_of_goods: parseInt(parts29[0]) || "",
            money_sale_of_goods: parseInt(parts29[1]) || "",
            dust_garbage: parseInt(parts30[0]) || "",
            money_dust_garbage: parseInt(parts30[1]) || "",
            carcases_of_animal: parseInt(parts31[0]) || "",
            money_carcases_of_animal: parseInt(parts31[1]) || "",
            irrelevant_property: parseInt(parts32[0]) || "",
            money_irrelevant_property: parseInt(parts32[1]) || "",
            // gst: parseInt(parts33[0]) || "",
            // money_gst: parseInt(parts33[1]) || "",
            // income_tax: parseInt(parts34[0]) || "",
            // money_income_tax: parseInt(parts34[1]) || "",
            // insurance: parseInt(parts35[0]) || "",
            // money_insurance: parseInt(parts35[1]) || "",
            // dmf_fund: parseInt(parts36[0]) || "",
            // money_dmf_fund: parseInt(parts36[1]) || "",
            state_govt: parseInt(parts37[0]) || "",
            money_state_govt: parseInt(parts37[1]) || "",
            land_revenue: parseInt(parts38[0]) || "",
            money_land_revenue: parseInt(parts38[1]) || "",
            equation: parseInt(parts39[0]) || "",
            money_equation: parseInt(parts39[1]) || "",
            stamp_duty: parseInt(parts40[0]) || "",
            money_stamp_duty: parseInt(parts40[1]) || "",
            cess: parseInt(parts41[0]) || "",
            money_cess: parseInt(parts41[1]) || "",
            light_bill: parseInt(parts42[0]) || "",
            money_light_bill: parseInt(parts42[1]) || "",
            financial_assistance: parseInt(parts43[0]) || "",
            money_financial_assistance: parseInt(parts43[1]) || "",
            jp_pt_form: parseInt(parts44[0]) || "",
            money_jp_pt_form: parseInt(parts44[1]) || "",
            employee_pay: parseInt(parts64[0]) || "",
            money_employee_pay: parseInt(parts64[1]) || "",
            other_contributions: parseInt(parts45[0]) || "",
            money_other_contributions: parseInt(parts45[1]) || "",
            fee_section_ninety: parseInt(parts46[0]) || "",
            money_fee_section_ninety: parseInt(parts46[1]) || "",
            fix_income_panchyat: parseInt(parts47[0]) || "",
            money_fix_income_panchyat: parseInt(parts47[1]) || "",
            building_rent: parseInt(parts48[0]) || "",
            money_building_rent: parseInt(parts48[1]) || "",
            space_rent: parseInt(parts49[0]) || "",
            money_space_rent: parseInt(parts49[1]) || "",
            kondwara: parseInt(parts50[0]) || "",
            money_kondwara: parseInt(parts50[1]) || "",
            donations: parseInt(parts51[0]) || "",
            money_donations: parseInt(parts51[1]) || "",
            fines_from_criminal: parseInt(parts52[0]) || "",
            money_fines_from_criminal: parseInt(parts52[1]) || "",
            section_one_twentyseven: parseInt(parts53[0]) || "",
            money_section_one_twentyseven: parseInt(parts53[1]) || "",
            other_amount_or_windmill: parseInt(parts54[0]) || "",
            money_other_amount_or_windmill: parseInt(parts54[1]) || "",
            loan: parseInt(parts55[0]) || "",
            money_loan: parseInt(parts55[1]) || "",
            receive_loan_govt: parseInt(parts56[0]) || "",
            money_receive_loan_govt: parseInt(parts56[1]) || "",
            jp_receive_loan: parseInt(parts57[0]) || "",
            money_jp_receive_loan: parseInt(parts57[1]) || "",
            jp_gram_loan_fund: parseInt(parts58[0]) || "",
            money_jp_gram_loan_fund: parseInt(parts58[1]) || "",
            works_devolved: parseInt(parts59[0]) || "",
            money_works_devolved: parseInt(parts59[1]) || "",
            section_fourtyeight_duties: parseInt(parts60[0]) || "",
            money_section_fourtyeight_duties: parseInt(parts60[1]) || "",
            jp_pt_receive_fund: parseInt(parts61[0]) || "",
            money_jp_pt_receive_fund: parseInt(parts61[1]) || "",
            // member_allowance: parseInt(parts62[0]) || "",
            // money_member_allowance: parseInt(parts62[1]) || "",
            loan_amount: parseInt(parts63[0]) || "",
            money_loan_amount: parseInt(parts63[1]) || "",
            total_amount_receive_year: "",
            total: "",
          });
        } else {
          setSheetData({
            ...defaultSheetData,
            opening_balance: "",
            money_opening_balance: "",
            mumbai_district_civil: "",
            money_mumbai_district_civil: "",
            wahad_municipality: "",
            money_wahad_municipality: "",
            section_eight: "",
            money_section_eight: "",
            govt_distribute_amount: "",
            money_govt_distribute_amount: "",
            fee_under_section: "",
            money_fee_under_section: "",
            building_tax: "",
            money_building_tax: "",
            shungi: "",
            money_shungi: "",
            tax_on_pilgrims: "",
            money_tax_on_pilgrims: "",
            fairs_tax: "",
            money_fair_tax: "",
            lamp_tax: "",
            money_lamp_tax: "",
            shop_hotel_tax: "",
            money_shop_hotel_tax: "",
            mill_tax: "",
            money_mill_tax: "",
            broker_tax: "",
            money_broker_tax: "",
            general_health_tax: "",
            money_general_health_tax: "",
            special_health_tax: "",
            money_special_health_tax: "",
            common_watershed: "",
            money_common_watershed: "",
            special_watershed: "",
            money_special_watershed: "",
            market_tax: "",
            money_market_tax: "",
            carriage_fees: "",
            money_carriage_fees: "",
            other_water_charges: "",
            money_other_water_charges: "",
            road_space_rent: "",
            money_road_space_rent: "",
            garbage_clean_fee: "",
            money_garbage_clean_fee: "",
            locate_in_panchyat: "",
            money_locate_in_panchyat: "",
            cattle_grazing_fee: "",
            money_cattle_grazing_fee: "",
            other_tax_specified: "",
            money_other_tax_specified: "",
            maharashtra_section: "",
            money_maharashtra_section: "",
            business_transaction: "",
            money_business_transaction: "",
            income_on_job: "",
            money_income_on_job: "",
            sale_of_goods: "",
            money_sale_of_goods: "",
            dust_garbage: "",
            money_dust_garbage: "",
            carcases_of_animal: "",
            money_carcases_of_animal: "",
            irrelevant_property: "",
            money_irrelevant_property: "",

            state_govt: "",
            money_state_govt: "",
            land_revenue: "",
            money_land_revenue: "",
            equation: "",
            money_equation: "",
            stamp_duty: "",
            money_stamp_duty: "",
            cess: "",
            money_cess: "",
            light_bill: "",
            money_light_bill: "",
            financial_assistance: "",
            money_financial_assistance: "",
            jp_pt_form: "",
            money_jp_pt_form: "",
            employee_pay: "",
            money_employee_pay: "",
            other_contributions: "",
            money_other_contributions: "",
            fee_section_ninety: "",
            money_fee_section_ninety: "",
            fix_income_panchyat: "",
            money_fix_income_panchyat: "",
            building_rent: "",
            money_building_rent: "",
            space_rent: "",
            money_space_rent: "",
            kondwara: "",
            money_kondwara: "",
            donations: "",
            money_donations: "",
            fines_from_criminal: "",
            money_fines_from_criminal: "",
            section_one_twentyseven: "",
            money_section_one_twentyseven: "",
            other_amount_or_windmill: "",
            money_other_amount_or_windmill: "",
            loan: "",
            money_loan: "",
            receive_loan_govt: "",
            money_receive_loan_govt: "",
            jp_receive_loan: "",
            money_jp_receive_loan: "",
            jp_gram_loan_fund: "",
            money_jp_gram_loan_fund: "",
            works_devolved: "",
            money_works_devolved: "",
            section_fourtyeight_duties: "",
            money_section_fourtyeight_duties: "",
            jp_pt_receive_fund: "",
            money_jp_pt_receive_fund: "",
            loan_amount: "",
            money_loan_amount: "",
            total_amount_receive_year: "",
            money_total_amount_receive_year: "",
            total: "",
            money_total: "",
          });
        }
      }

      if (
        response.data.data.form_j_data === null ||
        response.data.data.form_j_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_j_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value }));
  };

  // table validation
  const validateForm = () => {
    const newErrors = {};

    // Validate tabels all input

    if (
      sheetData.opening_balance !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.opening_balance)
    ) {
      newErrors.opening_balance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.mumbai_district_civil !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.mumbai_district_civil)
    ) {
      newErrors.mumbai_district_civil = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.wahad_municipality !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.wahad_municipality)
    ) {
      newErrors.wahad_municipality = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.section_eight !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.section_eight)
    ) {
      newErrors.section_eight = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.govt_distribute_amount !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.govt_distribute_amount)
    ) {
      newErrors.govt_distribute_amount = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fee_under_section !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fee_under_section)
    ) {
      newErrors.fee_under_section = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.building_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.building_tax)
    ) {
      newErrors.building_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.shungi !== "" && !/^-?\d*\.?\d+$/.test(sheetData.shungi)) {
      newErrors.shungi = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_pilgrims !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_pilgrims)
    ) {
      newErrors.tax_on_pilgrims = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fairs_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fairs_tax)
    ) {
      newErrors.fairs_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.lamp_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.lamp_tax)
    ) {
      newErrors.lamp_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.shop_hotel_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.shop_hotel_tax)
    ) {
      newErrors.shop_hotel_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.mill_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.mill_tax)
    ) {
      newErrors.mill_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.broker_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.broker_tax)
    ) {
      newErrors.broker_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.general_health_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.general_health_tax)
    ) {
      newErrors.general_health_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.special_health_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.special_health_tax)
    ) {
      newErrors.special_health_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.common_watershed !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.common_watershed)
    ) {
      newErrors.common_watershed = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.special_watershed !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.special_watershed)
    ) {
      newErrors.special_watershed = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.market_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.market_tax)
    ) {
      newErrors.market_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.carriage_fees !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.carriage_fees)
    ) {
      newErrors.carriage_fees = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_water_charges !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_water_charges)
    ) {
      newErrors.other_water_charges = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.road_space_rent !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.road_space_rent)
    ) {
      newErrors.road_space_rent = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.garbage_clean_fee !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.garbage_clean_fee)
    ) {
      newErrors.garbage_clean_fee = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.locate_in_panchyat !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.locate_in_panchyat)
    ) {
      newErrors.locate_in_panchyat = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.cattle_grazing_fee !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.cattle_grazing_fee)
    ) {
      newErrors.cattle_grazing_fee = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_tax_specified !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_tax_specified)
    ) {
      newErrors.other_tax_specified = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.maharashtra_section !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.maharashtra_section)
    ) {
      newErrors.maharashtra_section = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.business_transaction !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.business_transaction)
    ) {
      newErrors.business_transaction = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.income_on_job !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.income_on_job)
    ) {
      newErrors.income_on_job = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.sale_of_goods !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.sale_of_goods)
    ) {
      newErrors.sale_of_goods = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.dust_garbage !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.dust_garbage)
    ) {
      newErrors.dust_garbage = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.carcases_of_animal !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.carcases_of_animal)
    ) {
      newErrors.carcases_of_animal = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.irrelevant_property !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.irrelevant_property)
    ) {
      newErrors.irrelevant_property = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    // if (sheetData.gst !== "" && !/^-?\d*\.?\d+$/.test(sheetData.gst)) {
    //   newErrors.gst = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    // if (
    //   sheetData.income_tax !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.income_tax)
    // ) {
    //   newErrors.income_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    // if (
    //   sheetData.insurance !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.insurance)
    // ) {
    //   newErrors.insurance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    // if (
    //   sheetData.dmf_fund !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.dmf_fund)
    // ) {
    //   newErrors.dmf_fund = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    if (
      sheetData.state_govt !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.state_govt)
    ) {
      newErrors.state_govt = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.land_revenue !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.land_revenue)
    ) {
      newErrors.land_revenue = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.equation !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.equation)
    ) {
      newErrors.equation = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.stamp_duty !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.stamp_duty)
    ) {
      newErrors.stamp_duty = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.cess !== "" && !/^-?\d*\.?\d+$/.test(sheetData.cess)) {
      newErrors.cess = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.light_bill !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.light_bill)
    ) {
      newErrors.light_bill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.financial_assistance !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.financial_assistance)
    ) {
      newErrors.financial_assistance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.jp_pt_form !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.jp_pt_form)
    ) {
      newErrors.jp_pt_form = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.employee_pay !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.employee_pay)
    ) {
      newErrors.employee_pay = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_contributions !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_contributions)
    ) {
      newErrors.other_contributions = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fee_section_ninety !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fee_section_ninety)
    ) {
      newErrors.fee_section_ninety = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fix_income_panchyat !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fix_income_panchyat)
    ) {
      newErrors.fix_income_panchyat = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.building_rent !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.building_rent)
    ) {
      newErrors.building_rent = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.space_rent !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.space_rent)
    ) {
      newErrors.space_rent = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.kondwara !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.kondwara)
    ) {
      newErrors.kondwara = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.donations !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.donations)
    ) {
      newErrors.donations = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fines_from_criminal !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fines_from_criminal)
    ) {
      newErrors.fines_from_criminal = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.section_one_twentyseven !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.section_one_twentyseven)
    ) {
      newErrors.section_one_twentyseven = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_amount_or_windmill !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_amount_or_windmill)
    ) {
      newErrors.other_amount_or_windmill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.loan !== "" && !/^-?\d*\.?\d+$/.test(sheetData.loan)) {
      newErrors.loan = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.receive_loan_govt !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.receive_loan_govt)
    ) {
      newErrors.receive_loan_govt = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.jp_receive_loan !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.jp_receive_loan)
    ) {
      newErrors.jp_receive_loan = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.jp_gram_loan_fund !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.jp_gram_loan_fund)
    ) {
      newErrors.jp_gram_loan_fund = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.works_devolved !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.works_devolved)
    ) {
      newErrors.works_devolved = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.section_fourtyeight_duties !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.section_fourtyeight_duties)
    ) {
      newErrors.section_fourtyeight_duties = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.jp_pt_receive_fund !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.jp_pt_receive_fund)
    ) {
      newErrors.jp_pt_receive_fund = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    // if (
    //   sheetData.member_allowance !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.member_allowance)
    // ) {
    //   newErrors.member_allowance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    if (
      sheetData.loan_amount !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.loan_amount)
    ) {
      newErrors.loan_amount = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    // console.log("newErrors", newErrors);
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // update api

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm(true)) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const formatField = (mainValue, moneyValue) =>
        mainValue && moneyValue ? `${mainValue || ""}.${moneyValue || "" }` : mainValue ?  mainValue : "";
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_j_data: {
          gpt_name: sheetData.gpt_name,
          taluka_name: sheetData.taluka_name,
          district_name: sheetData.district_name,
          opening_balance: formatField(
            sheetData.opening_balance,
            sheetData.money_opening_balance
          ),
          mumbai_district_civil: formatField(
            sheetData.mumbai_district_civil,
            sheetData.money_mumbai_district_civil
          ),
          wahad_municipality: formatField(
            sheetData.wahad_municipality,
            sheetData.money_wahad_municipality
          ),
          section_eight: formatField(
            sheetData.section_eight,
            sheetData.money_section_eight
          ),
          govt_distribute_amount: formatField(
            sheetData.govt_distribute_amount,
            sheetData.money_govt_distribute_amount
          ),
          fee_under_section: formatField(
            sheetData.fee_under_section,
            sheetData.money_fee_under_section
          ),
          building_tax: formatField(
            sheetData.building_tax,
            sheetData.money_fee_under_section
          ),
          shungi: formatField(sheetData.shungi, sheetData.money_shungi),
          tax_on_pilgrims: formatField(
            sheetData.tax_on_pilgrims,
            sheetData.money_tax_on_pilgrims
          ),
          fairs_tax: formatField(sheetData.fairs_tax, sheetData.money_fair_tax),
          lamp_tax: formatField(sheetData.lamp_tax, sheetData.money_lamp_tax),
          shop_hotel_tax: formatField(
            sheetData.shop_hotel_tax,
            sheetData.money_shop_hotel_tax
          ),
          mill_tax: formatField(sheetData.mill_tax, sheetData.money_mill_tax),
          broker_tax: formatField(
            sheetData.broker_tax,
            sheetData.money_broker_tax
          ),
          general_health_tax: formatField(
            sheetData.general_health_tax,
            sheetData.money_general_health_tax
          ),
          special_health_tax: formatField(
            sheetData.special_health_tax,
            sheetData.money_special_health_tax
          ),
          common_watershed: formatField(
            sheetData.common_watershed,
            sheetData.money_common_watershed
          ),
          special_watershed: formatField(
            sheetData.special_watershed,
            sheetData.money_special_watershed
          ),
          // new point added
          market_tax: formatField(
            sheetData.market_tax,
            sheetData.money_market_tax
          ),
          // new point added
          carriage_fees: formatField(
            sheetData.carriage_fees,
            sheetData.money_carriage_fees
          ),
          other_water_charges: formatField(
            sheetData.other_water_charges,
            sheetData.money_other_water_charges
          ),
          road_space_rent: formatField(
            sheetData.road_space_rent,
            sheetData.money_road_space_rent
          ),
          garbage_clean_fee: formatField(
            sheetData.garbage_clean_fee,
            sheetData.money_garbage_clean_fee
          ),
          locate_in_panchyat: formatField(
            sheetData.locate_in_panchyat,
            sheetData.money_locate_in_panchyat
          ),
          cattle_grazing_fee: formatField(
            sheetData.cattle_grazing_fee,
            sheetData.money_cattle_grazing_fee
          ),
          other_tax_specified: formatField(
            sheetData.other_tax_specified,
            sheetData.money_other_tax_specified
          ),
          maharashtra_section: formatField(
            sheetData.maharashtra_section,
            sheetData.money_maharashtra_section
          ),
          business_transaction: formatField(
            sheetData.business_transaction,
            sheetData.money_business_transaction
          ),
          income_on_job: formatField(
            sheetData.income_on_job,
            sheetData.money_income_on_job
          ),
          sale_of_goods: formatField(
            sheetData.sale_of_goods,
            sheetData.money_sale_of_goods
          ),
          dust_garbage: formatField(
            sheetData.dust_garbage,
            sheetData.money_dust_garbage
          ),
          carcases_of_animal: formatField(
            sheetData.carcases_of_animal,
            sheetData.money_carcases_of_animal
          ),
          irrelevant_property: formatField(
            sheetData.irrelevant_property,
            sheetData.money_irrelevant_property
          ),
          // remove point
          // gst: formatField(sheetData.gst, sheetData.money_gst),
          // income_tax: formatField(
          //   sheetData.income_tax,
          //   sheetData.money_income_tax
          // ),
          // insurance: formatField(
          //   sheetData.insurance,
          //   sheetData.money_insurance
          // ),
          // dmf_fund: formatField(sheetData.dmf_fund, sheetData.money_dmf_fund),
          // remove point
          state_govt: formatField(
            sheetData.state_govt,
            sheetData.money_state_govt
          ),
          land_revenue: formatField(
            sheetData.land_revenue,
            sheetData.money_land_revenue
          ),
          equation: formatField(sheetData.equation, sheetData.money_equation),
          stamp_duty: formatField(
            sheetData.stamp_duty,
            sheetData.money_stamp_duty
          ),
          cess: formatField(sheetData.cess, sheetData.money_cess),
          light_bill: formatField(
            sheetData.light_bill,
            sheetData.money_light_bill
          ),
          financial_assistance: formatField(
            sheetData.financial_assistance,
            sheetData.money_financial_assistance
          ),
          jp_pt_form: formatField(
            sheetData.jp_pt_form,
            sheetData.money_jp_pt_form
          ),
          // add one point
          employee_pay: formatField(
            sheetData.employee_pay,
            sheetData.money_employee_pay
          ),
          // add one point

          other_contributions: formatField(
            sheetData.other_contributions,
            sheetData.money_other_contributions
          ),
          fee_section_ninety: formatField(
            sheetData.fee_section_ninety,
            sheetData.money_fee_section_ninety
          ),
          fix_income_panchyat: formatField(
            sheetData.fix_income_panchyat,
            sheetData.money_fix_income_panchyat
          ),
          building_rent: formatField(
            sheetData.building_rent,
            sheetData.money_building_rent
          ),
          space_rent: formatField(
            sheetData.space_rent,
            sheetData.money_space_rent
          ),
          kondwara: formatField(sheetData.kondwara, sheetData.money_kondwara),
          donations: formatField(
            sheetData.donations,
            sheetData.money_donations
          ),
          fines_from_criminal: formatField(
            sheetData.fines_from_criminal,
            sheetData.money_fines_from_criminal
          ),
          section_one_twentyseven: formatField(
            sheetData.section_one_twentyseven,
            sheetData.money_section_one_twentyseven
          ),
          other_amount_or_windmill: formatField(
            sheetData.other_amount_or_windmill,
            sheetData.money_other_amount_or_windmill
          ),
          loan: formatField(sheetData.loan, sheetData.money_loan),
          receive_loan_govt: formatField(
            sheetData.receive_loan_govt,
            sheetData.money_receive_loan_govt
          ),
          jp_receive_loan: formatField(
            sheetData.jp_receive_loan,
            sheetData.money_jp_receive_loan
          ),
          jp_gram_loan_fund: formatField(
            sheetData.jp_gram_loan_fund,
            sheetData.money_jp_gram_loan_fund
          ),
          works_devolved: formatField(
            sheetData.works_devolved,
            sheetData.money_works_devolved
          ),
          section_fourtyeight_duties: formatField(
            sheetData.section_fourtyeight_duties,
            sheetData.money_section_fourtyeight_duties
          ),
          jp_pt_receive_fund: formatField(
            sheetData.jp_pt_receive_fund,
            sheetData.money_jp_pt_receive_fund
          ),
          // remove point
          // member_allowance: formatField(
          //   sheetData.member_allowance,
          //   sheetData.money_member_allowance
          // ),
          // remove point

          loan_amount: formatField(
            sheetData.loan_amount,
            sheetData.money_loan_amount
          ),
          total_amount_receive_year: Total && Total.toFixed(2),
          total: TotalRound,
        },
      };
      console.log("update finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_j",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetJ response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // draft api
  const handleDraftSheetH = async (e) => {
    e.preventDefault();
    if (validateForm(true)) {
      console.log("validation success");
    }
    try {
      const formatField = (mainValue, moneyValue) =>
        mainValue && moneyValue ? `${mainValue || ""}.${moneyValue || "" }` : mainValue ?  mainValue : "";
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_j_data: {
          gpt_name: sheetData.gpt_name,
          taluka_name: sheetData.taluka_name,
          district_name: sheetData.district_name,
          opening_balance: formatField(
            sheetData.opening_balance,
            sheetData.money_opening_balance
          ),
          mumbai_district_civil: formatField(
            sheetData.mumbai_district_civil,
            sheetData.money_mumbai_district_civil
          ),
          wahad_municipality: formatField(
            sheetData.wahad_municipality,
            sheetData.money_wahad_municipality
          ),
          section_eight: formatField(
            sheetData.section_eight,
            sheetData.money_section_eight
          ),
          govt_distribute_amount: formatField(
            sheetData.govt_distribute_amount,
            sheetData.money_govt_distribute_amount
          ),
          fee_under_section: formatField(
            sheetData.fee_under_section,
            sheetData.money_fee_under_section
          ),
          building_tax: formatField(
            sheetData.building_tax,
            sheetData.money_fee_under_section
          ),
          shungi: formatField(sheetData.shungi, sheetData.money_shungi),
          tax_on_pilgrims: formatField(
            sheetData.tax_on_pilgrims,
            sheetData.money_tax_on_pilgrims
          ),
          fairs_tax: formatField(sheetData.fairs_tax, sheetData.money_fair_tax),
          lamp_tax: formatField(sheetData.lamp_tax, sheetData.money_lamp_tax),
          shop_hotel_tax: formatField(
            sheetData.shop_hotel_tax,
            sheetData.money_shop_hotel_tax
          ),
          mill_tax: formatField(sheetData.mill_tax, sheetData.money_mill_tax),
          broker_tax: formatField(
            sheetData.broker_tax,
            sheetData.money_broker_tax
          ),
          general_health_tax: formatField(
            sheetData.general_health_tax,
            sheetData.money_general_health_tax
          ),
          special_health_tax: formatField(
            sheetData.special_health_tax,
            sheetData.money_special_health_tax
          ),
          common_watershed: formatField(
            sheetData.common_watershed,
            sheetData.money_common_watershed
          ),
          special_watershed: formatField(
            sheetData.special_watershed,
            sheetData.money_special_watershed
          ),
          // new point added
          market_tax: formatField(
            sheetData.market_tax,
            sheetData.money_market_tax
          ),
          // new point added
          carriage_fees: formatField(
            sheetData.carriage_fees,
            sheetData.money_carriage_fees
          ),
          other_water_charges: formatField(
            sheetData.other_water_charges,
            sheetData.money_other_water_charges
          ),
          road_space_rent: formatField(
            sheetData.road_space_rent,
            sheetData.money_road_space_rent
          ),
          garbage_clean_fee: formatField(
            sheetData.garbage_clean_fee,
            sheetData.money_garbage_clean_fee
          ),
          locate_in_panchyat: formatField(
            sheetData.locate_in_panchyat,
            sheetData.money_locate_in_panchyat
          ),
          cattle_grazing_fee: formatField(
            sheetData.cattle_grazing_fee,
            sheetData.money_cattle_grazing_fee
          ),
          other_tax_specified: formatField(
            sheetData.other_tax_specified,
            sheetData.money_other_tax_specified
          ),
          maharashtra_section: formatField(
            sheetData.maharashtra_section,
            sheetData.money_maharashtra_section
          ),
          business_transaction: formatField(
            sheetData.business_transaction,
            sheetData.money_business_transaction
          ),
          income_on_job: formatField(
            sheetData.income_on_job,
            sheetData.money_income_on_job
          ),
          sale_of_goods: formatField(
            sheetData.sale_of_goods,
            sheetData.money_sale_of_goods
          ),
          dust_garbage: formatField(
            sheetData.dust_garbage,
            sheetData.money_dust_garbage
          ),
          carcases_of_animal: formatField(
            sheetData.carcases_of_animal,
            sheetData.money_carcases_of_animal
          ),
          irrelevant_property: formatField(
            sheetData.irrelevant_property,
            sheetData.money_irrelevant_property
          ),
          // remove point
          // gst: formatField(sheetData.gst, sheetData.money_gst),
          // income_tax: formatField(
          //   sheetData.income_tax,
          //   sheetData.money_income_tax
          // ),
          // insurance: formatField(
          //   sheetData.insurance,
          //   sheetData.money_insurance
          // ),
          // dmf_fund: formatField(sheetData.dmf_fund, sheetData.money_dmf_fund),
          // remove point
          state_govt: formatField(
            sheetData.state_govt,
            sheetData.money_state_govt
          ),
          land_revenue: formatField(
            sheetData.land_revenue,
            sheetData.money_land_revenue
          ),
          equation: formatField(sheetData.equation, sheetData.money_equation),
          stamp_duty: formatField(
            sheetData.stamp_duty,
            sheetData.money_stamp_duty
          ),
          cess: formatField(sheetData.cess, sheetData.money_cess),
          light_bill: formatField(
            sheetData.light_bill,
            sheetData.money_light_bill
          ),
          financial_assistance: formatField(
            sheetData.financial_assistance,
            sheetData.money_financial_assistance
          ),
          jp_pt_form: formatField(
            sheetData.jp_pt_form,
            sheetData.money_jp_pt_form
          ),
          // add one point
          employee_pay: formatField(
            sheetData.employee_pay,
            sheetData.money_employee_pay
          ),
          // add one point

          other_contributions: formatField(
            sheetData.other_contributions,
            sheetData.money_other_contributions
          ),
          fee_section_ninety: formatField(
            sheetData.fee_section_ninety,
            sheetData.money_fee_section_ninety
          ),
          fix_income_panchyat: formatField(
            sheetData.fix_income_panchyat,
            sheetData.money_fix_income_panchyat
          ),
          building_rent: formatField(
            sheetData.building_rent,
            sheetData.money_building_rent
          ),
          space_rent: formatField(
            sheetData.space_rent,
            sheetData.money_space_rent
          ),
          kondwara: formatField(sheetData.kondwara, sheetData.money_kondwara),
          donations: formatField(
            sheetData.donations,
            sheetData.money_donations
          ),
          fines_from_criminal: formatField(
            sheetData.fines_from_criminal,
            sheetData.money_fines_from_criminal
          ),
          section_one_twentyseven: formatField(
            sheetData.section_one_twentyseven,
            sheetData.money_section_one_twentyseven
          ),
          other_amount_or_windmill: formatField(
            sheetData.other_amount_or_windmill,
            sheetData.money_other_amount_or_windmill
          ),
          loan: formatField(sheetData.loan, sheetData.money_loan),
          receive_loan_govt: formatField(
            sheetData.receive_loan_govt,
            sheetData.money_receive_loan_govt
          ),
          jp_receive_loan: formatField(
            sheetData.jp_receive_loan,
            sheetData.money_jp_receive_loan
          ),
          jp_gram_loan_fund: formatField(
            sheetData.jp_gram_loan_fund,
            sheetData.money_jp_gram_loan_fund
          ),
          works_devolved: formatField(
            sheetData.works_devolved,
            sheetData.money_works_devolved
          ),
          section_fourtyeight_duties: formatField(
            sheetData.section_fourtyeight_duties,
            sheetData.money_section_fourtyeight_duties
          ),
          jp_pt_receive_fund: formatField(
            sheetData.jp_pt_receive_fund,
            sheetData.money_jp_pt_receive_fund
          ),
          // remove point
          // member_allowance: formatField(
          //   sheetData.member_allowance,
          //   sheetData.money_member_allowance
          // ),
          // remove point

          loan_amount: formatField(
            sheetData.loan_amount,
            sheetData.money_loan_amount
          ),
          total_amount_receive_year: Total && Total.toFixed(2),
          total: TotalRound,
        },
      };
      console.log("draftSheetJ finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_j",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetJ response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // calculate total
  const Total =
    // parseFloat(
    //   `${sheetData.opening_balance || 0}.${
    //     sheetData.money_opening_balance || 0
    //   }`
    // ) +
    parseFloat(
      `${sheetData.mumbai_district_civil || 0}.${
        sheetData.money_mumbai_district_civil || 0
      }`
    ) +
      parseFloat(
        `${sheetData.wahad_municipality || 0}.${
          sheetData.money_wahad_municipality || 0
        }`
      ) +
      parseFloat(
        `${sheetData.section_eight || 0}.${sheetData.money_section_eight || 0}`
      ) +
      parseFloat(
        `${sheetData.govt_distribute_amount || 0}.${
          sheetData.money_govt_distribute_amount || 0
        }`
      ) +
      parseFloat(
        `${sheetData.fee_under_section || 0}.${
          sheetData.money_fee_under_section || 0
        }`
      ) +
      parseFloat(
        `${sheetData.building_tax || 0}.${
          sheetData.money_fee_under_section || 0
        }`
      ) +
      parseFloat(`${sheetData.shungi || 0}.${sheetData.money_shungi || 0}`) +
      parseFloat(
        `${sheetData.tax_on_pilgrims || 0}.${
          sheetData.money_tax_on_pilgrims || 0
        }`
      ) +
      parseFloat(
        `${sheetData.fairs_tax || 0}.${sheetData.money_fair_tax || 0}`
      ) +
      parseFloat(
        `${sheetData.lamp_tax || 0}.${sheetData.money_lamp_tax || 0}`
      ) +
      parseFloat(
        `${sheetData.shop_hotel_tax || 0}.${
          sheetData.money_shop_hotel_tax || 0
        }`
      ) +
      parseFloat(
        `${sheetData.mill_tax || 0}.${sheetData.money_mill_tax || 0}`
      ) +
      parseFloat(
        `${sheetData.broker_tax || 0}.${sheetData.money_broker_tax || 0}`
      ) +
      parseFloat(
        `${sheetData.general_health_tax || 0}.${
          sheetData.money_general_health_tax || 0
        }`
      ) +
      parseFloat(
        `${sheetData.special_health_tax || 0}.${
          sheetData.money_special_health_tax || 0
        }`
      ) +
      parseFloat(
        `${sheetData.common_watershed || 0}.${
          sheetData.money_common_watershed || 0
        }`
      ) +
      parseFloat(
        `${sheetData.special_watershed || 0}.${
          sheetData.money_special_watershed || 0
        }`
      ) +
      parseFloat(
        `${sheetData.market_tax || 0}.${sheetData.money_market_tax || 0}`
      ) +
      parseFloat(
        `${sheetData.carriage_fees || 0}.${sheetData.money_carriage_fees || 0}`
      ) +
      parseFloat(
        `${sheetData.other_water_charges || 0}.${
          sheetData.money_other_water_charges || 0
        }`
      ) +
      parseFloat(
        `${sheetData.road_space_rent || 0}.${
          sheetData.money_road_space_rent || 0
        }`
      ) +
      parseFloat(
        `${sheetData.garbage_clean_fee || 0}.${
          sheetData.money_garbage_clean_fee || 0
        }`
      ) +
      parseFloat(
        `${sheetData.locate_in_panchyat || 0}.${
          sheetData.money_locate_in_panchyat || 0
        }`
      ) +
      parseFloat(
        `${sheetData.cattle_grazing_fee || 0}.${
          sheetData.money_cattle_grazing_fee || 0
        }`
      ) +
      parseFloat(
        `${sheetData.other_tax_specified || 0}.${
          sheetData.money_other_tax_specified || 0
        }`
      ) +
      parseFloat(
        `${sheetData.maharashtra_section || 0}.${
          sheetData.money_maharashtra_section || 0
        }`
      ) +
      parseFloat(
        `${sheetData.business_transaction || 0}.${
          sheetData.money_business_transaction || 0
        }`
      ) +
      parseFloat(
        `${sheetData.income_on_job || 0}.${sheetData.money_income_on_job || 0}`
      ) +
      parseFloat(
        `${sheetData.sale_of_goods || 0}.${sheetData.money_sale_of_goods || 0}`
      ) +
      parseFloat(
        `${sheetData.dust_garbage || 0}.${sheetData.money_dust_garbage || 0}`
      ) +
      parseFloat(
        `${sheetData.carcases_of_animal || 0}.${
          sheetData.money_carcases_of_animal || 0
        }`
      ) +
      parseFloat(
        `${sheetData.irrelevant_property || 0}.${
          sheetData.money_irrelevant_property || 0
        }`
      ) +
      // parseFloat(`${sheetData.gst || 0}.${sheetData.money_gst || 0}`) +
      // parseFloat(
      //   `${sheetData.income_tax || 0}.${sheetData.money_income_tax || 0}`
      // ) +
      // parseFloat(
      //   `${sheetData.insurance || 0}.${sheetData.money_insurance || 0}`
      // ) +
      // parseFloat(
      //   `${sheetData.dmf_fund || 0}.${sheetData.money_dmf_fund || 0}`
      // ) +
      parseFloat(
        `${sheetData.state_govt || 0}.${sheetData.money_state_govt || 0}`
      ) +
      parseFloat(
        `${sheetData.land_revenue || 0}.${sheetData.money_land_revenue || 0}`
      ) +
      parseFloat(
        `${sheetData.equation || 0}.${sheetData.money_equation || 0}`
      ) +
      parseFloat(
        `${sheetData.stamp_duty || 0}.${sheetData.money_stamp_duty || 0}`
      ) +
      parseFloat(`${sheetData.cess || 0}.${sheetData.money_cess || 0}`) +
      parseFloat(
        `${sheetData.light_bill || 0}.${sheetData.money_light_bill || 0}`
      ) +
      parseFloat(
        `${sheetData.financial_assistance || 0}.${
          sheetData.money_financial_assistance || 0
        }`
      ) +
      parseFloat(
        `${sheetData.jp_pt_form || 0}.${sheetData.money_jp_pt_form || 0}`
      ) +
      parseFloat(
        `${sheetData.employee_pay || 0}.${sheetData.money_employee_pay || 0}`
      ) +
      parseFloat(
        `${sheetData.other_contributions || 0}.${
          sheetData.money_other_contributions || 0
        }`
      ) +
      parseFloat(
        `${sheetData.fee_section_ninety || 0}.${
          sheetData.money_fee_section_ninety || 0
        }`
      ) +
      parseFloat(
        `${sheetData.fix_income_panchyat || 0}.${
          sheetData.money_fix_income_panchyat || 0
        }`
      ) +
      parseFloat(
        `${sheetData.building_rent || 0}.${sheetData.money_building_rent || 0}`
      ) +
      parseFloat(
        `${sheetData.space_rent || 0}.${sheetData.money_space_rent || 0}`
      ) +
      parseFloat(
        `${sheetData.kondwara || 0}.${sheetData.money_kondwara || 0}`
      ) +
      parseFloat(
        `${sheetData.donations || 0}.${sheetData.money_donations || 0}`
      ) +
      parseFloat(
        `${sheetData.fines_from_criminal || 0}.${
          sheetData.money_fines_from_criminal || 0
        }`
      ) +
      parseFloat(
        `${sheetData.section_one_twentyseven || 0}.${
          sheetData.money_section_one_twentyseven || 0
        }`
      ) +
      parseFloat(
        `${sheetData.other_amount_or_windmill || 0}.${
          sheetData.money_other_amount_or_windmill || 0
        }`
      ) +
      parseFloat(`${sheetData.loan || 0}.${sheetData.money_loan || 0}`) +
      parseFloat(
        `${sheetData.receive_loan_govt || 0}.${
          sheetData.money_receive_loan_govt || 0
        }`
      ) +
      parseFloat(
        `${sheetData.jp_receive_loan || 0}.${
          sheetData.money_jp_receive_loan || 0
        }`
      ) +
      parseFloat(
        `${sheetData.jp_gram_loan_fund || 0}.${
          sheetData.money_jp_gram_loan_fund || 0
        }`
      ) +
      parseFloat(
        `${sheetData.works_devolved || 0}.${
          sheetData.money_works_devolved || 0
        }`
      ) +
      parseFloat(
        `${sheetData.section_fourtyeight_duties || 0}.${
          sheetData.money_section_fourtyeight_duties || 0
        }`
      ) +
      parseFloat(
        `${sheetData.jp_pt_receive_fund || 0}.${
          sheetData.money_jp_pt_receive_fund || 0
        }`
      ) +
      // parseFloat(
      //   `${sheetData.member_allowance || 0}.${
      //     sheetData.money_member_allowance || 0
      //   }`
      // ) +
      parseFloat(
        `${sheetData.loan_amount || 0}.${sheetData.money_loan_amount || 0}`
      ) || "";

  var parts = Total.toString().split(".");
  // console.log("total parts", parts);
  var beforeDecimal = parseInt(parts[0]); // Part before the decimal point
  var afterDecimal = parseInt(parts[1]);
  // totalRound
  const TotalRound =
    parseFloat(Total || 0) +
      parseFloat(
        `${sheetData.opening_balance}.${sheetData.money_opening_balance} `
      ) || "";

  var parts1 = TotalRound.toString().split(".");
  var beforeDecimal1 = parseInt(parts1[0]); // Part before the decimal point
  var afterDecimal1 = parseInt(parts1[1]);

  const handleKeyDown = (event) => {
    if (
      [46, 8, 9, 27, 13].includes(event.keyCode) ||
      (event.keyCode === 65 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode === 67 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode === 88 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  };
  return (
    <section className={`${style.sample_page_section_main}`}>
      <div
        className={`container sheet_bg_color_pdf ${style.sample_sec_container}`}
      >
        <div className={`row ${style.sample_sec_row}`}>
          <div className="col-lg-12 mb-5 pb-5">
            <div className="table-main-sec">
              <div
                className={`d-flex justify-content-center  align-items-center gap-5 ${style.sample_page_title_top}`}
              >
                <p>
                  नमुना नंबर - ३{" "}
                  <span>
                    (पाणीपुरवठा जमा बाजु सन {yearInfo.previous_year_name} ते{" "}
                    {yearInfo.current_year_name} या वर्षाचे जमा वार्षिक हिशोब)
                  </span>
                </p>
                {economicData === "Y" ? (
                  <span className="drafted_text text-danger">
                    <FaEdit /> Drafted
                  </span>
                ) : economicData === "N" ? (
                  <span className="table_title_top gap-0 submit_text1s">
                    Submitted{" "}
                    <img
                      src={require("../../../component/assets/images/sumbit-success.png")}
                      alt="success"
                      width={30}
                      className="img-fluid"
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className={`${style.dtv_info_sec_main}`}>
                <div className={`${style.dtv_info_sec}`}>
                  <div className={`${style.dtv_info_sub_sec}`}>
                    <p>ग्रामपंचायत :-</p>
                    <span>{sheetData.gpt_name}</span>
                  </div>
                  <div className={`${style.dtv_info_sub_sec}`}>
                    <p>तालुका :-</p>
                    <span>{sheetData.taluka_name}</span>
                  </div>
                  <div className={`${style.dtv_info_sub_sec}`}>
                    <p>जिल्हा :-</p>
                    <span>{sheetData.district_name}</span>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <form action="">
                  <table
                    className="table table-bordered"
                    style={{ border: "1px solid black" }}
                  >
                    <thead className={`${style.custom_thead}`}>
                      <tr>
                        <th rowSpan={2} className="text-center">
                          अ.नु
                        </th>
                        <th rowSpan={2} className="text-center">
                          जमा बाब
                        </th>
                        <th colSpan={2} className="text-center">
                          रक्कम
                        </th>
                        <th rowSpan={2} className="text-center">
                          अ.नु
                        </th>
                        <th rowSpan={2} className="text-center">
                          जमा बाब
                        </th>
                        <th colSpan={2} className="text-center">
                          रक्कम
                        </th>
                      </tr>
                      <tr>
                        <th>रुपये</th>
                        <th>पैसे</th>
                        <th>रुपये</th>
                        <th>पैसे</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={`${style.custom_blank_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td>१</td>
                        <td>सुरवातीची शिल्लक </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.opening_balance ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.opening_balance
                                ? errors.opening_balance
                                : ""
                            }
                            name="opening_balance"
                            value={sheetData.opening_balance}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_opening_balance"
                            value={sheetData.money_opening_balance}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>५</td>
                        <td>अंशदाने १) अ) राज्य सरकारकडून</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.state_govt ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.state_govt ? errors.state_govt : ""
                            }
                            name="state_govt"
                            value={sheetData.state_govt}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_state_govt"
                            value={sheetData.money_state_govt}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td>२</td>
                        <td>मुंबई जिल्हा नागरी अधि. १९०१ च्या कलम १९१</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.mumbai_district_civil ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.mumbai_district_civil
                                ? errors.mumbai_district_civil
                                : ""
                            }
                            name="mumbai_district_civil"
                            value={sheetData.mumbai_district_civil}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_mumbai_district_civil"
                            value={sheetData.money_mumbai_district_civil}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>जमिनमहसूल</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.land_revenue ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.land_revenue ? errors.land_revenue : ""
                            }
                            name="land_revenue"
                            value={sheetData.land_revenue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_land_revenue"
                            value={sheetData.money_land_revenue}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>अन्वये किंवा मध्यप्रांत व वहाड नगरपालिका</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.wahad_municipality ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.wahad_municipality
                                ? errors.wahad_municipality
                                : ""
                            }
                            name="wahad_municipality"
                            value={sheetData.wahad_municipality}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_wahad_municipality"
                            value={sheetData.money_wahad_municipality}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>समीकरण</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.equation ? "is-invalid" : ""
                            }`}
                            placeholder={errors.equation ? errors.equation : ""}
                            name="equation"
                            value={sheetData.equation}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_equation"
                            value={sheetData.money_equation}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>अधिनियम १९२२ च्या कलम ८ अन्वये राज्य</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.section_eight ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.section_eight ? errors.section_eight : ""
                            }
                            name="section_eight"
                            value={sheetData.section_eight}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_section_eight"
                            value={sheetData.money_section_eight}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>मुद्रांक शुल्क</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.stamp_duty ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.stamp_duty ? errors.stamp_duty : ""
                            }
                            name="stamp_duty"
                            value={sheetData.stamp_duty}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_stamp_duty"
                            value={sheetData.money_stamp_duty}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>सरकारने वाटून दिलेली रक्कम</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.govt_distribute_amount ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.govt_distribute_amount
                                ? errors.govt_distribute_amount
                                : ""
                            }
                            name="govt_distribute_amount"
                            value={sheetData.govt_distribute_amount}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_govt_distribute_amount"
                            value={sheetData.money_govt_distribute_amount}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>उपकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.cess ? "is-invalid" : ""
                            }`}
                            placeholder={errors.cess ? errors.cess : ""}
                            name="cess"
                            value={sheetData.cess}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_cess"
                            value={sheetData.money_cess}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td>३</td>
                        <td>कलम १२४ अन्वये कर किंवा फी पासूनच्या रक्कमा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fee_under_section ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fee_under_section
                                ? errors.fee_under_section
                                : ""
                            }
                            name="fee_under_section"
                            value={sheetData.fee_under_section}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_fee_under_section"
                            value={sheetData.money_fee_under_section}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>विजबील</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.light_bill ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.light_bill ? errors.light_bill : ""
                            }
                            name="light_bill"
                            value={sheetData.light_bill}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_light_bill"
                            value={sheetData.money_light_bill}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१) इमारती जमीनीवरील कर ( घरपट्टी ) </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.building_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.building_tax ? errors.building_tax : ""
                            }
                            name="building_tax"
                            value={sheetData.building_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_building_tax"
                            value={sheetData.money_building_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>आर्थिक सहाय्य</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.financial_assistance ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.financial_assistance
                                ? errors.financial_assistance
                                : ""
                            }
                            name="financial_assistance"
                            value={sheetData.financial_assistance}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_financial_assistance"
                            value={sheetData.money_financial_assistance}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>२) षुंगी ( ऑक्ट्राय ) </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.shungi ? "is-invalid" : ""
                            }`}
                            placeholder={errors.shungi ? errors.shungi : ""}
                            name="shungi"
                            value={sheetData.shungi}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_shungi"
                            value={sheetData.money_shungi}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>३) यात्रेकरुवरील कर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_pilgrims ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_pilgrims
                                ? errors.tax_on_pilgrims
                                : ""
                            }
                            name="tax_on_pilgrims"
                            value={sheetData.tax_on_pilgrims}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_tax_on_pilgrims"
                            value={sheetData.money_tax_on_pilgrims}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>ब) जि. प. / पं. स. कडून</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.jp_pt_form ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.jp_pt_form ? errors.jp_pt_form : ""
                            }
                            name="jp_pt_form"
                            value={sheetData.jp_pt_form}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_jp_pt_form"
                            value={sheetData.money_jp_pt_form}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>४) जत्रा, उत्सव करमणूक कर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fairs_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fairs_tax ? errors.fairs_tax : ""
                            }
                            name="fairs_tax"
                            value={sheetData.fairs_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_fair_tax"
                            value={sheetData.money_fair_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>कर्मचारी वेतन</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.employee_pay ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.employee_pay ? errors.employee_pay : ""
                            }
                            name="employee_pay"
                            value={sheetData.employee_pay}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_employee_pay"
                            value={sheetData.money_employee_pay}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>५) दिवाबत्तीकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.lamp_tax ? "is-invalid" : ""
                            }`}
                            placeholder={errors.lamp_tax ? errors.lamp_tax : ""}
                            name="lamp_tax"
                            value={sheetData.lamp_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_lamp_tax"
                            value={sheetData.money_lamp_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>६) दुकान, हॉटेलकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.shop_hotel_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.shop_hotel_tax ? errors.shop_hotel_tax : ""
                            }
                            name="shop_hotel_tax"
                            value={sheetData.shop_hotel_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_shop_hotel_tax"
                            value={sheetData.money_shop_hotel_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>७) यांत्रिक, गिरणीकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.mill_tax ? "is-invalid" : ""
                            }`}
                            placeholder={errors.mill_tax ? errors.mill_tax : ""}
                            name="mill_tax"
                            value={sheetData.mill_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_mill_tax"
                            value={sheetData.money_mill_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>२) इतर अंशदाने</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_contributions ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_contributions
                                ? errors.other_contributions
                                : ""
                            }
                            name="other_contributions"
                            value={sheetData.other_contributions}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_other_contributions"
                            value={sheetData.money_other_contributions}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>८) गुराढोरांच्या बाजारातील धंदा, दलालीकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.broker_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.broker_tax ? errors.broker_tax : ""
                            }
                            name="broker_tax"
                            value={sheetData.broker_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_broker_tax"
                            value={sheetData.money_broker_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>६</td>
                        <td> कलम ९० अन्वये फी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fee_section_ninety ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fee_section_ninety
                                ? errors.fee_section_ninety
                                : ""
                            }
                            name="fee_section_ninety"
                            value={sheetData.fee_section_ninety}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_fee_section_ninety"
                            value={sheetData.money_fee_section_ninety}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>९) सामान्य आरोग्यकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.general_health_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.general_health_tax
                                ? errors.general_health_tax
                                : ""
                            }
                            name="general_health_tax"
                            value={sheetData.general_health_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_general_health_tax"
                            value={sheetData.money_general_health_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>७</td>
                        <td> पंचायतीकडील निहीत मिळकती पासून</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fix_income_panchyat ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fix_income_panchyat
                                ? errors.fix_income_panchyat
                                : ""
                            }
                            name="fix_income_panchyat"
                            value={sheetData.fix_income_panchyat}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_fix_income_panchyat"
                            value={sheetData.money_fix_income_panchyat}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१०) विशेष आरोग्यकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.special_health_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.special_health_tax
                                ? errors.special_health_tax
                                : ""
                            }
                            name="special_health_tax"
                            value={sheetData.special_health_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_special_health_tax"
                            value={sheetData.money_special_health_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> इमारती भाडे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.building_rent ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.building_rent ? errors.building_rent : ""
                            }
                            name="building_rent"
                            value={sheetData.building_rent}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_building_rent"
                            value={sheetData.money_building_rent}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>११) सामान्य पाणीपट्टी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.common_watershed ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.common_watershed
                                ? errors.common_watershed
                                : ""
                            }
                            name="common_watershed"
                            value={sheetData.common_watershed}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_common_watershed"
                            value={sheetData.money_common_watershed}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> जागा भाडे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.space_rent ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.space_rent ? errors.space_rent : ""
                            }
                            name="space_rent"
                            value={sheetData.space_rent}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_space_rent"
                            value={sheetData.money_space_rent}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१२) विशेष पाणीपट्टी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.special_watershed ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.special_watershed
                                ? errors.special_watershed
                                : ""
                            }
                            name="special_watershed"
                            value={sheetData.special_watershed}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_special_watershed"
                            value={sheetData.money_special_watershed}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>८</td>
                        <td> कोंडवाड्यापासून जमा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.kondwara ? "is-invalid" : ""
                            }`}
                            placeholder={errors.kondwara ? errors.kondwara : ""}
                            name="kondwara"
                            value={sheetData.kondwara}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_kondwara"
                            value={sheetData.money_kondwara}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१३) फी व ट्रेड</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.market_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.market_tax ? errors.market_tax : ""
                            }
                            name="market_tax"
                            value={sheetData.market_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_market_tax"
                            value={sheetData.money_market_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>९</td>
                        <td> देणगी किंवा अंशदाने याद्वारे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.donations ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.donations ? errors.donations : ""
                            }
                            name="donations"
                            value={sheetData.donations}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_donations"
                            value={sheetData.money_donations}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१४) गाड्या, टांगा, अड्डा फी इतर जमा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.carriage_fees ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.carriage_fees ? errors.carriage_fees : ""
                            }
                            name="carriage_fees"
                            value={sheetData.carriage_fees}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_carriage_fees"
                            value={sheetData.money_carriage_fees}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१५) इतर कारणासाठी पाणी फी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_water_charges ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_water_charges
                                ? errors.other_water_charges
                                : ""
                            }
                            name="other_water_charges"
                            value={sheetData.other_water_charges}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_other_water_charges"
                            value={sheetData.money_other_water_charges}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१०</td>
                        <td>फौजदारीखटल्या व्यतिरिक्त दंड, फी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fines_from_criminal ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fines_from_criminal
                                ? errors.fines_from_criminal
                                : ""
                            }
                            name="fines_from_criminal"
                            value={sheetData.fines_from_criminal}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_fines_from_criminal"
                            value={sheetData.money_fines_from_criminal}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१६) तात्पुरते जागा भाडे ( सार्व. सडकेवरील )</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.road_space_rent ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.road_space_rent
                                ? errors.road_space_rent
                                : ""
                            }
                            name="road_space_rent"
                            value={sheetData.road_space_rent}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_road_space_rent"
                            value={sheetData.money_road_space_rent}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१७) मलवापी साफ करणे फी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.garbage_clean_fee ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.garbage_clean_fee
                                ? errors.garbage_clean_fee
                                : ""
                            }
                            name="garbage_clean_fee"
                            value={sheetData.garbage_clean_fee}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_garbage_clean_fee"
                            value={sheetData.money_garbage_clean_fee}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>११</td>
                        <td>कलम १२७ अन्वये याद्वारे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.section_one_twentyseven ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.section_one_twentyseven
                                ? errors.section_one_twentyseven
                                : ""
                            }
                            name="section_one_twentyseven"
                            value={sheetData.section_one_twentyseven}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_section_one_twentyseven"
                            value={sheetData.money_section_one_twentyseven}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१८) पंचायतीत निहीत असलेल्या</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.locate_in_panchyat ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.locate_in_panchyat
                                ? errors.locate_in_panchyat
                                : ""
                            }
                            name="locate_in_panchyat"
                            value={sheetData.locate_in_panchyat}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_locate_in_panchyat"
                            value={sheetData.money_locate_in_panchyat}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td> गायरानातील गुरे चरणे फी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.cattle_grazing_fee ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.cattle_grazing_fee
                                ? errors.cattle_grazing_fee
                                : ""
                            }
                            name="cattle_grazing_fee"
                            value={sheetData.cattle_grazing_fee}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_cattle_grazing_fee"
                            value={sheetData.money_cattle_grazing_fee}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१२</td>
                        <td>इतर मिळालेल्या रकमा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_amount_or_windmill
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.other_amount_or_windmill
                                ? errors.other_amount_or_windmill
                                : ""
                            }
                            name="other_amount_or_windmill"
                            value={sheetData.other_amount_or_windmill}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_other_amount_or_windmill"
                            value={sheetData.money_other_amount_or_windmill}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१९) निर्दिष्ट करावयाचा इतर कर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_tax_specified ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_tax_specified
                                ? errors.other_tax_specified
                                : ""
                            }
                            name="other_tax_specified"
                            value={sheetData.other_tax_specified}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_other_tax_specified"
                            value={sheetData.money_other_tax_specified}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>( महाराष्ट्र जि. प. व पं. स अधिनियम १९६ ) कलम</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.maharashtra_section ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.maharashtra_section
                                ? errors.maharashtra_section
                                : ""
                            }
                            name="maharashtra_section"
                            value={sheetData.maharashtra_section}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_maharashtra_section"
                            value={sheetData.money_maharashtra_section}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१३</td>
                        <td> कर्जे, अनामत आणि आगाऊ रकमा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.loan ? "is-invalid" : ""
                            }`}
                            placeholder={errors.loan ? errors.loan : ""}
                            name="loan"
                            value={sheetData.loan}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_loan"
                            value={sheetData.money_loan}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>१६३ (ख) (ब) अन्वयेनेमूनदिलेला धंदा व्यापार</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.business_transaction ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.business_transaction
                                ? errors.business_transaction
                                : ""
                            }
                            name="business_transaction"
                            value={sheetData.business_transaction}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_business_transaction"
                            value={sheetData.money_business_transaction}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> अ) राज्य सरकारकडून प्राप्त कर्जे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.receive_loan_govt ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.receive_loan_govt
                                ? errors.receive_loan_govt
                                : ""
                            }
                            name="receive_loan_govt"
                            value={sheetData.receive_loan_govt}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_receive_loan_govt"
                            value={sheetData.money_receive_loan_govt}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td>नोकरी यावरीलकामे व उत्पन्न )</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.income_on_job ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.income_on_job ? errors.income_on_job : ""
                            }
                            name="income_on_job"
                            value={sheetData.income_on_job}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_income_on_job"
                            value={sheetData.money_income_on_job}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> ब) जि. प. / पं स. कडून प्राप्त कर्जे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.jp_receive_loan ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.jp_receive_loan
                                ? errors.jp_receive_loan
                                : ""
                            }
                            name="jp_receive_loan"
                            value={sheetData.jp_receive_loan}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_jp_receive_loan"
                            value={sheetData.money_jp_receive_loan}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td>४</td>
                        <td>पुढील वस्तुंची विक्री पासून उत्पन्न</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.sale_of_goods ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.sale_of_goods ? errors.sale_of_goods : ""
                            }
                            name="sale_of_goods"
                            value={sheetData.sale_of_goods}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_sale_of_goods"
                            value={sheetData.money_sale_of_goods}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> क) जि. ग्रा. वि. निधीतील कर्जे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.jp_gram_loan_fund ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.jp_gram_loan_fund
                                ? errors.jp_gram_loan_fund
                                : ""
                            }
                            name="jp_gram_loan_fund"
                            value={sheetData.jp_gram_loan_fund}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_jp_gram_loan_fund"
                            value={sheetData.money_jp_gram_loan_fund}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td> अ) धुळ, घाण, शेण, केरकचरा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.dust_garbage ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.dust_garbage ? errors.dust_garbage : ""
                            }
                            name="dust_garbage"
                            value={sheetData.dust_garbage}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_dust_garbage"
                            value={sheetData.money_dust_garbage}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> व ४७ अन्वये हस्तांतरीत कामे पार पाडणे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.works_devolved ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.works_devolved ? errors.works_devolved : ""
                            }
                            name="works_devolved"
                            value={sheetData.works_devolved}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_works_devolved"
                            value={sheetData.money_works_devolved}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td> जनावरांची प्रेते इत्यादी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.carcases_of_animal ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.carcases_of_animal
                                ? errors.carcases_of_animal
                                : ""
                            }
                            name="carcases_of_animal"
                            value={sheetData.carcases_of_animal}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_carcases_of_animal"
                            value={sheetData.money_carcases_of_animal}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> कलम ४८ प्रमाणे इतर कर्तव्य पार पाडणेसाठी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.section_fourtyeight_duties
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.section_fourtyeight_duties
                                ? errors.section_fourtyeight_duties
                                : ""
                            }
                            name="section_fourtyeight_duties"
                            value={sheetData.section_fourtyeight_duties}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_section_fourtyeight_duties"
                            value={sheetData.money_section_fourtyeight_duties}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td> ब) पंचायतीच्या निरुपयोगी मालमत्ता</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.irrelevant_property ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.irrelevant_property
                                ? errors.irrelevant_property
                                : ""
                            }
                            name="irrelevant_property"
                            value={sheetData.irrelevant_property}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_irrelevant_property"
                            value={sheetData.money_irrelevant_property}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td> जि. प. / पं. स. कडून प्राप्त निधी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.jp_pt_receive_fund ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.jp_pt_receive_fund
                                ? errors.jp_pt_receive_fund
                                : ""
                            }
                            name="jp_pt_receive_fund"
                            value={sheetData.jp_pt_receive_fund}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_jp_pt_receive_fund"
                            value={sheetData.money_jp_pt_receive_fund}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td> १)</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td> २)</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td> ३)</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td> ४)</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>इ) कर्जाऊ रकमा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.loan_amount ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.loan_amount ? errors.loan_amount : ""
                            }
                            name="loan_amount"
                            value={sheetData.loan_amount}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_loan_amount"
                            value={sheetData.money_loan_amount}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${style.custom_blank_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${style.custom_blank_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td rowSpan={3} colSpan={4}>
                          <div
                            className={`${style.people_count_price_sec_bottom}`}
                          >
                            लोक संख्या प्रमाणे दर माणशी प्राप्ती रु.
                          </div>
                        </td>
                        <td className="text-end" colSpan={2}>
                          वर्षात मिळालेल्या एकूण रकमा
                        </td>
                        <td>{Total && beforeDecimal}</td>
                        <td>{(Total && afterDecimal && !isNaN(afterDecimal)) ? afterDecimal.toString().slice(0, 2) : ""}</td>
                      </tr>
                      <tr>
                        <td className="text-end" colSpan={2}>
                          सुरुवातीची शिल्लक
                        </td>
                        <td>{sheetData.opening_balance}</td>
                        <td>{sheetData.money_opening_balance}</td>
                      </tr>
                      <tr>
                        <td className="text-end" colSpan={2}>
                          एकूण एकंदर
                        </td>
                        <td>{TotalRound && beforeDecimal1}</td>
                        <td>
                        {(TotalRound && afterDecimal1 && !isNaN(afterDecimal1)) ? afterDecimal1.toString().slice(0, 2) : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className={`${style.date_bottom_sec}`}>
                <p>
                  येणेप्रमाणे असे तारीख : <span> १०/१२/२०२४</span>
                </p>
              </div>
              <div className={`${style.sign_sec_main_bottom}`}>
                <div className={`${style.sign_sec_b}`}>
                  <p>ग्रामसेवक</p>
                  <p>सरपंच</p>
                </div>
              </div>
            </div>
            <div className={style.form_btn_space}>
              <FormBtn
                handleSubmit={handleSubmit}
                resetForm={handleSubmit}
                resetButton={true}
                handleDraft={handleDraftSheetH}
              />
              <div className="">
                <div className={style.btn_position}>
                  {currentStep > 1 && (
                    <Button
                      variant="contained"
                      className=""
                      // endIcon={<RestartAltRoundedIcon />}
                      style={{ background: "#49ab4e" }}
                      onClick={handlePrevStep}
                    >
                      Previous
                    </Button>
                  )}

                  {currentStep < 10 && (
                    <Button
                      variant="contained"
                      className=""
                      // endIcon={<RestartAltRoundedIcon />}
                      style={{ background: "#49ab4e" }}
                      onClick={handleNextStep}
                    >
                      Next
                    </Button>
                  )}

                  {currentStep === 10 && (
                    <button
                      type="submit"
                      className={style.btn_generate}
                      // onClick={handlePrint}
                    >
                      Generate Pdf
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-center"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </section>
  );
};

export default WaterSupplySampleNoThree;
