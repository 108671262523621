/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import pulseIcon from "../../component/assets/images/logo/plus-removebg-preview.png";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import "./Prapatra.css";
import axios from "axios";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../../component/Loader/Loader";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const Prapatra_1 = () => {
  const [rows, setRows] = useState([{}]);

  // data when store district taluka month
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [data, setData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [draftButton, setDraftButton] = useState(true);
  const [resetButton, setResetButton] = useState(true);
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);

    const errors = validateRow(updatedRows[index]);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;
    setValidationErrors(updatedValidationErrors);
  };

  const handleMonthChange = (newMonth) => {
    // Clear or reset data state
    setData([]);
    // Update the month state
    setMonth(newMonth);
    // Fetch data for the new month
    handleMonthMeetingDetails(newMonth);
    // ... other logic for month change
  };
  // get data on the server
  useEffect(() => {
    setIsLoading(true);
    handleMonthMeetingDetails(month);
  }, [month]);

  const handleMonthMeetingDetails = async (month) => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month:month.toString(),
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/form_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("prapatra response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      setMonth(response.data.data.month);
      if (
        response.data.data.form_a_data !== null ||
        response.data.data.form_a_data.length > 0
      ) {
        setData(response.data.data.form_a_data);
      } else {
        setData([]);
      }

      if (
        response.data.data.form_a_data === null ||
        response.data.data.form_a_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_a_data[0].is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // when data updated new then quick load the data in page
  const handleDataRefresh = async () => {
    await handleMonthMeetingDetails();
  };

  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleMonthMeetingDetails();
      setValidationErrors("");
    } else {
      setRows([{}]);
      setValidationErrors("");
    }
  };

  // const handleAddRow = () => {
  //   const lastType = rows.length > 0 ? rows[rows.length - 1].type : 0;
  //   const newRow = { type: parseFloat(lastType) + 1 }; // Increment type by 1
  //   setRows([...rows, newRow]);
  // };

  const handleAddRow = () => {
    const lastType = rows.length > 0 ? rows[rows.length - 1].type : 0;
    const initialGptName = villageDataInfo.village_name; // Set your initial value here
    const newRow = { type: parseFloat(lastType) + 1, gpt_name: initialGptName };
    setRows([...rows, newRow]);
  };

  const handleUpdateButtonClick = () => {
    handleMonthMeetingUpdate(rows);
  };

  const validateRow = (row) => {
    const errors = {};
    if (row.gpt_name || !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.gpt_name)) {
      errors.gpt_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if ( !/^-?\d*\.?\d+$/.test(row.gpt_total_member)) {
      errors.gpt_total_member = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if ( !/^-?\d*\.?\d+$/.test(row.no_of_vacancies)) {
      errors.no_of_vacancies = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
     
      !/^-?\d*\.?\d+$/.test(row.department_of_vacancy)
    ) {
      errors.department_of_vacancy = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.reserved_seat)
    ) {
      errors.reserved_seat = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.date_of_vacancy) {
      errors.date_of_vacancy = "महत्वाचे आहे";
    } else if (/^(\d{2})-(\d{2})-(\d{4})$/.test(row.date_of_vacancy)) {
      errors.date_of_vacancy = "अयोग्य दिनांक";
    }
    if (!row.gpt_expiry_date) {
      errors.gpt_expiry_date = "महत्वाचे आहे";
    } else if (!/^\d{4}-\d{2}-\d{2}$/.test(row.gpt_expiry_date)) {
      errors.gpt_expiry_date = "अयोग्य दिनांक";
    }
    if (
      
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.reason_for_vacancy)
    ) {
      errors.reason_for_vacancy = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (
      
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.court_claim_filed_or_not)
    ) {
      errors.court_claim_filed_or_not = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if ( !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.shera)) {
      errors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    return errors;
  };

  // draft validation
  const validateRow1 = (row) => {
    const errors = {};
    if (
      row.gpt_name === "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.gpt_name)
    ) {
      errors.gpt_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (row.gpt_total_member && !/^-?\d*\.?\d+$/.test(row.gpt_total_member)) {
      errors.gpt_total_member = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (row.no_of_vacancies && !/^-?\d*\.?\d+$/.test(row.no_of_vacancies)) {
      errors.no_of_vacancies = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      row.department_of_vacancy &&
      !/^-?\d*\.?\d+$/.test(row.department_of_vacancy)
    ) {
      errors.department_of_vacancy = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      row.reserved_seat !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.reserved_seat)
    ) {
      errors.reserved_seat = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (
      row.date_of_vacancy !== "" &&
      /^(\d{2})-(\d{2})-(\d{4})$/.test(row.date_of_vacancy)
    ) {
      errors.date_of_vacancy = "महत्वाचे आहे";
    }
    if (
      row.gpt_expiry_date !== "" &&
      /^(\d{2})-(\d{2})-(\d{4})$/.test(row.gpt_expiry_date)
    ) {
      errors.gpt_expiry_date = "महत्वाचे आहे";
    }
    if (
      row.reason_for_vacancy !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.reason_for_vacancy)
    ) {
      errors.reason_for_vacancy = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (
      row.court_claim_filed_or_not !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.court_claim_filed_or_not)
    ) {
      errors.court_claim_filed_or_not = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (row.shera !== "" && !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.shera)) {
      errors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    return errors;
  };
 

  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const hasErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        setValidationErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[index] = errors;
          return updatedErrors;
        });
      }
      return Object.keys(errors).length > 0;
    });
  
    if (hasErrors) {
      await handleMonthMeetingUpdate(e);
    } else {
      handleShow();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };

  //  Update Information api

  const handleMonthMeetingUpdate = async (updatedRows) => {
    // e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      scrollToTop();
      return;
    }

    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        form_a_data: rows.map((row) => ({
          gpt_name: villageDataInfo.village_name,
          gpt_total_member: row.gpt_total_member || "निरंक",
          no_of_vacancies: row.no_of_vacancies || "निरंक",
          department_of_vacancy: row.department_of_vacancy || "निरंक",
          reserved_seat: row.reserved_seat || "निरंक",
          date_of_vacancy: row.date_of_vacancy || "",
          gpt_expiry_date: row.gpt_expiry_date || "",
          reason_for_vacancy: row.reason_for_vacancy || "निरंक",
          court_claim_filed_or_not: row.court_claim_filed_or_not || "",
          shera: row.shera || "निरंक",
        })),
      };
      console.log("updateMonthMeeting finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_form_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
          setRows([{}]);
        }, 1000);
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  // store data in draft
  const handleMonthMeetingDraft = async (e) => {
    e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow1(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        form_a_data: rows.map((row) => ({
          type: row.type || 0, // Set default value for type
          gpt_name: row.gpt_name || villageDataInfo.village_name,
          gpt_total_member: row.gpt_total_member || "",
          no_of_vacancies: row.no_of_vacancies || "",
          department_of_vacancy: row.department_of_vacancy || "",
          reserved_seat: row.reserved_seat || "",
          date_of_vacancy: row.date_of_vacancy || "",
          gpt_expiry_date: row.gpt_expiry_date || "",
          reason_for_vacancy: row.reason_for_vacancy || "",
          court_claim_filed_or_not: row.court_claim_filed_or_not || "",
          shera: row.shera || "",
        })),
      };
      console.log("DraftMonthMeeting finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_form_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftMonthMeeting response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
          setRows([{}]);
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // delete entry
  const handleDelete = async (id) => {
    let confirm = window.confirm("Are you sure want to delete these !");
    if (!confirm) {
      return;
    }
    try {
      const finalData = {
        id: id,
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/delete_form_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
        const filteredData = rows.filter((item) => item.id !== id);
        setRows(filteredData);
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };
  // add child in table

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const renderRows = () => {
    return rows.map((rowData, index) => (
      <tr key={index}>
        <td>
          <input
            type="number"
            name="type"
            className="form-control table-input-box"
            value={index+1}
            onChange={(e) => handleInputChange(e, index)}
            autoFocus
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            name="gpt_name"
            value={rowData.gpt_name || villageDataInfo.village_name || ""}
            placeholder={
              validationErrors[index]?.gpt_name
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box 
            `}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            name="gpt_total_member"
            value={rowData.gpt_total_member || ""}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.gpt_total_member
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.gpt_total_member ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="no_of_vacancies"
            value={rowData.no_of_vacancies || ""}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.no_of_vacancies
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.no_of_vacancies ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="department_of_vacancy"
            value={rowData.department_of_vacancy || ""}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.department_of_vacancy
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.department_of_vacancy ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="reserved_seat"
            value={rowData.reserved_seat || ""}
            placeholder={
              validationErrors[index]?.reserved_seat
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.reserved_seat ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="date"
            name="date_of_vacancy"
            value={rowData.date_of_vacancy || ""}
            className={`form-control table-input-box ${
              validationErrors[index]?.date_of_vacancy ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
            max={getCurrentDate()}
          />
        </td>
        <td>
          <input
            type="date"
            name="gpt_expiry_date"
            value={rowData.gpt_expiry_date || ""}
            className={`form-control table-input-box ${
              validationErrors[index]?.gpt_expiry_date ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="reason_for_vacancy"
            value={rowData.reason_for_vacancy || ""}
            placeholder={
              validationErrors[index]?.reason_for_vacancy
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.reason_for_vacancy ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        {/* <td>
          <input
            type="text"
            name="court_claim_filed_or_not"
            value={rowData.court_claim_filed_or_not || ""}
            placeholder={
              validationErrors[index]?.court_claim_filed_or_not
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.court_claim_filed_or_not
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td> */}
        <td>
          <select
            name="court_claim_filed_or_not"
            value={rowData.court_claim_filed_or_not || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box pt-0 pb-0 ${
              validationErrors[index]?.court_claim_filed_or_not
                ? "is-invalid"
                : ""
            }`}
            style={{ appearance: "auto" }}
          >
            <option value="">दावा निवडा</option>
            <option value="होय">आहे </option>
            <option value="नाही">नाही</option>
          </select>
          {/* {validationErrors[index]?.court_claim_filed_or_not && (
            <div className="invalid-feedback">
              कृपया केवळ अक्षरे प्रविष्ट करा
            </div>
          )} */}
        </td>

        <td>
          <input
            type="text"
            name="shera"
            value={rowData.shera || ""}
            placeholder={
              validationErrors[index]?.shera
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.shera ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        {/* <td>
          <button
            type="submit"
            onClick={() => rowData.id && handleDelete(rowData.id)}
            className="table_delete_btn"
          >
            <AiFillDelete />
          </button>
        </td> */}
      </tr>
    ));
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Helmet>
            <title>प्रपत्र १ </title>
          </Helmet>
          <div className="container common-section pt-0">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">१.६ प्रपत्र</p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="arrow-box-active d-flex flex-column justify-content-center">
                    <Link className="text-decoration-none" to="/praptra-a">
                      अ) प्रपत्र १
                    </Link>
                    <span className="text-center arrow-icon">
                      <ArrowDropDownIcon fontSize="large" color="primary" />
                    </span>
                  </div>
                  <div className="arrow-box">
                    <Link className="me-5 ms-5 text-dark" to="/praptra-b">
                      ब) प्रपत्र २{" "}
                    </Link>
                  </div>
                  <div className="arrow-box me-5">
                    <Link className="text-dark" to="/praptra-c">
                      क) प्रपत्र ३{" "}
                    </Link>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="col-lg-12 mt-4 ">
                <div className="d-flex gap-5">
                  <Village_Tal_Dist_Mahe villageDataInfo={villageDataInfo} />
                  <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                    <label htmlFor="">माहे :</label>
                    {month === undefined ? (
                      <span></span>
                    ) : (
                      <select
                        name="month"
                        value={month}
                        onChange={(e) =>
                          handleMonthChange(e.target.value
                            )
                        }
                        className="fw-bold"
                      >
                        <option value="1">जानेवारी</option>
                        <option value="2">फेब्रुवारी</option>
                        <option value="3">मार्च</option>
                        <option value="4">एप्रिल</option>
                        <option value="5">मे</option>
                        <option value="6">जून</option>
                        <option value="7">जुलै</option>
                        <option value="8">ऑगस्ट</option>
                        <option value="9">सप्टेंबर</option>
                        <option value="10">ऑक्टोंबर</option>
                        <option value="11">नोव्हेंबर</option>
                        <option value="12">डिसेंबर</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive mb-3 mt-5">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center d-flex justify-content-center gap-3">
                          प्रपत्र १{" "}
                          {economicData === "Y" ? (
                            <span className="text-danger">is Drafted</span>
                          ) : economicData === "N" ? (
                            <span className="table_title_top gap-0 mb-0 submit_text1s">
                              Submitted{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={30}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          रिक्त सदस्य पदाची माहिती{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-12 table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">ग्रा.पं.नाव</th>
                      <th scope="col">एकूण ग्रा.पं. सदस्य</th>
                      <th scope="col">रिक्त पदाची संख्या</th>
                      <th scope="col">रिक्त पदाचा प्रभाग</th>
                      <th scope="col">
                        रिक्त पद आरक्षित असल्यास राखीव जागेचा प्रवर्ग
                      </th>
                      <th scope="col"> पद रिक्त होण्याचा दिनांक </th>
                      <th scope="col"> ग्रा.पं.मुदत समाप्तीचा दिनांक </th>
                      <th scope="col"> पद रिक्त होण्याचे कारण </th>
                      <th scope="col"> न्यायालयीन दावा दाखल आहे किवा नाही </th>
                      <th scope="col"> शेरा </th>
                      {/* <th scope="col"> Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <tr>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.gpt_name}</td>
                            <td className="text-center">{item.gpt_total_member}</td>
                            <td className="text-center">{item.no_of_vacancies}</td>
                            <td className="text-center">
                              {item.department_of_vacancy}
                            </td>
                            <td className="text-center">{item.reserved_seat}</td>
                            <td className="text-center">{item.date_of_vacancy}</td>
                            <td className="text-center">{item.gpt_expiry_date}</td>
                            <td className="text-center">{item.reason_for_vacancy}</td>
                            <td className="text-center">
                              {item.court_claim_filed_or_not}
                            </td>
                            <td className="text-center">{item.shera}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tbody>
                    {economicData === "Y" || economicData === ""
                      ? renderRows()
                      : ""}
                  </tbody>
                  {/* <div className="plus-icon">
                    <img
                      src={pulseIcon}
                      width={50}
                      className="mt-3"
                      alt="plusIcon"
                      onClick={handleAddRow}
                    />
                  </div> */}
                </table>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              {(month === currentMonth.toString() && !isLoading) &&
                (economicData === "Y" || economicData === "") && (
                  <div className="col-lg-12 d-flex justify-content-end align-items=center">
                    <FormBtn
                      handleSubmit={handleFormSubmission}
                      handleDraft={handleMonthMeetingDraft}
                      draftButton={draftButton}
                      resetButton={resetButton}
                      resetForm={handleDataRefreshReset}
                    />
                  </div>
                )}
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateButtonClick}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Prapatra_1;
