/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Prapatra.css";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import pulseIcon from "../../component/assets/images/logo/plus-removebg-preview.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../../component/Loader/Loader";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";
const Prapatra_2 = () => {
  const [rows, setRows] = useState([{}]);

  // data when store district taluka month
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [data, setData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);

    const errors = validateRow(updatedRows[index]);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;
    setValidationErrors(updatedValidationErrors);
  };

  const handleMonthChange = (newMonth) => {
    // Clear or reset data state
    setRows([]);
    // Update the month state
    setMonth(newMonth);
    // Fetch data for the new month
    handlePraptraDetails(newMonth);
    // ... other logic for month change
  };
  // get data on the server
  useEffect(() => {
    setIsLoading(true)
    handlePraptraDetails(month);
  }, [month]);

  const handlePraptraDetails = async (month) => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month,
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/form_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("prapatra2 response", response.data.data.form_b_data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);

      const formBData = response.data.data.form_b_data;

      if (formBData && formBData.length > 0) {
        const initialRows = formBData.map((item) => ({
          type: item.type,
          id: item.id,
          gpt_name: item.gpt_name,
          administrators_name_and_title: item.administrators_name_and_title,
          gpt_election_date: item.gpt_election_date,
          gpt_expiry_date: item.gpt_expiry_date,
          date_of_administration: item.date_of_administration,
          reason_for_appointing_administration:
            item.reason_for_appointing_administration,
        }));

        setRows(initialRows);
      } else {
        // Handle the case when form_b_data is empty
        setRows([{}]);
      }

      if (
        response.data.data.form_b_data === null ||
        response.data.data.form_b_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_b_data[0].is_draft);
      }

      setMonth(response.data.data.month);
      setData(response.data.data.form_b_data);
      // updated date show in inputs
      // if (
      //   response.data.data.form_b_data !== null ||
      //   response.data.data.form_b_data.length > 0
      // ) {

      // } else {
      //   setRows([]);
      // }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // when data updated new then quick load the data in page
  const handleDataRefresh = async () => {
    await handlePraptraDetails();
  };

  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handlePraptraDetails();
      setValidationErrors("");
    } else {
      setRows([{}]);
      setValidationErrors("");
    }
  };

  const handleAddRow = () => {
    const lastType = rows.length > 0 ? rows[rows.length - 1].type : 0;
    const newRow = { type: parseFloat(lastType) + 1 }; // Increment type by 1
    setRows([...rows, newRow]);
  };

  const handleUpdateButtonClick = () => {
    handleMonthMeetingUpdate(rows);
  };

  const validateRow = (row) => {
    const errors = {};

    if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.administrators_name_and_title)) {
      errors.administrators_name_and_title = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.gpt_election_date) {
      errors.gpt_election_date = "महत्वाचे आहे";
    } else if (/^(\d{2})-(\d{2})-(\d{4})$/.test(row.gpt_election_date)) {
      errors.gpt_election_date = "अयोग्य दिनांक";
    }
    if (!row.gpt_expiry_date) {
      errors.gpt_expiry_date = "महत्वाचे आहे";
    } else if (!/^\d{4}-\d{2}-\d{2}$/.test(row.gpt_expiry_date)) {
      errors.gpt_expiry_date = "अयोग्य दिनांक";
    }
    if (!row.date_of_administration) {
      errors.date_of_administration = "महत्वाचे आहे";
    } else if (/^(\d{2})-(\d{2})-(\d{4})$/.test(row.date_of_administration)) {
      errors.date_of_administration = "अयोग्य दिनांक";
    }
    if (
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(
        row.reason_for_appointing_administration
      )
    ) {
      errors.reason_for_appointing_administration =
        "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    return errors;
  };

  // draft validation
  const validateRow1 = (row) => {
    const errors = {};
    if (
      row.gpt_name === "" &&
      /^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.gpt_name)
    ) {
      errors.gpt_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (
      row.administrators_name_and_title !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.administrators_name_and_title)
    ) {
      errors.administrators_name_and_title = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (
      row.gpt_election_date !== "" &&
      /^(\d{2})-(\d{2})-(\d{4})$/.test(row.gpt_election_date)
    ) {
      errors.gpt_election_date = "महत्वाचे आहे";
    }
    if (
      row.gpt_expiry_date !== "" &&
      /^(\d{2})-(\d{2})-(\d{4})$/.test(row.gpt_expiry_date)
    ) {
      errors.gpt_expiry_date = "महत्वाचे आहे";
    }
    if (
      row.date_of_administration !== "" &&
      /^(\d{2})-(\d{2})-(\d{4})$/.test(row.date_of_administration)
    ) {
      errors.date_of_administration = "महत्वाचे आहे";
    }
    if (
      row.reason_for_appointing_administration !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(
        row.reason_for_appointing_administration
      )
    ) {
      errors.reason_for_appointing_administration =
        "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    return errors;
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const hasErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        setValidationErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[index] = errors;
          return updatedErrors;
        });
      }
      return Object.keys(errors).length > 0;
    });
  
    if (hasErrors) {
      await handleMonthMeetingUpdate(e);
    } else {
      handleShow();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };

  //  Update Information api

  const handleMonthMeetingUpdate = async (updatedRows) => {
    // e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      scrollToTop();
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        form_b_data: rows.map((row) => ({
          gpt_name: villageDataInfo.village_name,
          administrators_name_and_title:
            row.administrators_name_and_title || "निरंक",
          gpt_election_date: row.gpt_election_date || "",
          gpt_expiry_date: row.gpt_expiry_date || "",
          date_of_administration: row.date_of_administration || "",
          reason_for_appointing_administration:
            row.reason_for_appointing_administration || "निरंक",
        })),
      };
      console.log("updateMonthMeeting finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_form_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  // store data in draft
  const handleMonthMeetingDraft = async (e) => {
    e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow1(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        form_b_data: rows.map((row) => ({
          gpt_name: villageDataInfo.village_name,
          administrators_name_and_title:
            row.administrators_name_and_title || "",
          gpt_election_date: row.gpt_election_date || "",
          gpt_expiry_date: row.gpt_expiry_date || "",
          date_of_administration: row.date_of_administration || "",
          reason_for_appointing_administration:
            row.reason_for_appointing_administration || "",
        })),
      };
      console.log("DraftMonthMeeting finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_form_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftMonthMeeting response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // delete entry
  const handleDelete = async (id) => {
    let confirm = window.confirm("Are you sure want to delete these !");
    if (!confirm) return;
    try {
      const finalData = {
        id: id,
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/delete_form_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
        const filteredData = rows.filter((item) => item.id !== id);
        setRows(filteredData);
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const renderRows = () => {
    return rows.map((rowData, index) => (
      <tr key={index}>
        <td>
          <input
            type="number"
            name="type"
            className="form-control table-input-box"
            value={index + 1}
            onChange={(e) => handleInputChange(e, index)}
            autoComplete="off"
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            name="gpt_name"
            placeholder={
              validationErrors[index]?.gpt_name
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            value={rowData.gpt_name || villageDataInfo.village_name}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box `}
            autoComplete="off"
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            name="administrators_name_and_title"
            value={rowData.administrators_name_and_title || ""}
            onChange={(e) => handleInputChange(e, index)}
            placeholder={
              validationErrors[index]?.administrators_name_and_title
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.administrators_name_and_title
                ? "is-invalid"
                : ""
            }`}
            autoComplete="off"
          />
        </td>
        <td>
          <input
            type="date"
            name="gpt_election_date"
            value={rowData.gpt_election_date || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.gpt_election_date ? "is-invalid" : ""
            }`}
            max={getCurrentDate()}
            autoComplete="off"
          />
        </td>
        <td>
          <input
            type="date"
            name="gpt_expiry_date"
            value={rowData.gpt_expiry_date || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.gpt_expiry_date ? "is-invalid" : ""
            }`}
            autoComplete="off"
          />
        </td>
        <td>
          <input
            type="date"
            name="date_of_administration"
            value={rowData.date_of_administration || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.date_of_administration
                ? "is-invalid"
                : ""
            }`}
            max={getCurrentDate()}
            autoComplete="off"
          />
        </td>
        <td>
          <input
            type="text"
            name="reason_for_appointing_administration"
            value={rowData.reason_for_appointing_administration || ""}
            placeholder={
              validationErrors[index]?.reason_for_appointing_administration
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.reason_for_appointing_administration
                ? "is-invalid"
                : ""
            }`}
            autoComplete="off"
          />
        </td>
        {/* <td>
          <button
            type="submit"
            onClick={() => rowData.id && handleDelete(rowData.id)}
            className="table_delete_btn"
          >
            <AiFillDelete />
          </button>
        </td> */}
      </tr>
    ));
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Helmet>
            <title>प्रपत्र २ </title>
          </Helmet>
          <div className="container common-section pt-0">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">१.६ प्रपत्र</p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="arrow-box me-5">
                    <Link className="text-dark" to="/praptra-a">
                      अ) प्रपत्र १
                    </Link>
                  </div>
                  <div className="arrow-box-active d-flex flex-column justify-content-center">
                    <Link className="text-decoration-none" to="/praptra-b">
                      ब) प्रपत्र २{" "}
                    </Link>
                    <span className="text-center arrow-icon">
                      <ArrowDropDownIcon fontSize="large" color="primary" />
                    </span>
                  </div>
                  <div className="arrow-box ms-5 me-5">
                    <Link className="text-dark" to="/praptra-c">
                      क) प्रपत्र ३{" "}
                    </Link>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="col-lg-12 mt-4 ">
                <div className="d-flex gap-5">
                  <Village_Tal_Dist_Mahe villageDataInfo={villageDataInfo} />
                  <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                    <label htmlFor="">माहे :</label>
                    {month === undefined ? (
                      <span></span>
                    ) : (
                      <select
                        name="month"
                        value={month}
                        onChange={(e) =>
                          handleMonthChange(e.target.value)
                        }
                        className="fw-bold"
                      >
                        <option value="1">जानेवारी</option>
                        <option value="2">फेब्रुवारी</option>
                        <option value="3">मार्च</option>
                        <option value="4">एप्रिल</option>
                        <option value="5">मे</option>
                        <option value="6">जून</option>
                        <option value="7">जुलै</option>
                        <option value="8">ऑगस्ट</option>
                        <option value="9">सप्टेंबर</option>
                        <option value="10">ऑक्टोंबर</option>
                        <option value="11">नोव्हेंबर</option>
                        <option value="12">डिसेंबर</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive mb-3 mt-5">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center d-flex justify-content-center gap-3">
                          प्रपत्र २{" "}
                          {economicData === "Y" ? (
                            <span className="text-danger">is Drafted</span>
                          ) : economicData === "N" ? (
                            <span className="table_title_top gap-0 mb-0 submit_text1s">
                              Submitted{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={30}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          प्रशासन नेमलेल्या ग्रामपंचायतची माहिती{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "3rem" }}>
                        अ.क्र.
                      </th>
                      <th scope="col">ग्रा.पं.नाव</th>
                      <th scope="col">प्रशासकाची नाव व हुद्दा</th>
                      <th scope="col">ग्रा.पं.निवडणुकीची दिनांक</th>
                      <th scope="col">ग्रा.पं.मुदत समाप्तीचा दिनांक</th>
                      <th scope="col">प्रशासन नेमलेली तारीख</th>
                      <th scope="col"> प्रशासन नेमण्याचे कारण </th>
                      {/* <th scope="col"> Action</th> */}
                    </tr>
                  </thead>
                  <tbody>{renderRows()}</tbody>
                  {/* <div className="plus-icon">
                    <img
                      src={pulseIcon}
                      width={50}
                      className="mt-3"
                      alt="plusIcon"
                      onClick={handleAddRow}
                    />
                  </div> */}
                </table>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              {(month === currentMonth.toString() && !isLoading) &&
                (economicData === "Y" || economicData === "") && (
                  <div className="col-lg-12 d-flex justify-content-end align-items=center">
                    <FormBtn
                      handleSubmit={handleFormSubmission}
                      handleDraft={handleMonthMeetingDraft}
                      resetForm={handleDataRefreshReset}
                    />
                  </div>
                )}
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateButtonClick}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Prapatra_2;
