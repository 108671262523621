import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../../pages/Gharkul_vibhag/Gharkul.css";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { GoLink } from "react-icons/go";
import { LiaEdit } from "react-icons/lia";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
const AddNotice = () => {
  // data when store district taluka month
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [month, setMonth] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [draftButton, setDraftButton] = useState(true);
  const [notice, setNotice] = useState([]);
  const [edit, setEdit] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleNoticeData();
  }, []);

  const handleNoticeData = async () => {
    try {
      const finalData = {
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/my_notice",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("added notice response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setNotice(response.data.data.notices);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // when data updated new then quick load the data in page
  const handleDataRefresh = async () => {
    await handleNoticeData(message);
  };

  const handleUpdateButtonClick = () => {
    if (isEditing) {
      handleEditNotice();
    } else {
      handleAddNotice();
    }
  };

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  //  Update Information api

  const handleAddNotice = async () => {
    try {
      const finalData = {
        user_id: userDetails.id,
        message: message,
        type: type,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        onclick: url,
        status: status,
      };
      console.log("add notice finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/add_notice",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
        setMessage("");
        setUrl("");
        setType("");
        setStatus("");
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  const handleEditNotice = async () => {
    try {
      const finalData = {
        id: edit,
        user_id: userDetails.id,
        message: message,
        type: type.toString() === "All Users" ? "A" : "Specific" ? "S" : "",
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        onclick: url,
        status: status.toString() === "Active" ? "Y" : "Inactive" ? "N" : "",
      };
      console.log("add notice finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/edit_notice",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setIsEditing(false);
        setEdit(null);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
        setMessage("");
        setUrl("");
        setType("");
        setStatus("");
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  const handleEdit = (id) => {
    const updateNotice = notice.find((item) => item.id === id);
    setMessage(updateNotice.message);
    setUrl(updateNotice.onclick);
    setType(updateNotice.type);
    setStatus(updateNotice.status);
    setEdit(id);
    setIsEditing(true);
  };

  // delete entry
  const handleDelete = async (id) => {
    let confirm = window.confirm("Are you sure want to delete these !");
    if (!confirm) return;
    try {
      console.log("Deleting entry with id:", id);
      const finalData = {
        id: id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/delete_notice",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
        const filteredData = notice.filter((item) => item.id !== id);
        setNotice(filteredData);
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>घरकुल विभाग</title>
        </Helmet>
        <div className="container common-section">
          <div className="row">
            <header className="mt-5">
              <h5>नोटिस </h5>
            </header>
            <div className="table-responsive common_box mb-5 mt-3">
              <div className="date_gharkul">
                <textarea
                  type="text"
                  name="message"
                  value={message}
                  placeholder="नोटिस लिहा"
                  onChange={(e) => setMessage(e.target.value)}
                  className="form-control rounded-0"
                  cols="100"
                  rows="3"
                ></textarea>
              </div>
              <div className="date_gharkul mt-4 d-flex gap-5">
                <div style={{ width: "50%" }}>
                  <select className="form-control  rounded-0 w-100 styled-select" name="url" value={url} onChange={(e) => setUrl(e.target.value)}>
                    <option value="">लिंक निवडा</option>
                    <option value="/eco-a">आर्थिक वर्ष मागणी</option>
                    <option value="/recovery-a">वसुली विभाग</option>
                    <option value="/expense-pro-a"> खर्च तरतूद</option>
                    <option value="/details-of-expense"> खर्च तपशील</option>
                  </select>
                </div>
                <div style={{ width: "50%" }}>
                   <select className="form-control rounded-0 w-100 styled-select" name="type" value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="">वापरकर्ते निवडा</option>
                    <option value="A">सर्व</option>
                    <option value="S">ग्रामसेवक</option>
                    <option value="S">बी. डी. ओ</option>
                    <option value="S"> विस्तारअधिकारी</option>
                    <option value="S"> विस्तारअधिकारी घरकुल</option>
                  </select>
                </div>
              </div>
              <div className="date_gharkul mt-4 d-flex gap-5">
                <div style={{ width: "48%" }}>
                  {/* <input
                    type="text"
                    name="status"
                    value={status}
                    placeholder="स्थिती(Active or Inactive)"
                    onChange={(e) => setStatus(e.target.value)}
                    className="form-control rounded-0 w-100"
                  /> */}
                   <select className="form-control rounded-0 w-100 styled-select" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">स्थिती निवडा</option>
                    <option value="Y">Active</option>
                    <option value="N">InActive</option>
                  </select>
                </div>
              </div>
              <div className="mt-5">
              {/* <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div> */}
              <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
                <FormBtn
                  handleSubmit={handleUpdateButtonClick}
                  resetForm={handleDataRefresh}
                  draftButton={draftButton}
                  isEditing={isEditing}
                />
              </div>
              </div>
            </div>
            <div className="table-responsive mb-3">
              <div className="notice_data">
                <h6>नोटिस लिस्ट</h6>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">
                      अ.क्र.
                    </th>
                    <th scope="col" className="text-center">
                      नोटिस{" "}
                    </th>
                    <th scope="col" className="text-center">
                      {" "}
                      लिंक (url){" "}
                    </th>
                    <th scope="col" className="text-center">
                      प्रकार{" "}
                    </th>
                    <th scope="col" className="text-center">
                      स्थिती{" "}
                    </th>
                    <th scope="col" className="text-center">
                      बदला{" "}
                    </th>
                    <th scope="col" className="text-center">
                      काढून टाका{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(notice) ? (
                    notice.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="text-center">{item.id}</td>
                          <td className="text-center">{item.message}</td>
                          <td className="text-center">
                            {" "}
                            <Link to={`${item.onclick}`}>
                              <GoLink />
                            </Link>{" "}
                          </td>
                          <td className="text-center">{item.type}</td>
                          <td className="text-center">{item.status}</td>
                          <td className="text-center">
                            <button
                              type="submit"
                              onClick={() => handleEdit(item.id)}
                              className="table_delete_btn1"
                            >
                              <LiaEdit />
                            </button>
                          </td>
                          <td className="text-center">
                            <button
                              type="submit"
                              onClick={() => handleDelete(item.id)}
                              className="table_delete_btn"
                            >
                              <AiFillDelete />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p>No data available</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </React.Fragment>
    </div>
  );
};

export default AddNotice;
