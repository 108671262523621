import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./StepProgressBar.css";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { TbExclamationMark } from "react-icons/tb";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const StepProgressBar = ({
  currentStep,
  totalSteps,
  stepNames,
  onStepClick,
  stepStatus,
}) => {
  const renderMultilineText = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  useEffect(() => {
    console.log("Current Step:", currentStep);
    console.log("Step Status:", stepStatus);

    // ... (rest of your code)
  }, [currentStep, stepStatus]);

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="stepper-wrapper">
          {Array.from({ length: totalSteps }).map((_, index) => (
            <div
              key={index}
              className={` ${
                stepStatus[`form_${index + 1}`] === "Done"
                  ? "stepper-item completed"
                  : stepStatus[`form_${index + 1}`] === "Uncompleted"
                  ? "stepper-item active"
                  : stepStatus[`form_${index + 1}`] === "Not Done"
                  ? "stepper-item gray_bg"
                  : stepStatus[`form_${index + 1}`] === "Draft"
                  ? "stepper-item pending_draft"
                  : ""
              }`}
              onClick={() => onStepClick(index + 1)}
            >
              <div className="step-counter">
                {stepStatus[`form_${index + 1}`] === "Done" ? (
                  <IoMdCheckmark className="text-white p-1 fs-5" />
                ) : stepStatus[`form_${index + 1}`] === "Uncompleted" ? (
                  <span
                    className="p-1 step_cross_icon"
                    style={{ fontSize: "12px" }}
                  >
                    <RxCross2 />
                  </span>
                ) : stepStatus[`form_${index + 1}`] === "Not Done" ? (
                  <span
                    className="p-1 step_cross_icon"
                    style={{ fontSize: "12px", color: "black" }}
                  ></span>
                ) : stepStatus[`form_${index + 1}`] === "Draft" ? (
                  <TbExclamationMark className="text-white p-1 fs-3" />
                ) : (
                  ""
                )}
              </div>
              <div
                className={`step-name ${
                  currentStep === index + 1
                    ? "text-primary filter_text"
                    : "step-name"
                }`}
              >
                {renderMultilineText(stepNames[index])}

                {/* {stepStatus[`form_${index + 1}`]} */}
              </div>
              {currentStep === index + 1 && currentStep <= 6 ? (
                <span
                  className="text-center"
                  style={{ position: "absolute", top: "33px" }}
                >
                  <ArrowDropDownIcon sx={{ fontSize: 32 }} color="primary" />
                </span>
              ) : currentStep === index + 1 && currentStep > 6 ? (
                <span
                  className="text-center"
                  style={{ position: "absolute", top: "53px" }}
                >
                  <ArrowDropDownIcon sx={{ fontSize: 32 }} color="primary" />
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

StepProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  stepNames: PropTypes.arrayOf(PropTypes.string), // Array of custom step names
  onStepClick: PropTypes.func.isRequired,
  stepStatus: PropTypes.object.isRequired,
};

export default StepProgressBar;
