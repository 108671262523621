/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import "./EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../component/Loader/Loader";
import { FaEdit } from "react-icons/fa";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const EconomicsYear_Expanse1 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [isDraft, setIsDraft] = useState("");
  const [month, setMonth] = useState("");
  // १0% महिला व बालकल्याण खर्चाची तरतूद  input filed calculation

  const [backLog, setBackLog] = useState("");
  const [currentProvision, setCurrentProvision] = useState("");
  const [totalProvision, setTotalProvision] = useState("");
  const [expenseBData, setExpenseBData] = useState([]);
  const [validated, setValidated] = useState(false);

  // get data session
  const userDataInfo = sessionStorage.getItem("info");
  const userData = JSON.parse(userDataInfo);
  const userDetails = userData.user_details;
   
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const CalculateTotalProvision = () => {
    const num1 = parseFloat(backLog) || 0;
    const num2 = parseFloat(currentProvision) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalProvision("");
    } else {
      const sum = num1 + num2;
      setTotalProvision(Math.round(sum));
    }
  };

  useEffect(() => {
    CalculateTotalProvision();
  }, [backLog, currentProvision]);

  useEffect(() => {
    handleExpanse();
  }, []);
  // get data on server
  const handleExpanse = async () => {
    const finalData = {
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      year_id:userDetails.current_year_id,
      type: "women_and_child_welfare"
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/economic_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("economic_e women_and_child_welfare data", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setYearInfo(response.data.data.year_info);
      setExpenseBData(response.data.data.economic_e_data);
      
      if(response.data.data.economic_e_data === null || response.data.data.economic_e_data.length === 0){
        setIsDraft("");
      }else{
        setIsDraft(response.data.data.economic_e_data.is_draft);
      }
      // store data on input
      setBackLog(response.data.data.economic_e_data.backlog);
      setCurrentProvision(
        response.data.data.economic_e_data.current_provision
      );
      setTotalProvision(response.data.data.economic_e_data.total_provision);
    } catch (error) {
      console.log("error occured fetch data on server", error);
    }
  };

  const handleDataRefresh = async () => {
    await handleExpanse();
  };

  const handleDataReset = async () => {
    if (expenseBData !== null) {
      await handleExpanse();
      setValidated(false);
    } else {
      setBackLog("");
      setCurrentProvision("");
      setTotalProvision("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const backLogRef = useRef(null);
  const currentProvisionRef = useRef(null);
 

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(backLog)) {
        backLogRef.current.focus();
      } else if (!isNumeric(currentProvision)) {
        currentProvisionRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };


  // condition before submit data 

  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const isValid = () =>{
      if (!isNumeric(backLog) || !isNumeric(currentProvision)) {
        setValidated(true);
        return true;
      }
      return false;
    }
    if (isValid()) {
      if(validated){
        await handleUpdateData(e);
      }
    }else{
      handleShow();
    }
  }


  // updata data on server
  const handleUpdateData = async (e) => {
    e.preventDefault();
    if (
      !isNumeric(backLog) ||
      !isNumeric(currentProvision) 
    ) {
      setValidated(true);
      return;
    }
    
    handleClose();

    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      type : "women_and_child_welfare",
      economic_e_data: [
        {
          backlog: backLog,
          current_provision: currentProvision,
          total_provision: totalProvision,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/update_economic_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("error occured updata data", error);
    }
  };

  // store data in draft
  const handleDraft = async (e) => {
    e.preventDefault();

    const invalidFormats =
      (!isNumeric(backLog) && backLog !== "") ||
      (!isNumeric(currentProvision) && currentProvision !== "");
    if (invalidFormats) {
      setValidated(true);
      return;
    }
    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      type : "women_and_child_welfare",
      economic_e_data: [
        {
          backlog: backLog,
          current_provision: currentProvision,
          total_provision: totalProvision,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/draft_economic_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      setValidated(false); // Reset validation state
      setTimeout(() => {
        handleDataRefresh();
      }, 2000);
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>महिला व बालकल्याण खर्चाची तरतूद</title>
          </Helmet>
          <div className="container pt-0 common-section pb-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-main-sec">
                  <p className="d-flex gap-3">
                    ब) १0% महिला व बालकल्याण खर्चाची तरतूद{" "}
                    {isDraft === "Y" ? (
                      <span className="fw-bold text-danger ms-4" style={{fontSize:"18px"}}><FaEdit /> Drafted</span>
                    ) : isDraft === "N" ? (
                      <span className="table_title_top gap-0 submit_text1s ms-4">
                        Submitted{" "}
                        <img
                          src={require("../../component/assets/images/sumbit-success.png")}
                          alt="success"
                          width={30}
                          className="img-fluid"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" style={{width:"5rem"}}>अ.क्र.</th>
                          <th scope="col">मागील अनुशेष</th>
                          <th scope="col">
                            चालू तरतूद <br /> (सन २०२२-२०२३)
                          </th>
                          <th scope="col">
                            एकूण तरतूद <br />
                            (मागील + चालू) (सन २०२२-२०२३)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              type="number"
                              value={1}
                              disabled
                              className="form-control table-input-box"
                              style={{ background: "transparent" }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={backLog}
                              onChange={(e) => setBackLog(e.target.value)}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(backLog)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(backLog)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              ref={backLogRef}
                              autoFocus
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={currentProvision}
                              onChange={(e) =>
                                setCurrentProvision(e.target.value)
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(currentProvision)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(currentProvision)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              ref={currentProvisionRef}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={totalProvision}
                              readOnly
                              className="form-control table-input-box fw-bold"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-5">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
                {(isDraft === "" || isDraft === "Y") && (
                  <FormBtn
                  resetForm={handleDataReset}
                  handleSubmit={handleFormSubmission}
                  handleDraft={handleDraft}
                />
                )}
              </div>
              {isDraft === "N" && (
              <div className="col-lg-12 mb-5">
                <div className="d-flex justify-content-between">
                  <p className="text-danger">
                    सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                    करा.{" "}
                  </p>
                  <div>
                    <Button variant="contained">विनंती करा</Button>
                  </div>
                </div>
              </div>
            )}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateData}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          {/* <ToastContainer
            position={"top-center"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          /> */}
        </>
      )}
    </>
  );
};

export default EconomicsYear_Expanse1;
