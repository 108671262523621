/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "../VasuliReport/RecoveryReport.css";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { FaEdit } from "react-icons/fa";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import Loader from "../../component/Loader/Loader";
import pulseIcon from "../../component/assets/images/logo/plus-removebg-preview.png";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const EconomicsYear_D = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [total, setTotal] = useState("");


  const [rows, setRows] = useState([{}]);

  //  वसुली विभाग (घरपट्टी वसुली) Get Information api

  const handleRecoveryReportDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      // console.log("Recovery Report finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/economic_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("Economic_D_Details response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      if(response.data.data.economic_d_data === null || response.data.data.economic_d_data.length === 0){
        setEconomicData("");
      }else{
        setEconomicData(response.data.data.economic_d_data[0].is_draft);
      }
      if (response.data.data.economic_d_data.length > 0){
        setData(response.data.data.economic_d_data);
     
        const initialRows = response.data.data.economic_d_data.map((item) => ({
          id: item.id,
          windmill_company_name: item.windmill_company_name,
          no_of_windmill: item.no_of_windmill,
          windmill_capacity: item.windmill_capacity,
          past_due: item.past_due,
          current_demand: item.current_demand,
          total_demand: item.total_demand,
        }));
        setRows(initialRows);
      }else{
        setRows([{}]);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleRecoveryReportDetails();
  }, []);

  const handleDataRefresh = async () => {
    await handleRecoveryReportDetails();
  };

  // reset functionality of the using condition
  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleRecoveryReportDetails();
      setValidationErrors("");
    } else {
      setRows([{}]);
      setValidationErrors("");
    }
  };

  const validateRow = (row) => {
    const errors = {};
    if (!row.windmill_company_name || !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.windmill_company_name)) {
      errors.windmill_company_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.no_of_windmill || !/^-?\d*\.?\d+$/.test(row.no_of_windmill)) {
      errors.no_of_windmill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (!row.windmill_capacity || !/^-?\d*\.?\d+$/.test(row.windmill_capacity)) {
      errors.windmill_capacity = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      !row.past_due ||
      !/^-?\d*\.?\d+$/.test(row.past_due)
    ) {
      errors.past_due = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      !row.current_demand ||
      !/^-?\d*\.?\d+$/.test(row.current_demand)
    ) {
      errors.current_demand = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    return errors;
  };

  // draft validation
  const validateRow1 = (row) => {
    const errors = {};
    if (row.windmill_company_name !== "" && !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.windmill_company_name)) {
      errors.windmill_company_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (row.no_of_windmill && !/^-?\d*\.?\d+$/.test(row.no_of_windmill)) {
      errors.no_of_windmill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (row.windmill_capacity && !/^-?\d*\.?\d+$/.test(row.windmill_capacity)) {
      errors.windmill_capacity = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      row.past_due &&
      !/^-?\d*\.?\d+$/.test(row.past_due)
    ) {
      errors.past_due = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      row.current_demand &&
      !/^-?\d*\.?\d+$/.test(row.current_demand)
    ) {
      errors.current_demand = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    
    return errors;
  };
  
  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const hasErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        setValidationErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[index] = errors;
          return updatedErrors;
        });
      }
      return Object.keys(errors).length > 0;
    });
  
    if (hasErrors) {
      await handleUpdateRecoveryReport(e);
    } else {
      handleShow();
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };
  //  आर्थिक वर्ष मागणी (पवनचक्की वसुली/कारखाना वसुली) Update Information api

  const handleUpdateRecoveryReport = async (e) => {
    e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      scrollToTop();
      return;
    }

    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        economic_d_data: rows.map((item) => ({
          id: item.id || 0,
          windmill_company_name: item.windmill_company_name || "",
          no_of_windmill: item.no_of_windmill || "",
          windmill_capacity: item.windmill_capacity || "",
          past_due: item.past_due || "",
          current_demand: item.current_demand || "",
          total_demand: item.total_demand || 
          (parseInt(item.past_due) + parseInt(item.current_demand)) || 0,
        })),
      };
      console.log("updateEconomics_D finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_economic_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateEconomics_D response", response);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // आर्थिक वर्ष मागणी (पवनचक्की वसुली/कारखाना वसुली) Draft Information api

  const handleDraftRecoveryReport = async (e) => {
    e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow1(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        economic_d_data: rows.map((item) => ({
          id: item.id || 0,
          windmill_company_name: item.windmill_company_name || "",
          no_of_windmill: item.no_of_windmill || "",
          windmill_capacity: item.windmill_capacity || "",
          past_due: item.past_due || "",
          current_demand: item.current_demand || "",
          total_demand: item.total_demand || 
          (parseInt(item.past_due) + parseInt(item.current_demand)) || 0,
        })),
      };
      console.log("draftEconomics_D finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_economic_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draftEconomics_D response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  const handleAddRow = () => {
    const lastType = rows.length > 0 ? rows[rows.length - 1].id : 0;
    const newRow = { id: parseFloat(lastType) + 1 }; // Increment type by 1
    setRows([...rows, newRow]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);

    const errors = validateRow(updatedRows[index]);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;
    setValidationErrors(updatedValidationErrors);
  };
  // render table row
  const renderRows = () => {
    return rows.map((rowData, index) => (
      <tr key={rowData.id}>
        <td>
          <input
            type="number"
            name="id"
            className="form-control table-input-box"
            value={index+1}
            onChange={(e) => handleInputChange(e, index)}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            name="windmill_company_name"
            value={rowData.windmill_company_name || ""}
            placeholder={
              validationErrors[index]?.windmill_company_name
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.windmill_company_name ? "is-invalid" : ""
            }`}
          />
        </td>
        <td>
          <input
            type="text"
            name="no_of_windmill"
            value={rowData.no_of_windmill || ""}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.no_of_windmill
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.no_of_windmill ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="windmill_capacity"
            value={rowData.windmill_capacity || ""}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.windmill_capacity
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.windmill_capacity ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="past_due"
            value={rowData.past_due || ""}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.past_due
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.past_due ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="current_demand"
            value={rowData.current_demand || ""}
            placeholder={
              validationErrors[index]?.current_demand
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.current_demand ? "is-invalid" : ""
            }`}
            onChange={(e) => handleInputChange(e, index)}
          />
        </td>
        <td>
          <input
            type="text"
            name="total_demand"
            value={(parseInt(rowData.past_due || 0) + parseInt(rowData.current_demand || 0)) ||  0}
            className={`form-control table-input-box fw-bold`}
            onChange={(e) => handleInputChange(e, index)}
            // readOnly
          />
        </td>
      </tr>
    ));
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>वसुली रीपोर्ट || पवनचक्की वसुली/कारखाना वसुली </title>
          </Helmet>
          <div className="container common-section pt-0">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">१.१ आर्थिक वर्ष मागणी</p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="arrow-box">
                    <Link to="/eco-a" className="me-5 text-dark">
                      अ) ग्रामपंचायत करमागणी ( घरपट्टी )
                    </Link>
                  </div>
                  <div className="arrow-box">
                    <Link to="/eco-b" className=" text-dark">
                      ब) पाणीपट्टी
                    </Link>
                  </div>
                  <div className="arrow-box">
                    <Link to="/eco-c" className="ms-5 me-5 text-dark">
                      क) किरकोळ मागणी
                    </Link>
                  </div>
                  <div className="arrow-box-active d-flex flex-column justify-content-center">
                    <Link to="/eco-d" className="text-decoration-none">
                      ड) पवनचक्की / कारखाना मागणी
                    </Link>
                    <span className="text-center arrow-icon">
                      <ArrowDropDownIcon fontSize="large" color="primary" />
                    </span>
                  </div>
                  <div className="arrow-box me-3">
                    <Link to="/eco-e" className="ms-5 text-dark">
                      इ) खर्च तरतूद
                    </Link>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="col-lg-12 mt-4 mb-4">
                <Village_Tal_Dist
                  villageDataInfo={villageDataInfo}
                  yearInfo={yearInfo}
                />
              </div>
              <div className="col-lg-12">
                <div className="table-main-sec">
                  <div className="table_title_top">
                    <p className="">ड) पवनचक्की वसूली / कारखाना वसूली</p>
                    {economicData === "Y" ? (
                      <span className="drafted_text">
                        <FaEdit /> Drafted
                      </span>
                    ) : economicData === "N" ? (
                      <span className="submit_text">
                        Submitted{" "}
                        <img
                          src={require("../../component/assets/images/sumbit-success.png")}
                          alt="success"
                          width={30}
                          className="img-fluid"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="table-responsive mb-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">अ.क्र.</th>
                          <th scope="col">पवनचक्की कंपनीचे नाव</th>
                          <th scope="col">पवनचक्की संख्या</th>
                          <th scope="col">पवनचक्की क्षमता</th>
                          <th scope="col">
                            मागील थकबाकी (सन{" "}
                            {yearInfo ? yearInfo.previous_year_name : ""})
                          </th>
                          <th scope="col">
                            (सन {yearInfo ? yearInfo.current_year_name : ""}) ची चालू
                            मागणी
                          </th>
                          <th scope="col">
                            एकूण मागणी (सन{" "}
                            {yearInfo ? yearInfo.current_year_name : ""}) (मागील
                            व चालू)
                          </th>
                          {/* <th scope="col"> एकूण वसूली </th>
                          <th scope="col"> टक्केवारी </th> */}
                        </tr>
                      </thead>
                      <tbody>{renderRows()}</tbody>
                      {(economicData === "" || economicData === "Y") && (
                         <div className="plus-icon">
                         <img
                           src={pulseIcon}
                           width={50}
                           className="mt-3"
                           alt="plusIcon"
                           onClick={handleAddRow}
                         />
                       </div>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              <div className="col-lg-12 d-flex justify-content-end align-items=center">
                {(economicData === "" || economicData === "Y") && (
                   <FormBtn
                   handleSubmit={handleFormSubmission}
                   resetForm={handleDataRefreshReset}
                   handleDraft={handleDraftRecoveryReport}
                 />
                )}
              </div>
              {economicData === "N" && (
              <div className="col-lg-12">
                <div className="d-flex justify-content-between">
                  <p className="text-danger">
                    सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                    करा.{" "}
                  </p>
                  <div>
                    <Button variant="contained">विनंती करा</Button>
                  </div>
                </div>
              </div>
            )}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateRecoveryReport}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <ToastContainer
              position={"top-center"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default EconomicsYear_D;
