/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
import "../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../component/Loader/Loader";
// import { Button } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const DetailsOfExpenses = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [gramNidhi, setGramNidhi] = useState("");
  const [detailsOfData, setDetailsOfData] = useState([]);
  const [supply, setSupply] = useState("");
  const [financeCommission, setFinanceCommission] = useState("");
  const [neoBuddhist, setNeoBuddhist] = useState("");
  const [tantamukti, setTantamukti] = useState("");
  const [mGrRo, setMgrRo] = useState("");
  const [publicConvenience, setPublicConvenience] = useState("");
  const [civilFacilities, setCivilFacilities] = useState("");
  const [total, setTotal] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // total of all employee male and female
  const [totalPopulation, setTotalPopulation] = useState("");
  const [women, setWomen] = useState("");
  const [male, setMale] = useState("");
  const [povertyFamily, setPovertyFamily] = useState("");
  const [totalFamily, setTotalFamily] = useState("");
  const [grampanchyatEmployee, setGrampanchyatEmployee] = useState("");
  const [numberOfEmployeeInFigure, setNumberOfEmployeeInFigure] = useState("");

  // input wise date
  const [gramNidhiDate, setGramNidhiDate] = useState("");
  const [supplyDate, setSupplyDate] = useState("");
  const [financeCommissionDate, setFinanceCommissionDate] = useState("");
  const [neoBuddhistDate, setNeoBuddhistDate] = useState("");
  const [tantamuktiDate, setTantamuktiDate] = useState("");
  const [mGrRoDate, setMGrRoDate] = useState("");
  const [publicConvenienceDate, setPublicConvenienceDate] = useState("");
  const [civilFacilitiesDate, setCivilFacilitiesDate] = useState("");

  // expense detail fund type
  const [gramNidhiAuditYear, setGramNidhiAuditYear] = useState("");
  const [gramNidhiPendingNo, setGramNidhiPendingNo] = useState("");
  const [otherPlansAuditYear, setOtherPlansAuditYear] = useState("");
  const [otherPlansPendingNo, setOtherPlansPendingNo] = useState("");

  // other data
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [isDraft, setIsDraft] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [validated, setValidated] = useState(false);

  // get data session
  const userDataInfo = sessionStorage.getItem("info");
  const userData = JSON.parse(userDataInfo);
  const userDetails = userData.user_details;

  const totalDetailsOfExpenses = () => {
    const gramNidhiNum = parseFloat(gramNidhi) || 0;
    const supplyNum = parseFloat(supply) || 0;
    const financeCommissionNum = parseFloat(financeCommission) || 0;
    const neoBuddhistNum = parseFloat(neoBuddhist) || 0;
    const tantamuktiNum = parseFloat(tantamukti) || 0;
    const mGrRoNum = parseFloat(mGrRo) || 0;
    const publicConvenienceNum = parseFloat(publicConvenience) || 0;
    const civilFacilitiesNum = parseFloat(civilFacilities) || 0;

    if (
      isNaN(gramNidhiNum) ||
      isNaN(supplyNum) ||
      isNaN(financeCommissionNum) ||
      isNaN(neoBuddhistNum) ||
      isNaN(tantamuktiNum) ||
      isNaN(mGrRoNum) ||
      isNaN(publicConvenienceNum) ||
      isNaN(civilFacilitiesNum)
    ) {
      setTotal("");
    } else {
      const calculateDetailsOfExpenses =
        gramNidhiNum +
        supplyNum +
        financeCommissionNum +
        neoBuddhistNum +
        tantamuktiNum +
        mGrRoNum +
        publicConvenienceNum +
        civilFacilitiesNum;
      setTotal(Math.round(calculateDetailsOfExpenses));
    }
  };

  useEffect(() => {
    totalDetailsOfExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gramNidhi,
    supply,
    financeCommission,
    neoBuddhist,
    tantamukti,
    mGrRo,
    publicConvenience,
    civilFacilities,
  ]);

  // get data on server
  const handleDetaileExpanse = async () => {
    const finalData = {
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      year_id: userDetails.current_year_id,
      month: month,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/expense_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("expense_details data", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setYearInfo(response.data.data.year_info);
      setDetailsOfData(response.data.data.expense_details_data);

      if (
        response.data.data.expense_details_data === null ||
        response.data.data.expense_details_data.length === 0
      ) {
        setIsDraft("");
      } else {
        setIsDraft(response.data.data.expense_details_data[0].is_draft);
      }

      if (
        response.data.data.expense_details_data !== null &&
        response.data.data.expense_details_data.length > 0
      ) {
        // store server value in input
        setGramNidhiDate(response.data.data.expense_details_data[0].date);
        setGramNidhi(response.data.data.expense_details_data[0].balance_fund);

        // supply
        setSupplyDate(response.data.data.expense_details_data[1].date);
        setSupply(response.data.data.expense_details_data[1].balance_fund);

        // 15th Finance Commission
        setFinanceCommissionDate(
          response.data.data.expense_details_data[2].date
        );
        setFinanceCommission(
          response.data.data.expense_details_data[2].balance_fund
        );

        // Schedule Development NEO-Buddhist
        setNeoBuddhistDate(response.data.data.expense_details_data[3].date);
        setNeoBuddhist(response.data.data.expense_details_data[3].balance_fund);

        // Tantamukti
        setTantamuktiDate(response.data.data.expense_details_data[4].date);
        setTantamukti(response.data.data.expense_details_data[4].balance_fund);

        // M. GR. RO. H. Yo
        setMGrRoDate(response.data.data.expense_details_data[5].date);
        setMgrRo(response.data.data.expense_details_data[5].balance_fund);

        // Public Facilities
        setPublicConvenienceDate(
          response.data.data.expense_details_data[6].date
        );
        setPublicConvenience(
          response.data.data.expense_details_data[6].balance_fund
        );

        // Civil facilities
        setCivilFacilitiesDate(response.data.data.expense_details_data[7].date);
        setCivilFacilities(
          response.data.data.expense_details_data[7].balance_fund
        );
      } else {
        setGramNidhiDate("");
        setGramNidhi(0);
        setSupplyDate("");
        setSupply(0);
        setFinanceCommissionDate("");
        setFinanceCommission(0);
        setNeoBuddhistDate("");
        setNeoBuddhist(0);
        setTantamuktiDate("");
        setTantamukti(0);
        setMGrRoDate("");
        setMgrRo(0);
        setPublicConvenienceDate("");
        setPublicConvenience(0);
        setCivilFacilitiesDate("");
        setCivilFacilities(0);
      }

      // total population

      // setTotalPopulation(
      //   response.data.data.expense_details_data[0].total_population
      // );
      // setWomen(response.data.data.expense_details_data[0].woman);
      // setMale(response.data.data.expense_details_data[0].male);
      // setPovertyFamily(
      //   response.data.data.expense_details_data[0]
      //     .total_number_of_families_below_poverty_line
      // );
      // setTotalFamily(
      //   response.data.data.expense_details_data[0].total_number_of_families
      // );
      // setGrampanchyatEmployee(
      //   response.data.data.expense_details_data[0]
      //     .number_of_gram_panchayat_employees
      // );
      // setNumberOfEmployeeInFigure(
      //   response.data.data.expense_details_data[0]
      //     .number_of_employees_in_the_figure
      // );

      // expense detail fund type
      // setGramNidhiAuditYear(
      //   response.data.data.expense_details_data[0].expense_detail_fund_type[0]
      //     .audit_note_year
      // );
      // setGramNidhiPendingNo(
      //   response.data.data.expense_details_data[0].expense_detail_fund_type[0]
      //     .pending_no
      // );

      // setOtherPlansAuditYear(
      //   response.data.data.expense_details_data[0].expense_detail_fund_type[1]
      //     .audit_note_year
      // );
      // setOtherPlansPendingNo(
      //   response.data.data.expense_details_data[0].expense_detail_fund_type[1]
      //     .pending_no
      // );
    } catch (error) {
      console.log("error occured fetch data on server", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleDetaileExpanse();
  }, [month]);

  const handleDataRefresh = async () => {
    await handleDetaileExpanse();
  };

  const handleDataReset = async () => {
    if (detailsOfData.length > 0) {
      await handleDetaileExpanse();
      setValidated(false);
    } else {
      setGramNidhiDate("");
      setGramNidhi("");
      setSupplyDate("");
      setSupply("");
      setFinanceCommissionDate("");
      setFinanceCommission("");
      setNeoBuddhistDate("");
      setNeoBuddhist("");
      setTantamuktiDate("");
      setTantamukti("");
      setMGrRoDate("");
      setMgrRo("");
      setPublicConvenienceDate("");
      setPublicConvenience("");
      setCivilFacilitiesDate("");
      setCivilFacilities("");
      setTotalPopulation("");
      setWomen("");
      setMale("");
      setPovertyFamily("");
      setTotalFamily("");
      setGrampanchyatEmployee("");
      setNumberOfEmployeeInFigure("");
      setGramNidhiAuditYear("");
      setGramNidhiPendingNo("");
      setOtherPlansAuditYear("");
      setOtherPlansPendingNo("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const gramNidhiRef = useRef(null);
  const supplyRef = useRef(null);
  const financeCommissionRef = useRef(null);
  const neoBuddhistRef = useRef(null);
  const tantamuktiRef = useRef(null);
  const mGrRoRef = useRef(null);
  const publicConvenienceRef = useRef(null);
  const civilFacilitiesRef = useRef(null);
  const totalPopulationRef = useRef(null);
  const maleRef = useRef(null);
  const womenRef = useRef(null);
  const povertyFamilyRef = useRef(null);
  const totalFamilyRef = useRef(null);
  const grampanchyatEmployeeRef = useRef(null);
  const numberOfEmployeeInFigureRef = useRef(null);
  const gramNidhiAuditYearRef = useRef(null);
  const gramNidhiPendingNoRef = useRef(null);
  const otherPlansAuditYearRef = useRef(null);
  const otherPlansPendingNoRef = useRef(null);
  // ... (similar refs for other inputs)

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(gramNidhi)) {
        gramNidhiRef.current.focus();
      } else if (!isNumeric(supply)) {
        supplyRef.current.focus();
      } else if (!isNumeric(financeCommission)) {
        financeCommissionRef.current.focus();
      } else if (!isNumeric(neoBuddhist)) {
        neoBuddhistRef.current.focus();
      } else if (!isNumeric(tantamukti)) {
        tantamuktiRef.current.focus();
      } else if (!isNumeric(mGrRo)) {
        mGrRoRef.current.focus();
      } else if (!isNumeric(publicConvenience)) {
        publicConvenienceRef.current.focus();
      } else if (!isNumeric(civilFacilities)) {
        civilFacilitiesRef.current.focus();
      }
      // else if (!isNumberOnly(totalPopulation)) {
      //   totalPopulationRef.current.focus();
      // } else if (!isNumberOnly(male)) {
      //   maleRef.current.focus();
      // } else if (!isNumberOnly(women)) {
      //   womenRef.current.focus();
      // } else if (!isNumberOnly(povertyFamily)) {
      //   povertyFamilyRef.current.focus();
      // } else if (!isNumberOnly(totalFamily)) {
      //   totalFamilyRef.current.focus();
      // } else if (!isNumberOnly(grampanchyatEmployee)) {
      //   grampanchyatEmployeeRef.current.focus();
      // } else if (!isNumberOnly(numberOfEmployeeInFigure)) {
      //   numberOfEmployeeInFigureRef.current.focus();
      // } else if (!isYear(gramNidhiAuditYear)) {
      //   gramNidhiAuditYearRef.current.focus();
      // } else if (!isNumberOnly(gramNidhiPendingNo)) {
      //   gramNidhiPendingNoRef.current.focus();
      // } else if (!isYear(otherPlansAuditYear)) {
      //   otherPlansAuditYearRef.current.focus();
      // } else if (!isNumberOnly(otherPlansPendingNo)) {
      //   otherPlansPendingNoRef.current.focus();
      // }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions

  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  const isYear = (value) => {
    return /^-?\d+(\.\d+)?(-\d+(\.\d+)?)?$/.test(value);
  };

  const isNumberOnly = (value) => {
    return /^-?\d+$/.test(value);
  };

  const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;

  const formatDateString = (inputDate) => {
    const [day = "", month = "", year = ""] = inputDate.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const gramNidhiDateValid = formatDateString(gramNidhiDate);
    const supplyDateValid = formatDateString(supplyDate);
    const financeCommissionDateValid = formatDateString(financeCommissionDate);
    const neoBuddhistDateValid = formatDateString(neoBuddhistDate);
    const tantamuktiDateValid = formatDateString(tantamuktiDate);
    const mGrRoDateValid = formatDateString(mGrRoDate);
    const publicConvenienceDateValid = formatDateString(publicConvenienceDate);
    const civilFacilitiesDateValid = formatDateString(civilFacilitiesDate);

  // before submit check validation of
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(gramNidhi) ||
      !isNumeric(supply) ||
      !isNumeric(financeCommission) ||
      !isNumeric(neoBuddhist) ||
      !isNumeric(tantamukti) ||
      !isNumeric(mGrRo) ||
      !isNumeric(publicConvenience) ||
      !isNumeric(civilFacilities) ||
      !dateRegex.test(gramNidhiDateValid) ||
      !dateRegex.test(supplyDateValid) ||
      !dateRegex.test(financeCommissionDateValid) ||
      !dateRegex.test(neoBuddhistDateValid) ||
      !dateRegex.test(tantamuktiDateValid) ||
      !dateRegex.test(mGrRoDateValid) ||
      !dateRegex.test(publicConvenienceDateValid) ||
      !dateRegex.test(civilFacilitiesDateValid)
    ) {
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      if (validated) {
        await handleUpdateData(e);
      }
    } else {
      handleShow();
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  // updata data on server
  const handleUpdateData = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        // total_population: totalPopulation,
        // woman: women,
        // male: male,
        // total_number_of_families_below_poverty_line: povertyFamily,
        // total_number_of_families: totalFamily,
        // number_of_gram_panchayat_employees: grampanchyatEmployee,
        // number_of_employees_in_the_figure: numberOfEmployeeInFigure,
        expense_detail_schemes: [
          {
            type: "Gram Nidhi",
            date: gramNidhiDate,
            balance_fund: gramNidhi,
          },
          {
            type: "Supply",
            date: supplyDate,
            balance_fund: supply,
          },
          {
            type: "15th Finance Commission",
            date: financeCommissionDate,
            balance_fund: financeCommission,
          },
          {
            type: "Schedule Development NEO-Buddhist",
            date: neoBuddhistDate,
            balance_fund: neoBuddhist,
          },
          {
            type: "Tantamukti",
            date: tantamuktiDate,
            balance_fund: tantamukti,
          },
          {
            type: "M. GR. RO. H. Yo",
            date: mGrRoDate,
            balance_fund: mGrRo,
          },
          {
            type: "Public Facilities",
            date: publicConvenienceDate,
            balance_fund: publicConvenience,
          },
          {
            type: "Civil facilities",
            date: civilFacilitiesDate,
            balance_fund: civilFacilities,
          },
        ],
        // expense_detail_fund_type: [
        //   {
        //     type: "Gram Nidhi",
        //     audit_note_year: gramNidhiAuditYear,
        //     pending_no: gramNidhiPendingNo,
        //   },
        //   {
        //     type: "Other Plans",
        //     audit_note_year: otherPlansAuditYear,
        //     pending_no: otherPlansPendingNo,
        //   },
        // ],
      };
      console.log("expencedetails final Data", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_expense_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log(
        "details of expense update information response",
        response.data
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("error occured updata data", error);
    }
  };

  // updata data on server
  const handleDetailsOfExpenseDraft = async (e) => {
    const gramNidhiDateValid = formatDateString(gramNidhiDate);
    const supplyDateValid = formatDateString(supplyDate);
    const financeCommissionDateValid = formatDateString(financeCommissionDate);
    const neoBuddhistDateValid = formatDateString(neoBuddhistDate);
    const tantamuktiDateValid = formatDateString(tantamuktiDate);
    const mGrRoDateValid = formatDateString(mGrRoDate);
    const publicConvenienceDateValid = formatDateString(publicConvenienceDate);
    const civilFacilitiesDateValid = formatDateString(civilFacilitiesDate);

    e.preventDefault();

    // Check for invalid formats

    const invalidFormats =
      (!isNumeric(gramNidhi) && gramNidhi !== "") ||
      (!isNumeric(supply) && supply !== "") ||
      (!isNumeric(financeCommission) && financeCommission !== "") ||
      (!isNumeric(neoBuddhist) && neoBuddhist !== "") ||
      (!isNumeric(tantamukti) && tantamukti !== "") ||
      (!isNumeric(mGrRo) && mGrRo !== "") ||
      (!isNumeric(publicConvenience) && publicConvenience !== "") ||
      (!isNumeric(civilFacilities) && civilFacilities !== "") ||
      // (!isNumberOnly(totalPopulation) && totalPopulation !== "") ||
      // (!isNumberOnly(male) && male !== "") ||
      // (!isNumberOnly(women) && women !== "") ||
      // (!isNumberOnly(povertyFamily) && povertyFamily !== "") ||
      // (!isNumberOnly(totalFamily) && totalFamily !== "") ||
      // (!isNumberOnly(grampanchyatEmployee) && grampanchyatEmployee !== "") ||
      // (!isNumberOnly(numberOfEmployeeInFigure) &&
      //   numberOfEmployeeInFigure !== "") ||
      // (!isYear(gramNidhiAuditYear) && gramNidhiAuditYear !== "") ||
      // (!isNumberOnly(gramNidhiPendingNo) && gramNidhiPendingNo !== "") ||
      // (!isYear(otherPlansAuditYear) && otherPlansAuditYear !== "") ||
      // (!isNumberOnly(otherPlansPendingNo) && otherPlansPendingNo !== "") ||
      (!dateRegex.test(gramNidhiDateValid) && gramNidhiDate !== "") ||
      (!dateRegex.test(supplyDateValid) && supplyDate !== "") ||
      (!dateRegex.test(financeCommissionDateValid) &&
        financeCommissionDate !== "") ||
      (!dateRegex.test(neoBuddhistDateValid) && neoBuddhistDate !== "") ||
      (!dateRegex.test(tantamuktiDateValid) && tantamuktiDate !== "") ||
      (!dateRegex.test(mGrRoDateValid) && mGrRoDate !== "") ||
      (!dateRegex.test(publicConvenienceDateValid) &&
        publicConvenienceDate !== "") ||
      (!dateRegex.test(civilFacilitiesDateValid) && civilFacilitiesDate !== "");

    if (invalidFormats) {
      setValidated(true);
      return;
    }

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        // total_population: totalPopulation,
        // woman: women,
        // male: male,
        // total_number_of_families_below_poverty_line: povertyFamily,
        // total_number_of_families: totalFamily,
        // number_of_gram_panchayat_employees: grampanchyatEmployee,
        // number_of_employees_in_the_figure: numberOfEmployeeInFigure,
        expense_detail_schemes: [
          {
            type: "Gram Nidhi",
            date: gramNidhiDate,
            balance_fund: gramNidhi,
          },
          {
            type: "Supply",
            date: supplyDate,
            balance_fund: supply,
          },
          {
            type: "15th Finance Commission",
            date: financeCommissionDate,
            balance_fund: financeCommission,
          },
          {
            type: "Schedule Development NEO-Buddhist",
            date: neoBuddhistDate,
            balance_fund: neoBuddhist,
          },
          {
            type: "Tantamukti",
            date: tantamuktiDate,
            balance_fund: tantamukti,
          },
          {
            type: "M. GR. RO. H. Yo",
            date: mGrRoDate,
            balance_fund: mGrRo,
          },
          {
            type: "Public Facilities",
            date: publicConvenienceDate,
            balance_fund: publicConvenience,
          },
          {
            type: "Civil facilities",
            date: civilFacilitiesDate,
            balance_fund: civilFacilities,
          },
        ],
        // expense_detail_fund_type: [
        //   {
        //     type: "Gram Nidhi",
        //     audit_note_year: gramNidhiAuditYear,
        //     pending_no: gramNidhiPendingNo,
        //   },
        //   {
        //     type: "Other Plans",
        //     audit_note_year: otherPlansAuditYear,
        //     pending_no: otherPlansPendingNo,
        //   },
        // ],
      };
      console.log("expenseDetails draft final Data", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_expense_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("expenseDetails draft response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("error occured updata data", error);
    }
  };

  // const getCurrentDate = () => {
  //   const today = new Date();
  //   const dd = String(today.getDate()).padStart(2, "0");
  //   const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  //   const yyyy = today.getFullYear();
  //   return `${yyyy}-${mm}-${dd}`;
  // };

  const getCurrentDate = () => {
    const today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}`;
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>खर्चाचा तपशील</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container common-section">
          <div className="row">
            <div className="col-lg-12  mt-3">
              <div className="text-center">
                <p className="fw-bold mb-2">१.४ खर्च तपशील</p>
              </div>
              {/* <TabEco/> */}
            </div>
            <div className="col-lg-12 mt-4 mb-5">
              {/* eslint-disable-next-line react/jsx-pascal-case */}
              <div className="d-flex gap-5">
                <Village_Tal_Dist_Mahe villageDataInfo={villageDataInfo} />
                <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                  <label htmlFor="">माहे :</label>
                  {month === undefined ? (
                    <span></span>
                  ) : (
                    <select
                      name="month"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="fw-bold"
                    >
                      {/* <option value="" className="fw-bold">
                        महिना निवडा
                      </option> */}
                      <option value="1">जानेवारी</option>
                      <option value="2">फेब्रुवारी</option>
                      <option value="3">मार्च</option>
                      <option value="4">एप्रिल</option>
                      <option value="5">मे</option>
                      <option value="6">जून</option>
                      <option value="7">जुलै</option>
                      <option value="8">ऑगस्ट</option>
                      <option value="9">सप्टेंबर</option>
                      <option value="10">ऑक्टोंबर</option>
                      <option value="11">नोव्हेंबर</option>
                      <option value="12">डिसेंबर</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="table-main-sec">
                <p className="text-center d-flex gap-3 justify-content-center">
                  कीर्दी कोणत्या महिन्यापर्यंत लिहल्या आहेत (खर्च तपशील){" "}
                  {isDraft === "Y" ? (
                    <span className="fw-bold text-danger">is Drafted</span>
                  ) : isDraft === "N" ? (
                    <span className="table_title_top gap-0 submit_text1s">
                      Submitted{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">अ.क्र.</th>
                        <th scope="col">योजनेचे नाव</th>
                        <th scope="col">
                          दिनांक{" "}
                          {(validated && !gramNidhiDate) ||
                          (validated && !supplyDate) ||
                          (validated && !financeCommissionDate) ||
                          (validated && !neoBuddhistDate) ||
                          (validated && !tantamuktiDate) ||
                          (validated && !mGrRoDate) ||
                          (validated && !publicConvenienceDate) ||
                          (validated && !civilFacilitiesDate) ? (
                            <span
                              style={{ fontSize: "14px" }}
                              className="text-danger ms-4"
                            >
                              <i>दिनांक निवडा.</i>
                            </span>
                          ) : (
                            ""
                          )}
                        </th>
                        <th scope="col">शिल्लक निधी</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>१</td>
                        <td>ग्रामनिधी</td>
                        <td>
                          <input
                            type="date"
                            // name="gramNidhiDate"
                            value={gramNidhiDate}
                            onChange={(e) => setGramNidhiDate(e.target.value)}
                            className={`${
                              validated && !gramNidhiDate ? "is-invalid" : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            // max={getCurrentDate()}
                            min={`${getCurrentDate()}-01`} // Set min to the first day of the current month
                            max={`${getCurrentDate()}-31`}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={gramNidhi}
                            autoFocus
                            onChange={(e) => setGramNidhi(e.target.value)}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(gramNidhi)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(gramNidhi)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={gramNidhiRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>२</td>
                        <td>पाणीपुरवठा</td>
                        <td>
                          <input
                            type="date"
                            name="supplyDate"
                            value={supplyDate}
                            onChange={(e) => setSupplyDate(e.target.value)}
                            className={`${
                              validated && !supplyDate ? "is-invalid" : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            min={`${getCurrentDate()}-01`} // Set min to the first day of the current month
                            max={`${getCurrentDate()}-31`}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={supply}
                            onChange={(e) => setSupply(e.target.value)}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(supply)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(supply)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={supplyRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>३</td>
                        <td>१५ वा वित्त आयोग</td>
                        <td>
                          <input
                            type="date"
                            name="financeCommissionDate"
                            value={financeCommissionDate}
                            onChange={(e) =>
                              setFinanceCommissionDate(e.target.value)
                            }
                            className={`${
                              validated && !financeCommissionDate
                                ? "is-invalid"
                                : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            min={`${getCurrentDate()}-01`} // Set min to the first day of the current month
                            max={`${getCurrentDate()}-31`}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={financeCommission}
                            onChange={(e) =>
                              setFinanceCommission(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(financeCommission)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(financeCommission)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={financeCommissionRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>४</td>
                        <td>अनुसूचीत जा. व नवबौद्ध घटक विकास</td>
                        <td>
                          <input
                            type="date"
                            name="neoBuddhistDate"
                            value={neoBuddhistDate}
                            onChange={(e) => setNeoBuddhistDate(e.target.value)}
                            className={`${
                              validated && !neoBuddhistDate ? "is-invalid" : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            min={`${getCurrentDate()}-01`} // Set min to the first day of the current month
                            max={`${getCurrentDate()}-31`}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={neoBuddhist}
                            onChange={(e) => setNeoBuddhist(e.target.value)}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(neoBuddhist)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(neoBuddhist)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={neoBuddhistRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>५</td>
                        <td>तंटामुक्त्ती</td>
                        <td>
                          <input
                            type="date"
                            name="tantamuktiDate"
                            value={tantamuktiDate}
                            onChange={(e) => setTantamuktiDate(e.target.value)}
                            className={`${
                              validated && !tantamuktiDate ? "is-invalid" : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            min={`${getCurrentDate()}-01`}
                            max={`${getCurrentDate()}-31`}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={tantamukti}
                            onChange={(e) => setTantamukti(e.target.value)}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(tantamukti)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(tantamukti)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={tantamuktiRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>६</td>
                        <td>म. ग्रा. रो. ह. यो</td>
                        <td>
                          <input
                            type="date"
                            name="mGrRoDate"
                            value={mGrRoDate}
                            onChange={(e) => setMGrRoDate(e.target.value)}
                            className={`${
                              validated && !mGrRoDate ? "is-invalid" : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            min={`${getCurrentDate()}-01`}
                            max={`${getCurrentDate()}-31`}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={mGrRo}
                            onChange={(e) => setMgrRo(e.target.value)}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(mGrRo)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(mGrRo) ? "is-invalid" : ""
                            }`}
                            ref={mGrRoRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>७</td>
                        <td>जनसुविधा</td>
                        <td>
                          <input
                            type="date"
                            name="publicConvenienceDate"
                            value={publicConvenienceDate}
                            onChange={(e) =>
                              setPublicConvenienceDate(e.target.value)
                            }
                            className={`${
                              validated && !publicConvenienceDate
                                ? "is-invalid"
                                : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            min={`${getCurrentDate()}-01`}
                            max={`${getCurrentDate()}-31`}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={publicConvenience}
                            onChange={(e) =>
                              setPublicConvenience(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(publicConvenience)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(publicConvenience)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={publicConvenienceRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>८</td>
                        <td>नागरी सुविधा</td>
                        <td>
                          <input
                            type="date"
                            name="civilFacilitiesDate"
                            value={civilFacilitiesDate}
                            onChange={(e) =>
                              setCivilFacilitiesDate(e.target.value)
                            }
                            className={`${
                              validated && !civilFacilitiesDate
                                ? "is-invalid"
                                : ""
                            } form-control table-input-box `}
                            placeholder="dd-mm-yy"
                            min={`${getCurrentDate()}-01`}
                            max={`${getCurrentDate()}-31`}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={civilFacilities}
                            onChange={(e) => setCivilFacilities(e.target.value)}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(civilFacilities)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(civilFacilities)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={civilFacilitiesRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>९</td>
                        <td className="fw-bold">एकूण</td>
                        <td>
                          {/* <input
                          type="text"
                          className="form-control table-input-box"
                          readOnly
                        /> */}
                        </td>
                        <td>
                          <input
                            type="number"
                            value={total}
                            readOnly
                            className="form-control fw-bold table-input-box"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12 mt-4">
              <div className="table-division d-flex">
                <div className="col-lg-4">
                  <div className="total-people table-box border d-flex align-items-center">
                    <label>एकूण लोकसंख्या :</label>
                    <input
                      type="text"
                      value={totalPopulation}
                      onChange={(e) => setTotalPopulation(e.target.value)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumberOnly(totalPopulation)
                          ? "कृपया वैध क्रमांक प्रविष्ट करा."
                          : ""
                      }
                      className={`form-control ms-3  table-input-box ${
                        validated && !isNumberOnly(totalPopulation)
                          ? "is-invalid"
                          : ""
                      }`}
                      ref={totalPopulationRef}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="ladies-box table-box border d-flex align-items-center">
                    <label>स्त्री :</label>
                    <input
                      type="text"
                      value={women}
                      onChange={(e) => setWomen(e.target.value)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumberOnly(women)
                          ? "कृपया वैध क्रमांक प्रविष्ट करा."
                          : ""
                      }
                      className={`form-control ms-3  table-input-box ${
                        validated && !isNumberOnly(women) ? "is-invalid" : ""
                      }`}
                      ref={womenRef}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="gents-box table-box border d-flex align-items-center">
                    <label>पुरुष :</label>
                    <input
                      type="text"
                      value={male}
                      onChange={(e) => setMale(e.target.value)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumberOnly(male)
                          ? "कृपया वैध क्रमांक प्रविष्ट करा."
                          : ""
                      }
                      className={`form-control ms-3  table-input-box ${
                        validated && !isNumberOnly(male) ? "is-invalid" : ""
                      }`}
                      ref={maleRef}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="table-division d-flex">
                <div className="col-lg-6">
                  <div className="total-poverty table-box border d-flex align-items-center">
                    <label>एकूण दारिद्र्य रेषेखालील कुटुंब संख्या :</label>
                    <input
                      type="text"
                      value={povertyFamily}
                      onChange={(e) => setPovertyFamily(e.target.value)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumberOnly(povertyFamily)
                          ? "कृपया वैध क्रमांक प्रविष्ट करा."
                          : ""
                      }
                      className={`form-control ms-3  table-input-box ${
                        validated && !isNumberOnly(povertyFamily)
                          ? "is-invalid"
                          : ""
                      }`}
                      ref={povertyFamilyRef}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="total-family table-box border d-flex align-items-center">
                    <label>एकूण कुटुंब संख्या :</label>
                    <input
                      type="text"
                      value={totalFamily}
                      onChange={(e) => setTotalFamily(e.target.value)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumberOnly(totalFamily)
                          ? "कृपया वैध क्रमांक प्रविष्ट करा."
                          : ""
                      }
                      className={`form-control ms-3  table-input-box ${
                        validated && !isNumberOnly(totalFamily)
                          ? "is-invalid"
                          : ""
                      }`}
                      ref={totalFamilyRef}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="table-division d-flex">
                <div className="col-lg-6">
                  <div className="GramPanchayatStaff table-box border d-flex align-items-center">
                    <label>ग्रामपंचायत कर्मचारी संख्या :</label>
                    <input
                      type="text"
                      value={grampanchyatEmployee}
                      onChange={(e) => setGrampanchyatEmployee(e.target.value)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumberOnly(grampanchyatEmployee)
                          ? "कृपया वैध क्रमांक प्रविष्ट करा."
                          : ""
                      }
                      className={`form-control ms-3  table-input-box ${
                        validated && !isNumberOnly(grampanchyatEmployee)
                          ? "is-invalid"
                          : ""
                      }`}
                      ref={grampanchyatEmployeeRef}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="employeesInFigure table-box border d-flex align-items-center">
                    <label>आकृतीबांधतील कर्मचारी संख्या :</label>
                    <input
                      type="text"
                      value={numberOfEmployeeInFigure}
                      onChange={(e) =>
                        setNumberOfEmployeeInFigure(e.target.value)
                      }
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumberOnly(numberOfEmployeeInFigure)
                          ? "कृपया वैध क्रमांक प्रविष्ट करा."
                          : ""
                      }
                      className={`form-control ms-3  table-input-box ${
                        validated && !isNumberOnly(numberOfEmployeeInFigure)
                          ? "is-invalid"
                          : ""
                      }`}
                      ref={numberOfEmployeeInFigureRef}
                      style={{ width: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-5 mb-4">
              <div className="table-main-sec">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">अ.क्र.</th>
                        <th scope="col">निधीचा प्रकार</th>
                        <th scope="col">ऑडिट नोट वर्ष</th>
                        <th scope="col">प्रलंबित शक संख्या</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>१</td>
                        <td>ग्रामनिधी / पणीपुरवठा</td>
                        <td>
                          <input
                            type="text"
                            value={gramNidhiAuditYear}
                            onChange={(e) =>
                              setGramNidhiAuditYear(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isYear(gramNidhiAuditYear)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control   table-input-box ${
                              validated && !isYear(gramNidhiAuditYear)
                                ? "is-invalid"
                                : ""
                            }`}
                            maxLength={9}
                            ref={gramNidhiAuditYearRef}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={gramNidhiPendingNo}
                            onChange={(e) =>
                              setGramNidhiPendingNo(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*", "."].includes(
                                evt.key
                              ) && evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumberOnly(gramNidhiPendingNo)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control   table-input-box ${
                              validated && !isNumberOnly(gramNidhiPendingNo)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={gramNidhiPendingNoRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>२</td>
                        <td>इतर योजना</td>
                        <td>
                          <input
                            type="text"
                            value={otherPlansAuditYear}
                            onChange={(e) =>
                              setOtherPlansAuditYear(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isYear(otherPlansAuditYear)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control   table-input-box ${
                              validated && !isYear(otherPlansAuditYear)
                                ? "is-invalid"
                                : ""
                            }`}
                            maxLength={9}
                            ref={otherPlansAuditYearRef}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={otherPlansPendingNo}
                            onChange={(e) =>
                              setOtherPlansPendingNo(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*", "."].includes(
                                evt.key
                              ) && evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumberOnly(otherPlansPendingNo)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control   table-input-box ${
                              validated && !isNumberOnly(otherPlansPendingNo)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={otherPlansPendingNoRef}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12">
              <FooterDevOfficer villageDataInfo={villageDataInfo} />
            </div>
            <div className="col-lg-12 d-flex mt-4 justify-content-end align-items-center">
              {/* <FormBtn
                resetForm={handleDataReset}
                handleSubmit={handleUpdateData}
                handleDraft={handleDetailsOfExpenseDraft}
              /> */}
              
              {(month === currentMonth.toString() && !isLoading)  &&
                (isDraft === "Y" || isDraft === "") && (
                  <div className="col-lg-12 d-flex justify-content-end align-items=center">
                    <FormBtn
                      resetForm={handleDataReset}
                      handleSubmit={handleFormSubmission}
                      handleDraft={handleDetailsOfExpenseDraft}
                    />
                  </div>
                )}
              {isDraft === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateData}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default DetailsOfExpenses;
