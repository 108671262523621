/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import html2pdf from "html2pdf.js";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SheetB from "./SheetB";
import SheetC_D from "./SheetC_D";
import SheetE from "./SheetE";
import SheetF from "./SheetF";
import SheetG from "./SheetG";
import SheetA from "./SheetA";

import styles from "./pdf.module.css";

// import {
//   Document,
//   Page,
//   Text,
//   View,
//   PDFDownloadLink,
//   Font,
// } from "@react-pdf/renderer";

// import NotoSerifDevanagariFont from "../../assets/fonts/NotoSerifDevanagari-VariableFont_wdth,wght.ttf";

// Font.register({
//   family: "Noto Serif Devanagari",
//   src: NotoSerifDevanagariFont,
// });

const MainVarshikAhval = () => {
  // const MyDocument = (
  //   <Document>
  //     <Page size="A4" className={styles.pdf_page} orientation="landscape">
  //       <View style={{ margin: 10, padding: 10, flexGrow: 1 }}>
  //         <Text>PDF generated from HTML Table</Text>
  //         <div className={styles.pdf_table}>
  //           <div className={styles.pdf_tableRow}>
  //             <div className={styles.pdf_tableCell}>
  //               <Text>अनु क्रमांक</Text>
  //             </div>
  //             <div className={styles.pdf_tableCell}>
  //               <Text>पंचयात समितीचे नाव</Text>
  //             </div>
  //           </div>

  //           {/* Table Body */}
  //           <div className={styles.pdf_tableRow}>
  //             <div className={styles.pdf_tableCell}>
  //               <Text>1</Text>
  //             </div>
  //             <div className={styles.pdf_tableCell}>
  //               <Text>जत</Text>
  //             </div>
  //           </div>
  //         </div>
  //       </View>
  //     </Page>
  //   </Document>
  // );

  // const generatePDF = async () => {
  //   // Wait for styles to load
  //   await new Promise((resolve) => setTimeout(resolve, 1000));

  //   const input = document.getElementById("invoice-content");
  //   const pdf = new jsPDF("l", "mm", "a4");
  //   const margin = 5; // Set your desired margin size
  //   const tables = input.querySelectorAll(".table-responsive");
  //   const spaceBetweenTables = 0;

  //   for (let i = 0; i < tables.length; i += 2) {
  //     const table1 = tables[i];
  //     const table2 = tables[i + 1];

  //     if (!table1 || !table2) break; // Exit loop if either table is missing

  //     const canvas1 = await html2canvas(table1);
  //     const imgData1 = canvas1.toDataURL("image/jpeg", 1.0);

  //     let pdfWidth = pdf.internal.pageSize.getWidth();
  //     let pdfHeight = pdfWidth / (canvas1.width / canvas1.height);

  //     // Add margin space at the top
  //     pdfHeight += margin;

  //     pdf.addImage(
  //       imgData1,
  //       "JPEG",
  //       margin,
  //       margin,
  //       pdfWidth - 2 * margin,
  //       pdfHeight - 2 * margin
  //     );

  //     const canvas2 = await html2canvas(table2);
  //     const imgData2 = canvas2.toDataURL("image/jpeg", 1.0);
  //     pdfHeight += spaceBetweenTables;

  //     // Add margin space at the top of the second table
  //     pdfHeight += margin;

  //     pdf.addImage(
  //       imgData2,
  //       "JPEG",
  //       margin,
  //       pdfHeight,
  //       pdfWidth - 2 * margin,
  //       pdfHeight - 2 * margin
  //     );

  //     pdfHeight -= spaceBetweenTables;
  //     if (i + 2 < tables.length) {
  //       pdf.addPage();
  //     }
  //   }

  //   pdf.save("download.pdf");
  // };

  const generatePDF = () => {
    const content = document.getElementById("pdf-content"); // id of the element containing your content

    // Additional configuration options, including setting orientation to landscape
    const options = {
      margin: 10,
      filename: "your_document.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a2", orientation: "landscape" }, // Set orientation to landscape
    };

    html2pdf().from(content).set(options).save();
  };

  return (
    <>
      <div id="pdf-content">
        <Helmet>
          <title>ग्रामपंचायत रचना दर्शविणारे</title>
        </Helmet>
        {/* <PDFDownloadLink
          className="d_f_c_btn4"
          document={MyDocument}
          fileName="exported_data.pdf"
        >
          {({ blob, url, loading, error }) => (loading ? "PDF" : "PDF")}
        </PDFDownloadLink> */}
        <SheetA />
        {/* <SheetB />
        <SheetC_D />
        <SheetE />
        <SheetF />
        <SheetG /> */}
        <div className="mb-5">
          <button className="btn btn-primary" onClick={generatePDF}>
            Download
          </button>
        </div>
      </div>
    </>
  );
};

export default MainVarshikAhval;
