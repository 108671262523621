/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
import "../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import FormBtn from "../../component/buttons/Form/FormBtn";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import Loader from "../../component/Loader/Loader";
import { FaEdit } from "react-icons/fa";
import ContentWrapper from "../../component/ContentWrapper/ContentWrapper";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import styles from "./style.module.css";
import { ThDerivedIcon } from "evergreen-ui";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import { MdOutlineArrowDownward } from "react-icons/md";
const SheetE = ({
  handleNextStep,
  handlePrevStep,
  currentStep,
  handleStepDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [economicData, setEconomicData] = useState("");
  const [sheetData, setSheetData] = useState({
    sr_no: "",
    panchayat_name: "",
    gpt_name: "",
    water_supply: "",
    cleaning_of_public_roads: "",
    arrangement_of_burial_ground: "",
    playgrounds_or_public_parks: "",
    disposal_derelict_carcasses: "",
    public_forum: "",
    epidemic_diseases: "",
    maternal_or_child_care: "",
    medical_help: "",
    vaccination_humans_cattle: "",
    sanitation_health_scheme: "",
    total_three_to_thirteen: "",
    removal_encroachments: "",
    repair_roads_dams: "",
    public_buildings_tanks: "",
    street_lamp: "",
    jatra_bazaar_bases: "",
    eat_slaughter: "",
    planting_trees: "",
    religion_school: "",
    construction_ghats_on_river: "",
    market: "",
    houses_for_cleaning_worker: "",
    useful_ground_for_camping: "",
    cattle_sheds: "",
    drought_works: "",
    village_area_expansion: "",
    wakhari: "",
    other_matter_public_work: "",
    total_fifteen_to_thirtyone: "",
    education: "",
    clubs_entertainment: "",
    theaters: "",
    libraries: "",
    prohibition_of_alcohol: "",
    removal_of_untouchability: "",
    improve_condition_people: "",
    gambling_money_laundering: "",
    specify_matter: "",
    sum_thirythree_to_fourtyone: "",
    patrolling_or_guarding: "",
    controlling_industries_works: "",
    fire_protecting: "",
    specify_other_matters: "",
    sum_fourtythree_to_fourtysix: "",
    servant_class: "",
    numbering_of_seats: "",
    measure: "",
    regulate_fairs: "",
    holding_office_panchayat: "",
    registration_of_births: "",
    specifying_the_matter: "",
    sum_fouryeight_to_fiftythree: "",
    land_reform_scheme: "",
    helping_sick_person: "",
    assisting_natural_calamities: "",
    coperative_arrangement_land: "",
    association_of_credit_unions: "",
    making_waste_land: "",
    establish_shram_organization: "",
    gram_nidhi: "",
    debt_to_specify_matter: "",
    sum_fiftyfive_to_sixtythree: "",
    establishing_barns: "",
    waste_land_under_cultivation: "",
    increas_agricultural_production: "",
    making_fertilizers: "",
    improved_seeds: "",
    improved_seeds_production: "",
    community_farming: "",
    crop_protection: "",
    minor_irrigation: "",
    improvement_village_forests: "",
    cattle_improvement: "",
    specified_matters: "",
    sum_sixtyfive_to_seventysix: "",
    village_industry: "",
    total_expanses_in_year: "",
    expanses_previous_year: "",
    increase_decrease: "",
    total_servant_expanse: "",
    shera: "",
  });

  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetA response", response.data.data);
      if (response.data.status === true) {
        const data = response.data.data.form_e_data;
        const otherData = response.data.data.other_data;
        const defaultSheetData = {
          sr_no: "1",
          panchayat_name: otherData.taluka_name,
          gpt_name: otherData.village_name,
          // ... (other fields with default values)
        };
        if (data !== null) {
          setSheetData({
            ...defaultSheetData,
            water_supply: data.water_supply,
            cleaning_of_public_roads: data.cleaning_of_public_roads,
            arrangement_of_burial_ground: data.arrangement_of_burial_ground,
            playgrounds_or_public_parks: data.playgrounds_or_public_parks,
            disposal_derelict_carcasses: data.disposal_derelict_carcasses,
            public_forum: data.public_forum,
            epidemic_diseases: data.epidemic_diseases,
            maternal_or_child_care: data.maternal_or_child_care,
            medical_help: data.medical_help,
            vaccination_humans_cattle: data.vaccination_humans_cattle,
            sanitation_health_scheme: data.sanitation_health_scheme,
            total_three_to_thirteen: "",
            removal_encroachments: data.removal_encroachments,
            repair_roads_dams: data.repair_roads_dams,
            public_buildings_tanks: data.public_buildings_tanks,
            street_lamp: data.street_lamp,
            jatra_bazaar_bases: data.jatra_bazaar_bases,
            eat_slaughter: data.eat_slaughter,
            planting_trees: data.planting_trees,
            religion_school: data.religion_school,
            construction_ghats_on_river: data.construction_ghats_on_river,
            market: data.market,
            houses_for_cleaning_worker: data.houses_for_cleaning_worker,
            useful_ground_for_camping: data.useful_ground_for_camping,
            cattle_sheds: data.cattle_sheds,
            drought_works: data.drought_works,
            village_area_expansion: data.village_area_expansion,
            wakhari: data.wakhari,
            other_matter_public_work: data.other_matter_public_work,
            total_fifteen_to_thirtyone: "",
            education: data.education,
            clubs_entertainment: data.clubs_entertainment,
            theaters: data.theaters,
            libraries: data.libraries,
            prohibition_of_alcohol: data.prohibition_of_alcohol,
            removal_of_untouchability: data.removal_of_untouchability,
            improve_condition_people: data.improve_condition_people,
            gambling_money_laundering: data.gambling_money_laundering,
            specify_matter: data.specify_matter,
            sum_thirythree_to_fourtyone: "",
            patrolling_or_guarding: data.patrolling_or_guarding,
            controlling_industries_works: data.controlling_industries_works,
            fire_protecting: data.fire_protecting,
            specify_other_matters: data.specify_other_matters,
            sum_fourtythree_to_fourtysix: "",
            servant_class: data.servant_class,
            numbering_of_seats: data.numbering_of_seats,
            measure: data.measure,
            regulate_fairs: data.regulate_fairs,
            holding_office_panchayat: data.holding_office_panchayat,
            registration_of_births: data.registration_of_births,
            specifying_the_matter: data.specifying_the_matter,
            sum_fouryeight_to_fiftythree: "",
            land_reform_scheme: data.land_reform_scheme,
            helping_sick_person: data.helping_sick_person,
            assisting_natural_calamities: data.assisting_natural_calamities,
            coperative_arrangement_land: data.coperative_arrangement_land,
            association_of_credit_unions: data.association_of_credit_unions,
            making_waste_land: data.making_waste_land,
            establish_shram_organization: data.establish_shram_organization,
            gram_nidhi: data.gram_nidhi,
            debt_to_specify_matter: data.debt_to_specify_matter,
            sum_fiftyfive_to_sixtythree: "",
            establishing_barns: data.establishing_barns,
            waste_land_under_cultivation: data.waste_land_under_cultivation,
            increas_agricultural_production:
              data.increas_agricultural_production,
            making_fertilizers: data.making_fertilizers,
            improved_seeds: data.improved_seeds,
            improved_seeds_production: data.improved_seeds_production,
            community_farming: data.community_farming,
            crop_protection: data.crop_protection,
            minor_irrigation: data.minor_irrigation,
            improvement_village_forests: data.improvement_village_forests,
            cattle_improvement: data.cattle_improvement,
            specified_matters: data.specified_matters,
            sum_sixtyfive_to_seventysix: "",
            village_industry: data.village_industry,
            total_expanses_in_year: data.total_expanses_in_year,
            expanses_previous_year: data.expanses_previous_year,
            increase_decrease: data.increase_decrease,
            total_servant_expanse: "",
            shera: data.shera,
          });
        } else {
          setSheetData({
            ...defaultSheetData,
            water_supply: "",
            cleaning_of_public_roads: "",
            arrangement_of_burial_ground: "",
            playgrounds_or_public_parks: "",
            disposal_derelict_carcasses: "",
            public_forum: "",
            epidemic_diseases: "",
            maternal_or_child_care: "",
            medical_help: "",
            vaccination_humans_cattle: "",
            sanitation_health_scheme: "",
            total_three_to_thirteen: "",
            removal_encroachments: "",
            repair_roads_dams: "",
            public_buildings_tanks: "",
            street_lamp: "",
            jatra_bazaar_bases: "",
            eat_slaughter: "",
            planting_trees: "",
            religion_school: "",
            construction_ghats_on_river: "",
            market: "",
            houses_for_cleaning_worker: "",
            useful_ground_for_camping: "",
            cattle_sheds: "",
            drought_works: "",
            village_area_expansion: "",
            wakhari: "",
            other_matter_public_work: "",
            total_fifteen_to_thirtyone: "",
            education: "",
            clubs_entertainment: "",
            theaters: "",
            libraries: "",
            prohibition_of_alcohol: "",
            removal_of_untouchability: "",
            improve_condition_people: "",
            gambling_money_laundering: "",
            specify_matter: "",
            sum_thirythree_to_fourtyone: "",
            patrolling_or_guarding: "",
            controlling_industries_works: "",
            fire_protecting: "",
            specify_other_matters: "",
            sum_fourtythree_to_fourtysix: "",
            servant_class: "",
            numbering_of_seats: "",
            measure: "",
            regulate_fairs: "",
            holding_office_panchayat: "",
            registration_of_births: "",
            specifying_the_matter: "",
            sum_fouryeight_to_fiftythree: "",
            land_reform_scheme: "",
            helping_sick_person: "",
            assisting_natural_calamities: "",
            coperative_arrangement_land: "",
            association_of_credit_unions: "",
            making_waste_land: "",
            establish_shram_organization: "",
            gram_nidhi: "",
            debt_to_specify_matter: "",
            sum_fiftyfive_to_sixtythree: "",
            establishing_barns: "",
            waste_land_under_cultivation: "",
            increas_agricultural_production: "",
            making_fertilizers: "",
            improved_seeds: "",
            improved_seeds_production: "",
            community_farming: "",
            crop_protection: "",
            minor_irrigation: "",
            improvement_village_forests: "",
            cattle_improvement: "",
            specified_matters: "",
            sum_sixtyfive_to_seventysix: "",
            village_industry: "",
            total_expanses_in_year: "",
            expanses_previous_year: "",
            increase_decrease: "",
            total_servant_expanse: "",
            shera: "",
          });
        }
      }

      if (
        response.data.data.form_e_data === null ||
        response.data.data.form_e_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_e_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value }));
  };

  // table validation
  const validateForm = () => {
    const newErrors = {};

    // Validate tabels all input

    if (
      sheetData.water_supply !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.water_supply)
    ) {
      newErrors.water_supply = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.cleaning_of_public_roads !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.cleaning_of_public_roads)
    ) {
      newErrors.cleaning_of_public_roads = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.arrangement_of_burial_ground !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.arrangement_of_burial_ground)
    ) {
      newErrors.arrangement_of_burial_ground =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.playgrounds_or_public_parks !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.playgrounds_or_public_parks)
    ) {
      newErrors.playgrounds_or_public_parks = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.disposal_derelict_carcasses !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.disposal_derelict_carcasses)
    ) {
      newErrors.disposal_derelict_carcasses = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.public_forum !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.public_forum)
    ) {
      newErrors.public_forum = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.epidemic_diseases !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.epidemic_diseases)
    ) {
      newErrors.epidemic_diseases = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.maternal_or_child_care !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.maternal_or_child_care)
    ) {
      newErrors.maternal_or_child_care = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.medical_help !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.medical_help)
    ) {
      newErrors.medical_help = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.vaccination_humans_cattle !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.vaccination_humans_cattle)
    ) {
      newErrors.vaccination_humans_cattle = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.sanitation_health_scheme !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.sanitation_health_scheme)
    ) {
      newErrors.sanitation_health_scheme = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.removal_encroachments !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.removal_encroachments)
    ) {
      newErrors.removal_encroachments = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.repair_roads_dams !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.repair_roads_dams)
    ) {
      newErrors.repair_roads_dams = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.public_buildings_tanks !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.public_buildings_tanks)
    ) {
      newErrors.public_buildings_tanks = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.street_lamp !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.street_lamp)
    ) {
      newErrors.street_lamp = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.jatra_bazaar_bases !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.jatra_bazaar_bases)
    ) {
      newErrors.jatra_bazaar_bases = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.eat_slaughter !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.eat_slaughter)
    ) {
      newErrors.eat_slaughter = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.planting_trees !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.planting_trees)
    ) {
      newErrors.planting_trees = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.religion_school !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.religion_school)
    ) {
      newErrors.religion_school = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.construction_ghats_on_river !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.construction_ghats_on_river)
    ) {
      newErrors.construction_ghats_on_river = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.market !== "" && !/^-?\d*\.?\d+$/.test(sheetData.market)) {
      newErrors.market = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.houses_for_cleaning_worker !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.houses_for_cleaning_worker)
    ) {
      newErrors.houses_for_cleaning_worker = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.useful_ground_for_camping !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.useful_ground_for_camping)
    ) {
      newErrors.useful_ground_for_camping = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.cattle_sheds !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.cattle_sheds)
    ) {
      newErrors.cattle_sheds = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.drought_works !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.drought_works)
    ) {
      newErrors.drought_works = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.village_area_expansion !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.village_area_expansion)
    ) {
      newErrors.village_area_expansion = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.wakhari !== "" && !/^-?\d*\.?\d+$/.test(sheetData.wakhari)) {
      newErrors.wakhari = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_matter_public_work !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_matter_public_work)
    ) {
      newErrors.other_matter_public_work = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.education !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.education)
    ) {
      newErrors.education = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.clubs_entertainment !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.clubs_entertainment)
    ) {
      newErrors.clubs_entertainment = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.theaters !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.theaters)
    ) {
      newErrors.theaters = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.libraries !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.libraries)
    ) {
      newErrors.libraries = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.prohibition_of_alcohol !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.prohibition_of_alcohol)
    ) {
      newErrors.prohibition_of_alcohol = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.removal_of_untouchability !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.removal_of_untouchability)
    ) {
      newErrors.removal_of_untouchability = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.improve_condition_people !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.improve_condition_people)
    ) {
      newErrors.improve_condition_people = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.gambling_money_laundering !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.gambling_money_laundering)
    ) {
      newErrors.gambling_money_laundering = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.specify_matter !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.specify_matter)
    ) {
      newErrors.specify_matter = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.patrolling_or_guarding !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.patrolling_or_guarding)
    ) {
      newErrors.patrolling_or_guarding = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.controlling_industries_works !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.controlling_industries_works)
    ) {
      newErrors.controlling_industries_works =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fire_protecting !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fire_protecting)
    ) {
      newErrors.fire_protecting = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.specify_other_matters !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.specify_other_matters)
    ) {
      newErrors.specify_other_matters = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.servant_class !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.servant_class)
    ) {
      newErrors.servant_class = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.numbering_of_seats !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.numbering_of_seats)
    ) {
      newErrors.numbering_of_seats = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.measure !== "" && !/^-?\d*\.?\d+$/.test(sheetData.measure)) {
      newErrors.measure = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.regulate_fairs !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.regulate_fairs)
    ) {
      newErrors.regulate_fairs = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.holding_office_panchayat !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.holding_office_panchayat)
    ) {
      newErrors.holding_office_panchayat = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.registration_of_births !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.registration_of_births)
    ) {
      newErrors.registration_of_births = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.specifying_the_matter !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.specifying_the_matter)
    ) {
      newErrors.specifying_the_matter = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.land_reform_scheme !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.land_reform_scheme)
    ) {
      newErrors.land_reform_scheme = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.helping_sick_person !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.helping_sick_person)
    ) {
      newErrors.helping_sick_person = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.assisting_natural_calamities !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.assisting_natural_calamities)
    ) {
      newErrors.assisting_natural_calamities =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.coperative_arrangement_land !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.coperative_arrangement_land)
    ) {
      newErrors.coperative_arrangement_land = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.association_of_credit_unions !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.association_of_credit_unions)
    ) {
      newErrors.association_of_credit_unions =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.making_waste_land !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.making_waste_land)
    ) {
      newErrors.making_waste_land = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.establish_shram_organization !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.establish_shram_organization)
    ) {
      newErrors.establish_shram_organization =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.gram_nidhi !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.gram_nidhi)
    ) {
      newErrors.gram_nidhi = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.debt_to_specify_matter !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.debt_to_specify_matter)
    ) {
      newErrors.debt_to_specify_matter = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.establishing_barns !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.establishing_barns)
    ) {
      newErrors.establishing_barns = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.waste_land_under_cultivation !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.waste_land_under_cultivation)
    ) {
      newErrors.waste_land_under_cultivation =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.increas_agricultural_production !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.increas_agricultural_production)
    ) {
      newErrors.increas_agricultural_production =
        "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.making_fertilizers !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.making_fertilizers)
    ) {
      newErrors.making_fertilizers = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.improved_seeds !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.improved_seeds)
    ) {
      newErrors.improved_seeds = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.improved_seeds_production !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.improved_seeds_production)
    ) {
      newErrors.improved_seeds_production = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.community_farming !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.community_farming)
    ) {
      newErrors.community_farming = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.crop_protection !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.crop_protection)
    ) {
      newErrors.crop_protection = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.minor_irrigation !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.minor_irrigation)
    ) {
      newErrors.minor_irrigation = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.improvement_village_forests !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.improvement_village_forests)
    ) {
      newErrors.improvement_village_forests = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.cattle_improvement !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.cattle_improvement)
    ) {
      newErrors.cattle_improvement = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.specified_matters !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.specified_matters)
    ) {
      newErrors.specified_matters = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.village_industry !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.village_industry)
    ) {
      newErrors.village_industry = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.total_expanses_in_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.total_expanses_in_year)
    ) {
      newErrors.total_expanses_in_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.expanses_previous_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.expanses_previous_year)
    ) {
      newErrors.expanses_previous_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.increase_decrease !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.increase_decrease)
    ) {
      newErrors.increase_decrease = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.shera !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.shera)
    ) {
      newErrors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }

    // console.log("newErrors", newErrors);
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // update api

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_e_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          water_supply: sheetData.water_supply,
          cleaning_of_public_roads: sheetData.cleaning_of_public_roads,
          arrangement_of_burial_ground: sheetData.arrangement_of_burial_ground,
          playgrounds_or_public_parks: sheetData.playgrounds_or_public_parks,
          disposal_derelict_carcasses: sheetData.disposal_derelict_carcasses,
          public_forum: sheetData.public_forum,
          epidemic_diseases: sheetData.epidemic_diseases,
          maternal_or_child_care: sheetData.maternal_or_child_care,
          medical_help: sheetData.medical_help,
          vaccination_humans_cattle: sheetData.vaccination_humans_cattle,
          sanitation_health_scheme: sheetData.sanitation_health_scheme,
          total_three_to_thirteen: totalAdditionFirst,
          removal_encroachments: sheetData.removal_encroachments,
          repair_roads_dams: sheetData.repair_roads_dams,
          public_buildings_tanks: sheetData.public_buildings_tanks,
          street_lamp: sheetData.street_lamp,
          jatra_bazaar_bases: sheetData.jatra_bazaar_bases,
          eat_slaughter: sheetData.eat_slaughter,
          planting_trees: sheetData.planting_trees,
          religion_school: sheetData.religion_school,
          construction_ghats_on_river: sheetData.construction_ghats_on_river,
          market: sheetData.market,
          houses_for_cleaning_worker: sheetData.houses_for_cleaning_worker,
          useful_ground_for_camping: sheetData.useful_ground_for_camping,
          cattle_sheds: sheetData.cattle_sheds,
          drought_works: sheetData.drought_works,
          village_area_expansion: sheetData.village_area_expansion,
          wakhari: sheetData.wakhari,
          other_matter_public_work: sheetData.other_matter_public_work,
          total_fifteen_to_thirtyone: totalAdditionSecond,
          education: sheetData.education,
          clubs_entertainment: sheetData.clubs_entertainment,
          theaters: sheetData.theaters,
          libraries: sheetData.libraries,
          prohibition_of_alcohol: sheetData.prohibition_of_alcohol,
          removal_of_untouchability: sheetData.removal_of_untouchability,
          improve_condition_people: sheetData.improve_condition_people,
          gambling_money_laundering: sheetData.gambling_money_laundering,
          specify_matter: sheetData.specified_matters,
          sum_thirythree_to_fourtyone: totalAdditionThird,
          patrolling_or_guarding: sheetData.patrolling_or_guarding,
          controlling_industries_works: sheetData.controlling_industries_works,
          fire_protecting: sheetData.fire_protecting,
          specify_other_matters: sheetData.specify_other_matters,
          sum_fourtythree_to_fourtysix: totalAdditionThirdOne,
          servant_class: sheetData.servant_class,
          numbering_of_seats: sheetData.numbering_of_seats,
          measure: sheetData.measure,
          regulate_fairs: sheetData.regulate_fairs,
          holding_office_panchayat: sheetData.holding_office_panchayat,
          registration_of_births: sheetData.registration_of_births,
          specifying_the_matter: sheetData.specifying_the_matter,
          sum_fouryeight_to_fiftythree: totalAdditionFourth,
          land_reform_scheme: sheetData.land_reform_scheme,
          helping_sick_person: sheetData.helping_sick_person,
          assisting_natural_calamities: sheetData.assisting_natural_calamities,
          coperative_arrangement_land: sheetData.coperative_arrangement_land,
          association_of_credit_unions: sheetData.association_of_credit_unions,
          making_waste_land: sheetData.making_waste_land,
          establish_shram_organization: sheetData.establish_shram_organization,
          gram_nidhi: sheetData.gram_nidhi,
          debt_to_specify_matter: sheetData.debt_to_specify_matter,
          sum_fiftyfive_to_sixtythree: totalAdditionFourthOne,
          establishing_barns: sheetData.establishing_barns,
          waste_land_under_cultivation: sheetData.waste_land_under_cultivation,
          increas_agricultural_production:
            sheetData.increas_agricultural_production,
          making_fertilizers: sheetData.making_fertilizers,
          improved_seeds: sheetData.improved_seeds,
          improved_seeds_production: sheetData.improved_seeds_production,
          community_farming: sheetData.community_farming,
          crop_protection: sheetData.crop_protection,
          minor_irrigation: sheetData.minor_irrigation,
          improvement_village_forests: sheetData.improvement_village_forests,
          cattle_improvement: sheetData.cattle_improvement,
          specified_matters: sheetData.specified_matters,
          sum_sixtyfive_to_seventysix: totalAdditionFifth,
          village_industry: sheetData.village_industry,
          total_expanses_in_year: totalAdditionFifthOne,
          expanses_previous_year: sheetData.expanses_previous_year,
          increase_decrease: sheetData.increase_decrease,
          total_servant_expanse: totalExpansePercentage,
          shera: sheetData.shera,
        },
      };
      console.log("update finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetE response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // draft api
  const handleDraftSheetE = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_e_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          water_supply: sheetData.water_supply || "",
          cleaning_of_public_roads: sheetData.cleaning_of_public_roads || "",
          arrangement_of_burial_ground:
            sheetData.arrangement_of_burial_ground || "",
          playgrounds_or_public_parks:
            sheetData.playgrounds_or_public_parks || "",
          disposal_derelict_carcasses:
            sheetData.disposal_derelict_carcasses || "",
          public_forum: sheetData.public_forum || "",
          epidemic_diseases: sheetData.epidemic_diseases || "",
          maternal_or_child_care: sheetData.maternal_or_child_care || "",
          medical_help: sheetData.medical_help || "",
          vaccination_humans_cattle: sheetData.vaccination_humans_cattle || "",
          sanitation_health_scheme: sheetData.sanitation_health_scheme || "",
          total_three_to_thirteen: totalAdditionFirst || "",
          removal_encroachments: sheetData.removal_encroachments || "",
          repair_roads_dams: sheetData.repair_roads_dams || "",
          public_buildings_tanks: sheetData.public_buildings_tanks || "",
          street_lamp: sheetData.street_lamp || "",
          jatra_bazaar_bases: sheetData.jatra_bazaar_bases || "",
          eat_slaughter: sheetData.eat_slaughter || "",
          planting_trees: sheetData.planting_trees || "",
          religion_school: sheetData.religion_school || "",
          construction_ghats_on_river:
            sheetData.construction_ghats_on_river || "",
          market: sheetData.market || "",
          houses_for_cleaning_worker:
            sheetData.houses_for_cleaning_worker || "",
          useful_ground_for_camping: sheetData.useful_ground_for_camping || "",
          cattle_sheds: sheetData.cattle_sheds || "",
          drought_works: sheetData.drought_works || "",
          village_area_expansion: sheetData.village_area_expansion || "",
          wakhari: sheetData.wakhari || "",
          other_matter_public_work: sheetData.other_matter_public_work || "",
          total_fifteen_to_thirtyone: totalAdditionSecond || "",
          education: sheetData.education || "",
          clubs_entertainment: sheetData.clubs_entertainment || "",
          theaters: sheetData.theaters || "",
          libraries: sheetData.libraries || "",
          prohibition_of_alcohol: sheetData.prohibition_of_alcohol || "",
          removal_of_untouchability: sheetData.removal_of_untouchability || "",
          improve_condition_people: sheetData.improve_condition_people || "",
          gambling_money_laundering: sheetData.gambling_money_laundering || "",
          specify_matter: sheetData.specified_matters || "",
          sum_thirythree_to_fourtyone: totalAdditionThird || "",
          patrolling_or_guarding: sheetData.patrolling_or_guarding || "",
          controlling_industries_works:
            sheetData.controlling_industries_works || "",
          fire_protecting: sheetData.fire_protecting || "",
          specify_other_matters: sheetData.specify_other_matters || "",
          sum_fourtythree_to_fourtysix: totalAdditionThirdOne || "",
          servant_class: sheetData.servant_class || "",
          numbering_of_seats: sheetData.numbering_of_seats || "",
          measure: sheetData.measure || "",
          regulate_fairs: sheetData.regulate_fairs || "",
          holding_office_panchayat: sheetData.holding_office_panchayat || "",
          registration_of_births: sheetData.registration_of_births || "",
          specifying_the_matter: sheetData.specifying_the_matter || "",
          sum_fouryeight_to_fiftythree: totalAdditionFourth || "",
          land_reform_scheme: sheetData.land_reform_scheme || "",
          helping_sick_person: sheetData.helping_sick_person || "",
          assisting_natural_calamities:
            sheetData.assisting_natural_calamities || "",
          coperative_arrangement_land:
            sheetData.coperative_arrangement_land || "",
          association_of_credit_unions:
            sheetData.association_of_credit_unions || "",
          making_waste_land: sheetData.making_waste_land || "",
          establish_shram_organization:
            sheetData.establish_shram_organization || "",
          gram_nidhi: sheetData.gram_nidhi || "",
          debt_to_specify_matter: sheetData.debt_to_specify_matter || "",
          sum_fiftyfive_to_sixtythree: totalAdditionFourthOne || "",
          establishing_barns: sheetData.establishing_barns || "",
          waste_land_under_cultivation:
            sheetData.waste_land_under_cultivation || "",
          increas_agricultural_production:
            sheetData.increas_agricultural_production || "",
          making_fertilizers: sheetData.making_fertilizers || "",
          improved_seeds: sheetData.improved_seeds || "",
          improved_seeds_production: sheetData.improved_seeds_production || "",
          community_farming: sheetData.community_farming || "",
          crop_protection: sheetData.crop_protection || "",
          minor_irrigation: sheetData.minor_irrigation || "",
          improvement_village_forests:
            sheetData.improvement_village_forests || "",
          cattle_improvement: sheetData.cattle_improvement || "",
          specified_matters: sheetData.specified_matters || "",
          sum_sixtyfive_to_seventysix: totalAdditionFifth || "",
          village_industry: sheetData.village_industry || "",
          total_expanses_in_year: totalAdditionFifthOne || "",
          expanses_previous_year: sheetData.expanses_previous_year || "",
          increase_decrease: sheetData.increase_decrease || "",
          total_servant_expanse: totalExpansePercentage || "",
          shera: sheetData.shera || "",
        },
      };
      console.log("draftSheetE finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetE response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // totalAddition
  const totalAdditionFirst =
    parseInt(sheetData.water_supply || 0) +
      parseInt(sheetData.cleaning_of_public_roads || 0) +
      parseInt(sheetData.arrangement_of_burial_ground || 0) +
      parseInt(sheetData.playgrounds_or_public_parks || 0) +
      parseInt(sheetData.disposal_derelict_carcasses || 0) +
      parseInt(sheetData.public_forum || 0) +
      parseInt(sheetData.epidemic_diseases || 0) +
      parseInt(sheetData.maternal_or_child_care || 0) +
      parseInt(sheetData.medical_help || 0) +
      parseInt(sheetData.vaccination_humans_cattle || 0) +
      parseInt(sheetData.sanitation_health_scheme || 0) || "";

  // totalAdditionSecond
  const totalAdditionSecond =
    parseInt(sheetData.removal_encroachments || 0) +
      parseInt(sheetData.repair_roads_dams || 0) +
      parseInt(sheetData.public_buildings_tanks || 0) +
      parseInt(sheetData.street_lamp || 0) +
      parseInt(sheetData.jatra_bazaar_bases || 0) +
      parseInt(sheetData.eat_slaughter || 0) +
      parseInt(sheetData.planting_trees || 0) +
      parseInt(sheetData.religion_school || 0) +
      parseInt(sheetData.construction_ghats_on_river || 0) +
      parseInt(sheetData.market || 0) +
      parseInt(sheetData.houses_for_cleaning_worker || 0) +
      parseInt(sheetData.useful_ground_for_camping || 0) +
      parseInt(sheetData.cattle_sheds || 0) +
      parseInt(sheetData.drought_works || 0) +
      parseInt(sheetData.village_area_expansion || 0) +
      parseInt(sheetData.wakhari || 0) +
      parseInt(sheetData.other_matter_public_work || 0) || "";

  // totalAdditionThird
  const totalAdditionThird =
    parseInt(sheetData.education || 0) +
      parseInt(sheetData.clubs_entertainment || 0) +
      parseInt(sheetData.theaters || 0) +
      parseInt(sheetData.libraries || 0) +
      parseInt(sheetData.prohibition_of_alcohol || 0) +
      parseInt(sheetData.removal_of_untouchability || 0) +
      parseInt(sheetData.improve_condition_people || 0) +
      parseInt(sheetData.gambling_money_laundering || 0) +
      parseInt(sheetData.specify_matter || 0) || "";

  // totalAdditionThirdOne
  const totalAdditionThirdOne =
    parseInt(sheetData.patrolling_or_guarding || 0) +
      parseInt(sheetData.controlling_industries_works || 0) +
      parseInt(sheetData.fire_protecting || 0) +
      parseInt(sheetData.specify_other_matters || 0) || "";

  // totalAdditionFourth
  const totalAdditionFourth =
    parseInt(sheetData.servant_class || 0) +
      parseInt(sheetData.numbering_of_seats || 0) +
      parseInt(sheetData.measure || 0) +
      parseInt(sheetData.regulate_fairs || 0) +
      parseInt(sheetData.holding_office_panchayat || 0) +
      parseInt(sheetData.registration_of_births || 0) +
      parseInt(sheetData.specifying_the_matter || 0) || "";

  // totalAdditionFourthOne
  const totalAdditionFourthOne =
    parseInt(sheetData.land_reform_scheme || 0) +
      parseInt(sheetData.helping_sick_person || 0) +
      parseInt(sheetData.assisting_natural_calamities || 0) +
      parseInt(sheetData.coperative_arrangement_land || 0) +
      parseInt(sheetData.association_of_credit_unions || 0) +
      parseInt(sheetData.making_waste_land || 0) +
      parseInt(sheetData.establish_shram_organization || 0) +
      parseInt(sheetData.gram_nidhi || 0) +
      parseInt(sheetData.debt_to_specify_matter || 0) || "";

  // totalAdditionFifth
  const totalAdditionFifth =
    parseInt(sheetData.establishing_barns || 0) +
      parseInt(sheetData.waste_land_under_cultivation || 0) +
      parseInt(sheetData.increas_agricultural_production || 0) +
      parseInt(sheetData.making_fertilizers || 0) +
      parseInt(sheetData.improved_seeds || 0) +
      parseInt(sheetData.improved_seeds_production || 0) +
      parseInt(sheetData.community_farming || 0) +
      parseInt(sheetData.crop_protection || 0) +
      parseInt(sheetData.minor_irrigation || 0) +
      parseInt(sheetData.improvement_village_forests || 0) +
      parseInt(sheetData.cattle_improvement || 0) +
      parseInt(sheetData.specified_matters || 0) || "";

  // totalAdditionFifthOne
  const totalAdditionFifthOne =
    parseInt(totalAdditionFirst || 0) +
      parseInt(totalAdditionSecond || 0) +
      parseInt(totalAdditionThird || 0) +
      parseInt(totalAdditionThirdOne || 0) +
      parseInt(totalAdditionFourth || 0) +
      parseInt(totalAdditionFourthOne || 0) +
      parseInt(totalAdditionFifth || 0) +
      parseInt(sheetData.village_industry || 0) || "";

  // totalExpansePercentage
  const totalExpansePercentage =
    (parseInt(sheetData.servant_class || 0) /
      parseInt(totalAdditionFifthOne || 0)) *
      100 || "";

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>खर्च दाखविणारे पत्रक</title>
            {/* Conditionally set the viewport scale to 0.5 */}
          </Helmet>
          <div
            id="table-container"
            className={`sheet_bg_color_pdf container-fluid`}
          >
            <div className={`row`}>
              <div className="col-lg-12">
                <div className="table-main-sec">
                  <div className="table_top">
                    <div className={`${styles.patrak_title_top}`}>
                      <p className="mb-4">
                        खर्च दाखविणारे पत्रक
                        <span className="patrak_box">पत्रक- "ई"</span>
                      </p>
                      {economicData === "Y" ? (
                        <span className="drafted_text">
                          <FaEdit /> Drafted
                        </span>
                      ) : economicData === "N" ? (
                        <span className="table_title_top gap-0 submit_text1s ">
                          Submitted{" "}
                          <img
                            src={require("../../component/assets/images/sumbit-success.png")}
                            alt="success"
                            width={30}
                            className="img-fluid"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <form action="">
                      <table className={styles.table_bordered}>
                        <thead>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th scope="col" rowSpan={3}>
                              अ.नं
                            </th>
                            <th scope="col" rowSpan={3}>
                              पंचयात समितीचे नाव
                            </th>
                            <th scope="col" rowSpan={3}>
                              ग्रामपंचायतीचे नाव
                            </th>
                            <th
                              scope="col"
                              className="text-center"
                              colSpan={14}
                            >
                              १. स्वच्छता व आरोग्य यावरील खर्च
                            </th>
                          </tr>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th>पाणी पुरवठा</th>
                            <th>
                              सार्वजनिक रस्ते, गटार, तळी व विहिरी साफ करणे व
                              उपद्र्व्य कमी करणे, वगेरे
                            </th>
                            <th>दफन भूमी व दहन भूमी यांची व्यवस्था करणे</th>
                            <th>क्रीडांगणे व सार्वजनिक उद्याने </th>
                            <th>
                              बेवारशी प्रेते आणि गुरे ढोरे यांची विल्हेवाट लावणे
                            </th>
                            <th>सार्वजनिक संडास</th>
                            <th>साथीचे रोग आणि गुरांचे रोग</th>
                            <th>माता आणि बाल संगोपन </th>
                            <th>वैदकीय मदत </th>
                            <th>माणसांना आणि गुरांना लस टोचणी</th>
                            <th>
                              स्वच्छता विषयक व आरोग्य विषयक इतर उपाय योजना
                            </th>
                            <th>कॉलम नंबर ३ ते १३ ची बेरीज</th>
                            <th>अतिक्रमण दूर करणे </th>
                            <th>
                              रस्ते, बांध, गटारे, व पूल बांधणे व ती सुस्थितीत
                              ठेवणे व त्यांची दुरूस्ती करणे
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <td>१</td>
                            <td>१ अ</td>
                            <td>२</td>
                            <td>३</td>
                            <td>४</td>
                            <td>५</td>
                            <td>६</td>
                            <td>७</td>
                            <td>८</td>
                            <td>९</td>
                            <td>१०</td>
                            <td>११</td>
                            <td>१२</td>
                            <td>१३</td>
                            <td>१४</td>
                            <td>१५</td>
                            <td>१६</td>
                          </tr>
                          <tr
                            className={`${styles.text_font_padding} ${styles.common_tr_border}`}
                          >
                            <td>{sheetData.sr_no}</td>
                            <td>{sheetData.panchayat_name}</td>
                            <td>{sheetData.gpt_name}</td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.water_supply ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.water_supply ? errors.water_supply : ""
                                }
                                name="water_supply"
                                value={sheetData.water_supply}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.water_supply.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.water_supply.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.cleaning_of_public_roads
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.cleaning_of_public_roads
                                    ? errors.cleaning_of_public_roads
                                    : ""
                                }
                                name="cleaning_of_public_roads"
                                value={sheetData.cleaning_of_public_roads}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.cleaning_of_public_roads.length <=
                                    3
                                      ? "100%"
                                      : `${
                                          sheetData.cleaning_of_public_roads
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.arrangement_of_burial_ground
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.arrangement_of_burial_ground
                                    ? errors.arrangement_of_burial_ground
                                    : ""
                                }
                                name="arrangement_of_burial_ground"
                                value={sheetData.arrangement_of_burial_ground}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.arrangement_of_burial_ground
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.arrangement_of_burial_ground
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.playgrounds_or_public_parks
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.playgrounds_or_public_parks
                                    ? errors.playgrounds_or_public_parks
                                    : ""
                                }
                                name="playgrounds_or_public_parks"
                                value={sheetData.playgrounds_or_public_parks}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.playgrounds_or_public_parks
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.playgrounds_or_public_parks
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.disposal_derelict_carcasses
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.disposal_derelict_carcasses
                                    ? errors.disposal_derelict_carcasses
                                    : ""
                                }
                                name="disposal_derelict_carcasses"
                                value={sheetData.disposal_derelict_carcasses}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.disposal_derelict_carcasses
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.disposal_derelict_carcasses
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.public_forum ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.public_forum ? errors.public_forum : ""
                                }
                                name="public_forum"
                                value={sheetData.public_forum}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.public_forum.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.public_forum.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.epidemic_diseases ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.epidemic_diseases
                                    ? errors.epidemic_diseases
                                    : ""
                                }
                                name="epidemic_diseases"
                                value={sheetData.epidemic_diseases}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.epidemic_diseases.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.epidemic_diseases.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.maternal_or_child_care
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.maternal_or_child_care
                                    ? errors.maternal_or_child_care
                                    : ""
                                }
                                name="maternal_or_child_care"
                                value={sheetData.maternal_or_child_care}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.maternal_or_child_care.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.maternal_or_child_care
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.medical_help ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.medical_help ? errors.medical_help : ""
                                }
                                name="medical_help"
                                value={sheetData.medical_help}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.medical_help.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.medical_help.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.vaccination_humans_cattle
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.vaccination_humans_cattle
                                    ? errors.vaccination_humans_cattle
                                    : ""
                                }
                                name="vaccination_humans_cattle"
                                value={sheetData.vaccination_humans_cattle}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.vaccination_humans_cattle
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.vaccination_humans_cattle
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.sanitation_health_scheme
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.sanitation_health_scheme
                                    ? errors.sanitation_health_scheme
                                    : ""
                                }
                                name="sanitation_health_scheme"
                                value={sheetData.sanitation_health_scheme}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.sanitation_health_scheme.length <=
                                    3
                                      ? "100%"
                                      : `${
                                          sheetData.sanitation_health_scheme
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input-box fw-bold"
                                value={totalAdditionFirst}
                                readOnly
                                style={{
                                  width:
                                    totalAdditionFirst.toString().length <= 2
                                      ? "30px"
                                      : `${
                                          totalAdditionFirst.toString().length *
                                          12
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.removal_encroachments
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.removal_encroachments
                                    ? errors.removal_encroachments
                                    : ""
                                }
                                name="removal_encroachments"
                                value={sheetData.removal_encroachments}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.removal_encroachments.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.removal_encroachments
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.repair_roads_dams ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.repair_roads_dams
                                    ? errors.repair_roads_dams
                                    : ""
                                }
                                name="repair_roads_dams"
                                value={sheetData.repair_roads_dams}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.repair_roads_dams.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.repair_roads_dams.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  {/* second table */}
                  <div className={`table-responsive mt-5  ${styles.patrak1}`}>
                    <form action="">
                      <table className={styles.table_bordered}>
                        <thead>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th className="text-center" colSpan={19}>
                              २. सार्वजनिक बांधकामावरील खर्च
                            </th>
                          </tr>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th scope="col">सार्वजनिक इमारती, तळी व विहीरी</th>
                            <th scope="col" className="text-center">
                              रस्त्यावरील दिवाबत्ती
                            </th>
                            <th scope="col" className="text-center">
                              जत्रा बाजार, टांगे आणि बैलगाड्यांचे अड्डे
                            </th>
                            <th scope="col" className="text-center">
                              कत्तल खाने
                            </th>
                            <th scope="col" className="text-center">
                              झाडे लावणे व त्याची निगा राखणे
                            </th>
                            <th scope="col">धर्म - शाळा</th>
                            <th scope="col">नदी बंधारे यांना घाट बांधणे</th>
                            <th scope="col">बाजार</th>
                            <th scope="col">साफसफाई नोकर वर्गासाठी घरे</th>
                            <th scope="col" className="text-center">
                              शिबीरासाठी उपयोगी पडणारे मैदाने, कुरणे व तळी
                            </th>
                            <th scope="col">
                              <div className={styles.rotate_column_text1}>
                                गुरांचे कोंडवाडे
                              </div>
                            </th>
                            <th scope="col">
                              <div className={styles.rotate_column_text1}>
                                दुष्काळी कामे
                              </div>
                            </th>
                            <th scope="col">
                              <div className={styles.rotate_column_text1}>
                                गावठाण क्षेत्र विस्तार
                              </div>
                            </th>
                            <th scope="col">
                              <div className={styles.rotate_column_text1}>
                                वखारी
                              </div>
                            </th>
                            <th scope="col">
                              सार्वजनिक बांधकांमाच्या इतर कोणत्याही बाबी
                            </th>
                            <th scope="col">कॉलम नंबर १५ ते ३१ ची बेरीज</th>
                            <th scope="col">शिक्षण</th>
                            <th scope="col">क्लब व करमणुकी</th>
                            <th scope="col">नाट्यगृहे</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <td>१७</td>
                            <td>१८</td>
                            <td>१९</td>
                            <td>२०</td>
                            <td>२१ </td>
                            <td>२२</td>
                            <td>२३</td>
                            <td>२४</td>
                            <td>२५</td>
                            <td>२६</td>
                            <td>२७</td>
                            <td>२८</td>
                            <td>२९</td>
                            <td>३०</td>
                            <td>३१</td>
                            <td>३२</td>
                            <td>३३</td>
                            <td>३४</td>
                            <td>३५</td>
                          </tr>

                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.public_buildings_tanks
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.public_buildings_tanks
                                    ? errors.public_buildings_tanks
                                    : ""
                                }
                                name="public_buildings_tanks"
                                value={sheetData.public_buildings_tanks}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.public_buildings_tanks.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.public_buildings_tanks
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.street_lamp ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.street_lamp ? errors.street_lamp : ""
                                }
                                name="street_lamp"
                                value={sheetData.street_lamp}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.street_lamp.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.street_lamp.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.jatra_bazaar_bases ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.jatra_bazaar_bases
                                    ? errors.jatra_bazaar_bases
                                    : ""
                                }
                                name="jatra_bazaar_bases"
                                value={sheetData.jatra_bazaar_bases}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.jatra_bazaar_bases.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.jatra_bazaar_bases.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.eat_slaughter ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.eat_slaughter
                                    ? errors.eat_slaughter
                                    : ""
                                }
                                name="eat_slaughter"
                                value={sheetData.eat_slaughter}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.eat_slaughter.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.eat_slaughter.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.planting_trees ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.planting_trees
                                    ? errors.planting_trees
                                    : ""
                                }
                                name="planting_trees"
                                value={sheetData.planting_trees}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.planting_trees.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.planting_trees.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.religion_school ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.religion_school
                                    ? errors.religion_school
                                    : ""
                                }
                                name="religion_school"
                                value={sheetData.religion_school}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.religion_school.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.religion_school.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.construction_ghats_on_river
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.construction_ghats_on_river
                                    ? errors.construction_ghats_on_river
                                    : ""
                                }
                                name="construction_ghats_on_river"
                                value={sheetData.construction_ghats_on_river}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.construction_ghats_on_river
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.construction_ghats_on_river
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.market ? "is-invalid" : ""
                                }`}
                                placeholder={errors.market ? errors.market : ""}
                                name="market"
                                value={sheetData.market}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.market.length <= 3
                                      ? "100%"
                                      : `${sheetData.market.length * 10}px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.houses_for_cleaning_worker
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.houses_for_cleaning_worker
                                    ? errors.houses_for_cleaning_worker
                                    : ""
                                }
                                name="houses_for_cleaning_worker"
                                value={sheetData.houses_for_cleaning_worker}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.houses_for_cleaning_worker
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.houses_for_cleaning_worker
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.useful_ground_for_camping
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.useful_ground_for_camping
                                    ? errors.useful_ground_for_camping
                                    : ""
                                }
                                name="useful_ground_for_camping"
                                value={sheetData.useful_ground_for_camping}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.useful_ground_for_camping
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.useful_ground_for_camping
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.cattle_sheds ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.cattle_sheds ? errors.cattle_sheds : ""
                                }
                                name="cattle_sheds"
                                value={sheetData.cattle_sheds}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.cattle_sheds.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.cattle_sheds.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.drought_works ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.drought_works
                                    ? errors.drought_works
                                    : ""
                                }
                                name="drought_works"
                                value={sheetData.drought_works}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.drought_works.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.drought_works.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.village_area_expansion
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.village_area_expansion
                                    ? errors.village_area_expansion
                                    : ""
                                }
                                name="village_area_expansion"
                                value={sheetData.village_area_expansion}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.village_area_expansion.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.village_area_expansion
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.wakhari ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.wakhari ? errors.wakhari : ""
                                }
                                name="wakhari"
                                value={sheetData.wakhari}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.wakhari.length <= 3
                                      ? "100%"
                                      : `${sheetData.wakhari.length * 10}px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.other_matter_public_work
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.other_matter_public_work
                                    ? errors.other_matter_public_work
                                    : ""
                                }
                                name="other_matter_public_work"
                                value={sheetData.other_matter_public_work}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.other_matter_public_work.length <=
                                    3
                                      ? "100%"
                                      : `${
                                          sheetData.other_matter_public_work
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input-box fw-bold"
                                value={totalAdditionSecond}
                                readOnly
                                style={{
                                  width:
                                    totalAdditionSecond.toString().length <= 2
                                      ? "30px"
                                      : `${
                                          totalAdditionSecond.toString()
                                            .length * 12
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.education ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.education ? errors.education : ""
                                }
                                name="education"
                                value={sheetData.education}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.education.length <= 3
                                      ? "100%"
                                      : `${sheetData.education.length * 10}px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.clubs_entertainment ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.clubs_entertainment
                                    ? errors.clubs_entertainment
                                    : ""
                                }
                                name="clubs_entertainment"
                                value={sheetData.clubs_entertainment}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.clubs_entertainment.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.clubs_entertainment.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.theaters ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.theaters ? errors.theaters : ""
                                }
                                name="theaters"
                                value={sheetData.theaters}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.theaters.length <= 3
                                      ? "100%"
                                      : `${sheetData.theaters.length * 10}px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  {/* third table */}
                  <div
                    className={`table-responsive margin_class mt-5 ${styles.patrak1}`}
                  >
                    <form action="">
                      <table className={styles.table_bordered}>
                        <thead>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th className="text-center" colSpan={7}>
                              ३. शिक्षक आणि सांस्कृतिक विषयक कार्य यावरील खर्च
                            </th>
                            <th className="text-center" colSpan={5}>
                              ४. आत्मसंरक्षण व ग्रामसंरक्षण यावरील खर्च
                            </th>
                            <th className="text-center" colSpan={5}>
                              ५. कारभार विषयक खर्च
                            </th>
                          </tr>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th scope="col" rowSpan={2}>
                              ग्रंथालये आणि वाचनालये
                            </th>
                            <th scope="col" colSpan={6} className="text-center">
                              सामाजिक आणि नैतिक कल्याणविषयक कार्याची वाढ
                            </th>
                            <th scope="col" rowSpan={2} className="text-center">
                              गस्त व पहारा
                            </th>
                            <th scope="col" rowSpan={2} className="text-center">
                              धोक्याचे उद्योगधंदे व कामे यावर नियंत्रण ठेवणे
                            </th>
                            <th scope="col" rowSpan={2} className="text-center">
                              आगीपासून संरक्षण करणे
                            </th>
                            <th scope="col" rowSpan={2}>
                              इतर बाबी निर्दिष्ट करणे
                            </th>
                            <th scope="col" rowSpan={2}>
                              स्तंभ ४३ ते ४६ यांची बेरीज
                            </th>
                            <th scope="col" rowSpan={2}>
                              नोकर वर्ग
                            </th>
                            <th scope="col" rowSpan={2}>
                              जागांना क्रमांक देणे
                            </th>
                            <th scope="col" rowSpan={2} className="text-center">
                              मोज मापणी
                            </th>
                            <th scope="col" rowSpan={2}>
                              जत्रा, उत्सव, धार्मिक जत्रा यांचे नियमन करणे
                            </th>
                            <th scope="col" rowSpan={2}>
                              पंचायतीचे दफ्तर ठेवणे
                            </th>
                          </tr>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th>दारूबंदी</th>
                            <th>अस्पृश्यता निवारण</th>
                            <th>मागासलेल्या लोकांची स्थिती सुधारणे</th>
                            <th>
                              लाचलुचपत जुगार व कज्जे दलाली यांचे ऊचाटन करणे
                            </th>
                            <th>इतर कोणत्याही बाबी (बाब निर्दिष्ट करणे)</th>
                            <th>स्तंभ ३३ ते ४१ यांची बेरीज</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <td>३६</td>
                            <td>३७</td>
                            <td>३८</td>
                            <td>३९</td>
                            <td>४० </td>
                            <td>४१</td>
                            <td>४२</td>
                            <td>४३</td>
                            <td>४४</td>
                            <td>४५</td>
                            <td>४६</td>
                            <td>४७</td>
                            <td>४८</td>
                            <td>४९</td>
                            <td>५०</td>
                            <td>५१</td>
                            <td>५२</td>
                          </tr>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.libraries ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.libraries ? errors.libraries : ""
                                }
                                name="libraries"
                                value={sheetData.libraries}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.libraries.length <= 3
                                      ? "100%"
                                      : `${sheetData.libraries.length * 10}px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.prohibition_of_alcohol
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.prohibition_of_alcohol
                                    ? errors.prohibition_of_alcohol
                                    : ""
                                }
                                name="prohibition_of_alcohol"
                                value={sheetData.prohibition_of_alcohol}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.prohibition_of_alcohol.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.prohibition_of_alcohol
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.removal_of_untouchability
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.removal_of_untouchability
                                    ? errors.removal_of_untouchability
                                    : ""
                                }
                                name="removal_of_untouchability"
                                value={sheetData.removal_of_untouchability}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.removal_of_untouchability
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.removal_of_untouchability
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.improve_condition_people
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.improve_condition_people
                                    ? errors.improve_condition_people
                                    : ""
                                }
                                name="improve_condition_people"
                                value={sheetData.improve_condition_people}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.improve_condition_people.length <=
                                    3
                                      ? "100%"
                                      : `${
                                          sheetData.improve_condition_people
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.gambling_money_laundering
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.gambling_money_laundering
                                    ? errors.gambling_money_laundering
                                    : ""
                                }
                                name="gambling_money_laundering"
                                value={sheetData.gambling_money_laundering}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.gambling_money_laundering
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.gambling_money_laundering
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.specify_matter ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.specify_matter
                                    ? errors.specify_matter
                                    : ""
                                }
                                name="specify_matter"
                                value={sheetData.specify_matter}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.specify_matter.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.specify_matter.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input-box fw-bold"
                                value={totalAdditionThird}
                                readOnly
                                style={{
                                  width:
                                    totalAdditionThird.toString().length <= 2
                                      ? "30px"
                                      : `${
                                          totalAdditionThird.toString().length *
                                          12
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.patrolling_or_guarding
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.patrolling_or_guarding
                                    ? errors.patrolling_or_guarding
                                    : ""
                                }
                                name="patrolling_or_guarding"
                                value={sheetData.patrolling_or_guarding}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.patrolling_or_guarding.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.patrolling_or_guarding
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.controlling_industries_works
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.controlling_industries_works
                                    ? errors.controlling_industries_works
                                    : ""
                                }
                                name="controlling_industries_works"
                                value={sheetData.controlling_industries_works}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.controlling_industries_works
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.controlling_industries_works
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.fire_protecting ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.fire_protecting
                                    ? errors.fire_protecting
                                    : ""
                                }
                                name="fire_protecting"
                                value={sheetData.fire_protecting}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.fire_protecting.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.fire_protecting.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.specify_other_matters
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.specify_other_matters
                                    ? errors.specify_other_matters
                                    : ""
                                }
                                name="specify_other_matters"
                                value={sheetData.specify_other_matters}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.specify_other_matters.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.specify_other_matters
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input-box fw-bold"
                                value={totalAdditionThirdOne}
                                readOnly
                                style={{
                                  width:
                                    totalAdditionThirdOne.toString().length <= 2
                                      ? "30px"
                                      : `${
                                          totalAdditionThirdOne.toString()
                                            .length * 12
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.servant_class ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.servant_class
                                    ? errors.servant_class
                                    : ""
                                }
                                name="servant_class"
                                value={sheetData.servant_class}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.servant_class.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.servant_class.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.numbering_of_seats ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.numbering_of_seats
                                    ? errors.numbering_of_seats
                                    : ""
                                }
                                name="numbering_of_seats"
                                value={sheetData.numbering_of_seats}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.numbering_of_seats.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.numbering_of_seats.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.measure ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.measure ? errors.measure : ""
                                }
                                name="measure"
                                value={sheetData.measure}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.measure.length <= 3
                                      ? "100%"
                                      : `${sheetData.measure.length * 10}px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.regulate_fairs ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.regulate_fairs
                                    ? errors.regulate_fairs
                                    : ""
                                }
                                name="regulate_fairs"
                                value={sheetData.regulate_fairs}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.regulate_fairs.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.regulate_fairs.length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.holding_office_panchayat
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.holding_office_panchayat
                                    ? errors.holding_office_panchayat
                                    : ""
                                }
                                name="holding_office_panchayat"
                                value={sheetData.holding_office_panchayat}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.holding_office_panchayat.length <=
                                    3
                                      ? "100%"
                                      : `${
                                          sheetData.holding_office_panchayat
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  {/* fourth table */}
                  <div className={`table-responsive mt-5 ${styles.patrak1}`}>
                    <form action="">
                      <table className={styles.table_bordered}>
                        <thead>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th className="text-center" colSpan={3}></th>
                            <th className="text-center" colSpan={10}>
                              ६. लोककल्याण विषयक कार्यवरील खर्च
                            </th>
                            <th className="text-center" colSpan={3}>
                              ७ व ८, शेती, वने संरक्षण व पशुसंवर्धन यावरील खर्च
                            </th>
                          </tr>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <th scope="col">
                              जन्म मृत्यू व लग्न रजिस्टर करण्याबाबत
                            </th>
                            <th scope="col" className="text-center">
                              इतर कोणत्याही बाबी (बाब निर्दिष्ट करणे)
                            </th>
                            <th scope="col" className="text-center">
                              कॉलम नंबर ४८ ते ५३ यांची बेरीज
                            </th>
                            <th scope="col" className="text-center">
                              जमीन सुधारणा योजना
                            </th>
                            <th scope="col" className="text-center">
                              अपंग निराधार व आजारी माणसाला मदत करणे
                            </th>
                            <th scope="col">
                              नैसर्गिक आपतीच्या प्रसंगी मदत करणे
                            </th>
                            <th scope="col">
                              बिगरशेतीच्या कार्यासाठी जमीन व इतर साधने यांची
                              सहकारी पद्धतीने व्यवस्था करणे
                            </th>
                            <th scope="col">
                              पतीवर कर्ज देणार्‍या आणि बहुप्रयोजन सहकारी संस्था
                              यांची संघटना
                            </th>
                            <th scope="col">पडीक जमीन लागवडीस योग्य करणे</th>
                            <th scope="col">श्रमदान संघटना घडवून आणणे</th>
                            <th scope="col">ग्रामनिधी (०.२५%)</th>
                            <th scope="col">
                              इतर कोणत्याही बाबी (बाब निर्दिष्ट करणे कर्ज)
                            </th>
                            <th scope="col">कॉलम नंबर ५५ ते ६३ यांची बेरीज</th>
                            <th scope="col">धान्याची कोठारे स्थापन करणे</th>
                            <th scope="col">
                              ओसाड व पडीक जमीन लागवडीखाली आणणे
                            </th>
                            <th scope="col">शेती उत्पादन वाढवणे</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <td>५२ अ</td>
                            <td>५३</td>
                            <td>५४</td>
                            <td>५५</td>
                            <td>५६ </td>
                            <td>५७</td>
                            <td>५८</td>
                            <td>५९</td>
                            <td>६०</td>
                            <td>६१</td>
                            <td>६२</td>
                            <td>६३</td>
                            <td>६४</td>
                            <td>६५</td>
                            <td>६६</td>
                            <td>६७</td>
                          </tr>

                          <tr
                            className={`${styles.text_font} ${styles.common_tr_border}`}
                          >
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.registration_of_births
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.registration_of_births
                                    ? errors.registration_of_births
                                    : ""
                                }
                                name="registration_of_births"
                                value={sheetData.registration_of_births}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.registration_of_births.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.registration_of_births
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.specifying_the_matter
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.specifying_the_matter
                                    ? errors.specifying_the_matter
                                    : ""
                                }
                                name="specifying_the_matter"
                                value={sheetData.specifying_the_matter}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.specifying_the_matter.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.specifying_the_matter
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input-box fw-bold"
                                value={totalAdditionFourth}
                                readOnly
                                style={{
                                  width:
                                    totalAdditionFourth.toString().length <= 2
                                      ? "30px"
                                      : `${
                                          totalAdditionFourth.toString()
                                            .length * 12
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.land_reform_scheme ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.land_reform_scheme
                                    ? errors.land_reform_scheme
                                    : ""
                                }
                                name="land_reform_scheme"
                                value={sheetData.land_reform_scheme}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.land_reform_scheme.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.land_reform_scheme.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.helping_sick_person ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.helping_sick_person
                                    ? errors.helping_sick_person
                                    : ""
                                }
                                name="helping_sick_person"
                                value={sheetData.helping_sick_person}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.helping_sick_person.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.helping_sick_person.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.assisting_natural_calamities
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.assisting_natural_calamities
                                    ? errors.assisting_natural_calamities
                                    : ""
                                }
                                name="assisting_natural_calamities"
                                value={sheetData.assisting_natural_calamities}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.assisting_natural_calamities
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.assisting_natural_calamities
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.coperative_arrangement_land
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.coperative_arrangement_land
                                    ? errors.coperative_arrangement_land
                                    : ""
                                }
                                name="coperative_arrangement_land"
                                value={sheetData.coperative_arrangement_land}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.coperative_arrangement_land
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.coperative_arrangement_land
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.association_of_credit_unions
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.association_of_credit_unions
                                    ? errors.association_of_credit_unions
                                    : ""
                                }
                                name="association_of_credit_unions"
                                value={sheetData.association_of_credit_unions}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.association_of_credit_unions
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.association_of_credit_unions
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.making_waste_land ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.making_waste_land
                                    ? errors.making_waste_land
                                    : ""
                                }
                                name="making_waste_land"
                                value={sheetData.making_waste_land}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.making_waste_land.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.making_waste_land.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.establish_shram_organization
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.establish_shram_organization
                                    ? errors.establish_shram_organization
                                    : ""
                                }
                                name="establish_shram_organization"
                                value={sheetData.establish_shram_organization}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.establish_shram_organization
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.establish_shram_organization
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.gram_nidhi ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.gram_nidhi ? errors.gram_nidhi : ""
                                }
                                name="gram_nidhi"
                                value={sheetData.gram_nidhi}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.gram_nidhi.length <= 3
                                      ? "100%"
                                      : `${sheetData.gram_nidhi.length * 10}px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.debt_to_specify_matter
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.debt_to_specify_matter
                                    ? errors.debt_to_specify_matter
                                    : ""
                                }
                                name="debt_to_specify_matter"
                                value={sheetData.debt_to_specify_matter}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.debt_to_specify_matter.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.debt_to_specify_matter
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control table-input-box fw-bold"
                                value={totalAdditionFourthOne}
                                readOnly
                                style={{
                                  width:
                                    totalAdditionFourthOne.toString().length <=
                                    2
                                      ? "30px"
                                      : `${
                                          totalAdditionFourthOne.toString()
                                            .length * 12
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.establishing_barns ? "is-invalid" : ""
                                }`}
                                placeholder={
                                  errors.establishing_barns
                                    ? errors.establishing_barns
                                    : ""
                                }
                                name="establishing_barns"
                                value={sheetData.establishing_barns}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.establishing_barns.length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.establishing_barns.length *
                                          10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.waste_land_under_cultivation
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.waste_land_under_cultivation
                                    ? errors.waste_land_under_cultivation
                                    : ""
                                }
                                name="waste_land_under_cultivation"
                                value={sheetData.waste_land_under_cultivation}
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.waste_land_under_cultivation
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData.waste_land_under_cultivation
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={`form-control table-input-box ${
                                  errors.increas_agricultural_production
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder={
                                  errors.increas_agricultural_production
                                    ? errors.increas_agricultural_production
                                    : ""
                                }
                                name="increas_agricultural_production"
                                value={
                                  sheetData.increas_agricultural_production
                                }
                                onChange={handleChange}
                                autoComplete="off"
                                style={{
                                  width:
                                    sheetData.increas_agricultural_production
                                      .length <= 3
                                      ? "100%"
                                      : `${
                                          sheetData
                                            .increas_agricultural_production
                                            .length * 10
                                        }px`,
                                  paddingLeft: "5px",
                                  paddingRight: "0px",
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  {/* fifth table */}
                  <div className={`margin_class1 mt-5 ${styles.patrak1}`}>
                    <div className="table-responsive">
                      <form action="">
                        <table className={styles.table_bordered}>
                          <thead>
                            <tr
                              className={`${styles.text_font} ${styles.common_tr_border}`}
                            >
                              <th className="text-center" colSpan={10}>
                                ७ व ८. शेती, वने संरक्षण व पशुसंवर्धन यावरील
                                खर्च
                              </th>
                              <th className="text-center" colSpan={1}>
                                ९. ग्रामोद्योग व कुटीरोद्योग यावरील खर्च
                              </th>
                              <th rowSpan={2}>
                                अहवालाच्या वर्षातील खर्चाची एकूण बेरीज
                                १४,३२,४२,४७,५४,६४,७७ व ७८ यांची बेरीज
                              </th>
                              <th rowSpan={2}>मागील वर्षाचा एकूण खर्च </th>
                              <th rowSpan={2}>+वाढ -घट</th>
                              <th rowSpan={2}>
                                नोकर वर्गावरील खर्चाचे आसन ४८ आसन ७९ मधील एकूण
                                खर्चाशी शेकडा प्रमाण
                              </th>
                              <th rowSpan={2}>शेरा</th>
                            </tr>
                            <tr
                              className={`${styles.text_font} ${styles.common_tr_border}`}
                            >
                              <th scope="col">खते तयार करणे</th>
                              <th scope="col" className="text-center">
                                सुधारलेली बी-बीयाणे यासाठी संवर्धन गृहे औजारे व
                                साधने
                              </th>
                              <th scope="col" className="text-center">
                                सुधारलेली बी-बियाणाचे उत्पादन व वापर
                              </th>
                              <th scope="col" className="text-center">
                                सहकारी व सामुदायिक शेतीची वाढ
                              </th>
                              <th scope="col" className="text-center">
                                पिकासंबंधी प्रयोग व पिकांचे संरक्षण
                              </th>
                              <th scope="col">किरकोळ पाटबंधारे</th>
                              <th scope="col">
                                गावातील जंगलांचे जतन करणे व त्यात सुधारणा करणे
                              </th>
                              <th scope="col">गुरांची सुधारणा निपज व काळजी</th>
                              <th scope="col">
                                निर्दिष्ट करावयाची इतर कोणत्याही बाबी
                              </th>
                              <th scope="col">
                                कॉलम नंबर ६५ ते ७६ यांची बेरीज{" "}
                              </th>

                              <th scope="col">कुटीरीद्योग व ग्रामद्योग </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              className={`${styles.text_font} ${styles.common_tr_border}`}
                            >
                              <td>६८</td>
                              <td>६९</td>
                              <td>७०</td>
                              <td>७१</td>
                              <td>७२</td>
                              <td>७३</td>
                              <td>७४</td>
                              <td>७५</td>
                              <td>७६</td>
                              <td>७७</td>
                              <td>७८</td>
                              <td>७९</td>
                              <td>८०</td>
                              <td>८१</td>
                              <td>८२</td>
                              <td>८३</td>
                            </tr>

                            <tr
                              className={`${styles.text_font} ${styles.common_tr_border}`}
                            >
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.making_fertilizers
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder={
                                    errors.making_fertilizers
                                      ? errors.making_fertilizers
                                      : ""
                                  }
                                  name="making_fertilizers"
                                  value={sheetData.making_fertilizers}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.making_fertilizers.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.making_fertilizers
                                              .length * 10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.improved_seeds ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    errors.improved_seeds
                                      ? errors.improved_seeds
                                      : ""
                                  }
                                  name="improved_seeds"
                                  value={sheetData.improved_seeds}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.improved_seeds.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.improved_seeds.length * 10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.improved_seeds_production
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder={
                                    errors.improved_seeds_production
                                      ? errors.improved_seeds_production
                                      : ""
                                  }
                                  name="improved_seeds_production"
                                  value={sheetData.improved_seeds_production}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.improved_seeds_production
                                        .length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.improved_seeds_production
                                              .length * 10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.community_farming ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    errors.community_farming
                                      ? errors.community_farming
                                      : ""
                                  }
                                  name="community_farming"
                                  value={sheetData.community_farming}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.community_farming.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.community_farming.length *
                                            10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.crop_protection ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    errors.crop_protection
                                      ? errors.crop_protection
                                      : ""
                                  }
                                  name="crop_protection"
                                  value={sheetData.crop_protection}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.crop_protection.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.crop_protection.length *
                                            10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.minor_irrigation ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    errors.minor_irrigation
                                      ? errors.minor_irrigation
                                      : ""
                                  }
                                  name="minor_irrigation"
                                  value={sheetData.minor_irrigation}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.minor_irrigation.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.minor_irrigation.length *
                                            10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.improvement_village_forests
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder={
                                    errors.improvement_village_forests
                                      ? errors.improvement_village_forests
                                      : ""
                                  }
                                  name="improvement_village_forests"
                                  value={sheetData.improvement_village_forests}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.improvement_village_forests
                                        .length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData
                                              .improvement_village_forests
                                              .length * 10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.cattle_improvement
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder={
                                    errors.cattle_improvement
                                      ? errors.cattle_improvement
                                      : ""
                                  }
                                  name="cattle_improvement"
                                  value={sheetData.cattle_improvement}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.cattle_improvement.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.cattle_improvement
                                              .length * 10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.specified_matters ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    errors.specified_matters
                                      ? errors.specified_matters
                                      : ""
                                  }
                                  name="specified_matters"
                                  value={sheetData.specified_matters}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.specified_matters.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.specified_matters.length *
                                            10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input-box fw-bold"
                                  value={totalAdditionFifth}
                                  readOnly
                                  style={{
                                    width:
                                      totalAdditionFifth.toString().length <= 2
                                        ? "30px"
                                        : `${
                                            totalAdditionFifth.toString()
                                              .length * 12
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.village_industry ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    errors.village_industry
                                      ? errors.village_industry
                                      : ""
                                  }
                                  name="village_industry"
                                  value={sheetData.village_industry}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.village_industry.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.village_industry.length *
                                            10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input-box fw-bold"
                                  value={totalAdditionFifthOne}
                                  readOnly
                                  style={{
                                    width:
                                      totalAdditionFifthOne.toString().length <=
                                      2
                                        ? "30px"
                                        : `${
                                            totalAdditionFifthOne.toString()
                                              .length * 12
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.expanses_previous_year
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder={
                                    errors.expanses_previous_year
                                      ? errors.expanses_previous_year
                                      : ""
                                  }
                                  name="expanses_previous_year"
                                  value={sheetData.expanses_previous_year}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.expanses_previous_year.length <=
                                      3
                                        ? "100%"
                                        : `${
                                            sheetData.expanses_previous_year
                                              .length * 10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.increase_decrease ? "is-invalid" : ""
                                  }`}
                                  placeholder={
                                    errors.increase_decrease
                                      ? errors.increase_decrease
                                      : ""
                                  }
                                  name="increase_decrease"
                                  value={sheetData.increase_decrease}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.increase_decrease.length <= 3
                                        ? "100%"
                                        : `${
                                            sheetData.increase_decrease.length *
                                            10
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input-box fw-bold"
                                  value={
                                    typeof totalExpansePercentage === "number"
                                      ? `${totalExpansePercentage.toFixed(2)}`
                                      : ""
                                  }
                                  readOnly
                                  style={{
                                    width:
                                      totalExpansePercentage.toString()
                                        .length <= 6
                                        ? "100%"
                                        : `${
                                            totalExpansePercentage.toString()
                                              .length * 12
                                          }px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={`form-control table-input-box ${
                                    errors.shera ? "is-invalid" : ""
                                  }`}
                                  placeholder={errors.shera ? errors.shera : ""}
                                  name="shera"
                                  value={sheetData.shera}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{
                                    width:
                                      sheetData.shera.length <= 3
                                        ? "100%"
                                        : `${sheetData.shera.length * 10}px`,
                                    paddingLeft: "5px",
                                    paddingRight: "0px",
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                  <div className={styles.form_btn_space_e}>
                    <FormBtn
                      handleSubmit={handleSubmit}
                      resetForm={handleSubmit}
                      resetButton={true}
                      handleDraft={handleDraftSheetE}
                    />
                    {/* <div className="col-lg-12 d-flex justify-content-end align-items=center">
                      {(economicData === "" || economicData === "N") && (
                        <FormBtn
                          handleSubmit={handleShow}
                          resetForm={handleSubmit}
                          resetButton={true}
                          handleDraft={handleDraftSheetE}
                        />
                      )}
                    </div> */}
                    <div className="">
                      <div
                        className={styles.btn_position}
                        style={{ marginLeft: "8px" }}
                      >
                        {currentStep > 1 && (
                          <Button
                            variant="contained"
                            className=""
                            // endIcon={<RestartAltRoundedIcon />}
                            style={{ background: "#49ab4e" }}
                            onClick={handlePrevStep}
                          >
                            Previous
                          </Button>
                        )}

                        {currentStep < 10 && (
                          <Button
                            variant="contained"
                            className=""
                            // endIcon={<RestartAltRoundedIcon />}
                            style={{ background: "#49ab4e" }}
                            onClick={handleNextStep}
                          >
                            Next
                          </Button>
                        )}

                        {currentStep === 10 && (
                          <button
                            type="submit"
                            className={styles.btn_generate}
                            // onClick={handlePrint}
                          >
                            Generate Pdf
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* {economicData === "N" && (
                  <div className="col-lg-12">
                    <div className="d-flex justify-content-between">
                      <p className="text-danger">
                        सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                        विनंती करा.{" "}
                      </p>
                      <div>
                        <Button variant="contained">विनंती करा</Button>
                      </div>
                    </div>
                  </div>
                )} */}
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {" "}
                      सूचना <MdOutlineArrowDownward />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                    Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit
                    करू इच्छिता?
                  </Modal.Body>
                  <Modal.Footer className="gap-3">
                    <Button
                      className="modal-button-cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-button-submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <ToastContainer
              position={"top-center"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SheetE;
