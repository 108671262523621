/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
// import "./EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import Loader from "../../component/Loader/Loader";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const EconomicsYear_Expanse2 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [month, setMonth] = useState("");
  const [validated, setValidated] = useState(false);

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;


  // ५% अपंग खर्चाची तरतूद  input filed calculation

  const [backLog, setBackLog] = useState("");
  const [currentProvision, setCurrentProvision] = useState("");
  const [totalProvision, setTotalProvision] = useState("");
  const [expenseCData, setExpenseCData] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const CalculateTotalProvision = () => {
    const num1 = parseFloat(backLog) || 0;
    const num2 = parseFloat(currentProvision) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalProvision("");
    } else {
      const sum = num1 + num2;
      setTotalProvision(Math.round(sum));
    }
    // const sum = parseInt(backLog) + parseInt(currentProvision);
    // setTotalProvision(sum);
  };

  useEffect(() => {
    CalculateTotalProvision();
  }, [backLog, currentProvision]);

  // खर्च तरतूद ( ५% अपंग खर्चाची तरतूद) Get Information api

  const handleEconomicsYearDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        type: "disability",
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/economic_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log(
        "Economics_E_Disability_Details response",
        response.data.data
      );
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setYearInfo(response.data.data.year_info);
      setExpenseCData(response.data.data.economic_e_data);

      if (
        response.data.data.economic_e_data === null ||
        response.data.data.economic_e_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.economic_e_data.is_draft);
      }
      // updated date show in inputs

      setBackLog(response.data.data.economic_e_data.backlog);
      setCurrentProvision(response.data.data.economic_e_data.current_provision);
      setTotalProvision(response.data.data.economic_e_data.total_provision);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleEconomicsYearDetails();
  }, []);

  const handleDataRefresh = async () => {
    await handleEconomicsYearDetails();
  };

  const handleDataReset = async () => {
    if (expenseCData !== null) {
      await handleEconomicsYearDetails();
      setValidated(false);
    } else {
      setBackLog("");
      setCurrentProvision("");
      setTotalProvision("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const backLogRef = useRef(null);
  const currentProvisionRef = useRef(null);

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(backLog)) {
        backLogRef.current.focus();
      } else if (!isNumeric(currentProvision)) {
        currentProvisionRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };


   // before submission check validation
   const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const isValid = () =>{
      if (!isNumeric(backLog) || !isNumeric(currentProvision)) {
        setValidated(true);
        return true;
      }
      return false;
    }
    if (isValid()) {
      if(validated){
        await handleExpenseProvisionDetails(e);
      }
    }else{
      handleShow();
    }
  }

  // खर्च तरतूद (५% अपंग खर्चाची तरतूद)  Update Information api

  const handleExpenseProvisionDetails = async (e) => {
    e.preventDefault();
    if (!isNumeric(backLog) || !isNumeric(currentProvision)) {
      setValidated(true);
      return;
    }

    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        type: "disability",
        economic_e_data: [
          {
            backlog: backLog,
            current_provision: currentProvision,
            total_provision: totalProvision,
          },
        ],
      };
      console.log("Economics_disability_E finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_economic_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("Economics_disability_E response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // खर्च तरतूद (५% अपंग खर्चाची तरतूद)  Draft Information api

  const handleDraftExpenseProvision = async (e) => {
    e.preventDefault();
    const invalidFormats =
      (!isNumeric(backLog) && backLog !== "") ||
      (!isNumeric(currentProvision) && currentProvision !== "");

    if (invalidFormats) {
      setValidated(true);
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        type: "disability",
        economic_e_data: [
          {
            backlog: backLog,
            current_provision: currentProvision,
            total_provision: totalProvision,
          },
        ],
      };
      console.log("DraftEconomic_Disability_E finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_economic_e",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftEconomic_Disability_E  response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false); // Reset validation state
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  return (
    <>
      <Helmet>
        <title>अपंग खर्चाची तरतूद</title>
      </Helmet>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container pt-0 common-section">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-main-sec">
                  <div className="table_title_top">
                    <p>क) ५% अपंग खर्चाची तरतूद </p>

                    {economicData === "Y" ? (
                      <span className="drafted_text">
                        <FaEdit /> Drafted
                      </span>
                    ) : economicData === "N" ? (
                      <span className="submit_text">
                        Submitted{" "}
                        <img
                          src={require("../../component/assets/images/sumbit-success.png")}
                          alt="success"
                          width={30}
                          className="img-fluid"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "5rem" }}>
                            अ.क्र.
                          </th>
                          <th scope="col">मागील अनुशेष</th>
                          <th scope="col">
                            चालू तरतूद <br /> (सन २०२२-२०२३)
                          </th>
                          <th scope="col">
                            एकूण तरतूद <br />
                            (मागील + चालू) (सन २०२२-२०२३)
                          </th>
                          {/* <th scope="col">खर्च</th>
                          <th scope="col">बाब</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              type="number"
                              value={1}
                              disabled
                              className="form-control table-input-box"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              style={{ background: "transparent" }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={backLog}
                              onChange={(e) => setBackLog(e.target.value)}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(backLog)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(backLog)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              ref={backLogRef}
                              autoFocus
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={currentProvision}
                              onChange={(e) =>
                                setCurrentProvision(e.target.value)
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(currentProvision)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(currentProvision)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              ref={currentProvisionRef}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={totalProvision}
                              readOnly
                              className="form-control table-input-box fw-bold"
                            />
                          </td>
                          {/* <td>
                            <input
                              type="text"
                              value={expenses}
                              onChange={(e) => setExpenses(e.target.value)}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(expenses)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(expenses)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              ref={expensesRef}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={matter}
                              onChange={(e) => setMatter(e.target.value)}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isText(matter)
                                  ? "कृपया वैध माहिती प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isText(matter) ? "is-invalid" : ""
                              }`}
                              ref={matterRef}
                            />
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-5">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
                {(economicData === "" || economicData === "Y") && (
                  <FormBtn
                    handleSubmit={handleFormSubmission}
                    handleDraft={handleDraftExpenseProvision}
                    resetForm={handleDataReset}
                  />
                )}
              </div>
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleExpenseProvisionDetails}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            {/* <ToastContainer
              position={"top-right"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            /> */}
          </div>
        )}
      </>
    </>
  );
};

export default EconomicsYear_Expanse2;
