import React, { Suspense, lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import Home from "../Home/Home";
import imgTop from "../assets/images/gram-top-2.png";
import "../dashboard/Dashboard.css";
import { Helmet } from "react-helmet-async";
import DeputycoHome from "../Home/deputycoHome/DeputycoHome";
import BdoHome from "../Home/bdoHome/BdoHome";
import VistarAdikariHome from "../Home/vistarAdikariHome/VistarAdikariHome";
import VistarAdikariSwachhBharatHome from "../Home/vistarAdikariSwachhBharatHome/VistarAdikariSwachhBharatHome";
import style from "./Dashboard.module.css";
import Footer from "../footer/Footer";

const GramSevak = lazy(() => import("../Home/gramSevak/GramSevak"));
const Dashboard = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSideBarOpen] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      console.log("Please Login First");
      navigate("/");
    }
  }, []);

  var userDataInfo = sessionStorage.getItem("info");
  var userDetails = JSON.parse(userDataInfo);
  var userInfo = userDetails.user_details;

  return (
    <React.Fragment>
      <Helmet>
        <title>ग्रामपंचायत | मुखपृष्ट</title>
      </Helmet>
      <div className="dashboard-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ps-0 pe-0">
              <img src={imgTop} className="w-100 img-fluid" alt="" />
            </div>
            <div
              className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 ps-0 pe-0 ${style.navbar_sticky}`}
            >
              <Navbar
                sidebarOpen={sidebarOpen}
                setSideBarOpen={setSideBarOpen}
              />
            </div>
            <div
              className={` ${
                !sidebarOpen
                  ? `col-xl-3 ${style.dashboard_col_3} col-lg-3 col-md-3 col-sm-3 ps-0 pe-0`
                  : `d-none`
              } `}
            >
              <Sidebar
                sidebarOpen={sidebarOpen}
                setSideBarOpen={setSideBarOpen}
              />
            </div>
            <div
              style={{ backgroundColor: "#F0F5F7" }}
              className={` ${
                !sidebarOpen
                  ? `col-xl-9 ${style.dashboard_col_9} col-lg-9 col-md-9 col-sm-9 pe-0`
                  : `w-100`
              } `}
            >
              {userInfo.role_id === "1" ||
              userInfo.role_id === "2" ||
              userInfo.role_id === "3" ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <GramSevak />
                </Suspense>
              ) : userInfo.role_id === "7" ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <GramSevak />
                </Suspense>
              ) : // <DeputycoHome />
              userInfo.role_id === "6" ? (
                <GramSevak />
              ) : // <BdoHome />
              userInfo.role_id === "5" ? (
                <VistarAdikariHome />
              ) : userInfo.role_id === "22" ? (
                <VistarAdikariSwachhBharatHome />
              ) : (
                <Home />
              )}
            </div>
            <div
              className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 ps-0 pe-0`}
            >
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
