/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Janm_Mrutu.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import Loader from "../../component/Loader/Loader";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";
const Janm_Mrutu = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [month, setMonth] = useState("");
  const [draftButton, setDraftButton] = useState(true);
  const [resetButton, setResetButton] = useState(true);
  const [validated, setValidated] = useState(false);

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [monthlyData, setMonthlyData] = useState([]);

  const marathiMonthNames = [
    "जानेवारी",
    "फेब्रुवारी",
    "मार्च",
    "एप्रिल",
    "मे",
    "जून",
    "जुलै",
    "ऑगस्ट",
    "सप्टेंबर",
    "ऑक्टोबर",
    "नोव्हेंबर",
    "डिसेंबर",
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // जन्म मृत्यू अहवाल Get Information api

  const handleBirthDeathDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/birth_death_report",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("BirthDeathDetails response", response.data.data);

      if (
        response.data.data.birth_death_report_data !== null &&
        response.data.data.birth_death_report_data.length > 0
      ) {
        setMonthlyData(response.data.data.birth_death_report_data);
      } else {
        var tempData = [];

        for (var i = 0; i < 12; i++) {
          var monthData = {
            month: marathiMonthNames[i],
            birth_male: 0,
            birth_woman: 0,
            death_male: 0,
            death_woman: 0,
            total_man: 0,
            total_woman: 0,
          };
          tempData.push(monthData);
        }
        setMonthlyData(tempData);
        console.log("tempData", tempData);
      }
      setMonth(response.data.data.month);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      setEconomicData(response.data.data.birth_death_report_data[0].is_draft);

      // updated values January month inputs
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleBirthDeathDetails();
  }, []);

  const handleDataRefresh = async () => {
    await handleBirthDeathDetails();
  };

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  // Function to update data for a specific month
  const updateMonthData = (index, newData) => {
    setMonthlyData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], ...newData };
      return updatedData;
    });
  };

  const isValid = monthlyData.every((rowData) => {
    return (
      isNumeric(rowData.birth_male) &&
      isNumeric(rowData.birth_woman) &&
      isNumeric(rowData.death_male) &&
      isNumeric(rowData.death_woman)
    );
  });


  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    if (!isValid) {
        if(!validated){
          await handleUpdateBirthDeath(e);
        }
    }else{
      handleShow();
    }
  }
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };
  // जन्म मृत्यू अहवाल  Update Information api

  const handleUpdateBirthDeath = async (e) => {
    e.preventDefault();

    // Validate all input fields at once
    // const isValid = monthlyData.every((rowData) => {
    //   return (
    //     isNumeric(rowData.birth_male) &&
    //     isNumeric(rowData.birth_woman) &&
    //     isNumeric(rowData.death_male) &&
    //     isNumeric(rowData.death_woman)
    //   );
    // });

    if (!isValid) {
      scrollToTop();
      setValidated(true);
      return;
    }
    handleClose();

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        birth_death_report_data: monthlyData.map((rowData) => ({
          month: rowData.month,
          birth_male: rowData.birth_male,
          birth_woman: rowData.birth_woman,
          death_male: rowData.death_male,
          death_woman: rowData.death_woman,
          total_man:
            parseFloat(rowData.birth_male) + parseFloat(rowData.death_male),
          total_woman:
            parseFloat(rowData.birth_woman) + parseFloat(rowData.death_woman),
        })),
      };
      console.log("updateBirthDeath finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_birth_death_report",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateBirthDeath response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // जन्म मृत्यू अहवाल  Draft Information api

  const handleDraftBirthDeath = async (e) => {
    e.preventDefault();

    // Draft validation
    const isValidDraft = monthlyData.every((rowData) => {
      const isBirthEmpty =
        rowData.birth_male.trim() === "" && rowData.birth_woman.trim() === "";
      const isDeathEmpty =
        rowData.death_male.trim() === "" && rowData.death_woman.trim() === "";

      if (isBirthEmpty && isDeathEmpty) {
        return true; // No data entered, skip validation
      }

      const isValidNumeric =
        isNumeric(rowData.birth_male) &&
        isNumeric(rowData.birth_woman) &&
        isNumeric(rowData.death_male) &&
        isNumeric(rowData.death_woman);

      return isBirthEmpty || isDeathEmpty || isValidNumeric;
    });

    if (!isValidDraft) {
      setValidated(true);
      return;
    }

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        birth_death_report_data: monthlyData.map((rowData) => ({
          month: rowData.month,
          birth_male: rowData.birth_male,
          birth_woman: rowData.birth_woman,
          death_male: rowData.death_male,
          death_woman: rowData.death_woman,
          total_man:
            parseFloat(rowData.birth_male) + parseFloat(rowData.death_male),
          total_woman:
            parseFloat(rowData.birth_woman) + parseFloat(rowData.death_woman),
        })),
      };
      console.log("DraftBirthDeath finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_birth_death_report",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftBirthDeath response", response);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // total calculation of all field

  var totalBirthMale = 0;

  if (monthlyData && monthlyData.length > 0) {
    monthlyData.forEach((data, index) => {
      totalBirthMale += isNaN(parseFloat(data.birth_male))
        ? 0
        : Math.round(parseFloat(data.birth_male));
    });
  }

  var totalBirthWomen = 0;

  if (monthlyData && monthlyData.length > 0) {
    monthlyData.forEach((data, index) => {
      totalBirthWomen += isNaN(parseFloat(data.birth_woman))
        ? 0
        : Math.round(parseFloat(data.birth_woman));
    });
  }

  // total death male
  var totalDeathMale = 0;

  if (monthlyData && monthlyData.length > 0) {
    monthlyData.forEach((data, index) => {
      totalDeathMale += isNaN(parseFloat(data.death_male))
        ? 0
        : Math.round(parseFloat(data.death_male));
    });
  }

  // total death women
  var totalDeathWomen = 0;

  if (monthlyData && monthlyData.length > 0) {
    monthlyData.forEach((data, index) => {
      totalDeathWomen += isNaN(parseFloat(data.death_woman))
        ? 0
        : Math.round(parseFloat(data.death_woman));
    });
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Helmet>
            <title>जन्म मृत्यू अहवाल </title>
          </Helmet>
          <div className="container common-section">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive mb-3 mt-5">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          जन्म मृत्यू अहवाल{" "}
                          {economicData === "Y" ? (
                            <span
                              className="drafted_text"
                              style={{
                                marginLeft: "1rem",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "#dc3545",
                              }}
                            >
                              <FaEdit style={{ marginTop: "-3px" }} /> Drafted
                            </span>
                          ) : economicData === "N" ? (
                            <span
                              className="submit_text"
                              style={{
                                marginLeft: "1rem",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "#2bc63a",
                              }}
                            >
                              Submitted{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={27}
                                style={{ marginTop: "-3px" }}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="d-flex justify-content-around">
                          <label className="birth_death_label">
                            ग्रामपंचयात:{" "}
                            {villageDataInfo === undefined ? (
                              <span></span>
                            ) : (
                              <span className="fw-bold">
                                {" "}
                                {villageDataInfo.village_name}
                              </span>
                            )}
                          </label>
                          <label className="birth_death_label">
                            तालुका:{" "}
                            {villageDataInfo === undefined ? (
                              <span></span>
                            ) : (
                              <span className="fw-bold">
                                {" "}
                                {villageDataInfo.taluka_name}
                              </span>
                            )}
                          </label>
                          <label className="birth_death_label">
                            जिल्हा:{" "}
                            {villageDataInfo === undefined ? (
                              <span></span>
                            ) : (
                              <span className="fw-bold">
                                {" "}
                                {villageDataInfo.district_name}
                              </span>
                            )}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" rowSpan={2}>
                        अ.क्र.
                      </th>
                      <th scope="col" rowSpan={2}>
                        महिना
                      </th>
                      <th className="text-center" scope="col" colSpan={2}>
                        जन्म
                      </th>
                      <th className="text-center" scope="col" colSpan={2}>
                        मृत्यू
                      </th>
                      <th className="text-center" scope="col" colSpan={2}>
                        एकूण
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">पुरुष </th>
                      <th scope="col">स्त्री</th>
                      <th scope="col">पुरुष</th>
                      <th scope="col">स्त्री</th>
                      <th scope="col">पुरुष</th>
                      <th scope="col">स्त्री</th>
                    </tr>
                  </thead>
                  <tbody>
                    {monthlyData.map((rowData, index) => {
                      var isCurrentMonth = false;
                      const isCurrentIndex = index;
                      if (isCurrentIndex + 1 === parseInt(month)) {
                        isCurrentMonth = true;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="number"
                              value={index + 1}
                              className="form-control table-input-box"
                              readOnly
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={rowData.month || ""}
                              style={{ width: "30vh" }}
                              className="form-control table-input-box"
                              readOnly
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={rowData.birth_male || ""}
                              onChange={(e) =>
                                updateMonthData(index, {
                                  birth_male: e.target.value,
                                })
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(rowData.birth_male)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(rowData.birth_male)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              autoFocus
                              disabled={!isCurrentMonth}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={rowData.birth_woman || ""}
                              onChange={(e) =>
                                updateMonthData(index, {
                                  birth_woman: e.target.value,
                                })
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(rowData.birth_woman)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(rowData.birth_woman)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={!isCurrentMonth}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={rowData.death_male || ""}
                              onChange={(e) =>
                                updateMonthData(index, {
                                  death_male: e.target.value,
                                })
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(rowData.death_male)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(rowData.death_male)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={!isCurrentMonth}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={rowData.death_woman || ""}
                              onChange={(e) =>
                                updateMonthData(index, {
                                  death_woman: e.target.value,
                                })
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              placeholder={
                                validated && !isNumeric(rowData.death_woman)
                                  ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                  : ""
                              }
                              className={`form-control table-input-box ${
                                validated && !isNumeric(rowData.death_woman)
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled={!isCurrentMonth}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={
                                (parseInt(rowData.birth_male) || 0) +
                                  (parseInt(rowData.death_male) || 0)
                                }
                              className="form-control table-input-box fw-bold"
                              readOnly
                              disabled={!isCurrentMonth}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={
                                (parseInt(rowData.birth_woman) || 0) +
                                (parseInt(rowData.death_woman) || 0)
                              }
                              className="form-control table-input-box fw-bold"
                              readOnly
                              disabled={!isCurrentMonth}
                            />
                          </td>
                        </tr>
                      );
                    })}

                    {/* total */}
                    <tr>
                      <td>
                        <label className="fw-bold">एकूण</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          style={{ width: "30vh" }}
                          className="form-control fw-bold table-input-box"
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          style={{ width: "30vh" }}
                          className="form-control fw-bold table-input-box"
                          value={totalBirthMale || 0}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control fw-bold table-input-box"
                          value={totalBirthWomen || 0}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control fw-bold table-input-box"
                          value={totalDeathMale || 0}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control fw-bold table-input-box"
                          value={totalDeathWomen || 0}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control fw-bold table-input-box"
                          value={
                            (!isNaN(parseFloat(totalBirthMale)) ? parseFloat(totalBirthMale) : 0) +
                            (!isNaN(parseFloat(totalDeathMale)) ? parseFloat(totalDeathMale) : 0)
                          }
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control fw-bold table-input-box"
                          value={
                            (parseFloat(totalBirthWomen) || 0) +
                            (parseFloat(totalDeathWomen) || 0) || 0
                          }
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              {(economicData === "Y" || economicData === "") && (
                <div className="col-lg-12 d-flex justify-content-end align-items=center">
                  <FormBtn
                    handleSubmit={handleFormSubmission}
                    resetForm={handleDataRefresh}
                    draftButton={draftButton}
                    resetButton={resetButton}
                    handleDraft={handleDraftBirthDeath}
                  />
                </div>
              )}
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateBirthDeath}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <ToastContainer
              position={"top-right"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default Janm_Mrutu;
