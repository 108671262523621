/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "../../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import FormBtn from "../../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Village_Tal_Dist_Mahe from "../../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import pulseIcon from "../../../component/assets/images/logo/plus-removebg-preview.png";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./style.module.css";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";

const Prapatra = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [districtInfo, setDistrictInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");

  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  //  १५% मागासवर्गीय खर्चाची तरतूद  input filed calculation

  const [prapatraData, setPrapatraData] = useState([]);

  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  useEffect(() => {
    handlePrapatraDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talId, villageId, month]);

  const handlePrapatraDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        month: month,
        year_id: userDetails.current_year_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/summary_form",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("Prapatra Data response", response);
      setPrapatraData(response.data.data);
      setYearInfo(response.data.data.year_info);
      setDistrictInfo(response.data.data.other_data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };


  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id: userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>प्रपत्र</title>
      </Helmet>
      <div className="container-fluid common-section common_deputy_container">
        <div className="row common_deputy_row">
          <div className="col-lg-12 navigate-section-header mt-3">
            <div className="page-title">
              <p className="fw-bold mb-2">६. प्रपत्र</p>
            </div>
            <div className="navigate-section d-flex">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className={`${style.select_group_top}`}>
                  <label>जिल्हा: </label>
                  <span className="fw-bold">{districtInfo.district_name}</span>
                </div>
              </div>
              <div className="VillagePanchayat ms-5 d-flex gap-2 justify-content-center align-items-center">
                <label htmlFor="">माहे :</label>
                {month === undefined ? (
                  <span></span>
                ) : (
                  <select
                    name="month"
                    value={month}
                    onChange={(e) => setMonth(parseInt(e.target.value))}
                    className="taluka_select_input"
                  >
                    <option value="1">जानेवारी</option>
                    <option value="2">फेब्रुवारी</option>
                    <option value="3">मार्च</option>
                    <option value="4">एप्रिल</option>
                    <option value="5">मे</option>
                    <option value="6">जून</option>
                    <option value="7">जुलै</option>
                    <option value="8">ऑगस्ट</option>
                    <option value="9">सप्टेंबर</option>
                    <option value="10">ऑक्टोंबर</option>
                    <option value="11">नोव्हेंबर</option>
                    <option value="12">डिसेंबर</option>
                  </select>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">तालुका: </label>
                  <select
                    name="taluka"
                    className="taluka_select_input"
                    onChange={(e) => handleOnchangeTaluka(e, talId)}
                    value={talId}
                  >
                    {userDetails.role_id === "7" ? <option>निवडा</option> : ""}
                    {tal.map((ele) => {
                      return (
                        <option key={ele.id} value={ele.id}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">ग्रामपंचयात: </label>
                  <Autocomplete
                    disablePortal
                    className={`${style.select_common_dropdown}`}
                    options={village}
                    sx={{ width: 200 }}
                    onChange={(e, selectedOption) =>
                      handleVillage(selectedOption)
                    }
                    value={
                      villageId
                        ? village.find((v) => v.value === villageId) || null
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="निवडा" />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {prapatraData.level === "District" ||
          prapatraData.level === "Taluka" ? (
            <div className="row">
              <h4 className="mt-4">
                Level :- <i>{prapatraData.level}</i>{" "}
              </h4>
              <div className="col-lg-4 mt-5">
                <div className="card">
                  <div className="card-body">
                    <h4>प्रपत्र १ </h4>
                    <h1 className="float-end"> {prapatraData.form_a_count}</h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-5">
                <div className="card">
                  <div className="card-body">
                    <h4>प्रपत्र २</h4>
                    <h1 className="float-end"> {prapatraData.form_b_count}</h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-5">
                <div className="card">
                  <div className="card-body">
                    <h4>प्रपत्र ३ </h4>
                    <h1 className="float-end"> {prapatraData.form_c_count}</h1>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {prapatraData.level === "Village" && (
            <>
              {/* prapatra-1 */}

              <div className="col-lg-12">
                <div className="table-responsive mb-3 mt-3">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          प्रपत्र १{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          रिक्त सदस्य पदाची माहिती{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-12 table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">ग्रा.पं.नाव</th>
                      <th scope="col">एकूण ग्रा.पं. सदस्य</th>
                      <th scope="col">रिक्त पदाची संख्या</th>
                      <th scope="col">रिक्ता पदाचा प्रभाग</th>
                      <th scope="col">
                        रिक्त पद आरक्षित असल्यास राखीव जागेचा प्रवर्ग
                      </th>
                      <th scope="col"> पद रिक्त होण्याचा दिनांक </th>
                      <th scope="col"> ग्रा.पं.मुदत समाप्तीचा दिनांक </th>
                      <th scope="col"> पद रिक्त होण्याचे कारण </th>
                      <th scope="col"> न्यायालयीन दावा दाखल आहे किवा नाही </th>
                      <th scope="col"> शेरा </th>
                    </tr>
                  </thead>
                  <tbody>
                    {prapatraData.form_a_data.length <= 0 ? (
                      <tr>
                        <td>1</td>
                        <td>N/A</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    ) : (
                      prapatraData.form_a_data.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{ele.gpt_name}</td>
                            <td>{ele.gpt_total_member}</td>
                            <td>{ele.no_of_vacancies}</td>
                            <td>{ele.department_of_vacancy}</td>
                            <td>{ele.reserved_seat}</td>
                            <td>{ele.date_of_vacancy}</td>
                            <td>{ele.gpt_expiry_date}</td>
                            <td>{ele.reason_for_vacancy}</td>
                            <td>{ele.court_claim_filed_or_not}</td>
                            <td>{ele.shera}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>

              {/* prapatra-2 */}
              <div className="col-lg-12">
                <div className="table-responsive mb-3">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          प्रपत्र २{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          प्रशासन नेमलेल्या ग्रामपंचायतची माहिती{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">ग्रा.पं.नाव</th>
                      <th scope="col">प्रशासकाची नाव व हुदा</th>
                      <th scope="col">ग्रा.पं.निवडणुकीची दिनांक</th>
                      <th scope="col">ग्रा.पं.मुदत समाप्तीचा दिनांक</th>
                      <th scope="col">प्रशासन निमलेली तारीख</th>
                      <th scope="col"> प्रशासन नेमण्याचे कारण </th>
                    </tr>
                  </thead>
                  <tbody>
                    {prapatraData.form_b_data.length <= 0 ? (
                      <tr>
                        <td>1</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    ) : (
                      prapatraData.form_b_data.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <td>{index + 1}</td>
                            </td>
                            <td>{ele.gpt_name}</td>
                            <td>{ele.administrators_name_and_title}</td>
                            <td>{ele.gpt_election_date}</td>
                            <td>{ele.gpt_expiry_date}</td>
                            <td>{ele.date_of_administration}</td>
                            <td>{ele.reason_for_appointing_administration}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>

              {/* prapatra-3 */}

              <div className="col-lg-12">
                <div className="table-responsive mb-3">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          प्रपत्र ३
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          मासिक विवरणपत्र - ग्रामपंचायत ऑडिट शक सन २०२२-२०२३{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">ग्रा.पं.नाव</th>
                      <th scope="col">प्रलंबित शक</th>
                      <th scope="col">नवीन शक</th>
                      <th scope="col">एकूण शक</th>
                      <th scope="col">उदीष्ट शक</th>
                      <th scope="col"> पूर्तता केलेले शक </th>
                      <th scope="col"> जि.प. कडे सादर शक </th>
                      <th scope="col"> कामाची टक्केवारी </th>
                      <th scope="col"> एकूण प्रलंबित शक </th>
                      <th scope="col"> शेरा </th>
                    </tr>
                  </thead>
                  <tbody>
                    {prapatraData.form_c_data.length <= 0 ? (
                      <tr>
                        <td>1</td>
                        <td>N/A</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    ) : (
                      prapatraData.form_c_data.map((ele, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{ele.gpt_name}</td>
                            <td>{ele.pending}</td>
                            <td>{ele.new}</td>
                            <td className="fw-bold">{ele.total}</td>
                            <td>{ele.udishtha}</td>
                            <td>{ele.accomplished}</td>
                            <td>{ele.gp_submitted_to}</td>
                            <td>{ele.percentage_of_work}</td>
                            <td className="fw-bold">{ele.total_pending_rs}</td>
                            <td>{ele.shera}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </React.Fragment>
  );
};

export default Prapatra;
