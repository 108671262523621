/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import style from "../../GeneralAdministrationDepartment/districtEconomics/style.module.css";

const DistrictExpences = () => {
  const [gramNidhi, setGramNidhi] = useState("");
  const [supply, setSupply] = useState("");
  const [financeCommission, setFinanceCommission] = useState("");
  const [neoBuddhist, setNeoBuddhist] = useState("");
  const [tantamukti, setTantamukti] = useState("");
  const [mGrRo, setMgrRo] = useState("");
  const [publicConvenience, setPublicConvenience] = useState("");
  const [civilFacilities, setCivilFacilities] = useState("");
  const [total, setTotal] = useState("");
  const [data, setData] = useState([]);
  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");

  const marathiExpanseNames = [
    "ग्रामनिधी",
    "पाणीपुरवठा",
    "	१५ वा वित्त आयोग",
    "	अनुसूचीत जा. व नवबौद्ध घटक विकास",
    "	तंटामुक्त्ती",
    "	म. ग्रा. रो. ह. यो",
    "	जनसुविधा",
    "	नागरी सुविधा",
  ];

  // total of all employee male and female
  const [totalPopulation, setTotalPopulation] = useState("");
  const [women, setWomen] = useState("");
  const [male, setMale] = useState("");
  const [povertyFamily, setPovertyFamily] = useState("");
  const [totalFamily, setTotalFamily] = useState("");
  const [grampanchyatEmployee, setGrampanchyatEmployee] = useState("");
  const [numberOfEmployeeInFigure, setNumberOfEmployeeInFigure] = useState("");

  // input wise date
  const [gramNidhiDate, setGramNidhiDate] = useState("");
  const [supplyDate, setSupplyDate] = useState("");
  const [financeCommissionDate, setFinanceCommissionDate] = useState("");
  const [neoBuddhistDate, setNeoBuddhistDate] = useState("");
  const [tantamuktiDate, setTantamuktiDate] = useState("");
  const [mGrRoDate, setMGrRoDate] = useState("");
  const [publicConvenienceDate, setPublicConvenienceDate] = useState("");
  const [civilFacilitiesDate, setCivilFacilitiesDate] = useState("");

  // expense detail fund type
  const [gramNidhiAuditYear, setGramNidhiAuditYear] = useState("");
  const [gramNidhiPendingNo, setGramNidhiPendingNo] = useState("");
  const [otherPlansAuditYear, setOtherPlansAuditYear] = useState("");
  const [otherPlansPendingNo, setOtherPlansPendingNo] = useState("");

  // other data
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());

  // get data session
  const userDataInfo = sessionStorage.getItem("info");
  const userData = JSON.parse(userDataInfo);
  const userDetails = userData.user_details;

  const totalDetailsOfExpenses = () => {
    const gramNidhiNum = parseFloat(gramNidhi) || 0;
    const supplyNum = parseFloat(supply) || 0;
    const financeCommissionNum = parseFloat(financeCommission) || 0;
    const neoBuddhistNum = parseFloat(neoBuddhist) || 0;
    const tantamuktiNum = parseFloat(tantamukti) || 0;
    const mGrRoNum = parseFloat(mGrRo) || 0;
    const publicConvenienceNum = parseFloat(publicConvenience) || 0;
    const civilFacilitiesNum = parseFloat(civilFacilities) || 0;

    if (
      isNaN(gramNidhiNum) ||
      isNaN(supplyNum) ||
      isNaN(financeCommissionNum) ||
      isNaN(neoBuddhistNum) ||
      isNaN(tantamuktiNum) ||
      isNaN(mGrRoNum) ||
      isNaN(publicConvenienceNum) ||
      isNaN(civilFacilitiesNum)
    ) {
      setTotal("");
    } else {
      const calculateDetailsOfExpenses =
        gramNidhiNum +
        supplyNum +
        financeCommissionNum +
        neoBuddhistNum +
        tantamuktiNum +
        mGrRoNum +
        publicConvenienceNum +
        civilFacilitiesNum;
      setTotal(calculateDetailsOfExpenses);
    }
  };

  useEffect(() => {
    totalDetailsOfExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gramNidhi,
    supply,
    financeCommission,
    neoBuddhist,
    tantamukti,
    mGrRo,
    publicConvenience,
    civilFacilities,
  ]);

  // get data on server
  const handleDetaileExpanse = async () => {
    const finalData = {
      village_id: villageId,
      taluka_id: talId,
      district_id: userDetails.district_id,
      month: month,
      year_id: userDetails.current_year_id,
    };
    console.log("finalData detailexpanse", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "api/summary_expense_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("summary expense_details data", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setYearInfo(response.data.data.year_info);
      const expenseDetailsData = response.data.data.expense_details_data;
      if (
        expenseDetailsData &&
        Array.isArray(expenseDetailsData) &&
        expenseDetailsData.length > 0
      ) {
        var tempData = [];
        for (var i = 0; i < 8; i++) {
          var ExpanseData = {
            type: marathiExpanseNames[i],
            date: expenseDetailsData[i].date || "",
            balance_fund: expenseDetailsData[i].balance_fund || "",
          };
          tempData.push(ExpanseData);
        }
        setData(tempData);
        // setGramNidhi(
        //   response.data.data.expense_details_data.expense_detail_schemes[0]
        //     ?.balance_fund || ""
        // );
        // setGramNidhiDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[0]
        //     ?.date || ""
        // );
        // setSupply(
        //   response.data.data.expense_details_data.expense_detail_schemes[1]
        //     ?.balance_fund || ""
        // );
        // setSupplyDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[1]
        //     ?.date || ""
        // );
        // setFinanceCommission(
        //   response.data.data.expense_details_data.expense_detail_schemes[2]
        //     ?.balance_fund || ""
        // );
        // setFinanceCommissionDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[2]
        //     ?.date || ""
        // );
        // setNeoBuddhist(
        //   response.data.data.expense_details_data.expense_detail_schemes[3]
        //     ?.balance_fund || ""
        // );
        // setNeoBuddhistDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[3]
        //     ?.date || ""
        // );
        // setTantamukti(
        //   response.data.data.expense_details_data.expense_detail_schemes[4]
        //     ?.balance_fund || ""
        // );
        // setTantamuktiDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[4]
        //     ?.date || ""
        // );
        // setMgrRo(
        //   response.data.data.expense_details_data.expense_detail_schemes[5]
        //     ?.balance_fund || ""
        // );
        // setMGrRoDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[5]
        //     ?.date || ""
        // );
        // setPublicConvenience(
        //   response.data.data.expense_details_data.expense_detail_schemes[6]
        //     ?.balance_fund || ""
        // );
        // setPublicConvenienceDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[6]
        //     ?.date || ""
        // );
        // setCivilFacilities(
        //   response.data.data.expense_details_data.expense_detail_schemes[7]
        //     ?.balance_fund || ""
        // );
        // setCivilFacilitiesDate(
        //   response.data.data.expense_details_data.expense_detail_schemes[7]
        //     ?.date || ""
        // );

        // setTotalPopulation(
        //   response.data.data.expense_details_data.total_population
        // );
        // setWomen(response.data.data.expense_details_data.woman);
        // setMale(response.data.data.expense_details_data.male);
        // setPovertyFamily(
        //   response.data.data.expense_details_data
        //     .total_number_of_families_below_poverty_line
        // );
        // setTotalFamily(
        //   response.data.data.expense_details_data.total_number_of_families
        // );
        // setGrampanchyatEmployee(
        //   response.data.data.expense_details_data
        //     .number_of_gram_panchayat_employees
        // );
        // setNumberOfEmployeeInFigure(
        //   response.data.data.expense_details_data
        //     .number_of_employees_in_the_figure
        // );

        // setGramNidhiAuditYear(
        //   response.data.data.expense_details_data.expense_detail_fund_type[0]
        //     ?.audit_note_year || ""
        // );
        // setGramNidhiPendingNo(
        //   response.data.data.expense_details_data.expense_detail_fund_type[0]
        //     ?.pending_no || ""
        // );

        // setOtherPlansAuditYear(
        //   response.data.data.expense_details_data.expense_detail_fund_type[1]
        //     ?.audit_note_year || ""
        // );
        // setOtherPlansPendingNo(
        //   response.data.data.expense_details_data.expense_detail_fund_type[1]
        //     ?.pending_no || ""
        // );
      } else {
        var tempElseData = [];
        for (var j = 0; j < 8; j++) {
          var ExpanseDataElse = {
            type: marathiExpanseNames[j],
            date: "",
            balance_fund: "0",
          };
          tempElseData.push(ExpanseDataElse);
        }
        setData(tempElseData);
      }
    } catch (error) {
      console.log("error occured fetch data on server", error);
    }
  };

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  useEffect(() => {
    handleDetaileExpanse();
  }, [talId, villageId, month]);

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };


  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id: userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

// calculate total

var totalExpanse = 0;

 if(data && data.length > 0 && data.balance_fund !== ""){
  data.forEach((item)=>{
    if (item && typeof item.balance_fund === 'string') {
      totalExpanse += Math.round(parseFloat(item.balance_fund));
    }
  })
 }

  return (
    <React.Fragment>
      <Helmet>
        <title>खर्चाचा तपशील</title>
      </Helmet>
      <div className="container-fluid common-section common_deputy_container">
        <div className="row common_deputy_row">
          <div className="col-lg-12 navigate-section-header mt-3">
            <div className="page-title">
              <p className="fw-bold mb-2"># खर्चाचा तपशील</p>
            </div>

            <div className="navigate-section d-flex">
              <div className="mt-1 d-flex flex-column justify-content-center align-items-center">
                <h6>जिल्हा: {villageDataInfo.district_name}</h6>
              </div>
              <div className="VillagePanchayat ms-5 d-flex gap-2 justify-content-center align-items-center">
                <label htmlFor="">माहे :</label>
                {month === undefined ? (
                  <span></span>
                ) : (
                  <select
                    name="month"
                    value={month}
                    onChange={(e) => setMonth(parseInt(e.target.value))}
                    className="taluka_select_input"
                  >
                    <option value="1">जानेवारी</option>
                    <option value="2">फेब्रुवारी</option>
                    <option value="3">मार्च</option>
                    <option value="4">एप्रिल</option>
                    <option value="5">मे</option>
                    <option value="6">जून</option>
                    <option value="7">जुलै</option>
                    <option value="8">ऑगस्ट</option>
                    <option value="9">सप्टेंबर</option>
                    <option value="10">ऑक्टोंबर</option>
                    <option value="11">नोव्हेंबर</option>
                    <option value="12">डिसेंबर</option>
                  </select>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">तालुका: </label>
                  <select
                    name="taluka"
                    className="taluka_select_input"
                    onChange={(e) => handleOnchangeTaluka(e, talId)}
                    value={talId}
                  >
                    {userDetails.role_id === "7" ? <option>निवडा</option> : ""}
                    {tal.map((ele) => {
                      return (
                        <option key={ele.id} value={ele.id}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">ग्रामपंचयात: </label>
                  <Autocomplete
                    disablePortal
                    className={`${style.select_common_dropdown}`}
                    options={village}
                    sx={{ width: 200 }}
                    onChange={(e, selectedOption) =>
                      handleVillage(selectedOption)
                    }
                    value={
                      villageId
                        ? village.find((v) => v.value === villageId) || null
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="निवडा" />
                    )}
                  />
                </div>
              </div>
            </div>
            {/* <TabEco/> */}
          </div>
          <div className="col-lg-12 mt-5">
            <div className="table-main-sec">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">योजनेचे नाव</th>
                      <th scope="col">दिनांक</th>
                      <th scope="col">शिल्लक निधी</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td>१</td>
                      <td>ग्रामनिधी</td>
                      <td>{gramNidhiDate}</td>
                      <td>{gramNidhi}</td>
                    </tr>
                    <tr>
                      <td>२</td>
                      <td>पाणीपुरवठा</td>
                      <td>{supplyDate}</td>
                      <td>{supply}</td>
                    </tr>
                    <tr>
                      <td>३</td>
                      <td>१५ वा वित्त आयोग</td>
                      <td>{financeCommissionDate}</td>
                      <td>{financeCommission}</td>
                    </tr>
                    <tr>
                      <td>४</td>
                      <td>अनुसूचीत जा. व नवबौद्ध घटक विकास</td>
                      <td>{neoBuddhistDate}</td>
                      <td>{neoBuddhist}</td>
                    </tr>
                    <tr>
                      <td>५</td>
                      <td>तंटामुक्त्ती</td>
                      <td>{tantamuktiDate}</td>
                      <td>{tantamukti}</td>
                    </tr>
                    <tr>
                      <td>६</td>
                      <td>म. ग्रा. रो. ह. यो</td>
                      <td>{mGrRoDate}</td>
                      <td>{mGrRo}</td>
                    </tr>
                    <tr>
                      <td>७</td>
                      <td>जनसुविधा</td>
                      <td>{publicConvenienceDate}</td>
                      <td>{publicConvenience}</td>
                    </tr>
                    <tr>
                      <td>८</td>
                      <td>नागरी सुविधा</td>
                      <td>{civilFacilitiesDate}</td>
                      <td>{civilFacilities}</td>
                    </tr> */}
                    {data.map((item, index) => {
                      if(item.date !== ""){
                        const inputDate = item.date;
                        var formattedDate = new Date(inputDate).toLocaleDateString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      });
                      }
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.type}</td>
                          <td>{formattedDate || item.date}</td>
                          <td>{item.balance_fund}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>9</td>
                      <td className="fw-bold">एकूण</td>
                      <td>
                        {/* <input
                          type="text"
                          className="form-control table-input-box"
                          readOnly
                        /> */}
                      </td>
                      <td className="fw-bold">{totalExpanse || 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12 mt-4">
            <div className="table-division d-flex">
              <div className="col-lg-4">
                <div className="total-people table-box border d-flex align-items-center">
                  <label>एकूण लोकसंख्या :</label>
                  <p
                    className="ms-3 mt-1 table-input-box"
                    style={{ width: "auto" }}
                  >
                    {totalPopulation}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ladies-box table-box border d-flex align-items-center">
                  <label>स्त्री :</label>
                  <p
                    className="ms-3 mt-1 table-input-box"
                    style={{ width: "auto" }}
                  >
                    {women}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="gents-box table-box border d-flex align-items-center">
                  <label>पुरुष :</label>
                  <p
                    className="ms-3 mt-1 table-input-box"
                    style={{ width: "auto" }}
                  >
                    {male}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            <div className="table-division d-flex">
              <div className="col-lg-6">
                <div className="total-poverty table-box border d-flex align-items-center">
                  <label>एकूण दारिद्र्य रेषेखालील कुटुंब संख्या :</label>
                  <p
                    className="ms-3 mt-1 table-input-box"
                    style={{ width: "auto" }}
                  >
                    {povertyFamily}
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="total-family table-box border d-flex align-items-center">
                  <label>एकूण कुटुंब संख्या :</label>
                  <p
                    className="ms-3 mt-1 table-input-box"
                    style={{ width: "auto" }}
                  >
                    {totalFamily}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            <div className="table-division d-flex">
              <div className="col-lg-6">
                <div className="GramPanchayatStaff table-box border d-flex align-items-center">
                  <label>ग्रामपंचायत कर्मचारी संख्या :</label>
                  <p
                    className="ms-3 mt-1 table-input-box"
                    style={{ width: "auto" }}
                  >
                    {grampanchyatEmployee}
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="employeesInFigure table-box border d-flex align-items-center">
                  <label>आकृतीबांधतील कर्मचारी संख्या :</label>
                  <p
                    className="ms-3 mt-1 table-input-box"
                    style={{ width: "auto" }}
                  >
                    {numberOfEmployeeInFigure}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-5 mb-4">
            <div className="table-main-sec">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">निधीचा प्रकार</th>
                      <th scope="col">ऑडिट नोट वर्ष</th>
                      <th scope="col">प्रलंबित शक संख्या</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>१</td>
                      <td>ग्रामनिधी / पणीपुरवठा</td>
                      <td>{gramNidhiAuditYear}</td>
                      <td>{gramNidhiPendingNo}</td>
                    </tr>
                    <tr>
                      <td>२</td>
                      <td>इतर योजना</td>
                      <td>{otherPlansAuditYear}</td>
                      <td>{otherPlansPendingNo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </React.Fragment>
  );
};

export default DistrictExpences;
