/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./Fifteen_Vith.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";
import Loader from "../../component/Loader/Loader";

const Fifteen_Vith = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [validated, setValidated] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  // const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [year, setYear] = useState("");

  // Tied Ups states
  // const [tuFundRecived, setTuFundRecived] = useState("");
  const [tuAdministrativeExpences, setTuAdministrativeExpences] = useState("");
  const [tuNinetyCost, setTuNinetyCost] = useState("");
  const [tuTotalSanctionedWorks, setTuTotalSanctionedWorks] = useState("");
  const [tuCompleteWorks, setTuCompleteWorks] = useState("");
  const [tuWorksProgress, setTuWorksProgress] = useState("");
  const [tuUnfinishedWorks, setTuUnfinishedWorks] = useState("");
  const [tuUnspentFunds, setTuUnspentFunds] = useState("");
  const [tuPercentageCost, setTuPercentageCost] = useState(0);
  const [tuSign, setTuSign] = useState("");

  // Unbound states
  // const [ubFundRecived, setUbFundRecived] = useState("");
  const [ubAdministrativeExpences, setUbAdministrativeExpences] = useState("");
  const [ubNinetyCost, setUbNinetyCost] = useState("");
  const [ubTotalSanctionedWorks, setUbTotalSanctionedWorks] = useState("");
  const [ubCompleteWorks, setUbCompleteWorks] = useState("");
  const [ubWorksProgress, setUbWorksProgress] = useState("");
  const [ubUnfinishedWorks, setUbUnfinishedWorks] = useState("");
  const [ubUnspentFunds, setUbUnspentFunds] = useState("");
  const [ubPercentageCost, setUbPercentageCost] = useState(0);
  const [ubSign, setUbSign] = useState("");

  // total calculation

  const [totalAdministrativeExpences, setTotalAdministrativeExpences] =
    useState("");
  const [totalNinetyCost, setTotalNinetyCost] = useState("");
  const [totalTotalSanctionedWorks, setTotalSanctionedWorks] = useState("");
  const [totalCompleteWorks, setTotalCompleteWorks] = useState("");
  const [totalWorksProgress, setTotalWorksProgress] = useState("");
  const [totalUnfinishedWorks, setTotalUnfinishedWorks] = useState("");
  const [totalUnspentFunds, setTotalUnspentFunds] = useState("");
  const [totalPercentageCost, setTotalPercentageCost] = useState(0);

  // total १० % प्रशासकीय खर्च
  useEffect(() => {
    calculateTotalAdministrativeExpences();
  }, [tuAdministrativeExpences, ubAdministrativeExpences]);

  const calculateTotalAdministrativeExpences = () => {
    const num1 = parseFloat(tuAdministrativeExpences) || 0;
    const num2 = parseFloat(ubAdministrativeExpences) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalAdministrativeExpences("");
    } else {
      const sum = num1 + num2;
      setTotalAdministrativeExpences(Math.round(sum));
    }
  };

  // total १० % प्रशासकीय खर्च
  useEffect(() => {
    calculateTotalNinetyCost();
  }, [tuNinetyCost, ubNinetyCost]);

  const calculateTotalNinetyCost = () => {
    const num1 = parseFloat(tuNinetyCost) || 0;
    const num2 = parseFloat(ubNinetyCost) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalNinetyCost("");
    } else {
      const sum = num1 + num2;
      setTotalNinetyCost(Math.round(sum));
    }
  };

  // total एकूण मंजूर कामे (उपलब्ध निधीच्या प्रमाणात )
  useEffect(() => {
    calculateTotalSanctionedWorks();
  }, [tuTotalSanctionedWorks, ubTotalSanctionedWorks]);

  const calculateTotalSanctionedWorks = () => {
    const num1 = parseFloat(tuTotalSanctionedWorks) || 0;
    const num2 = parseFloat(ubTotalSanctionedWorks) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalSanctionedWorks("");
    } else {
      const sum = num1 + num2;
      setTotalSanctionedWorks(Math.round(sum));
    }
  };

  // total पूर्ण कामे
  useEffect(() => {
    calculateCompleteWorks();
  }, [tuCompleteWorks, ubCompleteWorks]);

  const calculateCompleteWorks = () => {
    const num1 = parseFloat(tuCompleteWorks) || 0;
    const num2 = parseFloat(ubCompleteWorks) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalCompleteWorks("");
    } else {
      const sum = num1 + num2;
      setTotalCompleteWorks(Math.round(sum));
    }
  };

  // total प्रगतीत कामे
  useEffect(() => {
    calculateWorksProgress();
  }, [tuWorksProgress, ubWorksProgress]);

  const calculateWorksProgress = () => {
    const num1 = parseFloat(tuWorksProgress) || 0;
    const num2 = parseFloat(ubWorksProgress) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalWorksProgress("");
    } else {
      const sum = num1 + num2;
      setTotalWorksProgress(Math.round(sum));
    }
  };

  // total अपूर्ण कामे
  useEffect(() => {
    calculateUnfinishedWorks();
  }, [tuUnfinishedWorks, ubUnfinishedWorks]);

  const calculateUnfinishedWorks = () => {
    const num1 = parseFloat(tuUnfinishedWorks) || 0;
    const num2 = parseFloat(ubUnfinishedWorks) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalUnfinishedWorks("");
    } else {
      const sum = num1 + num2;
      setTotalUnfinishedWorks(Math.round(sum));
    }
  };

  // total अखर्चित निधी
  useEffect(() => {
    calculateUnspentFunds();
  }, [tuUnspentFunds, ubUnspentFunds]);

  const calculateUnspentFunds = () => {
    const num1 = parseFloat(tuUnspentFunds) || 0;
    const num2 = parseFloat(ubUnspentFunds) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalUnspentFunds("");
    } else {
      const sum = num1 + num2;
      setTotalUnspentFunds(Math.round(sum));
    }
  };

  // tuPercentageCost खर्चाची टक्केवारी
  useEffect(() => {
    calculateTuPercentageCost();
  }, [tuAdministrativeExpences, tuNinetyCost, tuUnspentFunds]);

  const calculateTuPercentageCost = () => {
    const num1 = parseFloat(tuAdministrativeExpences) || 0;
    const num2 = parseFloat(tuNinetyCost) || 0;
    const num3 = parseFloat(tuUnspentFunds) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3)) {
      setTuPercentageCost(0);
    } else {
      const sum = num1 + num2 - num3;
      const sum2 = num1 + num2;
      const sum3 = (sum / sum2) * 100;
      setTuPercentageCost(isNaN(sum3) ? 0 : `${sum3.toFixed(2)}%`);
      // console.log("percentage of bandit", sum3);
    }
  };

  // ubPercentageCost खर्चाची टक्केवारी
  useEffect(() => {
    calculateUbPercentageCost();
  }, [ubAdministrativeExpences, ubNinetyCost, ubUnspentFunds]);

  const calculateUbPercentageCost = () => {
    const num1 = parseFloat(ubAdministrativeExpences) || 0;
    const num2 = parseFloat(ubNinetyCost) || 0;
    const num3 = parseFloat(ubUnspentFunds) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3)) {
      setUbPercentageCost(0);
    } else {
      const sum = num1 + num2 - num3;
      const sum2 = num1 + num2;
      const sum3 = (sum / sum2) * 100;
      setUbPercentageCost(isNaN(sum3) ? 0 : `${sum3.toFixed(2)}%`);
    }
  };

  // TotalPercentageCost खर्चाची टक्केवारी
  useEffect(() => {
    calculateTotalPercentageCost();
  }, [totalAdministrativeExpences, totalNinetyCost, totalUnspentFunds]);

  const calculateTotalPercentageCost = () => {
    const num1 = parseFloat(totalAdministrativeExpences) || 0;
    const num2 = parseFloat(totalNinetyCost) || 0;
    const num3 = parseFloat(totalUnspentFunds) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3)) {
      setTotalPercentageCost(0);
    } else {
      const sum = num1 + num2 - num3;
      const sum2 = num1 + num2;
      const sum3 = (sum / sum2) * 100;
      setTotalPercentageCost(isNaN(sum3) ? 0 : `${sum3.toFixed(2)}%`);
      // console.log("TotalPercentageCost", sum3);
    }
  };

  // total खर्चाची टक्केवारी
  // useEffect(() => {
  //   calculatePercentageCost();
  // }, [tuPercentageCost, ubPercentageCost]);

  // const calculatePercentageCost = () => {
  //   const num1 = parseFloat(tuPercentageCost) || 0;
  //   const num2 = parseFloat(ubPercentageCost) || 0;

  //   if (isNaN(num1) || isNaN(num2)) {
  //     setTotalPercentageCost("");
  //   } else {
  //     const sum = num1 + num2;
  //     setTotalPercentageCost(Math.round(sum));
  //   }
  // };

  // १५ वा वित्त आयोग मासिक प्रगती अहवाल Get Information api

  const handleFifteenVthDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month,
      };
      const response = await axios.post(
        API_BASE_URL + "api/vith_ayog",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("FifteenVthDetails response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setYearInfo(response.data.data.year_info);

      if (
        response.data.data.vith_ayog_data === null ||
        response.data.data.vith_ayog_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.vith_ayog_data[0].is_draft);
      }

      // updated values in Tied Up inputs
      if (
        response.data.data.vith_ayog_data !== null &&
        response.data.data.vith_ayog_data.length > 0
      ) {
        setTuAdministrativeExpences(
          response.data.data.vith_ayog_data[0].ten_administrative_expenses
        );
        setTuNinetyCost(response.data.data.vith_ayog_data[0].ninety_cost);
        setTuTotalSanctionedWorks(
          response.data.data.vith_ayog_data[0].total_sanctioned_works
        );
        setTuCompleteWorks(response.data.data.vith_ayog_data[0].complete_works);
        setTuWorksProgress(
          response.data.data.vith_ayog_data[0].works_in_progress
        );
        setTuUnfinishedWorks(
          response.data.data.vith_ayog_data[0].unfinished_works
        );
        setTuUnspentFunds(response.data.data.vith_ayog_data[0].unspent_funds);
        setTuPercentageCost(
          response.data.data.vith_ayog_data[0].percentage_of_cost
        );
        setTuSign(response.data.data.vith_ayog_data[0].shera);
        setYear(response.data.data.vith_ayog_data[0].year);

        // updated values in Unbound inputs

        setUbAdministrativeExpences(
          response.data.data.vith_ayog_data[1].ten_administrative_expenses
        );
        setUbNinetyCost(response.data.data.vith_ayog_data[1].ninety_cost);
        setUbTotalSanctionedWorks(
          response.data.data.vith_ayog_data[1].total_sanctioned_works
        );
        setUbCompleteWorks(response.data.data.vith_ayog_data[1].complete_works);
        setUbWorksProgress(
          response.data.data.vith_ayog_data[1].works_in_progress
        );
        setUbUnfinishedWorks(
          response.data.data.vith_ayog_data[1].unfinished_works
        );
        setUbUnspentFunds(response.data.data.vith_ayog_data[1].unspent_funds);
        setUbPercentageCost(
          response.data.data.vith_ayog_data[1].percentage_of_cost
        );
        setUbSign(response.data.data.vith_ayog_data[1].shera);
      } else {
        setTuAdministrativeExpences(0);
        setTuNinetyCost(0);
        setTuTotalSanctionedWorks(0);
        setTuCompleteWorks(0);
        setTuWorksProgress(0);
        setTuUnfinishedWorks(0);
        setTuUnspentFunds(0);
        setTuPercentageCost(0);
        setTuSign("");
        setYear(0);
        setUbAdministrativeExpences(0);
        setUbNinetyCost(0);
        setUbTotalSanctionedWorks(0);
        setUbCompleteWorks(0);
        setUbWorksProgress(0);
        setUbUnfinishedWorks(0);
        setUbUnspentFunds(0);
        setUbPercentageCost(0);
        setUbSign("");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleFifteenVthDetails();
  }, [month]);

  const handleDataRefresh = async () => {
    await handleFifteenVthDetails();
  };

  // Refs for input elements
  const tuAdministrativeExpencesRef = useRef(null);
  const tuNinetyCostRef = useRef(null);
  const tuTotalSanctionedWorksRef = useRef(null);
  const tuCompleteWorksRef = useRef(null);
  const tuWorksProgressRef = useRef(null);
  const tuUnfinishedWorksRef = useRef(null);
  const tuUnspentFundsRef = useRef(null);
  const tuPercentageCostRef = useRef(null);
  const tuSignRef = useRef(null);

  const ubAdministrativeExpencesRef = useRef(null);
  const ubNinetyCostRef = useRef(null);
  const ubTotalSanctionedWorksRef = useRef(null);
  const ubCompleteWorksRef = useRef(null);
  const ubWorksProgressRef = useRef(null);
  const ubUnfinishedWorksRef = useRef(null);
  const ubUnspentFundsRef = useRef(null);
  const ubPercentageCostRef = useRef(null);
  const ubSignRef = useRef(null);

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(tuAdministrativeExpences)) {
        tuAdministrativeExpencesRef.current.focus();
      } else if (!isNumeric(tuNinetyCost)) {
        tuNinetyCostRef.current.focus();
      } else if (!isNumberOnly(tuTotalSanctionedWorks)) {
        tuTotalSanctionedWorksRef.current.focus();
      } else if (!isNumberOnly(tuCompleteWorks)) {
        tuCompleteWorksRef.current.focus();
      } else if (!isNumberOnly(tuWorksProgress)) {
        tuWorksProgressRef.current.focus();
      } else if (!isNumberOnly(tuUnfinishedWorks)) {
        tuUnfinishedWorksRef.current.focus();
      } else if (!isNumeric(tuUnspentFunds)) {
        tuUnspentFundsRef.current.focus();
      } else if (!isNumeric(tuPercentageCost)) {
        tuPercentageCostRef.current.focus();
      } else if (!isText(tuSign)) {
        tuSignRef.current.focus();
      } else if (!isNumeric(ubAdministrativeExpences)) {
        ubAdministrativeExpencesRef.current.focus();
      } else if (!isNumeric(ubNinetyCost)) {
        ubNinetyCostRef.current.focus();
      } else if (!isNumberOnly(ubTotalSanctionedWorks)) {
        ubTotalSanctionedWorksRef.current.focus();
      } else if (!isNumberOnly(ubCompleteWorks)) {
        ubCompleteWorksRef.current.focus();
      } else if (!isNumberOnly(ubWorksProgress)) {
        ubWorksProgressRef.current.focus();
      } else if (!isNumberOnly(ubUnfinishedWorks)) {
        ubUnfinishedWorksRef.current.focus();
      } else if (!isNumeric(ubUnspentFunds)) {
        ubUnspentFundsRef.current.focus();
      } else if (!isNumeric(ubPercentageCost)) {
        ubPercentageCostRef.current.focus();
      } else if (!isText(ubSign)) {
        ubSignRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  const isNumberOnly = (value) => {
    return /^-?\d+$/.test(value);
  };

  const isText = (value) => {
    return /^[\u0900-\u097Fa-zA-Z\s]+$/.test(value);
  };


  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(tuAdministrativeExpences) ||
      !isNumeric(tuNinetyCost) ||
      !isNumberOnly(tuTotalSanctionedWorks) ||
      !isNumberOnly(tuCompleteWorks) ||
      !isNumberOnly(tuWorksProgress) ||
      !isNumberOnly(tuUnfinishedWorks) ||
      !isNumeric(tuUnspentFunds) ||
      !isText(tuSign) ||
      !isNumeric(ubAdministrativeExpences) ||
      !isNumeric(ubNinetyCost) ||
      !isNumberOnly(ubTotalSanctionedWorks) ||
      !isNumberOnly(ubCompleteWorks) ||
      !isNumberOnly(ubWorksProgress) ||
      !isNumberOnly(ubUnfinishedWorks) ||
      !isNumeric(ubUnspentFunds) ||
      !isText(ubSign)
    ){
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };
  

  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const isValid = validateForm();
    if (isValid) {
      if(validated){
        await handleFifteenVitUpdate(e);
      }
    }else{
      handleShow();
    }
  }
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };

  // १५ वा वित्त आयोग मासिक प्रगती अहवाल  Update Information api

  const handleFifteenVitUpdate = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
   
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year: year,
        month: month,
        vith_ayog_data: [
          {
            funds_received: "Tied Up",
            ten_administrative_expenses: tuAdministrativeExpences,
            ninety_cost: tuNinetyCost,
            total_sanctioned_works: tuTotalSanctionedWorks,
            complete_works: tuCompleteWorks,
            works_in_progress: tuWorksProgress,
            unfinished_works: tuUnfinishedWorks,
            unspent_funds: tuUnspentFunds,
            percentage_of_cost: tuPercentageCost,
            shera: tuSign,
          },
          {
            funds_received: "Unbound",
            ten_administrative_expenses: ubAdministrativeExpences,
            ninety_cost: ubNinetyCost,
            total_sanctioned_works: ubTotalSanctionedWorks,
            complete_works: ubCompleteWorks,
            works_in_progress: ubWorksProgress,
            unfinished_works: ubUnfinishedWorks,
            unspent_funds: ubUnspentFunds,
            percentage_of_cost: ubPercentageCost,
            shera: ubSign,
          },
        ],
      };
      console.log("updateFifteenVit finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_vith_ayog",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateFifteenVit response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false); // Reset validation states
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // १५ वा वित्त आयोग मासिक प्रगती अहवाल  Update Information api

  const handleFifteenVitDraft = async (e) => {
    e.preventDefault();

    const invalidFormats =
      (!isNumeric(tuAdministrativeExpences) &&
        tuAdministrativeExpences !== "") ||
      (!isNumeric(tuNinetyCost) && tuNinetyCost !== "") ||
      (!isNumberOnly(tuTotalSanctionedWorks) &&
        tuTotalSanctionedWorks !== "") ||
      (!isNumberOnly(tuCompleteWorks) && tuCompleteWorks !== "") ||
      (!isNumberOnly(tuWorksProgress) && tuWorksProgress !== "") ||
      (!isNumberOnly(tuUnfinishedWorks) && tuUnfinishedWorks !== "") ||
      (!isNumeric(tuUnspentFunds) && tuUnspentFunds !== "") ||
      // (!isNumeric(tuPercentageCost) && tuPercentageCost !== "") ||
      (!isText(tuSign) && tuSign !== "") ||
      (!isNumeric(ubAdministrativeExpences) &&
        ubAdministrativeExpences !== "") ||
      (!isNumeric(ubNinetyCost) && ubNinetyCost !== "") ||
      (!isNumberOnly(ubTotalSanctionedWorks) &&
        ubTotalSanctionedWorks !== "") ||
      (!isNumberOnly(ubCompleteWorks) && ubCompleteWorks !== "") ||
      (!isNumberOnly(ubWorksProgress) && ubWorksProgress !== "") ||
      (!isNumberOnly(ubUnfinishedWorks) && ubUnfinishedWorks !== "") ||
      (!isNumeric(ubUnspentFunds) && ubUnspentFunds !== "") ||
      // (!isNumeric(ubPercentageCost) && ubPercentageCost !== "") ||
      (!isText(ubSign) && ubSign !== "");

    if (invalidFormats) {
      setValidated(true);
      return;
    }

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year: year,
        month: month,
        vith_ayog_data: [
          {
            funds_received: "Tied Up",
            ten_administrative_expenses: tuAdministrativeExpences,
            ninety_cost: tuNinetyCost,
            total_sanctioned_works: tuTotalSanctionedWorks,
            complete_works: tuCompleteWorks,
            works_in_progress: tuWorksProgress,
            unfinished_works: tuUnfinishedWorks,
            unspent_funds: tuUnspentFunds,
            percentage_of_cost: tuPercentageCost,
            shera: tuSign,
          },
          {
            funds_received: "Unbound",
            ten_administrative_expenses: ubAdministrativeExpences,
            ninety_cost: ubNinetyCost,
            total_sanctioned_works: ubTotalSanctionedWorks,
            complete_works: ubCompleteWorks,
            works_in_progress: ubWorksProgress,
            unfinished_works: ubUnfinishedWorks,
            unspent_funds: ubUnspentFunds,
            percentage_of_cost: ubPercentageCost,
            shera: ubSign,
          },
        ],
      };
      console.log("DraftFifteenVit finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_vith_ayog",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftFifteenVit response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false); // Reset validation state
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>१५ वा वित्त आयोग</title>
        </Helmet>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container common-section">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive mb-3 mt-5">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center fs-6">
                          १५ वा वित्त आयोग मासिक प्रगती अहवाल{" "}
                          {economicData === "Y" ? (
                            <span
                              className="drafted_text"
                              style={{
                                marginLeft: "1rem",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "#dc3545",
                              }}
                            >
                              <FaEdit style={{ marginTop: "-3px" }} /> Drafted
                            </span>
                          ) : economicData === "N" ? (
                            <span
                              className="submit_text"
                              style={{
                                marginLeft: "1rem",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "#2bc63a",
                              }}
                            >
                              Submitted{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={27}
                                style={{ marginTop: "-3px" }}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="d-flex justify-content-around">
                          <label className="fifteen_vith_label">
                            ग्रामपंचयात:{" "}
                            {villageDataInfo === undefined ? (
                              <span></span>
                            ) : (
                              <span className="fw-bold">
                                {" "}
                                {villageDataInfo.village_name}
                              </span>
                            )}
                          </label>
                          <label className="fifteen_vith_label">
                            तालुका:{" "}
                            {villageDataInfo === undefined ? (
                              <span></span>
                            ) : (
                              <span className="fw-bold">
                                {" "}
                                {villageDataInfo.taluka_name}
                              </span>
                            )}
                          </label>
                          <label className="fifteen_vith_label">
                            जिल्हा:{" "}
                            {villageDataInfo === undefined ? (
                              <span></span>
                            ) : (
                              <span className="fw-bold">
                                {" "}
                                {villageDataInfo.district_name}
                              </span>
                            )}
                          </label>
                          <label className="fifteen_vith_label">
                            माहे:{" "}
                            {month === undefined ? (
                              <span></span>
                            ) : (
                              <select
                                name="month"
                                value={month}
                                onChange={(e) => setMonth(e.target.value)}
                                className="fw-bold"
                              >
                                {/* <option value="" className="fw-bold">
                                  महिना निवडा
                                </option> */}
                                <option value="1">जानेवारी</option>
                                <option value="2">फेब्रुवारी</option>
                                <option value="3">मार्च</option>
                                <option value="4">एप्रिल</option>
                                <option value="5">मे</option>
                                <option value="6">जून</option>
                                <option value="7">जुलै</option>
                                <option value="8">ऑगस्ट</option>
                                <option value="9">सप्टेंबर</option>
                                <option value="10">ऑक्टोंबर</option>
                                <option value="11">नोव्हेंबर</option>
                                <option value="12">डिसेंबर</option>
                              </select>
                            )}
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="commont_th" scope="col">अ.क्र.</th>
                      <th scope="col" className="common_work">वर्ष</th>
                      <th scope="col" className="common_work">प्राप्त निधी</th>
                      <th scope="col">१० % प्रशासकीय खर्च</th>
                      <th scope="col">९० % खर्च</th>
                      <th scope="col" className="common_work">
                        एकूण मंजूर कामे (उपलब्ध निधीच्या प्रमाणात )
                      </th>
                      <th scope="col" className="common_work"> पूर्ण कामे </th>
                      <th scope="col" className="common_work"> प्रगतीत कामे </th>
                      <th scope="col" className="common_work"> अपूर्ण कामे </th>
                      <th scope="col"> अखर्चित निधी </th>
                      <th scope="col" className="common_worked"> खर्चाची टक्केवारी </th>
                      <th scope="col">शेरा </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan={3}>
                        <input
                          type="number"
                          className="form-control table-input-box"
                          value={1}
                          readOnly
                        />
                      </td>
                      <td rowSpan={3}>
                        <input
                          type="text"
                          value={yearInfo.current_year_name}
                          readOnly
                          className="form-control table-input-box width_grma"
                        />
                      </td>
                      <td>
                        <label htmlFor="">बंधीत </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuAdministrativeExpences}
                          onChange={(e) => {
                            setTuAdministrativeExpences(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(tuAdministrativeExpences)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(tuAdministrativeExpences)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuAdministrativeExpencesRef}
                          autoFocus
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuNinetyCost}
                          onChange={(e) => {
                            setTuNinetyCost(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(tuNinetyCost)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(tuNinetyCost)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuNinetyCostRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuTotalSanctionedWorks}
                          onChange={(e) => {
                            setTuTotalSanctionedWorks(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(tuTotalSanctionedWorks)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(tuTotalSanctionedWorks)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuTotalSanctionedWorksRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuCompleteWorks}
                          onChange={(e) => {
                            setTuCompleteWorks(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(tuCompleteWorks)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(tuCompleteWorks)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuCompleteWorksRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuWorksProgress}
                          onChange={(e) => {
                            setTuWorksProgress(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(tuWorksProgress)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(tuWorksProgress)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuWorksProgressRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuUnfinishedWorks}
                          onChange={(e) => {
                            setTuUnfinishedWorks(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(tuUnfinishedWorks)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(tuUnfinishedWorks)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuUnfinishedWorksRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuUnspentFunds}
                          onChange={(e) => {
                            setTuUnspentFunds(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(tuUnspentFunds)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(tuUnspentFunds)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuUnspentFundsRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuPercentageCost || 0}
                          onChange={(e) => {
                            setTuPercentageCost(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(tuPercentageCost)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(tuPercentageCost)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={tuPercentageCostRef}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tuSign}
                          onChange={(e) => {
                            setTuSign(e.target.value);
                          }}
                          placeholder={
                            validated && !isText(tuSign)
                              ? "कृपया वैध माहिती प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isText(tuSign) ? "is-invalid" : ""
                          }`}
                          ref={tuSignRef}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">अबंधित </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubAdministrativeExpences}
                          onChange={(e) => {
                            setUbAdministrativeExpences(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(ubAdministrativeExpences)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(ubAdministrativeExpences)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubAdministrativeExpencesRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubNinetyCost}
                          onChange={(e) => {
                            setUbNinetyCost(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(ubNinetyCost)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(ubNinetyCost)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubNinetyCostRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubTotalSanctionedWorks}
                          onChange={(e) => {
                            setUbTotalSanctionedWorks(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(ubTotalSanctionedWorks)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(ubTotalSanctionedWorks)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubTotalSanctionedWorksRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubCompleteWorks}
                          onChange={(e) => {
                            setUbCompleteWorks(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(ubCompleteWorks)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(ubCompleteWorks)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubCompleteWorksRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubWorksProgress}
                          onChange={(e) => {
                            setUbWorksProgress(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(ubWorksProgress)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(ubWorksProgress)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubWorksProgressRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubUnfinishedWorks}
                          onChange={(e) => {
                            setUbUnfinishedWorks(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumberOnly(ubUnfinishedWorks)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumberOnly(ubUnfinishedWorks)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubUnfinishedWorksRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubUnspentFunds}
                          onChange={(e) => {
                            setUbUnspentFunds(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(ubUnspentFunds)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(ubUnspentFunds)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubUnspentFundsRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubPercentageCost || 0}
                          onChange={(e) => {
                            setUbPercentageCost(e.target.value);
                          }}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          placeholder={
                            validated && !isNumeric(ubPercentageCost)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(ubPercentageCost)
                              ? "is-invalid"
                              : ""
                          }`}
                          ref={ubPercentageCostRef}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={ubSign}
                          onChange={(e) => {
                            setUbSign(e.target.value);
                          }}
                          placeholder={
                            validated && !isText(ubSign)
                              ? "कृपया वैध माहिती प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isText(ubSign) ? "is-invalid" : ""
                          }`}
                          ref={ubSignRef}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="">एकूण </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={totalAdministrativeExpences}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalNinetyCost}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalTotalSanctionedWorks}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalCompleteWorks}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalWorksProgress}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalUnfinishedWorks}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalUnspentFunds}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={totalPercentageCost || 0}
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          className="form-control table-input-box"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <label className="fw-bold">एकूण एकंदर </label>
                      </td>
                      <td>
                        <input
                          type="number"
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={totalAdministrativeExpences}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalNinetyCost}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalTotalSanctionedWorks}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalCompleteWorks}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalWorksProgress}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalUnfinishedWorks}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={totalUnspentFunds}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={totalPercentageCost || 0}
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          className="form-control fw-bold table-input-box"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>

              {(month === currentMonth.toString() && !isLoading) &&
                (economicData === "Y" || economicData === "") && (
                  <div className="col-lg-12 d-flex justify-content-end align-items=center">
                    <FormBtn
                      handleSubmit={handleFormSubmission}
                      resetForm={handleDataRefresh}
                      handleDraft={handleFifteenVitDraft}
                    />
                  </div>
                )}
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleFifteenVitUpdate}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <ToastContainer
              position={"top-right"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

export default Fifteen_Vith;
