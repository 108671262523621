import React from "react";

const Village_Tal_Dist_Mahe = ({ villageDataInfo, month }) => {
  
  return (
    <div className="page-one-second-sec d-flex gap-5">
      <div className="VillagePanchayat me-2 d-flex justify-content-center align-items-center">
        <label htmlFor="">ग्रामपंचायत : </label>
        {villageDataInfo === undefined ? (
          <span></span>
        ) : (
          <span className="fw-bold"> {villageDataInfo.village_name}</span>
        )}
      </div>
      <div className="VillagePanchayat me-2 d-flex justify-content-center align-items-center">
        <label htmlFor="">तालुका : </label>
        {villageDataInfo === undefined ? (
          <span></span>
        ) : (
          <span className="fw-bold"> {villageDataInfo.taluka_name}</span>
        )}
      </div>
      <div className="VillagePanchayat d-flex justify-content-center align-items-center">
        <label htmlFor="">जिल्हा : </label>
        {villageDataInfo === undefined ? (
          <span></span>
        ) : (
          <span className="fw-bold"> {villageDataInfo.district_name}</span>
        )}
      </div>
      {/* <div className="VillagePanchayat d-flex justify-content-center align-items-center">
        <label htmlFor="">माहे :</label>
        {villageDataInfo === undefined ? (
          <span></span>
        ) : (
          <span className=""> {month}</span>
        )}
      </div> */}
    </div>
  );
};

export default Village_Tal_Dist_Mahe;
