/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
// import "./EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import Loader from "../../component/Loader/Loader";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const ExpenseProvision_C = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [validated, setValidated] = useState(false);

  const getMonthName = (monthNumber) => {
    const months = [
      "जानेवारी",
      "फेब्रुवारी",
      "मार्च",
      "एप्रिल",
      "मे",
      "जून",
      "जुलै",
      "ऑगस्ट",
      "सप्टेंबर",
      "ऑक्टोंबर",
      "नोव्हेंबर",
      "डिसेंबर",
    ];
    return months[monthNumber - 1];
  };

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  // ५% अपंग खर्चाची तरतूद  input filed calculation

  const [backLog, setBackLog] = useState("");
  const [currentProvision, setCurrentProvision] = useState("");
  const [totalProvision, setTotalProvision] = useState("");
  const [expenses, setExpenses] = useState("");
  const [expenseCData, setExpenseCData] = useState([]);
  const [matter, setMatter] = useState("");
  const [isAlertVisible, setAlertVisible] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const CalculateTotalProvision = () => {
    const num1 = parseFloat(backLog) || 0;
    const num2 = parseFloat(currentProvision) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalProvision("");
    } else {
      const sum = num1 + num2;
      setTotalProvision(Math.round(sum));
    }
    // const sum = parseInt(backLog) + parseInt(currentProvision);
    // setTotalProvision(sum);
  };

  useEffect(() => {
    CalculateTotalProvision();
  }, [backLog, currentProvision]);

  // खर्च तरतूद ( ५% अपंग खर्चाची तरतूद) Get Information api

  const handleEconomicsYearDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month,
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/expense_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("ExpenseProvision_C_Details response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setYearInfo(response.data.data.year_info);

      if (
        response.data.data.economic_e_data === null ||
        response.data.data.economic_e_data.length === 0
      ) {
        setAlertVisible(true);
        setIsLoading(false);
      }

      // updated date show in inputs
      setBackLog(response.data.data.economic_e_data[0].backlog);
      setCurrentProvision(
        response.data.data.economic_e_data[0].current_provision
      );
      setTotalProvision(response.data.data.economic_e_data[0].total_provision);

      setExpenseCData(response.data.data.expense_c_data);

      // draft condition
      if (
        response.data.data.expense_c_data === null ||
        response.data.data.expense_c_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.expense_c_data[0].is_draft);
      }

      //current month recovery
      if (
        response.data.data.expense_c_data !== null &&
        response.data.data.expense_c_data.length > 0
      ) {
        // updated date show in inputs
        setExpenses(response.data.data.expense_c_data[0].expenses);
        setMatter(response.data.data.expense_c_data[0].matter);
      } else {
        // updated date show in inputs
        setExpenses(0);
        setMatter("");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleEconomicsYearDetails();
  }, [month]);

  const handleDataRefresh = async () => {
    await handleEconomicsYearDetails();
  };

  const handleDataReset = async () => {
    if (expenseCData.length > 0) {
      await handleEconomicsYearDetails();
      setValidated(false);
    } else {
      setBackLog("");
      setCurrentProvision("");
      setTotalProvision("");
      setExpenses("");
      setMatter("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const backLogRef = useRef(null);
  const currentProvisionRef = useRef(null);
  const expensesRef = useRef(null);
  const matterRef = useRef(null);

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(backLog)) {
        backLogRef.current.focus();
      } else if (!isNumeric(currentProvision)) {
        currentProvisionRef.current.focus();
      } else if (!isNumeric(expenses)) {
        expensesRef.current.focus();
      } else if (!isText(matter)) {
        matterRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  const isText = (value) => {
    return /^[\u0900-\u097Fa-zA-Z\s]+$/.test(value);
  };

  // before submit check validation of
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(backLog) ||
      !isNumeric(currentProvision) ||
      !isNumeric(expenses) ||
      !isText(matter)
    ) {
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      if (validated) {
        await handleExpenseProvisionDetails(e);
      }
    } else {
      handleShow();
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  // खर्च तरतूद (५% अपंग खर्चाची तरतूद)  Update Information api

  const handleExpenseProvisionDetails = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        expense_c_data: [
          {
            type: "1",
            expenses: expenses,
            matter: matter,
          },
        ],
      };
      console.log("updateExpenseProvision_C finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_expense_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateExpenseProvision_C response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // खर्च तरतूद (५% अपंग खर्चाची तरतूद)  Draft Information api

  const handleDraftExpenseProvision = async (e) => {
    e.preventDefault();
    const invalidFormats =
      (!isNumeric(backLog) && backLog !== "") ||
      (!isNumeric(currentProvision) && currentProvision !== "") ||
      (!isNumeric(expenses) && expenses !== "") ||
      (!isText(matter) && matter !== "");

    if (invalidFormats) {
      setValidated(true);
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        expense_c_data: [
          {
            type: "1",
            expenses: expenses,
            matter: matter,
          },
        ],
      };
      console.log("DraftExpenseProvision_C finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_expense_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftExpenseProvision_C response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false); // Reset validation state
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  return (
    <>
      <Helmet>
        <title>अपंग खर्चाची तरतूद</title>
      </Helmet>
      <>
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="container pt-0 common-section">
              <div className="row">
                <div className="col-lg-12 navigate-section-header mt-3">
                  <div className="page-title">
                    <p className="fw-bold mb-2">१.३ खर्च तरतूद</p>
                  </div>
                  <div className="navigate-section d-flex">
                    <div className="arrow-box">
                      <Link to="/expense-pro-a" className="me-5 text-dark">
                        अ) १५% मागासवर्गीय खर्चाची तरतूद
                      </Link>
                    </div>
                    <div className="arrow-box">
                      <Link to="/expense-pro-b" className="me-5 text-dark">
                        ब) १0% महिला व बालकल्याण खर्चाची तरतूद
                      </Link>
                    </div>
                    <div className="arrow-box-active me-5 d-flex flex-column justify-content-center">
                      <Link
                        to="/expense-pro-c"
                        className="text-decoration-none"
                      >
                        क) ५% अपंग खर्चाची तरतूद
                      </Link>
                      <span className="text-center arrow-icon">
                        <ArrowDropDownIcon fontSize="large" color="primary" />
                      </span>
                    </div>
                  </div>
                  {/* <TabEco/> */}
                </div>
                {!isAlertVisible ? (
                  <>
                    <div className="col-lg-12 mt-5 mb-4">
                      <div className="d-flex gap-5">
                        <Village_Tal_Dist_Mahe
                          villageDataInfo={villageDataInfo}
                        />
                        <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                          <label htmlFor="">माहे :</label>
                          {month === undefined ? (
                          <span></span>
                        ) : (
                          <select
                            name="month"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            className="fw-bold"
                          >
                            <option value="1">जानेवारी</option>
                            <option value="2">फेब्रुवारी</option>
                            <option value="3">मार्च</option>
                            <option value="4">एप्रिल</option>
                            <option value="5">मे</option>
                            <option value="6">जून</option>
                            <option value="7">जुलै</option>
                            <option value="8">ऑगस्ट</option>
                            <option value="9">सप्टेंबर</option>
                            <option value="10">ऑक्टोंबर</option>
                            <option value="11">नोव्हेंबर</option>
                            <option value="12">डिसेंबर</option>
                          </select>
                        )}
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-main-sec">
                        <div className="table_title_top">
                          <p className="gap-3 d-flex">क) ५% अपंग खर्चाची तरतूद 

                          {economicData === "Y" ? (
                            <span className="drafted_text">
                              <FaEdit /> Drafted
                            </span>
                          ) : economicData === "N" ? (
                            <span className="submit_text">
                              Submitted{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={30}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                          </p>
                        </div>

                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: "5rem" }}>
                                  अ.क्र.
                                </th>
                                <th scope="col">मागील अनुशेष</th>
                                <th scope="col">
                                  चालू तरतूद <br /> (सन २०२२-२०२३)
                                </th>
                                <th scope="col">
                                  एकूण तरतूद <br />
                                  (मागील + चालू) (सन २०२२-२०२३)
                                </th>
                                <th scope="col">खर्च</th>
                                <th scope="col">बाब</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    type="number"
                                    value={1}
                                    disabled
                                    className="form-control table-input-box"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    style={{ background: "transparent" }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={backLog}
                                    onChange={(e) => setBackLog(e.target.value)}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(backLog)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(backLog)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={backLogRef}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={currentProvision}
                                    onChange={(e) =>
                                      setCurrentProvision(e.target.value)
                                    }
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(currentProvision)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(currentProvision)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={currentProvisionRef}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={totalProvision}
                                    readOnly
                                    className="form-control table-input-box fw-bold"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={expenses}
                                    onChange={(e) =>
                                      setExpenses(e.target.value)
                                    }
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(expenses)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(expenses)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={expensesRef}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={matter}
                                    onChange={(e) => setMatter(e.target.value)}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isText(matter)
                                        ? "कृपया वैध माहिती प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isText(matter)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={matterRef}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                      <FooterDevOfficer villageDataInfo={villageDataInfo} />
                    </div>
                    {(month === currentMonth.toString() && !isLoading) &&
                      (economicData === "" || economicData === "Y") && (
                        <div className="col-lg-12 d-flex justify-content-end align-items-center">
                          <FormBtn
                            handleSubmit={handleFormSubmission}
                            handleDraft={handleDraftExpenseProvision}
                            resetForm={handleDataReset}
                          />
                        </div>
                      )}
                    {(economicData === "N" || economicData !== "") && (
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p className="text-danger">
                            सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                            विनंती करा.{" "}
                          </p>
                          <div>
                            <Button variant="contained">विनंती करा</Button>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {" "}
                          सूचना <MdOutlineArrowDownward />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                        Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म
                        Submit करू इच्छिता?
                      </Modal.Body>
                      <Modal.Footer className="gap-3">
                        <Button
                          className="modal-button-cancel"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="modal-button-submit"
                          onClick={handleExpenseProvisionDetails}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : (
                  <section
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <div className="alert_pop_up_common">
                            <h4 className="alert_pop_up_common_title">
                              आर्थिक वर्ष मागणी
                            </h4>
                            <p className="alert_pop_up_common_des">
                              {" "}
                              कृपया आर्थिक वर्ष मागणी मधील ५% मागासवर्गीय
                              खर्चाची तरतूद भरा
                            </p>
                            <Link className="alert_submit_btn" to="/eco-e">
                              आता पूर्ण करा
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
              <ToastContainer
                position={"top-right"}
                autoClose={2000}
                pauseOnHover={false}
                closeOnClick={true}
              />
            </div>
          )}
        </>
      </>
    </>
  );
};

export default ExpenseProvision_C;
