/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./RecoveryReport.css";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { FaEdit } from "react-icons/fa";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import Loader from "../../component/Loader/Loader";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const RecoveryReport_D = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState([]);
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [validationErrors, setValidationErrors] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [total, setTotal] = useState("");
  const [percentage, setPercentage] = useState("");
  const [rows, setRows] = useState([]);
  const [isAlertVisible, setAlertVisible] = useState(false);

  //  वसुली विभाग (घरपट्टी वसुली) Get Information api

  const handleRecoveryReportDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month,
      };
      console.log("Recovery Report finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/recovery_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      console.log("RecoveryReport_D_Details response", response.data.data);
      if (
        response.data.data.economic_d_data === null ||
        response.data.data.economic_d_data.length === 0
      ) {
        setAlertVisible(true);
        setIsLoading(false);
      }
      var finalRowsData = [];
      if (
        response.data.status === true &&
        response.data.data !== null &&
        response.data.data.economic_d_data !== null &&
        response.data.data.economic_d_data.length > 0
      ) {
        for (var i = 0; i < response.data.data.economic_d_data.length; i++) {
          let no_of_windmill =
            response.data.data.economic_d_data[i].no_of_windmill;
          let past_due = response.data.data.economic_d_data[i].past_due;
          let current_demand =
            response.data.data.economic_d_data[i].current_demand;
          let total_demand = response.data.data.economic_d_data[i].total_demand;
          let windmill_company_name =
            response.data.data.economic_d_data[i].windmill_company_name;
          let windmill_capacity =
            response.data.data.economic_d_data[i].windmill_capacity;
          let last_month_recovery =
            response.data.data.last_month_total[i].last_month_recovery;
          let current_month_recovery = 0;
          if (
            response.data.data.recovery_d_data !== null &&
            response.data.data.recovery_d_data.length > 0
          ) {
            current_month_recovery =
              response.data.data.recovery_d_data[i].current_month_recovery;
          }
          var rowData = {
            windmill_company_name: windmill_company_name,
            no_of_windmill: no_of_windmill,
            past_due: past_due,
            current_demand: current_demand,
            total_demand: total_demand,
            windmill_capacity: windmill_capacity,
            last_month_recovery: last_month_recovery.toString(),
            current_month_recovery: current_month_recovery,
          };

          finalRowsData.push(rowData);
          setRows(finalRowsData);
        }
      }
      setIsLoading(false);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);

      if (response.data.status === true) {
        if (
          response.data.data.recovery_d_data === null ||
          response.data.data.recovery_d_data.length === 0
        ) {
          setEconomicData("");
        } else {
          setEconomicData(response.data.data.recovery_d_data[0].is_draft);
        }
      }
      setData(response.data.data.recovery_d_data);
      setMonth(response.data.data.month);
      // updated date show in inputs
      setTotal(response.data.data.recovery_d_data[0].total_recovery);
      setPercentage(response.data.data.recovery_d_data[0].percentage);

      console.log("finalRowsData", response.data.data.recovery_d_data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleRecoveryReportDetails();
  }, [month]);

  const handleDataRefresh = async () => {
    await handleRecoveryReportDetails();
  };

  // reset functionality of the using condition
  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleRecoveryReportDetails();
    } else {
      setRows("");
      setValidationErrors("");
    }
  };

  const validateRow = (row) => {
    const errors = {};

    if (
      !row.windmill_company_name ||
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.windmill_company_name)
    ) {
      errors.windmill_company_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (!row.no_of_windmill || !/^-?\d*\.?\d+$/.test(row.no_of_windmill)) {
      errors.no_of_windmill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (
      !row.windmill_capacity ||
      !/^-?\d*\.?\d+$/.test(row.windmill_capacity)
    ) {
      errors.windmill_capacity = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (!row.past_due || !/^-?\d*\.?\d+$/.test(row.past_due)) {
      errors.past_due = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (!row.current_demand || !/^-?\d*\.?\d+$/.test(row.current_demand)) {
      errors.current_demand = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (!row.total_demand || !/^-?\d*\.?\d+$/.test(row.total_demand)) {
      errors.total_demand = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (
      !row.last_month_recovery ||
      !/^-?\d*\.?\d+$/.test(row.last_month_recovery)
    ) {
      errors.last_month_recovery = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (
      !row.current_month_recovery ||
      !/^-?\d*\.?\d+$/.test(row.current_month_recovery)
    ) {
      errors.current_month_recovery = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    return errors;
  };

  // draft validation
  const validateRow1 = (row) => {
    const errors = {};
    if (
      row.windmill_company_name !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.windmill_company_name)
    ) {
      errors.windmill_company_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (row.no_of_windmill && !/^-?\d*\.?\d+$/.test(row.no_of_windmill)) {
      errors.no_of_windmill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (row.windmill_capacity && !/^-?\d*\.?\d+$/.test(row.windmill_capacity)) {
      errors.windmill_capacity = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (row.past_due && !/^-?\d*\.?\d+$/.test(row.past_due)) {
      errors.past_due = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (row.current_demand && !/^-?\d*\.?\d+$/.test(row.current_demand)) {
      errors.current_demand = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (row.total_demand && !/^-?\d*\.?\d+$/.test(row.total_demand)) {
      errors.total_demand = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      row.last_month_recovery &&
      !/^-?\d*\.?\d+$/.test(row.last_month_recovery)
    ) {
      errors.last_month_recovery = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      row.current_month_recovery &&
      !/^-?\d*\.?\d+$/.test(row.current_month_recovery)
    ) {
      errors.current_month_recovery = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    return errors;
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);
    console.log("updatedRows", updatedRows);

    const errors = validateRow(updatedRows[index]);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;
    setValidationErrors(updatedValidationErrors);
  };

  // before submission check to validation

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const hasErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        setValidationErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[index] = errors;
          return updatedErrors;
        });
      }
      return Object.keys(errors).length > 0;
    });

    // Check if the sum is greater than total demand
    const hasSumGreaterThanTotalDemand = rows.some(
      (item) =>
        parseFloat(item.last_month_recovery) +
          parseFloat(item.current_month_recovery) >
        parseFloat(item.total_demand)
    );

    if (hasErrors || hasSumGreaterThanTotalDemand) {
      if (hasSumGreaterThanTotalDemand) {
        alert(
          "सूचना :- कृपया एकूण वसूली ही एकूण मागणी पेक्षा कमी किवा समान असावी."
        );
        return true;
      }
      await handleUpdateRecoveryReport(e);
    } else {
      handleShow();
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  //  वसुली विभाग (पवनचक्की वसुली/कारखाना वसुली) Update Information api

  const handleUpdateRecoveryReport = async (e) => {
    e.preventDefault();
    const hasSumGreaterThanTotalDemand = rows.some(
      (item, index) =>
        parseFloat(item.last_month_recovery) +
          parseFloat(item.current_month_recovery) >
        parseFloat(item.total_demand)
    );
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow(row);

      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }

      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      scrollToTop();
      return;
    } else if (hasSumGreaterThanTotalDemand) {
      alert(
        "सूचना :- कृपया एकूण वसूली ही एकूण मागणी पेक्षा कमी किवा समान असावी."
      );
      return;
    }
    handleClose();

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        recovery_d_data: rows.map((item) => ({
          windmill_company_name: item.windmill_company_name || "",
          last_month_recovery: item.last_month_recovery || 0,
          current_month_recovery: item.current_month_recovery || "",
          total_recovery:
            parseFloat(item.last_month_recovery) +
              parseFloat(item.current_month_recovery) || "",
          percentage: parseFloat(
            item.last_month_recovery +
              (parseFloat(item.current_month_recovery) / item.total_demand) *
                100
          ).toFixed(2),
        })),
      };
      console.log("updateRecoveryReport_D finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_recovery_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateRecoveryReportDetails_D response", response);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // वसुली विभाग (पवनचक्की वसुली/कारखाना वसुली) Draft Information api

  const handleDraftRecoveryReport = async (e) => {
    e.preventDefault();

    // Check for validation errors
    const validationErrors = rows.map((item) => validateRow1(item));

    // Check if any row has a sum greater than total demand
    const hasSumGreaterThanTotalDemand = rows.some(
      (item, index) =>
        parseFloat(item.last_month_recovery) +
          parseFloat(item.current_month_recovery) >
        parseFloat(item.total_demand)
    );

    // Check if any row has validation errors
    const hasValidationErrors = validationErrors.some(
      (errors) => Object.keys(errors).length > 0
    );

    if (hasValidationErrors) {
      // There are errors, do not submit
      return;
    } else if (hasSumGreaterThanTotalDemand) {
      alert(
        "सूचना :- कृपया एकूण वसूली ही एकूण मागणी पेक्षा कमी किवा समान असावी."
      );
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        recovery_d_data: rows.map((item) => ({
          windmill_company_name: item.windmill_company_name || "",
          last_month_recovery: item.last_month_recovery || "",
          current_month_recovery: item.current_month_recovery || "",
          total_recovery:
            parseFloat(item.last_month_recovery) +
              parseFloat(item.current_month_recovery) || "",
          percentage:
            parseFloat(
              item.last_month_recovery +
                (parseFloat(item.current_month_recovery) / item.total_demand) *
                  100
            ).toFixed(2) || "",
        })),
      };
      console.log("draftRecoveryReport_D finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_recovery_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draftRecoveryReportDetails_D response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // render table row
  const renderRows = () => {
    return rows.map((rowData, index) => (
      <tr key={rowData.id}>
        <td>
          <input
            type="number"
            value={index + 1}
            disable="true"
            className="form-control table-input-box"
            readOnly
            style={{ background: "transparent" }}
          />
        </td>
        <td>
          <input
            type="text"
            value={rowData.windmill_company_name || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.windmill_company_name
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.windmill_company_name ? "is-invalid" : ""
            }`}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            value={rowData.no_of_windmill || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.no_of_windmill
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.no_of_windmill ? "is-invalid" : ""
            }`}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            value={rowData.past_due || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.past_due
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.past_due ? "is-invalid" : ""
            }`}
            // ref={pasThakBakiRef}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            value={rowData.current_demand || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.current_demand
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.current_demand ? "is-invalid" : ""
            }`}
            readOnly
          />
        </td>
        <td>
          <input
            type="number"
            className="form-control table-input-box fw-bold"
            value={rowData.total_demand}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            value={rowData.last_month_recovery || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.last_month_recovery
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.last_month_recovery ? "is-invalid" : ""
            }`}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            name="current_month_recovery"
            value={rowData.current_month_recovery || 0}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.current_month_recovery
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.current_month_recovery
                ? "is-invalid"
                : ""
            }`}
          />
        </td>
        <td>
          <input
            type="number"
            className={`form-control table-input-box fw-bold ${
              validated && parseFloat(total) > parseFloat() ? "is-invalid " : ""
            }`}
            value={
              parseInt(rowData.last_month_recovery || 0) +
              parseInt(rowData.current_month_recovery || 0)
            }
            readOnly
          />
        </td>

        
        <td>
          <input
            type="number"
            className={`form-control table-input-box fw-bold`}
            value={(
              (parseFloat(rowData.last_month_recovery || 0) +
              (parseFloat(rowData.current_month_recovery || 0))) /
                (parseFloat(rowData.total_demand || 1)) *
                100
            ).toFixed(2) || 0}
            readOnly
          />
        </td>
      </tr>
    ));
  };

  // total calculation

  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Helmet>
              <title>वसुली रीपोर्ट || पवनचक्की वसुली/कारखाना वसुली </title>
            </Helmet>
            <div className="container common-section pt-0">
              <div className="row">
                <div className="col-lg-12 navigate-section-header mt-3">
                  <div className="page-title">
                    <p className="fw-bold mb-2">१.२ वसुली विभाग</p>
                  </div>
                  <div className="navigate-section d-flex">
                    <div className="arrow-box">
                      <Link className="me-5 text-dark" to="/recovery-a">
                        अ) घरपट्टी वसुली{" "}
                      </Link>
                    </div>
                    <div className="arrow-box">
                      <Link className="me-5 text-dark" to="/recovery-b">
                        ब) पाणीपट्टी (सामान्य व विशेष पाणीपट्टी)
                      </Link>
                    </div>
                    <div className="arrow-box">
                      <Link className="text-dark" to="/recovery-c">
                        क) किरकोळ मागणी (पवनचक्की सोडून)
                      </Link>
                    </div>
                    <div className="arrow-box-active d-flex flex-column justify-content-center ms-4 me-5">
                      <Link className="text-decoration-none" to="/recovery-d">
                        ड) पवनचक्की/कारखाना वसुली{" "}
                      </Link>
                      <span className="text-center arrow-icon">
                        <ArrowDropDownIcon fontSize="large" color="primary" />
                      </span>
                    </div>
                  </div>
                  {/* <TabEco/> */}
                </div>
                {!isAlertVisible ? (
                  <>
                    <div className="col-lg-12 mt-4 mb-4">
                      <div className="d-flex gap-5">
                        <Village_Tal_Dist_Mahe
                          villageDataInfo={villageDataInfo}
                        />
                        <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                          <label htmlFor="">माहे :</label>
                          {month === undefined ? (
                            <span></span>
                          ) : (
                            <select
                              name="month"
                              value={month}
                              onChange={(e) => setMonth(e.target.value)}
                              className="fw-bold"
                            >
                              <option value="1">जानेवारी</option>
                              <option value="2">फेब्रुवारी</option>
                              <option value="3">मार्च</option>
                              <option value="4">एप्रिल</option>
                              <option value="5">मे</option>
                              <option value="6">जून</option>
                              <option value="7">जुलै</option>
                              <option value="8">ऑगस्ट</option>
                              <option value="9">सप्टेंबर</option>
                              <option value="10">ऑक्टोंबर</option>
                              <option value="11">नोव्हेंबर</option>
                              <option value="12">डिसेंबर</option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-main-sec">
                        <div className="table_title_top">
                          <p className="">ड) पवनचक्की वसूली / कारखाना वसूली</p>
                          {economicData === "Y" ? (
                            <span className="drafted_text">
                              <FaEdit /> Drafted
                            </span>
                          ) : economicData === "N" ? (
                            <span className="submit_text">
                              Submit{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={30}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="table-responsive mb-3">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">अ.क्र.</th>
                                <th scope="col">पवनचक्की कंपनीचे नाव</th>
                                <th scope="col">
                                  पवनचक्की / कारखाना <br /> संख्या
                                </th>
                                <th scope="col">
                                  मागील थकबाकी <br /> (सन{" "}
                                  {yearInfo ? yearInfo.previous_year_name : ""})
                                </th>
                                <th scope="col">
                                  चालू मागणी <br /> (सन{" "}
                                  {yearInfo ? yearInfo.current_year_name : ""})
                                </th>
                                <th scope="col">
                                  एकूण मागणी <br />
                                  (मागील + चालू) (सन{" "}
                                  {yearInfo ? yearInfo.current_year_name : ""})
                                </th>
                                <th scope="col">
                                  {" "}
                                  मागील महिनाअखेर <br /> वसूली{" "}
                                </th>
                                <th scope="col">
                                  {" "}
                                  चालू महिन्यातील <br /> वसूली{" "}
                                </th>
                                <th scope="col"> एकूण वसूली </th>
                                <th scope="col"> टक्केवारी </th>
                              </tr>
                            </thead>
                            <tbody>{renderRows()}</tbody>
                          </table>
                          {/* {validated &&
                      (parseFloat(rowData.last_month_recovery + rowData.current_month_recovery) > parseFloat(rowData.total_demand) ? (
                        <span className="text-danger">
                          सूचना :- कृपया एकूण वसूली ही एकूण मागणी पेक्षा कमी
                          किवा समान असावी.
                        </span>
                      ) : (
                        ""
                      ))} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <FooterDevOfficer villageDataInfo={villageDataInfo} />
                    </div>
                    {month === currentMonth.toString() &&
                      !isLoading &&
                      (economicData === "Y" || economicData === "") && (
                        <div className="col-lg-12 d-flex justify-content-end align-items=center">
                          <FormBtn
                            handleSubmit={handleFormSubmission}
                            resetForm={handleDataRefreshReset}
                            handleDraft={handleDraftRecoveryReport}
                          />
                        </div>
                      )}
                    {economicData === "N" && (
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p className="text-danger">
                            सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                            विनंती करा.{" "}
                          </p>
                          <div>
                            <Button variant="contained">विनंती करा</Button>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {" "}
                          सूचना <MdOutlineArrowDownward />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                        Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म
                        Submit करू इच्छिता?
                      </Modal.Body>
                      <Modal.Footer className="gap-3">
                        <Button
                          className="modal-button-cancel"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="modal-button-submit"
                          onClick={handleUpdateRecoveryReport}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : (
                  <section
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <div className="alert_pop_up_common">
                            <h4 className="alert_pop_up_common_title">
                              आर्थिक वर्ष मागणी
                            </h4>
                            <p className="alert_pop_up_common_des">
                              {" "}
                              कृपया आर्थिक वर्ष करमागणी मधील पवनचक्की वसूली /
                              कारखाना वसूली भरा
                            </p>
                            <Link className="alert_submit_btn" to="/eco-d">
                              आता पूर्ण करा
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
              <ToastContainer
                position={"top-center"}
                autoClose={2000}
                pauseOnHover={false}
                closeOnClick={true}
              />
            </div>
          </>
        )}
      </>
    </React.Fragment>
  );
};

export default RecoveryReport_D;
