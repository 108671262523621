/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./RecoveryReport.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { FaEdit } from "react-icons/fa";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import FormBtn from "../../component/buttons/Form/FormBtn";
import Loader from "../../component/Loader/Loader";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
// import { Button } from "@mui/material";
const RecoveryReport_A = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState([]);
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isAlertVisible, setAlertVisible] = useState(false);

  //first row
  const [Property1, setProperty1] = useState("");
  const [Property2, setProperty2] = useState("");
  const [Property3, setProperty3] = useState("");
  const [Property4, setProperty4] = useState("");
  const [Property5, setProperty5] = useState("");
  const [totalProperty, settotalProperty] = useState("");
  const [Percentageproperty, setPercentageproperty] = useState("");

  //second row
  const [Padsar1, setPadsar1] = useState("");
  const [Padsar2, setPadsar2] = useState("");
  const [Padsar3, setPadsar3] = useState("");
  const [Padsar4, setPadsar4] = useState("");
  const [Padsar5, setPadsar5] = useState("");
  const [totalPadsar, settotalPadsar] = useState("");
  const [Percentagepadsar, setPercentagepadsar] = useState("");

  //Third row

  const [divabati1, setDivabati1] = useState("");
  const [divabati2, setDivabati2] = useState("");
  const [divabati3, setDivabati3] = useState("");
  const [divabati4, setDivabati4] = useState("");
  const [divabati5, setDivabati5] = useState("");
  const [totaldivabati, settotaldivabati] = useState("");
  const [Percentagedivabati, setPercentagedivabati] = useState("");

  //Fourth row
  const [Arogya1, setArogya1] = useState("");
  const [Arogya2, setArogya2] = useState("");
  const [Arogya3, setArogya3] = useState("");
  const [Arogya4, setArogya4] = useState("");
  const [Arogya5, setArogya5] = useState("");
  const [totalArogya, settotalArogya] = useState("");
  const [PercentageArogya, setPercentageArogya] = useState("");

  //Fifth row
  const [totalPast, setTotalpast] = useState("");
  const [totalPresant, setTotalpresant] = useState("");
  const [totalpaspre, setTotalprepas] = useState("");
  const [pastmonth, setpastMonth] = useState("");
  const [premonth, setpreMonth] = useState("");
  const [totalall, settotalall] = useState("");
  const [percentageall, setpercentageall] = useState("");

  useEffect(() => {
    calculate();
  }, [Property1, Property2]);

  const calculate = () => {
    const num1 = parseFloat(Property1) || 0;
    const num2 = parseFloat(Property2) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setProperty3("");
    } else {
      const sum = num1 + num2;
      setProperty3(Math.round(sum));
    }
  };

  useEffect(() => {
    calculate0();
  }, [Property4, Property5]);

  const calculate0 = () => {
    const num1 = parseFloat(Property4) || 0;
    const num2 = parseFloat(Property5) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      settotalProperty("");
    } else {
      const sum = num1 + num2;
      settotalProperty(Math.round(sum));
    }
  };

  useEffect(() => {
    calculatepercentage();
  }, [Property3, totalProperty]);

  const calculatepercentage = () => {
    const num1 = parseFloat(Property3) || 0;
    const num2 = parseFloat(totalProperty) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setPercentageproperty("");
    } else {
      if (num1 > 0 && num2 > 0) {
        const sum = (num2 / num1) * 100;
        setPercentageproperty(sum.toFixed(2));
      }
    }
  };

  //second row

  useEffect(() => {
    calculate1();
  }, [Padsar1, Padsar2]);

  const calculate1 = () => {
    const num1 = parseFloat(Padsar1) || 0;
    const num2 = parseFloat(Padsar2) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setPadsar3("");
    } else {
      const sum = num1 + num2;
      setPadsar3(Math.round(sum));
    }
  };

  useEffect(() => {
    calculated();
  }, [Padsar4, Padsar5]);

  const calculated = () => {
    const num1 = parseFloat(Padsar4) || 0;
    const num2 = parseFloat(Padsar5) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      settotalPadsar("");
    } else {
      const sum = num1 + num2;
      settotalPadsar(Math.round(sum));
    }
  };

  useEffect(() => {
    calculatepercentage1();
  }, [Padsar3, totalPadsar]);

  const calculatepercentage1 = () => {
    "";

    const num1 = parseFloat(Padsar3) || 0;
    const num2 = parseFloat(totalPadsar) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setPercentagepadsar("");
    } else {
      if (num1 > 0 && num2 > 0) {
        const sum = (num2 / num1) * 100;
        setPercentagepadsar(sum.toFixed(2));
      }
    }
  };

  //Third row

  useEffect(() => {
    calculate2();
  }, [divabati1, divabati2]);

  const calculate2 = () => {
    const num1 = parseFloat(divabati1) || 0;
    const num2 = parseFloat(divabati2) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setDivabati3("");
    } else {
      const sum = num1 + num2;
      setDivabati3(Math.round(sum));
    }
  };

  useEffect(() => {
    calculated1();
  }, [divabati4, divabati5]);

  const calculated1 = () => {
    const num1 = parseFloat(divabati4) || 0;
    const num2 = parseFloat(divabati5) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      settotaldivabati("");
    } else {
      const sum = num1 + num2;
      settotaldivabati(Math.round(sum));
    }
  };

  useEffect(() => {
    calculatepercentage2();
  }, [divabati3, totaldivabati]);

  const calculatepercentage2 = () => {
    "";

    const num1 = parseFloat(divabati3) || 0;
    const num2 = parseFloat(totaldivabati) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setPercentagedivabati("");
    } else {
      if (num1 > 0 && num2 > 0) {
        const sum = (num2 / num1) * 100;
        setPercentagedivabati(sum.toFixed(2));
      }
    }
  };

  //Fourth row

  useEffect(() => {
    calculate3();
  }, [Arogya1, Arogya2]);

  const calculate3 = () => {
    const num1 = parseFloat(Arogya1) || 0;
    const num2 = parseFloat(Arogya2) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setArogya3("");
    } else {
      const sum = num1 + num2;
      setArogya3(Math.round(sum));
    }
  };

  useEffect(() => {
    calculated2();
  }, [Arogya4, Arogya5]);

  const calculated2 = () => {
    const num1 = parseFloat(Arogya4) || 0;
    const num2 = parseFloat(Arogya5) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      settotalArogya("");
    } else {
      const sum = num1 + num2;
      settotalArogya(Math.round(sum));
    }
  };

  useEffect(() => {
    calculatepercentage3();
  }, [Arogya3, totalArogya]);

  const calculatepercentage3 = () => {
    const num1 = parseFloat(Arogya3) || 0;
    const num2 = parseFloat(totalArogya) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setPercentageArogya("");
    } else {
      if (num1 > 0 && num2 > 0) {
        const sum = (num2 / num1) * 100;
        setPercentageArogya(sum.toFixed(2));
      }
    }
  };

  //Fifth row

  useEffect(() => {
    calculate4();
  }, [Property1, Padsar1, divabati1, Arogya1]);

  const calculate4 = () => {
    const num1 = parseFloat(Property1) || 0;
    const num2 = parseFloat(Padsar1) || 0;
    const num3 = parseFloat(divabati1) || 0;
    const num4 = parseFloat(Arogya1) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3) || isNaN(num4)) {
      setTotalpast("");
    } else {
      const sum = num1 + num2 + num3 + num4;
      setTotalpast(Math.round(sum));
    }
  };

  useEffect(() => {
    calculate5();
  }, [Property2, Padsar2, divabati2, Arogya2]);

  const calculate5 = () => {
    const num1 = parseFloat(Property2) || 0;
    const num2 = parseFloat(Padsar2) || 0;
    const num3 = parseFloat(divabati2) || 0;
    const num4 = parseFloat(Arogya2) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3) || isNaN(num4)) {
      setTotalpresant("");
    } else {
      const sum = num1 + num2 + num3 + num4;
      setTotalpresant(Math.round(sum));
    }
  };

  useEffect(() => {
    calculate6();
  }, [Property3, Padsar3, divabati3, Arogya3]);

  const calculate6 = () => {
    const num1 = parseFloat(Property3) || 0;
    const num2 = parseFloat(Padsar3) || 0;
    const num3 = parseFloat(divabati3) || 0;
    const num4 = parseFloat(Arogya3) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3) || isNaN(num4)) {
      setTotalprepas("");
    } else {
      const sum = num1 + num2 + num3 + num4;
      setTotalprepas(Math.round(sum));
    }
  };

  useEffect(() => {
    calculate7();
  }, [Property4, Padsar4, divabati4, Arogya4]);

  const calculate7 = () => {
    const num1 = parseFloat(Property4) || 0;
    const num2 = parseFloat(Padsar4) || 0;
    const num3 = parseFloat(divabati4) || 0;
    const num4 = parseFloat(Arogya4) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3) || isNaN(num4)) {
      setpastMonth("");
    } else {
      const sum = num1 + num2 + num3 + num4;
      setpastMonth(Math.round(sum));
    }
  };

  useEffect(() => {
    calculate8();
  }, [Property5, Padsar5, divabati5, Arogya5]);

  const calculate8 = () => {
    const num1 = parseFloat(Property5) || 0;
    const num2 = parseFloat(Padsar5) || 0;
    const num3 = parseFloat(divabati5) || 0;
    const num4 = parseFloat(Arogya5) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3) || isNaN(num4)) {
      setpreMonth("");
    } else {
      const sum = num1 + num2 + num3 + num4;
      setpreMonth(Math.round(sum));
    }
  };

  useEffect(() => {
    calculate9();
  }, [pastmonth, premonth]);

  const calculate9 = () => {
    const num1 = parseFloat(pastmonth) || 0;
    const num2 = parseFloat(premonth) || 0;
    // const num3 = parseFloat(totaldivabati) || 0;
    // const num4 = parseFloat(totalArogya) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      settotalall("");
    } else {
      const sum = num1 + num2;
      settotalall(Math.round(sum));
    }
  };

  useEffect(() => {
    calculate10();
  }, [totalpaspre, totalall]);

  const calculate10 = () => {
    const num1 = parseFloat(totalpaspre) || 0;
    const num2 = parseFloat(totalall) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setpercentageall("");
    } else {
      if (num1 > 0 && num2 > 0) {
        const sum = (num2 / num1) * 100;
        setpercentageall(sum.toFixed(2));
      }
    }
  };

  //  वसुली विभाग (घरपट्टी वसुली) Get Information api
  const handleRecoveryReportDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month,
      };
      const response = await axios.post(
        API_BASE_URL + "api/recovery_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("RecoveryReportDetails response", response.data.data);
      if (
        response.data.data.economic_a_data === null ||
        response.data.data.economic_a_data.length === 0
      ) {
        setAlertVisible(true);
        setIsLoading(false);
      }
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      if (response.data.status === true) {
        if (
          response.data.data.recovery_a_data === null ||
          response.data.data.recovery_a_data.length === 0
        ) {
          setEconomicData("");
        } else {
          setEconomicData(response.data.data.recovery_a_data[0].is_draft);
        }
      } else {
        console.log("error occured", response.data.status);
      }
      setData(response.data.data.economic_a_data);
      setMonth(response.data.data.month);
      // updated date show in inputs

      setProperty1(response.data.data.economic_a_data[0].past_due);
      setProperty2(response.data.data.economic_a_data[0].current_demand);
      setProperty3(response.data.data.economic_a_data[0].total_demand);
      // settotalProperty(response.data.data.recovery_a_data[0].total_recovery);

      // पडसर

      setPadsar1(response.data.data.economic_a_data[1].past_due);
      setPadsar2(response.data.data.economic_a_data[1].current_demand);
      setPadsar3(response.data.data.economic_a_data[1].total_demand);
      // settotalPadsar(response.data.data.recovery_a_data[1].total_recovery);

      // दिवाबत्ती

      setDivabati1(response.data.data.economic_a_data[2].past_due);
      setDivabati2(response.data.data.economic_a_data[2].current_demand);
      setDivabati3(response.data.data.economic_a_data[2].total_demand);
      // settotaldivabati(response.data.data.economic_a_data[2].total_recovery);

      // आरोग्य

      setArogya1(response.data.data.economic_a_data[3].past_due);
      setArogya2(response.data.data.economic_a_data[3].current_demand);
      setArogya3(response.data.data.economic_a_data[3].total_demand);
      // settotalArogya(response.data.data.economic_a_data[3].total_recovery);

      // last month recover
      setProperty4(response.data.data.last_month_total[0].last_month_recovery);
      setPadsar4(response.data.data.last_month_total[1].last_month_recovery);
      setDivabati4(response.data.data.last_month_total[2].last_month_recovery);
      setArogya4(response.data.data.last_month_total[3].last_month_recovery);

      //current month recovery
      if (
        response.data.data.recovery_a_data !== null &&
        response.data.data.recovery_a_data.length > 0
      ) {
        setProperty5(
          response.data.data.recovery_a_data[0].current_month_recovery
        );
        setPadsar5(
          response.data.data.recovery_a_data[1].current_month_recovery
        );
        setDivabati5(
          response.data.data.recovery_a_data[2].current_month_recovery
        );
        setArogya5(
          response.data.data.recovery_a_data[3].current_month_recovery
        );
      } else {
        setProperty5(0);
        setPadsar5(0);
        setDivabati5(0);
        setArogya5(0);
      }

      // setPercentageproperty(response.data.data.recovery_a_data[0].percentage);
      // setPercentagepadsar(response.data.data.recovery_a_data[1].percentage);
      // setPercentagedivabati(response.data.data.recovery_a_data[2].percentage);
      // setPercentageArogya(response.data.data.recovery_a_data[3].percentage);

      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleRecoveryReportDetails();
    if (
      Property4 === 0 &&
      Padsar4 === 0 &&
      divabati4 === 0 &&
      Arogya4 === 0 &&
      Property5 === 0 &&
      Padsar5 === 0 &&
      divabati5 === 0 &&
      Arogya5 === 0
    ) {
      setPercentageproperty(0);
      setPercentagepadsar(0);
      setPercentagedivabati(0);
      setPercentageArogya(0);
      setpercentageall(0);
    }
  }, [
    month,
    Property4,
    Padsar4,
    divabati4,
    Arogya4,
    Property5,
    Padsar5,
    divabati5,
    Arogya5,
    setPercentageproperty,
    setPercentagepadsar,
    setPercentagedivabati,
    setPercentageArogya,
    setpercentageall,
  ]);

  const handleDataRefresh = async () => {
    await handleRecoveryReportDetails();
  };

  // reset the data on server using function
  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleRecoveryReportDetails();
    } else {
      setProperty1("");
      setProperty2("");
      setProperty4("");
      setProperty5("");
      setPadsar1("");
      setPadsar2("");
      setPadsar4("");
      setPadsar5("");
      setDivabati1("");
      setDivabati2("");
      setDivabati4("");
      setDivabati5("");
      setArogya1("");
      setArogya2("");
      setArogya4("");
      setArogya5("");
      setValidated(false);
    }
  };
  // Refs for input elements
  const Property1Ref = useRef(null);
  const Property2Ref = useRef(null);
  const Property4Ref = useRef(null);
  const Property5Ref = useRef(null);
  const Padsar1Ref = useRef(null);
  const Padsar2Ref = useRef(null);
  const Padsar4Ref = useRef(null);
  const Padsar5Ref = useRef(null);
  const divabati1Ref = useRef(null);
  const divabati2Ref = useRef(null);
  const divabati4Ref = useRef(null);
  const divabati5Ref = useRef(null);
  const Arogya1Ref = useRef(null);
  const Arogya2Ref = useRef(null);
  const Arogya4Ref = useRef(null);
  const Arogya5Ref = useRef(null);
  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(Property1)) {
        Property1Ref.current.focus();
      } else if (!isNumeric(Property2)) {
        Property2Ref.current.focus();
      } else if (!isNumeric(Property4)) {
        Property4Ref.current.focus();
      } else if (!isNumeric(Property5)) {
        Property5Ref.current.focus();
      } else if (!isNumeric(Padsar1)) {
        Padsar1Ref.current.focus();
      } else if (!isNumeric(Padsar2)) {
        Padsar2Ref.current.focus();
      } else if (!isNumeric(Padsar4)) {
        Padsar4Ref.current.focus();
      } else if (!isNumeric(Padsar5)) {
        Padsar5Ref.current.focus();
      } else if (!isNumeric(divabati1)) {
        divabati1Ref.current.focus();
      } else if (!isNumeric(divabati2)) {
        divabati2Ref.current.focus();
      } else if (!isNumeric(divabati4)) {
        divabati4Ref.current.focus();
      } else if (!isNumeric(divabati5)) {
        divabati5Ref.current.focus();
      } else if (!isNumeric(Arogya1)) {
        Arogya1Ref.current.focus();
      } else if (!isNumeric(Arogya2)) {
        Arogya2Ref.current.focus();
      } else if (!isNumeric(Arogya4)) {
        Arogya4Ref.current.focus();
      } else if (!isNumeric(Arogya5)) {
        Arogya5Ref.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  // before submit check validation of
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(Property1) ||
      !isNumeric(Property2) ||
      !isNumeric(Property4) ||
      !isNumeric(Property5) ||
      !isNumeric(Padsar1) ||
      !isNumeric(Padsar2) ||
      !isNumeric(Padsar4) ||
      !isNumeric(Padsar5) ||
      !isNumeric(divabati1) ||
      !isNumeric(divabati2) ||
      !isNumeric(divabati4) ||
      !isNumeric(divabati5) ||
      !isNumeric(Arogya1) ||
      !isNumeric(Arogya2) ||
      !isNumeric(Arogya4) ||
      !isNumeric(Arogya5) ||
      parseFloat(totalProperty) > parseFloat(Property3) ||
      parseFloat(totalPadsar) > parseFloat(Padsar3) ||
      parseFloat(totaldivabati) > parseFloat(divabati3) ||
      parseFloat(totalArogya) > parseFloat(Arogya3)
    ) {
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      if (validated) {
        await handleUpdateRecoveryReport(e);
      }
    } else {
      handleShow();
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  //  वसुली विभाग (घरपट्टी वसुली) Update Information api

  const handleUpdateRecoveryReport = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        recovery_a_data: [
          {
            type: "Property Tax",
            last_month_recovery: Property4,
            current_month_recovery: Property5,
            total_recovery: totalProperty,
            percentage: Percentageproperty || 0,
          },
          {
            type: "Neighbor Tax",
            last_month_recovery: Padsar4,
            current_month_recovery: Padsar5,
            total_recovery: totalPadsar,
            percentage: Percentagepadsar || 0,
          },
          {
            type: "Lamp Tax",
            last_month_recovery: divabati4,
            current_month_recovery: divabati5,
            total_recovery: totaldivabati,
            percentage: Percentagedivabati || 0,
          },
          {
            type: "Health Tax",
            last_month_recovery: Arogya4,
            current_month_recovery: Arogya5,
            total_recovery: totalArogya,
            percentage: PercentageArogya || 0,
          },
        ],
      };
      console.log("updateRecoveryReport finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_recovery_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateRecoveryReportDetails response", response);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          setValidated(false);
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // वसुली विभाग (घरपट्टी वसुली) Update Information api

  const handleDraftRecoveryReport = async (e) => {
    e.preventDefault();
    if (
      (!isNumeric(Property1) && Property1 !== "") ||
      (!isNumeric(Property2) && Property2 !== "") ||
      (!isNumeric(Property4) && Property4 !== "") ||
      (!isNumeric(Property5) && Property5 !== "") ||
      (!isNumeric(Padsar1) && Padsar1 !== "") ||
      (!isNumeric(Padsar2) && Padsar2 !== "") ||
      (!isNumeric(Padsar4) && Padsar4 !== "") ||
      (!isNumeric(Padsar5) && Padsar5 !== "") ||
      (!isNumeric(divabati1) && divabati1 !== "") ||
      (!isNumeric(divabati2) && divabati2 !== "") ||
      (!isNumeric(divabati4) && divabati4 !== "") ||
      (!isNumeric(divabati5) && divabati5 !== "") ||
      (!isNumeric(Arogya1) && Arogya1 !== "") ||
      (!isNumeric(Arogya2) && Arogya2 !== "") ||
      (!isNumeric(Arogya4) && Arogya4 !== "") ||
      (!isNumeric(Arogya5) && Arogya5 !== "") ||
      parseFloat(totalProperty) > parseFloat(Property3) ||
      parseFloat(totalPadsar) > parseFloat(Padsar3) ||
      parseFloat(totaldivabati) > parseFloat(divabati3) ||
      parseFloat(totalArogya) > parseFloat(Arogya3)
    ) {
      setValidated(true);
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        recovery_a_data: [
          {
            type: "Property Tax",
            last_month_recovery: Property4,
            current_month_recovery: Property5,
            total_recovery: totalProperty,
            percentage: Percentageproperty || 0,
          },
          {
            type: "Neighbor Tax",
            last_month_recovery: Padsar4,
            current_month_recovery: Padsar5,
            total_recovery: totalPadsar,
            percentage: Percentagepadsar || 0,
          },
          {
            type: "Lamp Tax",
            last_month_recovery: divabati4,
            current_month_recovery: divabati5,
            total_recovery: totaldivabati,
            percentage: Percentagedivabati || 0,
          },
          {
            type: "Health Tax",
            last_month_recovery: Arogya4,
            current_month_recovery: Arogya5,
            total_recovery: totalArogya,
            percentage: PercentageArogya || 0,
          },
        ],
      };
      console.log("draftRecoveryReport finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_recovery_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draftRecoveryReportDetails response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          setValidated(false);
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Helmet>
              <title>वसुली रीपोर्ट || घरपट्टी वसुली </title>
            </Helmet>
            <div className="container common-section pt-0">
              <div className="row">
                <div className="col-lg-12 navigate-section-header mt-3">
                  <div className="page-title">
                    <p className="fw-bold mb-2">१.२ वसुली विभाग</p>
                  </div>
                  <div className="navigate-section d-flex">
                    <div className="arrow-box-active d-flex flex-column justify-content-center">
                      <Link className="text-decoration-none" to="/recovery-a">
                        अ) घरपट्टी वसुली{" "}
                      </Link>
                      <span className="text-center arrow-icon">
                        <ArrowDropDownIcon fontSize="large" color="primary" />
                      </span>
                    </div>
                    <div className="arrow-box">
                      <Link
                        className="me-5 ms- 5 text-dark"
                        style={{ marginLeft: "48px" }}
                        to="/recovery-b"
                      >
                        ब) पाणीपट्टी (सामान्य व विशेष पाणीपट्टी)
                      </Link>
                    </div>
                    <div className="arrow-box">
                      <Link to="/recovery-c" className="text-dark">
                        क) किरकोळ मागणी (पवनचक्की सोडून)
                      </Link>
                    </div>
                    <div className="arrow-box me-5">
                      <Link className="ms-4 text-dark" to="/recovery-d">
                        ड) पवनचक्की/कारखाना वसुली{" "}
                      </Link>
                    </div>
                  </div>
                  {/* <TabEco/> */}
                </div>
                {!isAlertVisible ? (
                  <>
                    <div className="col-lg-12 mt-5 mb-5">
                      <div className="d-flex gap-5">
                        <Village_Tal_Dist_Mahe
                          villageDataInfo={villageDataInfo}
                        />
                        <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                          <label htmlFor="">माहे :</label>
                          {month === undefined ? (
                            <span></span>
                          ) : (
                            <select
                              name="month"
                              value={month}
                              onChange={(e) => setMonth(e.target.value)}
                              className="fw-bold"
                            >
                              <option value="1">जानेवारी</option>
                              <option value="2">फेब्रुवारी</option>
                              <option value="3">मार्च</option>
                              <option value="4">एप्रिल</option>
                              <option value="5">मे</option>
                              <option value="6">जून</option>
                              <option value="7">जुलै</option>
                              <option value="8">ऑगस्ट</option>
                              <option value="9">सप्टेंबर</option>
                              <option value="10">ऑक्टोंबर</option>
                              <option value="11">नोव्हेंबर</option>
                              <option value="12">डिसेंबर</option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-main-sec">
                        <div className="table_title_top">
                          <p>
                            अ) घरपट्टी वसूली (मालमत्ता कर, पडसर कर, दिवाबत्ती कर
                            व आरोग्य कर)
                          </p>
                          {economicData === "Y" ? (
                            <span className="drafted_text">
                              <FaEdit /> Drafted
                            </span>
                          ) : economicData === "N" ? (
                            <span className="submit_text">
                              Submitted {" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={30}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="table-responsive mb-3">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">कराचे नाव</th>
                                <th scope="col">
                                  मागील थकबाकी <br /> (सन{" "}
                                  {yearInfo ? yearInfo.previous_year_name : ""})
                                </th>
                                <th scope="col">
                                  चालू मागणी <br /> (सन{" "}
                                  {yearInfo ? yearInfo.current_year_name : ""})
                                </th>
                                <th scope="col">
                                  एकूण मागणी <br />
                                  (मागील + चालू) (सन{" "}
                                  {yearInfo ? yearInfo.current_year_name : ""})
                                </th>
                                <th scope="col">
                                  {" "}
                                  मागील महिनाअखेर <br /> वसूली{" "}
                                </th>
                                <th scope="col">
                                  {" "}
                                  चालू महिन्यातील <br /> वसूली{" "}
                                </th>
                                <th scope="col"> एकूण वसूली </th>
                                <th scope="col"> टक्केवारी </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>मालमत्ता</td>
                                <td>
                                  <input
                                    type="text"
                                    value={Property1}
                                    onChange={(e) => {
                                      setProperty1(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Property1)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Property1)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Property1Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Property2}
                                    onChange={(e) => {
                                      setProperty2(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Property2)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Property2)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Property2Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={Property3}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Property4}
                                    onChange={(e) => {
                                      setProperty4(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Property4)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Property4)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Property4Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Property5}
                                    onChange={(e) => {
                                      setProperty5(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Property5)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Property5)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Property5Ref}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totalProperty) >
                                        parseFloat(Property3)
                                        ? "is-invalid "
                                        : ""
                                    }`}
                                    value={totalProperty}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totalProperty) >
                                        parseFloat(Property3)
                                        ? "is-invalid "
                                        : ""
                                    }`}
                                    value={Percentageproperty || 0}
                                    readOnly
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>पडसर</td>
                                <td>
                                  <input
                                    type="text"
                                    value={Padsar1}
                                    onChange={(e) => {
                                      setPadsar1(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Padsar1)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Padsar1)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Padsar1Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Padsar2}
                                    onChange={(e) => {
                                      setPadsar2(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Padsar2)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Padsar2)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Padsar2Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={Padsar3 || 0}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Padsar4}
                                    onChange={(e) => {
                                      setPadsar4(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Padsar4)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Padsar4)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Padsar4Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Padsar5}
                                    onChange={(e) => {
                                      setPadsar5(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Padsar5)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Padsar5)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Padsar5Ref}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={totalPadsar}
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totalPadsar) >
                                        parseFloat(Padsar3)
                                        ? "is-invalid "
                                        : ""
                                    }`}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={Percentagepadsar || 0}
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totalPadsar) >
                                        parseFloat(Padsar3)
                                        ? "is-invalid "
                                        : ""
                                    }`}
                                    readOnly
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>दिवाबत्ती</td>
                                <td>
                                  <input
                                    type="text"
                                    value={divabati1}
                                    onChange={(e) => {
                                      setDivabati1(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(divabati1)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(divabati1)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={divabati1Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={divabati2}
                                    onChange={(e) => {
                                      setDivabati2(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(divabati2)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(divabati2)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={divabati2Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={divabati3}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={divabati4}
                                    onChange={(e) => {
                                      setDivabati4(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(divabati4)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(divabati4)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={divabati4Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={divabati5}
                                    onChange={(e) => {
                                      setDivabati5(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(divabati5)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(divabati5)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={divabati5Ref}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={totaldivabati}
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totaldivabati) >
                                        parseFloat(divabati3)
                                        ? "is-invalid "
                                        : ""
                                    }`}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={Percentagedivabati || 0}
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totaldivabati) >
                                        parseFloat(divabati3)
                                        ? "is-invalid "
                                        : ""
                                    }`}
                                    readOnly
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>आरोग्य</td>
                                <td>
                                  <input
                                    type="text"
                                    value={Arogya1}
                                    onChange={(e) => {
                                      setArogya1(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Arogya1)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Arogya1)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Arogya1Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Arogya2}
                                    onChange={(e) => {
                                      setArogya2(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Arogya2)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Arogya2)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Arogya2Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={Arogya3}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Arogya4}
                                    onChange={(e) => {
                                      setArogya4(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Arogya4)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Arogya4)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Arogya4Ref}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={Arogya5}
                                    onChange={(e) => {
                                      setArogya5(e.target.value);
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(Arogya5)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(Arogya5)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={Arogya5Ref}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={totalArogya}
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totalArogya) >
                                        parseFloat(Arogya3)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={PercentageArogya || 0}
                                    className={`form-control table-input-box fw-bold ${
                                      validated &&
                                      parseFloat(totalArogya) >
                                        parseFloat(Arogya3)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    readOnly
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>एकूण</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={totalPast}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={totalPresant}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={totalpaspre}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={pastmonth}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={premonth}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={totalall}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control table-input-box fw-bold"
                                    value={percentageall || 0}
                                    readOnly
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {validated &&
                            (parseFloat(totalProperty) >
                              parseFloat(Property3) ||
                            parseFloat(totalPadsar) > parseFloat(Padsar3) ||
                            parseFloat(totaldivabati) > parseFloat(divabati3) ||
                            parseFloat(totalArogya) > parseFloat(Arogya3) ? (
                              <span className="text-danger">
                                सूचना :- कृपया एकूण वसूली ही एकूण मागणी पेक्षा
                                कमी किवा समान असावी.
                              </span>
                            ) : (
                              ""
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <FooterDevOfficer villageDataInfo={villageDataInfo} />
                    </div>
                    {month === currentMonth.toString() && !isLoading && (
                      <div className="col-lg-12 d-flex justify-content-end align-items-center">
                        {(economicData === "Y" || economicData === "") && (
                          <FormBtn
                            handleSubmit={handleFormSubmission}
                            resetForm={handleDataRefreshReset}
                            handleDraft={handleDraftRecoveryReport}
                          />
                        )}
                      </div>
                    )}

                    {economicData === "N" && (
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p className="text-danger">
                            सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                            विनंती करा.{" "}
                          </p>
                          <div>
                            <Button variant="contained">विनंती करा</Button>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {" "}
                          सूचना <MdOutlineArrowDownward />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                        Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म
                        Submit करू इच्छिता?
                      </Modal.Body>
                      <Modal.Footer className="gap-3">
                        <Button
                          className="modal-button-cancel"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="modal-button-submit"
                          onClick={handleUpdateRecoveryReport}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : (
                  <section
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <div className="alert_pop_up_common">
                            <h4 className="alert_pop_up_common_title">
                              आर्थिक वर्ष मागणी
                            </h4>
                            <p className="alert_pop_up_common_des">
                              {" "}
                              कृपया आर्थिक वर्ष मागणी ग्रामपंचायत करमागणी
                              (मालमत्ता कर, घरपट्टी, पडसर कर, दिवाबत्ती कर व
                              आरोग्य कर) भरा.
                            </p>
                            <Link className="alert_submit_btn" to="/eco-a">
                              आता पूर्ण करा
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
              <ToastContainer
                position={"top-center"}
                autoClose={2000}
                pauseOnHover={false}
                closeOnClick={true}
              />
            </div>
          </>
        )}
      </>
    </React.Fragment>
  );
};

export default RecoveryReport_A;
