import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./component/dashboard/Dashboard";
import Login from "./component/login/Login";
import AdminLogin from "./component/login/AdminLogin";
import EconomicsYear_A from "./pages/GeneralAdministrationDepartment/EconomicsYear_A";
import EconomicsYear_B from "./pages/GeneralAdministrationDepartment/EconomicsYear_B";
import EconomicsYear_C from "./pages/GeneralAdministrationDepartment/EconomicsYear_C";
import ExpenseProvision_A from "./pages/ExpenseProvision/ExpenseProvision_A";
import ExpenseProvision_B from "./pages/ExpenseProvision/ExpenseProvision_B";
import ExpenseProvision_C from "./pages/ExpenseProvision/ExpenseProvision_C";
import DetailsOfExpenses from "./pages/DetailsOfExpenses/DetailsOfExpenses";
import MonthlyMeeting from "./pages/Monthly-GramSabha/MonthlyMeeting";

//Recovery
import RecoveryReport_A from "./pages/VasuliReport/RecoveryReport_A";
import RecoveryReport_B from "./pages/VasuliReport/RecoveryReport_B";
import RecoveryReport_C from "./pages/VasuliReport/RecoveryReport_C";
import RecoveryReport_D from "./pages/VasuliReport/RecoveryReport_D";
import DistrictRecoveryReport from "./pages/VasuliReport/DistrictVasuliReport/DistrictRecoveryReport";
import Navbar from "./component/navbar/Navbar";
import Footer from "./component/footer/Footer";
import GramMetting from "./pages/Monthly-GramSabha/GramMetting";

import Fifteen_Vith from "./pages/Fifteen_Vith_Auoyog/Fifteen_Vith";
import Anusuchit from "./pages/AnusuchitJati/Anusuchit";
import Gharkul from "./pages/Gharkul_vibhag/Gharkul";
import Swach_Bharat from "./pages/Swach_Bharat/Swach_Bharat";
import Janm_Mrutu from "./pages/Janm_Mrutu/Janm_Mrutu";
import Jansuvidha from "./pages/Jansuvida/Jansuvidha";
import Nagrisuvida from "./pages/Jansuvida/Nagrisuvida";
import Tirthshetra from "./pages/Jansuvida/Tirthshetra";
import Prapatra_1 from "./pages/Prapatra/Prapatra_1";
import Prapatra_2 from "./pages/Prapatra/Prapatra_2";
import Prapatra_3 from "./pages/Prapatra/Prapatra_3";

// navbar show hide
import MyBeShowNavbar from "./component/MyBeShowNavbar/MyBeShowNavbar";
import Loader from "./component/Loader/Loader";
import NoticeBoard from "./component/NoticeBoard/NoticeBoard";
import React from "react";
import VistarGharkul from "./pages/Gharkul_vibhag/VistarGharkul/VistarGharkul";
import VistarGharkul_A from "./pages/Gharkul_vibhag/VistarGharkul/VistarGharkul_A";
import AddNotice from "./component/Notice/AddNotice";
import Notification from "./component/Notification/Notification";
import DistrictExpense from "./pages/ExpenseProvision/districtExpense/DistrictExpense";
import Meeting from "./pages/Monthly-GramSabha/meeting/Meeting";
import Prapatra from "./pages/Prapatra/districtPrapatra/Prapatra";
import DistrictFifttenVith from "./pages/Fifteen_Vith_Auoyog/districtFifteenVith/DistrictFifteenVith";
import DistrictAnusuchit from "./pages/AnusuchitJati/districtAnusuchit/DistrictAnusuchit";
import DistrictJansuvidha from "./pages/Jansuvida/districtJansuvidha/DistrictJansuvidha";
import DistrictNagrisuvidha from "./pages/Jansuvida/districtJansuvidha/DistrictNagrisuvidha";
import DistrictTirthshetra from "./pages/Jansuvida/districtJansuvidha/DistrictTirthshetra";
import DistrictEconomics from "./pages/GeneralAdministrationDepartment/districtEconomics/DistrictEconomics";
import DistrictGharkul from "./pages/Gharkul_vibhag/districtGharkul/DistrictGharkul";
import BirthDeathReport from "./pages/Janm_Mrutu/districtJanmMrutu/BirthDeathReport";
import DistrictExpences from "./pages/DetailsOfExpenses/districtExpences/DistrictExpences";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import SheetB from "./pages/VarshikAhval/SheetB";
import SheetC_D from "./pages/VarshikAhval/SheetC_D";
import SheetF from "./pages/VarshikAhval/SheetF";
import SheetG from "./pages/VarshikAhval/SheetG";
import GramNidhiSampleNoThree from "./pages/VarshikAhval/samplePages/GramNidhiSampleNoThree";
import WaterSupplySampleNoThree from "./pages/VarshikAhval/samplePages/WaterSupplySampleNoThree";
import SheetA from "./pages/VarshikAhval/SheetA";
import MainVarshikAhval from "./pages/VarshikAhval/MainVarshikAhval";
import Pdf from "./pages/VarshikAhval/Pdf";
import Profile from "./component/Profile/Profile";
import EconomicsYear_D from "./pages/GeneralAdministrationDepartment/EconomicsYear_D";
import EconomicsYear_E from "./pages/GeneralAdministrationDepartment/EconomicsYear_E";
import DistrictSwatchBharat from "./pages/Swach_Bharat/districtSwachBharat/DistrictSwatchBharat";
import Mpr_Report from "./pages/MPR/Mpr_Report";
import Settings from "./pages/Settings/Settings";
import VistarSwachBharat from "./pages/Swach_Bharat/VistarSwachBharat/VistarSwachBharat";

function App() {
  var userDataInfo = sessionStorage.getItem("info");
  var userDetails = JSON.parse(userDataInfo);
  var userInfo = userDetails ? userDetails.user_details : "";
  const location = useLocation("");

  const footerHide = location.pathname === "/dashboard";

  return (
    <HelmetProvider>
      <MyBeShowNavbar>
        <Navbar />
      </MyBeShowNavbar>
      <Routes>
        <Route path="/*" element={<PageNotFound />} />
        {/* {userDetails && <Route path="/dashboard" element={<Dashboard />} />} */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/admin-login" element={<AdminLogin />} />
        {/* सामान्य प्रशासन विभाग */}
        GramNidhiSampleNoThree
        {userInfo.role_id === "1" && (
          <Route path="/eco-a" element={<EconomicsYear_A />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/district_economics" element={<DistrictEconomics />} />
        )}
        <Route path="/eco-b" element={<EconomicsYear_B />} />
        <Route path="/eco-c" element={<EconomicsYear_C />} />
        <Route path="/eco-d" element={<EconomicsYear_D />} />
        <Route path="/eco-e" element={<EconomicsYear_E />} />
        {/* वसूली  विभाग */}
        {userInfo.role_id === "1" && (
          <Route path="/recovery-a" element={<RecoveryReport_A />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/recovery" element={<DistrictRecoveryReport />} />
        )}
        <Route path="/recovery-b" element={<RecoveryReport_B />} />
        <Route path="/recovery-c" element={<RecoveryReport_C />} />
        <Route path="/recovery-d" element={<RecoveryReport_D />} />
        {/* खर्च तरतूद */}
        {userInfo.role_id === "1" && (
          <Route path="/expense-pro-a" element={<ExpenseProvision_A />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/expense" element={<DistrictExpense />} />
        )}
        <Route path="/expense-pro-b" element={<ExpenseProvision_B />} />
        <Route path="/expense-pro-c" element={<ExpenseProvision_C />} />
        {/* खर्चाचा तपशील */}
        {userInfo.role_id === "1" && (
          <Route path="/details-of-expense" element={<DetailsOfExpenses />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/expense_details" element={<DistrictExpences />} />
        )}
        {/* मासिक सभा /ग्रामसभा */}
        {userInfo.role_id === "1" && (
          <Route path="/month-meeting" element={<MonthlyMeeting />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/meeting" element={<Meeting />} />
        )}
        <Route path="/gram-meeting" element={<GramMetting />} />
        {/* प्रपत्र  */}
        {userInfo.role_id === "1" && (
          <Route path="/praptra-a" element={<Prapatra_1 />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/praptra" element={<Prapatra />} />
        )}
        <Route path="/praptra-b" element={<Prapatra_2 />} />
        <Route path="/praptra-c" element={<Prapatra_3 />} />
        {/* १५ वित्त आयोग  */}
        {userInfo.role_id === "1" && (
          <Route path="/vith_ayog" element={<Fifteen_Vith />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/d-vith_ayog" element={<DistrictFifttenVith />} />
        )}
        {/* अनुसूचीत जाती   */}
        {userInfo.role_id === "1" && (
          <Route path="/anusuchit_jati" element={<Anusuchit />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/d_anusuchit_jati" element={<DistrictAnusuchit />} />
        )}
        {/* घरकुल विभाग   */}
        {userInfo.role_id === "1" && (
          <Route path="/gharkul" element={<Gharkul />} />
        )}
        {(userInfo.role_id !== "1" && userInfo.role_id !== "5" ) && (
          <Route path="/d-gharkul" element={<DistrictGharkul />} />
        )}
        {userInfo.role_id == "5" && (
          <Route path="/vistar_gharkul" element={<VistarGharkul />} />
        )}
        
        <Route path="/vistar_gharkul_a" element={<VistarGharkul_A />} />
        {/* स्वछ भारत   */}
        {userInfo.role_id === "1" && (
          <Route path="/swach_bharat" element={<Swach_Bharat />} />
        )}

        {userInfo.role_id === "22" && (
          <Route path="/vistar_swach_bharat" element={<VistarSwachBharat />} />
        )}
        {(userInfo.role_id !== "1" && userInfo.role_id !== "22" ) && (
          <Route path="/d_swach_bharat" element={<DistrictSwatchBharat />} />
        )}
        {/* जन्म मृत्यू  */}
        {userInfo.role_id === "1" && (
          <Route path="/janm_mrutu" element={<Janm_Mrutu />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/birth_death_report" element={<BirthDeathReport />} />
        )}
        {/* जनसुविधा, नागरीसुविधा, तिर्थक्षेत्र विकास विभाग  */}
        {userInfo.role_id === "1" && (
          <Route path="/jansuvidha" element={<Jansuvidha />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/d-jansuvidha" element={<DistrictJansuvidha />} />
        )}
        {userInfo.role_id === "1" && (
          <Route path="/nagrisuvidha" element={<Nagrisuvida />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/d-nagrisuvidha" element={<DistrictNagrisuvidha />} />
        )}
        {userInfo.role_id === "1" && (
          <Route path="/tirthshetra" element={<Tirthshetra />} />
        )}
        {userInfo.role_id !== "1" && (
          <Route path="/d-tirthshetra" element={<DistrictTirthshetra />} />
        )}

        {userInfo.role_id === "1" && (
          <Route path="/settings" element={<Settings />} />
        )}

        <Route path="/mpr_report" element={<Mpr_Report />} />
        <Route path="/loader" element={<Loader />} />
        {/* नोटिस बोर्ड */}
        <Route path="/notice_board" element={<NoticeBoard />} />
        {/* नोटिस अॅड डिलीट एडिट  */}
        <Route path="/add_notice" element={<AddNotice />} />
        {/* सूचना */}
        <Route path="/add_notification" element={<Notification />} />
        {/*  वार्षिक प्रशासन अहवाल विभाग*/}
        <Route path="/varshik-ahval" element={<MainVarshikAhval />} />
        <Route path="/sheet_a" element={<SheetA />} />
        <Route path="/sheet_b" element={<SheetB />} />
        <Route path="/sheet_c_d" element={<SheetC_D />} />
        <Route path="/sheet_f" element={<SheetF />} />
        <Route path="/sheet_g" element={<SheetG />} />
        <Route path="/pdf" element={<Pdf />} />
        {/* sample page  */}
        <Route
          path="/gramnidhi-sample-three"
          element={<GramNidhiSampleNoThree />}
        />
        <Route
          path="/watersupply-sample-three"
          element={<WaterSupplySampleNoThree />}
        />
        <Route path="/profile" element={<Profile />} />
      </Routes>
      {!footerHide && <Footer />}
    </HelmetProvider>
  );
}

export default App;
