/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import pulseIcon from "../../component/assets/images/logo/plus-removebg-preview.png";
import "./Gharkul.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Select from "react-select";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "evergreen-ui";
import Loader from "../../component/Loader/Loader";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
const Gharkul = () => {
  const [rows, setRows] = useState([{}]);
  const [type, setType] = useState("");
  // data when store district taluka month
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [gharkhulData, setGharkhulData] = useState([]);
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [selectDate, setSelectDate] = useState("");
  const [updatedRows, setUpdatedRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);

    // Set the updated rows to state
    setUpdatedRows(updatedRows);

    const errors = validateRow(updatedRows[index]);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;
    setValidationErrors(updatedValidationErrors);
  };

  const validateRow = (row) => {
    const errors = {};
    if (!row.house_status) {
      errors.house_status = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.house_status)) {
      errors.house_status = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.akher) {
      errors.akher = "महत्वाचे आहे";
    } else if (/^(\d{2})-(\d{2})-(\d{4})$/.test(row.akher)) {
      errors.akher = "अयोग्य दिनांक";
    }
    if (!row.comment) {
      errors.comment = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.comment)) {
      errors.comment = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    return errors;
  };

  const validateRow1 = (row) => {
    const errors = {};
    if (
      row.house_status !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.house_status)
    ) {
      errors.house_status = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (row.akher !== "" && /^(\d{2})-(\d{2})-(\d{4})$/.test(row.akher)) {
      errors.akher = "अयोग्य दिनांक";
    }

    if (row.comment !== "" && !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.comment)) {
      errors.comment = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    return errors;
  };

  // get data on the server
  useEffect(() => {
    handleGharkulDetails();
    setFilter('');
  }, [month, type]);

  const handleGharkulDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        year_id: userDetails.current_year_id,
        type: type || "प्रधानमंत्री आवास योजना",
      };
      console.log("finalData in gharkul", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("gharkul details response", response.data.data);
      // Check if the response contains gharkul_data
      setIsLoading(false);
      setVillageDataInfo(response.data.data.other_data);
      if (response.data.data.gharkul_data.length > 0) {
        // Update the state with new data
        setYearInfo(response.data.data.year_info);
        setGharkhulData(response.data.data.gharkul_data);
        setEconomicData(response.data.data.gharkul_data[0].is_draft);
        setSelectDate(response.data.data.gharkul_data[0].date);
        setMonth(response.data.data.month);
        const initialRows = response.data.data.gharkul_data.map((item) => ({
          id: item.id,
          // date: "",
          beneficiary_name: item.beneficiary_name,
          gpt_name: item.gpt_name,
          house_status: item.house_status,
          akher: item.akher,
          comment: item.comment,
          uploaded_photo: "",
          uploaded_doc: "",
          submit_img: item.uploaded_photo,
          submit_doc: item.uploaded_doc,
        }));
        setRows(initialRows);
        console.log("submit image", initialRows);
      } else {
        // Reset the state when gharkul_data is empty
        const initialRows = response.data.data.gharkul_data.map((item) => ({
          id: "",
          // date: "",
          beneficiary_name: "",
          gpt_name: "",
          house_status: "",
          akher: "",
          comment: "",
          uploaded_photo: "",
          uploaded_doc: "",
        }));
        setRows(initialRows);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = dateObject.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // when data updated new then quick load the data in page
  const handleDataRefresh = async () => {
    await handleGharkulDetails();
  };

  const handleDataReset = async () => {
    if (gharkhulData.length > 0) {
      await handleGharkulDetails();
      setValidationErrors("");
    } else {
      setRows([{}]);
      setValidationErrors("");
    }
  };

  const handleAddRow = () => {
    const lastId = rows.length > 0 ? rows[rows.length - 1].id : 0;
    const newRow = { id: parseFloat(lastId) + 1 }; // Increment id by 1
    setRows([...rows, newRow]);
  };

  const handleUpdateButtonClick = () => {
    handleGharkulUpdate(rows);
  };

  // handle Photo upload

  const handlePhotoInputChange = (e, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result;
      handleInputChange(
        { target: { name: "uploaded_photo", value: base64Data } },
        index
      );
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // handle upload document

  const handleFileInputChange = (e, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result;
      handleInputChange(
        { target: { name: "uploaded_doc", value: base64Data } },
        index
      );
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  //  Update Information api

  const handleGharkulUpdate = async (updatedRows) => {
    // e.preventDefault();
    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }
    // setIsLoading(true);
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        type: type || "प्रधानमंत्री आवास योजना",
        gharkul_data: rows.map((row) => ({
          id: row.id || "",
          beneficiary_name: row.beneficiary_name || "",
          gpt_name: row.gpt_name || "",
          house_status: row.house_status || "",
          akher: row.akher || "",
          comment: row.comment,
          uploaded_photo: row.uploaded_photo || "",
          uploaded_doc: row.uploaded_doc || "",
        })),
      };
      console.log("updateGharkul finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  // store data in draft
  const handleGharkulDraft = async (updatedRows) => {
    // e.preventDefault();
    // setIsLoading(true);

    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow1(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }

    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        type: type || "प्रधानमंत्री आवास योजना",
        gharkul_data: rows.map((row) => ({
          id: row.id || "",
          beneficiary_name: row.beneficiary_name || "",
          gpt_name: row.gpt_name || "",
          house_status: row.house_status || "",
          akher: row.akher || "",
          comment: row.comment,
          uploaded_photo: row.uploaded_photo || "",
          uploaded_doc: row.uploaded_doc || "",
        })),
      };
      console.log("finalData in grarkul draft", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error(
          "Data property not found in API response:",
          response.data
        );
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // delete entry
  const handleDelete = async (id) => {
    let confirmResult = window.confirm("Are you sure want to delete these?");
    if (!confirmResult) {
      return;
    }
    try {
      console.log("Deleting entry with id:", id);
      const finalData = {
        id: id,
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/delete_gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
        const filteredData = rows.filter((item) => item.id !== id);
        setRows(filteredData);
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleRemoveImage = (index) => {
    // Make a copy of your data
    const updatedData = [...rows];

    // Update the completation_file property to null or an empty string
    updatedData[index] = {
      ...updatedData[index],
      submit_img: null, // or '', depending on your data structure
    };

    // Update your state with the modified data
    setRows(updatedData);
  };

  const handleRemoveDocument = (index) => {
    // Make a copy of your data
    const updatedData = [...rows];

    // Update the completation_file property to null or an empty string
    updatedData[index] = {
      ...updatedData[index],
      submit_doc: null, // or '', depending on your data structure
    };

    // Update your state with the modified data
    setRows(updatedData);
  };

  //  filter dropdown
  useEffect(() => {
    if (filter === "") {
      // If the filter is empty, reset to original rows
      setFilteredRows(rows);
    } else {
      // Otherwise, filter based on the filter value
      const filteredData = rows.filter((item) => item.house_status === filter);
      setFilteredRows(filteredData);
    }
  }, [filter, rows]);
  

  // add child in table
  const renderRows = () => {
    return filteredRows.length <= 0 ? (
      <tr>
        <td colSpan={8} className="text-center fs-5">
          no data found, please insert data in table
        </td>
      </tr>
    ) : (
      filteredRows.map((rowData, index) => (
        <tr key={index}>
          <td>
            <input
              type="number"
              name="id"
              value={index + 1}
              onChange={(e) => handleInputChange(e, index)}
              className="form-control table-input-box"
              readOnly
            />
          </td>
          <td>
            <input
              type="text"
              name="beneficiary_name"
              value={rowData.beneficiary_name || ""}
              onChange={(e) => handleInputChange(e, index)}
              style={{ width: "30vh" }}
              // placeholder="लाभार्थ्याचे नाव"
              className="form-control table-input-box"
              readOnly
            />
          </td>
          <td>
            <input
              type="text"
              name="gpt_name"
              value={rowData.gpt_name || ""}
              onChange={(e) => handleInputChange(e, index)}
              // placeholder="ग्रा.पं. नाव"
              className="form-control table-input-box"
              readOnly
            />
          </td>
          <td>
            {/* <input
              type="text"
              name="house_status"
              value={rowData.house_status || ""}
              onChange={(e) => handleInputChange(e, index)}
              placeholder={
                validationErrors[index]?.house_status
                  ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                  : ""
              }
            /> */}
            <select
              name="house_status"
              value={rowData.house_status || ""}
              onChange={(e) => handleInputChange(e, index)}
              className={`form-control pb-0 table-input-box ${
                validationErrors[index]?.house_status ? "is-invalid" : ""
              }`}
              title="कृपया स्थिती निवडा"
              style={{
                width: "8rem",
                appearance: validationErrors[index]?.house_status
                  ? "none"
                  : "auto",
              }}
              autoFocus
            >
              {" "}
              <option value="">स्थिती निवडा</option>{" "}
              <option value="पाया">पाया</option>{" "}
              <option value="जोते लेवल">जोते लेवल</option>{" "}
              <option value="छत लेवल">छत लेवल</option>{" "}
              <option value="लिंटल लेवल">लिंटल लेवल</option>{" "}
              <option value="काम पूर्ण">काम पूर्ण</option>{" "}
              <option value="काम अपूर्ण">काम अपूर्ण</option>{" "}
              <option value="काम सुरू नाही">काम सुरू नाही </option>{" "}
            </select>
          </td>
          <td>
            <input
              type="date"
              name="akher"
              value={rowData.akher || ""}
              title="कृपया अयोग्य दिनांक टाका"
              onChange={(e) => handleInputChange(e, index)}
              max={getCurrentDate()}
              className={`form-control table-input-box ${
                validationErrors[index]?.akher ? "is-invalid" : ""
              }`}
            />
          </td>
          <td className="position-relative">
            {/* {rowData.uploaded_photo ? (
              <Link to="" className="text-decoration-underline">
                View
              </Link>
            ) : (
              <input
                type="file"
                name="uploaded_photo"
                title="कृपया फोटो अपलोड करा"
                onChange={(e) => handleInputChange(e, index)}
                className="form-control "
              />
            )} */}

            {rowData.submit_img ? (
              <>
                <div className="table_img_link">
                  <div className="table_img_height_width">
                    <div className="remove_icon_of_draft_image">
                      <MdCancel
                        className="text-danger"
                        onClick={() => handleRemoveImage(index)}
                      />
                    </div>
                    <a
                      href={rowData.submit_img}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={rowData.submit_img} alt="" />
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <input
                type="file"
                name="uploaded_photo"
                onChange={(e) => handlePhotoInputChange(e, index)}
                className="form-control table-input-box"
              />
            )}
          </td>
          <td className="position-relative pt-0 pb-2">
            {rowData.submit_doc ? (
              <>
                <div className="table_img_link">
                  <div className="table_img_height_width">
                    <div className="remove_icon_of_draft_image">
                      <MdCancel
                        className="text-danger"
                        onClick={() => handleRemoveDocument(index)}
                      />
                    </div>
                    <a
                      href={rowData.submit_doc}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={rowData.submit_doc} alt="" />
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <input
                type="file"
                name="uploaded_doc"
                onChange={(e) => handleFileInputChange(e, index)}
                className="form-control table-input-box"
              />
            )}
          </td>
          <td>
            <input
              type="text"
              name="comment"
              value={rowData.comment || ""}
              onChange={(e) => handleInputChange(e, index)}
              title="कृपया केवळ अक्षरे प्रविष्ट करा"
              placeholder={
                validationErrors[index]?.comment
                  ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                  : ""
              }
              className={`form-control table-input-box ${
                validationErrors[index]?.comment ? "is-invalid" : ""
              }`}
            />
          </td>
          {/* <td>
            <button
              type="submit"
              onClick={() => rowData.id && handleDelete(rowData.id)}
              className="table_delete_btn"
            >
              <AiFillDelete />
            </button>
          </td> */}
        </tr>
      ))
    );
  };
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>घरकुल विभाग</title>
        </Helmet>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container common-section">
            <div className="row">
              <header className="mt-5 col-12 ">
                <h5 className="d-flex justify-content-start gap-3 mb-4">
                  घरकुल विभाग (लाभार्थी निहाय अहवाल ){" "}
                  {economicData === "Y" ? (
                    <span className="text-danger">is Drafted</span>
                  ) : economicData === "N" ? (
                    <span className="table_title_top gap-0 submit_text1s ">
                      Submitted{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </h5>
                <div className="date_gharkul d-flex justify-content-end">
                  <div className="d-flex align-items-center gap-3">
                    <div className="VillagePanchayat d-flex gap-3 justify-content-center align-items-center">
                      <label className="" htmlFor="">
                        प्रकार :
                      </label>
                      {type === undefined ? (
                        <span></span>
                      ) : (
                        <select
                          name="type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          className="fw-bold "
                        >
                          <option value="प्रधानमंत्री आवास योजना">
                            प्रधानमंत्री आवास योजना
                          </option>
                          <option value="रमाई आवास योजना">
                            रमाई आवास योजना
                          </option>
                        </select>
                      )}
                      <label className="" htmlFor="">
                        फिल्टर :
                      </label>
                      <select
                        name="filter"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="fw-bold "
                      >
                        <option value="">सर्व</option>{" "}
                        <option value="पाया">पाया</option>{" "}
                        <option value="जोते लेवल">जोते लेवल</option>{" "}
                        <option value="छत लेवल">छत लेवल</option>{" "}
                        <option value="लिंटल लेवल">लिंटल लेवल</option>{" "}
                        <option value="काम पूर्ण">काम पूर्ण</option>{" "}
                        <option value="काम अपूर्ण">काम अपूर्ण</option>{" "}
                        <option value="काम सुरू नाही">काम सुरू नाही </option>
                      </select>

                      <label htmlFor="">माहे :</label>
                      {month === undefined ? (
                        <span></span>
                      ) : (
                        <select
                          name="month"
                          value={month}
                          onChange={(e) => setMonth(e.target.value)}
                          className="fw-bold"
                        >
                          <option value="1">जानेवारी</option>
                          <option value="2">फेब्रुवारी</option>
                          <option value="3">मार्च</option>
                          <option value="4">एप्रिल</option>
                          <option value="5">मे</option>
                          <option value="6">जून</option>
                          <option value="7">जुलै</option>
                          <option value="8">ऑगस्ट</option>
                          <option value="9">सप्टेंबर</option>
                          <option value="10">ऑक्टोंबर</option>
                          <option value="11">नोव्हेंबर</option>
                          <option value="12">डिसेंबर</option>
                        </select>
                      )}
                    </div>
                  </div>
                  <div></div>
                </div>
              </header>
              <div className="table-responsive mb-3 mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" style={{width:"5rem"}}>अ.क्र.</th>
                      <th scope="col">लाभार्थ्याचे नाव</th>
                      <th scope="col">ग्रा.पं. नाव</th>
                      <th scope="col">घरकुल स्थिती</th>
                      <th scope="col">
                        {selectDate && formatDate(selectDate)} अखेर
                      </th>
                      <th scope="col">अपलोड फोटो</th>
                      <th scope="col"> अपलोड डॉक्युमेंट </th>
                      <th scope="col">शेरा </th>
                      {/* <th scope="col">Action </th> */}
                    </tr>
                  </thead>
                  <tbody>{renderRows()}</tbody>
                  {/* <div className="plus-icon">
                    <img
                      src={pulseIcon}
                      width={50}
                      className="mt-3"
                      alt="plusIcon"
                      onClick={handleAddRow}
                    />
                  </div> */}
                </table>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
                {month === currentMonth.toString() && (
                  <div className="col-lg-12 d-flex justify-content-end align-items=center">
                    <FormBtn
                      handleSubmit={handleUpdateButtonClick}
                      handleDraft={handleGharkulDraft}
                      resetForm={handleDataReset}
                      resetButton={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <ToastContainer
              position={"top-right"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

export default Gharkul;
