/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./RecoveryReport.css";
import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Loader from "../../component/Loader/Loader";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const RecoveryReport_B = () => {
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [isDraft, setIsDraft] = useState("");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [isAlertVisible, setAlertVisible] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //first row
  const [Samanyapast, setSamanyapast] = useState("");
  const [Samanyapresant, setSamanyapresant] = useState("");
  const [Samanyapaspre, setSamanypaspre] = useState("");
  const [Samanypasmonth, setSamanyapasmonth] = useState("");
  const [Samanyapremonth, setSamanyapremonth] = useState("");
  const [Samanytotal, setSamanyatotal] = useState("");
  const [Samanypercentage, setSamanyapercentage] = useState("");

  //second row
  const [Vishespast, setVishespast] = useState("");
  const [Vishespresant, setVishespresant] = useState("");
  const [Vishespaspre, setVishespaspre] = useState("");
  const [Vishespasmonth, setVishespasmonth] = useState("");
  const [Vishespremonth, setVishespremonth] = useState("");
  const [Vishestotal, setVishestotal] = useState("");
  const [Vishespercentage, setVishespercentage] = useState("");

  //third row
  const [TotalPast, SetTotalPast] = useState("");
  const [TotalPresant, SetTotalPresant] = useState("");
  const [TotalPasPre, SetTotalPasPre] = useState("");
  const [TotalPasMonth, SetTotalPasMonth] = useState("");
  const [TotalPreMonth, SetTotalPreMonth] = useState("");
  const [TotalAll, SetTotalAll] = useState("");
  const [TotalPercntageAll, SetTotalPercntageAll] = useState("");

  // get data session
  const userDataInfo = sessionStorage.getItem("info");
  const userData = JSON.parse(userDataInfo);
  const userDetails = userData.user_details;

  useEffect(() => {
    calcualate();
  }, [Samanyapast, Samanyapresant]);

  const calcualate = () => {
    const num1 = parseFloat(Samanyapast) || 0;
    const num2 = parseFloat(Samanyapresant) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setSamanypaspre("");
    } else {
      const sum = num1 + num2;
      setSamanypaspre(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculation();
  }, [Samanypasmonth, Samanyapremonth]);

  const Calculation = () => {
    const num1 = parseFloat(Samanypasmonth) || 0;
    const num2 = parseFloat(Samanyapremonth) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setSamanyatotal("");
    } else {
      const sum = num1 + num2;
      setSamanyatotal(Math.round(sum));
    }
  };

  useEffect(() => {
    calculatePercentage();
  }, [Samanyapaspre, Samanytotal]);

  const calculatePercentage = () => {
    const num1 = parseInt(Samanyapaspre) || 0;
    const num2 = parseInt(Samanytotal) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setSamanyapercentage("");
    } else {
      const sum = (num2 / num1) * 100;
      setSamanyapercentage(sum.toFixed(2));
    }
  };

  useEffect(() => {
    calculate1();
  }, [Vishespast, Vishespresant]);

  const calculate1 = () => {
    const num1 = parseFloat(Vishespast) || 0;
    const num2 = parseFloat(Vishespresant) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setVishespaspre("");
    } else {
      const sum = num1 + num2;
      setVishespaspre(Math.round(sum));
    }
  };

  useEffect(() => {
    caluclatepercentage1();
  }, [Vishespasmonth, Vishespremonth]);

  const caluclatepercentage1 = () => {
    const num1 = parseFloat(Vishespasmonth) || 0;
    const num2 = parseFloat(Vishespremonth) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setVishestotal("");
    } else {
      const sum = num1 + num2;
      setVishestotal(Math.round(sum));
    }
  };

  useEffect(() => {
    calciPecentage();
  }, [Vishespaspre, Vishestotal]);

  const calciPecentage = () => {
    const num1 = parseInt(Vishespaspre) || 0;
    const num2 = parseInt(Vishestotal) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setVishespercentage("");
    } else {
      const sum = (num2 / num1) * 100;
      setVishespercentage(sum.toFixed(2));
    }
  };

  useEffect(() => {
    Calculate2();
  }, [Samanyapast, Vishespast]);

  const Calculate2 = () => {
    const num1 = parseFloat(Samanyapast) || 0;
    const num2 = parseFloat(Vishespast) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      SetTotalPast("");
    } else {
      const sum = num1 + num2;
      SetTotalPast(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate3();
  }, [Samanyapresant, Vishespresant]);

  const Calculate3 = () => {
    const num1 = parseFloat(Samanyapresant) || 0;
    const num2 = parseFloat(Vishespresant) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      SetTotalPresant("");
    } else {
      const sum = num1 + num2;
      SetTotalPresant(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate4();
  }, [Samanyapaspre, Vishespaspre]);

  const Calculate4 = () => {
    const num1 = parseFloat(Samanyapaspre) || 0;
    const num2 = parseFloat(Vishespaspre) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      SetTotalPasPre("");
    } else {
      const sum = num1 + num2;
      SetTotalPasPre(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate5();
  }, [Samanypasmonth, Vishespasmonth]);

  const Calculate5 = () => {
    const num1 = parseFloat(Samanypasmonth) || 0;
    const num2 = parseFloat(Vishespasmonth) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      SetTotalPasMonth("");
    } else {
      const sum = num1 + num2;
      SetTotalPasMonth(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate6();
  }, [Samanyapremonth, Vishespremonth]);

  const Calculate6 = () => {
    const num1 = parseFloat(Samanyapremonth) || 0;
    const num2 = parseFloat(Vishespremonth) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      SetTotalPreMonth("");
    } else {
      const sum = num1 + num2;
      SetTotalPreMonth(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate7();
  }, [Samanytotal, Vishestotal]);

  const Calculate7 = () => {
    const num1 = parseFloat(Samanytotal) || 0;
    const num2 = parseFloat(Vishestotal) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      SetTotalAll("");
    } else {
      const sum = num1 + num2;
      SetTotalAll(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate8();
  }, [TotalPasPre, TotalAll]);

  const Calculate8 = () => {
    const num1 = parseInt(TotalPasPre) || 0;
    const num2 = parseInt(TotalAll) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      SetTotalPercntageAll("");
    } else {
      const sum = (num2 / num1) * 100;
      SetTotalPercntageAll(sum.toFixed(2));
    }
  };

  // get data on server
  const handleRecoveryData = async () => {
    const finalData = {
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      year_id: userDetails.current_year_id,
      month: month,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/recovery_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("revoery_b data", response.data.data);
      if (
        response.data.data.economic_b_data === null ||
        response.data.data.economic_b_data.length === 0
      ) {
        setAlertVisible(true);
        setIsLoading(false);
      }
      
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      if (
        response.data.data.recovery_b_data === null ||
        response.data.data.recovery_b_data.length === 0
      ) {
        setIsDraft("");
      } else {
        setIsDraft(response.data.data.recovery_b_data[0].is_draft);
      }

      setData(response.data.data.recovery_b_data);
      setMonth(response.data.data.month);
      // samanya value
      setSamanyapast(response.data.data.economic_b_data[0].past_due);
      setSamanyapresant(response.data.data.economic_b_data[0].current_demand);
      setSamanypaspre(response.data.data.economic_b_data[0].total_demand);

      // vishesh value
      setVishespast(response.data.data.economic_b_data[1].past_due);
      setVishespresant(response.data.data.economic_b_data[1].current_demand);
      setVishespaspre(response.data.data.economic_b_data[1].total_demand);

      // last month recovery
      setSamanyapasmonth(
        response.data.data.last_month_total[0].last_month_recovery
      );
      setVishespasmonth(
        response.data.data.last_month_total[1].last_month_recovery
      );

      // current month recovery
      if (
        response.data.data.recovery_b_data !== null &&
        response.data.data.recovery_b_data.length > 0
      ) {
        setSamanyapremonth(
          response.data.data.recovery_b_data[0].current_month_recovery
        );
        setVishespremonth(
          response.data.data.recovery_b_data[1].current_month_recovery
        );
      } else {
        setSamanyapremonth(0);
        setVishespremonth(0);
      }
      setIsLoading(false);
      // setSamanyatotal(response.data.data.recovery_b_data[0].total_recovery);
      // setSamanyapercentage(response.data.data.recovery_b_data[0].percentage);
      // setVishestotal(response.data.data.recovery_b_data[1].total_recovery);
      //  setVishespercentage(response.data.data.recovery_b_data[1].percentage);
    } catch (error) {
      console.log("error occured fetch data on server", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleRecoveryData();
  }, [month]);

  const handleDataRefresh = async () => {
    await handleRecoveryData();
  };

  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleRecoveryData();
    } else {
      setSamanyapast("");
      setSamanyapresant("");
      setSamanyapasmonth("");
      setSamanyapremonth("");
      setVishespast("");
      setVishespresant("");
      setVishespasmonth("");
      setVishespremonth("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const SamanyapastRef = useRef(null);
  const SamanyapresantRef = useRef(null);
  const SamanypasmonthRef = useRef(null);
  const SamanyapremonthRef = useRef(null);
  const VishespastRef = useRef(null);
  const VishespresantRef = useRef(null);
  const VishespasmonthRef = useRef(null);
  const VishespremonthRef = useRef(null);

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(Samanyapast)) {
        SamanyapastRef.current.focus();
      } else if (!isNumeric(Samanyapresant)) {
        SamanyapresantRef.current.focus();
      } else if (!isNumeric(Samanypasmonth)) {
        SamanypasmonthRef.current.focus();
      } else if (!isNumeric(Samanyapremonth)) {
        SamanyapremonthRef.current.focus();
      } else if (!isNumeric(Vishespast)) {
        VishespastRef.current.focus();
      } else if (!isNumeric(Vishespresant)) {
        VishespresantRef.current.focus();
      } else if (!isNumeric(Vishespasmonth)) {
        VishespasmonthRef.current.focus();
      } else if (!isNumeric(Vishespremonth)) {
        VishespremonthRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  // before submit check validation of
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(Samanyapast) ||
      !isNumeric(Samanyapresant) ||
      !isNumeric(Samanypasmonth) ||
      !isNumeric(Samanyapremonth) ||
      !isNumeric(Vishespast) ||
      !isNumeric(Vishespresant) ||
      !isNumeric(Vishespasmonth) ||
      !isNumeric(Vishespremonth) ||
      parseFloat(Samanytotal) > parseFloat(Samanyapaspre) ||
      parseFloat(Vishestotal) > parseFloat(Vishespaspre)
    ) {
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      if (validated) {
        await handleUpdateData(e);
      }
    } else {
      handleShow();
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  // updata data on server
  const handleUpdateData = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();
    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      month: month,
      recovery_b_data: [
        {
          type: "General Watershed",
          last_month_recovery: Samanypasmonth,
          current_month_recovery: Samanyapremonth,
          total_recovery: Samanytotal,
          percentage: Samanypercentage,
        },
        {
          type: "Special Watershed",
          last_month_recovery: Vishespasmonth,
          current_month_recovery: Vishespremonth,
          total_recovery: Vishestotal,
          percentage: Vishespercentage,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/update_recovery_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("error occured updata data", error);
    }
  };

  // store data in draft
  const handleStoreEconomic = async (e) => {
    e.preventDefault();
    if (
      (!isNumeric(Samanyapast) && Samanyapast !== "") ||
      (!isNumeric(Samanyapresant) && Samanyapresant !== "") ||
      (!isNumeric(Samanypasmonth) && Samanypasmonth !== "") ||
      (!isNumeric(Samanyapremonth) && Samanyapremonth !== "") ||
      (!isNumeric(Vishespast) && Vishespast !== "") ||
      (!isNumeric(Vishespresant) && Vishespresant !== "") ||
      (!isNumeric(Vishespasmonth) && Vishespasmonth !== "") ||
      (!isNumeric(Vishespremonth) && Vishespremonth !== "") ||
      parseFloat(Samanytotal) > parseFloat(Samanyapaspre) ||
      parseFloat(Vishestotal) > parseFloat(Vishespaspre)
    ) {
      setValidated(true);
      return;
    }
    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      month: month,
      recovery_b_data: [
        {
          type: "General Watershed",
          last_month_recovery: Samanypasmonth,
          current_month_recovery: Samanyapremonth,
          total_recovery: Samanytotal,
          percentage: Samanypercentage,
        },
        {
          type: "Special Watershed",
          last_month_recovery: Vishespasmonth,
          current_month_recovery: Vishespremonth,
          total_recovery: Vishestotal,
          percentage: Vishespercentage,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/draft_recovery_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      setValidated(false);
      setTimeout(() => {
        handleDataRefresh();
      }, 2000);
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Helmet>
              <title>वसुली रीपोर्ट || पाणीपट्टी वसुली </title>
            </Helmet>
            <div className="container common-section pt-0">
              <div className="row">
                <div className="col-lg-12 navigate-section-header mt-3">
                  <div className="page-title">
                    <p className="fw-bold mb-2">१.२ वसुली विभाग</p>
                  </div>
                  <div className="navigate-section d-flex">
                    <div className="arrow-box">
                      <Link className="me-5 text-dark" to="/recovery-a">
                        अ) घरपट्टी वसुली{" "}
                      </Link>
                    </div>
                    <div className="arrow-box-active d-flex flex-column justify-content-center">
                      <Link className="text-decoration-none" to="/recovery-b">
                        ब) पाणीपट्टी (सामान्य व विशेष पाणीपट्टी)
                      </Link>
                      <span className="text-center arrow-icon">
                        <ArrowDropDownIcon fontSize="large" color="primary" />
                      </span>
                    </div>
                    <div className="arrow-box">
                      <Link to="/recovery-c" className="ms-5 text-dark">
                        क) किरकोळ मागणी (पवनचक्की सोडून)
                      </Link>
                    </div>
                    <div className="arrow-box me-5">
                      <Link className="ms-4 text-dark" to="/recovery-d">
                        ड) पवनचक्की/कारखाना वसुली{" "}
                      </Link>
                    </div>
                  </div>
                  {/* <TabEco/> */}
                </div>
                {!isAlertVisible ? (
                  <>
                    <div className="col-lg-12 mt-5 mb-5">
                      <div className="d-flex gap-5">
                        <Village_Tal_Dist_Mahe
                          villageDataInfo={villageDataInfo}
                        />
                        <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                          <label htmlFor="">माहे :</label>
                          {month === undefined ? (
                            <span></span>
                          ) : (
                            <select
                              name="month"
                              value={month}
                              onChange={(e) =>
                                setMonth(e.target.value)
                              }
                              className="fw-bold"
                            >
                              <option value="1">जानेवारी</option>
                              <option value="2">फेब्रुवारी</option>
                              <option value="3">मार्च</option>
                              <option value="4">एप्रिल</option>
                              <option value="5">मे</option>
                              <option value="6">जून</option>
                              <option value="7">जुलै</option>
                              <option value="8">ऑगस्ट</option>
                              <option value="9">सप्टेंबर</option>
                              <option value="10">ऑक्टोंबर</option>
                              <option value="11">नोव्हेंबर</option>
                              <option value="12">डिसेंबर</option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <p>
                      ब) पाणीपट्टी वसूली (सामान्य व विशेष पाणीपट्टी){" "}
                      {isDraft === "Y" ? (
                        <span className="fw-bold text-danger">is Drafted</span>
                      ) : isDraft === "N" ? (
                        <span className="submit_text">
                          Submitted {" "}
                          <img
                            src={require("../../component/assets/images/sumbit-success.png")}
                            alt="success"
                            width={30}
                            className="img-fluid"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <div className="table-responsive mb-3">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">कराचे नाव</th>
                            <th scope="col">
                              मागील थकबाकी <br /> (सन{" "}
                              {yearInfo ? yearInfo.previous_year_name : ""})
                            </th>
                            <th scope="col">
                              चालू मागणी <br /> (सन{" "}
                              {yearInfo ? yearInfo.current_year_name : ""})
                            </th>
                            <th scope="col">
                              एकूण मागणी <br />
                              (मागील + चालू) (सन{" "}
                              {yearInfo ? yearInfo.current_year_name : ""})
                            </th>
                            <th scope="col">
                              {" "}
                              मागील महिनाअखेर <br /> वसूली{" "}
                            </th>
                            <th scope="col">
                              {" "}
                              चालू महिन्यातील <br /> वसूली{" "}
                            </th>
                            <th scope="col"> एकूण वसूली </th>
                            <th scope="col"> टक्केवारी </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>सामान्य</td>
                            <td>
                              <input
                                type="text"
                                value={Samanyapast}
                                onChange={(e) => {
                                  setSamanyapast(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Samanyapast)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Samanyapast)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={SamanyapastRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={Samanyapresant}
                                onChange={(e) => {
                                  setSamanyapresant(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Samanyapresant)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Samanyapresant)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={SamanyapresantRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={Samanyapaspre}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={Samanypasmonth}
                                onChange={(e) => {
                                  setSamanyapasmonth(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Samanypasmonth)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Samanypasmonth)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={SamanypasmonthRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={Samanyapremonth}
                                onChange={(e) => {
                                  setSamanyapremonth(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Samanyapremonth)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Samanyapremonth)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={SamanyapremonthRef}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className={`form-control table-input-box fw-bold ${
                                  validated &&
                                  parseFloat(Samanytotal) >
                                    parseFloat(Samanyapaspre)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={Samanytotal}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className={`form-control table-input-box fw-bold ${
                                  validated &&
                                  parseFloat(Samanytotal) >
                                    parseFloat(Samanyapaspre)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={Samanypercentage}
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>विशेष</td>
                            <td>
                              <input
                                type="text"
                                value={Vishespast}
                                onChange={(e) => {
                                  setVishespast(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Vishespast)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Vishespast)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={VishespastRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={Vishespresant}
                                onChange={(e) => {
                                  setVishespresant(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Vishespresant)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Vishespresant)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={VishespresantRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={Vishespaspre}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={Vishespasmonth}
                                onChange={(e) => {
                                  setVishespasmonth(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Vishespasmonth)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Vishespasmonth)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={VishespasmonthRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={Vishespremonth}
                                onChange={(e) => {
                                  setVishespremonth(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(Vishespremonth)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(Vishespremonth)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={VishespremonthRef}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className={`form-control table-input-box fw-bold ${
                                  validated &&
                                  parseFloat(Vishestotal) >
                                    parseFloat(Vishespaspre)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={Vishestotal}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className={`form-control table-input-box fw-bold ${
                                  validated &&
                                  parseFloat(Vishestotal) >
                                    parseFloat(Vishespaspre)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={Vishespercentage}
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>एकूण</td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={TotalPast}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={TotalPresant}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={TotalPasPre}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={TotalPasMonth}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={TotalPreMonth}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={TotalAll}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={TotalPercntageAll}
                                readOnly
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {validated &&
                        (parseFloat(Samanytotal) > parseFloat(Samanyapaspre) ||
                        parseFloat(Vishestotal) > parseFloat(Vishespaspre) ? (
                          <span className="text-danger">
                            सूचना :- कृपया एकूण वसूली ही एकूण मागणी पेक्षा कमी
                            किवा समान असावी.
                          </span>
                        ) : (
                          ""
                        ))}
                    </div>
                    <div className="col-lg-12">
                      <FooterDevOfficer villageDataInfo={villageDataInfo} />
                    </div>
                    <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
                      {(month === currentMonth.toString() && !isLoading) &&
                        (isDraft === "" || isDraft === "Y") && (
                          <div className="col-lg-12 d-flex justify-content-end align-items=center">
                            <FormBtn
                              resetForm={handleDataRefreshReset}
                              handleSubmit={handleFormSubmission}
                              handleDraft={handleStoreEconomic}
                            />
                          </div>
                        )}
                    </div>
                    {isDraft === "N" && (
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p className="text-danger">
                            सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                            विनंती करा.{" "}
                          </p>
                          <div>
                            <Button variant="contained">विनंती करा</Button>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {" "}
                          सूचना <MdOutlineArrowDownward />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                        Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म
                        Submit करू इच्छिता?
                      </Modal.Body>
                      <Modal.Footer className="gap-3">
                        <Button
                          className="modal-button-cancel"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="modal-button-submit"
                          onClick={handleUpdateData}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : (
                  <section
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <div className="alert_pop_up_common">
                            <h4 className="alert_pop_up_common_title">
                              आर्थिक वर्ष मागणी
                            </h4>
                            <p className="alert_pop_up_common_des">
                              {" "}
                              कृपया आर्थिक वर्ष मागणी ग्रामपंचायत करमागणी
                              पाणीपट्टी वसुली भरा.
                            </p>
                            <Link className="alert_submit_btn" to="/eco-b">
                              आता पूर्ण करा
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
            <ToastContainer
              position={"top-center"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </>
        )}
      </>
    </React.Fragment>
  );
};

export default RecoveryReport_B;
