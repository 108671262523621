/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "../EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import FormBtn from "../../../component/buttons/Form/FormBtn";
import Village_Tal_Dist from "../../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import Loader from "../../../component/Loader/Loader";
import style from "./style.module.css";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const DistrictEconomics = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(currentDate);
  const isSpecificPage = true;
  const [totalsLoaded, setTotalsLoaded] = useState(false);
  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");
  //   economics a data
  const [propertyData, setPropertyData] = useState("");
  const [padsarData, setPadasarData] = useState("");
  const [lampData, setLampData] = useState("");
  const [healthData, setHealthData] = useState("");
  const [options, setOptions] = useState([]);
  const [defaultVillageValue, setDefaultVillageValue] = useState(null);
  // economics b data
  const [generalData, setGeneralData] = useState("");
  const [specialData, setSpecialData] = useState("");

  //   economics c data
  const [minorData, setMinorData] = useState("");

  //   economics d data
  const [windmillFactoryData, setWindmillFactoryData] = useState("");

  //   economics d data
  const [expenseProvisionData, setExpenseProvisionData] = useState("");

  // get data on the session storage
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  // आर्थिक वर्ष मागणी ( ग्रामपंचायत करमागणी) api

  useEffect(() => {
    handleEconomicsYearDetails();
  }, [talId, villageId]);

  useEffect(() => {
    // Check if all required data is available before setting totalsLoaded to true
    if (
      propertyData &&
      padsarData &&
      lampData &&
      healthData &&
      generalData &&
      specialData &&
      minorData &&
      windmillFactoryData &&
      expenseProvisionData
    ) {
      setTotalsLoaded(true);
    }
  }, [
    propertyData,
    padsarData,
    lampData,
    healthData,
    generalData,
    specialData,
    minorData,
    windmillFactoryData,
    expenseProvisionData,
  ]);

  const handleEconomicsYearDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/summary_economic",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("economic data", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);

      //   economic a data
      setPropertyData(response.data.data.economic_a_data[0]);
      setPadasarData(response.data.data.economic_a_data[1]);
      setLampData(response.data.data.economic_a_data[2]);
      setHealthData(response.data.data.economic_a_data[3]);

      //   economic b data
      setGeneralData(response.data.data.economic_b_data[0]);
      setSpecialData(response.data.data.economic_b_data[1]);

      //  economic c data
      setMinorData(response.data.data.economic_c_data[0]);

      //   economic d data
      setWindmillFactoryData(response.data.data.economic_d_data[0]);

      //   economic d data
      setExpenseProvisionData(response.data.data.economic_e_data[0]);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // economics a  total due
  const TotalDue =
    parseFloat(propertyData?.past_due || 0) +
    parseFloat(padsarData?.past_due || 0) +
    parseFloat(lampData?.past_due || 0) +
    parseFloat(healthData?.past_due || 0);

  // economics a  total current demand
  const TotalCurrentDemand =
    parseFloat(propertyData?.current_demand || 0) +
    parseFloat(padsarData?.current_demand || 0) +
    parseFloat(lampData?.current_demand || 0) +
    parseFloat(healthData?.current_demand || 0);

  // economics a  total demand
  const TotalDemand =
    parseFloat(propertyData?.total_demand || 0) +
    parseFloat(padsarData?.total_demand || 0) +
    parseFloat(lampData?.total_demand || 0) +
    parseFloat(healthData?.total_demand || 0);

  //   economics b total pastDue
  const EconomyBTotalPastDue =
    parseFloat(generalData?.past_due || 0) +
    parseFloat(specialData?.past_due || 0);

  //   economics b total currentDemand
  const EconomyBTotalCurrentDemand =
    parseFloat(generalData?.current_demand) +
    parseFloat(specialData?.current_demand);

  // economics b total demand
  const EconomyBTotalDemand =
    parseFloat(generalData?.total_demand || 0) +
    parseFloat(specialData?.total_demand || 0);

  // const handleTal = async (selectedOption) => {
  //   if (selectedOption && selectedOption.value) {
  //     setTal(selectedOption.value);
  //     setTalId(selectedOption.id);
  //     setVillage("");
  //     setVillageId("");
  //     villageData(selectedOption.id, "");

  //     // console.log("villageData : " + villageData);
  //   } else {
  //     setTal(""); // Set tal to an empty string when no taluka is selected
  //     setTalId("");
  //     setVillage(""); // Reset the selected village when taluka changes
  //     setVillageId("");
  //   }
  // };

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;

    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected

    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id:
          userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("village finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>
              ग्रामपंचायत करमागणी मालमत्ता कर, पडसर कर, दिवाबत्ती कर व आरोग्य कर
            </title>
            {/* Conditionally set the viewport scale to 0.5 */}
            {isSpecificPage && (
              <meta
                name="viewport"
                content="width=device-width, initial-scale=0.5, maximum-scale=1.0"
              />
            )}
          </Helmet>
          <div className="container common-section pt-0">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">
                    १.१ आर्थिक वर्ष मागणी (वार्षिक)
                  </p>
                </div>

                <div className="navigate-section d-flex">
                  <div className=" d-flex flex-column justify-content-center">
                    <h6 className="mb-0">
                      जिल्हा: {villageDataInfo.district_name}
                    </h6>
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-5">
                    <div className={`${style.select_group_top}`}>
                      <label htmlFor="">तालुका: </label>
                      <select
                        name="taluka"
                        className="taluka_select_input"
                        onChange={(e) => handleOnchangeTaluka(e, talId)}
                        value={talId}
                      >
                        {userDetails.role_id === "7" ? (
                          <option>निवडा</option>
                        ) : (
                          ""
                        )}
                        {tal.map((ele) => {
                          return (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-5">
                    <div className={`${style.select_group_top}`}>
                      <label htmlFor="">ग्रामपंचयात: </label>
                      <Autocomplete
                        disablePortal
                        className={`${style.select_common_dropdown}`}
                        options={village}
                        sx={{ width: 200 }}
                        onChange={(e, selectedOption) =>
                          handleVillage(selectedOption)
                        }
                        value={
                          villageId
                            ? village.find((v) => v.value === villageId) || null
                            : null
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="निवडा" />
                        )}
                      />
                    </div>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="col-lg-5 mt-4 mb-4">
                {/* <Village_Tal_Dist villageDataInfo={villageDataInfo} /> */}
              </div>
              <div className="col-lg-12">
                <div className="table-main-sec">
                  <div className="table_title_top">
                    <p>
                      अ) ग्रामपंचायत करमागणी मालमत्ता कर, पडसर कर, दिवाबत्ती कर
                      व आरोग्य कर
                    </p>
                  </div>

                  <div className="table-responsive">
                    <form action="">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">कराचे नाव</th>
                            <th scope="col">
                              मागील थकबाकी <br /> (सन{" "}
                              {yearInfo.previous_year_name})
                            </th>
                            <th scope="col">
                              चालू मागणी <br /> (सन {yearInfo.current_year_name}
                              )
                            </th>
                            <th scope="col">
                              एकूण मागणी <br />
                              (मागील + चालू) (सन {yearInfo.current_year_name})
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>मालमत्ता कर</td>
                            <td>
                              {totalsLoaded && propertyData
                                ? propertyData.past_due
                                : ""}
                            </td>
                            <td>
                              {totalsLoaded && propertyData
                                ? propertyData.current_demand
                                : ""}
                            </td>
                            <td className="fw-bold">
                              {totalsLoaded && propertyData
                                ? propertyData.total_demand
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>पडसर कर</td>
                            <td>
                              {totalsLoaded && padsarData
                                ? padsarData.past_due
                                : ""}
                            </td>
                            <td>
                              {totalsLoaded && padsarData
                                ? padsarData.current_demand
                                : ""}
                            </td>
                            <td className="fw-bold">
                              {totalsLoaded && padsarData
                                ? padsarData.total_demand
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>दिवाबत्ती कर</td>
                            <td>
                              {totalsLoaded && lampData
                                ? lampData.past_due
                                : ""}
                            </td>
                            <td>
                              {totalsLoaded && lampData
                                ? lampData.current_demand
                                : ""}
                            </td>
                            <td className="fw-bold">
                              {totalsLoaded && lampData
                                ? lampData.total_demand
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>आरोग्य कर</td>
                            <td>
                              {totalsLoaded && healthData
                                ? healthData.past_due
                                : ""}
                            </td>
                            <td>
                              {totalsLoaded && healthData
                                ? healthData.current_demand
                                : ""}
                            </td>
                            <td className="fw-bold">
                              {totalsLoaded && healthData
                                ? healthData.total_demand
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <th>एकूण</th>
                            <td className="fw-bold">
                              {TotalDue ? TotalDue : ""}
                            </td>
                            <td className="fw-bold">
                              {TotalCurrentDemand ? TotalCurrentDemand : ""}
                            </td>
                            <td className="fw-bold">
                              {TotalDemand ? TotalDemand : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>

                  <div className="table_title_top  mt-3">
                    <p>ब) पाणीपट्टी (सामान्य व विशेष पाणीपट्टी)</p>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">कराचे नाव</th>
                          <th scope="col">
                            मागील थकबाकी <br /> (सन{" "}
                            {yearInfo.previous_year_name})
                          </th>
                          <th scope="col">
                            चालू मागणी <br /> (सन {yearInfo.current_year_name})
                          </th>
                          <th scope="col">
                            एकूण मागणी <br />
                            (मागील + चालू) (सन {yearInfo.current_year_name})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>सामान्य पाणीपट्टी</td>
                          <td>
                            {totalsLoaded && generalData
                              ? generalData.past_due
                              : ""}
                          </td>
                          <td>
                            {totalsLoaded && generalData
                              ? generalData.current_demand
                              : ""}
                          </td>
                          <td className="fw-bold">
                            {totalsLoaded && generalData
                              ? generalData.total_demand
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>विशेष पाणीपट्टी</td>
                          <td>
                            {totalsLoaded && specialData
                              ? specialData.past_due
                              : ""}
                          </td>
                          <td>
                            {totalsLoaded && specialData
                              ? specialData.current_demand
                              : ""}
                          </td>
                          <td className="fw-bold">
                            {totalsLoaded && specialData
                              ? specialData.total_demand
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <th>एकूण</th>
                          <td className="fw-bold">
                            {EconomyBTotalPastDue ? EconomyBTotalPastDue : ""}
                          </td>
                          <td className="fw-bold">
                            {EconomyBTotalCurrentDemand
                              ? EconomyBTotalCurrentDemand
                              : ""}
                          </td>
                          <td className="fw-bold">
                            {EconomyBTotalDemand ? EconomyBTotalDemand : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="table_title_top  mt-3">
                    <p>क) किरकोळ मागणी </p>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">
                            मागील थकबाकी <br /> (सन{" "}
                            {yearInfo.previous_year_name})
                          </th>
                          <th scope="col">
                            चालू मागणी <br /> (सन {yearInfo.current_year_name})
                          </th>
                          <th scope="col">
                            एकूण मागणी <br />
                            (मागील + चालू) (सन {yearInfo.current_year_name})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {totalsLoaded && minorData
                              ? minorData.past_due
                              : ""}
                          </td>
                          <td>
                            {totalsLoaded && minorData
                              ? minorData.current_demand
                              : ""}
                          </td>
                          <td className="fw-bold">
                            {totalsLoaded && minorData
                              ? minorData.total_demand
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* ड) पवनचक्की / कारखाना मागणी */}

                  <div className="table_title_top  mt-3">
                    <p>ड) पवनचक्की / कारखाना मागणी</p>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">अ.क्र.</th>
                          <th scope="col">
                            मागील थकबाकी <br /> (सन{" "}
                            {yearInfo.previous_year_name})
                          </th>
                          <th scope="col">
                            (सन {yearInfo.current_year_name}) ची <br /> चालू
                            मागणी
                          </th>
                          <th scope="col">
                            एकूण मागणी (सन {yearInfo.current_year_name}) <br />
                            (मागील व चालू)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            {totalsLoaded && windmillFactoryData
                              ? windmillFactoryData.past_due
                              : ""}
                          </td>
                          <td>
                            {totalsLoaded && windmillFactoryData
                              ? windmillFactoryData.current_demand
                              : ""}
                          </td>
                          <td className="fw-bold">
                            {totalsLoaded && windmillFactoryData
                              ? windmillFactoryData.total_demand
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* इ) खर्च तरतूद */}

                  <div className="table_title_top  mt-3">
                    <p>इ) खर्च तरतूद</p>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">अ.क्र.</th>
                          <th scope="col">मागील अनुशेष</th>
                          <th scope="col">
                            चालू तरतूद <br /> (सन {yearInfo.current_year_name})
                          </th>
                          <th scope="col">
                            एकूण तरतूद <br />
                            (मागील + चालू) (सन {yearInfo.current_year_name})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            {totalsLoaded && expenseProvisionData
                              ? expenseProvisionData.backlog
                              : ""}
                          </td>
                          <td>
                            {totalsLoaded && expenseProvisionData
                              ? expenseProvisionData.current_provision
                              : ""}
                          </td>
                          <td className="fw-bold">
                            {totalsLoaded && expenseProvisionData
                              ? expenseProvisionData.total_provision
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DistrictEconomics;
