import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import { MdNotifications } from "react-icons/md";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
const NoticeBoard = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notice, setNotice] = useState([]);
  const [notification, setNotification] = useState([]);
  var userDataInfo = sessionStorage.getItem("info");
  if (userDataInfo !== null) {
    var userInfo = JSON.parse(userDataInfo);
    var userDetails = userInfo.user_details;
  } else {
    console.error("User data not found in sessionStorage");
  }

  const handleNotificationClick = () => {
    setShowNotifications(true);
  };

  // const handleNotificationDoubleClick = () => {
  //   setShowNotifications((prev) => !prev);
  // };

  useEffect(() => {
    getDashBoardData();
    getDashBoardNotification();
  }, []);

  // dashboard notice api
  const getDashBoardData = async () => {
    try {
      const finalData = {
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/dashboard_notice",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("response", response.data.data.notices);
      setNotice(response.data.data.notices);
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  // dashboard notification
  const getDashBoardNotification = async () => {
    try {
      const finalData = {
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/my_notification",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("added notice response", response.data.data);
      setNotification(response.data.data.notifications);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 mt-2 ">
            <div className="card notice-main">
              <div className="notice-page-title mt-3 d-flex justify-content-between">
                <h5 className="notice-heading mb-3">नोटीस बोर्ड</h5>
                <div className="notification-alert d-flex">
                  <Dropdown>
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="navbar-dropdown-profile fw-bold custom-dropdown"
                      title={
                        <div className="notification-alert d-flex">
                          <h5 className="text-primary fw-bold">सूचना </h5>
                          <MdNotifications
                            className="fs-4"
                            style={{ color: "#1284ed" }}
                          />
                          <span className="fw-bold notification-alert-message">
                            १३
                          </span>
                        </div>
                      }
                      onClick={handleNotificationClick}
                    >
                      {showNotifications && (
                        <Dropdown.Item>
                          {notification.map((item) => {
                            return (
                              <>
                                <Link key={item.id} to={`${item.onclick}`}>
                                  {item.message}
                                </Link>
                              </>
                            );
                          })}
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </Dropdown>
                </div>
              </div>
              <div className="card-body notice-body">
                {notice &&
                  notice.map((item, index) => (
                    <Link to={`${item.onclick}`} key={item.id}>
                      {" "}
                      <h6>
                        {index + 1}. {item.message}
                      </h6>{" "}
                    </Link>
                  ))}
                <Link to="/dashboard" className="btn_view">
                  कमी पाहा <MdKeyboardDoubleArrowUp />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;
