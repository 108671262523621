/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "../../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import FormBtn from "../../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Village_Tal_Dist_Mahe from "../../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import pulseIcon from "../../../component/assets/images/logo/plus-removebg-preview.png";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./style.module.css";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";

const Meeting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [districtInfo, setDistrictInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");

  const getCurrentMarathiMonth = () => {
    const marathiMonthNames = [
      'जानेवारी',
      'फेब्रुवारी',
      'मार्च',
      'एप्रिल',
      'मे',
      'जून',
      'जुलै',
      'ऑगस्ट',
      'सप्टेंबर',
      'ऑक्टोबर',
      'नोव्हेंबर',
      'डिसेंबर',
    ];
  
    const currentMonthIndex = new Date().getMonth();
    const currentMarathiMonth = marathiMonthNames[currentMonthIndex];
  
    return currentMarathiMonth;
  };
  
  const currentMonth = getCurrentMarathiMonth();
  const [month, setMonth] = useState(currentMonth);
  
  
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  //  १५% मागासवर्गीय खर्चाची तरतूद  input filed calculation

  const [meetingData, setMeetingData] = useState([]);
  const [expenseBData, setExpenseBData] = useState([]);
  const [expenseCData, setExpenseCData] = useState([]);

  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  useEffect(() => {
    handleMeetingDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talId, villageId, month]);

  const handleMeetingDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        month_marathi_name:month,
        year_id:userDetails.current_year_id
      };
      const response = await axios.post(
        API_BASE_URL + "api/summary_monthly_meeting",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("Meeting Data response", response);
      setMeetingData(response.data.data);
      setYearInfo(response.data.data.year_info);
      setDistrictInfo(response.data.data.other_data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };

  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id: userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>मासिक सभा / ग्रामसभा</title>
      </Helmet>
      <div className="container-fluid common-section common_deputy_container">
        <div className="row common_deputy_row">
          <div className="col-lg-12 navigate-section-header mt-3">
            <div className="page-title">
              <p className="fw-bold mb-2"># ग्रामसभा</p>
            </div>
            <div className="navigate-section d-flex">
              <div className="d-flex flex-column justify-content-center">
                <div className={`${style.select_group_top}`}>
                  <label>जिल्हा: </label>
                  <span className="">{districtInfo.district_name}</span>
                </div>
              </div>
              <div className="VillagePanchayat ms-5 d-flex gap-2 justify-content-center align-items-center">
                <label htmlFor="">माहे :</label>
                {month === undefined ? (
                  <span></span>
                ) : (
                  <select
                    name="month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    className="taluka_select_input"
                  >
                    <option value="जानेवारी">जानेवारी</option>
                    <option value="फेब्रुवारी">फेब्रुवारी</option>
                    <option value="मार्च">मार्च</option>
                    <option value="एप्रिल">एप्रिल</option>
                    <option value="मे">मे</option>
                    <option value="जून">जून</option>
                    <option value="जुलै">जुलै</option>
                    <option value="ऑगस्ट">ऑगस्ट</option>
                    <option value="सप्टेंबर">सप्टेंबर</option>
                    <option value="ऑक्टोंबर">ऑक्टोंबर</option>
                    <option value="नोव्हेंबर">नोव्हेंबर</option>
                    <option value="डिसेंबर">डिसेंबर</option>
                  </select>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">तालुका: </label>
                  <select
                    name="taluka"
                    className="taluka_select_input"
                    onChange={(e) => handleOnchangeTaluka(e, talId)}
                    value={talId}
                  >
                    {userDetails.role_id === "7" ? <option>निवडा</option> : ""}
                    {tal.map((ele) => {
                      return (
                        <option key={ele.id} value={ele.id}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">ग्रामपंचयात: </label>
                  <Autocomplete
                    disablePortal
                    className={`${style.select_common_dropdown}`}
                    options={village}
                    sx={{ width: 200 }}
                    onChange={(e, selectedOption) =>
                      handleVillage(selectedOption)
                    }
                    value={
                      villageId
                        ? village.find((v) => v.value === villageId) || null
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="निवडा" />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {meetingData.level === "District" ||
          meetingData.level === "Taluka" ? (
            <div className="row">
              <h4 className="mt-4">
                Level :- <i>{meetingData.level}</i>{" "}
              </h4>
              <div className="col-lg-6 mt-5">
                <div className="card">
                  <div className="card-body">
                    <h4>मासिक सभा </h4>
                    <h1 className="float-end">
                      {" "}
                      {meetingData.monthly_meeting_count}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5">
                <div className="card">
                  <div className="card-body">
                    <h4>ग्रामसभा</h4>
                    <h1 className="float-end">
                      {" "}
                      {meetingData.gram_sabha_count}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {meetingData.level === "Village" && (
            <>
              {/* month meeting */}

              <div className="col-lg-12 mt-4">
                <div className="table-division d-flex">
                  <div className="col-lg-6">
                    <div className="employeesInFigure table_title_top table-box border d-flex align-items-center">
                      <label>
                        मासिक सभा दिनांक (सन {yearInfo.current_year_name}){" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-1 mb-4">
                <div className="table-main-sec">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">माहे</th>
                          <th scope="col">दिनांक</th>
                        </tr>
                      </thead>
                      <tbody>
                        {meetingData.monthly_meeting_data.length <= 0 ? (
                          <tr>
                            <td>N/A</td>
                            <td>N/A</td>
                          </tr>
                        ) : (
                          meetingData.monthly_meeting_data.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td>{ele.month}</td>
                                <td>{ele.date}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-2">
                <div className="table-division d-flex">
                  <div className="col-lg-6">
                    <div className="employeesInFigure table_title_top table-box border d-flex align-items-center">
                      <label>
                        ग्रामसभा दिनांक (सन {yearInfo.current_year_name}){" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-1 mb-4">
                <div className="table-main-sec">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">माहे</th>
                          <th scope="col">दिनांक</th>
                        </tr>
                      </thead>
                      <tbody>
                        {meetingData.gram_sabha_data.length <= 0 ? (
                          <tr>
                            <td>N/A</td>
                            <td>N/A</td>
                          </tr>
                        ) : (
                          meetingData.gram_sabha_data.map((ele, index) => {
                            return (
                              <tr key={index}>
                                <td>{ele.month}</td>
                                <td>{ele.date}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </React.Fragment>
  );
};

export default Meeting;
