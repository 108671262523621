/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./RecoveryReport.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Loader from "../../component/Loader/Loader";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const RecoveryReport_C = () => {
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [isDraft, setIsDraft] = useState("");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //first row
  const [pastThakBaki, setPastThakBaki] = useState("");
  const [presantThakBaki, setPresantThakBaki] = useState("");
  const [totalPasPre, setTotalPasPre] = useState("");
  const [pasMonth, setPasMonth] = useState("");
  const [preMonth, setPreMonth] = useState("");
  const [totalAll, setTotalAll] = useState("");
  const [totalPercentage, setTotalPercentage] = useState("");
  const [isAlertVisible, setAlertVisible] = useState(false);
  //  get data in session
  const userDataInfo = sessionStorage.getItem("info");
  const userData = JSON.parse(userDataInfo);
  const userDetails = userData.user_details;

  useEffect(() => {
    Calculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastThakBaki, presantThakBaki]);

  const Calculate = () => {
    const num1 = parseFloat(pastThakBaki) || 0;
    const num2 = parseFloat(presantThakBaki) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalPasPre("");
    } else {
      const sum = num1 + num2;
      setTotalPasPre(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pasMonth, preMonth]);

  const Calculate1 = () => {
    const num1 = parseFloat(pasMonth) || 0;
    const num2 = parseFloat(preMonth) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalAll("");
    } else {
      const sum = num1 + num2;
      setTotalAll(Math.round(sum));
    }
  };

  useEffect(() => {
    Calculate3();
  }, [totalPasPre, totalAll]);
  const Calculate3 = () => {
    const num1 = parseFloat(totalPasPre) || 0;
    const num2 = parseFloat(totalAll) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalPercentage("");
    } else {
      const sum = (num2 / num1) * 100;
      setTotalPercentage(sum.toFixed(2));
    }
  };

  // fetch data in server api

  const handleData = async () => {
    const finalData = {
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      year_id: userDetails.current_year_id,
      month: month,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/recovery_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("recovery_c data", response.data.data);
      if (
        response.data.data.economic_c_data === null ||
        response.data.data.economic_c_data.length === 0
      ) {
        setAlertVisible(true);
        setIsLoading(false);
      }
      
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      if (response.data.status === true) {
        if (
          response.data.data.recovery_c_data === null ||
          response.data.data.recovery_c_data.length === 0
        ) {
          setIsDraft("");
        } else {
          setIsDraft(response.data.data.recovery_c_data[0].is_draft);
        }
      }
      setData(response.data.data.recovery_c_data);
      setMonth(response.data.data.month);
      // add data server on input
      setPastThakBaki(response.data.data.economic_c_data[0].past_due);
      setPresantThakBaki(response.data.data.economic_c_data[0].current_demand);
      setTotalPasPre(response.data.data.economic_c_data[0].total_demand);
      if(response.data.data.last_month_total.length.length > 0 ||  response.data.data.last_month_total !== "" ){
      setPasMonth(response.data.data.last_month_total[0].last_month_recovery);
      }else{
        setPasMonth(0);
      }
      // current month recovery
      if (
        response.data.data.recovery_c_data !== null &&
        response.data.data.recovery_c_data.length > 0
      ) {
       
        // current month recovery
        setPreMonth(
          response.data.data.recovery_c_data[0]?.current_month_recovery
        );
      } else {
        setPreMonth(0);
      }
      setIsLoading(false);
      // setTotalAll(response.data.data.recovery_c_data[0].total_recovery);
      // setTotalPercentage(response.data.data.recovery_c_data[0].percentage);
    } catch (error) {
      console.log("error occured fetch data on server", error);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    handleData();
  }, [month]);

  const handleDataRefresh = async () => {
    await handleData();
  };

  // reset the data on server
  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleData();
    } else {
      setPastThakBaki("");
      setPresantThakBaki("");
      setPasMonth("");
      setPreMonth("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const pastThakBakiRef = useRef(null);
  const presantThakBakiRef = useRef(null);
  const pasMonthRef = useRef(null);
  const preMonthRef = useRef(null);

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(pastThakBaki)) {
        pastThakBakiRef.current.focus();
      } else if (!isNumeric(presantThakBaki)) {
        presantThakBakiRef.current.focus();
      } else if (!isNumeric(pasMonth)) {
        pasMonthRef.current.focus();
      } else if (!isNumeric(preMonth)) {
        preMonthRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  // before submit check validation of
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(pastThakBaki) ||
      !isNumeric(presantThakBaki) ||
      !isNumeric(pasMonth) ||
      !isNumeric(preMonth) ||
      parseFloat(totalAll) > parseFloat(totalPasPre)
    ) {
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      if (validated) {
        await handleUpdateData(e);
      }
    } else {
      handleShow();
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  // update data on server
  const handleUpdateData = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();
    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      month: month,
      recovery_c_data: [
        {
          type: "1",
          last_month_recovery: pasMonth,
          current_month_recovery: preMonth,
          total_recovery: totalAll,
          percentage: totalPercentage,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/update_recovery_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("error occured updata data", error);
    }
  };

  // store data in draft
  const handleStoreEconomic = async (e) => {
    e.preventDefault();
    if (
      (!isNumeric(pastThakBaki) && pastThakBaki !== "") ||
      (!isNumeric(presantThakBaki) && presantThakBaki !== "") ||
      (!isNumeric(pasMonth) && pasMonth !== "") ||
      (!isNumeric(preMonth) && preMonth !== "") ||
      parseFloat(totalAll) > parseFloat(totalPasPre)
    ) {
      setValidated(true);
      return;
    }
    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      month: month,
      recovery_c_data: [
        {
          type: "1",
          last_month_recovery: pasMonth,
          current_month_recovery: preMonth,
          total_recovery: totalAll,
          percentage: totalPercentage,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/draft_recovery_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      setValidated(false);
      setTimeout(() => {
        handleDataRefresh();
      }, 2000);
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <React.Fragment>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Helmet>
              <title>वसुली रीपोर्ट || किरकोळ मागणी </title>
            </Helmet>
            <div className="container common-section pt-0">
              <div className="row">
                <div className="col-lg-12 navigate-section-header mt-3">
                  <div className="page-title">
                    <p className="fw-bold mb-2">१.२ वसुली विभाग</p>
                  </div>
                  <div className="navigate-section d-flex">
                    <div className="arrow-box">
                      <Link className="me-5 text-dark" to="/recovery-a">
                        अ) घरपट्टी वसुली{" "}
                      </Link>
                    </div>
                    <div className="arrow-box">
                      <Link className="me-5 text-dark" to="/recovery-b">
                        ब) पाणीपट्टी (सामान्य व विशेष पाणीपट्टी)
                      </Link>
                    </div>
                    <div className="arrow-box-active d-flex flex-column justify-content-center">
                      <Link to="/recovery-c" className="text-decoration-none">
                        क) किरकोळ मागणी (पवनचक्की सोडून)
                      </Link>
                      <span className="text-center me-4 arrow-icon">
                        <ArrowDropDownIcon fontSize="large" color="primary" />
                      </span>
                    </div>
                    <div className="arrow-box me-5">
                      <Link className="ms-4 text-dark" to="/recovery-d">
                        ड) पवनचक्की/कारखाना वसुली{" "}
                      </Link>
                    </div>
                  </div>
                  {/* <TabEco/> */}
                </div>
                {!isAlertVisible ? (
                  <>
                    <div className="col-lg-12 mt-5 mb-5">
                      <div className="d-flex gap-5">
                        <Village_Tal_Dist_Mahe
                          villageDataInfo={villageDataInfo}
                        />
                        <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                          <label htmlFor="">माहे :</label>
                          {month === undefined ? (
                            <span></span>
                          ) : (
                            <select
                              name="month"
                              value={month}
                              onChange={(e) =>
                                setMonth(e.target.value)
                              }
                              className="fw-bold"
                            >
                              <option value="1">जानेवारी</option>
                              <option value="2">फेब्रुवारी</option>
                              <option value="3">मार्च</option>
                              <option value="4">एप्रिल</option>
                              <option value="5">मे</option>
                              <option value="6">जून</option>
                              <option value="7">जुलै</option>
                              <option value="8">ऑगस्ट</option>
                              <option value="9">सप्टेंबर</option>
                              <option value="10">ऑक्टोंबर</option>
                              <option value="11">नोव्हेंबर</option>
                              <option value="12">डिसेंबर</option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="mt-3">
                      क) किरकोळ वसूली (पवनचक्की सोडून){" "}
                      {isDraft === "Y" ? (
                        <span className="fw-bold text-danger">is Drafted</span>
                      ) : isDraft === "N" ? (
                        <span className="submit_text">
                          Submitted{" "}
                          <img
                            src={require("../../component/assets/images/sumbit-success.png")}
                            alt="success"
                            width={30}
                            className="img-fluid"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <div className="table-responsive mb-3">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">अ.क्र.</th>
                            <th scope="col">
                              मागील थकबाकी <br /> (सन{" "}
                              {yearInfo ? yearInfo.previous_year_name : ""})
                            </th>
                            <th scope="col">
                              चालू मागणी <br /> (सन{" "}
                              {yearInfo ? yearInfo.current_year_name : ""})
                            </th>
                            <th scope="col">
                              एकूण मागणी <br />
                              (मागील + चालू) (सन{" "}
                              {yearInfo ? yearInfo.current_year_name : ""})
                            </th>
                            <th scope="col">
                              {" "}
                              मागील महिनाअखेर <br /> वसूली{" "}
                            </th>
                            <th scope="col">
                              {" "}
                              चालू महिन्यातील <br /> वसूली{" "}
                            </th>
                            <th scope="col"> एकूण वसूली </th>
                            <th scope="col"> टक्केवारी </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                value={1}
                                className="form-control table-input-box"
                                disable="true"
                                readOnly
                                style={{ background: "transparent" }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={pastThakBaki}
                                onChange={(e) => {
                                  setPastThakBaki(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(pastThakBaki)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(pastThakBaki)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={pastThakBakiRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={presantThakBaki}
                                onChange={(e) => {
                                  setPresantThakBaki(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(presantThakBaki)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(presantThakBaki)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={presantThakBakiRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control table-input-box fw-bold"
                                value={totalPasPre}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={pasMonth}
                                onChange={(e) => {
                                  setPasMonth(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(pasMonth)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(pasMonth)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={pasMonthRef}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={preMonth}
                                onChange={(e) => {
                                  setPreMonth(e.target.value);
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(preMonth)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(preMonth)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={preMonthRef}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className={`form-control table-input-box fw-bold ${
                                  validated &&
                                  parseFloat(totalAll) > parseFloat(totalPasPre)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={totalAll}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className={`form-control table-input-box fw-bold ${
                                  validated &&
                                  parseFloat(totalAll) > parseFloat(totalPasPre)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={totalPercentage}
                                readOnly
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {validated &&
                        (parseFloat(totalAll) > parseFloat(totalPasPre) ? (
                          <span className="text-danger">
                            सूचना :- कृपया एकूण वसूली ही एकूण मागणी पेक्षा कमी
                            किवा समान असावी.
                          </span>
                        ) : (
                          ""
                        ))}
                    </div>
                    <div className="col-lg-12">
                      <FooterDevOfficer villageDataInfo={villageDataInfo} />
                    </div>
                    {(month === currentMonth.toString() && !isLoading)&&
                      (isDraft === "" || isDraft === "Y") && (
                        <div className="col-lg-12 d-flex justify-content-end align-items=center">
                          <FormBtn
                            resetForm={handleDataRefreshReset}
                            handleSubmit={handleFormSubmission}
                            handleDraft={handleStoreEconomic}
                          />
                        </div>
                      )}

                    {isDraft === "N" && (
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p className="text-danger">
                            सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                            विनंती करा.{" "}
                          </p>
                          <div>
                            <Button variant="contained">विनंती करा</Button>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {" "}
                          सूचना <MdOutlineArrowDownward />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                        Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म
                        Submit करू इच्छिता?
                      </Modal.Body>
                      <Modal.Footer className="gap-3">
                        <Button
                          className="modal-button-cancel"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="modal-button-submit"
                          onClick={handleUpdateData}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : (
                  <section
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <div className="alert_pop_up_common">
                            <h4 className="alert_pop_up_common_title">
                              आर्थिक वर्ष मागणी
                            </h4>
                            <p className="alert_pop_up_common_des">
                              {" "}
                              कृपया आर्थिक वर्ष करमागणी मधील किरकोळ मागणी भरा.
                            </p>
                            <Link className="alert_submit_btn" to="/eco-c">
                              आता पूर्ण करा
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
            <ToastContainer
              position={"top-center"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </>
        )}
      </>
    </React.Fragment>
  );
};

export default RecoveryReport_C;
