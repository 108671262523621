/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import "../VasuliReport/RecoveryReport.css";
import { saveAs } from 'file-saver';
import style from "./style.module.css";
import { IoMdEye } from "react-icons/io";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
// import { Document, Page, pdfjs } from "react-pdf";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa6";
import Loader from "../../component/Loader/Loader";
const Mpr_Report = () => {
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [pdfUrl, setPdfUrl] = useState("")
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [monthMarathi, setMonthMarathi] = useState("")
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
 
//   const [numPages, setNumPages] = useState(null);


//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

  const downloadPdf = () => {
   
    // Trigger the download
    saveAs(pdfUrl, 'MPR_Report.pdf');
  };

  //  वसुली विभाग (घरपट्टी वसुली) Get Information api
  const handleRecoveryReportDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month,
      };
      const response = await axios.post(
        API_BASE_URL + "/mprpdf",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      if(response.data.status === true){
        console.log("Mpr details response", response.data);
      setPdfUrl(response.data.data);
      setMonthMarathi(response.data.marathi_month)
      setVillageDataInfo(response.data.other_data);
      
      }else{
        setPdfUrl("");
        setMonth("")
        setVillageDataInfo("");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleRecoveryReportDetails();
  }, [month]);

  return (
    <>
    {isLoading ? (
      <Loader/>
    ):(
      <div className="container">
      <div className="row">
        <div className="col-lg-12 navigate-section-header mt-3">
          <div className="page-title">
            <p className="fw-bold mb-2">१. मासिक रिपोर्ट</p>
          </div>
          <div className="navigate-section d-flex"></div>
          <div className="d-flex gap-5">
            <Village_Tal_Dist_Mahe villageDataInfo={villageDataInfo} />
            <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
              <label htmlFor="">माहे :</label>
              {month === undefined ? (
                <span></span>
              ) : (
                <select
                  name="month"
                  value={month}
                  onChange={(e) => setMonth(parseInt(e.target.value))}
                  className="fw-bold"
                >
                  <option value="1">जानेवारी</option>
                  <option value="2">फेब्रुवारी</option>
                  <option value="3">मार्च</option>
                  <option value="4">एप्रिल</option>
                  <option value="5">मे</option>
                  <option value="6">जून</option>
                  <option value="7">जुलै</option>
                  <option value="8">ऑगस्ट</option>
                  <option value="9">सप्टेंबर</option>
                  <option value="10">ऑक्टोंबर</option>
                  <option value="11">नोव्हेंबर</option>
                  <option value="12">डिसेंबर</option>
                </select>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-5 mb-5">
          <div className="text-end d-flex gap-3 justify-content-center mb-3">
            {/* Your component content */}
            <Link to={pdfUrl} className={`btn ${style.btn_prview}`}><IoMdEye /> पूर्वावलोकन <span className="fw-bold">{monthMarathi}</span> मासिक अहवाल रीपोर्ट</Link>
            <button className={`btn btn-danger ${style.downloadPdf}`} onClick={downloadPdf}><FaFilePdf /> डाउनलोड <span className="fw-bold">{monthMarathi}</span> मासिक अहवाल रीपोर्ट (PDF Format)</button>
          </div>
          {/* <div className={style.pdf_viewer_css}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer fileUrl={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} />
            </Worker>
          </div> */}
        </div>
      </div>
    </div>
    )}
    </>
  
  );
};

export default Mpr_Report;
