/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../Fifteen_Vith.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import style from "./style.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const DistrictFifttenVith = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [districtInfo, setDistrictInfo] = useState("");
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [year, setYear] = useState("");

  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  useEffect(() => {
    handleFifttenVithDetails();
  }, [talId, villageId, month]);

  // Tied Ups states
  // const [tuFundRecived, setTuFundRecived] = useState("");
  const [tuAdministrativeExpences, setTuAdministrativeExpences] = useState("");
  const [tuNinetyCost, setTuNinetyCost] = useState("");
  const [tuTotalSanctionedWorks, setTuTotalSanctionedWorks] = useState("");
  const [tuCompleteWorks, setTuCompleteWorks] = useState("");
  const [tuWorksProgress, setTuWorksProgress] = useState("");
  const [tuUnfinishedWorks, setTuUnfinishedWorks] = useState("");
  const [tuUnspentFunds, setTuUnspentFunds] = useState("");
  const [tuPercentageCost, setTuPercentageCost] = useState("");
  const [tuSign, setTuSign] = useState("");

  // Unbound states
  // const [ubFundRecived, setUbFundRecived] = useState("");
  const [ubAdministrativeExpences, setUbAdministrativeExpences] = useState("");
  const [ubNinetyCost, setUbNinetyCost] = useState("");
  const [ubTotalSanctionedWorks, setUbTotalSanctionedWorks] = useState("");
  const [ubCompleteWorks, setUbCompleteWorks] = useState("");
  const [ubWorksProgress, setUbWorksProgress] = useState("");
  const [ubUnfinishedWorks, setUbUnfinishedWorks] = useState("");
  const [ubUnspentFunds, setUbUnspentFunds] = useState("");
  const [ubPercentageCost, setUbPercentageCost] = useState("");
  const [ubSign, setUbSign] = useState("");

  // total calculation

  const [totalAdministrativeExpences, setTotalAdministrativeExpences] =
    useState("");
  const [totalNinetyCost, setTotalNinetyCost] = useState("");
  const [totalTotalSanctionedWorks, setTotalSanctionedWorks] = useState("");
  const [totalCompleteWorks, setTotalCompleteWorks] = useState("");
  const [totalWorksProgress, setTotalWorksProgress] = useState("");
  const [totalUnfinishedWorks, setTotalUnfinishedWorks] = useState("");
  const [totalUnspentFunds, setTotalUnspentFunds] = useState("");
  const [totalPercentageCost, setTotalPercentageCost] = useState("");

  // total १० % प्रशासकीय खर्च
  useEffect(() => {
    calculateTotalAdministrativeExpences();
  }, [tuAdministrativeExpences, ubAdministrativeExpences]);

  const calculateTotalAdministrativeExpences = () => {
    const num1 = parseInt(tuAdministrativeExpences) || 0;
    const num2 = parseInt(ubAdministrativeExpences) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalAdministrativeExpences("");
    } else {
      const sum = num1 + num2;
      setTotalAdministrativeExpences(sum.toString());
    }
  };

  // total १० % प्रशासकीय खर्च
  useEffect(() => {
    calculateTotalNinetyCost();
  }, [tuNinetyCost, ubNinetyCost]);

  const calculateTotalNinetyCost = () => {
    const num1 = parseInt(tuNinetyCost) || 0;
    const num2 = parseInt(ubNinetyCost) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalNinetyCost("");
    } else {
      const sum = num1 + num2;
      setTotalNinetyCost(sum.toString());
    }
  };

  // total एकूण मंजूर कामे (उपलब्ध निधीच्या प्रमाणात )
  useEffect(() => {
    calculateTotalSanctionedWorks();
  }, [tuTotalSanctionedWorks, ubTotalSanctionedWorks]);

  const calculateTotalSanctionedWorks = () => {
    const num1 = parseInt(tuTotalSanctionedWorks) || 0;
    const num2 = parseInt(ubTotalSanctionedWorks) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalSanctionedWorks("");
    } else {
      const sum = num1 + num2;
      setTotalSanctionedWorks(sum.toString());
    }
  };

  // total पूर्ण कामे
  useEffect(() => {
    calculateCompleteWorks();
  }, [tuCompleteWorks, ubCompleteWorks]);

  const calculateCompleteWorks = () => {
    const num1 = parseInt(tuCompleteWorks) || 0;
    const num2 = parseInt(ubCompleteWorks) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalCompleteWorks("");
    } else {
      const sum = num1 + num2;
      setTotalCompleteWorks(sum.toString());
    }
  };

  // total प्रगतीत कामे
  useEffect(() => {
    calculateWorksProgress();
  }, [tuWorksProgress, ubWorksProgress]);

  const calculateWorksProgress = () => {
    const num1 = parseInt(tuWorksProgress) || 0;
    const num2 = parseInt(ubWorksProgress) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalWorksProgress("");
    } else {
      const sum = num1 + num2;
      setTotalWorksProgress(sum.toString());
    }
  };

  // total अपूर्ण कामे
  useEffect(() => {
    calculateUnfinishedWorks();
  }, [tuUnfinishedWorks, ubUnfinishedWorks]);

  const calculateUnfinishedWorks = () => {
    const num1 = parseInt(tuUnfinishedWorks) || 0;
    const num2 = parseInt(ubUnfinishedWorks) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalUnfinishedWorks("");
    } else {
      const sum = num1 + num2;
      setTotalUnfinishedWorks(sum.toString());
    }
  };

  // total अखर्चित निधी
  useEffect(() => {
    calculateUnspentFunds();
  }, [tuUnspentFunds, ubUnspentFunds]);

  const calculateUnspentFunds = () => {
    const num1 = parseInt(tuUnspentFunds) || 0;
    const num2 = parseInt(ubUnspentFunds) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalUnspentFunds("");
    } else {
      const sum = num1 + num2;
      setTotalUnspentFunds(sum.toString());
    }
  };

  // total खर्चाची टक्केवारी
  useEffect(() => {
    calculatePercentageCost();
  }, [totalAdministrativeExpences, totalNinetyCost, totalUnspentFunds]);



  const calculatePercentageCost = () => {
    const num1 = parseFloat(totalAdministrativeExpences) || 0;
    const num2 = parseFloat(totalNinetyCost) || 0;
    const num3 = parseFloat(totalUnspentFunds) || 0;

    if (isNaN(num1) || isNaN(num2) || isNaN(num3)) {
      setTotalPercentageCost("0");
    } else {
      const sum = num1 + num2 - num3;
      const sum2 = num1 + num2;
      const sum3 = (sum / sum2) * 100;
      setTotalPercentageCost(isNaN(sum3) ? "0" : `${sum3.toFixed(2)} %`);
      // console.log("TotalPercentageCost", sum3);
    }
  };

  const handleFifttenVithDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        month: month,
        year_id: userDetails.current_year_id
      };
      const response = await axios.post(
        API_BASE_URL + "api/summary_vith_ayog",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("FifteenVith Data response", response);
      //   setMeetingData(response.data.data);
      setYearInfo(response.data.data.year_info);
      setDistrictInfo(response.data.data.other_data);
      // updated values in Tied Up inputs

      setTuAdministrativeExpences(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].ten_administrative_expenses
          : "0"
      );
      setTuNinetyCost(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].ninety_cost
          : "0"
      );
      setTuTotalSanctionedWorks(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].total_sanctioned_works
          : "0"
      );
      setTuCompleteWorks(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].complete_works
          : "0"
      );
      setTuWorksProgress(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].works_in_progress
          : 0
      );
      setTuUnfinishedWorks(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].unfinished_works
          : "0"
      );
      setTuUnspentFunds(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].unspent_funds
          : "0"
      );
      setTuPercentageCost(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].percentage_of_cost
          : "0"
      );
      setTuSign(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].shera
          : "N/A"
      );
      setYear(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[0].year
          : "N/A"
      );

      // updated values in Unbound inputs

      setUbAdministrativeExpences(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].ten_administrative_expenses
          : "0"
      );
      setUbNinetyCost(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].ninety_cost
          : "0"
      );
      setUbTotalSanctionedWorks(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].total_sanctioned_works
          : "0"
      );
      setUbCompleteWorks(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].complete_works
          : "0"
      );
      setUbWorksProgress(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].works_in_progress
          : "0"
      );
      setUbUnfinishedWorks(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].unfinished_works
          : "0"
      );
      setUbUnspentFunds(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].unspent_funds
          : "0"
      );
      setUbPercentageCost(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].percentage_of_cost
          : "0"
      );
      setUbSign(
        response.data.data.vith_ayog_data.length > 0
          ? response.data.data.vith_ayog_data[1].shera
          : "N/A"
      );
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };

  var userDetailsTalukaId = userDetails.taluka_id;
  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id: userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>१५ वा वित्त आयोग</title>
        </Helmet>
        <div className="container-fluid common-section common_deputy_container">
          <div className="row common_deputy_row">
            <div className="col-lg-12 navigate-section-header mt-3">
              <div className="page-title">
                <p className="fw-bold mb-2">
                  # १५ वा वित्त आयोग
                </p>
              </div>
              <div className="navigate-section d-flex">
                <div className="d-flex flex-column justify-content-center">
                  <div className={`${style.select_group_top}`}>
                    <label>जिल्हा: </label>
                    <span className="fw-bold">
                      {districtInfo.district_name}
                    </span>
                  </div>
                </div>
                <div className="VillagePanchayat ms-5 d-flex gap-2 justify-content-center align-items-center">
                  <label htmlFor="">माहे :</label>
                  {month === undefined ? (
                    <span></span>
                  ) : (
                    <select
                      name="month"
                      value={month}
                      onChange={(e) => setMonth(parseInt(e.target.value))}
                      className="taluka_select_input"
                    >
                      <option value="1">जानेवारी</option>
                      <option value="2">फेब्रुवारी</option>
                      <option value="3">मार्च</option>
                      <option value="4">एप्रिल</option>
                      <option value="5">मे</option>
                      <option value="6">जून</option>
                      <option value="7">जुलै</option>
                      <option value="8">ऑगस्ट</option>
                      <option value="9">सप्टेंबर</option>
                      <option value="10">ऑक्टोंबर</option>
                      <option value="11">नोव्हेंबर</option>
                      <option value="12">डिसेंबर</option>
                    </select>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-center ms-5">
                  <div className={`${style.select_group_top}`}>
                    <label htmlFor="">तालुका: </label>
                    <select
                      name="taluka"
                      className="taluka_select_input"
                      onChange={(e) => handleOnchangeTaluka(e, talId)}
                      value={talId}
                    >
                      {userDetails.role_id === "7" ? (
                        <option>निवडा</option>
                      ) : (
                        ""
                      )}
                      {tal.map((ele) => {
                        return (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center ms-5">
                  <div className={`${style.select_group_top}`}>
                    <label htmlFor="">ग्रामपंचयात: </label>
                    <Autocomplete
                      disablePortal
                      className={`${style.select_common_dropdown}`}
                      options={village}
                      sx={{ width: 200 }}
                      onChange={(e, selectedOption) =>
                        handleVillage(selectedOption)
                      }
                      value={
                        villageId
                          ? village.find((v) => v.value === villageId) || null
                          : null
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="निवडा" />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive ps-0 pe-0 mt-4 mb-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "2rem" }}>अ.क्र.</th>
                    <th scope="col" style={{ width: "0rem" }}>वर्ष</th>
                    <th scope="col">प्राप्त निधी</th>
                    <th scope="col">१० % प्रशासकीय खर्च</th>
                    <th scope="col">९० % खर्च</th>
                    <th scope="col" style={{ width: "5rem" }}>
                      एकूण मंजूर कामे (उपलब्ध निधीच्या प्रमाणात )
                    </th>
                    <th scope="col"> पूर्ण कामे </th>
                    <th scope="col"> प्रगतीत कामे </th>
                    <th scope="col"> अपूर्ण कामे </th>
                    <th scope="col"> अखर्चित निधी </th>
                    <th scope="col" style={{ width: "5rem" }}> खर्चाची टक्केवारी </th>
                    <th scope="col">शेरा </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={3}>
                      <input
                        type="number"
                        className="form-control table-input-box"
                        value={1}
                        readOnly
                      />
                    </td>
                    <td rowSpan={3}>
                      <input
                        type="text"
                        value={year}
                        readOnly
                        onChange={(e) => {
                          setYear(e.target.value);
                        }}
                        className="form-control table-input-box width_grma"
                      />
                    </td>
                    <td>
                      <label htmlFor="">बंधीत </label>
                    </td>
                    <td>{tuAdministrativeExpences}</td>
                    <td>{tuNinetyCost}</td>
                    <td>{tuTotalSanctionedWorks}</td>
                    <td>{tuCompleteWorks}</td>
                    <td>{tuWorksProgress}</td>
                    <td>{tuUnfinishedWorks}</td>
                    <td>{tuUnspentFunds}</td>
                    <td>{tuPercentageCost} %</td>
                    <td>{tuSign}</td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="">अबंधित </label>
                    </td>
                    <td>{ubAdministrativeExpences}</td>
                    <td>{ubNinetyCost}</td>
                    <td>{ubTotalSanctionedWorks}</td>
                    <td>{ubCompleteWorks}</td>
                    <td>{ubWorksProgress}</td>
                    <td>{ubUnfinishedWorks}</td>
                    <td>{ubUnspentFunds}</td>
                    <td>{ubPercentageCost} %</td>
                    <td>{ubSign}</td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="" className="fw-bold">एकूण </label>
                    </td>
                    <td>{totalAdministrativeExpences}</td>
                    <td>{totalNinetyCost}</td>
                    <td>{totalTotalSanctionedWorks}</td>
                    <td>{totalCompleteWorks}</td>
                    <td>{totalWorksProgress}</td>
                    <td>{totalUnfinishedWorks}</td>
                    <td>{totalUnspentFunds}</td>
                    <td>{totalPercentageCost}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <label className="fw-bold" htmlFor="">एकूण एकंदर </label>
                    </td>
                    <td></td>
                    <td>{totalAdministrativeExpences}</td>
                    <td>{totalNinetyCost}</td>
                    <td>{totalTotalSanctionedWorks}</td>
                    <td>{totalCompleteWorks}</td>
                    <td>{totalWorksProgress}</td>
                    <td>{totalUnfinishedWorks}</td>
                    <td>{totalUnspentFunds}</td>
                    <td>{totalPercentageCost}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
      </React.Fragment>
    </div>
  );
};

export default DistrictFifttenVith;
