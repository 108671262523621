/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import style from "./styles.module.css";

import { ToastContainer, toast } from "react-toastify";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { Button } from "@mui/material";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { MdOutlineArrowDownward } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const SheetF = ({ handleNextStep, handlePrevStep, currentStep, handleStepDetails }) => {
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [economicData, setEconomicData] = useState("");
  const [sheetData, setSheetData] = useState({
    sr_no: "1",
    panchayat_name: "",
    gpt_name: "",
    opening_balance: "",
    excluding_opg_balance_income: "",
    opg_balance_holding_income: "",
    total_expanse: "",
    final_balance: "",
    shera: "",
  });

  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_f",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetF response", response.data.data);
      if (response.data.status === true) {
        const data = response.data.data.form_f_data;
        const otherData = response.data.data.other_data;
        const openingBalance = response.data.data.opening_balance;        ;
        const sevenData = response.data.data.total_deposit_seven;
        const totalExpanse = response.data.data.total_amount_receive_year;
        const defaultSheetData = {
          sr_no: "1",
          panchayat_name: otherData.taluka_name,
          gpt_name: otherData.village_name,
          opening_balance: openingBalance,
          excluding_opg_balance_income: sevenData,
          total_expanse:totalExpanse
        };
        if (data !== null) {
          setSheetData({
            ...defaultSheetData,
            // opening_balance: data.opening_balance,
            opg_balance_holding_income: data.opg_balance_holding_income,
            final_balance: data.final_balance,
            shera: data.shera,
          });
        } else {
          setSheetData({...defaultSheetData,
            opg_balance_holding_income: "",
            final_balance: "",
            shera: "",});
        }
      }

      if (
        response.data.data.form_f_data === null ||
        response.data.data.form_f_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_f_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value }));
  };

  // table validation
  const validateForm = () => {
    const newErrors = {};

    // Validate tabels all input

    if (
      sheetData.opening_balance !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.opening_balance)
    ) {
      newErrors.opening_balance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    // if (
    //   sheetData.excluding_opg_balance_income !== "" &&
    //   !/^-?\d*\.?\d+$/.test(sheetData.excluding_opg_balance_income)
    // ) {
    //   newErrors.excluding_opg_balance_income =
    //     "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    if (
      sheetData.opg_balance_holding_income !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.opg_balance_holding_income)
    ) {
      newErrors.opg_balance_holding_income = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.shera !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.shera)
    ) {
      newErrors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }
    
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };


  const validateForm1 = () => {
    const newErrors = {};

    // Validate tabels all input

    if (
      !sheetData.opening_balance ||
      !/^-?\d*\.?\d+$/.test(sheetData.opening_balance)
    ) {
      newErrors.opening_balance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    // if (
    //   !sheetData.opg_balance_holding_income ||
    //   !/^-?\d*\.?\d+$/.test(sheetData.opg_balance_holding_income)
    // ) {
    //   newErrors.opg_balance_holding_income = "कृपया वैध क्रमांक प्रविष्ट करा.";
    // }
    if (
      sheetData.shera !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.shera)
    ) {
      newErrors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }
    console.log("newErrors", newErrors)
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // update api

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm1()) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_f_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          opening_balance: sheetData.opening_balance,
          excluding_opg_balance_income: sheetData.excluding_opg_balance_income,
          opg_balance_holding_income: totalOpeningBalance,
          total_expanse: sheetData.total_expanse,
          final_balance: FinalBalance,
          shera: sheetData.shera,
        },
      };
      console.log("update finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_f",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetF response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // draft api
  const handleDraftSheetF = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_f_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          opening_balance: sheetData.opening_balance,
          excluding_opg_balance_income: sheetData.excluding_opg_balance_income,
          opg_balance_holding_income: totalOpeningBalance,
          total_expanse: sheetData.total_expanse,
          final_balance: FinalBalance,
          shera: sheetData.shera,
        },
      };
      console.log("draftSheetF finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_f",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetF response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  const totalOpeningBalance = 
  parseFloat(sheetData.opening_balance || 0) +
  parseFloat(sheetData.excluding_opg_balance_income || 0) || "";

  const FinalBalance =
  parseFloat(totalOpeningBalance || 0) -
  parseFloat(sheetData.total_expanse || 0) || "";
  
  console.log("opening_balance",sheetData.opening_balance);
  console.log("excluding_opg_balance_income",sheetData.total_expanse);



  
  return (
    <>
      <Helmet>
        <title>पत्रक - एफ</title>
      </Helmet>
      <div
        id="table-container"
        className={`container-fluid sheet_bg_color_pdf mb-5 pt-0`}
      >
        <div className={`row`}>
          <div className="col-lg-12">
            <div className="table-main-sec">
              <div className="table_top">
                <div className={`${style.patrak_title_top}`}>
                  <p className="mb-4">
                    आर्थिक प्रगती दर्शविणारे पत्रक
                    <span className="patrak_box">पत्रक- 'एफ'</span>
                  </p>
                  {economicData === "Y" ? (
                    <span className="drafted_text text-danger mt-3">
                      <FaEdit /> Drafted
                    </span>
                  ) : economicData === "N" ? (
                    <span className="submit_text mt-3">
                      Submit{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <form action="">
                  <table className={`w-100 ${style.table_bordered}`}>
                    <thead className={`${style.custom_thead}`}>
                      <tr className={` ${style.common_tr_border}`}>
                        <th className="text-center">अ.क्र</th>
                        <th className="text-center">
                          पंचायत समितीचे <br /> नांव
                        </th>
                        <th className="text-center">
                          ग्रामपंचायतीचे <br /> नांव
                        </th>
                        <th className="text-center">आरंभीचे शिल्लक</th>
                        <th className="text-center">
                          आरंभीची शिल्लक वगळून <br /> उत्पन्न (सी व डी पत्रकातील{" "}
                          <br />
                          कॉ.नं.३३)
                        </th>
                        <th className="text-center">
                          आरंभीची शिल्लक <br /> धरून उत्पन्न
                        </th>
                        <th className="text-center">
                          एकूण खर्च (पत्रक इ <br /> कॉ.नं.७९ प्रमाणे)
                        </th>
                        <th className="text-center">
                          अखेरची शिल्लक <br />
                          (कॉ.नं. ६-७)
                        </th>
                        <th className="text-center">शेरा</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>१</td>
                        <td>२</td>
                        <td>३</td>
                        <td>४</td>
                        <td>५</td>
                        <td>६</td>
                        <td>७</td>
                        <td>८</td>
                        <td>९</td>
                      </tr>
                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>{sheetData.sr_no}</td>
                        <td>{sheetData.panchayat_name}</td>
                        <td>{sheetData.gpt_name}</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box fw-bold  ${
                              errors.opening_balance ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.opening_balance
                                ? errors.opening_balance
                                : ""
                            }
                            name="opening_balance"
                            value={sheetData.opening_balance}
                            onChange={handleChange}
                            autoComplete="off"
                            readOnly
                          />
                        </td>
                        <td>
                          
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={sheetData.excluding_opg_balance_income || ""}
                            readOnly
                          />
                          </td>
                        <td>
                          {/* <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.opg_balance_holding_income
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.opg_balance_holding_income
                                ? errors.opg_balance_holding_income
                                : ""
                            }
                            name="opg_balance_holding_income"
                            value={sheetData.opg_balance_holding_income}
                            onChange={handleChange}
                            autoComplete="off"
                          /> */}
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalOpeningBalance || ""}
                            readOnly
                          />
                         
                        </td>
                        <td>
                        <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={sheetData.total_expanse || ""}
                            readOnly
                          />
                          
                        </td>
                        <td>
                        <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={FinalBalance}
                            readOnly
                          /></td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.shera ? "is-invalid" : ""
                            }`}
                            placeholder={errors.shera ? errors.shera : ""}
                            name="shera"
                            value={sheetData.shera}
                            onChange={handleChange}
                            style={{
                              width: sheetData.shera.length <= 6
                                ? "100%"
                                : `${sheetData.shera.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      {/* <tr
                        className={`${style.custom_tr_second} ${style.common_tr_border}`}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr
                        className={`${style.custom_tr_second} ${style.common_tr_border}`}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr> */}
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="mt-3">
                <p className="text-danger">सूचना :- पत्रक - फ भरण्या अगोदर नमुना - ३ व नमुना - ४ भरावा.</p>
              </div>
              <div className={style.form_btn_space_f}>
                {/* <FormBtn
                  handleSubmit={handleSubmit}
                  resetForm={handleSubmit}
                  resetButton={true}
                  handleDraft={handleSubmit}
                /> */}
                <div className="col-lg-12 d-flex justify-content-end align-items=center">
                <FormBtn
                      handleSubmit={handleSubmit}
                      resetForm={handleSubmit}
                      resetButton={true}
                      handleDraft={handleDraftSheetF}
                    />
                </div>
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {" "}
                      सूचना <MdOutlineArrowDownward />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                    Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit
                    करू इच्छिता?
                  </Modal.Body>
                  <Modal.Footer className="gap-3">
                    <Button
                      className="modal-button-cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-button-submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>

                <div className="">
                  <div
                    style={{ marginLeft: "8px" }}
                    className={style.btn_position}
                  >
                    {currentStep > 1 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handlePrevStep}
                      >
                        Previous
                      </Button>
                    )}

                    {currentStep < 8 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handleNextStep}
                      >
                        Next
                      </Button>
                    )}

                    {currentStep === 8 && (
                      <button
                        type="submit"
                        className={style.btn_generate}
                        // onClick={handlePrint}
                      >
                        Generate Pdf
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-center"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default SheetF;
