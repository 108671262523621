/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Prapatra.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import Loader from "../../component/Loader/Loader";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const Prapatra_3 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [validated, setValidated] = useState(false);

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [gptName, setGptName] = useState("");
  const [pralambitShak, setPralambitShak] = useState("");
  const [newShak, setNewShak] = useState("");
  const [totalShak, setTotalShak] = useState("");
  const [data, setData] = useState([]);
  const [udistaShak, setUdistaShak] = useState("");
  const [purtataShak, setPurtataShak] = useState("");
  const [gpShak, setGpShak] = useState("");
  const [workPercentage, setWorkPercentage] = useState("");
  const [totalPralambitShak, setTotalPralambitShak] = useState("");
  const [sign, setSign] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    calculate();
  }, [pralambitShak, newShak]);

  const calculate = () => {
    const num1 = parseInt(pralambitShak) || 0;
    const num2 = parseInt(newShak) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalShak("");
    } else {
      const sum = num1 + num2;
      setTotalShak(sum);
    }
  };

  useEffect(() => {
    calculate1();
  }, [totalShak, purtataShak]);

  const calculate1 = () => {
    const num1 = parseInt(totalShak) || 0;
    const num2 = parseInt(purtataShak) || 0;
    if (isNaN(num1) || isNaN(num2)) {
      setTotalPralambitShak("");
    } else {
      const sum = num1 - num2;
      setTotalPralambitShak(sum);
    }
  };

  useEffect(() => {
    calculateWorkPercentage();
  }, [purtataShak, totalShak]);

  const calculateWorkPercentage = () => {
    const num1 = parseInt(purtataShak);
    const num2 = parseInt(totalShak);
    if (isNaN(num1) || isNaN(num2)) {
      setWorkPercentage("");
    } else {
      const sum = (num1 / num2) * 100;
      setWorkPercentage(`${sum.toFixed(2)}`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handlePrapatraDetails(month);
  }, [month]);

  const handleMonthChange = (newMonth) => {
    // Clear or reset data state
    setGptName("");
    setPralambitShak("");
    setNewShak("");
    setTotalShak("");
    setUdistaShak("");
    setPurtataShak("");
    setPurtataShak("");
    setGpShak("");
    setWorkPercentage("");
    setTotalPralambitShak("");
    setSign("");
    // Update the month state
    setMonth(newMonth);
    // Fetch data for the new month
    handlePrapatraDetails(newMonth);
    // ... other logic for month change
  };

  // प्रपत्र (प्रपत्र ३) Get Information api

  const handlePrapatraDetails = async (month) => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month: month || currentMonth.toString(),
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/form_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("Prapatra_C_Details response", response.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);

      if (
        response.data.data.form_c_data === null ||
        response.data.data.form_c_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_c_data[0].is_draft);
      }

      setMonth(response.data.data.month);
      setData(response.data.data.form_c_data);
      // updated date show in inputs

      if (
        response.data.data.form_c_data !== null ||
        response.data.data.form_c_data.length > 0
      ) {
        setGptName(response.data.data.form_c_data[0].gpt_name);
        setPralambitShak(response.data.data.form_c_data[0].pending);
        setNewShak(response.data.data.form_c_data[0].new);
        setTotalShak(response.data.data.form_c_data[0].total);
        setUdistaShak(response.data.data.form_c_data[0].udishtha);
        setPurtataShak(response.data.data.form_c_data[0].accomplished);
        setPurtataShak(response.data.data.form_c_data[0].accomplished);
        setGpShak(response.data.data.form_c_data[0].gp_submitted_to);
        setWorkPercentage(response.data.data.form_c_data[0].percentage_of_work);
        setTotalPralambitShak(
          response.data.data.form_c_data[0].total_pending_rs
        );
        setSign(response.data.data.form_c_data[0].shera);
      } else {
        setGptName("");
        setPralambitShak("");
        setNewShak("");
        setTotalShak("");
        setUdistaShak("");
        setPurtataShak("");
        setPurtataShak("");
        setGpShak("");
        setWorkPercentage("");
        setTotalPralambitShak("");
        setSign("");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  const handleDataRefresh = async () => {
    await handlePrapatraDetails();
  };

  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handlePrapatraDetails();
      setValidated(false);
    } else {
      setGptName("");
      setPralambitShak("");
      setNewShak("");
      setUdistaShak("");
      setPurtataShak("");
      setGpShak("");
      setWorkPercentage("");
      setSign("");
      setValidated(false);
    }
  };

  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  const isText = (value) => {
    return /^[\u0900-\u097Fa-zA-Z\s]+$/.test(value);
  };

  
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(pralambitShak) ||
      !isNumeric(newShak) ||
      !isNumeric(udistaShak) ||
      !isNumeric(purtataShak) ||
      !isNumeric(gpShak) ||
      !isNumeric(workPercentage) ||
      !isText(sign)
    ){
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };
  

  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const isValid = validateForm();
    if (isValid) {
      if(validated){
        await handleExpenseProvisionDetails(e);
      }
    }else{
      handleShow();
    }
  }
 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };
  // प्रपत्र (प्रपत्र ३)   Update Information api

  const handleExpenseProvisionDetails = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        form_c_data: [
          {
            type: "1",
            gpt_name: villageDataInfo.village_name,
            pending: pralambitShak,
            new: newShak,
            total: totalShak,
            udishtha: udistaShak,
            accomplished: purtataShak,
            gp_submitted_to: gpShak,
            percentage_of_work: workPercentage,
            total_pending_rs: totalPralambitShak,
            shera: sign,
          },
        ],
      };
      console.log("updatePrapatra_C finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_form_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdatePrapatra_C response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // प्रपत्र (प्रपत्र ३)   draft Information api

  const handleExpenseProvisionDraft = async (e) => {
    e.preventDefault();
    if (
      (!isNumeric(pralambitShak) && pralambitShak !== "") ||
      (!isNumeric(newShak) && newShak !== "") ||
      (!isNumeric(udistaShak) && udistaShak !== "") ||
      (!isNumeric(purtataShak) && purtataShak !== "") ||
      (!isNumeric(gpShak) && gpShak !== "") ||
      (!isNumeric(workPercentage) && workPercentage !== "") ||
      (!isText(sign) && sign !== "")
    ) {
      setValidated(true);
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        month: month,
        form_c_data: [
          {
            type: "1",
            gpt_name: villageDataInfo.village_name,
            pending: pralambitShak,
            new: newShak,
            total: totalShak,
            udishtha: udistaShak,
            accomplished: purtataShak,
            gp_submitted_to: gpShak,
            percentage_of_work: workPercentage,
            total_pending_rs: totalPralambitShak,
            shera: sign,
          },
        ],
      };
      console.log("DraftPrapatra_C finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_form_c",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftPrapatra_C response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Helmet>
            <title>प्रपत्र ३</title>
          </Helmet>
          <div className="container common-section pt-0">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">१.६ प्रपत्र</p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="arrow-box me-5">
                    <Link className="text-dark" to="/praptra-a">
                      अ) प्रपत्र १
                    </Link>
                  </div>
                  <div className="arrow-box me-5">
                    <Link className="text-dark" to="/praptra-b">
                      ब) प्रपत्र २{" "}
                    </Link>
                  </div>
                  <div className="arrow-box-active d-flex flex-column justify-content-center  me-5">
                    <Link className="text-decoration-none" to="/praptra-c">
                      क) प्रपत्र ३{" "}
                    </Link>
                    <span className="text-center arrow-icon">
                      <ArrowDropDownIcon fontSize="large" color="primary" />
                    </span>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="col-lg-12 mt-4 ">
                <div className="d-flex gap-5">
                  <Village_Tal_Dist_Mahe villageDataInfo={villageDataInfo} />
                  <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                    <label htmlFor="">माहे :</label>
                    {month === undefined ? (
                      <span></span>
                    ) : (
                      <select
                        name="month"
                        value={month}
                        onChange={(e) => handleMonthChange(e.target.value)}
                        className="fw-bold"
                      >
                        <option value="1">जानेवारी</option>
                        <option value="2">फेब्रुवारी</option>
                        <option value="3">मार्च</option>
                        <option value="4">एप्रिल</option>
                        <option value="5">मे</option>
                        <option value="6">जून</option>
                        <option value="7">जुलै</option>
                        <option value="8">ऑगस्ट</option>
                        <option value="9">सप्टेंबर</option>
                        <option value="10">ऑक्टोंबर</option>
                        <option value="11">नोव्हेंबर</option>
                        <option value="12">डिसेंबर</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive mb-3 mt-5">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          प्रपत्र ३{" "}
                          {economicData === "Y" ? (
                            <span
                              className="drafted_text"
                              style={{
                                marginLeft: "1rem",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "#dc3545",
                              }}
                            >
                              <FaEdit style={{ marginTop: "-3px" }} /> Drafted
                            </span>
                          ) : economicData === "N" ? (
                            <span
                              className="submit_text"
                              style={{
                                marginLeft: "1rem",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "#2bc63a",
                              }}
                            >
                              Submitted{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={27}
                                style={{ marginTop: "-3px" }}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          मासिक विवरणपत्र - ग्रामपंचायत ऑडिट शक सन २०२२-२०२३{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "3rem" }}>
                        अ.क्र.
                      </th>
                      <th scope="col">ग्रा.पं.नाव</th>
                      <th scope="col">प्रलंबित शक</th>
                      <th scope="col">नवीन शक</th>
                      <th scope="col">एकूण शक</th>
                      <th scope="col">उदीष्ट शक</th>
                      <th scope="col"> पूर्तता केलेले शक </th>
                      <th scope="col"> जि.प. कडे सादर शक </th>
                      <th scope="col"> कामाची टक्केवारी </th>
                      <th scope="col"> एकूण प्रलंबित शक </th>
                      <th scope="col"> शेरा </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="number"
                          className="form-control table-input-box"
                          value={1}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={gptName || villageDataInfo.village_name}
                          onChange={(e) => {
                            setGptName(e.target.value);
                          }}
                          placeholder={
                            validated && !isText(gptName)
                              ? "कृपया केवळ अक्षरे प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box`}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={pralambitShak}
                          placeholder={
                            validated && !isNumeric(pralambitShak)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(pralambitShak)
                              ? "is-invalid"
                              : ""
                          }`}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setPralambitShak(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={newShak}
                          placeholder={
                            validated && !isNumeric(newShak)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(newShak) ? "is-invalid" : ""
                          }`}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setNewShak(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control table-input-box fw-bold"
                          value={totalShak}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={udistaShak}
                          placeholder={
                            validated && !isNumeric(udistaShak)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(udistaShak)
                              ? "is-invalid"
                              : ""
                          }`}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setUdistaShak(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={purtataShak}
                          placeholder={
                            validated && !isNumeric(purtataShak)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(purtataShak)
                              ? "is-invalid"
                              : ""
                          }`}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setPurtataShak(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={gpShak}
                          placeholder={
                            validated && !isNumeric(gpShak)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isNumeric(gpShak) ? "is-invalid" : ""
                          }`}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "*", "."].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setGpShak(e.target.value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={workPercentage}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            setWorkPercentage(e.target.value);
                          }}
                          placeholder={
                            validated && !isNumeric(workPercentage)
                              ? "कृपया वैध क्रमांक प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box fw-bold ${
                            validated && !isNumeric(workPercentage)
                              ? "is-invalid"
                              : ""
                          }`}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control table-input-box fw-bold"
                          readOnly
                          value={totalPralambitShak}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder={
                            validated && !isText(sign)
                              ? "कृपया केवळ अक्षरे प्रविष्ट करा."
                              : ""
                          }
                          className={`form-control table-input-box ${
                            validated && !isText(sign) ? "is-invalid" : ""
                          }`}
                          value={sign}
                          onChange={(e) => {
                            setSign(e.target.value);
                          }}
                          // readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-5" id="footerDevOfficer">
                  <FooterDevOfficer villageDataInfo={villageDataInfo} />
                </div>
              </div>
              <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center"></div>
              {(month === currentMonth.toString() && !isLoading) &&
                (economicData === "Y" || economicData === "") && (
                  <div className="col-lg-12 d-flex justify-content-end align-items=center">
                    <FormBtn
                      resetForm={handleDataRefreshReset}
                      handleSubmit={handleFormSubmission}
                      handleDraft={handleExpenseProvisionDraft}
                    />
                  </div>
                )}
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleExpenseProvisionDetails}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <ToastContainer
              position={"top-right"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Prapatra_3;
