import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import pulseIcon from "../../../component/assets/images/logo/plus-removebg-preview.png";
import "../Gharkul.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormBtn from "../../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillDelete } from "react-icons/ai";
const VistarGharkul_A = () => {
  const [rows, setRows] = useState([{}]);

  // data when store district taluka month
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [month, setMonth] = useState("");
  const [selectDate, setSelectDate] = useState("");
  const [draftButton,setDraftButton] = useState(true);
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);
  };

  // useEffect(()=>{
  //   handleGharkulDetails();
  // },[])

  // get data on the server
  useEffect(() => {
    if (selectDate) {
      handleGharkulDetails();
    }
  }, [selectDate]);

  const handleGharkulDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        selected_date: selectDate,
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("gharkul response", response.data.data);
      // Check if the response contains gharkul_data
      if (response.data.data.gharkul_data.length > 0) {
        // Update the state with new data
        setVillageDataInfo(response.data.data.other_data);
        setYearInfo(response.data.data.year_info);
        setEconomicData(response.data.data.gharkul_data[0].is_draft);
        setSelectDate(response.data.data.gharkul_data[0].date);
        setMonth(response.data.data.month);

        const initialRows = response.data.data.gharkul_data.map((item) => ({
          id: item.id,
          date: "",
          beneficiary_name: item.beneficiary_name,
          gpt_name: item.gpt_name,
          house_status: item.house_status,
          akher: item.akher,
          comment: item.comment,
          uploaded_photo: "",
          uploaded_doc: "",
        }));
        setRows(initialRows);
      } else {
        // Reset the state when gharkul_data is empty
        const initialRows = response.data.data.gharkul_data.map((item) => ({
          id: "",
          date: "",
          beneficiary_name: "",
          gpt_name: "",
          house_status: "",
          akher: "",
          comment: "",
          uploaded_photo: "",
          uploaded_doc: "",
        }));
        setRows(initialRows);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = dateObject.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // when data updated new then quick load the data in page
  const handleDataRefresh = async () => {
    await handleGharkulDetails(selectDate);
  };

  const handleAddRow = () => {
    const lastId = rows.length > 0 ? rows[rows.length - 1].id : 0;
    const newRow = { id: parseFloat(lastId) + 1 }; // Increment id by 1
    setRows([...rows, newRow]);
  };

  const handleUpdateButtonClick = () => {
    handleGharkulUpdate(rows);
  };

  //  Update Information api

  const handleGharkulUpdate = async (updatedRows) => {
    // e.preventDefault();
    try {
      const updatedRowsWithId = rows.map((row) => ({
        ...row,
        id: row.id || "",
        uploaded_photo: row.uploaded_photo || "",
        uploaded_doc: row.uploaded_doc || "",
        date: row.date || selectDate || "",
      }));
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        selected_date: selectDate,
        gharkul_data: updatedRowsWithId,
      };
      console.log("updateGharkul finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error("Data property not found in API response:", response);
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  // store data in draft
  const handleGharkulDraft = async (updatedRows) => {
    // e.preventDefault();
    try {
      const updatedRowsWithId = rows.map((row) => ({
        ...row,
        id: row.id || "",
        uploaded_photo: row.uploaded_photo || "",
        uploaded_doc: row.uploaded_doc || "",
        date: row.date || selectDate || "",
      }));
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        selected_date: selectDate,
        gharkul_data: updatedRowsWithId,
      };
      console.log("finalData in grarkul draft", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error(
          "Data property not found in API response:",
          response.data
        );
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // delete entry
  const handleDelete = async (id) => {
    try {
      console.log("Deleting entry with id:", id);
      const finalData = {
        id: id,
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/delete_gharkul",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
        const filteredData = rows.filter((item) => item.id !== id);
        setRows(filteredData);
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };

  // add child in table
  const renderRows = () => {
    return rows.length <= 0 ? (
      <tr>
        <td colSpan={9} className="text-center fs-5">
          no data found, please insert data in table
        </td>
      </tr>
    ) : (
      rows.map((rowData, index) => (
        <tr key={index}>
          <td>
            <input
              type="number"
              name="id"
              value={rowData.id || ""}
              onChange={(e) => handleInputChange(e, index)}
              className="form-control table-input-box"
              readOnly
            />
          </td>
          <td>
            <input
              type="text"
              name="beneficiary_name"
              value={rowData.beneficiary_name || ""}
              onChange={(e) => handleInputChange(e, index)}
              style={{ width: "30vh" }}
              className="form-control table-input-box"
              autoFocus
            />
          </td>
          <td>
            <input
              type="text"
              name="gpt_name"
              value={rowData.gpt_name || ""}
              onChange={(e) => handleInputChange(e, index)}
              className="form-control table-input-box"
            />
          </td>

          <td>
            <input
              type="file"
              name="uploaded_photo"
              onChange={(e) => handleInputChange(e, index)}
              className="form-control "
            />
          </td>
          <td>
            <button
              type="submit"
              onClick={() => rowData.id && handleDelete(rowData.id)}
              className="table_delete_btn"
            >
              <AiFillDelete />
            </button>
          </td>
        </tr>
      ))
    );
  };
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>घरकुल विभाग</title>
        </Helmet>
        <div className="container common-section">
          <div className="row">
            <header className="mt-5">
              <h5 style={{ textAlign: "center" }}>
                विस्तार अधिकारी घरकुल विभाग (लाभार्थी निहाय अहवाल ){" "}
                {economicData === "Y" ? (
                  <span className="text-danger">is Drafted</span>
                ) : economicData === "N" ? (
                  <span className="submit_text">
                    <CheckCircleIcon className="text-success" />
                  </span>
                ) : (
                  ""
                )}
              </h5>
            </header>
            <div className="table-responsive mb-3 mt-3 d-flex justify-between">
              <div className="date_gharkul">
                <input
                  type="date"
                  name="selectDate"
                  value={selectDate}
                  onChange={(e) => setSelectDate(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">अ.क्र.</th>
                    <th scope="col">ग्रा.पं. नाव</th>
                    <th scope="col">दिनांक</th>
                    <th scope="col"> अपलोड डॉक्युमेंट </th>
                    <th scope="col">Action </th>
                  </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
              </table>
            </div>
            <div className="col-lg-12">
              <FooterDevOfficer villageDataInfo={villageDataInfo} />
            </div>
            <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
              <FormBtn
                handleSubmit={handleUpdateButtonClick}
                handleDraft={handleGharkulDraft}
                resetForm={handleDataRefresh}
                draftButton={draftButton}
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </React.Fragment>
    </div>
  );
};

export default VistarGharkul_A;
