import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Jansuvidha.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Helmet } from "react-helmet-async";
import FormBtn from "../../component/buttons/Form/FormBtn";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import { ToastContainer, toast } from "react-toastify";
import pulseIcon from "../../component/assets/images/logo/plus-removebg-preview.png";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../../component/Loader/Loader";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdCancel, MdOutlineArrowDownward } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";
const Nagrisuvida = () => {
  const [rows, setRows] = useState([{}]);

  // data when store district taluka month
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [month, setMonth] = useState("");
  const [jandsuvidhaData, setJandsuvidhaData] = useState([]);
  const [data, setData] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);

  const [validationErrors, setValidationErrors] = useState([]);
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);

    // Set the updated rows to state
    setUpdatedRows(updatedRows);

    const errors = validateRow(updatedRows[index]);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = errors;
    setValidationErrors(updatedValidationErrors);
  };

  const handleFileInputChange = (e, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result;
      handleInputChange(
        { target: { name: "completation_file", value: base64Data } },
        index
      );
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const validateRow = (row) => {
    const errors = {};
    if (!row.job_name) {
      errors.job_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.job_name)) {
      errors.job_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.year) {
      errors.year = "महत्वाचे आहे";
    } else if (!/^-?\d+(\.\d+)?(-\d+(\.\d+)?)?$/.test(row.year)) {
      errors.year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (!row.administrative_approval) {
      errors.administrative_approval = "महत्वाचे आहे";
    } else if (
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.administrative_approval)
    ) {
      errors.administrative_approval = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (!row.technical_approval) {
      errors.technical_approval = "महत्वाचे आहे";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.technical_approval)) {
      errors.technical_approval = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (!row.tender_process_done_not) {
      errors.tender_process_done_not = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.tender_process_done_not)
    ) {
      errors.tender_process_done_not = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.work_in_progress) {
      errors.work_in_progress = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.work_in_progress)) {
      errors.work_in_progress = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.work_status) {
      errors.work_status = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.work_status)) {
      errors.work_status = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.job_done) {
      errors.job_done = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.job_done)) {
      errors.job_done = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    if (!row.comments) {
      errors.comments = "कृपया केवळ अक्षरे प्रविष्ट करा";
    } else if (!/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.comments)) {
      errors.comments = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }
    return errors;
  };

  const validateRow1 = (row) => {
    const errors = {};

    if (
      row.job_name !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.job_name)
    ) {
      errors.job_name = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (row.year !== "" && !/^-?\d+(\.\d+)?(-\d+(\.\d+)?)?$/.test(row.year)) {
      errors.year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (
      row.administrative_approval !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.administrative_approval)
    ) {
      errors.administrative_approval = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (
      row.technical_approval !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.technical_approval)
    ) {
      errors.technical_approval = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    if (
      row.tender_process_done_not !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.tender_process_done_not)
    ) {
      errors.tender_process_done_not = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (
      row.work_in_progress !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.work_in_progress)
    ) {
      errors.work_in_progress = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (
      row.work_status !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.work_status)
    ) {
      errors.work_status = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (
      row.job_done !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.job_done)
    ) {
      errors.job_done = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    if (
      row.comments !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(row.comments)
    ) {
      errors.comments = "कृपया केवळ अक्षरे प्रविष्ट करा";
    }

    return errors;
  };

  // get data on the server
  useEffect(() => {
    handleNagrisuvidhaDetails();
  }, []);

  const handleNagrisuvidhaDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/nagri_subidha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("Nagrisuvidha response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      setJandsuvidhaData(response.data.data.nagri_subidha_data);

      if (
        response.data.data.nagri_subidha_data === null ||
        response.data.data.nagri_subidha_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.nagri_subidha_data[0].is_draft);
      }

      setMonth(response.data.data.month);
      setData(response.data.data.jan_subidha_data);
      // updated date show in inputs
      if (response.data.data.nagri_subidha_data > 0){
      const initialRows = response.data.data.nagri_subidha_data.map((item) => ({
        id: item.id,
        job_name: item.job_name,
        year: item.year,
        administrative_approval: item.administrative_approval,
        technical_approval: item.technical_approval,
        tender_process_done_not: item.tender_process_done_not,
        work_in_progress: item.work_in_progress,
        work_status: item.work_status,
        job_done: item.job_done,
        comments: item.comments,
        completation_file: "",
        draft_image: item.completation_file,
      }));
      setRows(initialRows);
    }else{
      setRows([{}]);
    }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // when data updated new then quick load the data in page
  const handleDataRefresh = async () => {
    await handleNagrisuvidhaDetails();
  };

  const handleDataReset = async () => {
    if (jandsuvidhaData.length > 0) {
      await handleNagrisuvidhaDetails();
      setValidationErrors("");
      setRows([{}]);
    } else {
      setRows([{}]);
      setValidationErrors("");
    }
  };

  const handleAddRow = () => {
    const lastId = rows.length > 0 ? rows[rows.length - 1].id : 0;
    const newRow = { id: parseFloat(lastId) + 1 }; // Increment type by 1
    setRows([...rows, newRow]);
  };

  const handleUpdateButtonClick = () => {
    handleNagrisuvidhaUpdate(rows);
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const hasErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        setValidationErrors((prevErrors) => {
          const updatedErrors = [...prevErrors];
          updatedErrors[index] = errors;
          return updatedErrors;
        });
      }
      return Object.keys(errors).length > 0;
    });

    if (hasErrors) {
      await handleNagrisuvidhaUpdate(e);
    } else {
      handleShow();
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  //  Update Information api

  const handleNagrisuvidhaUpdate = async (updatedRows) => {
    // e.preventDefault();

    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      scrollToTop();
      return;
    }

    handleClose();
    try {
      const updatedRowsWithId = rows.map((row) => ({
        ...row,
        id: row.id || "",
        completation_file: row.completation_file || "",
      }));
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        nagri_subidha_data: updatedRowsWithId,
      };
      console.log("updating jansubidha finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_nagri_subidha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error(
          "Data property not found in API response:",
          response.data
        );
      }
    } catch (error) {
      console.log("error occured updating data", error);
    }
  };

  // store data in draft
  const handleAnusuchitDraft = async (updatedRows) => {
    // e.preventDefault();

    const hasValidationErrors = rows.some((row, index) => {
      const errors = validateRow1(row);
      if (Object.keys(errors).length > 0) {
        // Update the validation errors state
        const updatedValidationErrors = [...validationErrors];
        updatedValidationErrors[index] = errors;
        setValidationErrors(updatedValidationErrors);
      }
      return Object.keys(errors).length > 0;
    });

    if (hasValidationErrors) {
      return;
    }

    try {
      const updatedRowsWithId = rows.map((row) => ({
        ...row,
        id: row.id || "",
        completation_file: row.completation_file || "",
      }));
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        nagri_subidha_data: updatedRowsWithId,
      };
      const response = await axios.post(
        API_BASE_URL + "api/draft_nagri_subidha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidationErrors("");
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        console.error(
          "Data property not found in API response:",
          response.data
        );
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // delete entry
  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure want to delete this data !");
    if (!confirm) {
      return;
    }
    try {
      console.log("Deleting entry with id:", id);
      const finalData = {
        id: id,
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/delete_nagri_subidha",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success("Data Deleted Successfully");
        const filteredData = rows.filter((item) => item.id !== id);
        setRows(filteredData);
      }
    } catch (error) {
      console.log("error occured deleting date", error);
    }
  };

  const handleRemoveImage = (index) => {
    // Make a copy of your data
    const updatedData = [...rows];

    // Update the completation_file property to null or an empty string
    updatedData[index] = {
      ...updatedData[index],
      draft_image: null, // or '', depending on your data structure
    };

    // Update your state with the modified data
    setRows(updatedData);
  };

  // row rendering all get data
  const renderRows = () => {
    return rows.map((rowData, index) => (
      <tr key={index}>
        <td>
          <input
            type="number"
            name="id"
            value={index + 1}
            onChange={(e) => handleInputChange(e, index)}
            className="form-control table-input-box"
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            name="job_name"
            value={rowData.job_name || ""}
            onChange={(e) => handleInputChange(e, index)}
            placeholder={
              validationErrors[index]?.job_name
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.job_name ? "is-invalid" : ""
            }`}
            autoFocus
          />
        </td>
        <td>
          <input
            type="text"
            name="year"
            value={rowData.year || ""}
            onChange={(e) => handleInputChange(e, index)}
            placeholder={
              validationErrors[index]?.year
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.year ? "is-invalid" : ""
            }`}
            maxLength={9}
          />
        </td>
        <td>
          {/* <input
            type="text"
            name="administrative_approval"
            value={rowData.administrative_approval || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.administrative_approval
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.administrative_approval
                ? "is-invalid"
                : ""
            }`}
          /> */}
          <select
            name="administrative_approval"
            value={rowData.administrative_approval || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.administrative_approval
                ? "is-invalid"
                : ""
            }`}
            style={{ appearance: "auto", width: "7rem", padding: "0rem" }}
          >
            <option value="">मंजुरी निवडा</option>
            <option value="आहे">आहे </option>
            <option value="नाही">नाही</option>
          </select>
        </td>
        <td>
          {/* <input
            type="text"
            name="technical_approval"
            value={rowData.technical_approval || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            placeholder={
              validationErrors[index]?.technical_approval
                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.technical_approval ? "is-invalid" : ""
            }`}
          /> */}
          <select
            name="technical_approval"
            value={rowData.technical_approval || ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "*"].includes(evt.key) &&
              evt.preventDefault()
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.technical_approval ? "is-invalid" : ""
            }`}
            style={{ appearance: "auto", width: "7rem", padding: "0rem" }}
          >
            <option value="">मंजुरी निवडा</option>
            <option value="आहे">आहे </option>
            <option value="नाही">नाही</option>
          </select>
        </td>
        <td>
          <select
            name="tender_process_done_not"
            value={rowData.tender_process_done_not || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.tender_process_done_not
                ? "is-invalid"
                : ""
            }`}
            style={{ appearance: "auto", width: "7rem", padding: "0rem" }}
          >
            <option value="">प्रक्रिया निवडा</option>
            <option value="झाली">झाली </option>
            <option value="नाही">नाही</option>
          </select>
        </td>
        <td>
          <select
            name="work_in_progress"
            value={rowData.work_in_progress || ""}
            onChange={(e) => handleInputChange(e, index)}
            placeholder={
              validationErrors[index]?.work_in_progress
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.work_in_progress ? "is-invalid" : ""
            }`}
            style={{ appearance: "auto", width: "5rem", padding: "0rem" }}
          >
            <option value="">स्थिती</option>
            <option value="होय">होय </option>
            <option value="नाही">नाही</option>
          </select>
        </td>
        <td>
          <select
            name="work_status"
            value={rowData.work_status || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.work_status ? "is-invalid" : ""
            }`}
            style={{ appearance: "auto", width: "7rem", padding: "0rem" }}
          >
            <option value="">स्थिती निवडा </option>
            <option value="पूर्ण">पूर्ण </option>
            <option value="अपूर्ण">अपूर्ण</option>
          </select>
        </td>
        <td>
          <select
            name="job_done"
            value={rowData.job_done || ""}
            onChange={(e) => handleInputChange(e, index)}
            className={`form-control table-input-box ${
              validationErrors[index]?.job_done ? "is-invalid" : ""
            }`}
            style={{ appearance: "auto", width: "7rem", padding: "0rem" }}
          >
            <option value="">स्थिती निवडा </option>
            <option value="आहे">आहे </option>
            <option value="नाही">नाही</option>
          </select>
        </td>
        <td className="position-relative">
          {rowData.draft_image ? (
            <>
              <div className="table_img_link">
                <div className="table_img_height_width">
                  <div className="remove_icon_of_draft_image">
                    <MdCancel
                      className="text-danger"
                      onClick={() => handleRemoveImage(index)}
                    />
                  </div>
                  <Link to={rowData.draft_image} target="_blank">
                    <img src={rowData.draft_image} alt="" />
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <input
              type="file"
              name="completation_file"
              onChange={(e) => handleFileInputChange(e, index)}
              className="form-control table-input-box"
            />
          )}
        </td>
        <td>
          <input
            type="text"
            name="comments"
            value={rowData.comments || ""}
            onChange={(e) => handleInputChange(e, index)}
            placeholder={
              validationErrors[index]?.comments
                ? "कृपया केवळ अक्षरे प्रविष्ट करा"
                : ""
            }
            className={`form-control table-input-box ${
              validationErrors[index]?.comments ? "is-invalid" : ""
            }`}
          />
        </td>
        {/* <td>
          <button
            type="submit"
            onClick={() => rowData.id && handleDelete(rowData.id)}
            className="table_delete_btn"
          >
            <AiFillDelete />
          </button>
        </td> */}
      </tr>
    ));
  };

  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>नागरीसुविधा विकास विभाग </title>
        </Helmet>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container common-section pt-0">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">
                    ८ . जनसुविधा, नागरीसुविधा, तिर्थक्षेत्र विभाग (ग्रामपंचायत
                    विभाग)
                  </p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="arrow-box">
                    <Link className="text-dark me-5" to="/jansuvidha">
                      अ) जनसुविधा विकास विभाग
                    </Link>
                  </div>
                  <div className="arrow-box-active d-flex flex-column justify-content-center">
                    <Link className="text-decoration-none" to="/nagrisuvidha">
                      ब) नागरीसुविधा विकास विभाग{" "}
                    </Link>
                    <span className="text-center arrow-icon">
                      <ArrowDropDownIcon fontSize="large" color="primary" />
                    </span>
                  </div>
                  <div className="arrow-box me-5">
                    <Link className="text-dark ms-5" to="/tirthshetra">
                      क) तिर्थक्षेत्र विकास विभाग{" "}
                    </Link>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="table-responsive mt-3 py-5 mb-3">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <h5 className="text-center d-flex justify-content-center gap-3">
                      {" "}
                      ब) नागरीसुविधा विकास विभाग{" "}
                      {economicData === "Y" ? (
                        <span className="text-danger">is Drafted</span>
                      ) : economicData === "N" ? (
                        <span className="table_title_top gap-0 submit_text1s ">
                        Submitted{" "}
                        <img
                          src={require("../../component/assets/images/sumbit-success.png")}
                          alt="success"
                          width={30}
                          className="img-fluid"
                        />
                      </span>
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">कामाचे नाव</th>
                      <th scope="col">वर्ष</th>
                      <th scope="col">प्रशासकीय मंजुरी</th>
                      <th scope="col">तांत्रिक मंजुरी</th>
                      <th scope="col">टेंडर प्रक्रिया झाली/नाही</th>
                      <th scope="col"> काम चालू </th>
                      <th scope="col"> कामाची स्थिती </th>
                      <th scope="col"> काम पूर्ण </th>
                      <th scope="col"> काम पूर्ण अपलोड दाखला </th>
                      <th scope="col">शेरा </th>
                      {/* <th scope="col"> Action</th> */}
                    </tr>
                  </thead>
                  {economicData === "N" && (
                    <tbody>
                      {jandsuvidhaData.map((rowData, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{rowData.job_name}</td>
                            <td className="text-center">{rowData.year}</td>
                            <td className="text-center">
                              {rowData.administrative_approval}
                            </td>
                            <td className="text-center">
                              {rowData.technical_approval}
                            </td>
                            <td className="text-center">
                              {rowData.tender_process_done_not}
                            </td>
                            <td className="text-center">
                              {rowData.work_in_progress}
                            </td>
                            <td className="text-center">
                              {rowData.work_status}
                            </td>
                            <td className="text-center">{rowData.job_done}</td>
                            <td className="text-center">
                              <Link
                                to={rowData.completation_file}
                                className="table_img_link"
                                target="_blank"
                              >
                                <div className="table_img_height_width">
                                  <img src={rowData.completation_file} alt="" />
                                </div>
                              </Link>
                            </td>
                            <td className="text-center">{rowData.comments}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                  <tbody>
                    {(economicData === "" || economicData === "Y") &&
                      renderRows()}
                  </tbody>
                  {economicData === "" || economicData === "Y" ? (
                    <div className="plus-icon">
                      <img
                        src={pulseIcon}
                        width={50}
                        className="mt-3"
                        alt="plusIcon"
                        onClick={handleAddRow}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </table>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              {(economicData === "Y" || economicData === "") && (
                <div className="col-lg-12 d-flex justify-content-end align-items=center">
                  <FormBtn
                    handleSubmit={handleFormSubmission}
                    handleDraft={handleAnusuchitDraft}
                    resetForm={handleDataReset}
                  />
                </div>
              )}
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateButtonClick}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <ToastContainer
              position={"top-right"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

export default Nagrisuvida;
