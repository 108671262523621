/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import style from "./styles.module.css";
import "../GeneralAdministrationDepartment/EconomicsYear.css";
import { ToastContainer, toast } from "react-toastify";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { Button } from "@mui/material";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { MdOutlineArrowDownward } from "react-icons/md";

const SheetB = ({
  handleNextStep,
  handlePrevStep,
  currentStep,
  handleStepDetails,
}) => {
  const [sheetData, setSheetData] = useState({
    sr_no: "1",
    panchayat_name: "",
    gpt_name: "",
    starting_year: "",
    established_year: "",
    aggregate_panchayats: "",
    beginning_year_cases: "",
    filed_cases_year: "",
    total_cases: "",
    compromise: "",
    driven_by: "",
    pending_cases_year: "",
    installed: "",
    recovery: "",
    damages_filed: "",
    claims_of_year: "",
    total_claims_of_year: "",
    total_no_of_claims: "",
    compromise_claims_year: "",
    claims_driven_by_year: "",
    ending_year: "",
    shera: "",
  });

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [economicData, setEconomicData] = useState("");

  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetB response", response.data);
      if (response.data.status === true) {
        const data = response.data.data.form_b_data;
        const otherData = response.data.data.other_data;

        const defaultSheetData = {
          sr_no: "1",
          panchayat_name: otherData.taluka_name,
          gpt_name: otherData.village_name,
        };
        if (data !== null) {
          setSheetData({
            ...defaultSheetData,
            starting_year: data.starting_year,
            established_year: data.established_year,
            aggregate_panchayats: data.aggregate_panchayats,
            beginning_year_cases: data.beginning_year_cases,
            filed_cases_year: data.filed_cases_year,
            total_cases: "",
            compromise: data.compromise,
            driven_by: data.driven_by,
            pending_cases_year: data.pending_cases_year,
            installed: data.installed,
            recovery: data.recovery,
            damages_filed: data.damages_filed,
            claims_of_year: data.claims_of_year,
            total_claims_of_year: data.total_claims_of_year,
            total_no_of_claims: "",
            compromise_claims_year: data.compromise_claims_year,
            claims_driven_by_year: data.claims_driven_by_year,
            ending_year: data.ending_year,
            shera: data.shera,
          });
        } else {
          setSheetData({
            ...defaultSheetData,
            starting_year: "",
            established_year: "",
            aggregate_panchayats: "",
            beginning_year_cases: "",
            filed_cases_year: "",
            total_cases: "",
            compromise: "",
            driven_by: "",
            pending_cases_year: "",
            installed: "",
            recovery: "",
            damages_filed: "",
            claims_of_year: "",
            total_claims_of_year: "",
            total_no_of_claims: "",
            compromise_claims_year: "",
            claims_driven_by_year: "",
            ending_year: "",
            shera: "",
          });
        }
      }

      if (
        response.data.data.form_b_data === null ||
        response.data.data.form_b_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_b_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value }));
  };

  // table validation
  const validateForm = () => {
    const newErrors = {};

    // Validate tabels all input

    if (
      sheetData.starting_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.starting_year)
    ) {
      newErrors.starting_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.established_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.established_year)
    ) {
      newErrors.established_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.beginning_year_cases !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.beginning_year_cases)
    ) {
      newErrors.beginning_year_cases = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.filed_cases_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.filed_cases_year)
    ) {
      newErrors.filed_cases_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.total_cases !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.total_cases)
    ) {
      newErrors.total_cases = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.compromise !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.compromise)
    ) {
      newErrors.compromise = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.driven_by !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.driven_by)
    ) {
      newErrors.driven_by = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
   
    if (
      sheetData.installed !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.installed)
    ) {
      newErrors.installed = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.recovery !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.recovery)
    ) {
      newErrors.recovery = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.damages_filed !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.damages_filed)
    ) {
      newErrors.damages_filed = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.claims_of_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.claims_of_year)
    ) {
      newErrors.claims_of_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.total_claims_of_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.total_claims_of_year)
    ) {
      newErrors.total_claims_of_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.total_no_of_claims !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.total_no_of_claims)
    ) {
      newErrors.total_no_of_claims = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.compromise_claims_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.compromise_claims_year)
    ) {
      newErrors.compromise_claims_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.claims_driven_by_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.claims_driven_by_year)
    ) {
      newErrors.claims_driven_by_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    
    if (
      sheetData.shera !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.shera)
    ) {
      newErrors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // update api
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_b_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          starting_year: sheetData.starting_year,
          established_year: sheetData.established_year,
          aggregate_panchayats: TotalCasesStarting || "",
          beginning_year_cases: sheetData.beginning_year_cases,
          filed_cases_year: sheetData.filed_cases_year,
          total_cases: TotalCases,
          compromise: sheetData.compromise,
          driven_by: sheetData.driven_by,
          pending_cases_year: TotalCasesPending,
          installed: sheetData.installed,
          recovery: sheetData.recovery,
          damages_filed: sheetData.damages_filed,
          claims_of_year: sheetData.claims_of_year,
          total_claims_of_year: sheetData.total_claims_of_year,
          total_no_of_claims: TotalNoOfClaims,
          compromise_claims_year: sheetData.compromise_claims_year,
          claims_driven_by_year: sheetData.claims_driven_by_year,
          ending_year: totalClaimsYear,
          shera: sheetData.shera,
        },
      };
      console.log("update finalData sheetB", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetB response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // draft api
  const handleDraftSheetB = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_b_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          starting_year: sheetData.starting_year,
          established_year: sheetData.established_year,
          aggregate_panchayats: TotalCasesStarting || "",
          beginning_year_cases: sheetData.beginning_year_cases,
          filed_cases_year: sheetData.filed_cases_year,
          total_cases: TotalCases,
          compromise: sheetData.compromise,
          driven_by: sheetData.driven_by,
          pending_cases_year: TotalCasesPending,
          installed: sheetData.installed,
          recovery: sheetData.recovery,
          damages_filed: sheetData.damages_filed,
          claims_of_year: sheetData.claims_of_year,
          total_claims_of_year: sheetData.total_claims_of_year,
          total_no_of_claims: TotalNoOfClaims,
          compromise_claims_year: sheetData.compromise_claims_year,
          claims_driven_by_year: sheetData.claims_driven_by_year,
          ending_year: totalClaimsYear,
          shera: sheetData.shera,
        },
      };
      console.log("draftSheetA finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetB response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // totalCases
  const TotalCases =
    parseInt(sheetData.beginning_year_cases || 0) +
      parseInt(sheetData.filed_cases_year || 0) || "";

  //  TotalNoOfClaims
  const TotalNoOfClaims =
    parseInt(sheetData.claims_of_year || 0) +
      parseInt(sheetData.total_claims_of_year || 0) || "";

  // total cases in year starting and year
  const TotalCasesStarting =
    parseInt(sheetData.starting_year || 0) +
      parseInt(sheetData.established_year || 0) || "";

  const totalCompromise =
    parseInt(sheetData.compromise || 0) + parseInt(sheetData.driven_by || 0) ||
    "";
  // total cases in year pending
  const TotalCasesPending = (TotalCases || 0) - (totalCompromise || 0) || "";

  const totalCompromiseYear =
    parseInt(sheetData.compromise_claims_year || 0) +
      parseInt(sheetData.claims_driven_by_year || 0) || "";
  // end of the year all claims
  const totalClaimsYear =
    (TotalNoOfClaims || 0) - (totalCompromiseYear || 0) || "";
  return (
    <>
      <Helmet>
        <title>पत्रक - ब</title>
      </Helmet>
      <div
        id="table-container"
        className={`container-fluid sheet_bg_color_pdf ${style.custom_container} pt-0`}
      >
        <div className={`row`}>
          <div className="col-lg-12">
            <div className="table-main-sec">
              <div className="table_top">
                <div className={`${style.patrak_title_top}`}>
                  <p className="mb-4">
                    ग्रामपंचायती कडील न्याय पंचायतीच्या कामकाजची माहिती
                    दर्शविणारे पत्रक
                    <span className="patrak_box">पत्रक- 'ब' (बी)</span>
                  </p>
                  {economicData === "Y" ? (
                    <span className="drafted_text text-danger mt-3">
                      <FaEdit /> Drafted
                    </span>
                  ) : economicData === "N" ? (
                    <span className="table_title_top gap-0 submit_text1s ">
                      Submitted{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <form action="">
                  <table className={style.table_bordered}>
                    <thead className={`${style.custom_thead}`}>
                      <tr className={`${style.common_tr_border}`}>
                        <th rowSpan={3}>अ.क्र</th>
                        <th rowSpan={3}>ग्रामपंचायत व पंचायत समितीचे नाव</th>
                        <th className="text-center" colSpan={3}>
                          न्याय पंचायती
                        </th>
                        <th className="text-center" colSpan={6}>
                          फौजदारी खटल्यांचा निर्णयाबाबत
                        </th>
                        <th className="text-center" colSpan={3}>
                          दंडाची रक्कम
                        </th>
                        <th className="text-center" colSpan={5}>
                          दावांच्या निर्णयाबाबत
                        </th>
                        <th rowSpan={3}>वर्षाखेरीस</th>
                        <th rowSpan={3}>शेरा</th>
                      </tr>
                      <tr className={`${style.common_tr_border}`}>
                        <th rowSpan={2}>वर्षाच्या सुरूवातीस</th>
                        <th rowSpan={2}>वर्षात स्थापन झालेल्या</th>
                        <th rowSpan={2}>एकूण न्याय पंचायती</th>
                        <th rowSpan={2}>वर्षाच्या सुरूवातीस शिल्लक खटले</th>
                        <th rowSpan={2}>वर्षात दाखल झालेले खटले</th>
                        <th rowSpan={2}>एकूण खटले</th>
                        <th colSpan={2}>वर्षामधील निकालात काढलेले खटले</th>
                        <th rowSpan={2}>वर्षाखेरीस शिल्लक राहिलेले खटले</th>
                        <th rowSpan={2}>
                          <div className={`${style.rotate_col}`}>बसविलेले</div>
                        </th>
                        <th rowSpan={2}>वसूल</th>
                        <th rowSpan={2}>नुकसान दाखल</th>
                        <th rowSpan={2}>वर्षाच्या सुरूवातीस शिल्लक दावे</th>
                        <th rowSpan={2}>वर्षात दाखल झालेले दावे</th>
                        <th rowSpan={2}>दाव्यांची एकूण संख्या</th>
                        <th colSpan={2}>वर्षामध्ये निकालात निघालेले दावे</th>
                      </tr>
                      <tr className={`${style.common_tr_border}`}>
                        <th>
                          <div className={`${style.rotate_col}`}>तडजोड</div>
                        </th>
                        <th>
                          <div className={`${style.rotate_col}`}>चालविलेले</div>
                        </th>
                        <th>तडजोड</th>
                        <th>चालविलेले</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>१</td>
                        <td>२</td>
                        <td>२ अ</td>
                        <td>३</td>
                        <td>४</td>
                        <td>५</td>
                        <td>६</td>
                        <td>७</td>
                        <td>८</td>
                        <td>९</td>
                        <td>१०</td>
                        <td>११</td>
                        <td>१२</td>
                        <td>१३</td>
                        <td>१४</td>
                        <td>१५</td>
                        <td>१६</td>
                        <td>१७</td>
                        <td>१७ अ</td>
                        <td>१८</td>
                        <td>१९</td>
                      </tr>

                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>{sheetData.sr_no}</td>
                        <td>{sheetData.gpt_name}</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.starting_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.starting_year ? errors.starting_year : ""
                            }
                            name="starting_year"
                            value={sheetData.starting_year}
                            onChange={handleChange}
                            autoComplete="off"
                            style={{
                              width:
                                sheetData.starting_year.length <= 3
                                  ? "100%"
                                  : `${sheetData.starting_year.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.established_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.established_year
                                ? errors.established_year
                                : ""
                            }
                            name="established_year"
                            value={sheetData.established_year}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.established_year.length <= 3
                                  ? "100%"
                                  : `${
                                      sheetData.established_year.length * 10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          {/* <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.aggregate_panchayats ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.aggregate_panchayats
                                ? errors.aggregate_panchayats
                                : ""
                            }
                            name="aggregate_panchayats"
                            value={sheetData.aggregate_panchayats}
                            onChange={handleChange}
                            autoComplete="off"
                          /> */}
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={TotalCasesStarting || ""}
                            style={{
                              width: TotalCasesStarting.toString().length <= 2 ? "100%" : `${TotalCasesStarting.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.beginning_year_cases ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.beginning_year_cases
                                ? errors.beginning_year_cases
                                : ""
                            }
                            name="beginning_year_cases"
                            value={sheetData.beginning_year_cases}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.beginning_year_cases.length <= 3
                                  ? "100%"
                                  : `${
                                      sheetData.beginning_year_cases.length * 10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.filed_cases_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.filed_cases_year
                                ? errors.filed_cases_year
                                : ""
                            }
                            name="filed_cases_year"
                            value={sheetData.filed_cases_year}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.filed_cases_year.length <= 3
                                  ? "100%"
                                  : `${
                                      sheetData.filed_cases_year.length * 10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box fw-bold `}
                            // placeholder={
                            //   errors.total_cases ? errors.total_cases : ""
                            // }
                            // name="total_cases"
                            value={TotalCases}
                            onChange={handleChange}
                            style={{
                              width: TotalCases.toString().length <= 2 ? "100%" : `${TotalCases.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.compromise ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.compromise ? errors.compromise : ""
                            }
                            name="compromise"
                            value={sheetData.compromise}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.compromise.length <= 3
                                  ? "100%"
                                  : `${sheetData.compromise.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.driven_by ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.driven_by ? errors.driven_by : ""
                            }
                            name="driven_by"
                            value={sheetData.driven_by}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.driven_by.length <= 3
                                  ? "100%"
                                  : `${sheetData.driven_by.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          {/* <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.pending_cases_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.pending_cases_year
                                ? errors.pending_cases_year
                                : ""
                            }
                            name="pending_cases_year"
                            value={sheetData.pending_cases_year}
                            onChange={handleChange}
                            autoComplete="off"
                          /> */}
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={TotalCasesPending || ""}
                            style={{
                              width:
                                TotalCasesPending.toString().length <= 2
                                  ? "30px"
                                  : `${TotalCasesPending.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.installed ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.installed ? errors.installed : ""
                            }
                            name="installed"
                            value={sheetData.installed}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.installed.length <= 3
                                  ? "100%"
                                  : `${sheetData.installed.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.recovery ? "is-invalid" : ""
                            }`}
                            placeholder={errors.recovery ? errors.recovery : ""}
                            name="recovery"
                            value={sheetData.recovery}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.recovery.length <= 3
                                  ? "100%"
                                  : `${sheetData.recovery.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.damages_filed ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.damages_filed ? errors.damages_filed : ""
                            }
                            name="damages_filed"
                            value={sheetData.damages_filed}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.damages_filed.length <= 3
                                  ? "100%"
                                  : `${sheetData.damages_filed.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.claims_of_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.claims_of_year ? errors.claims_of_year : ""
                            }
                            name="claims_of_year"
                            value={sheetData.claims_of_year}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.claims_of_year.length <= 3
                                  ? "100%"
                                  : `${sheetData.claims_of_year.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.total_claims_of_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.total_claims_of_year
                                ? errors.total_claims_of_year
                                : ""
                            }
                            name="total_claims_of_year"
                            value={sheetData.total_claims_of_year}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.total_claims_of_year.length <= 3
                                  ? "100%"
                                  : `${
                                      sheetData.total_claims_of_year.length * 10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box fw-bold `}
                            // placeholder={
                            //   errors.total_no_of_claims
                            //     ? errors.total_no_of_claims
                            //     : ""
                            // }
                            // name="total_no_of_claims"
                            value={TotalNoOfClaims}
                            onChange={handleChange}
                            style={{
                              width:
                                TotalNoOfClaims.toString().length <= 2
                                  ? "30px"
                                  : `${
                                      TotalNoOfClaims.toString().length * 12
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.compromise_claims_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.compromise_claims_year
                                ? errors.compromise_claims_year
                                : ""
                            }
                            name="compromise_claims_year"
                            value={sheetData.compromise_claims_year}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.compromise_claims_year.length <= 3
                                  ? "100%"
                                  : `${
                                      sheetData.compromise_claims_year.length *
                                      10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.claims_driven_by_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.claims_driven_by_year
                                ? errors.claims_driven_by_year
                                : ""
                            }
                            name="claims_driven_by_year"
                            value={sheetData.claims_driven_by_year}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.claims_driven_by_year.length <= 3
                                  ? "100%"
                                  : `${
                                      sheetData.claims_driven_by_year.length *
                                      10
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          {/* <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.ending_year ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.ending_year ? errors.ending_year : ""
                            }
                            name="ending_year"
                            value={sheetData.ending_year}
                            onChange={handleChange}
                            autoComplete="off"
                          /> */}
                          <input
                            type="text"
                            value={totalClaimsYear}
                            className="form-control table-input-box fw-bold"
                            style={{
                              width:
                                totalClaimsYear.toString().length <= 2
                                  ? "30px"
                                  : `${
                                      totalClaimsYear.toString().length * 12
                                    }px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.shera ? "is-invalid" : ""
                            }`}
                            placeholder={errors.shera ? errors.shera : ""}
                            name="shera"
                            value={sheetData.shera}
                            onChange={handleChange}
                            style={{
                              width:
                                sheetData.shera.length <= 3
                                  ? "100%"
                                  : `${sheetData.shera.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className={style.form_btn_space}>
                <div className="col-lg-12 d-flex justify-content-end align-items=center">
                  <FormBtn
                    handleSubmit={handleSubmit}
                    resetForm={handleSubmit}
                    resetButton={true}
                    handleDraft={handleDraftSheetB}
                  />
                  {/* {(economicData === "" || economicData === "Y") && (
                    <FormBtn
                      handleSubmit={handleShow}
                      resetForm={handleSubmit}
                      resetButton={true}
                      handleDraft={handleDraftSheetB}
                    />
                  )} */}
                </div>

                <div className="">
                  <div className={style.btn_position}>
                    {currentStep > 1 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handlePrevStep}
                      >
                        Previous
                      </Button>
                    )}

                    {currentStep < 10 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handleNextStep}
                      >
                        Next
                      </Button>
                    )}

                    {currentStep === 10 && (
                      <button
                        type="submit"
                        className={style.btn_generate}
                        // onClick={handlePrint}
                      >
                        Generate Pdf
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* {economicData === "N" && (
                <div className="col-lg-12 mt-3">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )} */}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-center"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default SheetB;
