import React from "react";
import style from "./dashboardSkeleton.module.css";
const DashboardSkeleton = () => {
  const data = ["1", "2", "3", "4", "5"];
  const data1 = ["1", "2", "3", "4"];
  return (
    <section className={style.skeleton_main}>
      <div className="container-fluid">
        <div className={`row ${style.ske_row}`}>
          <div className={`col-lg-12  ${style.ske_col}`}>
            <div className={`${style.skeleton_popular_card}`}>
              {data.map((elem, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={`${style.skeleton_image}`}></div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className={`col-lg-12 ${style.ske_col} ${style.ske_col_none}`}>
            <div className={`${style.skeleton_popular_card}`}>
              {data1.map((elem, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={`${style.skeleton_image}`}></div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className={`col-lg-12 ${style.ske_col} ${style.ske_col_none}`}>
            <div className={`${style.skeleton_popular_card}`}>
              {data1.map((elem, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={`${style.skeleton_image}`}></div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardSkeleton;
