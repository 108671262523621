// CircularProgressBar.js
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularWithValueLabel = ({ value }) => {
  return (
    <div style={{ width: "40px", margin: "auto" }}>
      <CircularProgressbar
        value={value}
        text={`${value}%`}
        styles={buildStyles({
          textColor: "#fff",
          textSize: "28px",
          pathColor: "#b2ecfa",
          trailColor: "#12728a",
        })}
      />
    </div>
  );
};

export default CircularWithValueLabel;
