import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import { MdNotifications } from "react-icons/md";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
const Home = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notice, setNotice] = useState([]);
  const [notification, setNotification] = useState([]);
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  if (userDataInfo !== null) {
    var userInfo = JSON.parse(userDataInfo);
    var userDetails = userInfo.user_details;
  } else {
    console.error("User data not found in sessionStorage");
  }

  const handleNotificationClick = () => {
    // Open notification list or perform any other action
    setShowNotifications(true);
    // toaster.danger('13 Notification is Pending', { duration: 3 });
  };

  const handleNotificationDoubleClick = () => {
    // Hide notification list on double-click
    setShowNotifications((prev) => !prev);
  };

  useEffect(() => {
    getDashBoardData();
    getDashBoardNotification();
  }, []);

  // dashboard notice api
  const getDashBoardData = async () => {
    try {
      const finalData = {
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/dashboard_notice",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("response", response.data.data.notices);
      setNotice(response.data.data.notices.slice(0, 2));
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  // dashboard notification
  const getDashBoardNotification = async () => {
    try {
      const finalData = {
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/my_notification",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("added notice response", response.data.data);
      setNotification(response.data.data.notifications);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row d-flex justify-content-start align-items-center">
          {/* <div className="col-lg-12 mt-3 mb-0 pb-0">
            <div className="notification-sec d-flex float-end me-3">
              <h5 className="text-danger me-2">notification </h5> <span><NotificationsActiveIcon/><sup className="fw-bold">३</sup> </span>
            </div>
          </div> */}
          <div className="col-lg-12 mt-2">
            <div className="card notice-main">
              <div className="notice-page-title mt-3 d-flex justify-content-between">
                <h5 className="notice-heading pt-2">नोटीस बोर्ड</h5>
                <div className="notification-alert d-flex">
                  <Dropdown>
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="navbar-dropdown-profile fw-bold custom-dropdown"
                      title={
                        <div className="notification-alert d-flex">
                          <h5 className="text-primary fw-bold">सूचना </h5>
                          <MdNotifications
                            className="fs-4"
                            style={{ color: "#1284ed" }}
                          />
                          <span className="fw-bold notification-alert-message">
                            {notification.length}
                          </span>
                        </div>
                      }
                      onClick={handleNotificationClick}
                    >
                      {showNotifications && (
                        <Dropdown.Item>
                          {notification.map((item) => {
                            return (
                              <>
                                <Link key={item.id} to={`${item.onclick}`}>
                                  {item.message}
                                </Link>
                              </>
                            );
                          })}
                          <br />
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </Dropdown>
                </div>
              </div>
              <div className="card-body notice-body custom-dropdown">
                {notice &&
                  notice.map((item, index) => (
                    <Link to={`${item.onclick}`} key={item.id}>
                      {" "}
                      <h6>
                        {index + 1}. {item.message}
                      </h6>{" "}
                    </Link>
                  ))}
                <Link to="/notice_board" className="btn_view">
                  अधिक पाहा <MdKeyboardDoubleArrowDown />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4">
            <div className="card information-import-main">
              <h5 className="notice-heading mb-4">माहिती मागविणे</h5>
              <div className="card-body information-import-body"></div>
            </div>
          </div>
          <div className="col-lg-8 mt-4">
            <div className="card incomplete-info-main">
              <h5 className="notice-heading mb-4"> अपूर्ण माहिती अलर्ट</h5>
              <div className="card-body incomplete-info-body"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
