/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../Janm_Mrutu.css";
import { Helmet } from "react-helmet-async";

import axios from "axios";
import style from "../../GeneralAdministrationDepartment/districtEconomics/style.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import Loader from "../../../component/Loader/Loader";

const BirthDeathReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [month, setMonth] = useState("");
  const [birthData, setBirthData] = useState([]);

  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  // January month state

  // जन्म मृत्यू अहवाल Get Information api

  const handleBirthDeathDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        year_id:userDetails.current_year_id
      };
      const response = await axios.post(
        API_BASE_URL + "api/summary_birth_death_report",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("BirthDeathDetails summary response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      // setEconomicData(response.data.data.birth_death_report_data[0].is_draft);
      setMonth(response.data.data.month);
      setBirthData(response.data.data.birth_death_report_data);
      // updated values January month inputs
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleBirthDeathDetails();
  }, [talId, villageId]);

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };


  
  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id: userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  // Calculate totals
  const calculateTotals = () => {
    let totalBirthMale = 0;
    let totalBirthWoman = 0;
    let totalDeathMale = 0;
    let totalDeathWoman = 0;

    birthData.forEach((item) => {
      totalBirthMale += parseFloat(item.birth_male) || 0;
      totalBirthWoman += parseFloat(item.birth_woman) || 0;
      totalDeathMale += parseFloat(item.death_male) || 0;
      totalDeathWoman += parseFloat(item.death_woman) || 0;
    });

    return {
      totalBirthMale,
      totalBirthWoman,
      totalDeathMale,
      totalDeathWoman,
    };
  };

  const totals = calculateTotals();
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Helmet>
            <title>जन्म मृत्यू अहवाल </title>
          </Helmet>
          <div className="container common-section">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2"># जन्म मृत्यू अहवाल</p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="d-flex flex-column justify-content-center">
                    <div className={`${style.select_group_top}`}>
                      <label>जिल्हा: </label>
                      <span className="">
                        {villageDataInfo.district_name}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-5">
                    <div className={`${style.select_group_top}`}>
                      <label htmlFor="">तालुका: </label>
                      <select
                        name="taluka"
                        className="taluka_select_input"
                        onChange={(e) => handleOnchangeTaluka(e, talId)}
                        value={talId}
                      >
                        {userDetails.role_id === "7" ? (
                          <option>निवडा</option>
                        ) : (
                          ""
                        )}
                        {tal.map((ele) => {
                          return (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-5">
                    <div className={`${style.select_group_top}`}>
                      <label htmlFor="">ग्रामपंचयात: </label>
                      <Autocomplete
                        disablePortal
                        className={`${style.select_common_dropdown}`}
                        options={village}
                        sx={{ width: 200 }}
                        onChange={(e, selectedOption) =>
                          handleVillage(selectedOption)
                        }
                        value={
                          villageId
                            ? village.find((v) => v.value === villageId) || null
                            : null
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="निवडा" />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive mb-3 mt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" rowSpan={2}>
                        अ.क्र.
                      </th>
                      <th scope="col" rowSpan={2}>
                        महिना
                      </th>
                      <th className="text-center" scope="col" colSpan={2}>
                        जन्म
                      </th>
                      <th className="text-center" scope="col" colSpan={2}>
                        मृत्यू
                      </th>
                      <th className="text-center" scope="col" colSpan={2}>
                        एकूण
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">पुरुष </th>
                      <th scope="col">स्त्री</th>
                      <th scope="col">पुरुष</th>
                      <th scope="col">स्त्री</th>
                      <th scope="col">पुरुष</th>
                      <th scope="col">स्त्री</th>
                    </tr>
                  </thead>
                  <tbody>
                    {birthData
                      ? birthData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.month}</td>
                              <td>{item.birth_male}</td>
                              <td>{item.birth_woman}</td>
                              <td>{item.death_male}</td>
                              <td>{item.death_woman}</td>
                              <td className="fw-bold">{parseInt(item.birth_male)+parseInt(item.death_male)}</td>
                              <td className="fw-bold">{parseInt(item.birth_woman)+parseInt(item.death_woman)}</td>
                            </tr>
                          );
                        })
                      : ""}
                    {/* total */}
                    <tr>
                      <td>
                        <label htmlFor="">एकूण</label>
                      </td>
                      <td></td>
                      <td className="fw-bold">{totals.totalBirthMale}</td>
                      <td className="fw-bold">{totals.totalBirthWoman}</td>
                      <td className="fw-bold">{totals.totalDeathMale}</td>
                      <td className="fw-bold">{totals.totalDeathWoman}</td>
                      <td className="fw-bold">
                        {totals.totalBirthMale + totals.totalDeathMale}
                      </td>
                      <td className="fw-bold">
                        {totals.totalBirthWoman + totals.totalDeathWoman}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default BirthDeathReport;
