
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../../AnusuchitJati/Anusuchit.css";
import axios from "axios";
import style from "../../AnusuchitJati/districtAnusuchit/style.module.css";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";

const DistrictSwatchBharat = () => {
  // data when store district taluka month
  const [isLoading, setIsLoading] = useState(true);
  const [yearInfo, setYearInfo] = useState("");
  const [districtInfo, setDistrictInfo] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  // get data on the session
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const [anusuchitData, setAnusuchitData] = useState([]);
  const [tal, setTal] = useState([]);
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    // Clear the village and villageId states when a new taluka is selected
    setVillage([]); // Reset the village list
    setVillageId(""); // Reset the selected village ID
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  useEffect(() => {
    handleAnusuchitDetails();
  }, [talId, villageId, month]);

  const handleAnusuchitDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        month:month
      };
      const response = await axios.post(
        API_BASE_URL + "api/summary_swachh_bharat",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("Swach bharat Data response", response);
      setAnusuchitData(response.data.data);
      setYearInfo(response.data.data.year_info);
      setDistrictInfo(response.data.data.other_data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id !== "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };

  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id: userDetails.role_id === "7" ? talukaId : userDetails.taluka_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>स्वच्छ भारत अभियान</title>
        </Helmet>
        <div className="container-fluid common-section common_deputy_container">
          <div className="row common_deputy_row">
            <div className="col-lg-12 navigate-section-header mt-3">
              <div className="page-title">
                <p className="fw-bold mt-3"># स्वच्छ भारत अभियान </p>
              </div>
              <div className="navigate-section d-flex">
                <div className="d-flex flex-column justify-content-center">
                  <div className={`${style.select_group_top}`}>
                    <label>जिल्हा: </label>
                    <span className="">
                      {districtInfo.district_name}
                    </span>
                  </div>
                </div>
                <div className="VillagePanchayat d-flex gap-2 ms-5 justify-content-center align-items-center">
                  <label htmlFor="">माहे :</label>
                  {month === undefined ? (
                    <span></span>
                  ) : (
                    <select
                      name="month"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="taluka_select_input"
                    >
                      {/* <option value="" className="fw-bold">
                          महिना निवडा
                        </option> */}
                      <option value="1">जानेवारी</option>
                      <option value="2">फेब्रुवारी</option>
                      <option value="3">मार्च</option>
                      <option value="4">एप्रिल</option>
                      <option value="5">मे</option>
                      <option value="6">जून</option>
                      <option value="7">जुलै</option>
                      <option value="8">ऑगस्ट</option>
                      <option value="9">सप्टेंबर</option>
                      <option value="10">ऑक्टोंबर</option>
                      <option value="11">नोव्हेंबर</option>
                      <option value="12">डिसेंबर</option>
                    </select>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-center ms-5">
                  <div className={`${style.select_group_top}`}>
                    <label htmlFor="">तालुका: </label>
                    <select
                      name="taluka"
                      className="taluka_select_input"
                      onChange={(e) => handleOnchangeTaluka(e, talId)}
                      value={talId}
                    >
                      {userDetails.role_id === "7" ? (
                        <option>निवडा</option>
                      ) : (
                        ""
                      )}
                      {tal.map((ele) => {
                        return (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center ms-5">
                  <div className={`${style.select_group_top}`}>
                    <label htmlFor="">ग्रामपंचयात: </label>
                    <Autocomplete
                      disablePortal
                      className={`${style.select_common_dropdown}`}
                      options={village}
                      sx={{ width: 200 }}
                      onChange={(e, selectedOption) =>
                        handleVillage(selectedOption)
                      }
                      value={
                        villageId
                          ? village.find((v) => v.value === villageId) || null
                          : null
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="निवडा" />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            {anusuchitData.level === "District" ||
            anusuchitData.level === "Taluka" ? (
              <div className="row">
                <h4 className="mt-4">
                  Level :- <i>{anusuchitData.level}</i>{" "}
                </h4>
                <div className="col-lg-6 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <h4>स्वच्छ भारत अभियान </h4>
                      <h1 className="float-end">
                        {" "}
                        {anusuchitData.swachh_bharat_count}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {anusuchitData.level === "Village" && (
              <div className="table-responsive pe-0 ps-0 mt-4  mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.क्र.</th>
                      <th scope="col">लाभार्थ्याचे नाव</th>
                      <th scope="col">ग्रा.पं. नाव</th>
                      <th scope="col">शौच्छालय स्थिती</th>
                      <th scope="col">अखेर</th>
                      <th scope="col">अपलोड फोटो</th>
                      <th scope="col"> अपलोड डॉक्युमेंट </th>
                      <th scope="col">शेरा </th>
                    </tr>
                  </thead>
                  <tbody>
                    {anusuchitData.swachh_bharat_data.length <= 0 ? (
                      <tr>
                        <td>1</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>N/A</td>
                      </tr>
                    ) : (
                      anusuchitData.swachh_bharat_data.map((ele, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{ele.beneficiary_name}</td>
                            <td>{ele.gpt_name}</td>
                            <td>{ele.house_status}</td>
                            <td>{ele.akher}</td>
                            <td>
                              <div className="table_img_link">
                                <div className="table_img_height_width">
                                  <Link
                                    to={ele.uploaded_photo}
                                    target="_blank"
                                  >
                                    <img
                                      src={ele.uploaded_photo}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="table_img_link">
                                <div className="table_img_height_width">
                                  <Link
                                    to={ele.uploaded_doc}
                                    target="_blank"
                                  >
                                    <img
                                      src={ele.uploaded_doc}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>{ele.comment}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default DistrictSwatchBharat;

