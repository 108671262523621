import React from "react";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";

const FormBtn = ({
  resetForm,
  handleDownloadPDF,
  handleSubmit,
  handleDraft,
  draftButton,
  resetButton,
  isEditing,
}) => {
  return (
    <div className="button-sec mb-5 d-flex ">
      {isEditing ? (
        <Button
          variant="contained"
          // onClick={handleDownloadPDF}
          onClick={handleSubmit}
          endIcon={<SendIcon />}
        >
          Update
        </Button>
      ) : (
        <Button
          variant="contained"
          // onClick={handleDownloadPDF}
          onClick={handleSubmit}
          endIcon={<SendIcon />}
        >
          Submit
        </Button>
      )}
      {draftButton ? (
        ""
      ) : (
        <Button
          variant="contained"
          className="ms-2"
          endIcon={<DriveFileRenameOutlineRoundedIcon />}
          style={{ background: "black" }}
          onClick={handleDraft}
        >
          Draft
        </Button>
      )}
      {resetButton ? (
        ""
      ):(
        <Button
          variant="contained"
          className="ms-2"
          endIcon={<RestartAltRoundedIcon />}
          style={{ background: "red" }}
          onClick={resetForm}
        >
          Reset
        </Button>
      )}
    </div>
  );
};

export default FormBtn;
