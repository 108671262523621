import React, { useEffect, useState } from "react";
import styles from "../style.module.css";
import { Button } from "@mui/material";
import FormBtn from "../../../component/buttons/Form/FormBtn";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

const GramNidhiSampleNoFour = ({
  handleNextStep,
  handlePrevStep,
  currentStep,
  handleStepDetails
}) => {
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [economicData, setEconomicData] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [previousTotal, setPreviousTotal] = useState("");
  const [sheetData, setSheetData] = useState({
    gpt_name: "",
    taluka_name: "",
    district_name: "",
    general_administration: "",
    money_general_administration: "",
    clerk_salary: "",
    money_clerk_salary: "",
    peon_salary: "",
    money_peon_salary: "",
    traveling_allowance: "",
    money_traveling_allowance: "",
    member: "",
    money_member: "",
    sarpanch_emoluments: "",
    money_sarpanch_emoluments: "",
    member_meeting_allowance: "",
    money_member_meeting_allowance: "",
    office_expenses: "",
    money_office_expenses: "",
    office_light_bill: "",
    money_office_light_bill: "",
    office_retail: "",
    money_office_retail: "",
    guest_allowance: "",
    money_guest_allowance: "",
    telephone_costs: "",
    money_telephone_costs: "",
    material_supply: "",
    money_material_supply: "",
    water_supply: "",
    money_water_supply: "",
    means_communication: "",
    money_means_communication: "",
    road_construction: "",
    money_road_construction: "",
    sewer_construction: "",
    money_sewer_construction: "",
    road_sewer_repair: "",
    money_road_sewer_repair: "",
    health_related: "",
    money_health_related: "",
    employee_salary: "",
    money_employee_salary: "",
    health_restored: "",
    money_health_restored: "",
    drainage_construction: "",
    money_drainage_construction: "",
    manure_pits: "",
    money_manure_pits: "",
    cleanliness: "",
    money_cleanliness: "",
    lamp_regarding: "",
    money_lamp_regarding: "",
    material: "",
    money_material: "",
    road_light_bill: "",
    money_road_light_bill: "",
    other: "",
    other_money: "",
    fair_expanse: "",
    money_fair_expanse: "",
    fair_salary: "",
    money_fair_salary: "",
    other_fair: "",
    money_other_fair: "",
    kindergarten: "",
    money_kindergarten: "",
    purchase_management: "",
    money_purchase_management: "",
    kondwara_employment: "",
    money_kondwara_employment: "",
    ethical_costs: "",
    money_ethical_costs: "",
    vested_pt_buildings: "",
    money_vested_pt_buildings: "",
    medical_assistant: "",
    money_medical_assistant: "",
    child_welfare: "",
    money_child_welfare: "",
    public_health: "",
    money_public_health: "",
    education: "",
    money_education: "",
    cultural_programs: "",
    money_cultural_programs: "",
    public_welfare: "",
    money_public_welfare: "",
    village_protection: "",
    money_village_protection: "",
    agriculture: "",
    money_agriculture: "",
    animal_husbandry: "",
    money_animal_husbandry: "",
    tv: "",
    money_tv: "",
    work_public_utility: "",
    money_public_utility: "",
    e_grav_program: "",
    money_e_grav_program: "",
    machinery: "",
    money_machinery: "",
    deadstock: "",
    money_deadstock: "",
    subscription_fund: "",
    money_subscription_fund: "",
    dist_loan: "",
    money_dist_loan: "",
    backward_class: "",
    money_backward_class: "",
    other_retail: "",
    money_other_retail: "",
    loan_advances: "",
    money_loan_advances: "",
    pay_loan: "",
    money_pay_loan: "",
    payment_deposit_amt: "",
    money_payment_deposit_amt: "",
    advance: "",
    money_advance: "",
    village_school: "",
    money_village_school: "",
    jp_pt_section: "",
    money_jp_pt_section: "",
    work_expanse: "",
    money_work_expanse: "",
    motor_insurance: "",
    money_motor_insurance: "",
    give_away: "",
    money_give_away: "",
    other_royalty_fund: "",
    money_other_royalty_fund: "",
    income_tax: "",
    money_income_tax: "",
    total_amount_receive_year: "",
    money_total_amount_receive_year: "",
    final_balance: "",
    money_final_balance: "",
    total: "",
    money_total: "",
  });

  const [errors, setErrors] = useState({});

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_i",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetI response", response.data.data);
      if (response.data.status === true) {
        setPreviousTotal(response.data.data.form_h_total);
        const data = response.data.data.form_i_data;
        const otherData = response.data.data.other_data;
        setYearInfo(response.data.data.year_info);
        const defaultSheetData = {
          sr_no: "1",
          gpt_name: otherData.village_name,
          taluka_name: otherData.taluka_name,
          district_name: otherData.district_name,
          // ... (other fields with default values)
        };
        if (data !== null) {
          var parts = data.general_administration.toString().split(".");
          var parts1 = data.clerk_salary.toString().split(".");
          var parts2 = data.peon_salary.toString().split(".");
          var parts3 = data.traveling_allowance.toString().split(".");
          var parts4 = data.member.toString().split(".");
          var parts5 = data.sarpanch_emoluments.toString().split(".");
          var parts6 = data.member_meeting_allowance.toString().split(".");
          var parts7 = data.office_expenses.toString().split(".");
          var parts8 = data.office_light_bill.toString().split(".");
          var parts9 = data.office_retail.toString().split(".");
          var parts10 = data.guest_allowance.toString().split(".");
          var parts11 = data.telephone_costs.toString().split(".");
          var parts12 = data.material_supply.toString().split(".");
          var parts13 = data.water_supply.toString().split(".");
          var parts14 = data.means_communication.toString().split(".");
          var parts15 = data.road_construction.toString().split(".");
          var parts16 = data.sewer_construction.toString().split(".");
          var parts17 = data.road_sewer_repair.toString().split(".");
          var parts18 = data.health_related.toString().split(".");
          var parts19 = data.employee_salary.toString().split(".");
          var parts20 = data.health_restored.toString().split(".");
          var parts21 = data.drainage_construction.toString().split(".");
          var parts22 = data.manure_pits.toString().split(".");
          var parts23 = data.cleanliness.toString().split(".");
          var parts24 = data.lamp_regarding.toString().split(".");
          var parts25 = data.material.toString().split(".");
          var parts26 = data.road_light_bill.toString().split(".");
          var parts27 = data.other.toString().split(".");
          var parts28 = data.fair_expanse.toString().split(".");
          var parts29 = data.fair_salary.toString().split(".");
          var parts30 = data.other_fair.toString().split(".");
          var parts31 = data.kindergarten.toString().split(".");
          var parts32 = data.purchase_management.toString().split(".");
          var parts33 = data.kondwara_employment.toString().split(".");
          var parts34 = data.ethical_costs.toString().split(".");
          var parts35 = data.vested_pt_buildings.toString().split(".");
          var parts36 = data.medical_assistant.toString().split(".");
          var parts37 = data.child_welfare.toString().split(".");
          var parts38 = data.public_health.toString().split(".");
          var parts39 = data.education.toString().split(".");
          var parts40 = data.cultural_programs.toString().split(".");
          var parts41 = data.public_welfare.toString().split(".");
          var parts42 = data.village_protection.toString().split(".");
          var parts43 = data.agriculture.toString().split(".");
          var parts44 = data.animal_husbandry.toString().split(".");
          var parts45 = data.tv.toString().split(".");
          var parts46 = data.work_public_utility.toString().split(".");
          var parts47 = data.e_grav_program.toString().split(".");
          var parts48 = data.machinery.toString().split(".");
          var parts49 = data.deadstock.toString().split(".");
          var parts50 = data.subscription_fund.toString().split(".");
          var parts51 = data.dist_loan.toString().split(".");
          var parts52 = data.backward_class.toString().split(".");
          var parts53 = data.other_retail.toString().split(".");
          var parts54 = data.loan_advances.toString().split(".");
          var parts55 = data.pay_loan.toString().split(".");
          var parts56 = data.payment_deposit_amt.toString().split(".");
          var parts57 = data.advance.toString().split(".");
          var parts58 = data.village_school.toString().split(".");
          var parts59 = data.jp_pt_section.toString().split(".");
          var parts60 = data.work_expanse.toString().split(".");
          var parts61 = data.motor_insurance.toString().split(".");
          var parts62 = data.give_away.toString().split(".");
          var parts63 = data.other_royalty_fund.toString().split(".");
          var parts64 = data.income_tax.toString().split(".");

          setSheetData({
            ...defaultSheetData,
            general_administration: parseInt(parts[0]) || "",
            money_general_administration: parseInt(parts[1]) || "",
            clerk_salary: parseInt(parts1[0]) || "",
            money_clerk_salary: parseInt(parts1[1]) || "",
            peon_salary: parseInt(parts2[0]) || "",
            money_peon_salary: parseInt(parts2[1]) || "",
            traveling_allowance: parseInt(parts3[0]) || "",
            money_traveling_allowance: parseInt(parts3[1]) || "",
            member: parseInt(parts4[0]) || "",
            money_member: parseInt(parts4[1]) || "",
            sarpanch_emoluments: parseInt(parts5[0]) || "",
            money_sarpanch_emoluments: parseInt(parts5[1]) || "",
            member_meeting_allowance: parseInt(parts6[0]) || "",
            money_member_meeting_allowance: parseInt(parts6[1]) || "",
            office_expenses: parseInt(parts7[0]) || "",
            money_office_expenses: parseInt(parts7[1]) || "",
            office_light_bill: parseInt(parts8[0]) || "",
            money_office_light_bill: parseInt(parts8[1]) || "",
            office_retail: parseInt(parts9[0]) || "",
            money_office_retail: parseInt(parts9[1]) || "",
            guest_allowance: parseInt(parts10[0]) || "",
            money_guest_allowance: parseInt(parts10[1]) || "",
            telephone_costs: parseInt(parts11[0]) || "",
            money_telephone_costs: parseInt(parts11[1]) || "",
            material_supply: parseInt(parts12[0]) || "",
            money_material_supply: parseInt(parts12[1]) || "",
            water_supply: parseInt(parts13[0]) || "",
            money_water_supply: parseInt(parts13[1]) || "",
            means_communication: parseInt(parts14[0]) || "",
            money_means_communication: parseInt(parts14[1]) || "",
            road_construction: parseInt(parts15[0]) || "",
            money_road_construction: parseInt(parts15[1]) || "",
            sewer_construction: parseInt(parts16[0]) || "",
            money_sewer_construction: parseInt(parts16[1]) || "",
            road_sewer_repair: parseInt(parts17[0]) || "",
            money_road_sewer_repair: parseInt(parts17[1]) || "",
            health_related: parseInt(parts18[0]) || "",
            money_health_related: parseInt(parts18[1]) || "",
            employee_salary: parseInt(parts19[0]) || "",
            money_employee_salary: parseInt(parts19[1]) || "",
            health_restored: parseInt(parts20[0]) || "",
            money_health_restored: parseInt(parts20[1]) || "",
            drainage_construction: parseInt(parts21[0]) || "",
            money_drainage_construction: parseInt(parts21[1]) || "",
            manure_pits: parseInt(parts22[0]) || "",
            money_manure_pits: parseInt(parts22[1]) || "",
            cleanliness: parseInt(parts23[0]) || "",
            money_cleanliness: parseInt(parts23[1]) || "",
            lamp_regarding: parseInt(parts24[0]) || "",
            money_lamp_regarding: parseInt(parts24[1]) || "",
            material: parseInt(parts25[0]) || "",
            money_material: parseInt(parts25[1]) || "",
            road_light_bill: parseInt(parts26[0]) || "",
            money_road_light_bill: parseInt(parts26[1]) || "",
            other: parseInt(parts27[0]) || "",
            other_money: parseInt(parts27[1]) || "",
            fair_expanse: parseInt(parts28[0]) || "",
            money_fair_expanse: parseInt(parts28[1]) || "",
            fair_salary: parseInt(parts29[0]) || "",
            money_fair_salary: parseInt(parts29[1]) || "",
            other_fair: parseInt(parts30[0]) || "",
            money_other_fair: parseInt(parts30[1]) || "",
            kindergarten: parseInt(parts31[0]) || "",
            money_kindergarten: parseInt(parts31[1]) || "",
            purchase_management: parseInt(parts32[0]) || "",
            money_purchase_management: parseInt(parts32[1]) || "",
            kondwara_employment: parseInt(parts33[0]) || "",
            money_kondwara_employment: parseInt(parts33[1]) || "",
            ethical_costs: parseInt(parts34[0]) || "",
            money_ethical_costs: parseInt(parts34[1]) || "",
            vested_pt_buildings: parseInt(parts35[0]) || "",
            money_vested_pt_buildings: parseInt(parts35[1]) || "",
            medical_assistant: parseInt(parts36[0]) || "",
            money_medical_assistant: parseInt(parts36[1]) || "",
            child_welfare: parseInt(parts37[0]) || "",
            money_child_welfare: parseInt(parts37[1]) || "",
            public_health: parseInt(parts38[0]) || "",
            money_public_health: parseInt(parts38[1]) || "",
            education: parseInt(parts39[0]) || "",
            money_education: parseInt(parts39[1]) || "",
            cultural_programs: parseInt(parts40[0]) || "",
            money_cultural_programs: parseInt(parts40[1]) || "",
            public_welfare: parseInt(parts41[0]) || "",
            money_public_welfare: parseInt(parts41[1]) || "",
            village_protection: parseInt(parts42[0]) || "",
            money_village_protection: parseInt(parts42[1]) || "",
            agriculture: parseInt(parts43[0]) || "",
            money_agriculture: parseInt(parts43[1]) || "",
            animal_husbandry: parseInt(parts44[0]) || "",
            money_animal_husbandry: parseInt(parts44[1]) || "",
            tv: parseInt(parts45[0]) || "",
            money_tv: parseInt(parts45[1]) || "",
            work_public_utility: parseInt(parts46[0]) || "",
            money_public_utility: parseInt(parts46[1]) || "",
            e_grav_program: parseInt(parts47[0]) || "",
            money_e_grav_program: parseInt(parts47[1]) || "",
            machinery: parseInt(parts48[0]) || "",
            money_machinery: parseInt(parts48[1]) || "",
            deadstock: parseInt(parts49[0]) || "",
            money_deadstock: parseInt(parts49[1]) || "",
            subscription_fund: parseInt(parts50[0]) || "",
            money_subscription_fund: parseInt(parts50[1]) || "",
            dist_loan: parseInt(parts51[0]) || "",
            money_dist_loan: parseInt(parts51[1]) || "",
            backward_class: parseInt(parts52[0]) || "",
            money_backward_class: parseInt(parts52[1]) || "",
            other_retail: parseInt(parts53[0]) || "",
            money_other_retail: parseInt(parts53[1]) || "",
            loan_advances: parseInt(parts54[0]) || "",
            money_loan_advances: parseInt(parts54[1]) || "",
            pay_loan: parseInt(parts55[0]) || "",
            money_pay_loan: parseInt(parts55[1]) || "",
            payment_deposit_amt: parseInt(parts56[0]) || "",
            money_payment_deposit_amt: parseInt(parts56[1]) || "",
            advance: parseInt(parts57[0]) || "",
            money_advance: parseInt(parts57[1]) || "",
            village_school: parseInt(parts58[0]) || "",
            money_village_school: parseInt(parts58[1]) || "",
            jp_pt_section: parseInt(parts59[0]) || "",
            money_jp_pt_section: parseInt(parts59[1]) || "",
            work_expanse: parseInt(parts60[0]) || "",
            money_work_expanse: parseInt(parts60[1]) || "",
            motor_insurance: parseInt(parts61[0]) || "",
            money_motor_insurance: parseInt(parts61[1]) || "",
            give_away: parseInt(parts62[0]) || "",
            money_give_away: parseInt(parts62[1]) || "",
            other_royalty_fund: parseInt(parts63[0]) || "",
            money_other_royalty_fund: parseInt(parts63[1]) || "",
            income_tax: parseInt(parts64[0]) || "",
            money_income_tax: parseInt(parts64[1]) || "",
            total_amount_receive_year: "",
            total: "",
          });
        } else {
          setSheetData({
            ...defaultSheetData,
            general_administration: "",
            money_general_administration: "",
            clerk_salary: "",
            money_clerk_salary: "",
            peon_salary: "",
            money_peon_salary: "",
            traveling_allowance: "",
            money_traveling_allowance: "",
            member: "",
            money_member: "",
            sarpanch_emoluments: "",
            money_sarpanch_emoluments: "",
            member_meeting_allowance: "",
            money_member_meeting_allowance: "",
            office_expenses: "",
            money_office_expenses: "",
            office_light_bill: "",
            money_office_light_bill: "",
            office_retail: "",
            money_office_retail: "",
            guest_allowance: "",
            money_guest_allowance: "",
            telephone_costs: "",
            money_telephone_costs: "",
            material_supply: "",
            money_material_supply: "",
            water_supply: "",
            money_water_supply: "",
            means_communication: "",
            money_means_communication: "",
            road_construction: "",
            money_road_construction: "",
            sewer_construction: "",
            money_sewer_construction: "",
            road_sewer_repair: "",
            money_road_sewer_repair: "",
            health_related: "",
            money_health_related: "",
            employee_salary: "",
            money_employee_salary: "",
            health_restored: "",
            money_health_restored: "",
            drainage_construction: "",
            money_drainage_construction: "",
            manure_pits: "",
            money_manure_pits: "",
            cleanliness: "",
            money_cleanliness: "",
            lamp_regarding: "",
            money_lamp_regarding: "",
            material: "",
            money_material: "",
            road_light_bill: "",
            money_road_light_bill: "",
            other: "",
            other_money: "",
            fair_expanse: "",
            money_fair_expanse: "",
            fair_salary: "",
            money_fair_salary: "",
            other_fair: "",
            money_other_fair: "",
            kindergarten: "",
            money_kindergarten: "",
            purchase_management: "",
            money_purchase_management: "",
            kondwara_employment: "",
            money_kondwara_employment: "",
            ethical_costs: "",
            money_ethical_costs: "",
            vested_pt_buildings: "",
            money_vested_pt_buildings: "",
            medical_assistant: "",
            money_medical_assistant: "",
            child_welfare: "",
            money_child_welfare: "",
            public_health: "",
            money_public_health: "",
            education: "",
            money_education: "",
            cultural_programs: "",
            money_cultural_programs: "",
            public_welfare: "",
            money_public_welfare: "",
            village_protection: "",
            money_village_protection: "",
            agriculture: "",
            money_agriculture: "",
            animal_husbandry: "",
            money_animal_husbandry: "",
            tv: "",
            money_tv: "",
            work_public_utility: "",
            money_public_utility: "",
            e_grav_program: "",
            money_e_grav_program: "",
            machinery: "",
            money_machinery: "",
            deadstock: "",
            money_deadstock: "",
            subscription_fund: "",
            money_subscription_fund: "",
            dist_loan: "",
            money_dist_loan: "",
            backward_class: "",
            money_backward_class: "",
            other_retail: "",
            money_other_retail: "",
            loan_advances: "",
            money_loan_advances: "",
            pay_loan: "",
            money_pay_loan: "",
            payment_deposit_amt: "",
            money_payment_deposit_amt: "",
            advance: "",
            money_advance: "",
            village_school: "",
            money_village_school: "",
            jp_pt_section: "",
            money_jp_pt_section: "",
            work_expanse: "",
            money_work_expanse: "",
            motor_insurance: "",
            money_motor_insurance: "",
            give_away: "",
            money_give_away: "",
            other_royalty_fund: "",
            money_other_royalty_fund: "",
            income_tax: "",
            money_income_tax: "",
            total_amount_receive_year: "",
            money_total_amount_receive_year: "",
            final_balance: "",
            money_final_balance: "",
            total: "",
            money_total: "",
          });
        }
      }

      if (
        response.data.data.form_i_data === null ||
        response.data.data.form_i_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_i_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value }));
  };

  // table validation
  const validateForm = () => {
    const newErrors = {};

    // Validate tabels all input

    if (
      sheetData.general_administration !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.general_administration)
    ) {
      newErrors.general_administration = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.clerk_salary !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.clerk_salary)
    ) {
      newErrors.clerk_salary = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.peon_salary !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.peon_salary)
    ) {
      newErrors.peon_salary = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.traveling_allowance !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.traveling_allowance)
    ) {
      newErrors.traveling_allowance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.member !== "" && !/^-?\d*\.?\d+$/.test(sheetData.member)) {
      newErrors.member = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.sarpanch_emoluments !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.sarpanch_emoluments)
    ) {
      newErrors.sarpanch_emoluments = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.member_meeting_allowance !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.member_meeting_allowance)
    ) {
      newErrors.member_meeting_allowance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.office_expenses !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.office_expenses)
    ) {
      newErrors.office_expenses = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.office_light_bill !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.office_light_bill)
    ) {
      newErrors.office_light_bill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.office_retail !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.office_retail)
    ) {
      newErrors.office_retail = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.guest_allowance !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.guest_allowance)
    ) {
      newErrors.guest_allowance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.telephone_costs !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.telephone_costs)
    ) {
      newErrors.telephone_costs = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.material_supply !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.material_supply)
    ) {
      newErrors.material_supply = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.water_supply !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.water_supply)
    ) {
      newErrors.water_supply = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.means_communication !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.means_communication)
    ) {
      newErrors.means_communication = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.road_construction !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.road_construction)
    ) {
      newErrors.road_construction = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.sewer_construction !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.sewer_construction)
    ) {
      newErrors.sewer_construction = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.road_sewer_repair !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.road_sewer_repair)
    ) {
      newErrors.road_sewer_repair = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.health_related !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.health_related)
    ) {
      newErrors.health_related = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.employee_salary !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.employee_salary)
    ) {
      newErrors.employee_salary = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.health_restored !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.health_restored)
    ) {
      newErrors.health_restored = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.drainage_construction !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.drainage_construction)
    ) {
      newErrors.drainage_construction = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.manure_pits !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.manure_pits)
    ) {
      newErrors.manure_pits = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.cleanliness !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.cleanliness)
    ) {
      newErrors.cleanliness = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.lamp_regarding !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.lamp_regarding)
    ) {
      newErrors.lamp_regarding = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.material !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.material)
    ) {
      newErrors.material = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.road_light_bill !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.road_light_bill)
    ) {
      newErrors.road_light_bill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.other !== "" && !/^-?\d*\.?\d+$/.test(sheetData.other)) {
      newErrors.other = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fair_expanse !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fair_expanse)
    ) {
      newErrors.fair_expanse = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fair_salary !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fair_salary)
    ) {
      newErrors.fair_salary = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_fair !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_fair)
    ) {
      newErrors.other_fair = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.kindergarten !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.kindergarten)
    ) {
      newErrors.kindergarten = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.purchase_management !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.purchase_management)
    ) {
      newErrors.purchase_management = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.kondwara_employment !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.kondwara_employment)
    ) {
      newErrors.kondwara_employment = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.ethical_costs !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.ethical_costs)
    ) {
      newErrors.ethical_costs = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.vested_pt_buildings !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.vested_pt_buildings)
    ) {
      newErrors.vested_pt_buildings = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.medical_assistant !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.medical_assistant)
    ) {
      newErrors.medical_assistant = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.child_welfare !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.child_welfare)
    ) {
      newErrors.child_welfare = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.public_health !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.public_health)
    ) {
      newErrors.public_health = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.education !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.education)
    ) {
      newErrors.education = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.cultural_programs !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.cultural_programs)
    ) {
      newErrors.cultural_programs = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.public_welfare !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.public_welfare)
    ) {
      newErrors.public_welfare = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.village_protection !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.village_protection)
    ) {
      newErrors.village_protection = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.agriculture !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.agriculture)
    ) {
      newErrors.agriculture = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.animal_husbandry !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.animal_husbandry)
    ) {
      newErrors.animal_husbandry = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.tv !== "" && !/^-?\d*\.?\d+$/.test(sheetData.tv)) {
      newErrors.tv = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.work_public_utility !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.work_public_utility)
    ) {
      newErrors.work_public_utility = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.e_grav_program !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.e_grav_program)
    ) {
      newErrors.e_grav_program = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.machinery !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.machinery)
    ) {
      newErrors.machinery = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.deadstock !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.deadstock)
    ) {
      newErrors.deadstock = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.subscription_fund !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.subscription_fund)
    ) {
      newErrors.subscription_fund = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.dist_loan !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.dist_loan)
    ) {
      newErrors.dist_loan = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.backward_class !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.backward_class)
    ) {
      newErrors.backward_class = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_retail !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_retail)
    ) {
      newErrors.other_retail = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.loan_advances !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.loan_advances)
    ) {
      newErrors.loan_advances = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.pay_loan !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.pay_loan)
    ) {
      newErrors.pay_loan = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.payment_deposit_amt !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.payment_deposit_amt)
    ) {
      newErrors.payment_deposit_amt = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.advance !== "" && !/^-?\d*\.?\d+$/.test(sheetData.advance)) {
      newErrors.advance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.village_school !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.village_school)
    ) {
      newErrors.village_school = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.jp_pt_section !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.jp_pt_section)
    ) {
      newErrors.jp_pt_section = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.work_expanse !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.work_expanse)
    ) {
      newErrors.work_expanse = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.motor_insurance !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.motor_insurance)
    ) {
      newErrors.motor_insurance = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.give_away !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.give_away)
    ) {
      newErrors.give_away = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_royalty_fund !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_royalty_fund)
    ) {
      newErrors.other_royalty_fund = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.income_tax !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.income_tax)
    ) {
      newErrors.income_tax = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    setErrors(newErrors);
    console.log("newErrors I", newErrors);
    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // update api

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm(true)) {
      console.log("Form submitted:", sheetData);
    } else {
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const formatField = (mainValue, moneyValue) =>
        mainValue && moneyValue ? `${mainValue || ""}.${moneyValue || "" }` : mainValue ?  mainValue : "";
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_i_data: {
          gpt_name: sheetData.gpt_name,
          taluka_name: sheetData.taluka_name,
          district_name: sheetData.district_name,
          general_administration: formatField(
            sheetData.general_administration,
            sheetData.money_general_administration
          ),
          clerk_salary: formatField(
            sheetData.clerk_salary,
            sheetData.money_clerk_salary
          ),
          peon_salary: formatField(
            sheetData.peon_salary,
            sheetData.money_peon_salary
          ),
          traveling_allowance: formatField(
            sheetData.traveling_allowance,
            sheetData.money_traveling_allowance
          ),
          member: formatField(sheetData.member, sheetData.money_member),
          sarpanch_emoluments: formatField(
            sheetData.sarpanch_emoluments,
            sheetData.money_sarpanch_emoluments
          ),
          member_meeting_allowance: formatField(
            sheetData.member_meeting_allowance,
            sheetData.money_member_meeting_allowance
          ),
          office_expenses: formatField(
            sheetData.office_expenses,
            sheetData.money_office_expenses
          ),
          office_light_bill: formatField(
            sheetData.office_light_bill,
            sheetData.money_office_light_bill
          ),
          office_retail: formatField(
            sheetData.office_retail,
            sheetData.money_office_retail
          ),
          guest_allowance: formatField(
            sheetData.guest_allowance,
            sheetData.money_guest_allowance
          ),
          telephone_costs: formatField(
            sheetData.telephone_costs,
            sheetData.money_telephone_costs
          ),
          material_supply: formatField(
            sheetData.material_supply,
            sheetData.money_material_supply
          ),
          water_supply: formatField(
            sheetData.water_supply,
            sheetData.money_water_supply
          ),
          means_communication: formatField(
            sheetData.means_communication,
            sheetData.money_means_communication
          ),
          road_construction: formatField(
            sheetData.road_construction,
            sheetData.money_road_construction
          ),
          sewer_construction: formatField(
            sheetData.sewer_construction,
            sheetData.money_sewer_construction
          ),
          road_sewer_repair: formatField(
            sheetData.road_sewer_repair,
            sheetData.money_road_sewer_repair
          ),
          health_related: formatField(
            sheetData.health_related,
            sheetData.money_health_related
          ),
          employee_salary: formatField(
            sheetData.employee_salary,
            sheetData.money_employee_salary
          ),
          health_restored: formatField(
            sheetData.health_restored,
            sheetData.money_health_restored
          ),
          drainage_construction: formatField(
            sheetData.drainage_construction,
            sheetData.money_drainage_construction
          ),
          manure_pits: formatField(
            sheetData.manure_pits,
            sheetData.money_manure_pits
          ),
          cleanliness: formatField(
            sheetData.cleanliness,
            sheetData.money_cleanliness
          ),
          lamp_regarding: formatField(
            sheetData.lamp_regarding,
            sheetData.money_lamp_regarding
          ),
          material: formatField(sheetData.material, sheetData.money_material),
          road_light_bill: formatField(
            sheetData.road_light_bill,
            sheetData.money_road_light_bill
          ),
          other: formatField(sheetData.other, sheetData.other_money),
          fair_expanse: formatField(
            sheetData.fair_expanse,
            sheetData.money_fair_expanse
          ),
          fair_salary: formatField(
            sheetData.fair_salary,
            sheetData.money_fair_salary
          ),
          other_fair: formatField(
            sheetData.other_fair,
            sheetData.money_other_fair
          ),
          kindergarten: formatField(
            sheetData.kindergarten,
            sheetData.money_kindergarten
          ),
          purchase_management: formatField(
            sheetData.purchase_management,
            sheetData.money_purchase_management
          ),
          kondwara_employment: formatField(
            sheetData.kondwara_employment,
            sheetData.money_kondwara_employment
          ),
          ethical_costs: formatField(
            sheetData.ethical_costs,
            sheetData.money_ethical_costs
          ),
          vested_pt_buildings: formatField(
            sheetData.vested_pt_buildings,
            sheetData.money_vested_pt_buildings
          ),
          medical_assistant: formatField(
            sheetData.medical_assistant,
            sheetData.money_medical_assistant
          ),
          child_welfare: formatField(
            sheetData.child_welfare,
            sheetData.money_child_welfare
          ),
          public_health: formatField(
            sheetData.public_health,
            sheetData.money_public_health
          ),
          education: formatField(
            sheetData.education,
            sheetData.money_education
          ),
          cultural_programs: formatField(
            sheetData.cultural_programs,
            sheetData.money_cultural_programs
          ),
          public_welfare: formatField(
            sheetData.public_welfare,
            sheetData.money_public_welfare
          ),
          village_protection: formatField(
            sheetData.village_protection,
            sheetData.money_village_protection
          ),
          agriculture: formatField(
            sheetData.agriculture,
            sheetData.money_agriculture
          ),
          animal_husbandry: formatField(
            sheetData.animal_husbandry,
            sheetData.money_animal_husbandry
          ),
          tv: formatField(sheetData.tv, sheetData.money_tv),
          work_public_utility: formatField(
            sheetData.work_public_utility,
            sheetData.money_public_utility
          ),
          e_grav_program: formatField(
            sheetData.e_grav_program,
            sheetData.money_e_grav_program
          ),
          machinery: formatField(
            sheetData.machinery,
            sheetData.money_machinery
          ),
          deadstock: formatField(
            sheetData.deadstock,
            sheetData.money_deadstock
          ),
          subscription_fund: formatField(
            sheetData.subscription_fund,
            sheetData.money_subscription_fund
          ),
          dist_loan: formatField(
            sheetData.dist_loan,
            sheetData.money_dist_loan
          ),
          backward_class: formatField(
            sheetData.backward_class,
            sheetData.money_backward_class
          ),
          other_retail: formatField(
            sheetData.other_retail,
            sheetData.money_other_retail
          ),
          loan_advances: formatField(
            sheetData.loan_advances,
            sheetData.money_loan_advances
          ),
          pay_loan: formatField(sheetData.pay_loan, sheetData.money_pay_loan),
          payment_deposit_amt: formatField(
            sheetData.payment_deposit_amt,
            sheetData.money_payment_deposit_amt
          ),
          advance: formatField(sheetData.advance, sheetData.money_advance),
          village_school: formatField(
            sheetData.village_school,
            sheetData.money_village_school
          ),
          jp_pt_section: formatField(
            sheetData.jp_pt_section,
            sheetData.money_jp_pt_section
          ),
          work_expanse: formatField(
            sheetData.work_expanse,
            sheetData.money_work_expanse
          ),
          motor_insurance: formatField(
            sheetData.motor_insurance,
            sheetData.money_motor_insurance
          ),
          give_away: formatField(
            sheetData.give_away,
            sheetData.money_give_away
          ),
          other_royalty_fund: formatField(
            sheetData.other_royalty_fund,
            sheetData.money_other_royalty_fund
          ),
          income_tax: formatField(
            sheetData.income_tax,
            sheetData.money_income_tax
          ),
          total_amount_receive_year: Total && Total.toFixed(2),
          final_balance: lastTotal && lastTotal.toFixed(2),
          total: previousTotal,
        },
      };
      console.log("update finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_i",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetI response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // draft api
  const handleDraftSheetI = async (e) => {
    e.preventDefault();
    if (validateForm(true)) {
      console.log("Form submitted:", sheetData);
    } else {
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const formatField = (mainValue, moneyValue) =>
        mainValue && moneyValue ? `${mainValue || ""}.${moneyValue || "" }` : mainValue ?  mainValue : "";
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_i_data: {
          gpt_name: sheetData.gpt_name,
          taluka_name: sheetData.taluka_name,
          district_name: sheetData.district_name,
          general_administration: formatField(
            sheetData.general_administration,
            sheetData.money_general_administration
          ),
          clerk_salary: formatField(
            sheetData.clerk_salary,
            sheetData.money_clerk_salary
          ),
          peon_salary: formatField(
            sheetData.peon_salary,
            sheetData.money_peon_salary
          ),
          traveling_allowance: formatField(
            sheetData.traveling_allowance,
            sheetData.money_traveling_allowance
          ),
          member: formatField(sheetData.member, sheetData.money_member),
          sarpanch_emoluments: formatField(
            sheetData.sarpanch_emoluments,
            sheetData.money_sarpanch_emoluments
          ),
          member_meeting_allowance: formatField(
            sheetData.member_meeting_allowance,
            sheetData.money_member_meeting_allowance
          ),
          office_expenses: formatField(
            sheetData.office_expenses,
            sheetData.money_office_expenses
          ),
          office_light_bill: formatField(
            sheetData.office_light_bill,
            sheetData.money_office_light_bill
          ),
          office_retail: formatField(
            sheetData.office_retail,
            sheetData.money_office_retail
          ),
          guest_allowance: formatField(
            sheetData.guest_allowance,
            sheetData.money_guest_allowance
          ),
          telephone_costs: formatField(
            sheetData.telephone_costs,
            sheetData.money_telephone_costs
          ),
          material_supply: formatField(
            sheetData.material_supply,
            sheetData.money_material_supply
          ),
          water_supply: formatField(
            sheetData.water_supply,
            sheetData.money_water_supply
          ),
          means_communication: formatField(
            sheetData.means_communication,
            sheetData.money_means_communication
          ),
          road_construction: formatField(
            sheetData.road_construction,
            sheetData.money_road_construction
          ),
          sewer_construction: formatField(
            sheetData.sewer_construction,
            sheetData.money_sewer_construction
          ),
          road_sewer_repair: formatField(
            sheetData.road_sewer_repair,
            sheetData.money_road_sewer_repair
          ),
          health_related: formatField(
            sheetData.health_related,
            sheetData.money_health_related
          ),
          employee_salary: formatField(
            sheetData.employee_salary,
            sheetData.money_employee_salary
          ),
          health_restored: formatField(
            sheetData.health_restored,
            sheetData.money_health_restored
          ),
          drainage_construction: formatField(
            sheetData.drainage_construction,
            sheetData.money_drainage_construction
          ),
          manure_pits: formatField(
            sheetData.manure_pits,
            sheetData.money_manure_pits
          ),
          cleanliness: formatField(
            sheetData.cleanliness,
            sheetData.money_cleanliness
          ),
          lamp_regarding: formatField(
            sheetData.lamp_regarding,
            sheetData.money_lamp_regarding
          ),
          material: formatField(sheetData.material, sheetData.money_material),
          road_light_bill: formatField(
            sheetData.road_light_bill,
            sheetData.money_road_light_bill
          ),
          other: formatField(sheetData.other, sheetData.other_money),
          fair_expanse: formatField(
            sheetData.fair_expanse,
            sheetData.money_fair_expanse
          ),
          fair_salary: formatField(
            sheetData.fair_salary,
            sheetData.money_fair_salary
          ),
          other_fair: formatField(
            sheetData.other_fair,
            sheetData.money_other_fair
          ),
          kindergarten: formatField(
            sheetData.kindergarten,
            sheetData.money_kindergarten
          ),
          purchase_management: formatField(
            sheetData.purchase_management,
            sheetData.money_purchase_management
          ),
          kondwara_employment: formatField(
            sheetData.kondwara_employment,
            sheetData.money_kondwara_employment
          ),
          ethical_costs: formatField(
            sheetData.ethical_costs,
            sheetData.money_ethical_costs
          ),
          vested_pt_buildings: formatField(
            sheetData.vested_pt_buildings,
            sheetData.money_vested_pt_buildings
          ),
          medical_assistant: formatField(
            sheetData.medical_assistant,
            sheetData.money_medical_assistant
          ),
          child_welfare: formatField(
            sheetData.child_welfare,
            sheetData.money_child_welfare
          ),
          public_health: formatField(
            sheetData.public_health,
            sheetData.money_public_health
          ),
          education: formatField(
            sheetData.education,
            sheetData.money_education
          ),
          cultural_programs: formatField(
            sheetData.cultural_programs,
            sheetData.money_cultural_programs
          ),
          public_welfare: formatField(
            sheetData.public_welfare,
            sheetData.money_public_welfare
          ),
          village_protection: formatField(
            sheetData.village_protection,
            sheetData.money_village_protection
          ),
          agriculture: formatField(
            sheetData.agriculture,
            sheetData.money_agriculture
          ),
          animal_husbandry: formatField(
            sheetData.animal_husbandry,
            sheetData.money_animal_husbandry
          ),
          tv: formatField(sheetData.tv, sheetData.money_tv),
          work_public_utility: formatField(
            sheetData.work_public_utility,
            sheetData.money_public_utility
          ),
          e_grav_program: formatField(
            sheetData.e_grav_program,
            sheetData.money_e_grav_program
          ),
          machinery: formatField(
            sheetData.machinery,
            sheetData.money_machinery
          ),
          deadstock: formatField(
            sheetData.deadstock,
            sheetData.money_deadstock
          ),
          subscription_fund: formatField(
            sheetData.subscription_fund,
            sheetData.money_subscription_fund
          ),
          dist_loan: formatField(
            sheetData.dist_loan,
            sheetData.money_dist_loan
          ),
          backward_class: formatField(
            sheetData.backward_class,
            sheetData.money_backward_class
          ),
          other_retail: formatField(
            sheetData.other_retail,
            sheetData.money_other_retail
          ),
          loan_advances: formatField(
            sheetData.loan_advances,
            sheetData.money_loan_advances
          ),
          pay_loan: formatField(sheetData.pay_loan, sheetData.money_pay_loan),
          payment_deposit_amt: formatField(
            sheetData.payment_deposit_amt,
            sheetData.money_payment_deposit_amt
          ),
          advance: formatField(sheetData.advance, sheetData.money_advance),
          village_school: formatField(
            sheetData.village_school,
            sheetData.money_village_school
          ),
          jp_pt_section: formatField(
            sheetData.jp_pt_section,
            sheetData.money_jp_pt_section
          ),
          work_expanse: formatField(
            sheetData.work_expanse,
            sheetData.money_work_expanse
          ),
          motor_insurance: formatField(
            sheetData.motor_insurance,
            sheetData.money_motor_insurance
          ),
          give_away: formatField(
            sheetData.give_away,
            sheetData.money_give_away
          ),
          other_royalty_fund: formatField(
            sheetData.other_royalty_fund,
            sheetData.money_other_royalty_fund
          ),
          income_tax: formatField(
            sheetData.income_tax,
            sheetData.money_income_tax
          ),
          total_amount_receive_year: Total && Total.toFixed(2),
          final_balance: lastTotal && lastTotal.toFixed(2),
          total: previousTotal,
        },
      };
      console.log("draftSheetI finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_i",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetI response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // calculate total
  const Total =
    parseFloat(
      `${sheetData.general_administration || 0}.${
        sheetData.money_general_administration || 0
      }`
    ) +
      parseFloat(
        `${sheetData.clerk_salary || 0}.${sheetData.money_clerk_salary || 0}`
      ) +
      parseFloat(
        `${sheetData.peon_salary || 0}.${sheetData.money_peon_salary || 0}`
      ) +
      parseFloat(
        `${sheetData.traveling_allowance || 0}.${
          sheetData.money_traveling_allowance || 0
        }`
      ) +
      parseFloat(`${sheetData.member || 0}.${sheetData.money_member || 0}`) +
      parseFloat(
        `${sheetData.sarpanch_emoluments || 0}.${
          sheetData.money_sarpanch_emoluments || 0
        }`
      ) +
      parseFloat(
        `${sheetData.member_meeting_allowance || 0}.${
          sheetData.money_member_meeting_allowance || 0
        }`
      ) +
      parseFloat(
        `${sheetData.office_expenses || 0}.${
          sheetData.money_office_expenses || 0
        }`
      ) +
      parseFloat(
        `${sheetData.office_light_bill || 0}.${
          sheetData.money_office_light_bill || 0
        }`
      ) +
      parseFloat(
        `${sheetData.office_retail || 0}.${sheetData.money_office_retail || 0}`
      ) +
      parseFloat(
        `${sheetData.guest_allowance || 0}.${
          sheetData.money_guest_allowance || 0
        }`
      ) +
      parseFloat(
        `${sheetData.telephone_costs || 0}.${
          sheetData.money_telephone_costs || 0
        }`
      ) +
      parseFloat(
        `${sheetData.material_supply || 0}.${
          sheetData.money_material_supply || 0
        }`
      ) +
      parseFloat(
        `${sheetData.water_supply || 0}.${sheetData.money_water_supply || 0}`
      ) +
      parseFloat(
        `${sheetData.means_communication || 0}.${
          sheetData.money_means_communication || 0
        }`
      ) +
      parseFloat(
        `${sheetData.road_construction || 0}.${
          sheetData.money_road_construction || 0
        }`
      ) +
      parseFloat(
        `${sheetData.sewer_construction || 0}.${
          sheetData.money_sewer_construction || 0
        }`
      ) +
      parseFloat(
        `${sheetData.road_sewer_repair || 0}.${
          sheetData.money_road_sewer_repair || 0
        }`
      ) +
      parseFloat(
        `${sheetData.health_related || 0}.${
          sheetData.money_health_related || 0
        }`
      ) +
      parseFloat(
        `${sheetData.employee_salary || 0}.${
          sheetData.money_employee_salary || 0
        }`
      ) +
      parseFloat(
        `${sheetData.health_restored || 0}.${
          sheetData.money_health_restored || 0
        }`
      ) +
      parseFloat(
        `${sheetData.drainage_construction || 0}.${
          sheetData.money_drainage_construction || 0
        }`
      ) +
      parseFloat(
        `${sheetData.manure_pits || 0}.${sheetData.money_manure_pits || 0}`
      ) +
      parseFloat(
        `${sheetData.cleanliness || 0}.${sheetData.money_cleanliness || 0}`
      ) +
      parseFloat(
        `${sheetData.lamp_regarding || 0}.${
          sheetData.money_lamp_regarding || 0
        }`
      ) +
      parseFloat(
        `${sheetData.material || 0}.${sheetData.money_material || 0}`
      ) +
      parseFloat(
        `${sheetData.road_light_bill || 0}.${
          sheetData.money_road_light_bill || 0
        }`
      ) +
      parseFloat(`${sheetData.other || 0}.${sheetData.other_money || 0}`) +
      parseFloat(
        `${sheetData.fair_expanse || 0}.${sheetData.money_fair_expanse || 0}`
      ) +
      parseFloat(
        `${sheetData.fair_salary || 0}.${sheetData.money_fair_salary || 0}`
      ) +
      parseFloat(
        `${sheetData.other_fair || 0}.${sheetData.money_other_fair || 0}`
      ) +
      parseFloat(
        `${sheetData.kindergarten || 0}.${sheetData.money_kindergarten || 0}`
      ) +
      parseFloat(
        `${sheetData.purchase_management || 0}.${
          sheetData.money_purchase_management || 0
        }`
      ) +
      parseFloat(
        `${sheetData.kondwara_employment || 0}.${
          sheetData.money_kondwara_employment || 0
        }`
      ) +
      parseFloat(
        `${sheetData.ethical_costs || 0}.${sheetData.money_ethical_costs || 0}`
      ) +
      parseFloat(
        `${sheetData.vested_pt_buildings || 0}.${
          sheetData.money_vested_pt_buildings || 0
        }`
      ) +
      parseFloat(
        `${sheetData.medical_assistant || 0}.${
          sheetData.money_medical_assistant || 0
        }`
      ) +
      parseFloat(
        `${sheetData.child_welfare || 0}.${sheetData.money_child_welfare || 0}`
      ) +
      parseFloat(
        `${sheetData.public_health || 0}.${sheetData.money_public_health || 0}`
      ) +
      parseFloat(
        `${sheetData.education || 0}.${sheetData.money_education || 0}`
      ) +
      parseFloat(
        `${sheetData.cultural_programs || 0}.${
          sheetData.money_cultural_programs || 0
        }`
      ) +
      parseFloat(
        `${sheetData.public_welfare || 0}.${
          sheetData.money_public_welfare || 0
        }`
      ) +
      parseFloat(
        `${sheetData.village_protection || 0}.${
          sheetData.money_village_protection || 0
        }`
      ) +
      parseFloat(
        `${sheetData.agriculture || 0}.${sheetData.money_agriculture || 0}`
      ) +
      parseFloat(
        `${sheetData.animal_husbandry || 0}.${
          sheetData.money_animal_husbandry || 0
        }`
      ) +
      parseFloat(`${sheetData.tv || 0}.${sheetData.money_tv || 0}`) +
      parseFloat(
        `${sheetData.work_public_utility || 0}.${
          sheetData.money_public_utility || 0
        }`
      ) +
      parseFloat(
        `${sheetData.e_grav_program || 0}.${
          sheetData.money_e_grav_program || 0
        }`
      ) +
      parseFloat(
        `${sheetData.machinery || 0}.${sheetData.money_machinery || 0}`
      ) +
      parseFloat(
        `${sheetData.deadstock || 0}.${sheetData.money_deadstock || 0}`
      ) +
      parseFloat(
        `${sheetData.subscription_fund || 0}.${
          sheetData.money_subscription_fund || 0
        }`
      ) +
      parseFloat(
        `${sheetData.dist_loan || 0}.${sheetData.money_dist_loan || 0}`
      ) +
      parseFloat(
        `${sheetData.backward_class || 0}.${
          sheetData.money_backward_class || 0
        }`
      ) +
      parseFloat(
        `${sheetData.other_retail || 0}.${sheetData.money_other_retail || 0}`
      ) +
      parseFloat(
        `${sheetData.loan_advances || 0}.${sheetData.money_loan_advances || 0}`
      ) +
      parseFloat(
        `${sheetData.pay_loan || 0}.${sheetData.money_pay_loan || 0}`
      ) +
      parseFloat(
        `${sheetData.payment_deposit_amt || 0}.${
          sheetData.money_receive_loan_govt || 0
        }`
      ) +
      parseFloat(`${sheetData.advance || 0}.${sheetData.money_advance || 0}`) +
      parseFloat(
        `${sheetData.village_school || 0}.${
          sheetData.money_village_school || 0
        }`
      ) +
      parseFloat(
        `${sheetData.jp_pt_section || 0}.${sheetData.money_jp_pt_section || 0}`
      ) +
      parseFloat(
        `${sheetData.work_expanse || 0}.${sheetData.money_work_expanse || 0}`
      ) +
      parseFloat(
        `${sheetData.motor_insurance || 0}.${
          sheetData.money_motor_insurance || 0
        }`
      ) +
      parseFloat(
        `${sheetData.give_away || 0}.${sheetData.money_give_away || 0}`
      ) +
      parseFloat(
        `${sheetData.other_royalty_fund || 0}.${
          sheetData.money_other_royalty_fund || 0
        }`
      ) +
      parseFloat(
        `${sheetData.income_tax || 0}.${sheetData.money_income_tax || 0}`
      ) || "";

  var parts = Total.toString().split(".");
  var beforeDecimal = parseInt(parts[0]) || ""; // Part before the decimal point
  var afterDecimal = parseInt(parts[1]) || "";

  // totalRound
  const totalDecimalValue = previousTotal.toString().split(".");

  const lastTotal =
  parseFloat(`${totalDecimalValue[0]}.${totalDecimalValue[1]} `) -
    parseFloat(Total || 0) 
       || "";


  const TotalRound =
  parseFloat(`${parts[0]}.${parts[1]} `) + parseFloat(lastTotal || 0) || "";

  var parts1 = TotalRound.toString().split(".");
  var beforeDecimal1 = parseInt(parts1[0]) || ""; // Part before the decimal point
  var afterDecimal1 = parseInt(parts1[1]) || "";

  

  var partsLastTotal = lastTotal.toString().split(".");
  var beforeDecimalLastTotal = parseInt(partsLastTotal[0]) || ""; // Part before the decimal point
  var afterDecimalLastTotal = parseInt(partsLastTotal[1]) || "";

  const handleKeyDown = (event) => {
    if (
      [46, 8, 9, 27, 13].includes(event.keyCode) ||
      (event.keyCode === 65 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode === 67 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode === 88 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  };
  return (
    <section className={`${styles.sample_page_section_main}`}>
      <div
        className={`container sheet_bg_color_pdf ${styles.sample_sec_container}`}
      >
        <div className={`row ${styles.sample_sec_row}`}>
          <div className="col-lg-12 mb-5 pb-5">
            <div className="table-main-sec portrait-section">
              <div className={`d-flex justify-content-center align-items-center gap-5 ${styles.sample_page_title_top}`}>
                <p>
                  नमुना नंबर - ४{" "}
                  <span>
                    ( ग्रामनिधी खर्च बाजु सन {yearInfo.previous_year_name} ते {yearInfo.current_year_name} या वर्षाचे जमा वार्षिक
                    हिशोब )
                  </span>
                </p>
                {economicData === "Y" ? (
                  <span className="drafted_text text-danger">
                    <FaEdit /> Drafted
                  </span>
                ) : economicData === "N" ? (
                  <span className="table_title_top gap-0 submit_text1s">
                    Submitted{" "}
                    <img
                      src={require("../../../component/assets/images/sumbit-success.png")}
                      alt="success"
                      width={30}
                      className="img-fluid"
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className={`${styles.dtv_info_sec_main}`}>
                <div className={`${styles.dtv_info_sec}`}>
                  <div className={`${styles.dtv_info_sub_sec}`}>
                    <p>ग्रामपंचायत :-</p>
                    <span>{sheetData.gpt_name}</span>
                  </div>
                  <div className={`${styles.dtv_info_sub_sec}`}>
                    <p>तालुका :-</p>
                    <span>{sheetData.taluka_name}</span>
                  </div>
                  <div className={`${styles.dtv_info_sub_sec}`}>
                    <p>जिल्हा :-</p>
                    <span>{sheetData.district_name}</span>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <form action="">
                  <table
                    className="table table-bordered "
                    style={{ border: "1px solid black" }}
                  >
                    <thead className={`${styles.custom_thead}`}>
                      <tr>
                        <th rowSpan={2} className="text-center">
                          अ.नु
                        </th>
                        <th rowSpan={2} className="text-center">
                          खर्चाचा तपशील
                        </th>
                        <th colSpan={2} className="text-center">
                          रक्कम
                        </th>
                        <th rowSpan={2} className="text-center">
                          अ.नु
                        </th>
                        <th rowSpan={2} className="text-center">
                          खर्चाचा तपशील
                        </th>
                        <th colSpan={2} className="text-center">
                          रक्कम
                        </th>
                      </tr>
                      <tr>
                        <th>रुपये</th>
                        <th>पैसे</th>
                        <th>रुपये</th>
                        <th>पैसे</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={`${styles.custom_tr_second}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td>१</td>
                        <td>सामान्य कारभार</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.general_administration ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.general_administration
                                ? errors.general_administration
                                : ""
                            }
                            name="general_administration"
                            value={sheetData.general_administration}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_general_administration"
                            value={sheetData.money_general_administration}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>७</td>
                        <td>बालवाडी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.kindergarten ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.kindergarten ? errors.kindergarten : ""
                            }
                            name="kindergarten"
                            value={sheetData.kindergarten}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_kindergarten"
                            value={sheetData.money_kindergarten}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>अ) नोकर पगार - क्लार्क 5 कर्मचारी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.clerk_salary ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.clerk_salary ? errors.clerk_salary : ""
                            }
                            name="clerk_salary"
                            value={sheetData.clerk_salary}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_clerk_salary"
                            value={sheetData.money_clerk_salary}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>८</td>
                        <td style={{ fontSize: "12px" }}>
                          प्रेत जाळणे व पुरणे जागा खरेदी, व्यवस्थापन
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.purchase_management ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.purchase_management
                                ? errors.purchase_management
                                : ""
                            }
                            name="purchase_management"
                            value={sheetData.purchase_management}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_purchase_management"
                            value={sheetData.money_purchase_management}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td className="text-center">शिपाई</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.peon_salary ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.peon_salary ? errors.peon_salary : ""
                            }
                            name="peon_salary"
                            value={sheetData.peon_salary}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_peon_salary"
                            value={sheetData.money_peon_salary}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>९</td>
                        <td>कोंडवाडा अ) नोकरपगार</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.kondwara_employment ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.kondwara_employment
                                ? errors.kondwara_employment
                                : ""
                            }
                            name="kondwara_employment"
                            value={sheetData.kondwara_employment}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_kondwara_employment"
                            value={sheetData.money_kondwara_employment}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>ब) प्रवास भत्ता - नोकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.traveling_allowance ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.traveling_allowance
                                ? errors.traveling_allowance
                                : ""
                            }
                            name="traveling_allowance"
                            value={sheetData.traveling_allowance}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_traveling_allowance"
                            value={sheetData.money_traveling_allowance}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>ब) नैमितिक खर्च</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.ethical_costs ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.ethical_costs ? errors.ethical_costs : ""
                            }
                            name="ethical_costs"
                            value={sheetData.ethical_costs}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_ethical_costs"
                            value={sheetData.money_ethical_costs}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td className="ps-5">सदस्य | सरपंच</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.member ? "is-invalid" : ""
                            }`}
                            placeholder={errors.member ? errors.member : ""}
                            name="member"
                            value={sheetData.member}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_member"
                            value={sheetData.money_member}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>क) सरपंच मानधन</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.sarpanch_emoluments ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.sarpanch_emoluments
                                ? errors.sarpanch_emoluments
                                : ""
                            }
                            name="sarpanch_emoluments"
                            value={sheetData.sarpanch_emoluments}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_sarpanch_emoluments"
                            value={sheetData.money_sarpanch_emoluments}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१०</td>
                        <td>पंचायात निहित सार्व, इमारती वरील </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.vested_pt_buildings ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.vested_pt_buildings
                                ? errors.vested_pt_buildings
                                : ""
                            }
                            name="vested_pt_buildings"
                            value={sheetData.vested_pt_buildings}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_vested_pt_buildings"
                            value={sheetData.money_vested_pt_buildings}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>ड) सदस्य बैठक भत्ता </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.member_meeting_allowance
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.member_meeting_allowance
                                ? errors.member_meeting_allowance
                                : ""
                            }
                            name="member_meeting_allowance"
                            value={sheetData.member_meeting_allowance}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_member_meeting_allowance"
                            value={sheetData.money_member_meeting_allowance}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>११</td>
                        <td>वैदकीय सहाय्य</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.medical_assistant ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.medical_assistant
                                ? errors.medical_assistant
                                : ""
                            }
                            name="medical_assistant"
                            value={sheetData.medical_assistant}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_medical_assistant"
                            value={sheetData.money_medical_assistant}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>इ) कार्यालय नैमितिक खर्च</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.office_expenses ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.office_expenses
                                ? errors.office_expenses
                                : ""
                            }
                            name="office_expenses"
                            value={sheetData.office_expenses}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_office_expenses"
                            value={sheetData.money_office_expenses}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१२</td>
                        <td>महिला व बालकल्याण १०%</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.child_welfare ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.child_welfare ? errors.child_welfare : ""
                            }
                            name="child_welfare"
                            value={sheetData.child_welfare}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_child_welfare"
                            value={sheetData.money_child_welfare}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>कार्यालयीन विजबील खर्च</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.office_light_bill ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.office_light_bill
                                ? errors.office_light_bill
                                : ""
                            }
                            name="office_light_bill"
                            value={sheetData.office_light_bill}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_office_light_bill"
                            value={sheetData.money_office_light_bill}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१३</td>
                        <td>सार्वजनिक आरोग्य</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.public_health ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.public_health ? errors.public_health : ""
                            }
                            name="public_health"
                            value={sheetData.public_health}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_public_health"
                            value={sheetData.money_public_health}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>कार्यालय किरकोळ</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.office_retail ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.office_retail ? errors.office_retail : ""
                            }
                            name="office_retail"
                            value={sheetData.office_retail}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_office_retail"
                            value={sheetData.money_office_retail}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१४</td>
                        <td>शिक्षण</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.education ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.education ? errors.education : ""
                            }
                            name="education"
                            value={sheetData.education}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_education"
                            value={sheetData.money_education}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>फ) अतिथी भत्ता</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.guest_allowance ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.guest_allowance
                                ? errors.guest_allowance
                                : ""
                            }
                            name="guest_allowance"
                            value={sheetData.guest_allowance}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_guest_allowance"
                            value={sheetData.money_guest_allowance}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१५</td>
                        <td>सामाजिक सांस्कृतिक कार्यक्रम (५%)</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.cultural_programs ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.cultural_programs
                                ? errors.cultural_programs
                                : ""
                            }
                            name="cultural_programs"
                            value={sheetData.cultural_programs}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_cultural_programs"
                            value={sheetData.money_cultural_programs}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>त) दुरध्वनी खर्च</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.telephone_costs ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.telephone_costs
                                ? errors.telephone_costs
                                : ""
                            }
                            name="telephone_costs"
                            value={sheetData.telephone_costs}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_telephone_costs"
                            value={sheetData.money_telephone_costs}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१६</td>
                        <td>लोककल्याण</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.public_welfare ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.public_welfare ? errors.public_welfare : ""
                            }
                            name="public_welfare"
                            value={sheetData.public_welfare}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_public_welfare"
                            value={sheetData.money_public_welfare}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>साहित्य पुरवठा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.material_supply ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.material_supply
                                ? errors.material_supply
                                : ""
                            }
                            name="material_supply"
                            value={sheetData.material_supply}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_material_supply"
                            value={sheetData.money_material_supply}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>१७</td>
                        <td>ग्रामसंरक्षण</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.village_protection ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.village_protection
                                ? errors.village_protection
                                : ""
                            }
                            name="village_protection"
                            value={sheetData.village_protection}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_village_protection"
                            value={sheetData.money_village_protection}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>१८</td>
                        <td> शेती</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.agriculture ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.agriculture ? errors.agriculture : ""
                            }
                            name="agriculture"
                            value={sheetData.agriculture}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_agriculture"
                            value={sheetData.money_agriculture}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>१९</td>
                        <td>पशुसंवर्धन</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.animal_husbandry ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.animal_husbandry
                                ? errors.animal_husbandry
                                : ""
                            }
                            name="animal_husbandry"
                            value={sheetData.animal_husbandry}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_animal_husbandry"
                            value={sheetData.money_animal_husbandry}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td>२</td>
                        <td>पाणी पुरवठा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.water_supply ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.water_supply ? errors.water_supply : ""
                            }
                            name="water_supply"
                            value={sheetData.water_supply}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_water_supply"
                            value={sheetData.money_water_supply}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>२०</td>
                        <td> टी.व्ही.</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tv ? "is-invalid" : ""
                            }`}
                            placeholder={errors.tv ? errors.tv : ""}
                            name="tv"
                            value={sheetData.tv}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_tv"
                            value={sheetData.money_tv}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>२१</td>
                        <td>सार्वजनिक उपयुकत कामे</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.work_public_utility ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.work_public_utility
                                ? errors.work_public_utility
                                : ""
                            }
                            name="work_public_utility"
                            value={sheetData.work_public_utility}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_public_utility"
                            value={sheetData.money_public_utility}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>अ) इ.ग्रा.वि.कार्यक्रम </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.e_grav_program ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.e_grav_program ? errors.e_grav_program : ""
                            }
                            name="e_grav_program"
                            value={sheetData.e_grav_program}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_e_grav_program"
                            value={sheetData.money_e_grav_program}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>दळणवळणाची साधने</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.means_communication ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.means_communication
                                ? errors.means_communication
                                : ""
                            }
                            name="means_communication"
                            value={sheetData.means_communication}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_means_communication"
                            value={sheetData.money_means_communication}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>ब) यंत्रसामुग्री/साधन</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.machinery ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.machinery ? errors.machinery : ""
                            }
                            name="machinery"
                            value={sheetData.machinery}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_machinery"
                            value={sheetData.money_machinery}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>अ) १) रस्ते बांधकाम</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.road_construction ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.road_construction
                                ? errors.road_construction
                                : ""
                            }
                            name="road_construction"
                            value={sheetData.road_construction}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_road_construction"
                            value={sheetData.money_road_construction}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>क)</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>ब) २) गटार बांधकाम</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.sewer_construction ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.sewer_construction
                                ? errors.sewer_construction
                                : ""
                            }
                            name="sewer_construction"
                            value={sheetData.sewer_construction}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_sewer_construction"
                            value={sheetData.money_sewer_construction}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>ड)</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>इ)</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>२२</td>
                        <td>हजारे, सयंत्रे (डेडस्टॉक)</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.deadstock ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.deadstock ? errors.deadstock : ""
                            }
                            name="deadstock"
                            value={sheetData.deadstock}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_deadstock"
                            value={sheetData.money_deadstock}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>ब) रस्ते, गटार, दुरूस्ती </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.road_sewer_repair ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.road_sewer_repair
                                ? errors.road_sewer_repair
                                : ""
                            }
                            name="road_sewer_repair"
                            value={sheetData.road_sewer_repair}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_road_sewer_repair"
                            value={sheetData.money_road_sewer_repair}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>२३</td>
                        <td>जि.ग्रा.वि.निधी ५% वर्गणी</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.subscription_fund ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.subscription_fund
                                ? errors.subscription_fund
                                : ""
                            }
                            name="subscription_fund"
                            value={sheetData.subscription_fund}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_subscription_fund"
                            value={sheetData.money_subscription_fund}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>२४</td>
                        <td>जि.ग्रा.वि.कर्ज</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.dist_loan ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.dist_loan ? errors.dist_loan : ""
                            }
                            name="dist_loan"
                            value={sheetData.dist_loan}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_dist_loan"
                            value={sheetData.money_dist_loan}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td>४</td>
                        <td>आरोग्य विषयक</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.health_related ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.health_related ? errors.health_related : ""
                            }
                            name="health_related"
                            value={sheetData.health_related}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_health_related"
                            value={sheetData.money_health_related}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>२५</td>
                        <td>मागासवर्ग कल्याण १५%</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.backward_class ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.backward_class ? errors.backward_class : ""
                            }
                            name="backward_class"
                            value={sheetData.backward_class}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_backward_class"
                            value={sheetData.money_backward_class}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>अ) १) नोकर पगार </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.employee_salary ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.employee_salary
                                ? errors.employee_salary
                                : ""
                            }
                            name="employee_salary"
                            value={sheetData.employee_salary}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_employee_salary"
                            value={sheetData.money_employee_salary}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>२६</td>
                        <td>इतर किरकोळ </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_retail ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_retail ? errors.other_retail : ""
                            }
                            name="other_retail"
                            value={sheetData.other_retail}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_other_retail"
                            value={sheetData.money_other_retail}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> २) आरोग्य सादील </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.health_restored ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.health_restored
                                ? errors.health_restored
                                : ""
                            }
                            name="health_restored"
                            value={sheetData.health_restored}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_health_restored"
                            value={sheetData.money_health_restored}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>२७</td>
                        <td>कर्ज, अनामत रकमा व आगाऊ रकमा </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.loan_advances ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.loan_advances ? errors.loan_advances : ""
                            }
                            name="loan_advances"
                            value={sheetData.loan_advances}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_loan_advances"
                            value={sheetData.money_loan_advances}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>अ) कर्जाची फेड </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.pay_loan ? "is-invalid" : ""
                            }`}
                            placeholder={errors.pay_loan ? errors.pay_loan : ""}
                            name="pay_loan"
                            value={sheetData.pay_loan}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_pay_loan"
                            value={sheetData.money_pay_loan}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> ब) १) गटार काढणे, बांधणे </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.drainage_construction ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.drainage_construction
                                ? errors.drainage_construction
                                : ""
                            }
                            name="drainage_construction"
                            value={sheetData.drainage_construction}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_drainage_construction"
                            value={sheetData.money_drainage_construction}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>ब) अनामत रकमांची फेड </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.payment_deposit_amt ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.payment_deposit_amt
                                ? errors.payment_deposit_amt
                                : ""
                            }
                            name="payment_deposit_amt"
                            value={sheetData.payment_deposit_amt}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_payment_deposit_amt"
                            value={sheetData.money_payment_deposit_amt}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> २) खतांचे खड्डे, शोचकुप </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.manure_pits ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.manure_pits ? errors.manure_pits : ""
                            }
                            name="manure_pits"
                            value={sheetData.manure_pits}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_manure_pits"
                            value={sheetData.money_manure_pits}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>क) कर्जाऊ दिलेल्या (अ‍ॅडव्हान्स)) </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.advance ? "is-invalid" : ""
                            }`}
                            placeholder={errors.advance ? errors.advance : ""}
                            name="advance"
                            value={sheetData.advance}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_advance"
                            value={sheetData.money_advance}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> ३) स्वच्छता </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.cleanliness ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.cleanliness ? errors.cleanliness : ""
                            }
                            name="cleanliness"
                            value={sheetData.cleanliness}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_cleanliness"
                            value={sheetData.money_cleanliness}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>ड) गावातील शाळासाठी आणि कलम </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.village_school ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.village_school ? errors.village_school : ""
                            }
                            name="village_school"
                            value={sheetData.village_school}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_village_school"
                            value={sheetData.money_village_school}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td>५</td>
                        <td> दिवाबत्ती बाबत </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.lamp_regarding ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.lamp_regarding ? errors.lamp_regarding : ""
                            }
                            name="lamp_regarding"
                            value={sheetData.lamp_regarding}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_lamp_regarding"
                            value={sheetData.money_lamp_regarding}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>४६, ४७, ४८, (जि.प. / पं.स.)</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.jp_pt_section ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.jp_pt_section ? errors.jp_pt_section : ""
                            }
                            name="jp_pt_section"
                            value={sheetData.jp_pt_section}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_jp_pt_section"
                            value={sheetData.money_jp_pt_section}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> १) साहित्य </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.material ? "is-invalid" : ""
                            }`}
                            placeholder={errors.material ? errors.material : ""}
                            name="material"
                            value={sheetData.material}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_material"
                            value={sheetData.money_material}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>कडील कामावरील खर्च</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.work_expanse ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.work_expanse ? errors.work_expanse : ""
                            }
                            name="work_expanse"
                            value={sheetData.work_expanse}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_work_expanse"
                            value={sheetData.money_work_expanse}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> २) रस्ता वीजबिल </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.road_light_bill ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.road_light_bill
                                ? errors.road_light_bill
                                : ""
                            }
                            name="road_light_bill"
                            value={sheetData.road_light_bill}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_road_light_bill"
                            value={sheetData.money_road_light_bill}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>गतविमा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.motor_insurance ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.motor_insurance
                                ? errors.motor_insurance
                                : ""
                            }
                            name="motor_insurance"
                            value={sheetData.motor_insurance}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_motor_insurance"
                            value={sheetData.money_motor_insurance}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> ३) इतर </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other ? "is-invalid" : ""
                            }`}
                            placeholder={errors.other ? errors.other : ""}
                            name="other"
                            value={sheetData.other}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="other_money"
                            value={sheetData.other_money}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>बयाता दिटो</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.give_away ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.give_away ? errors.give_away : ""
                            }
                            name="give_away"
                            value={sheetData.give_away}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_give_away"
                            value={sheetData.money_give_away}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>इतर निधी रॉयल्टी बडा</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_royalty_fund ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_royalty_fund
                                ? errors.other_royalty_fund
                                : ""
                            }
                            name="other_royalty_fund"
                            value={sheetData.other_royalty_fund}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_other_royalty_fund"
                            value={sheetData.money_other_royalty_fund}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td>६</td>
                        <td>जत्रा, बाजार इ. वरील खर्च </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fair_expanse ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fair_expanse ? errors.fair_expanse : ""
                            }
                            name="fair_expanse"
                            value={sheetData.fair_expanse}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_fair_expanse"
                            value={sheetData.money_fair_expanse}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td>आयकर</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.income_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.income_tax ? errors.income_tax : ""
                            }
                            name="income_tax"
                            value={sheetData.income_tax}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_income_tax"
                            value={sheetData.money_income_tax}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>अ) पगार </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fair_salary ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fair_salary ? errors.fair_salary : ""
                            }
                            name="fair_salary"
                            value={sheetData.fair_salary}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_fair_salary"
                            value={sheetData.money_fair_salary}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr className={`${styles.sample_pages_common_tr}`}>
                        <td></td>
                        <td>ब) इतर </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_fair ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_fair ? errors.other_fair : ""
                            }
                            name="other_fair"
                            value={sheetData.other_fair}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box`}
                            name="money_other_fair"
                            value={sheetData.money_other_fair}
                            onChange={handleChange}
                            maxLength={2}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan={4} rowSpan={3}></td>
                        <td colSpan={2}> वर्षात एकूण खर्च</td>
                        <td className="fw-bold">{Total && beforeDecimal}</td>
                        <td className="fw-bold">
                          {Total && (afterDecimal.toString().slice(0, 2) || "")}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}> अखेरची शिल्लक</td>
                        <td className="fw-bold">
                          {(Total && TotalRound && lastTotal) && beforeDecimalLastTotal}
                        </td>
                        <td className="fw-bold">
                          {(Total && TotalRound && lastTotal) &&
                            afterDecimalLastTotal.toString().slice(0, 2)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}> एकूण एकंदर बेरीज</td>
                        <td className="fw-bold">
                          {(Total && TotalRound) && beforeDecimal1}
                        </td>
                        <td className="fw-bold">
                          {Total && TotalRound && afterDecimal1.toString().slice(0, 2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div>
                <h6>येणेप्रमाणे असे तारीख: / / २०</h6>
              </div>
              <div className="d-flex justify-content-center mt-5 gap-5">
                <h6 className="fw-bold">ग्रामसेवक</h6>
                <div className={styles.sarpanch_sign}>
                  <h6 className="fw-bold">सरपंच</h6>
                </div>
              </div>
            </div>
            <div className={styles.form_btn_space}>
              <FormBtn
                handleSubmit={handleSubmit}
                resetForm={handleSubmit}
                resetButton={true}
                handleDraft={handleDraftSheetI}
              />
              <div className="">
                <div className={styles.btn_position}>
                  {currentStep > 1 && (
                    <Button
                      variant="contained"
                      className=""
                      // endIcon={<RestartAltRoundedIcon />}
                      style={{ background: "#49ab4e" }}
                      onClick={handlePrevStep}
                    >
                      Previous
                    </Button>
                  )}

                  {currentStep < 10 && (
                    <Button
                      variant="contained"
                      className=""
                      // endIcon={<RestartAltRoundedIcon />}
                      style={{ background: "#49ab4e" }}
                      onClick={handleNextStep}
                    >
                      Next
                    </Button>
                  )}

                  {currentStep === 10 && (
                    <button
                      type="submit"
                      className={styles.btn_generate}
                      // onClick={handlePrint}
                    >
                      Generate Pdf
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-center"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </section>
  );
};

export default GramNidhiSampleNoFour;
