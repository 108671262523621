/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
import "./EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import NavigatePage from "../../component/header-navigate/NavigatePage";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import FormBtn from "../../component/buttons/Form/FormBtn";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Loader from "../../component/Loader/Loader";
import "../../assets/css/main.css";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { Button } from "@mui/material";
const EconomicsYear_B = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [isDraft, setIsDraft] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(currentDate);
  const [srNo, setSrNo] = useState("");
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState([]);
  // सामान्य पाणीपट्टी input filed calculation

  const [commonWatershed1, setCommonWatershed1] = useState("");
  const [commonWatershed2, setCommonWatershed2] = useState("");
  const [commonWatershedSum, setCommonWatershedSum] = useState("");

  // विशेष पाणीपट्टी input filed calculation

  const [specialWaterStrip1, setSpecialWaterStrip1] = useState("");
  const [specialWaterStrip2, setSpecialWaterStrip2] = useState("");
  const [specialWaterStripSum, setSpecialWaterStripSum] = useState("");

  // मागील थकबाकी calculate

  const [pastDue, setPastDue] = useState("");

  // चालू मागणी calculate

  const [currentDemand, setCurrentDemand] = useState("");

  // एकूण मागणी calculate

  const [aggregateDemand, setAggregateDemand] = useState("");

  const userDataInfo = sessionStorage.getItem("info");
  const userData = JSON.parse(userDataInfo);
  const userDetails = userData.user_details;
  // CommonWatershed calculation

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const CommonWatershedCalculation = () => {
    const commonWatershed1Num = parseFloat(commonWatershed1) || 0;
    const commonWatershed2Num = parseFloat(commonWatershed2) || 0;

    if (isNaN(commonWatershed1Num) || isNaN(commonWatershed2Num)) {
      setCommonWatershedSum("");
    } else {
      const sum = commonWatershed1Num + commonWatershed2Num;
      setCommonWatershedSum(Math.round(sum));
    }
  };

  useEffect(() => {
    CommonWatershedCalculation();
    totalSpecialWaterStrip();
    calculatePastDue();
    calculateCurrentDemand();
    calculateAggregateDemand();
  }, [
    commonWatershed1,
    commonWatershed2,
    specialWaterStrip1,
    specialWaterStrip2,
    commonWatershed1,
    specialWaterStrip1,
    commonWatershed2,
    specialWaterStrip2,
    commonWatershedSum,
    specialWaterStripSum,
  ]);

  // विशेष पाणीपट्टी input filed calculation

  const totalSpecialWaterStrip = () => {
    const specialWaterStrip1Num = parseFloat(specialWaterStrip1) || 0;
    const specialWaterStrip2Num = parseFloat(specialWaterStrip2) || 0;

    if (isNaN(specialWaterStrip1Num) || isNaN(specialWaterStrip2Num)) {
      setSpecialWaterStripSum("");
    } else {
      const calPadSar = specialWaterStrip1Num + specialWaterStrip2Num;
      setSpecialWaterStripSum(Math.round(calPadSar));
    }
  };

  // मागील थकबाकी calculate

  const calculatePastDue = () => {
    const commonWatershed1Num = parseFloat(commonWatershed1) || 0;
    const specialWaterStrip1Num = parseFloat(specialWaterStrip1) || 0;

    if (isNaN(commonWatershed1Num) || isNaN(specialWaterStrip1Num)) {
      setPastDue("");
    } else {
      const calculateSumOfPastDue = commonWatershed1Num + specialWaterStrip1Num;
      setPastDue(Math.round(calculateSumOfPastDue));
    }
  };

  // चालू मागणी calculate

  const calculateCurrentDemand = () => {
    const commonWatershed2Num = parseFloat(commonWatershed2) || 0;
    const specialWaterStrip2Num = parseFloat(specialWaterStrip2) || 0;

    if (isNaN(commonWatershed2Num) || isNaN(specialWaterStrip2Num)) {
      setCurrentDemand("");
    } else {
      const calculateSumOfCurrentDemand =
        commonWatershed2Num + specialWaterStrip2Num;
      setCurrentDemand(Math.round(calculateSumOfCurrentDemand));
    }
  };

  // एकूण मागणी calculate

  const calculateAggregateDemand = () => {
    const commonWatershedSumNum = parseFloat(commonWatershedSum) || 0;
    const specialWaterStripSumNum = parseFloat(specialWaterStripSum) || 0;

    if (isNaN(commonWatershedSumNum) || isNaN(specialWaterStripSumNum)) {
      setAggregateDemand("");
    } else {
      const calculateSumOfAggregateDemand =
        commonWatershedSumNum + specialWaterStripSumNum;
      setAggregateDemand(Math.round(calculateSumOfAggregateDemand));
    }

    // const calculateSumOfAggregateDemand =
    //   parseInt(commonWatershedSum) + parseInt(specialWaterStripSum);
    // setAggregateDemand(calculateSumOfAggregateDemand);
  };

  // fetch data in server api

  const handleEconocmic = async () => {
    const finalData = {
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      year_id: userDetails.current_year_id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/economic_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("economic_b data", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      setDate(response.data.data.economic_b_data[0].date);
      setSrNo(response.data.data.economic_b_data[0].sr_no);
      setData(response.data.data.economic_b_data);
      // set the data on input
      setCommonWatershed1(response.data.data.economic_b_data[0].past_due);
      setCommonWatershed2(response.data.data.economic_b_data[0].current_demand);
      if (
        response.data.data.economic_b_data === null ||
        response.data.data.economic_b_data.length === 0
      ) {
        setIsDraft("");
      } else {
        setIsDraft(response.data.data.economic_b_data[0].is_draft);
      }

      setSpecialWaterStrip1(response.data.data.economic_b_data[1].past_due);
      setSpecialWaterStrip2(
        response.data.data.economic_b_data[1].current_demand
      );
    } catch (error) {
      console.log("error occured fetch data on server", error);
    }
  };
  useEffect(() => {
    handleEconocmic();
  }, []);

  const handleDataRefresh = async () => {
    await handleEconocmic();
  };

  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleEconocmic();
    } else {
      setCommonWatershed1("");
      setCommonWatershed2("");
      setSpecialWaterStrip1("");
      setSpecialWaterStrip2("");
      setPastDue("");
      setCurrentDemand("");
      setAggregateDemand("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const commonWatershed1Ref = useRef(null);
  const commonWatershed2Ref = useRef(null);
  const specialWaterStrip1Ref = useRef(null);
  const specialWaterStrip2Ref = useRef(null);
  const srNoRef = useRef(null);
  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(commonWatershed1)) {
        commonWatershed1Ref.current.focus();
      } else if (!isNumeric(commonWatershed2)) {
        commonWatershed2Ref.current.focus();
      } else if (!isNumeric(specialWaterStrip1)) {
        specialWaterStrip1Ref.current.focus();
      } else if (!isNumeric(specialWaterStrip2)) {
        specialWaterStrip2Ref.current.focus();
      } else if (!isNumeric(srNo)) {
        srNoRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;

  const formatDateString = (inputDate) => {
    const [day = "", month = "", year = ""] = inputDate.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const formatted = formatDateString(date);

  // check validation when before update the data .
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(commonWatershed1) ||
      !isNumeric(commonWatershed2) ||
      !isNumeric(specialWaterStrip1) ||
      !isNumeric(specialWaterStrip2) ||
      !isNumeric(srNo) ||
      !dateRegex.test(formatted)
    ){
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const isValid = validateForm();
    if (isValid) {
      if(validated){
        await handleUpdateEconomic(e);
      }
    }else{
      handleShow();
    }
  }
 
  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };

  //  update data in api
  const handleUpdateEconomic = async (e) => {

    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();

    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      date: date,
      sr_no: srNo,
      economic_b_data: [
        {
          type: "General Watershed",
          past_due: commonWatershed1,
          current_demand: commonWatershed2,
          toal_demand: commonWatershedSum,
        },
        {
          type: "Special Watershed",
          past_due: specialWaterStrip1,
          current_demand: specialWaterStrip2,
          toal_demand: specialWaterStripSum,
        },
      ],
    };
    console.log("finalData Pani", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "api/update_economic_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("error occured updata data", error);
    }
  };

  // store data in draft
  const handleStoreEconomic = async (e) => {
    const formattedDate = formatDateString(date);
    e.preventDefault();

    // Check for invalid formats
    if (
      (!isNumeric(commonWatershed1) && commonWatershed1 !== "") ||
      (!isNumeric(commonWatershed2) && commonWatershed2 !== "") ||
      (!isNumeric(specialWaterStrip1) && specialWaterStrip1 !== "") ||
      (!isNumeric(specialWaterStrip2) && specialWaterStrip2 !== "") ||
      (!isNumeric(srNo) && srNo !== "") ||
      (!dateRegex.test(formattedDate) && date !== "")
    ) {
      setValidated(true);
      return;
    }
    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      date: date,
      sr_no: srNo,
      economic_b_data: [
        {
          type: "General Watershed",
          past_due: commonWatershed1,
          current_demand: commonWatershed2,
          toal_demand: commonWatershedSum,
        },
        {
          type: "Special Watershed",
          past_due: specialWaterStrip1,
          current_demand: specialWaterStrip2,
          toal_demand: specialWaterStripSum,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/draft_economic_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      setValidated(false); // Reset validation state
      setTimeout(() => {
        handleDataRefresh();
      }, 2000);
    } catch (error) {
      toast.error(error);
    }
  };

  const inputWidth = {
    width: `${(srNo?.length + 1 || 5) * 12}px`, // Adjust the multiplier based on your needs
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>पाणीपट्टी (सामान्य व विशेष पाणीपट्टी)</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container common-section pt-0">
          <div className="row">
            <div className="col-lg-12 navigate-section-header mt-3">
              <div className="page-title">
                <p className="fw-bold mb-2">१.१ आर्थिक वर्ष मागणी </p>
              </div>
              <div className="navigate-section d-flex">
                <div className="arrow-box">
                  <Link to="/eco-a" className="me-5 text-dark">
                    अ) ग्रामपंचायत करमागणी ( घरपट्टी )
                  </Link>
                </div>
                <div className="arrow-box-active d-flex flex-column justify-content-center">
                  <Link to="/eco-b" className="text-decoration-none">
                    ब) पाणीपट्टी
                    {/* {isDraft === "Y" ? "" : <CheckCircleIcon className="text-success"/>} */}
                  </Link>
                  <span className="text-center arrow-icon">
                    <ArrowDropDownIcon fontSize="large" color="primary" />
                  </span>
                </div>
                <div className="arrow-box">
                  <Link to="/eco-c" className="ms-5 text-dark">
                    क) किरकोळ मागणी
                  </Link>
                </div>
                <div className="arrow-box">
                  <Link to="/eco-d" className="ms-5 text-dark">
                    ड) पवनचक्की / कारखाना मागणी
                  </Link>
                </div>
                <div className="arrow-box me-3">
                  <Link to="/eco-e" className="ms-5 text-dark">
                    इ) खर्च तरतूद
                  </Link>
                </div>
              </div>
              {/* <TabEco/> */}
            </div>
            <div className="col-lg-12 mt-5 mb-5">
              <Village_Tal_Dist
                villageDataInfo={villageDataInfo}
                yearInfo={yearInfo}
              />
            </div>
            <div className="col-lg-12">
              <div className="table-main-sec">
                <p>
                  ब) पाणीपट्टी (सामान्य व विशेष पाणीपट्टी){" "}
                  {isDraft === "Y" ? (
                    <span className="fw-bold text-danger">is Drafted</span>
                  ) : isDraft === "N" ? (
                    <span className="submit_text">
                      Submitted{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">कराचे नाव</th>
                        <th scope="col">
                          मागील थकबाकी <br /> (सन{" "}
                          {yearInfo ? yearInfo.previous_year_name : ""})
                        </th>
                        <th scope="col">
                          चालू मागणी <br /> (सन{" "}
                          {yearInfo ? yearInfo.current_year_name : ""})
                        </th>
                        <th scope="col">
                          एकूण मागणी <br />
                          (मागील + चालू) (सन{" "}
                          {yearInfo ? yearInfo.current_year_name : ""})
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>सामान्य पाणीपट्टी</td>
                        <td>
                          <input
                            type="text"
                            value={commonWatershed1}
                            onChange={(e) =>
                              setCommonWatershed1(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(commonWatershed1)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(commonWatershed1)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={commonWatershed1Ref}
                            autoFocus
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={commonWatershed2}
                            onChange={(e) =>
                              setCommonWatershed2(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(commonWatershed2)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(commonWatershed2)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={commonWatershed2Ref}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={commonWatershedSum}
                            readOnly
                            className="form-control table-input-box fw-bold"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>विशेष पाणीपट्टी</td>
                        <td>
                          <input
                            type="text"
                            value={specialWaterStrip1}
                            onChange={(e) =>
                              setSpecialWaterStrip1(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(specialWaterStrip1)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(specialWaterStrip1)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={specialWaterStrip1Ref}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={specialWaterStrip2}
                            onChange={(e) =>
                              setSpecialWaterStrip2(e.target.value)
                            }
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-", "*"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            placeholder={
                              validated && !isNumeric(specialWaterStrip2)
                                ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                : ""
                            }
                            className={`form-control table-input-box ${
                              validated && !isNumeric(specialWaterStrip2)
                                ? "is-invalid"
                                : ""
                            }`}
                            ref={specialWaterStrip2Ref}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={specialWaterStripSum}
                            readOnly
                            className="form-control table-input-box fw-bold"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>एकूण</th>
                        <td>
                          <input
                            type="number"
                            value={pastDue}
                            readOnly
                            className="form-control fw-bold table-input-box"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={currentDemand}
                            readOnly
                            className="form-control fw-bold table-input-box"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={aggregateDemand}
                            readOnly
                            className="form-control fw-bold table-input-box"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="mt-3">
                  वरील प्रमाणे ग्रामपंचायतची करमागणी ग्रामपंचायतची दिनांक -{" "}
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className={`date_input ${validated ? "" : "is-invalid"}`}
                    placeholder="dd-mm-yy"
                    max={getCurrentDate()}
                  />{" "}
                  रोजीच्या मासिक सभेत ठराव क्र.
                  <input
                    type="text"
                    value={srNo}
                    onChange={(e) => setSrNo(e.target.value)}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-", "*"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    placeholder={
                      validated && !isNumeric(srNo) ? "क्रमांक टाका." : ""
                    }
                    className={`sr_no_input ${
                      validated && !isNumeric(srNo) ? "is-invalid" : ""
                    }`}
                    ref={srNoRef}
                    style={inputWidth}
                  />{" "}
                  ने कायम झाला.
                </p>
                {validated && !date ? (
                  <span className="text-danger">दिनांक निवडा.</span>
                ) : validated && !dateRegex.test(formatted) ? (
                  <span className="text-danger">
                    अयोग्य दिनांक फॉर्मॅट (dd-mm-yyyy).
                  </span>
                ) : (
                  ""
                )}
                {validated && !isNumeric(srNo) ? (
                  <span className="text-danger">ठराव क्रमांक टाका.</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <FooterDevOfficer villageDataInfo={villageDataInfo} />
            </div>
            <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
              {(isDraft === "" || isDraft === "Y") && (
                <FormBtn
                  resetForm={handleDataRefreshReset}
                  handleSubmit={handleFormSubmission}
                  handleDraft={handleStoreEconomic}
                />
              )}
            </div>
            {isDraft === "N" && (
              <div className="col-lg-12">
                <div className="d-flex justify-content-between">
                  <p className="text-danger">
                    सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                    करा.{" "}
                  </p>
                  <div>
                    <Button variant="contained">विनंती करा</Button>
                  </div>
                </div>
              </div>
            )}
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  {" "}
                  सूचना <MdOutlineArrowDownward />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                इच्छिता?
              </Modal.Body>
              <Modal.Footer className="gap-3">
                <Button className="modal-button-cancel" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  className="modal-button-submit"
                  onClick={handleUpdateEconomic}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <ToastContainer
            position={"top-center"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default EconomicsYear_B;
