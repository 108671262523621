/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import "./EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FormBtn from "../../component/buttons/Form/FormBtn";
import NavigatePage from "../../component/header-navigate/NavigatePage";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";
import Village_Tal_Dist_Mahe from "../../component/header-navigate/Village_Tal_Dist_Mahe/Village_Tal_Dist_Mahe";
import ExpenseProvisionPage_Navigate from "../../component/header-navigate/ExpenseProvisionPage_Navigate";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Loader from "../../component/Loader/Loader";
import { Button } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";

const ExpenseProvision_B = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [isDraft, setIsDraft] = useState("");
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [isAlertVisible, setAlertVisible] = useState(false);


  // १0% महिला व बालकल्याण खर्चाची तरतूद  input filed calculation

  const [backLog, setBackLog] = useState("");
  const [currentProvision, setCurrentProvision] = useState("");
  const [totalProvision, setTotalProvision] = useState("");
  const [expenses, setExpenses] = useState("");
  const [expenseBData, setExpenseBData] = useState([]);
  const [matter, setMatter] = useState("");
  const [validated, setValidated] = useState(false);

  // get data session
  const userDataInfo = sessionStorage.getItem("info");
  const userData = JSON.parse(userDataInfo);
  const userDetails = userData.user_details;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const CalculateTotalProvision = () => {
    const num1 = parseFloat(backLog) || 0;
    const num2 = parseFloat(currentProvision) || 0;

    if (isNaN(num1) || isNaN(num2)) {
      setTotalProvision("");
    } else {
      const sum = num1 + num2;
      setTotalProvision(Math.round(sum));
    }
    // const sum = parseInt(backLog) + parseInt(currentProvision);
    // setTotalProvision(sum);
  };

  useEffect(() => {
    CalculateTotalProvision();
  }, [backLog, currentProvision]);

  useEffect(() => {
    setIsLoading(true);
    handleExpanse();
  }, [month]);
  // get data on server
  const handleExpanse = async () => {
    const finalData = {
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      year_id: userDetails.current_year_id,
      month: month,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/expense_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("expense_b data", response.data);
      // showing alert when first time data not avialable.
      if (
        response.data.data.economic_e_data === null ||
        response.data.data.economic_e_data.length === 0
      ) {
        setAlertVisible(true);
        setIsLoading(false);
      }
      setVillageDataInfo(response.data.data.other_data);
      setMonth(response.data.data.month);
      setYearInfo(response.data.data.year_info);
      // store data on input
      setBackLog(response.data.data.economic_e_data[0].backlog);
      setCurrentProvision(
        response.data.data.economic_e_data[0].current_provision
      );
      setTotalProvision(response.data.data.economic_e_data[0].total_provision);

      setExpenseBData(response.data.data.expense_b_data);

      // draft condition
      if (response.data.status === true) {
        if (
          response.data.data.expense_b_data === null ||
          response.data.data.expense_b_data.length === 0
        ) {
          setIsDraft("");
        } else {
          setIsDraft(response.data.data.expense_b_data[0].is_draft);
        }
      }

      //current month recovery
      if (
        response.data.data.expense_b_data !== null &&
        response.data.data.expense_b_data.length > 0
      ) {
        // updated date show in inputs
        setExpenses(response.data.data.expense_b_data[0].expenses);
        setMatter(response.data.data.expense_b_data[0].matter);
      } else {
        // updated date show in inputs
        setExpenses(0);
        setMatter("");
      }
    } catch (error) {
      console.log("error occured fetch data on server", error);
    }
  };

  const handleDataRefresh = async () => {
    await handleExpanse();
  };

  const handleDataReset = async () => {
    if (expenseBData.length > 0) {
      await handleExpanse();
      setValidated(false);
    } else {
      setBackLog("");
      setCurrentProvision("");
      setTotalProvision("");
      setExpenses("");
      setMatter("");
      setValidated(false);
    }
  };

  // Refs for input elements
  const backLogRef = useRef(null);
  const currentProvisionRef = useRef(null);
  const expensesRef = useRef(null);
  const matterRef = useRef(null);

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(backLog)) {
        backLogRef.current.focus();
      } else if (!isNumeric(currentProvision)) {
        currentProvisionRef.current.focus();
      } else if (!isNumeric(expenses)) {
        expensesRef.current.focus();
      } else if (!isText(matter)) {
        matterRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  const isText = (value) => {
    return /^[\u0900-\u097Fa-zA-Z\s]+$/.test(value);
  };

  // before submit check validation of
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(backLog) ||
      !isNumeric(currentProvision) ||
      !isNumeric(expenses) ||
      !isText(matter)
    ) {
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      if (validated) {
        await handleUpdateData(e);
      }
    } else {
      handleShow();
    }
  };

  // scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling
    });
  };

  // updata data on server
  const handleUpdateData = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
    handleClose();
    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      month: month,
      expense_b_data: [
        {
          type: "1",
          expenses: expenses,
          matter: matter,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/update_expense_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false);
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("error occured updata data", error);
    }
  };

  // store data in draft
  const handleDraft = async (e) => {
    e.preventDefault();

    const invalidFormats =
      (!isNumeric(backLog) && backLog !== "") ||
      (!isNumeric(currentProvision) && currentProvision !== "") ||
      (!isNumeric(expenses) && expenses !== "") ||
      (!isText(matter) && matter !== "");

    if (invalidFormats) {
      setValidated(true);
      return;
    }

    const finalData = {
      year_id: userDetails.current_year_id,
      gram_sevek_id: userDetails.id,
      village_id: userDetails.village_id,
      taluka_id: userDetails.taluka_id,
      district_id: userDetails.district_id,
      month: month,
      expense_b_data: [
        {
          type: "1",
          expenses: expenses,
          matter: matter,
        },
      ],
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/draft_expense_b",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      toast.success(response.data.message);
      setValidated(false); // Reset validation state
      setTimeout(() => {
        handleDataRefresh();
      }, 2000);
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Helmet>
              <title>महिला व बालकल्याण खर्चाची तरतूद</title>
            </Helmet>
            <div className="container pt-0 common-section">
              <div className="row">
                <div className="col-lg-12 navigate-section-header mt-3">
                  <div className="page-title">
                    <p className="fw-bold mb-2">१.३ खर्च तरतूद</p>
                  </div>
                  <div className="navigate-section d-flex">
                    <div className="arrow-box">
                      <Link to="/expense-pro-a" className="me-5 text-dark">
                        अ) १५% मागासवर्गीय खर्चाची तरतूद
                      </Link>
                    </div>
                    <div className="arrow-box-active d-flex flex-column justify-content-center">
                      <Link
                        to="/expense-pro-b"
                        className="text-decoration-none"
                      >
                        ब) १0% महिला व बालकल्याण खर्चाची तरतूद
                      </Link>
                      <span className="text-center arrow-icon">
                        <ArrowDropDownIcon fontSize="large" color="primary" />
                      </span>
                    </div>
                    <div className="arrow-box me-5">
                      <Link to="/expense-pro-c" className="ms-5 text-dark">
                        क) ५% अपंग खर्चाची तरतूद
                      </Link>
                    </div>
                  </div>
                  {/* <TabEco/> */}
                </div>
                {!isAlertVisible ? (
                  <>
                    <div className="col-lg-12 mt-5 mb-4">
                      {/* eslint-disable-next-line react/jsx-pascal-case */}
                      <div className="d-flex gap-5">
                        <Village_Tal_Dist_Mahe
                          villageDataInfo={villageDataInfo}
                        />
                        <div className="VillagePanchayat d-flex gap-2 justify-content-center align-items-center">
                          <label htmlFor="">माहे :</label>
                          {month === undefined ? (
                          <span></span>
                        ) : (
                          <select
                            name="month"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            className="fw-bold"
                          >
                            <option value="1">जानेवारी</option>
                            <option value="2">फेब्रुवारी</option>
                            <option value="3">मार्च</option>
                            <option value="4">एप्रिल</option>
                            <option value="5">मे</option>
                            <option value="6">जून</option>
                            <option value="7">जुलै</option>
                            <option value="8">ऑगस्ट</option>
                            <option value="9">सप्टेंबर</option>
                            <option value="10">ऑक्टोंबर</option>
                            <option value="11">नोव्हेंबर</option>
                            <option value="12">डिसेंबर</option>
                          </select>
                        )}
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-main-sec">
                        <p className="gap-3 d-flex">
                          ब) १0% महिला व बालकल्याण खर्चाची तरतूद{" "}
                          {isDraft === "Y" ? (
                            <span className="fw-bold text-danger ms-2 ">
                              <FaEdit /> Drafted
                            </span>
                          ) : isDraft === "N" ? (
                            <span className="table_title_top gap-0 submit_text1s d-inline">
                              Submitted{" "}
                              <img
                                src={require("../../component/assets/images/sumbit-success.png")}
                                alt="success"
                                width={30}
                                className="img-fluid"
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: "3rem" }}>
                                  अ.क्र.
                                </th>
                                <th scope="col">मागील अनुशेष</th>
                                <th scope="col">
                                  चालू तरतूद <br /> (सन २०२२-२०२३)
                                </th>
                                <th scope="col">
                                  एकूण तरतूद <br />
                                  (मागील + चालू) (सन २०२२-२०२३)
                                </th>
                                <th scope="col">खर्च</th>
                                <th scope="col">बाब</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    type="number"
                                    value={1}
                                    disabled
                                    className="form-control table-input-box"
                                    style={{ background: "transparent" }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={backLog}
                                    onChange={(e) => setBackLog(e.target.value)}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(backLog)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(backLog)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={backLogRef}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={currentProvision}
                                    onChange={(e) =>
                                      setCurrentProvision(e.target.value)
                                    }
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(currentProvision)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(currentProvision)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={currentProvisionRef}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={totalProvision}
                                    readOnly
                                    className="form-control table-input-box fw-bold"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={expenses}
                                    onChange={(e) =>
                                      setExpenses(e.target.value)
                                    }
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isNumeric(expenses)
                                        ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isNumeric(expenses)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={expensesRef}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={matter}
                                    onChange={(e) => setMatter(e.target.value)}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "*"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                    placeholder={
                                      validated && !isText(matter)
                                        ? "कृपया वैध माहिती प्रविष्ट करा."
                                        : ""
                                    }
                                    className={`form-control table-input-box ${
                                      validated && !isText(matter)
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    ref={matterRef}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                      <FooterDevOfficer villageDataInfo={villageDataInfo} />
                    </div>
                    {(month === currentMonth.toString() && !isLoading) &&
                      (isDraft === "" || isDraft === "Y") && (
                        <div className="col-lg-12 d-flex justify-content-end align-items-center">
                          <FormBtn
                            resetForm={handleDataReset}
                            handleSubmit={handleFormSubmission}
                            handleDraft={handleDraft}
                          />
                        </div>
                      )}
                    {(isDraft === "N" || !isDraft === "") && (
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between">
                          <p className="text-danger">
                            सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                            विनंती करा.{" "}
                          </p>
                          <div>
                            <Button variant="contained">विनंती करा</Button>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {" "}
                          सूचना <MdOutlineArrowDownward />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म
                        Edit करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म
                        Submit करू इच्छिता?
                      </Modal.Body>
                      <Modal.Footer className="gap-3">
                        <Button
                          className="modal-button-cancel"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="modal-button-submit"
                          onClick={handleUpdateData}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : (
                  <section
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <div className="alert_pop_up_common">
                            <h4 className="alert_pop_up_common_title">
                              आर्थिक वर्ष मागणी
                            </h4>
                            <p className="alert_pop_up_common_des">
                              {" "}
                              कृपया आर्थिक वर्ष मागणी मधील १0% मागासवर्गीय
                              खर्चाची तरतूद भरा.
                            </p>
                            <Link className="alert_submit_btn" to="/eco-e">
                              आता पूर्ण करा
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
            <ToastContainer
              position={"top-center"}
              autoClose={2000}
              pauseOnHover={false}
              closeOnClick={true}
            />
          </>
        )}
      </>
    </>
  );
};

export default ExpenseProvision_B;
