/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import style from "./styles.module.css";

import { ToastContainer, toast } from "react-toastify";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { Button } from "@mui/material";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { MdOutlineArrowDownward } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const SheetC_D = ({
  handleNextStep,
  handlePrevStep,
  currentStep,
  handleStepDetails,
}) => {
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const [economicData, setEconomicData] = useState("");

  const [sheetData, setSheetData] = useState({
    sr_no: "1",
    panchayat_name: "",
    gpt_name: "",
    land_revenue: "",
    generalization_grant: "",
    est_cess_under_gpt: "",
    grant_for_development: "",
    total_grant: "",
    subordinate_contributions: "",
    backwards_donations: "",
    prizes: "",
    rule_of_penalty: "",
    grant: "",
    others: "",
    total_contribution: "",
    building_land_tax: "",
    tax_on_pilgrims: "",
    tax_on_orcestra: "",
    tax_on_hotel_or_shop: "",
    tax_on_bicycles_or_vehicles: "",
    tax_on_physical_labour: "",
    tax_on_cattle_brokers: "",
    tax_on_fairs_or_amusements: "",
    tax_on_weekly_market: "",
    tax_on_temporary_place: "",
    tax_on_bullock_carts: "",
    sum_factory_windmill: "",
    fees_for_supply_water: "",
    general_cleaning_strip: "",
    toll_on_vehicle_or_animal: "",
    fees_animal_feeding: "",
    general_water_supply: "",
    other_tax_fees: "",
    total_deposit_fourteen: "",
    total_deposit_seven: "",
    per_capita_tax_burden: "",
    per_capita_income_ratio: "",
    total_earned_previous_year: "",
    more_or_less_previous_year: "",
    reason_more_or_less: "",
  });

  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // get api

  const handleSheetADetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/varshik_ahwal_form_c_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("sheetCD response", response.data.data);
      if (response.data.status === true) {
        const data = response.data.data.form_c_d_data;
        const otherData = response.data.data.other_data;
        const defaultSheetData = {
          sr_no: "1",
          panchayat_name: otherData.taluka_name,
          gpt_name: otherData.village_name,
          // ... (other fields with default values)
        };
        if (data !== null) {
          setSheetData({
            ...defaultSheetData,
            land_revenue: data.land_revenue,
            generalization_grant: data.generalization_grant,
            est_cess_under_gpt: data.est_cess_under_gpt,
            grant_for_development: data.grant_for_development,
            total_grant: "",
            subordinate_contributions: data.subordinate_contributions,
            backwards_donations: data.backwards_donations,
            prizes: data.prizes,
            rule_of_penalty: data.rule_of_penalty,
            grant: data.grant,
            others: data.others,
            total_contribution: "",
            building_land_tax: data.building_land_tax,
            tax_on_pilgrims: data.tax_on_pilgrims,
            tax_on_orcestra: data.tax_on_orcestra,
            tax_on_hotel_or_shop: data.tax_on_hotel_or_shop,
            tax_on_bicycles_or_vehicles: data.tax_on_bicycles_or_vehicles,
            tax_on_physical_labour: data.tax_on_physical_labour,
            tax_on_cattle_brokers: data.tax_on_cattle_brokers,
            tax_on_fairs_or_amusements: data.tax_on_fairs_or_amusements,
            tax_on_weekly_market: data.tax_on_weekly_market,
            tax_on_temporary_place: data.tax_on_temporary_place,
            tax_on_bullock_carts: data.tax_on_bullock_carts,
            sum_factory_windmill: data.sum_factory_windmill,
            fees_for_supply_water: data.fees_for_supply_water,
            general_cleaning_strip: data.general_cleaning_strip,
            toll_on_vehicle_or_animal: data.toll_on_vehicle_or_animal,
            fees_animal_feeding: data.fees_animal_feeding,
            general_water_supply: data.general_water_supply,
            other_tax_fees: data.other_tax_fees,
            total_deposit_fourteen: "",
            total_deposit_seven: "",
            per_capita_tax_burden: data.per_capita_tax_burden,
            per_capita_income_ratio: data.per_capita_income_ratio,
            total_earned_previous_year: data.total_earned_previous_year,
            more_or_less_previous_year: data.more_or_less_previous_year,
            reason_more_or_less: data.reason_more_or_less,
          });
        } else {
          setSheetData({
            ...defaultSheetData,
            land_revenue: "",
            generalization_grant: "",
            est_cess_under_gpt: "",
            grant_for_development: "",
            total_grant: "",
            subordinate_contributions: "",
            backwards_donations: "",
            prizes: "",
            rule_of_penalty: "",
            grant: "",
            others: "",
            total_contribution: "",
            building_land_tax: "",
            tax_on_pilgrims: "",
            tax_on_orcestra: "",
            tax_on_hotel_or_shop: "",
            tax_on_bicycles_or_vehicles: "",
            tax_on_physical_labour: "",
            tax_on_cattle_brokers: "",
            tax_on_fairs_or_amusements: "",
            tax_on_weekly_market: "",
            tax_on_temporary_place: "",
            tax_on_bullock_carts: "",
            sum_factory_windmill: "",
            fees_for_supply_water: "",
            general_cleaning_strip: "",
            toll_on_vehicle_or_animal: "",
            fees_animal_feeding: "",
            general_water_supply: "",
            other_tax_fees: "",
            total_deposit_fourteen: "",
            total_deposit_seven: "",
            per_capita_tax_burden: "",
            per_capita_income_ratio: "",
            total_earned_previous_year: "",
            more_or_less_previous_year: "",
            reason_more_or_less: "",
          });
        }
      }

      if (
        response.data.data.form_c_d_data === null ||
        response.data.data.form_c_d_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.form_c_d_data.is_draft);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleSheetADetails();
  }, []);

  // onchange function

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the current field and get errors
    const errors = validateForm();

    // Clear the error for the specific field being modified
    const updatedErrors = { ...errors };
    updatedErrors[name] = undefined;

    // Set the updated errors
    setErrors(updatedErrors);

    // Update the sheetData
    setSheetData((prevData) => ({ ...prevData, [name]: value }));
  };

  // table validation
  const validateForm = () => {
    const newErrors = {};

    // Validate tabels all input
    if (
      sheetData.land_revenue !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.land_revenue)
    ) {
      newErrors.land_revenue = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.generalization_grant !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.generalization_grant)
    ) {
      newErrors.generalization_grant = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.est_cess_under_gpt !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.est_cess_under_gpt)
    ) {
      newErrors.est_cess_under_gpt = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.grant_for_development !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.grant_for_development)
    ) {
      newErrors.grant_for_development = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.subordinate_contributions !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.subordinate_contributions)
    ) {
      newErrors.subordinate_contributions = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.backwards_donations !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.backwards_donations)
    ) {
      newErrors.backwards_donations = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.prizes !== "" && !/^-?\d*\.?\d+$/.test(sheetData.prizes)) {
      newErrors.prizes = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.rule_of_penalty !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.rule_of_penalty)
    ) {
      newErrors.rule_of_penalty = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.grant !== "" && !/^-?\d*\.?\d+$/.test(sheetData.grant)) {
      newErrors.grant = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (sheetData.others !== "" && !/^[0-9a-zA-Z]+$/.test(sheetData.others)) {
      newErrors.others = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }
    if (
      sheetData.building_land_tax !== "" &&
      !/^[0-9a-zA-Z]+$/.test(sheetData.building_land_tax)
    ) {
      newErrors.building_land_tax = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_pilgrims !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_pilgrims)
    ) {
      newErrors.tax_on_pilgrims = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_orcestra !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_orcestra)
    ) {
      newErrors.tax_on_orcestra = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_hotel_or_shop !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_hotel_or_shop)
    ) {
      newErrors.tax_on_hotel_or_shop = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_bicycles_or_vehicles !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_bicycles_or_vehicles)
    ) {
      newErrors.tax_on_bicycles_or_vehicles = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_physical_labour !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_physical_labour)
    ) {
      newErrors.tax_on_physical_labour = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_cattle_brokers !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_cattle_brokers)
    ) {
      newErrors.tax_on_cattle_brokers = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_fairs_or_amusements !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_fairs_or_amusements)
    ) {
      newErrors.tax_on_fairs_or_amusements = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_weekly_market !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_weekly_market)
    ) {
      newErrors.tax_on_weekly_market = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_temporary_place !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_temporary_place)
    ) {
      newErrors.tax_on_temporary_place = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.tax_on_bullock_carts !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.tax_on_bullock_carts)
    ) {
      newErrors.tax_on_bullock_carts = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.sum_factory_windmill !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.sum_factory_windmill)
    ) {
      newErrors.sum_factory_windmill = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fees_for_supply_water !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fees_for_supply_water)
    ) {
      newErrors.fees_for_supply_water = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.general_cleaning_strip !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.general_cleaning_strip)
    ) {
      newErrors.general_cleaning_strip = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.toll_on_vehicle_or_animal !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.toll_on_vehicle_or_animal)
    ) {
      newErrors.toll_on_vehicle_or_animal = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.fees_animal_feeding !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.fees_animal_feeding)
    ) {
      newErrors.fees_animal_feeding = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.general_water_supply !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.general_water_supply)
    ) {
      newErrors.general_water_supply = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.other_tax_fees !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.other_tax_fees)
    ) {
      newErrors.other_tax_fees = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.per_capita_tax_burden !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.per_capita_tax_burden)
    ) {
      newErrors.per_capita_tax_burden = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.per_capita_income_ratio !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.per_capita_income_ratio)
    ) {
      newErrors.per_capita_income_ratio = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.total_earned_previous_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.total_earned_previous_year)
    ) {
      newErrors.total_earned_previous_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.more_or_less_previous_year !== "" &&
      !/^-?\d*\.?\d+$/.test(sheetData.more_or_less_previous_year)
    ) {
      newErrors.more_or_less_previous_year = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }
    if (
      sheetData.reason_more_or_less !== "" &&
      !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.reason_more_or_less)
    ) {
      newErrors.reason_more_or_less = "कृपया वैध क्रमांक प्रविष्ट करा.";
    }

    // if (
    //   sheetData.shera !== "" &&
    //   !/^[\u0900-\u097Fa-zA-Z\s]+$/.test(sheetData.shera)
    // ) {
    //   newErrors.shera = "कृपया केवळ अक्षरे प्रविष्ट करा.";
    // }
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  // scroll to top
  useEffect(() => {
    scrollTop();
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  // reload the data when draft or update
  const handleDataRefresh = async () => {
    await handleSheetADetails();
  };

  // update api

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    console.log("Initial form data:", sheetData);
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      handleClose();
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_c_d_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          land_revenue: sheetData.land_revenue,
          generalization_grant: sheetData.generalization_grant,
          est_cess_under_gpt: sheetData.est_cess_under_gpt,
          grant_for_development: sheetData.grant_for_development,
          total_grant: totalGrant,
          subordinate_contributions: sheetData.subordinate_contributions,
          backwards_donations: sheetData.backwards_donations,
          prizes: sheetData.prizes,
          rule_of_penalty: sheetData.rule_of_penalty,
          grant: sheetData.grant,
          others: sheetData.others,
          total_contribution: sheetData.total_contribution,
          building_land_tax: sheetData.building_land_tax,
          tax_on_pilgrims: sheetData.tax_on_pilgrims,
          tax_on_orcestra: sheetData.tax_on_orcestra,
          tax_on_hotel_or_shop: sheetData.tax_on_hotel_or_shop,
          tax_on_bicycles_or_vehicles: sheetData.tax_on_bicycles_or_vehicles,
          tax_on_physical_labour: sheetData.tax_on_physical_labour,
          tax_on_cattle_brokers: sheetData.tax_on_cattle_brokers,
          tax_on_fairs_or_amusements: sheetData.tax_on_fairs_or_amusements,
          tax_on_weekly_market: sheetData.tax_on_weekly_market,
          tax_on_temporary_place: sheetData.tax_on_temporary_place,
          tax_on_bullock_carts: sheetData.tax_on_bullock_carts,
          sum_factory_windmill: sheetData.sum_factory_windmill,
          fees_for_supply_water: sheetData.fees_for_supply_water,
          general_cleaning_strip: sheetData.general_cleaning_strip,
          toll_on_vehicle_or_animal: sheetData.toll_on_vehicle_or_animal,
          fees_animal_feeding: sheetData.fees_animal_feeding,
          general_water_supply: sheetData.general_water_supply,
          other_tax_fees: sheetData.other_tax_fees,
          total_deposit_fourteen: totalDeposite,
          total_deposit_seven: totalAllDeposite,
          per_capita_tax_burden: sheetData.per_capita_tax_burden,
          per_capita_income_ratio: sheetData.per_capita_income_ratio,
          total_earned_previous_year: sheetData.total_earned_previous_year,
          more_or_less_previous_year: sheetData.more_or_less_previous_year,
          reason_more_or_less: sheetData.reason_more_or_less,
        },
      };
      console.log("update finalData sheetCD", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/update_varshik_ahwal_form_c_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateSheetCD response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleDataRefresh();
        handleStepDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // draft api
  const handleDraftSheetCD = async (e) => {
    e.preventDefault();
    // Validate the form
    if (validateForm()) {
      console.log("Form submitted:", sheetData);
    } else {
      scrollTop(); // Scroll to the top if there are validation errors
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        form_c_d_data: {
          panchayat_name: sheetData.panchayat_name,
          gpt_name: sheetData.gpt_name,
          land_revenue: sheetData.land_revenue,
          generalization_grant: sheetData.generalization_grant,
          est_cess_under_gpt: sheetData.est_cess_under_gpt,
          grant_for_development: sheetData.grant_for_development,
          total_grant: totalGrant,
          subordinate_contributions: sheetData.subordinate_contributions,
          backwards_donations: sheetData.backwards_donations,
          prizes: sheetData.prizes,
          rule_of_penalty: sheetData.rule_of_penalty,
          grant: sheetData.grant,
          others: sheetData.others,
          total_contribution: sheetData.total_contribution,
          building_land_tax: sheetData.building_land_tax,
          tax_on_pilgrims: sheetData.tax_on_pilgrims,
          tax_on_orcestra: sheetData.tax_on_orcestra,
          tax_on_hotel_or_shop: sheetData.tax_on_hotel_or_shop,
          tax_on_bicycles_or_vehicles: sheetData.tax_on_bicycles_or_vehicles,
          tax_on_physical_labour: sheetData.tax_on_physical_labour,
          tax_on_cattle_brokers: sheetData.tax_on_cattle_brokers,
          tax_on_fairs_or_amusements: sheetData.tax_on_fairs_or_amusements,
          tax_on_weekly_market: sheetData.tax_on_weekly_market,
          tax_on_temporary_place: sheetData.tax_on_temporary_place,
          tax_on_bullock_carts: sheetData.tax_on_bullock_carts,
          sum_factory_windmill: sheetData.sum_factory_windmill,
          fees_for_supply_water: sheetData.fees_for_supply_water,
          general_cleaning_strip: sheetData.general_cleaning_strip,
          toll_on_vehicle_or_animal: sheetData.toll_on_vehicle_or_animal,
          fees_animal_feeding: sheetData.fees_animal_feeding,
          general_water_supply: sheetData.general_water_supply,
          other_tax_fees: sheetData.other_tax_fees,
          total_deposit_fourteen: totalDeposite,
          total_deposit_seven: totalAllDeposite,
          per_capita_tax_burden: sheetData.per_capita_tax_burden,
          per_capita_income_ratio: sheetData.per_capita_income_ratio,
          total_earned_previous_year: sheetData.total_earned_previous_year,
          more_or_less_previous_year: sheetData.more_or_less_previous_year,
          reason_more_or_less: sheetData.reason_more_or_less,
        },
      };
      console.log("draftSheetCD finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/draft_varshik_ahwal_form_c_d",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("draft SheetCD response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleDataRefresh();
          handleStepDetails();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // end draft api

  // totalGrant
  const totalGrant =
    parseInt(sheetData.land_revenue || 0) +
      parseInt(sheetData.generalization_grant || 0) +
      parseInt(sheetData.est_cess_under_gpt || 0) +
      parseInt(sheetData.grant_for_development || 0) || "";

  // totalUnreservedSeat
  const totalContribution =
    parseInt(sheetData.subordinate_contributions || 0) +
      parseInt(sheetData.backwards_donations || 0) +
      parseInt(sheetData.prizes || 0) +
      parseInt(sheetData.rule_of_penalty || 0) +
      parseInt(sheetData.grant || 0) +
      parseInt(sheetData.others || 0) || "";

  // totalDeposite
  const totalDeposite =
    parseInt(sheetData.building_land_tax || 0) +
      parseInt(sheetData.tax_on_pilgrims || 0) +
      parseInt(sheetData.tax_on_orcestra || 0) +
      parseInt(sheetData.tax_on_hotel_or_shop || 0) +
      parseInt(sheetData.tax_on_bicycles_or_vehicles || 0) +
      parseInt(sheetData.tax_on_physical_labour || 0) +
      parseInt(sheetData.tax_on_cattle_brokers || 0) +
      parseInt(sheetData.tax_on_fairs_or_amusements || 0) +
      parseInt(sheetData.tax_on_weekly_market || 0) +
      parseInt(sheetData.tax_on_temporary_place || 0) +
      parseInt(sheetData.tax_on_bullock_carts || 0) +
      parseInt(sheetData.sum_factory_windmill || 0) +
      parseInt(sheetData.fees_for_supply_water || 0) +
      parseInt(sheetData.general_cleaning_strip || 0) +
      parseInt(sheetData.toll_on_vehicle_or_animal || 0) +
      parseInt(sheetData.fees_animal_feeding || 0) +
      parseInt(sheetData.general_water_supply || 0) +
      parseInt(sheetData.other_tax_fees || 0) || "";

  // totalAllDeposite
  const totalAllDeposite =
    parseInt(totalGrant || 0) +
      parseInt(totalContribution || 0) +
      parseInt(totalDeposite || 0) || "";
  return (
    <>
      <Helmet>
        <title>पत्रक - सी आणि डी</title>
      </Helmet>
      <div
        id="table-container"
        className={`container-fluid sheet_bg_color_pdf ${style.custom_container} pt-0`}
      >
        <div className={`row`}>
          <div className="col-lg-12">
            <div className="table-main-sec">
              <div className="table_top mb-5">
                <div className={`${style.patrak_title_top}`}>
                  <p className="mb-4">
                    शासनाकडून मिळालेली अनुदाने, कर, फी इ. पासून उत्पन्नासंबंधी
                    माहिती दर्शविणारे पत्रक
                    <span className="patrak_box">पत्रक- 'सी' आणि 'डी'</span>
                  </p>
                  {economicData === "Y" ? (
                    <span className="drafted_text text-danger mt-3">
                      <FaEdit /> Drafted
                    </span>
                  ) : economicData === "N" ? (
                    <span className="table_title_top gap-0 submit_text1s ">
                      Submitted{" "}
                      <img
                        src={require("../../component/assets/images/sumbit-success.png")}
                        alt="success"
                        width={30}
                        className="img-fluid"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <form action="">
                  <table className={style.table_bordered}>
                    <thead className={`${style.custom_thead}`}>
                      <tr className={` ${style.common_tr_border}`}>
                        <th rowSpan={4}>अ.क्र</th>
                        <th rowSpan={4}>पंचायत समितीचे नाव</th>
                        <th className="text-center" rowSpan={4}>
                          ग्राम पंचायतीचे नाव
                        </th>
                        <th className="text-center" colSpan={17}>
                          शासनाकडून मिळालेली अंशदाने, देणग्या व बक्षिसे
                        </th>
                      </tr>
                      <tr className={` ${style.common_tr_border}`}>
                        <th className="text-center" colSpan={3}>
                          शासनाकडून सहाय्य अनुदान
                        </th>
                        <th rowSpan={3}>
                          जि.प.व. <br /> पं.स.कडून विकास कामासाठी अनुदान
                        </th>
                        <th rowSpan={3}>
                          एकूण अनुदान कॉ.नं. <br /> ३ ते ६
                        </th>
                        <th className="text-center" colSpan={7}>
                          शासनाकडून मिळालेली अंशदाने, देणग्या व बक्षिसे
                        </th>
                        <th className="text-center" colSpan={6}>
                          कर फी ईत्यादी
                        </th>
                      </tr>
                      <tr className={` ${style.common_tr_border}`}>
                        <th rowSpan={2}>जमीन महसूल</th>
                        <th rowSpan={2}>सामानी करण अनुदान</th>
                        <th rowSpan={2}>ग्रा.पं.का.क १२७ अन्वये स्था.उपकर</th>
                        <th rowSpan={2}>गौण अंशदाने</th>
                        <th rowSpan={2}>
                          मागास <br /> वर्गीया <br /> साठी <br /> देणग्या
                        </th>
                        <th rowSpan={2}>बक्षिसे</th>
                        <th rowSpan={2}>नियमाचा भंग केल्याबद्दल दंड</th>
                        <th colSpan={2}>
                          इतर निर्दिष्ट न केलेल्या संकीर्ण बाबी
                        </th>
                        <th rowSpan={2}>एकूण अंशदाने देणग्या कॉ.नं ८ ते १२</th>
                        <th rowSpan={2}>इमारती जमीन पावरोल कर</th>
                        <th rowSpan={2}>यात्रेकरू वरील कर</th>
                        <th rowSpan={2}>ऑक्ट्रा वरील कर</th>
                        <th rowSpan={2}>दुकाने व हॉटेल या वरील कर</th>
                        <th rowSpan={2}>
                          सायकली व जनावरांकडून ओढ्ल्या जाणार्‍या वाहना वरील कर
                        </th>
                      </tr>
                      <tr className={` ${style.common_tr_border}`}>
                        <th>अनुदान</th>
                        <th>इतर</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>१</td>
                        <td>२</td>
                        <td>२ अ</td>
                        <td>३</td>
                        <td>४</td>
                        <td>५</td>
                        <td>६</td>
                        <td>७</td>
                        <td>८</td>
                        <td>९</td>
                        <td>१०</td>
                        <td>११</td>
                        <td>१२</td>
                        <td>१२ अ</td>
                        <td>१३</td>
                        <td>१४</td>
                        <td>१५</td>
                        <td>१६</td>
                        <td>१७</td>
                        <td>१८</td>
                      </tr>
                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>{sheetData.sr_no}</td>
                        <td>{sheetData.panchayat_name}</td>
                        <td>{sheetData.gpt_name}</td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.land_revenue ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.land_revenue ? errors.land_revenue : ""
                            }
                            name="land_revenue"
                            value={sheetData.land_revenue}
                            onChange={handleChange}
                            style={{
                              width: sheetData.land_revenue.length <= 3
                                ? "100%"
                                : `${sheetData.land_revenue.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.generalization_grant ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.generalization_grant
                                ? errors.generalization_grant
                                : ""
                            }
                            name="generalization_grant"
                            value={sheetData.generalization_grant}
                            onChange={handleChange}
                            style={{
                              width: sheetData.generalization_grant.length <= 3
                                ? "100%"
                                : `${sheetData.generalization_grant.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.est_cess_under_gpt ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.est_cess_under_gpt
                                ? errors.est_cess_under_gpt
                                : ""
                            }
                            name="est_cess_under_gpt"
                            value={sheetData.est_cess_under_gpt}
                            onChange={handleChange}
                            style={{
                              width: sheetData.est_cess_under_gpt.length <= 3
                                ? "100%"
                                : `${sheetData.est_cess_under_gpt.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.grant_for_development ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.grant_for_development
                                ? errors.grant_for_development
                                : ""
                            }
                            name="grant_for_development"
                            value={sheetData.grant_for_development}
                            onChange={handleChange}
                            style={{
                              width: sheetData.grant_for_development.length <= 3
                                ? "100%"
                                : `${sheetData.grant_for_development.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalGrant || ""}
                            style={{
                              width: totalGrant.toString().length <= 2 ? "100%" : `${totalGrant.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.subordinate_contributions
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.subordinate_contributions
                                ? errors.subordinate_contributions
                                : ""
                            }
                            name="subordinate_contributions"
                            value={sheetData.subordinate_contributions}
                            onChange={handleChange}
                            style={{
                              width: sheetData.subordinate_contributions.length <= 3
                                ? "100%"
                                : `${sheetData.subordinate_contributions.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.backwards_donations ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.backwards_donations
                                ? errors.backwards_donations
                                : ""
                            }
                            name="backwards_donations"
                            value={sheetData.backwards_donations}
                            onChange={handleChange}
                            style={{
                              width: sheetData.backwards_donations.length <= 3
                                ? "100%"
                                : `${sheetData.backwards_donations.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.prizes ? "is-invalid" : ""
                            }`}
                            placeholder={errors.prizes ? errors.prizes : ""}
                            name="prizes"
                            value={sheetData.prizes}
                            onChange={handleChange}
                            style={{
                              width: sheetData.prizes.length <= 3
                                ? "100%"
                                : `${sheetData.prizes.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.rule_of_penalty ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.rule_of_penalty
                                ? errors.rule_of_penalty
                                : ""
                            }
                            name="rule_of_penalty"
                            value={sheetData.rule_of_penalty}
                            onChange={handleChange}
                            style={{
                              width: sheetData.rule_of_penalty.length <= 3
                                ? "100%"
                                : `${sheetData.rule_of_penalty.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.grant ? "is-invalid" : ""
                            }`}
                            placeholder={errors.grant ? errors.grant : ""}
                            name="grant"
                            value={sheetData.grant}
                            onChange={handleChange}
                            style={{
                              width: sheetData.grant.length <= 3
                                ? "100%"
                                : `${sheetData.grant.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.others ? "is-invalid" : ""
                            }`}
                            placeholder={errors.others ? errors.others : ""}
                            name="others"
                            value={sheetData.others}
                            onChange={handleChange}
                            style={{
                              width: sheetData.others.length <= 3
                                ? "100%"
                                : `${sheetData.others.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalContribution}
                            style={{
                              width: totalContribution.toString().length <= 2 ? "30px" : `${totalContribution.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.building_land_tax ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.building_land_tax
                                ? errors.building_land_tax
                                : ""
                            }
                            name="building_land_tax"
                            value={sheetData.building_land_tax}
                            onChange={handleChange}
                            style={{
                              width: sheetData.building_land_tax.length <= 3
                                ? "100%"
                                : `${sheetData.building_land_tax.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_pilgrims ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_pilgrims
                                ? errors.tax_on_pilgrims
                                : ""
                            }
                            name="tax_on_pilgrims"
                            value={sheetData.tax_on_pilgrims}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_pilgrims.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_pilgrims.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_orcestra ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_orcestra
                                ? errors.tax_on_orcestra
                                : ""
                            }
                            name="tax_on_orcestra"
                            value={sheetData.tax_on_orcestra}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_orcestra.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_orcestra.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_hotel_or_shop ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_hotel_or_shop
                                ? errors.tax_on_hotel_or_shop
                                : ""
                            }
                            name="tax_on_hotel_or_shop"
                            value={sheetData.tax_on_hotel_or_shop}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_hotel_or_shop.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_hotel_or_shop.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_bicycles_or_vehicles
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.tax_on_bicycles_or_vehicles
                                ? errors.tax_on_bicycles_or_vehicles
                                : ""
                            }
                            name="tax_on_bicycles_or_vehicles"
                            value={sheetData.tax_on_bicycles_or_vehicles}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_bicycles_or_vehicles.length <= 6
                                ? "100%"
                                : `${sheetData.tax_on_bicycles_or_vehicles.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="table-main-sec">
              <div className="margin_top_class">
                {/* <div className={`${style.patrak_title_top}`}>
                  <p>
                    <span className="patrak_box">पत्रक- 'सी' आणि 'डी'</span>-
                    (पुढे चालू)
                  </p>
                </div> */}

                <form action="">
                  <table className={`mt -3 ${style.table_bordered}`}>
                    <thead className={`${style.custom_thead}`}>
                      <tr className={`${style.common_tr_border}`}>
                        <th colSpan={20}>कर व फी इत्यादी पासूनचे उत्पन्न</th>
                      </tr>
                      <tr className={`${style.common_tr_border}`}>
                        <th>
                          शेती व्यतिरिक्त <br /> बाष्प तेल किंवा <br /> शारीरिक
                          श्रम यावर चालणारया <br /> यंत्राचा धंदा <br /> व्यासाय
                          वरील कर
                        </th>
                        <th>
                          गुरांच्या बाजारातील दलालांचा धंदयावरील किंवा व्यासाया
                          वरील कर
                        </th>
                        <th>जत्रा उस्तव व करमणूक या वरील कर</th>
                        <th>आठवड्याचे बाजार व मार्केट या वरील कर</th>
                        <th>
                          कोणतेही सार्वजनिक रस्ते किंवा ठिकाण यावर तात्पुरता
                          भोगवटा करणे या वरील कर
                        </th>
                        <th>बैल गाडयांच्या खड्ड्या या वरील कर</th>
                        <th>कारखान्यावरील ठोक रकमेची अंशदने पवनचक्की</th>
                        <th>
                          विहिरी व तळी यातून पाण्याचा पुरवठा करण्याबद्दल फी
                        </th>
                        <th>सर्वसाधारण व विशेष साफसफाई पट्टी आरोग्य</th>
                        <th>वाहने व जनावरे यावरील जकात (टोल)</th>
                        <th>जनावर चारल्या बद्दल फी</th>
                        <th>सर्वसाधारण व विशेष पाणीपट्टी</th>
                        <th>इतर कर फी इत्यादी</th>
                        <th>एकूण जमा कॉ.नं. १४ ते ३१</th>
                        <th>एकूण जमा कॉ.नं. ७ + १३ ते ३२</th>
                        <th>दर माणसी कराचा बोजा</th>
                        <th>दर माणसी उत्पनाचे प्रमाण</th>
                        <th>मागील वर्षी मिळालेली एकूण रक्कम</th>
                        <th>मागील वर्षापेक्षा कमी किंवा अधिक</th>
                        <th>कमी किंवा अधिक यांची कारणे</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        className={`${style.custom_tr} ${style.common_tr_border}`}
                      >
                        <td>१९</td>
                        <td>२०</td>
                        <td>२१</td>
                        <td>२२</td>
                        <td>२३</td>
                        <td>२४</td>
                        <td>२५</td>
                        <td>२६</td>
                        <td>२७</td>
                        <td>२८</td>
                        <td>२९</td>
                        <td>३०</td>
                        <td>३१</td>
                        <td>३२</td>
                        <td>३३</td>
                        <td>३४</td>
                        <td>३५</td>
                        <td>३६</td>
                        <td>३७</td>
                        <td>३८</td>
                      </tr>
                      <tr
                        className={`${style.custom_tr_second} ${style.common_tr_border}`}
                      >
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_physical_labour ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_physical_labour
                                ? errors.tax_on_physical_labour
                                : ""
                            }
                            name="tax_on_physical_labour"
                            value={sheetData.tax_on_physical_labour}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_physical_labour.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_physical_labour.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_cattle_brokers ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_cattle_brokers
                                ? errors.tax_on_cattle_brokers
                                : ""
                            }
                            name="tax_on_cattle_brokers"
                            value={sheetData.tax_on_cattle_brokers}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_cattle_brokers.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_cattle_brokers.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_fairs_or_amusements
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.tax_on_fairs_or_amusements
                                ? errors.tax_on_fairs_or_amusements
                                : ""
                            }
                            name="tax_on_fairs_or_amusements"
                            value={sheetData.tax_on_fairs_or_amusements}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_fairs_or_amusements.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_fairs_or_amusements.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_weekly_market ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_weekly_market
                                ? errors.tax_on_weekly_market
                                : ""
                            }
                            name="tax_on_weekly_market"
                            value={sheetData.tax_on_weekly_market}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_weekly_market.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_weekly_market.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_temporary_place ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_temporary_place
                                ? errors.tax_on_temporary_place
                                : ""
                            }
                            name="tax_on_temporary_place"
                            value={sheetData.tax_on_temporary_place}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_temporary_place.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_temporary_place.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.tax_on_bullock_carts ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.tax_on_bullock_carts
                                ? errors.tax_on_bullock_carts
                                : ""
                            }
                            name="tax_on_bullock_carts"
                            value={sheetData.tax_on_bullock_carts}
                            onChange={handleChange}
                            style={{
                              width: sheetData.tax_on_bullock_carts.length <= 3
                                ? "100%"
                                : `${sheetData.tax_on_bullock_carts.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.sum_factory_windmill ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.sum_factory_windmill
                                ? errors.sum_factory_windmill
                                : ""
                            }
                            name="sum_factory_windmill"
                            value={sheetData.sum_factory_windmill}
                            onChange={handleChange}
                            style={{
                              width: sheetData.sum_factory_windmill.length <= 3
                                ? "100%"
                                : `${sheetData.sum_factory_windmill.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fees_for_supply_water ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fees_for_supply_water
                                ? errors.fees_for_supply_water
                                : ""
                            }
                            name="fees_for_supply_water"
                            value={sheetData.fees_for_supply_water}
                            onChange={handleChange}
                            style={{
                              width: sheetData.fees_for_supply_water.length <= 3
                                ? "100%"
                                : `${sheetData.fees_for_supply_water.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.general_cleaning_strip ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.general_cleaning_strip
                                ? errors.general_cleaning_strip
                                : ""
                            }
                            name="general_cleaning_strip"
                            value={sheetData.general_cleaning_strip}
                            onChange={handleChange}
                            style={{
                              width: sheetData.general_cleaning_strip.length <= 3
                                ? "100%"
                                : `${sheetData.general_cleaning_strip.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.toll_on_vehicle_or_animal
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.toll_on_vehicle_or_animal
                                ? errors.toll_on_vehicle_or_animal
                                : ""
                            }
                            name="toll_on_vehicle_or_animal"
                            value={sheetData.toll_on_vehicle_or_animal}
                            onChange={handleChange}
                            style={{
                              width: sheetData.toll_on_vehicle_or_animal.length <= 3
                                ? "100%"
                                : `${sheetData.toll_on_vehicle_or_animal.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.fees_animal_feeding ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.fees_animal_feeding
                                ? errors.fees_animal_feeding
                                : ""
                            }
                            name="fees_animal_feeding"
                            value={sheetData.fees_animal_feeding}
                            onChange={handleChange}
                            style={{
                              width: sheetData.fees_animal_feeding.length <= 3
                                ? "100%"
                                : `${sheetData.fees_animal_feeding.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.general_water_supply ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.general_water_supply
                                ? errors.general_water_supply
                                : ""
                            }
                            name="general_water_supply"
                            value={sheetData.general_water_supply}
                            onChange={handleChange}
                            style={{
                              width: sheetData.general_water_supply.length <= 3
                                ? "100%"
                                : `${sheetData.general_water_supply.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.other_tax_fees ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.other_tax_fees ? errors.other_tax_fees : ""
                            }
                            name="other_tax_fees"
                            value={sheetData.other_tax_fees}
                            onChange={handleChange}
                            style={{
                              width: sheetData.other_tax_fees.length <= 3
                                ? "100%"
                                : `${sheetData.other_tax_fees.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalDeposite}
                            style={{
                              width: totalDeposite.toString().length <= 2 ? "100%" : `${totalDeposite.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control table-input-box fw-bold"
                            value={totalAllDeposite}
                            style={{
                              width: totalAllDeposite.toString().length <= 2 ? "100%" : `${totalAllDeposite.toString().length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.per_capita_tax_burden ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.per_capita_tax_burden
                                ? errors.per_capita_tax_burden
                                : ""
                            }
                            name="per_capita_tax_burden"
                            value={sheetData.per_capita_tax_burden}
                            onChange={handleChange}
                            style={{
                              width: sheetData.per_capita_tax_burden.length <= 1
                                ? "100%"
                                : `${sheetData.per_capita_tax_burden.length * 12}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.per_capita_income_ratio ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.per_capita_income_ratio
                                ? errors.per_capita_income_ratio
                                : ""
                            }
                            name="per_capita_income_ratio"
                            value={sheetData.per_capita_income_ratio}
                            onChange={handleChange}
                            style={{
                              width: sheetData.per_capita_income_ratio.length <= 3
                                ? "100%"
                                : `${sheetData.per_capita_income_ratio.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.total_earned_previous_year
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.total_earned_previous_year
                                ? errors.total_earned_previous_year
                                : ""
                            }
                            name="total_earned_previous_year"
                            value={sheetData.total_earned_previous_year}
                            onChange={handleChange}
                            style={{
                              width: sheetData.total_earned_previous_year.length <= 3
                                ? "100%"
                                : `${sheetData.total_earned_previous_year.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.more_or_less_previous_year
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder={
                              errors.more_or_less_previous_year
                                ? errors.more_or_less_previous_year
                                : ""
                            }
                            name="more_or_less_previous_year"
                            value={sheetData.more_or_less_previous_year}
                            onChange={handleChange}
                            style={{
                              width: sheetData.more_or_less_previous_year.length <= 3
                                ? "100%"
                                : `${sheetData.more_or_less_previous_year.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className={`form-control table-input-box ${
                              errors.reason_more_or_less ? "is-invalid" : ""
                            }`}
                            placeholder={
                              errors.reason_more_or_less
                                ? errors.reason_more_or_less
                                : ""
                            }
                            name="reason_more_or_less"
                            value={sheetData.reason_more_or_less}
                            onChange={handleChange}
                            style={{
                              width: sheetData.reason_more_or_less.length <= 3
                                ? "100%"
                                : `${sheetData.reason_more_or_less.length * 10}px`,
                              paddingLeft: "5px",
                              paddingRight: "0px",
                            }}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div className={style.form_btn_space}>
                <div className="col-lg-12 d-flex justify-content-end align-items=center">
                  <FormBtn
                    handleSubmit={handleSubmit}
                    resetForm={handleSubmit}
                    resetButton={true}
                    handleDraft={handleDraftSheetCD}
                  />
                </div>
                <div className="">
                  <div className={style.btn_position}>
                    {currentStep > 1 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handlePrevStep}
                      >
                        Previous
                      </Button>
                    )}

                    {currentStep < 10 && (
                      <Button
                        variant="contained"
                        className=""
                        // endIcon={<RestartAltRoundedIcon />}
                        style={{ background: "#49ab4e" }}
                        onClick={handleNextStep}
                      >
                        Next
                      </Button>
                    )}

                    {currentStep === 10 && (
                      <button
                        type="submit"
                        className={style.btn_generate}
                        // onClick={handlePrint}
                      >
                        Generate Pdf
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* {economicData === "N" && (
                  <div className="col-lg-12 mt-3">
                    <div className="d-flex justify-content-between">
                      <p className="text-danger">
                        सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला
                        विनंती करा.{" "}
                      </p>
                      <div>
                        <Button variant="contained">विनंती करा</Button>
                      </div>
                    </div>
                  </div>
                )} */}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-center"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default SheetC_D;
