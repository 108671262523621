/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
import "./EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import FormBtn from "../../component/buttons/Form/FormBtn";
import Village_Tal_Dist from "../../component/header-navigate/Village_Tal_Dist/Village_Tal_Dist";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import Loader from "../../component/Loader/Loader";
import { FaEdit } from "react-icons/fa";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineArrowDownward } from "react-icons/md";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterDevOfficer from "../../component/footer/VillageDevelopmentOfficer/FooterDevOfficer";

const EconomicsYear_A = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [economicData, setEconomicData] = useState("");
  const [data, setData] = useState([]);
  const [isSubmissionActive, setIsSubmissionActive] = useState(true);
  // const currentDate = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState("");
  const [srNo, setSrNo] = useState("");
  const [validated, setValidated] = useState(false);
  // मालमत्ता कर input filed calculation
  const [property1, setProperty1] = useState("");
  const [property2, setProperty2] = useState("");
  const [propSum, setPropSum] = useState("");
  // पडसर कर input filed calculation

  const [padSar1, setPadSar1] = useState("");
  const [padSar2, setPadSar2] = useState("");
  const [pdSarSum, setPadSarSum] = useState("");

  // दिवाबत्ती कर input filed calculation

  const [lamp1, setLamp1] = useState("");
  const [lamp2, setLamp2] = useState("");
  const [lampSum, setLampSum] = useState("");

  // आरोग्य कर input filed calculation

  const [health1, setHealth1] = useState("");
  const [health2, setHealth2] = useState("");
  const [healthSum, setHealthSum] = useState("");

  // मागील थकबाकी calculate

  const [pastDue, setPastDue] = useState("");

  // चालू मागणी calculate

  const [currentDemand, setCurrentDemand] = useState("");
  const [lastDate, setLastDate] = useState("");
  // एकूण मागणी calculate

  const [aggregateDemand, setAggregateDemand] = useState("");

  const isSpecificPage = true;

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  const currentDate = (inputDate) => {
    const parts = inputDate.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // मालमत्ता कर input filed calculation

  const totalTaxCalculation = () => {
    const property1Num = parseFloat(property1) || 0;
    const property2Num = parseFloat(property2) || 0;

    if (isNaN(property1Num) || isNaN(property2Num)) {
      setPropSum("");
    } else {
      const sum = property1Num + property2Num;
      setPropSum(Math.round(sum));
    }
    // const sum = parseInt(property1) + parseInt(property2);
    // setPropSum(sum);
  };

  useEffect(() => {
    totalTaxCalculation();
    totalPadSarTax();
    totalLampTax();
    calculateHealthTax();
    calculatePastDue();
    calculateCurrentDemand();
    calculateAggregateDemand();
  }, [
    property1,
    property2,
    padSar1,
    padSar2,
    lamp1,
    lamp2,
    health1,
    health2,
    propSum,
    pdSarSum,
    lampSum,
    healthSum,
  ]);

  // पडसर कर input filed calculation

  const totalPadSarTax = () => {
    const padSar1Num = parseFloat(padSar1) || 0;
    const padSar2Num = parseFloat(padSar2) || 0;

    if (isNaN(padSar1Num) || isNaN(padSar2Num)) {
      setPadSarSum("");
    } else {
      const calPadSar = padSar1Num + padSar2Num;
      setPadSarSum(Math.round(calPadSar));
    }
    // const calPadSar = parseInt(padSar1) + parseInt(padSar2);
    // setPadSarSum(calPadSar);
  };

  // दिवाबत्ती कर input filed calculation

  const totalLampTax = () => {
    const lamp1Num = parseFloat(lamp1) || 0;
    const lamp2Num = parseFloat(lamp2) || 0;

    if (isNaN(lamp1Num) || isNaN(lamp2Num)) {
      setLampSum("");
    } else {
      const calculateLam = lamp1Num + lamp2Num;
      setLampSum(Math.round(calculateLam));
    }
    // let calculateLam = parseInt(lamp1) + parseInt(lamp2);
    // setLampSum(calculateLam);
  };

  // आरोग्य कर input filed calculation

  const calculateHealthTax = () => {
    const health1Num = parseFloat(health1) || 0;
    const health2Num = parseFloat(health2) || 0;

    if (isNaN(health1Num) || isNaN(health2Num)) {
      setHealthSum("");
    } else {
      const calculateHealth = health1Num + health2Num;
      setHealthSum(Math.round(calculateHealth));
    }

    // let calculateHealth = parseInt(health1) + parseInt(health2);
    // setHealthSum(calculateHealth);
  };

  // मागील थकबाकी calculate

  const calculatePastDue = () => {
    const property1Num = parseFloat(property1) || 0;
    const padSar1Num = parseFloat(padSar1) || 0;
    const lamp1Num = parseFloat(lamp1) || 0;
    const health1Num = parseFloat(health1) || 0;

    if (
      isNaN(property1Num) ||
      isNaN(padSar1Num) ||
      isNaN(lamp1Num) ||
      isNaN(health1Num)
    ) {
      setPastDue("");
    } else {
      const calculateSumOfPastDue =
        property1Num + padSar1Num + lamp1Num + health1Num;
      setPastDue(Math.round(calculateSumOfPastDue));
    }

    // const calculateSumOfPastDue =
    //   parseInt(property1) +
    //   parseInt(padSar1) +
    //   parseInt(lamp1) +
    //   parseInt(health1);
    // setPastDue(calculateSumOfPastDue);
  };

  // चालू मागणी calculate

  const calculateCurrentDemand = () => {
    const property2Num = parseFloat(property2) || 0;
    const padSar2Num = parseFloat(padSar2) || 0;
    const lamp2Num = parseFloat(lamp2) || 0;
    const health2Num = parseFloat(health2) || 0;

    if (
      isNaN(property2Num) ||
      isNaN(padSar2Num) ||
      isNaN(lamp2Num) ||
      isNaN(health2Num)
    ) {
      setCurrentDemand("");
    } else {
      const calculateSumOfCurrentDemand =
        property2Num + padSar2Num + lamp2Num + health2Num;
      setCurrentDemand(Math.round(calculateSumOfCurrentDemand));
    }

    // const calculateSumOfCurrentDemand =
    //   parseInt(property2) +
    //   parseInt(padSar2) +
    //   parseInt(lamp2) +
    //   parseInt(health2);
    // setCurrentDemand(calculateSumOfCurrentDemand);
  };

  // एकूण मागणी calculate

  const calculateAggregateDemand = () => {
    const propSumNum = parseFloat(propSum) || 0;
    const pdSarSumNum = parseFloat(pdSarSum) || 0;
    const lampSumNum = parseFloat(lampSum) || 0;
    const healthSumNum = parseFloat(healthSum) || 0;

    if (
      isNaN(propSumNum) ||
      isNaN(pdSarSumNum) ||
      isNaN(lampSumNum) ||
      isNaN(healthSumNum)
    ) {
      setAggregateDemand("");
    } else {
      const calculateSumOfAggregateDemand =
        propSumNum + pdSarSumNum + lampSumNum + healthSumNum;
      setAggregateDemand(Math.round(calculateSumOfAggregateDemand));
    }

    // const calculateSumOfAggregateDemand =
    //   parseInt(propSum) +
    //   parseInt(pdSarSum) +
    //   parseInt(lampSum) +
    //   parseInt(healthSum);
    // setAggregateDemand(calculateSumOfAggregateDemand);
  };

  // hanleEntry submission data

  // आर्थिक वर्ष मागणी ( ग्रामपंचायत करमागणी) api

  const handleEconomicsYearDetails = async () => {
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/economic_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("EconomicsYearDetails response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      setData(response.data.data.economic_a_data);
      if (
        response.data.data.economic_a_data === null ||
        response.data.data.economic_a_data.length === 0
      ) {
        setEconomicData("");
      } else {
        setEconomicData(response.data.data.economic_a_data[0].is_draft);
      }
      setDate(response.data.data.economic_a_data[0].date);
      setSrNo(response.data.data.economic_a_data[0].sr_no);

      // updated date show in inputs

      setProperty1(response.data.data.economic_a_data[0].past_due);
      setProperty2(response.data.data.economic_a_data[0].current_demand);
      setPropSum(response.data.data.economic_a_data[0].total_demand);

      setPadSar1(response.data.data.economic_a_data[1].past_due);
      setPadSar2(response.data.data.economic_a_data[1].current_demand);
      setPadSarSum(response.data.data.economic_a_data[1].total_demand);

      setLamp1(response.data.data.economic_a_data[2].past_due);
      setLamp2(response.data.data.economic_a_data[2].current_demand);
      setLampSum(response.data.data.economic_a_data[2].total_demand);

      setHealth1(response.data.data.economic_a_data[3].past_due);
      setHealth2(response.data.data.economic_a_data[3].current_demand);
      setHealthSum(response.data.data.economic_a_data[3].total_demand);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleEconomicsYearDetails();
  }, []);

  useEffect(() => {
    const handleEntrySubmissionDate = async () => {
      try {
        const response = await axios.post(
          API_BASE_URL + "api/entry_submission_date_range",
          {},
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("date data", response.data.data);
        if (response.data.data !== null) {
          const date1 = new Date(response.data.data.start_date); // Replace with your first date
          const date2 = new Date(response.data.data.last_date); // Replace with your second date
          const currentDate = new Date();
          setLastDate(date2);
          if (currentDate >= date1 && currentDate <= date2) {
            console.log("Submission is Active");
            setIsSubmissionActive(true);
          } else {
            console.log("Submission date expired");
            setIsSubmissionActive(false);
          }
        }
      } catch (error) {
        console.log("error occured fetching data", error);
      }
    };
    handleEntrySubmissionDate();
  }, []);

  // Reset the form fields or perform any other necessary actions
  const handleDataRefreshReset = async () => {
    if (data.length > 0) {
      await handleEconomicsYearDetails();
    } else {
      setProperty1("");
      setProperty2("");
      setPadSar1("");
      setPadSar2("");
      setLamp1("");
      setLamp2("");
      setHealth1("");
      setHealth2("");
      setSrNo("");
      setDate("");
      setValidated(false);
    }
  };

  // reload the data on the server
  const handleDataRefresh = async () => {
    await handleEconomicsYearDetails();
  };

  // Refs for input elements
  const property1Ref = useRef(null);
  const property2Ref = useRef(null);
  const padSar1Ref = useRef(null);
  const padSar2Ref = useRef(null);
  const lamp1Ref = useRef(null);
  const lamp2Ref = useRef(null);
  const health1Ref = useRef(null);
  const health2Ref = useRef(null);
  const srNoRef = useRef(null);
  // ... (similar refs for other inputs)

  useEffect(() => {
    // Set focus on the first input with an error
    if (validated) {
      if (!isNumeric(property1)) {
        property1Ref.current.focus();
      } else if (!isNumeric(property2)) {
        property2Ref.current.focus();
      } else if (!isNumeric(padSar1)) {
        padSar1Ref.current.focus();
      } else if (!isNumeric(padSar2)) {
        padSar2Ref.current.focus();
      } else if (!isNumeric(lamp1)) {
        lamp1Ref.current.focus();
      } else if (!isNumeric(lamp2)) {
        lamp2Ref.current.focus();
      } else if (!isNumeric(health1)) {
        health1Ref.current.focus();
      } else if (!isNumeric(health2)) {
        health2Ref.current.focus();
      } else if (!isNumeric(srNo)) {
        srNoRef.current.focus();
      }
      // ... (add similar conditions for other inputs)
    }
  }, [validated]);

  // Validation functions
  //  allow decimal for these variable
  const isNumeric = (value) => {
    return /^-?\d*\.?\d+$/.test(value);
  };

  // without decimal
  // const isNumeric = (value) => {
  //   return /^-?\d*\.?\d*$/.test(value);
  // };

  const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;

  const formatDateString = (inputDate) => {
    const [day = "", month = "", year = ""] = inputDate.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const formatted = formatDateString(date);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional smooth scrolling
    });
  };
  const formattedDate = formatDateString(date);
  const validateForm = () => {
    // Validation logic based on your requirements
    if (
      !isNumeric(property1) ||
      !isNumeric(property2) ||
      !isNumeric(padSar1) ||
      !isNumeric(padSar2) ||
      !isNumeric(lamp1) ||
      !isNumeric(lamp2) ||
      !isNumeric(health1) ||
      !isNumeric(health2) ||
      !isNumeric(srNo) ||
      !dateRegex.test(formattedDate)
    ){
      setValidated(true);
      // Additional actions if needed
      // ...
      return true; // Validation failed
    }
    return false; // Validation passed
  };
  

  const handleFormSubmission = async (e) => {
    e.preventDefault();
  
    const isValid = validateForm();
    if (isValid) {
      if(validated){
        await handleUpdateEconomicsYear(e);
      }
    }else{
      handleShow();
    }
  }

  // आर्थिक वर्ष मागणी ( ग्रामपंचायत करमागणी) Update Information api
  const handleUpdateEconomicsYear = async (e) => {
    e.preventDefault();
    // const confirm = window.confirm("Are you sure want to submit these form. ");
    const isValid = validateForm();
    if (isValid) {
      setValidated(true);
      scrollToTop();
      return;
    }
   
    handleClose();
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        date: date,
        sr_no: srNo,
        economic_a_data: [
          {
            type: "Property Tax",
            past_due: property1,
            current_demand: property2,
            toal_demand: propSum,
          },
          {
            type: "Neighbor Tax",
            past_due: padSar1,
            current_demand: padSar2,
            toal_demand: pdSarSum,
          },
          {
            type: "Lamp Tax",
            past_due: lamp1,
            current_demand: lamp2,
            toal_demand: lampSum,
          },
          {
            type: "Health Tax",
            past_due: health1,
            current_demand: health2,
            toal_demand: healthSum,
          },
        ],
      };
      console.log("update finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/update_economic_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("UpdateEconomicsYearDetails response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false); // Reset validation state
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  // आर्थिक वर्ष मागणी ( ग्रामपंचायत करमागणी) Draft Information api

  const handleDraftEconomicsYear = async (e) => {
    const formattedDate = formatDateString(date);
    e.preventDefault();

    // Check for invalid formats
    const invalidFormats =
      (!isNumeric(property1) && property1 !== "") ||
      (!isNumeric(property2) && property2 !== "") ||
      (!isNumeric(padSar1) && padSar1 !== "") ||
      (!isNumeric(padSar2) && padSar2 !== "") ||
      (!isNumeric(lamp1) && lamp1 !== "") ||
      (!isNumeric(lamp2) && lamp2 !== "") ||
      (!isNumeric(health1) && health1 !== "") ||
      (!isNumeric(health2) && health2 !== "") ||
      (!isNumeric(srNo) && srNo !== "") ||
      (!dateRegex.test(formattedDate) && date !== "");

    if (invalidFormats) {
      setValidated(true);
      return;
    }
    try {
      const finalData = {
        year_id: userDetails.current_year_id,
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        date: date,
        sr_no: srNo,
        economic_a_data: [
          {
            type: "Property Tax",
            past_due: property1,
            current_demand: property2,
            toal_demand: propSum,
          },
          {
            type: "Neighbor Tax",
            past_due: padSar1,
            current_demand: padSar2,
            toal_demand: pdSarSum,
          },
          {
            type: "Lamp Tax",
            past_due: lamp1,
            current_demand: lamp2,
            toal_demand: lampSum,
          },
          {
            type: "Health Tax",
            past_due: health1,
            current_demand: health2,
            toal_demand: healthSum,
          },
        ],
      };
      console.log("Draft finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/draft_economic_a",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // setIsLoading(false);
      console.log("DraftEconomicsYear response", response.data.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setValidated(false); // Reset validation state
        setTimeout(() => {
          handleDataRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.error);
    }
  };

  const inputWidth = {
    width: `${(srNo?.length + 1 || 5) * 12}px`, // Adjust the multiplier based on your needs
  };

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>
              ग्रामपंचायत करमागणी मालमत्ता कर, पडसर कर, दिवाबत्ती कर व आरोग्य कर
            </title>
            {/* Conditionally set the viewport scale to 0.5 */}
            {isSpecificPage && (
              <meta
                name="viewport"
                content="width=device-width, initial-scale=0.5, maximum-scale=1.0"
              />
            )}
          </Helmet>
          <div className="container common-section pt-0">
            <div className="row">
              <div className="col-lg-12 navigate-section-header mt-3">
                <div className="page-title">
                  <p className="fw-bold mb-2">१.१ आर्थिक वर्ष मागणी</p>
                </div>
                <div className="navigate-section d-flex">
                  <div className="arrow-box-active d-flex flex-column justify-content-center">
                    <Link to="/eco-a" className="text-decoration-none">
                      अ) ग्रामपंचायत करमागणी ( घरपट्टी )
                    </Link>
                    <span className="text-center arrow-icon">
                      <ArrowDropDownIcon fontSize="large" color="primary" />
                    </span>
                  </div>
                  <div className="arrow-box">
                    <Link to="/eco-b" className="ms-5 text-dark">
                      ब) पाणीपट्टी
                    </Link>
                  </div>
                  <div className="arrow-box">
                    <Link to="/eco-c" className="ms-5 text-dark">
                      क) किरकोळ मागणी
                    </Link>
                  </div>
                  <div className="arrow-box">
                    <Link to="/eco-d" className="ms-5 text-dark">
                      ड) पवनचक्की / कारखाना मागणी
                    </Link>
                  </div>
                  <div className="arrow-box me-3">
                    <Link to="/eco-e" className="ms-5 text-dark">
                      इ) खर्च तरतूद
                    </Link>
                  </div>
                </div>
                {/* <TabEco/> */}
              </div>
              <div className="col-lg-12 mt-4 mb-4">
                <Village_Tal_Dist
                  villageDataInfo={villageDataInfo}
                  yearInfo={yearInfo}
                />
              </div>
              <div className="col-lg-12">
                <div className="table-main-sec">
                  <div className="table_title_top">
                    <p>
                      अ) ग्रामपंचायत करमागणी (मालमत्ता कर, पडसर कर,
                      दिवाबत्ती कर व आरोग्य कर)
                    </p>

                    {economicData === "Y" ? (
                      <span className="drafted_text">
                        <FaEdit /> Drafted
                      </span>
                    ) : economicData === "N" ? (
                      <span className="submit_text">
                        Submitted{" "}
                        <img
                          src={require("../../component/assets/images/sumbit-success.png")}
                          alt="success"
                          width={30}
                          className="img-fluid"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="table-responsive">
                    <form action="">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">कराचे नाव</th>
                            <th scope="col">
                              मागील थकबाकी <br /> (सन{" "}
                              {yearInfo ? yearInfo.previous_year_name : ""})
                            </th>
                            <th scope="col">
                              चालू मागणी <br /> (सन{" "}
                              {yearInfo ? yearInfo.current_year_name : ""})
                            </th>
                            <th scope="col">
                              एकूण मागणी <br />
                              (मागील + चालू) (सन{" "}
                              {yearInfo ? yearInfo.current_year_name : ""})
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>मालमत्ता कर</td>
                            <td>
                              <input
                                type="text"
                                value={property1}
                                onChange={(e) => setProperty1(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(property1)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(property1)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={property1Ref}
                                autoFocus
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={property2}
                                onChange={(e) => setProperty2(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(property2)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(property2)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={property2Ref}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={propSum}
                                readOnly
                                className="form-control table-input-box fw-bold"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>पडसर कर</td>
                            <td>
                              <input
                                type="text"
                                value={padSar1}
                                onChange={(e) => setPadSar1(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(padSar1)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(padSar1)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={padSar1Ref}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={padSar2}
                                onChange={(e) => setPadSar2(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(padSar2)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(padSar2)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={padSar2Ref}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={pdSarSum}
                                readOnly
                                className="form-control table-input-box fw-bold"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>दिवाबत्ती कर</td>
                            <td>
                              <input
                                type="text"
                                value={lamp1}
                                onChange={(e) => setLamp1(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(lamp1)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(lamp1)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={lamp1Ref}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={lamp2}
                                onChange={(e) => setLamp2(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(lamp2)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(lamp2)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={lamp2Ref}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={lampSum}
                                readOnly
                                className="form-control table-input-box fw-bold"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>आरोग्य कर</td>
                            <td>
                              <input
                                type="text"
                                value={health1}
                                onChange={(e) => setHealth1(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(health1)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(health1)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={health1Ref}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={health2}
                                onChange={(e) => setHealth2(e.target.value)}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", "*"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                placeholder={
                                  validated && !isNumeric(health2)
                                    ? "कृपया वैध क्रमांक प्रविष्ट करा."
                                    : ""
                                }
                                className={`form-control table-input-box ${
                                  validated && !isNumeric(health2)
                                    ? "is-invalid"
                                    : ""
                                }`}
                                ref={health2Ref}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={healthSum}
                                readOnly
                                className="form-control table-input-box fw-bold"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>एकूण</th>
                            <td>
                              <input
                                type="text"
                                value={pastDue}
                                readOnly
                                className="form-control fw-bold table-input-box"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={currentDemand}
                                readOnly
                                className="form-control fw-bold table-input-box"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={aggregateDemand}
                                readOnly
                                className="form-control fw-bold table-input-box"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  <p className="mt-3 mb-5">
                    टीप: मालमत्ता कराच्या थकबाकीवरील ५% दंड यातच समाविष्ट करावा.
                  </p>
                  <p className="mt-3">
                    वरील प्रमाणे ग्रामपंचायतची करमागणी ग्रामपंचायतची दिनांक -{" "}
                    <input
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className={`date_input ${validated ? "" : "is-invalid"}`}
                      placeholder="dd-mm-yy"
                      max={getCurrentDate()}
                      required
                    />{" "}
                    रोजीच्या मासिक सभेत ठराव क्र.
                    <input
                      type="text"
                      value={srNo}
                      onChange={(e) => setSrNo(e.target.value)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "*"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      placeholder={
                        validated && !isNumeric(srNo) ? "क्रमांक टाका." : ""
                      }
                      className={`sr_no_input ${
                        validated && !isNumeric(srNo) ? "is-invalid" : ""
                      }`}
                      ref={srNoRef}
                      style={inputWidth}
                    />{" "}
                    ने कायम झाला.
                  </p>
                  {validated && !date ? (
                    <span className="text-danger">दिनांक निवडा.</span>
                  ) : validated && !dateRegex.test(formatted) ? (
                    <span className="text-danger">
                      अयोग्य दिनांक फॉर्मॅट (dd-mm-yyyy).
                    </span>
                  ) : (
                    ""
                  )}
                  {validated && !isNumeric(srNo) ? (
                    <span className="text-danger">ठराव क्रमांक टाका.</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <FooterDevOfficer villageDataInfo={villageDataInfo} />
              </div>
              {/* <div className="col-lg-12 d-flex justify-content-end align-items=center">
                {isSubmissionActive &&
                  (economicData === "" || economicData === "Y") && (
                    <FormBtn
                      handleSubmit={handleShow}
                      resetForm={handleDataRefreshReset}
                      handleDraft={handleDraftEconomicsYear}
                    />
                  )}
              </div>
              {economicData === "N" ? (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              ) : (
                !isSubmissionActive && (
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : तुमची शेवटची सबमिशन तारीख{" "}
                      {lastDate.toLocaleDateString("mr-IN", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      कालबाह्य झाली होती
                    </p>
                  </div>
                )
              )} */}
              {console.log("validated", validated)}
              <div className="col-lg-12 d-flex mt-4 justify-content-end align-items=center">
                {(economicData === "" || economicData === "Y") && (
                  <FormBtn
                    handleSubmit={handleFormSubmission}
                    resetForm={handleDataRefreshReset}
                    handleDraft={handleDraftEconomicsYear}
                    
                  />
                )}
              </div>
              {economicData === "N" && (
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-danger">
                      सूचना : कृपया काही बदल करायचा असेल तर बी. डी. ओ. ला विनंती
                      करा.{" "}
                    </p>
                    <div>
                      <Button variant="contained">विनंती करा</Button>
                    </div>
                  </div>
                </div>
              )}
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {" "}
                    सूचना <MdOutlineArrowDownward />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Submit केल्यानंतर तुम्ही बी.डी.ओ. च्या मंजुरीशिवाय फॉर्म Edit
                  करू शकत नाही. कृपया खात्री करा की तुम्ही फॉर्म Submit करू
                  इच्छिता?
                </Modal.Body>
                <Modal.Footer className="gap-3">
                  <Button className="modal-button-cancel" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className="modal-button-submit"
                    onClick={handleUpdateEconomicsYear}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <ToastContainer
            position={"top-center"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </>
      )}
    </>
  );
};

export default EconomicsYear_A;
