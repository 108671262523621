/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./Sidebar.css"; // Import the CSS file
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";

const Sidebar = ({ sidebarOpen, setSideBarOpen }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isJanOpen, setIsJanOpen] = useState(false);
  const [iconOpen, setIconOpen] = useState(false);
  const [janIcon, setJanIcon] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      console.log("Please Login First");
      navigate("/");
    }
  }, []);

  var userDataInfo = sessionStorage.getItem("info");
  var userDetails = JSON.parse(userDataInfo);
  var permission = userDetails.role_permission;
  var userInfo = userDetails.user_details;

  // navigation variables
  var nav_Samanya_Prashasan_Vibhag = "N";
  var nav_Varshik_Prashasan_Ahwal_Vibhag = "N";
  var nav_Virt_Aayog_Vibhag = "N";
  var nav_A_Jati = "N";
  var nav_Gharkul_Vibhag = "N";
  var nav_Swachh_Bharat_Abhiyan_Yojana = "N";
  var nav_Janam_Mrityu = "N";
  var nav_Jan_Suvidha = "N";
  var nav_Report = "N";

  if (permission) {
    for (var i = 0; i < permission.length; i++) {
      if (
        permission[i].name === "Samanya Prashasan Vibhag" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Samanya_Prashasan_Vibhag = "Y";
      } else if (
        permission[i].name === "Varshik Prashasan Ahwal Vibhag" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Varshik_Prashasan_Ahwal_Vibhag = "Y";
      } else if (
        permission[i].name === "Virt Aayog Vibhag" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Virt_Aayog_Vibhag = "Y";
      } else if (
        permission[i].name === "A Jati" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_A_Jati = "Y";
      } else if (
        permission[i].name === "Gharkul Vibhag" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Gharkul_Vibhag = "Y";
      } else if (
        permission[i].name === "Swachh Bharat Abhiyan Yojana" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Swachh_Bharat_Abhiyan_Yojana = "Y";
      } else if (
        permission[i].name === "Janam Mrityu" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Janam_Mrityu = "Y";
      } else if (
        permission[i].name === "Jan Suvidha" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Jan_Suvidha = "Y";
      } else if (
        permission[i].name === "Report" &&
        permission[i].navigation_permission === "Y"
      ) {
        nav_Report = "Y";
      }
    }
  }

  return (
    <div className={` ${sidebarOpen ? "side_bar_hide " : "sidebar"} `}>
      <ul className="nav flex-column">
        {/* मुखपृष्ट */}
        <li
          className="nav-item sidebar-item"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="मुखपृष्ट"
        >
          <Link className="nav-link active" to="/dashboard">
            मुखपृष्ट
          </Link>
        </li>
        {/* सामान्य प्रशासन विभाग */}
        {(() => {
          if (nav_Samanya_Prashasan_Vibhag == "Y") {
            return (
              <li className="nav-item">
                <button
                  className="nav-link arrow_btn_group text-start form-control"
                  onClick={() => setIsOpen(!isOpen)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="सामान्य प्रशासन विभाग"
                >
                  <span className="text_overflow_span">
                    सामान्य प्रशासन विभाग
                  </span>
                  <span className="ms-5" onClick={() => setIconOpen(!iconOpen)}>
                    {isOpen ? (
                      <MdKeyboardArrowRight />
                    ) : (
                      <MdKeyboardArrowDown />
                    )}
                  </span>
                </button>
                {isOpen && (
                  <ul className="sidebar_list pt-1 pb-1">
                    {userInfo.role_id === "1" ? (
                      <Link to="/eco-a">
                        <li className="pt-2 pb-2">आर्थिक वर्ष मागणी</li>
                      </Link>
                    ) : (
                      <Link to="/district_economics">
                        <li className="pt-2 pb-2">आर्थिक वर्ष मागणी</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                    {userInfo.role_id === "1" ? (
                      <Link to="/recovery-a">
                        <li className="pt-2 pb-2">वसूली विभाग</li>
                      </Link>
                    ) : (
                      <Link to="/recovery">
                        <li className="pt-2 pb-2">वसूली विभाग</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                    {userInfo.role_id === "1" ? (
                      <Link to="/expense-pro-a">
                        <li className="pt-2 pb-2">खर्च तरतूद</li>
                      </Link>
                    ) : (
                      <Link to="/expense">
                        <li className="pt-2 pb-2">खर्च तरतूद</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                    {userInfo.role_id === "1" ? (
                      <Link to="/details-of-expense">
                        <li className="pt-2 pb-2">खर्च तपशील</li>
                      </Link>
                    ) : (
                      <Link to="/expense_details">
                        <li className="pt-2 pb-2">खर्च तपशील</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                    {userInfo.role_id === "1" ? (
                      <Link to="/month-meeting">
                        <li className="pt-2 pb-2">मासिक सभा</li>
                      </Link>
                    ) : (
                      <Link to="/meeting">
                        <li className="pt-2 pb-2">मासिक सभा</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                    {userInfo.role_id === "1" ? (
                      <Link to="/praptra-a">
                        <li className="pt-2 pb-2">प्रपत्र</li>
                      </Link>
                    ) : (
                      <Link to="/praptra">
                        <li className="pt-2 pb-2">प्रपत्र</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                  </ul>
                )}
              </li>
            );
          }
          return null;
        })()}
        {/* वार्षिक प्रशासन अहवाल विभाग  */}
        {(() => {
          if (nav_Varshik_Prashasan_Ahwal_Vibhag == "Y") {
            return (
              <li className="nav-item sidebar-item">
                <Link
                  className="nav-link"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="वार्षिक प्रशासन अहवाल विभाग"
                  to="/pdf"
                >
                  वार्षिक प्रशासन अहवाल विभाग
                </Link>
              </li>
            );
          }
          return null;
        })()}
        {/*  १५ वित्त अयोग विभाग */}
        {(() => {
          if (nav_Virt_Aayog_Vibhag == "Y") {
            return (
              <li
                className="nav-item sidebar-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="१५ वित्त अयोग विभाग"
              >
                {userInfo.role_id === "1" ? (
                  <Link className="nav-link" to="/vith_ayog">
                    १५ वित्त अयोग विभाग
                  </Link>
                ) : (
                  <Link className="nav-link" to="/d-vith_ayog">
                    १५ वित्त अयोग विभाग
                  </Link>
                )}
              </li>
            );
          }
          return null;
        })()}
        {/*  अ.जाती व नवबौद्ध घटकांचा विकास योजना */}
        {(() => {
          if (nav_A_Jati == "Y") {
            return (
              <li
                className="nav-item sidebar-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="अ.जाती व नवबौद्ध घटकांचा विकास योजना"
              >
                {userInfo.role_id === "1" ? (
                  <Link className="nav-link" to="/anusuchit_jati">
                    अ.जाती व नवबौद्ध घटकांचा विकास योजना
                  </Link>
                ) : (
                  <Link className="nav-link" to="/d_anusuchit_jati">
                    अ.जाती व नवबौद्ध घटकांचा विकास योजना
                  </Link>
                )}
              </li>
            );
          }
          return null;
        })()}
        {/* घरकुल विभाग */}
        {(() => {
          if (nav_Gharkul_Vibhag == "Y") {
            return (
              <li
                className="nav-item sidebar-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="घरकुल विभाग"
              >
                {userInfo.role_id === "1" ? (
                  <Link className="nav-link" to="/gharkul">
                    घरकुल विभाग
                  </Link>
                ) : userInfo.role_id === "5" ? (
                  <Link className="nav-link" to="/vistar_gharkul">
                  घरकुल विभाग
                </Link>
                ) : (
                  <Link className="nav-link" to="/d-gharkul">
                    घरकुल विभाग
                  </Link>
                )}
              </li>
            );
          }
          return null;
        })()}
        {/* स्वछ भारत आभियान विभाग */}
        {(() => {
          if (nav_Swachh_Bharat_Abhiyan_Yojana == "Y") {
            return (
              <li
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="स्वछ भारत आभियान विभाग"
                className="nav-item sidebar-item"
              >
                 {userInfo.role_id === "1" ? (
                  <Link className="nav-link" to="/swach_bharat">
                  स्वछ भारत आभियान विभाग
                </Link>
                ) : userInfo.role_id === "22" ? (
                  <Link className="nav-link" to="/vistar_swach_bharat">
                  स्वछ भारत आभियान विभाग
                </Link>
                ) : (
                  <Link className="nav-link" to="/d_swach_bharat">
                    स्वछ भारत आभियान विभाग
                  </Link>
                )}
                
              </li>
            );
          }
          return null;
        })()}
        {/* जन्म मृत्यू उपजत मृत्यू नोंदी अहवाल विभाग  */}
        {(() => {
          if (nav_Janam_Mrityu == "Y") {
            return (
              <li
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="जन्म मृत्यू उपजत मृत्यू नोंदी अहवाल विभाग"
                className="nav-item sidebar-item"
              >
                {userInfo.role_id === "1" ? (
                  <Link className="nav-link" to="/janm_mrutu">
                    जन्म मृत्यू उपजत मृत्यू नोंदी अहवाल विभाग
                  </Link>
                ) : (
                  <Link className="nav-link" to="/birth_death_report">
                    जन्म मृत्यू उपजत मृत्यू नोंदी अहवाल विभाग
                  </Link>
                )}
              </li>
            );
          }
          return null;
        })()}
        {/* जनसुविधा, नागरीसुविधा, तिर्थक्षेत्र विकास विभाग */}
        {(() => {
          if (nav_Jan_Suvidha === "Y") {
            return (
              <li className="nav-item">
                <button
                  className="nav-link arrow_btn_group form-control text-start"
                  onClick={() => setIsJanOpen(!isJanOpen)}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="जनसुविधा, नागरीसुविधा, तिर्थक्षेत्र विकास विभाग"
                >
                  <span className="text_overflow_span me-3">
                    जनसुविधा, नागरीसुविधा, तिर्थक्षेत्र विकास विभाग
                  </span>

                  <span onClick={() => setJanIcon(!janIcon)}>
                    {" "}
                    {isJanOpen ? (
                      <MdKeyboardArrowRight />
                    ) : (
                      <MdKeyboardArrowDown />
                    )}
                  </span>
                </button>
                {isJanOpen && (
                  <ul className="sidebar_list pt-1 pb-1">
                    {userInfo.role_id === "1" ? (
                      <Link to="/jansuvidha">
                        <li className="pt-2 pb-2">जनसुविधा</li>
                      </Link>
                    ) : (
                      <Link to="/d-jansuvidha">
                        <li className="pt-2 pb-2">जनसुविधा</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                    {userInfo.role_id === "1" ? (
                      <Link to="/nagrisuvidha">
                        <li className="pt-2 pb-2">नागरीसुविधा</li>
                      </Link>
                    ) : (
                      <Link to="/d-nagrisuvidha">
                        <li className="pt-2 pb-2">नागरीसुविधा</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                    {userInfo.role_id === "1" ? (
                      <Link to="/tirthshetra">
                        <li className="pt-2 pb-2">तिर्थक्षेत्र</li>
                      </Link>
                    ) : (
                      <Link to="/d-tirthshetra">
                        <li className="pt-2 pb-2">तिर्थक्षेत्र</li>
                      </Link>
                    )}

                    <hr className="sidebar-hr" />
                  </ul>
                )}
              </li>
            );
          }
          return null;
        })()}
        {/* रिपोर्ट */}
        {(() => {
          if (nav_Report == "Y") {
            return (
              <li
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="रिपोर्ट"
                className="nav-item sidebar-item"
              >
                <Link className="nav-link" to="/mpr_report">
                  रिपोर्ट
                </Link>
              </li>
            );
          }
          return null;
        })()}
        {/* माहिती मागवणे */}
        <li
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="माहिती मागवणे"
          className="nav-item sidebar-item"
        >
          <Link className="nav-link" to="/">
            माहिती मागवणे
          </Link>
        </li>
        {userInfo.role_id !== "1" && (
          <>
          <li
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="नोटिस पाठवणे"
          className="nav-item sidebar-item"
        >
          <Link className="nav-link" to="/add_notice">
          नोटिस पाठवणे
          </Link>
        </li>
        <li
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="नोटिफिकेशन पाठवणे"
          className="nav-item sidebar-item"
        >
          <Link className="nav-link" to="/add_notification">
          नोटिफिकेशन पाठवणे
          </Link>
        </li>
          </>
        )}
        
        <li
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="सेटिंग्ज"
          className="nav-item sidebar-item"
        >
          <Link className="nav-link" to="/settings">
            सेटिंग्ज
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
