/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
// import "../GeneralAdministrationDepartment/EconomicsYear.css";
import { Helmet } from "react-helmet-async";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import "react-toastify/dist/ReactToastify.css";
import SheetB from "./SheetB";
import { useRef } from "react";
import SheetC_D from "./SheetC_D";
import SheetE from "./SheetE";
import SheetF from "./SheetF";
import SheetG from "./SheetG";
import SheetA from "./SheetA";
import GramNidhiSampleNoFour from "./samplePages/GramNidhiSampleNoFour";
import WaterSupplySampleNoFour from "./samplePages/WaterSupplySampleNoFour";
import style from "./pdf.module.css";
import FormBtn from "../../component/buttons/Form/FormBtn";
import { Button } from "@mui/material";
import GramNidhiSampleNoThree from "./samplePages/GramNidhiSampleNoThree";
import WaterSupplySampleNoThree from "./samplePages/WaterSupplySampleNoThree";
import StepProgressBar from "./StepProgressBar";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
const Pdf = () => {
  const isSpecificPage = true;
  const [stepStatus, setStepStatus] = useState({});
  const [pdf, setPdf] = useState("")

  const [currentStep, setCurrentStep] = useState(1);
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  const customStepNames = [
    "पत्रक- अ (ए)",
    "पत्रक- 'ब' (बी)",
    "पत्रक- 'सी' आणि 'डी'",
    "पत्रक- 'ई'",
    "पत्रक- 'एफ'",
    "पत्रक- 'जी'",
    "नमुना नंबर - ३\n ग्रामनिधी जमा बाजु",
    "नमुना नंबर - ४\n ग्रामनिधी खर्च बाजु",
    "नमुना नंबर - ३\n पाणीपुरवठा जमा बाजु",
    "नमुना नंबर - ४\n पाणीपुरवठा खर्च बाजु",
  ];

  const totalSteps = 10;

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const yourFormsObject = {
    form_a: "form_a",
    form_b: "form_b",
    form_c_d: "form_c_d",
    form_e: "form_e",
    form_f: "form_f",
    form_g: "form_g",
    form_h: "form_h",
    form_i: "form_i",
    form_j: "form_j",
    form_k: "form_k",
  };

  // response of all step  wise components based on the current step number

  const handleStepDetails = async () => {
    const {
      form_a,
      form_b,
      form_c_d,
      form_e,
      form_f,
      form_g,
      form_h,
      form_i,
      form_j,
      form_k,
    } = yourFormsObject;
    try {
      const finalData = {
        gram_sevek_id: userDetails.id,
        village_id: userDetails.village_id,
        taluka_id: userDetails.taluka_id,
        district_id: userDetails.district_id,
        year_id: userDetails.current_year_id,
        current_form:
          currentStep === 1
            ? form_a
            : currentStep === 2
            ? form_b
            : currentStep === 3
            ? form_c_d
            : currentStep === 4
            ? form_e
            : currentStep === 5
            ? form_f
            : currentStep === 6
            ? form_g
            : currentStep === 7
            ? form_h
            : currentStep === 8
            ? form_i
            : currentStep === 9
            ? form_j
            : currentStep === 10
            ? form_k
            : "",
      };
      console.log("form_a:", form_a);
      console.log("finalData step", finalData);
      const response = await axios.post(
        API_BASE_URL + "varshikahwal/all_form_status",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        console.log("step wise status", response.data);
        setStepStatus(response.data.data);
      }
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  
  useEffect(() => {
    handleStepDetails();
  }, [currentStep]);

  // generate pdf
  useEffect(()=>{
    const handlePdfDetails = async () => {
    
      try {
        const finalData = {
          gram_sevek_id: userDetails.id,
          village_id: userDetails.village_id,
          taluka_id: userDetails.taluka_id,
          district_id: userDetails.district_id,
          year_id: userDetails.current_year_id
        };
        
        console.log("finalData pdf", finalData);
        const response = await axios.post(
          API_BASE_URL + "varshikahwalpdf",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status === true) {
          console.log("pdf details", response.data.data);
          setPdf(response.data.data);
        }
      } catch (error) {
        console.log("error occured fetching data", error);
      }
    };
    handlePdfDetails();
  },[])
  return (
    <div id="invoice-content">
      <Helmet>
        {isSpecificPage && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=0.5"
          />
        )}
        <title>ग्रामपंचायत रचना दर्शविणारे</title>
      </Helmet>

      <StepProgressBar
        currentStep={currentStep}
        totalSteps={totalSteps}
        onStepClick={handleStepClick}
        stepNames={customStepNames}
        stepStatus={stepStatus}
      />
      {currentStep === 1 && (
        <SheetA
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
          handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 2 && (
        <SheetB
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
           handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 3 && (
        <SheetC_D
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
           handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 4 && (
        <SheetE
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
           handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 5 && (
        <SheetF
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
           handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 6 && (
        <SheetG
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
           handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 7 && (
        <GramNidhiSampleNoThree
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
           handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 8 && (
        <GramNidhiSampleNoFour
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
           handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 9 && (
        <WaterSupplySampleNoThree
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
          handleStepDetails={handleStepDetails}
        />
      )}
      {currentStep === 10 && (
        <WaterSupplySampleNoFour
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          currentStep={currentStep}
          handleStepDetails={handleStepDetails}
          pdf={pdf}
        />
      )}

      {/* <div className="" style={{marginBottom:"30px"}}>
        <div className={style.btn_position}>
          {currentStep > 1 && (
             <Button
             variant="contained"
             className=""
             // endIcon={<RestartAltRoundedIcon />}
             style={{ background: "#49ab4e" }}
             onClick={handlePrevStep}
           >
             Previous
           </Button>
          )}
         
            {currentStep < 8 && (
              <Button
                variant="contained"
                className=""
                // endIcon={<RestartAltRoundedIcon />}
                style={{ background: "#49ab4e" }}
                onClick={handleNextStep}
              >
                Next 
              </Button>
            )}
          
          {currentStep === 8 && (
            <button
              type="submit"
              className={style.btn_generate}
              onClick={handlePrint}
            >
              Generate Pdf
            </button>
          )}
        </div>
      </div> */}

      {/* <div className="pb-5">
        <button type="submit" className="btn_generate" onClick={handlePrint}>
          Generate Pdf
        </button>
      </div> */}
    </div>
  );
};

export default Pdf;
