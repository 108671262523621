/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../RecoveryReport.css";
import { Helmet } from "react-helmet-async";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../../Constant/Global";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import style from "../../GeneralAdministrationDepartment/districtEconomics/style.module.css";
const RecoveryReport_A = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [villageDataInfo, setVillageDataInfo] = useState("");
  const [yearInfo, setYearInfo] = useState("");
  const [tal, setTal] = useState([]);
  const currentMonth = new Date().getMonth() + 1;
  const [month, setMonth] = useState(currentMonth.toString());
  const [talId, setTalId] = useState("");
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState("");
  const [totalsLoaded, setTotalsLoaded] = useState(false);

  // get data in session storage
  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;

  //   recovery a data

  const [recoveryAEcoAData, setRecoveryAEcoAData] = useState("");
  const [recoveryALastMonthTotal, setRecoveryALastMonthTotal] = useState("");
  const [recoveryARecoveryAData, setRecoveryARecoveryAData] = useState("");

  //   recovery b data

  const [recoveryBEcoBData, setRecoveryBEcoBData] = useState("");
  const [recoveryBLastMonthTotal, setRecoveryBLastMonthTotal] = useState("");
  const [recoveryBRecoveryBData, setRecoveryBRecoveryBData] = useState("");

  //   recovery c data

  const [recoveryCEcoCData, setRecoveryCEcoCData] = useState("");
  const [recoveryCLastMonthTotal, setRecoveryCLastMonthTotal] = useState("");
  const [recoveryCRecoveryCData, setRecoveryCRecoveryCData] = useState("");

  //   recovery d data

  const [recoveryDEcoDData, setRecoveryDEcoDData] = useState("");
  const [recoveryDLastMonthTotal, setRecoveryDLastMonthTotal] = useState("");
  const [recoveryDRecoveryDData, setRecoveryDRecoveryDData] = useState("");

  useEffect(() => {
    // Check if all required data is available before setting totalsLoaded to true
    if (
      recoveryAEcoAData &&
      recoveryALastMonthTotal &&
      recoveryARecoveryAData &&
      recoveryBEcoBData &&
      recoveryBLastMonthTotal &&
      recoveryBRecoveryBData &&
      recoveryCEcoCData &&
      recoveryCLastMonthTotal &&
      recoveryCRecoveryCData &&
      recoveryDEcoDData &&
      recoveryDLastMonthTotal &&
      recoveryDRecoveryDData
    ) {
      setTotalsLoaded(true);
    }
  }, [
    recoveryAEcoAData,
    recoveryALastMonthTotal,
    recoveryARecoveryAData,
    recoveryBEcoBData,
    recoveryBLastMonthTotal,
    recoveryBRecoveryBData,
    recoveryCEcoCData,
    recoveryCLastMonthTotal,
    recoveryCRecoveryCData,
    recoveryDEcoDData,
    recoveryDLastMonthTotal,
    recoveryDRecoveryDData,
  ]);

  useEffect(() => {
    handleRecoveryReportDetails();
  }, [talId, villageId, month]);

  //  वसुली विभाग (घरपट्टी वसुली) Get Information api

  const handleRecoveryReportDetails = async () => {
    try {
      const finalData = {
        village_id: villageId,
        taluka_id: talId,
        district_id: userDetails.district_id,
        month: month.toString(),
        year_id: userDetails.current_year_id,
      };
      console.log("Recovery Report finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/summary_recovery",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      setIsLoading(false);
      console.log("Summary RecoveryReportDetails response", response.data.data);
      setVillageDataInfo(response.data.data.other_data);
      setYearInfo(response.data.data.year_info);
      // setEconomicData(response.data.data.recovery_a_data[0].is_draft);

      // recovery a data
      setRecoveryAEcoAData(response.data.data.recovery_a.economic_a_data);
      setRecoveryALastMonthTotal(
        response.data.data.recovery_a.last_month_total
      );
      setRecoveryARecoveryAData(response.data.data.recovery_a.recovery_a_data);

      // recovery b data
      setRecoveryBEcoBData(response.data.data.recovery_b.economic_b_data);
      setRecoveryBLastMonthTotal(
        response.data.data.recovery_b.last_month_total
      );
      setRecoveryBRecoveryBData(response.data.data.recovery_b.recovery_b_data);

      // recovery c data
      setRecoveryCEcoCData(response.data.data.recovery_c.economic_c_data);
      setRecoveryCLastMonthTotal(
        response.data.data.recovery_c.last_month_total
      );
      setRecoveryCRecoveryCData(response.data.data.recovery_c.recovery_c_data);

      // recovery d data
      setRecoveryDEcoDData(response.data.data.recovery_d.economic_d_data);
      setRecoveryDLastMonthTotal(
        response.data.data.recovery_d.last_month_total
      );
      setRecoveryDRecoveryDData(response.data.data.recovery_d.recovery_d_data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // recovery a  total past due

  var TotalDueRecoveryA = 0;
  if (recoveryAEcoAData) {
    recoveryAEcoAData.forEach((data) => {
      TotalDueRecoveryA = TotalDueRecoveryA + parseFloat(data.past_due);
    });
  }

  // recovery a  total current demand

  var TotalCurrentDemandRecoveryA = 0;
  if (recoveryAEcoAData) {
    recoveryAEcoAData.forEach((data) => {
      TotalCurrentDemandRecoveryA =
        TotalCurrentDemandRecoveryA + parseFloat(data.current_demand);
    });
  }

  // recovery a  total demand

  var TotalDemandRecoveryA = 0;
  if (recoveryAEcoAData) {
    recoveryAEcoAData.forEach((data) => {
      TotalDemandRecoveryA =
        TotalDemandRecoveryA + parseFloat(data.total_demand);
    });
  }

  // recovery a last month recovery

  var TotalLastMonthDemandRecoveryA = 0;
  if (recoveryALastMonthTotal) {
    recoveryALastMonthTotal.forEach((data) => {
      TotalLastMonthDemandRecoveryA =
        TotalLastMonthDemandRecoveryA + parseFloat(data.last_month_recovery);
    });
  }

  // recovery a  total current month recovery

  var TotalCurrentMonthDemandRecoveryA = 0;
  if (recoveryARecoveryAData) {
    recoveryARecoveryAData.forEach((data) => {
      TotalCurrentMonthDemandRecoveryA =
        TotalCurrentMonthDemandRecoveryA +
        parseFloat(data.total_current_month_recovery);
    });
  }

  // recovery a total recovery

  var TotalRecoveryRecoveryA = 0;
  if (recoveryARecoveryAData) {
    recoveryARecoveryAData.forEach((data) => {
      TotalRecoveryRecoveryA =
        TotalRecoveryRecoveryA + parseFloat(data.total_recovery);
    });
  }

  // recovery a  total percentage

  const TotalPercentageRecoveryA =
    (parseFloat(TotalRecoveryRecoveryA || 0) /
      parseFloat(TotalDemandRecoveryA || 0)) *
    100;

  // recovery b  total past due

  var TotalDueRecoveryB = 0;
  if (recoveryBEcoBData) {
    recoveryBEcoBData.forEach((data) => {
      TotalDueRecoveryB = TotalDueRecoveryB + parseFloat(data.past_due);
    });
  }

  // recovery b  total current demand

  var TotalCurrentDemandRecoveryB = 0;
  if (recoveryBEcoBData) {
    recoveryBEcoBData.forEach((data) => {
      TotalCurrentDemandRecoveryB =
        TotalCurrentDemandRecoveryB + parseFloat(data.current_demand);
    });
  }

  // recovery b total demand

  var TotalDemandRecoveryB = 0;
  if (recoveryBEcoBData) {
    recoveryBEcoBData.forEach((data) => {
      TotalDemandRecoveryB =
        TotalDemandRecoveryB + parseFloat(data.total_demand);
    });
  }

  // recovery b  last month recovery

  var TotalLastMonthDemandRecoveryB = 0;
  if (recoveryBLastMonthTotal) {
    recoveryBLastMonthTotal.forEach((data) => {
      TotalLastMonthDemandRecoveryB =
        TotalLastMonthDemandRecoveryB + parseFloat(data.last_month_recovery);
    });
  }

  // recovery b total current month recovery

  var TotalCurrentMonthDemandRecoveryB = 0;
  if (recoveryBRecoveryBData) {
    recoveryBRecoveryBData.forEach((data) => {
      TotalCurrentMonthDemandRecoveryB =
        TotalCurrentMonthDemandRecoveryB +
        parseFloat(data.total_current_month_recovery);
    });
  }

  // recovery b total recovery

  var TotalRecoveryRecoveryB = 0;
  if (recoveryBRecoveryBData) {
    recoveryBRecoveryBData.forEach((data) => {
      TotalRecoveryRecoveryB =
        TotalRecoveryRecoveryB + parseFloat(data.total_recovery);
    });
  }

  // recovery b total percentage

  const TotalPercentageRecoveryB =
    (parseFloat(TotalRecoveryRecoveryB || 0) /
      parseFloat(TotalDemandRecoveryB || 0)) *
    100;

  const handleOnchangeTaluka = (e) => {
    const selectedTalukaId = e.target.value;
    setTalId(selectedTalukaId);

    // Fetch and set the initial list of villages when a taluka is selected
    villageData(selectedTalukaId, "").then((initialVillages) => {
      setVillage(initialVillages);
    });

    setVillage([]);
    setVillageId("");
  };

  const handleVillage = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setVillageId(selectedOption.value);
      console.log("selectedOption of village", selectedOption.value);
    } else {
      setVillageId("");
    }
  };

  var userDetailsTalukaId = userDetails.taluka_id;

  useEffect(() => {
    if (userDetails.role_id !== "7") {
      villageData(userDetailsTalukaId, "");
    }
  }, [userDetailsTalukaId]);

  // taluka api

  const talData = async () => {
    try {
      const finalData = {
        district_id: userDetails.district_id,
        user_id: userDetails.id,
      };
      const response = await axios.post(
        API_BASE_URL + "api/taluka_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("taluka list response", response.data.data);

      if (
        response.data.data != null &&
        response.data.data.length >= 1 &&
        userDetails.role_id != "7"
      ) {
        console.log("taluka level response", response.data.data);
        setTalId(response.data.data[0].id);
      } else if (userDetails.role_id === "7") {
        console.log("district level response", "");
        setTalId("");
      }
      setTal(response.data.data);
    } catch (error) {
      console.error("Error fetching taluka list data:", error);
      alert("Error fetching taluke list data");
    }
  };

  // village api

  const villageData = async (talukaId, inputValue) => {
    try {
      if (!talukaId) {
        setVillage([]);
        return [];
      }
      const finalData = {
        user_id: userDetails.id,
        taluka_id: talukaId,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/village_list_by_user_id",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("inputValue", inputValue);
      console.log("All villages list top:", response.data.data);
      // Map the response data to the format expected by Autocomplete
      const villages = response.data.data.map((village) => ({
        value: village.id,
        label: village.name,
      }));

      // Set the village state with the fetched data
      setVillage(villages);

      return villages;
    } catch (error) {
      console.error("Error fetching village list data:", error);
      alert("Error fetching village list data");
      return [];
    }
  };

  useEffect(() => {
    talData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>वसुली रीपोर्ट || घरपट्टी वसुली </title>
      </Helmet>
      <div className="container-fluid common-section common_deputy_container pt-0">
        <div className="row common_deputy_row">
          <div className="col-lg-12 navigate-section-header  mt-3">
            <div className="page-title">
              <p className="fw-bold mb-2">२ . वसुली विभाग</p>
            </div>
            <div className="navigate-section d-flex">
              <div className=" d-flex flex-column justify-content-center">
                <h6 className="mb-0">
                  जिल्हा: {villageDataInfo.district_name}
                </h6>
              </div>
              <div className="VillagePanchayat d-flex gap-2 ms-5 justify-content-center align-items-center">
                <label htmlFor="">माहे :</label>
                {month === undefined ? (
                  <span></span>
                ) : (
                  <select
                    name="month"
                    className="taluka_select_input"
                    value={month}
                    onChange={(e) => setMonth(parseInt(e.target.value))}
                  >
                    {/* <option value="" className="fw-bold">
                          महिना निवडा
                        </option> */}
                    <option value="1">जानेवारी</option>
                    <option value="2">फेब्रुवारी</option>
                    <option value="3">मार्च</option>
                    <option value="4">एप्रिल</option>
                    <option value="5">मे</option>
                    <option value="6">जून</option>
                    <option value="7">जुलै</option>
                    <option value="8">ऑगस्ट</option>
                    <option value="9">सप्टेंबर</option>
                    <option value="10">ऑक्टोंबर</option>
                    <option value="11">नोव्हेंबर</option>
                    <option value="12">डिसेंबर</option>
                  </select>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">तालुका: </label>
                  <select
                    name="taluka"
                    className="taluka_select_input"
                    onChange={(e) => handleOnchangeTaluka(e, talId)}
                    value={talId}
                  >
                    {userDetails.role_id === "7" ? <option>निवडा</option> : ""}
                    {tal.map((ele) => {
                      return (
                        <option key={ele.id} value={ele.id}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center ms-5">
                <div className={`${style.select_group_top}`}>
                  <label htmlFor="">ग्रामपंचयात: </label>
                  <Autocomplete
                    disablePortal
                    className={`${style.select_common_dropdown}`}
                    options={village}
                    sx={{ width: 200 }}
                    onChange={(e, selectedOption) =>
                      handleVillage(selectedOption)
                    }
                    value={
                      villageId
                        ? village.find((v) => v.value === villageId) || null
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="निवडा" />
                    )}
                  />
                </div>
              </div>
            </div>
            {/* <TabEco/> */}
          </div>
          <div className="col-lg-12 mt-5">
            <div className="table-main-sec">
              <div className="table_title_top">
                <p>
                  अ) घरपट्टी वसूली (मालमत्ता कर, पडसर कर, दिवाबत्ती कर व आरोग्य
                  कर)
                </p>
              </div>

              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">कराचे नाव</th>
                      <th scope="col">
                        मागील थकबाकी <br /> (सन {yearInfo.previous_year_name})
                      </th>
                      <th scope="col">
                        चालू मागणी <br /> (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        एकूण मागणी <br />
                        (मागील + चालू) (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        {" "}
                        मागील महिनाअखेर <br /> वसूली{" "}
                      </th>
                      <th scope="col">
                        {" "}
                        चालू महिन्यातील <br /> वसूली{" "}
                      </th>
                      <th scope="col"> एकूण वसूली </th>
                      <th scope="col"> टक्केवारी </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>मालमत्ता</td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[0]?.past_due || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[0]?.current_demand || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[0]?.total_demand || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryALastMonthTotal
                          ? recoveryALastMonthTotal[0]?.last_month_recovery ||
                            "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[0]
                              ?.total_current_month_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[0]?.total_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[0]?.percentage || "0"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>पडसर</td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[1]?.past_due || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[1]?.current_demand || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[1]?.total_demand || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryALastMonthTotal
                          ? recoveryALastMonthTotal[1]?.last_month_recovery ||
                            "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[1]
                              ?.total_current_month_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[1]?.total_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[1]?.percentage || "0"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>दिवाबत्ती</td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[2]?.past_due || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[2]?.current_demand || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[2]?.total_demand || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryALastMonthTotal
                          ? recoveryALastMonthTotal[2]?.last_month_recovery ||
                            "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[2]
                              ?.total_current_month_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[2]?.total_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[2]?.percentage || "0"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>आरोग्य</td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[3]?.past_due || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[3]?.current_demand || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryAEcoAData
                          ? recoveryAEcoAData[3]?.total_demand || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryALastMonthTotal
                          ? recoveryALastMonthTotal[3]?.last_month_recovery ||
                            "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[3]
                              ?.total_current_month_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[3]?.total_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryARecoveryAData
                          ? recoveryARecoveryAData[3]?.percentage || "0"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>एकूण</td>
                      <td className="fw-bold">
                        {TotalDueRecoveryA ? TotalDueRecoveryA : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalCurrentDemandRecoveryA
                          ? TotalCurrentDemandRecoveryA
                          : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalDemandRecoveryA ? TotalDemandRecoveryA : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalLastMonthDemandRecoveryA
                          ? TotalLastMonthDemandRecoveryA
                          : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalCurrentMonthDemandRecoveryA
                          ? TotalCurrentMonthDemandRecoveryA
                          : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalRecoveryRecoveryA ? TotalRecoveryRecoveryA : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalPercentageRecoveryA
                          ? TotalPercentageRecoveryA?.toFixed(2)
                          : "0"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p>ब) पाणीपट्टी वसूली (सामान्य व विशेष पाणीपट्टी) </p>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">कराचे नाव</th>
                      <th scope="col">
                        मागील थकबाकी <br /> (सन {yearInfo.previous_year_name})
                      </th>
                      <th scope="col">
                        चालू मागणी <br /> (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        एकूण मागणी <br />
                        (मागील + चालू) (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        {" "}
                        मागील महिनाअखेर <br /> वसूली{" "}
                      </th>
                      <th scope="col">
                        {" "}
                        चालू महिन्यातील <br /> वसूली{" "}
                      </th>
                      <th scope="col"> एकूण वसूली </th>
                      <th scope="col"> टक्केवारी </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>सामान्य</td>
                      <td>
                        {totalsLoaded && recoveryBEcoBData
                          ? recoveryBEcoBData[0]?.past_due || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryBEcoBData
                          ? recoveryBEcoBData[0]?.current_demand || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryBEcoBData
                          ? recoveryBEcoBData[0]?.total_demand || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryBLastMonthTotal
                          ? recoveryBLastMonthTotal[0]?.last_month_recovery ||
                            "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryBRecoveryBData
                          ? recoveryBRecoveryBData[0]
                              ?.total_current_month_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryBRecoveryBData
                          ? recoveryBRecoveryBData[0]?.total_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryBRecoveryBData
                          ? recoveryBRecoveryBData[0]?.percentage || "0"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>विशेष</td>
                      <td>
                        {totalsLoaded && recoveryBEcoBData
                          ? recoveryBEcoBData[1]?.past_due || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryBEcoBData
                          ? recoveryBEcoBData[1]?.current_demand || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryBEcoBData
                          ? recoveryBEcoBData[1]?.total_demand || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryBLastMonthTotal
                          ? recoveryBLastMonthTotal[1]?.last_month_recovery ||
                            "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryBRecoveryBData
                          ? recoveryBRecoveryBData[1]
                              ?.total_current_month_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryBRecoveryBData
                          ? recoveryBRecoveryBData[1]?.total_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryBRecoveryBData
                          ? recoveryBRecoveryBData[1]?.percentage || "0"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>एकूण</td>
                      <td className="fw-bold">
                        {TotalDueRecoveryB ? TotalDueRecoveryB : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalCurrentDemandRecoveryB
                          ? TotalCurrentDemandRecoveryB
                          : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalDemandRecoveryB ? TotalDemandRecoveryB : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalLastMonthDemandRecoveryB
                          ? TotalLastMonthDemandRecoveryB
                          : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalCurrentMonthDemandRecoveryB
                          ? TotalCurrentMonthDemandRecoveryB
                          : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalRecoveryRecoveryB ? TotalRecoveryRecoveryB : "0"}
                      </td>
                      <td className="fw-bold">
                        {TotalPercentageRecoveryB
                          ? TotalPercentageRecoveryB?.toFixed(2)
                          : "0"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="mt-3">क) किरकोळ वसूली (पवनचक्की सोडून) </p>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.नं.</th>
                      <th scope="col">
                        मागील थकबाकी <br /> (सन {yearInfo.previous_year_name})
                      </th>
                      <th scope="col">
                        चालू मागणी <br /> (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        एकूण मागणी <br />
                        (मागील + चालू) (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        {" "}
                        मागील महिनाअखेर <br /> वसूली{" "}
                      </th>
                      <th scope="col">
                        {" "}
                        चालू महिन्यातील <br /> वसूली{" "}
                      </th>
                      <th scope="col"> एकूण वसूली </th>
                      <th scope="col"> टक्केवारी </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        {totalsLoaded && recoveryCEcoCData
                          ? recoveryCEcoCData[0]?.past_due || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryCEcoCData
                          ? recoveryCEcoCData[0]?.current_demand || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryCEcoCData
                          ? recoveryCEcoCData[0]?.total_demand || "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryCLastMonthTotal
                          ? recoveryCLastMonthTotal[0]?.last_month_recovery ||
                            "0"
                          : ""}
                      </td>
                      <td>
                        {totalsLoaded && recoveryCRecoveryCData
                          ? recoveryCRecoveryCData[0]
                              ?.total_current_month_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryCRecoveryCData
                          ? recoveryCRecoveryCData[0]?.total_recovery || "0"
                          : ""}
                      </td>
                      <td className="fw-bold">
                        {totalsLoaded && recoveryCRecoveryCData
                          ? recoveryCRecoveryCData[0]?.percentage || "0"
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className="">ड) पवनचक्की वसूली / कारखाना वसूली</p>
              <div className="table-responsive mb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">अ.नं.</th>
                      <th scope="col">
                        पवनचक्की <br /> कंपनीचे नाव
                      </th>
                      <th scope="col">
                        पवनचक्की / कारखाना <br /> संख्या
                      </th>
                      <th scope="col">
                        मागील थकबाकी <br /> (सन {yearInfo.previous_year_name})
                      </th>
                      <th scope="col">
                        चालू मागणी <br /> (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        एकूण मागणी <br />
                        (मागील + चालू) (सन {yearInfo.current_year_name})
                      </th>
                      <th scope="col">
                        {" "}
                        मागील महिनाअखेर <br /> वसूली{" "}
                      </th>
                      <th scope="col">
                        {" "}
                        चालू महिन्यातील <br /> वसूली{" "}
                      </th>
                      <th scope="col"> एकूण वसूली </th>
                      <th scope="col"> टक्केवारी </th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalsLoaded &&
                      recoveryDEcoDData.map((ele, index) => {
                        const lastMonthRecovery = recoveryDLastMonthTotal.find(
                          (item) =>
                            item.windmill_company_name ===
                            ele.windmill_company_name
                        );
                        const currentMonthRecovery =
                          recoveryDRecoveryDData.find(
                            (item) =>
                              item.windmill_company_name ===
                              ele.windmill_company_name
                          );
                        return (
                          <tr key={ele.id}>
                            <td>{index + 1}</td>
                            <td>{ele.windmill_company_name || "-"}</td>
                            <td>{ele.no_of_windmill || "0"}</td>
                            <td className="fw-bold">{ele.past_due || "0"}</td>
                            <td className="fw-bold">
                              {ele.current_demand || "0"}
                            </td>
                            <td className="fw-bold">
                              {ele.total_demand || "0"}
                            </td>
                            <td>
                              {lastMonthRecovery
                                ? lastMonthRecovery.last_month_recovery
                                : "0"}
                            </td>
                            <td>
                              {currentMonthRecovery
                                ? currentMonthRecovery.total_current_month_recovery
                                : "0"}
                            </td>
                            <td>
                              {currentMonthRecovery
                                ? currentMonthRecovery.total_recovery
                                : "0"}
                            </td>
                            <td>
                              {currentMonthRecovery
                                ? currentMonthRecovery.percentage
                                : "0"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecoveryReport_A;
