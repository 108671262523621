import React, { useEffect, useState } from "react";
import style from "./vistartSwachhBharat.module.css";
import { API_AUTH_PASSWORD, API_AUTH_USERNAME, API_BASE_URL } from "../../../Constant/Global";
import axios from "axios";
import DashboardSkeleton from "../../skeleton/dashboardSkeleton/DashboardSkeleton";
const VistarAdikariSwachhBharatHome = () => {
  const [progress, setProgress] = useState(40);
  const [swachBharathData, setSwachBharathData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [otherData, setOtherData] = useState("");

  var userDataInfo = sessionStorage.getItem("info");
  var userInfo = JSON.parse(userDataInfo);
  var userDetails = userInfo.user_details;
  var userDetailsVillageList = userInfo.user_village_list;


  // swachh_bharat data  for dashboard
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const dashboardData = async () => {
      try {
        const finalData = {
          user_village_list: userDetailsVillageList,
        };
        console.log(
          "swachh_bharat finalData vistaradhikari",
          finalData
        );
        const response = await axios.post(
          API_BASE_URL + "dashboard/swachh_bharat",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          },
          signal
        );
        console.log(
          "swachh_bharat data response for dashboard in vistaradhikari",
          response.data
        );
        setIsLoading(false);
        setSwachBharathData(response.data.data.swachh_bharat);
      } catch (error) {
        console.log(error.response.data.data);
      }
    };
    dashboardData();
    
    return () => {
      controller.abort();
      console.log("clear dashboard");
    };
  }, []);


  // other swachh_bharat data  for dashboard
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const dashboardData = async () => {
      try {
        const finalData = {
          user_id: userDetails.id,
        };

        const response = await axios.post(
          API_BASE_URL + "dashboard/gharkul_swachh_extra",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          },
          signal
        );
        console.log(
          "gharkul_swachh_extra data response for dashboard vistar",
          response.data
        );
        setIsLoading(false);
        setOtherData(response.data.data);
      } catch (error) {
        console.log(error.response.data.data);
      }
    };
    dashboardData();

    return () => {
      controller.abort();
      console.log("clear dashboard");
    };
  }, []);
  return (
    <section className={`${style.model_main_section}`}>
      {isLoading ? (
        <DashboardSkeleton/>
      ):(
        <div className="container-fluid ps-0  pe-0 ">
        {/* घरकुल विभाग */}
        <div className="row ms-0 me-1 mt-3 mb-3">
          <div className="col-lg-12 ps-0 pe-0">
            <div className={`card ${style.model_notice_board_card}`}>
              <div className={`${style.model_report_cart_title}`}>
                <span className={`${style.m_r_c_t_span}`}>
                  विस्तार अधिकारी स्वच्छ भारत
                </span>
              </div>
              <div className={`row ${style.expense_row}`}>
                <div className={`col-lg-2 ps-3 mb-2 ${style.top_cards_models}`}>
                  <div className={`${style.grampanchayat_main_card}`}>
                    <div className={`${style.name_of_model}`}>
                      <h4 className={`${style.n_o_m_h4}`}>ग्रामपंचायत</h4>
                      <span>
                        <img
                          src={require("../../../component/assets/images/deputyco/expand_more.png")}
                          alt="expand"
                          className="img-fluid"
                        />
                      </span>
                    </div>
                    <div className={`${style.total_count_of_model}`}>
                      <h4 className={`${style.t_o_m_h4}`}>{otherData?.village_count || 0}</h4>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-2 mb-2 ${style.top_cards_models}`}>
                  <div className={`${style.gramsevek_main_card}`}>
                    <div className={`${style.name_of_model}`}>
                      <h4 className={`${style.n_o_m_h4}`}>ग्रामसेवक</h4>
                      <span>
                        <img
                          src={require("../../../component/assets/images/deputyco/expand_more.png")}
                          alt="expand"
                          className="img-fluid"
                        />
                      </span>
                    </div>
                    <div className={`${style.total_count_of_model}`}>
                      <h4 className={`${style.t_o_m_h4}`}>{otherData?.gramsevek_count || 0}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`row ${style.expense_row}`}>
                {/* <div className={`col-lg-4 ${style.expense_main_col_4}`}>
                  <div className={`${style.fifteen_vith_banner_sec}`}>
                    <div className={`${style.expense_counting_sec_main}`}>
                      <div className={`${style.fifteen_vith_left_main}`}>
                        <div className={`${style.fifteen_vith_left_one_sec}`}>
                          <div className={`${style.progress_bar_sec_ex}`}>
                            <CircularProgressWithLabel value={progress} />
                          </div>
                          <div className={`${style.count_sec_ex}`}>
                            <h4 className={`${style.count_sec_h4}`}>1750</h4>
                            <span className={`${style.count_sec_span}`}>
                              Total
                            </span>
                          </div>
                        </div>
                        <div
                          className={`${style.fifteen_vith_left_second_sec}`}
                        >
                          <div className={`${style.progress_bar_sec_ex}`}>
                            <CircularProgressWithLabel value={progress} />
                          </div>
                          <div className={`${style.count_sec_ex}`}>
                            <h4 className={`${style.count_sec_h4}`}>2000</h4>
                            <span className={`${style.count_sec_span}`}>
                              Hold
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={`${style.fifteen_vith_right_main}`}>
                        <div className={`${style.fifteen_vith_arrow_top}`}>
                          <span className={`${style.n_m_e_b_span}`}>
                            <GoArrowRight />
                          </span>
                        </div>
                        <div
                          className={`${style.fifteen_vith_right_middle_sec}`}
                        >
                          <div className={`${style.progress_bar_sec_ex}`}>
                            <CircularProgressWithLabel value={progress} />
                          </div>
                          <div className={`${style.count_sec_ex}`}>
                            <h4 className={`${style.count_sec_h4}`}>87.50%</h4>
                            <span className={`${style.count_sec_span}`}>
                              Total Sanction
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className={`col-lg-2 ${style.expense_main_col_2}`}>
                  <div className={`${style.expense_main_sec_5}`}>
                    <div className={`${style.name_of_model_expense_red}`}>
                      <div className={`${style.expense_of_total_count_sec}`}>
                        <h4 className={`${style.e_o_t_c_s_h4}`}>एकूण मंजूर शौचालय</h4>
                        <div className={`${style.people_count_15_vith}`}>
                          <h3 className={`${style.e_o_t_c_s_h3}`}>{swachBharathData.total_swachh_bharat || 0}</h3>
                          {/* <p className={`${style.e_o_t_c_s_span}`}>
                            Use Amount
                          </p> */}
                        </div>
                      </div>

                      {/* <div className={`${style.expense_bottom_sec}`}>
                        <img
                          src={require("../../../component/assets/images/deputyco/expand_more.png")}
                          alt="expand"
                          className="img-fluid"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className={`col-lg-2 ${style.expense_main_col_2}`}>
                  <div className={`${style.expense_main_sec_2}`}>
                    <div className={`${style.name_of_model_expense_red}`}>
                      <div className={`${style.expense_of_total_count_sec}`}>
                        <h4 className={`${style.e_o_t_c_s_h4}`}>
                          पूर्ण स्थिती अंतर्गत
                        </h4>
                        <div className={`${style.people_count_15_vith}`}>
                          <h3 className={`${style.e_o_t_c_s_h3}`}>{swachBharathData.swachh_bharat_complete || 0}</h3>
                          {/* <p className={`${style.e_o_t_c_s_span}`}>Total</p> */}
                        </div>
                      </div>

                      {/* <div className={`${style.expense_bottom_sec}`}>
                        <img
                          src={require("../../../component/assets/images/deputyco/expand_more.png")}
                          alt="expand"
                          className="img-fluid"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className={`col-lg-2 ${style.expense_main_col_2}`}>
                  <div className={`${style.expense_main_sec_3}`}>
                    <div className={`${style.name_of_model_expense_red}`}>
                      <div className={`${style.expense_of_total_count_sec}`}>
                        <h4 className={`${style.e_o_t_c_s_h4}`}>
                          अपूर्ण स्थिती अंतर्गत
                        </h4>
                        <div className={`${style.people_count_15_vith}`}>
                          <h3 className={`${style.e_o_t_c_s_h3}`}>{swachBharathData.swachh_bharat_uncomplete || 0}</h3>
                          {/* <p className={`${style.e_o_t_c_s_span}`}>Pending</p> */}
                        </div>
                      </div>
                      {/* <div className={`${style.expense_bottom_sec}`}>
                        <img
                          src={require("../../../component/assets/images/deputyco/expand_more.png")}
                          alt="expand"
                          className="img-fluid"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* notice board */}
        <div className="row ms-0 me-1">
          <div className="col-lg-6 ps-0 pe-0">
            <div className={`card ${style.model_notice_board_card}`}>
              <div className={`${style.notice_board_title_main_group}`}>
                <div className={`${style.notice_board_title_sub_left_group}`}>
                  <img
                    src={require("../../../component/assets/images/grambox/sound.png")}
                    alt="notice"
                    className="img-fluid"
                  />
                  <span className={`${style.notice_board_title}`}>
                    नोटिस बोर्ड
                  </span>
                </div>
                <div className="notice_print_sec">
                  <img
                    src={require("../../../component/assets/images/grambox/print_connect.png")}
                    alt="notice"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className={`${style.notice_information_list}`}>
                <ul className={`${style.notice_information_ul_list}`}>
                  <li>१. प्रतिबंध मनाई</li>
                  <li>२. तंटामुकत्ती</li>
                  <li>३. लैंगिक छळ प्रतिबंध मनाई</li>
                  <li>४. प्रतिबंध मनाई</li>
                  <li>५. तंटामुकत्ती</li>
                  <li>६. लैंगिक छळ प्रतिबंध मनाई</li>
                </ul>
              </div>
              <div className={`${style.view_more_notice_list}`}>
                <img
                  src={require("../../../component/assets/images/grambox/dropDownArrow.png")}
                  alt="notice"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 pe-0">
            <div className={`card ${style.model_notice_board_card}`}>
              <div className={`${style.notice_board_title_main_group}`}>
                <div className={`${style.notice_board_title_sub_left_group}`}>
                  {/* <img
                    src={require("../../../component/assets/images/grambox/sound.png")}
                    alt="notice"
                    className="img-fluid"
                  /> */}
                  <span className={`${style.notice_board_title}`}>
                    माहिती मागवणे
                  </span>
                </div>
                {/* <div className="notice_print_sec">
                  <img
                    src={require("../../../component/assets/images/grambox/print_connect.png")}
                    alt="notice"
                    className="img-fluid"
                  />
                </div> */}
              </div>
              {/* <div className={`${style.notice_information_list}`}>
                <ul className={`${style.notice_information_ul_list}`}>
                  <li>१. प्रतिबंध मनाई</li>
                  <li>२. तंटामुकत्ती</li>
                  <li>३. लैंगिक छळ प्रतिबंध मनाई</li>
                  <li>४. प्रतिबंध मनाई</li>
                  <li>५. तंटामुकत्ती</li>
                  <li>६. लैंगिक छळ प्रतिबंध मनाई</li>
                </ul>
              </div>
              <div className={`${style.view_more_notice_list}`}>
                <img
                  src={require("../../../component/assets/images/grambox/dropDownArrow.png")}
                  alt="notice"
                  className="img-fluid"
                />
              </div> */}
            </div>
          </div>
          <div className="col-lg-12 ps-0 pe-0 mt-3" style={{ height: "400px" }}>
            <div className={`card ${style.model_notice_board_card}`}>
              <div className={`${style.notice_board_title_main_group}`}>
                <div className={`${style.notice_board_title_sub_left_group}`}>
                  {/* <img
                    src={require("../../../component/assets/images/grambox/sound.png")}
                    alt="notice"
                    className="img-fluid"
                  /> */}
                  <span className={`${style.notice_board_title}`}>
                    अपूर्ण माहिती अलर्ट
                  </span>
                </div>
                {/* <div className="notice_print_sec">
                  <img
                    src={require("../../../component/assets/images/grambox/print_connect.png")}
                    alt="notice"
                    className="img-fluid"
                  />
                </div> */}
              </div>
              {/* <div className={`${style.notice_information_list}`}>
                <ul className={`${style.notice_information_ul_list}`}>
                  <li>१. प्रतिबंध मनाई</li>
                  <li>२. तंटामुकत्ती</li>
                  <li>३. लैंगिक छळ प्रतिबंध मनाई</li>
                  <li>४. प्रतिबंध मनाई</li>
                  <li>५. तंटामुकत्ती</li>
                  <li>६. लैंगिक छळ प्रतिबंध मनाई</li>
                </ul>
              </div>
              <div className={`${style.view_more_notice_list}`}>
                <img
                  src={require("../../../component/assets/images/grambox/dropDownArrow.png")}
                  alt="notice"
                  className="img-fluid"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      )}
    </section>
  );
};

export default VistarAdikariSwachhBharatHome;
